<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">资料管理</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                <el-input v-model="title" placeholder="输入标题查询" class="iws" clearable></el-input>
                <el-input v-model="tag" placeholder="输入标签查询" class="iws" clearable></el-input>
                <el-cascader v-model="zlType"  :props="typeprops" placeholder="搜索或选择资料分类" clearable filterable class="iwss" ></el-cascader>
                <el-button type="primary" @click="searchPost">查询</el-button>
                
                <el-button type="primary" class="fr" @click="ckqxsp">查看权限申请</el-button>
                <el-button type="success" @click="addPost" class="fr">添加</el-button>
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column prop="TITLE" label="标题"></el-table-column>
                    <el-table-column prop="DOCTYPESTR" label="资料分类">
                        <!-- <template slot-scope="scope">
                            {{scope.row.DOCTYPELIST[scope.row.DOCTYPELIST.length-1].NAME}}
                        </template> -->
                    </el-table-column>
                    <el-table-column prop="ISNEEDPERMISSIONS" label="需要权限">
                        <template slot-scope="scope">
                            <span v-if="scope.row.ISNEEDPERMISSIONS==1">是</span>
                            <span v-else>否</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="TAG" label="标签"></el-table-column>
                    <el-table-column prop="CONTENT" label="内容">
                        <template slot-scope="scope">
                            <div v-if="scope.row.ISNEEDPERMISSIONS==1">
                                   <div v-if="scope.row.CONTENT==''">/</div> 
                                   <div v-else>
                                        <el-button type="text" @click="showDocCon(scope.row)">点击查看</el-button>
                                   </div>
                            </div>
                            <div v-else>
                                {{scope.row.CONTENT}}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="CREATORNAME" label="创建者"></el-table-column>
                    <el-table-column prop="CREATETIME" label="创建日期"></el-table-column>
                    <el-table-column label="操作" width="120">
                        <template slot-scope="scope">
                            <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button>
                            <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
                            <el-button @click="filePost(scope.row)"  type="text" size="small" style="color:#409EFF">附件</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="1200px">
            <el-form :model="postInfo" status-icon :rules="postrules"  ref="uForm" label-width="100px" class="demo-uForm">
                <el-form-item label="资料标题" prop="TITLE">
                    <el-input v-model="postInfo.TITLE" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="资料分类" prop="DOCTYPEID">
                    <el-cascader v-model="postInfo.DOCTYPEID"  :options="tree" :props="treeProps" placeholder="搜索或选择资料分类" filterable style="width:100%"></el-cascader>
                </el-form-item>
                <el-form-item label="资料内容" prop="CONTENT">
                    <el-input type="textarea" v-model="postInfo.CONTENT" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="标签" prop="TAG">
                    <el-input v-model="postInfo.TAG" autocomplete="off" placeholder="多个空格分隔"></el-input>
                </el-form-item>
                <el-form-item label="是否需要权限">
                    <el-radio v-model="postInfo.ISNEEDPERMISSIONS" label="1">是</el-radio>
                    <el-radio v-model="postInfo.ISNEEDPERMISSIONS" label="0">否</el-radio>
                </el-form-item>
                <el-form-item>
                <el-button type="primary" @click="submitPostForm()">提交</el-button>
                <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog :title="docFileStr" :visible.sync="chooseFileModal" width="800px"  :append-to-body="true">
            <p class="fileTitle">现有附件：</p>
            <div class="fileBox">
                <div class="fileItem" v-for="item in fileList" :key="item.SNOIWD" :title="item.FILENAME">
                    <p class="name" @click="downFile(item.FILEPATH)">{{item.FILENAME}}</p>
                    <p class="keywords" @click="setFileKeyWords(item)">关键字：<span v-if="item.KEYWORDS">{{item.KEYWORDS}}</span> <span v-else style="color:#67C23A">点击添加</span>  </p>
                    <div class="fileBtn">
                        <span @click="downFile(item.FILEPATH)" style="color:#409EFF">下载</span>
                        <span @click="delFile(item.SNOWID)" style="color:#F56C6C">删除</span>
                    </div>
                </div>
                <div v-if="fileList.length==0" class="noFile">该资料还未上传附件，请在下方上传。</div>
            </div>
            <p class="fileTitle" style="margin-bottom:20px;">上传附件：</p>
            <div style="overflow:hidden;">
                <el-upload ref="upload"  class=""  :multiple="true" :limit="5" action="" :http-request="uploadFun">
                    <el-button size="small" type="primary">点击上传</el-button>
                </el-upload>
            </div>
      </el-dialog>
      <el-dialog title="关键字设置" :visible.sync="setFileKwyWordsModal" width="500px">
            <el-form :model="fileInfo" status-icon  ref="fForm" label-width="100px" class="demo-uForm">
                <el-form-item label="关键字">
                    <el-input v-model="fileInfo.KEYWORDS" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitFileForm()">提交</el-button>
                    <el-button @click="setFileKwyWordsModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
export default {
  name: "PostManage",
  data() {
    var TITLE = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入资料标题"));
      } else {
        callback();
      }
    };
    var DOCTYPEID = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择资料分类"));
      } else {
        callback();
      }
    };
    var CONTENT = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入资料内容"));
      } else {
        callback();
      }
    };
    var TAG = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入标签"));
      } else {
        callback();
      }
    };
    return {
        typeprops: {
          lazy: true,
          checkStrictly:true,
          lazyLoad :(node, resolve)=> {
            var guid="0"
            if(node && node.data!=undefined){
               guid=node.data.guid 
            }
            var nodes=[]
            var md5_data =this.comjs.requestDataEncrypt({PARENTID:guid}, this.$store.state.token,this.$md5(this.comjs.sortAsc({PARENTID:guid})).toUpperCase())
            this.$http.post("Doc/GetDoctypeList",md5_data)
            .then((response) => {
              if(response.data.SUCCESS){   
                 var l=response.data.DATA
                 for(var i=0;i<l.length;i++){
                    nodes.push({
                      value:l[i].SNOWID,
                      label:l[i].NAME,
                      guid:l[i].SNOWID,
                      leaf: false
                    })
                 }
                 resolve(nodes);
              }else{
                this.errorRequest(response.data.DATA,response.data.MSG,false)
              }
            })
          }
      },
        canDo:true,
        zlType:"",
        title:"",
        tag:"",
       

        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,


        doTypeStr:"",
        doType:"",
        postModal:false,
        postInfo:{},
        postrules: {
            TITLE: [{ validator: TITLE, trigger: "blur" }],
            CONTENT: [{ validator: CONTENT, trigger: "blur" }],
            DOCTYPEID: [{ validator: DOCTYPEID, trigger: "blur" }],
            TAG: [{ validator: TAG, trigger: "blur" }],
        },

        tree:[],
        treeProps:{
            value:"SNOWID",
            label:"NAME",
            children:"DOCTYPERESPONSES",
            checkStrictly:true,
        },
        
        docFileStr:"",
        checkDocId:"",
        fileList:[],
        chooseFileModal:false,

        fileInfo:{},
        setFileKwyWordsModal:false,

    };
  },
  mounted() {
    this.loadItem=null
    this.getTypeTree()
    this.getPostList()
  },
  methods: {
    getTypeTree(){
        var md5_data =this.comjs.requestDataEncrypt({}, this.$store.state.token,this.$md5(this.comjs.sortAsc({})).toUpperCase())
        this.$http.post("Doc/GetDoctypeTreeList", md5_data)
            .then((response) => {
                if (response.data.SUCCESS) {
                   this.tree=response.data.DATA
                } 
            })
    },
    getPostList(){
        this.showLoading("请稍后")
        var params={
            PAGEINDEX:this.PageIndex,
            PAGESIZE:this.PageSize,
            DOCTYPEID:this.zlType,
            TITLE:this.title,
            CONTENT:"",
            TAG:this.tag,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Doc/GetDocList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                var list=response.data.DATA
                var nl=[]
                for(var i=0;i<list.length;i++){
                    var d=list[i]
                    d.DOCTYPESTR="/"
                    if(d.DOCTYPELIST){
                        d.DOCTYPESTR=d.DOCTYPELIST[d.DOCTYPELIST.length-1].NAME
                    }
                    nl.push(d)
                }

                this.postList=nl
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
        });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    addPost(){
        this.postInfo={
            TITLE:"",
            CONTENT:"",
            TAG:"",
            ISNEEDPERMISSIONS:"0",
            DOCTYPEID:[],
            CREATORID:this.$store.state.guid,
            CREATORNAME:this.$store.state.name,
        }
        this.doTypeStr="添加资料"
        this.doType="1"
        this.postModal=true
    },
    sendAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("Doc/CreateNewDoc", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    editPost(item){
        var cids=[]
        for(var i=0;i<item.DOCTYPELIST.length;i++){
            cids.push(item.DOCTYPELIST[i].SNOWID)
        }
        this.postInfo={
            SNOWID:item.SNOWID,
            TITLE:item.TITLE,
            CONTENT:item.CONTENT,
            TAG:item.TAG,
            ISNEEDPERMISSIONS:item.ISNEEDPERMISSIONS.toString(),
            DOCTYPEID:cids,
            CREATORID:this.$store.state.guid,
            CREATORNAME:this.$store.state.name,
        }
        this.doTypeStr="修改资料"
        this.doType="2"
        this.postModal=true
    },
    sendEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("Doc/EditDoc", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.postModal=false
                    this.getPostList();
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    delPost(item){
        if(this.canDo){
            this.$confirm('确定删除资料：'+item.TITLE+'？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
            this.sendDel(item.SNOWID)
            }).catch(() => {
            this.$message.info("已取消")    
          });
        }

    },
    sendDel(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({SNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:id})).toUpperCase())
        this.$http.post("Doc/DeleteDoc", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    submitPostForm() {
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    var  tid=this.postInfo.DOCTYPEID[this.postInfo.DOCTYPEID.length-1]
                    this.postInfo.DOCTYPEID=tid
                    this.postInfo.ISNEEDPERMISSIONS = parseInt(this.postInfo.ISNEEDPERMISSIONS)
                    if(this.doType=="1"){
                        this.sendAdd()
                    }else if(this.doType=="2"){
                        this.sendEdit()
                    }
                } else {
                    return false;
                }
            });
        }
    },
    filePost(item){
        if(item.ISNEEDPERMISSIONS==1){
            this.showDocFile(item)
        }else{
            this.docFileStr=item.TITLE+"-附件列表"
            this.checkDocId=item.SNOWID
            this.getDocFiles()
        }

        
    },
    uploadFun(f){
      this.showLoading("请稍后...")
      var fd = new FormData();
      fd.append("file", f.file);
      var fname=f.file.name
      this.$upload.post("Doc/UploadDocFile?DOCID="+this.checkDocId+"&FILENAME="+fname, fd)
        .then((response) => {
          //console.log(response)
         this.hideLoading()
          if (response.data.SUCCESS) {
                this.hideLoading()
                this.$message.success("上传成功")
                this.$refs.upload.clearFiles()
                this.getDocFiles()
          }else{
             this.hideLoading()
             this.$message.error("上传失败")
          }
        })
        .catch((error) => {
         this.hideLoading()
      });
    },
    getDocFiles(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({SNOWID:this.checkDocId}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:this.checkDocId})).toUpperCase())
        this.$http.post("Doc/GetDocFileList", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                   this.fileList=response.data.DATA
                   this.chooseFileModal=true
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    downFile(e){
        window.open(e)
    },
    delFile(e){
        if(this.canDo){
            this.$confirm('确定删除附件？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
                this.sendDelFile(e)
            }).catch(() => {
            this.$message.info("已取消")    
          });
        }
    },
    sendDelFile(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({SNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:id})).toUpperCase())
        this.$http.post("Doc/DelDocFile", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.getDocFiles()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    setFileKeyWords(item){
        
        var kw=""
        if(item.KEYWORDS){
            kw=item.KEYWORDS
        }
        this.fileInfo={
            SNOWID:item.SNOWID,
            KEYWORDS:kw
        }
        this.setFileKwyWordsModal=true
    },
    submitFileForm() {
        if (this.canDo) {
            //console.log(this.fileInfo)
            this.canDo=false
            this.showLoading("请稍后")
            var md5_data =this.comjs.requestDataEncrypt(this.fileInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.fileInfo)).toUpperCase())
            this.$http.post("Doc/SetDocKeyWord", md5_data)
                .then((response) => {
                    this.canDo=true
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.$message.success("设置成功")
                        var nl=[]
                        for(var i=0;i<this.fileList.length;i++){
                            var d=this.fileList[i]
                            if(d.SNOWID== this.fileInfo.SNOWID){
                                d.KEYWORDS = this.fileInfo.KEYWORDS
                            }
                           nl.push(d) 
                        }
                        this.fileList=nl
                        this.setFileKwyWordsModal=false
                    } else {
                        this.errorRequest(response.data.DATA,response.data.MSG,true)
                    }
                })
                .catch((error) => {
                    this.$message.error("请求出错")
                    this.hideLoading()
                    this.canDo=true   
                });

        }
    },
    showDocCon(item){
        //console.log(item)
        if (this.canDo) {
            //console.log(this.fileInfo)
            this.canDo=false
            this.showLoading("请稍后")
            var params={
                SNOWID:item.SNOWID
            }
            var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
            this.$http.post("SysAccount/EditHaveCount", md5_data)
                .then((response) => {
                    this.canDo=true
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                       this.$alert(item.CONTENT, item.TITLE, {
                            confirmButtonText: '确定',
                            callback: action => {
                                //
                            }
                        });
                    } else {
                        this.errorRequest(response.data.DATA,'暂无查看权限',true)
                    }
                })
                .catch((error) => {
                    this.$message.error("请求出错")
                    this.hideLoading()
                    this.canDo=true   
                });

        }

    },
    showDocFile(item){
        //console.log(item)
        if (this.canDo) {
            //console.log(this.fileInfo)
            this.canDo=false
            this.showLoading("请稍后")
            var params={
                SNOWID:item.SNOWID
            }
            var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
            this.$http.post("SysAccount/EditHaveCount", md5_data)
                .then((response) => {
                    this.canDo=true
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.docFileStr=item.TITLE+"-附件列表"
                        this.checkDocId=item.SNOWID
                        this.getDocFiles()
                    } else {
                        this.errorRequest(response.data.DATA,"暂无查看权限",true)
                    }
                })
                .catch((error) => {
                    this.$message.error("请求出错")
                    this.hideLoading()
                    this.canDo=true   
                });

        }

    },
    ckqxsp(){
        this.showLoading("请稍后")
        var params={
            PAGEINDEX:1,
            PAGESIZE:1,
            NAME:"",
            ALIAS:"SHOWDOC",
            VISIBILITY:true,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Model/GetModelList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS && response.data.DATA.length>0) {
                var d=response.data.DATA[0]
                window.open("/statics/pages/LCQC.html?mid="+d.SNOWID+"&token="+this.$store.state.token)
            } else {
            this.$message.error("请先配置资料库查看申请流程")
            }
            })
            .catch((error) => {
                this.$message.error("请求失败，请稍后再试")
                this.hideLoading()
            }); 
    }
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style scoped>
.fileBox{overflow: hidden;margin: 20px 0;padding: 20px;background-color: #F8F8F9;}
.fileItem{border-radius: 3px;background-color: #FFFFFF;cursor: pointer;padding: 10px;float:left;margin-right: 20px;margin-bottom: 20px;width: 206px;}
.fileItem:nth-of-type(3n){margin-right: 0!important;}
.fileItem .name{font-size: 15px;font-weight: bold;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;color: #444;margin-bottom: 2px;}
.fileItem .keywords{font-size: 14px;font-weight: bold;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;margin-bottom: 5px;color: #909399;}
.fileItem .fileBtn{overflow: hidden;text-align: left;}
.fileItem span{margin-right: 10px;}
.noFile{text-align: center;font-size: 15px;color: #409EFF;}
.fileTitle{font-size: 16px;font-weight: bold;overflow: hidden;}

</style>