<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">监测应急管理</p>
        </div>
        <div class="tableBox" style="top:50px;">
                <div class="ksItem" >
                    防火检测系统
                </div>
                <div class="ksItem" >
                   应急管理
                </div>
                <div style="width:100%;float:left;height:1px;"></div>
                <div class="ksItem" >
                   安全活动月
                </div>
                <div class="ksItem" >
                   系统内及系统外历次检查发现的问题,记录问题的整改落实情况,未能按时整改落实需说明原因,并列出整改计划,行程资料库汇总
                </div>
                <div style="width:100%;float:left;height:1px;"></div>
        </div>
    </div>
</template>
<script>
export default {
  name: "KSFL",
  data() {

    return {
        canDo:true, 
    };
  },
  mounted() {
    this.loadItem=null
  },
  methods: {

  },
  computed: {
  },
  watch: {
  },
};
</script>
<style scoped>
    .el-collapse-item__header{padding: 0 20px;font-size: 16px;}
    .el-collapse .is-active{color: #409EFF;}
    .el-collapse-item__content{padding-left:20px;}

    .ksItem{width: 600px;height: 80px;border-radius: 3px;cursor: pointer;color: #FFFFFF;background-color: #409EFF;font-size: 16px;margin-left: 30px;margin-top: 30px;float:left;display: -webkit-box;display: -ms-flexbox;display: -webkit-flex;display: flex;-webkit-box-pack: center;-ms-flex-pack: center;-webkit-justify-content: center;justify-content: center;-webkit-box-align: center;-ms-flex-align: center;-webkit-align-items: center;align-items: center;padding:0 30px;}


    .ksItem:hover{background-color: #5FADFC;}

</style>