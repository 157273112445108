<template>
    <div class="views-main">
        <div class="views-main-title">
             <el-button type="primary" icon="el-icon-arrow-left" style="float:left;margin-right:20px;" size="small" @click="backddtd">返回</el-button>
            <p class="txt">预试停电计划</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                <el-input v-model="gdgs" placeholder="请输入供电公司" class="iw" ></el-input>
                <el-input v-model="sqdw" placeholder="请输入申请单位" class="iw" ></el-input>
                <el-input v-model="sbmc" placeholder="请输入设备名称" class="iw" ></el-input>
                <el-button type="primary" @click="searchPost">查询</el-button>                
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column type="expand">
                        <template slot-scope="props">
                            <div style="padding:10px 20px;overflow:hidden;">
                                <p style="font-size:15px;margin-bottom:10px;">
                                    备注：{{props.row.BZ}}
                                </p>
                            </div>
                            <div style="padding:10px 20px;overflow:hidden;">
                                <p style="font-size:15px;margin-bottom:10px;">
                                    检修详细内容：{{props.row.CONTENT}}
                                </p>
                            </div>
                            <div style="padding:10px 20px;overflow:hidden;">
                                <p style="font-size:15px;margin-bottom:10px;">
                                    申请时间：{{props.row.APPLYTIME}}
                                </p>
                            </div>
                            <div style="padding:10px 20px;overflow:hidden;">
                                <p style="font-size:15px;margin-bottom:10px;">
                                    请求停电开始时间：{{props.row.APPLYSTARTTIME}}
                                </p>
                            </div>
                            <div style="padding:10px 20px;overflow:hidden;">
                                <p style="font-size:15px;margin-bottom:10px;">
                                    请求停电结束时间：{{props.row.APPLYENDTIME}}
                                </p>
                            </div>

                            <div style="padding:10px 20px;overflow:hidden;">
                                <p style="font-size:15px;margin-bottom:10px;">
                                    批准停电开始时间：{{props.row.APPROVEDSTARTTIME}}
                                </p>
                            </div>
                            <div style="padding:10px 20px;overflow:hidden;">
                                <p style="font-size:15px;margin-bottom:10px;">
                                    批准停电结束时间：{{props.row.APPROVEDENDTIME}}
                                </p>
                            </div>
                            <div style="padding:10px 20px;overflow:hidden;">
                                <p style="font-size:15px;margin-bottom:10px;">
                                    批准作业开始时间：{{props.row.APPROVEDWORKSTARTTIME}}
                                </p>
                            </div>
                            <div style="padding:10px 20px;overflow:hidden;">
                                <p style="font-size:15px;margin-bottom:10px;">
                                    批准作业结束时间：{{props.row.APPROVEDWORKENDTIME}}
                                </p>
                            </div>
                        </template>
                        </el-table-column>
                    <el-table-column prop="ELECCOMPANY" label="供电公司"></el-table-column>
                    <el-table-column prop="APPLYCOMPANY" label="申请单位"></el-table-column>
                    <el-table-column prop="LEADERNAME" label="单位领导"></el-table-column>
                    <el-table-column prop="PLANUSERNAME" label="计划员"></el-table-column>
                    <el-table-column prop="MACHINENAME" label="停检设备名称"></el-table-column>
                    <el-table-column prop="EFFECTREGION" label="影响停电地区及厂矿名称"></el-table-column>
                    <el-table-column prop="WORKMANAGER" label="工作负责人"></el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  name: "TDJH",
  data() {
    
    return {
        canDo:true,
        gdgs:"",
        sqdw:"",
        sbmc:"",
       
        userList:[],

        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,
    };
  },
  mounted() {
    this.loadItem=null
    this.getPostList()
  },
  methods: {
    getPostList(){
        this.showLoading("请稍后")
        var sbid=""
        var params={
            PAGEINDEX:this.PageIndex,
            PAGESIZE:this.PageSize,
            MACHINEID:sbid,
            ELECCOMPANY:this.gdgs,
            APPLYCOMPANY:this.sqdw,
            MACHINENAME:this.sbmc,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("StopelecPower/GetStopelecPowerList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
        });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    backddtd(){
        this.$router.replace('/DDTD')
    }
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style >
</style>