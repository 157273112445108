<template>
  <div class="views-main">
    <div class="views-main-title">
      <p class="txt">货品分类</p>
    </div>
    <div class="tableBox" style="top:50px;">
      <div class="views-main-search">
        <el-input placeholder="输入关键字进行过滤" v-model="filterText" style="width:200px;"> </el-input>
        <el-button type="primary" @click="sendQuery" class="ml10">查询</el-button>
        <el-button type="success" @click="addDep" class="ml10">添加分类</el-button>
      </div>
      <div class="views-main-body">
          <el-tree :props="props" :load="getList" lazy  ref="tree" node-key="guid"  :filter-node-method="filterNode" draggable @node-drop="handleDrop">
            <span class="custom-tree-node" slot-scope="{ node, data }">
              <span class="treeName">{{ node.label }}</span>
              <span>
                <el-button type="text" size="mini" @click.stop="() => append(node, data)" style="color:#19be6b">
                  添加
                </el-button>
                <el-button type="text" size="mini" @click.stop="() => edit(node, data)" style="color:#ff9900">
                  编辑
                </el-button>
                <el-button type="text" size="mini" @click.stop="() => remove(node, data)" style="color:#ed4014">
                  删除
                </el-button>
              </span>
            </span>
          </el-tree>
      </div>
    </div>
    <el-dialog :title="DepModalTitle" :visible.sync="DepModal" width="500px">
      <el-form :model="DepInfo" status-icon :rules="DepRules"  ref="DepForm" label-width="110px" class="demo-DepForm" >
        <el-form-item label="分类名称" prop="NAME">
          <el-input v-model="DepInfo.NAME" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitDepForm()">提交</el-button>
          <el-button @click="closeDepForm()">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
var h=document.body.clientHeight
export default {
  name: "WZHPFL",
  data() {
    var NAME = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入货品分类名称"));
      } else {
        callback();
      }
    };
    return {

      //资料库模块
      props: {
          label: 'name',
          children: 'zones',
          isLeaf: 'leaf'
      },
      filterText:"",

      DepModalType:"",
      DepModalTitle:"",

      DepParentID:"",
      DepParentNAME:"",   

      DepModal:false,
      DepInfo:{},
      DepRules: {
        NAME: [{ validator: NAME, trigger: "blur" }],
      },
      DepEditItem:null,
      canDo:true,
      auditText:"",
    };
  },
  mounted() {
    this.dialogMaxHeigh = h -300
    this.loadItem=null
  },
  methods: {


    filterNode(value, data) {
        if (!value) return true;
        return data.name.indexOf(value) !== -1;
    },
    getList(node, resolve){
        var guid="0"
        var hasChild=true
        if(node.data != undefined){
            guid = node.data.guid
        }
        if(hasChild){
          setTimeout(()=>{
            var md5_data =this.comjs.requestDataEncrypt({PARENTID:guid}, this.$store.state.token,this.$md5(this.comjs.sortAsc({PARENTID:guid})).toUpperCase())
            this.$http.post("Storage/GetStorageThingtypeList",md5_data)
            .then((response) => {
              if(response.data.SUCCESS){   
                  var l=response.data.DATA
                  var d=[]
                  for(var i=0;i<l.length;i++){
                    var pname="0"
                    if(l[i].PARENTNAME){
                      pname=l[i].PARENTNAME
                    }
                    d.push({
                        guid:l[i].SNOWID,
                        name: l[i].NAME,
                        pid:l[i].PARENTID,
                        //pname:pname,
                        leaf: false,
                    })
                    
                  }
                  resolve(d);
              }else{
                this.errorRequest(response.data.DATA,response.data.MSG,false)
              }
            })
          },100)
            
        }

    },
    sendQuery(){
      var val=this.filterText
      this.$refs.tree.filter(val)
    },
    closeDepForm(){
      this.DepModal=false
    },
    submitDepForm(){
      if (this.canDo) {
          this.$refs.DepForm.validate((valid) => {
          if (valid) {
              if(this.DepModalType=="1"){
                this.appendDep()
              }else if(this.DepModalType=="2"){
                this.editDep()  
              }
          } else {
            return false;
          }
        });
      }
    },
    appendDep(){
      this.canDo=false
      this.showLoading("请稍后")
      var md5_data =this.comjs.requestDataEncrypt(this.DepInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.DepInfo)).toUpperCase())
      this.$http.post("Storage/CreateNewStorageThingtype", md5_data)
        .then((response) => {
          //console.log(response)
          this.hideLoading()
          this.canDo=true
          this.DepModal=false
          if(response.data.SUCCESS){   

            var data={
                name: this.DepInfo.NAME,
                guid:response.data.DATA,
                pid:this.DepParentID,
                //pname:this.DepParentNAME,
                leaf: false,
            }
            var  pid=null
            if(this.DepParentID!="0"){
              pid=this.DepParentID
            }
            this.$refs.tree.append(data,pid) 
            this.$message.success("添加成功")
          }else{
            this.errorRequest(response.data.DATA,response.data.MSG,true)
          }
        })
        .catch((error) => {
          this.$message.error("请求出错")
          this.hideLoading()
          this.canDo=true
      });
    },
    editDep(){
      this.canDo=false
      this.showLoading("请稍后")
      var md5_data =this.comjs.requestDataEncrypt(this.DepInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.DepInfo)).toUpperCase())
      this.$http.post("Storage/EditStorageThingtype", md5_data)
        .then((response) => {
          //console.log(response)
          this.hideLoading()
          this.canDo=true
          this.DepModal=false
          if(response.data.SUCCESS){   
            this.$set(this.DepEditItem,'name',this.DepInfo.NAME)
            this.$set(this.DepEditItem,'pid',this.DepParentID)
            //this.$set(this.DepEditItem,'pname',this.DepParentNAME)
            this.$message.success("编辑成功")
          }else{
            this.errorRequest(response.data.DATA,response.data.MSG,true)
          }
        })
        .catch((error) => {
          this.$message.error("请求出错")
          this.hideLoading()
          this.canDo=true
      });
    },
    addDep(){
      this.DepInfo={
        NAME:"",
        PARENTID:"0",
      },
      this.DepModalType="1"
      this.DepParentID="0"
      //this.DepParentNAME="0"
      this.DepModalTitle="添加货品分类"
      this.DepModal=true
    },
    append(node, data){
      this.DepInfo={
        NAME:"",
        PARENTID:data.guid,
      },
      this.DepModalType="1"
      this.DepParentID=data.guid
      //this.DepParentNAME=data.name
      this.DepModalTitle="添加货品分类"
      this.DepModal=true
    },
    edit(node, data){
       this.DepInfo={
        SNOWID:data.guid,
        NAME:data.name,
        PARENTID:data.pid,
      },
      this.DepEditItem=data
      this.DepModalType="2"
      this.DepParentID=data.pid
      //this.DepParentNAME=data.pname
      this.DepModalTitle="编辑货品分类"
      this.DepModal=true

    },
    remove(node, data){
      if(this.canDo){
        this.$confirm('确定删除'+data.name+'及其全部的子分类？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.delDep(data)
          }).catch(() => {
            this.$message.info("已取消")    
          });
      }
    },
    delDep(data){
      this.canDo=false
      this.showLoading("请稍后")
      var md5_data =this.comjs.requestDataEncrypt({SNOWID:data.guid}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:data.guid})).toUpperCase())
      this.$http.post("Storage/DeleteStorageThingtype", md5_data)
        .then((response) => {
          //console.log(response)
          this.hideLoading()
          this.canDo=true
          if(response.data.SUCCESS){   
              this.$refs.tree.remove(data)
              this.$message.success("删除成功")
          }else{
            this.errorRequest(response.data.DATA,response.data.MSG,true)
          }
        })
        .catch((error) => {
          this.$message.error("请求出错")
          this.hideLoading()
          this.canDo=true
      });
    },
    handleDrop(draggingNode, dropNode, dropType, ev) {
        this.DepEditItem=draggingNode.data
        this.DepInfo={
            SNOWID:draggingNode.data.guid,
            NAME:draggingNode.data.name,
            PARENTID:null,
        }

        if(dropType=="inner"){
            this.DepInfo.PARENTID = dropNode.data.guid
            this.DepParentID = dropNode.data.guid
            //this.DepParentNAME= dropNode.data.name
        }else{
          if(dropNode.data.pid=="0"){
            this.DepInfo.PARENTID = "0"
            this.DepParentID = "0"
          }else{
            this.DepInfo.PARENTID = dropNode.data.pid
            this.DepParentID = dropNode.data.pid
          }
            //console.log(dropNode.data)
            //this.DepParentNAME= "0"
        }
        if(this.canDo){
            this.editDep()
        }
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style >
  .el-tree-node{margin-top: 20px;}
  .treeName{font-size: 16px;margin-right: 5px;}

  .funTreeRow{width: 500px;}
  .funtreeName{font-size: 15px;margin-right: 30px;}
  .funItemList{float:right;}
  .managerName{font-size: 12px;margin-right: 20px;color: #409EFF;}
  .custom-tree-node{display: block;}
  .el-checkbox{margin-right: 15px!important;}
  .el-checkbox__label{padding-left: 5px!important;}
</style>
