<template>
    <div class="views-main">
        <div class="views-main-title">
            <el-button type="primary" icon="el-icon-arrow-left" style="float:left;margin-right:20px;" size="small" @click="backXJGL">返回</el-button>
            <p class="txt" style="margin-top:3px;">模板检查项</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                
                <el-input v-model="code" placeholder="请输入编号查询" class="iww" ></el-input>
                <el-select v-model="gw" placeholder="点击选择岗位查询"  clearable filterable  class="iww">
                        <el-option v-for="item in gwList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                </el-select>
                <el-select v-model="sblx" placeholder="选择设备类型查询"  filterable  clearable class="iww" @change="sblxChange">
                        <el-option v-for="item in mhTypeList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                </el-select>
                <el-select v-model="sbid" placeholder="选择设备查询"  filterable  clearable class="iww">
                            <el-option v-for="item in sblb" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                    </el-select>

                <el-button type="primary" @click="searchPost">查询</el-button>
                
                <el-button type="danger" @click="delMore" class="fr">批量删除</el-button>
                <el-button type="primary" @click="batchMore" class="fr">批量复制</el-button>
                <el-button type="success" @click="addPost" class="fr">添加</el-button>
                <el-upload
                    action=""
                    name="file"
                    class="fr"
                    :multiple="false"
                    :http-request="customRequest"
                    :showUploadList="false"
                    accept=".xlsx"
                >
                <el-button type="primary">上传</el-button>
                </el-upload>
                <el-button type="primary" @click="downloadTemplet" class="fr mr20">下载模板</el-button>
      <!-- <a-modal v-model="visible" title="错误信息" @ok="handleOk">
        <p v-for="item in errormsg" :key="item">{{item}}</p>
      </a-modal> -->
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%" @selection-change="handleSelectionChange">
                    <el-table-column type="selection" width="60" align="center"></el-table-column>
                    <el-table-column prop="CODE" label="编号"></el-table-column>
                    <el-table-column prop="MACHINERESPONSE.NAME" label="设备">
                      <template v-slot="scope">
                        <div>{{scope.row.MACHINERESPONSE.NAME ? scope.row.MACHINERESPONSE.NAME : '/'}}</div>
                      </template>
                    </el-table-column>
                    <el-table-column prop="POSITIONNAME" label="岗位"></el-table-column>
                    <el-table-column prop="BZ" label="描述"></el-table-column>
                    <el-table-column prop="CREATETIME" label="创建日期"></el-table-column>
                    <el-table-column label="操作" width="130">
                        <template slot-scope="scope">
                        <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button>
                        <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
                        <el-button @click="batchAdd(scope.row)"  type="text" size="small" style="color:#409EFF">复制</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="700px">
            <el-form :model="chechItemInfo" status-icon :rules="chechItemrules"  ref="uForm" label-width="100px" class="demo-uForm">
                <el-form-item label="编号" prop="CODE">
                    <el-input v-model="chechItemInfo.CODE" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="岗位">
                    <el-select v-model="chechItemInfo.POSITIONID" placeholder="点击选择岗位"  clearable  filterable  style="width:100%;" @change="postChange">
                            <el-option v-for="item in gwList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="设备类型">
                    <el-select v-model="mhType" placeholder="选择设备类型获取设备"  filterable style="width:100%;" @change="mhTypeChange">
                            <el-option v-for="item in mhTypeList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="设备">
                    <el-select v-model="chechItemInfo.MACHINEID" placeholder="点击选择设备"  filterable  style="width:100%;">
                            <el-option v-for="item in mhList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="描述" prop="BZ">
                    <el-input v-model="chechItemInfo.BZ" autocomplete="off" type="textarea"  class="jcxbz"></el-input>
                </el-form-item>
                <el-form-item>
                <el-button type="primary" @click="submitCheckItemForm()">提交</el-button>
                <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
export default {
  name: "CheckTempletItemManage",
  data() {
    // var POSITIONID = (rule, value, callback) => {
    //   if (value === "") {
    //     callback(new Error("请选择岗位"));
    //   } else {
    //     callback();
    //   }
    // };
    var CODE = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入任务项编号"));
      } else {
        callback();
      }
    };
    var MACHINEID = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择一个设备"));
      } else {
        callback();
      }
    };
    var BZ = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入任务项说明"));
      } else {
        callback();
      }
    };
    return {
        canDo:true,
        gwList:[],

        gw:"",
        code:"",
        sblx:"",
        sblb:[],
        sbid:"",

        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,

        mhType:"",
        mhTypeList:[],

        mhList:[],

        doTypeStr:"",
        doType:"",
        postModal:false,
        chechItemInfo:{
            CODE:"",
            MACHINEID:"",
            BZ:"",
            POSITIONID:"",
            POSITIONNAME:"",
        },
        chechItemrules: {
            CODE: [{ validator: CODE, trigger: "blur" }],
            MACHINEID: [{ validator: MACHINEID, trigger: "blur" }],
            BZ: [{ validator: BZ, trigger: "blur" }],
            //POSITIONID: [{ validator: POSITIONID, trigger: "blur" }],
        },

        selectSnowids:[],
    };
  },
  mounted() {
    //console.log(this.$store.state.checkType)
    this.loadItem=null
    this.getGWList()
    this.getPostList()
    this.getMachineTypeList()
  },
  methods: {
    getGWList(){
        var params={
            PAGEINDEX:1,
            PAGESIZE:10000,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("PositonManage/GetPositionListWithoutPage", md5_data)
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.gwList=response.data.DATA
                } 
            })
    },
    postChange(item){
        if(item==""){
            this.chechItemInfo.POSITIONNAME =""
        }else{
            for(var i=0;i<this.gwList.length;i++){
                if(this.gwList[i].SNOWID == item){
                    this.chechItemInfo.POSITIONNAME = this.gwList[i].NAME
                }
            }
        }
    },
    //获取设备类型列表
    getMachineTypeList(){
        var params={
            PAGEINDEX:1,
            PAGESIZE:10000,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("MachineType/GetMachineTypeList", md5_data)
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.mhTypeList=response.data.DATA
                } 
            })
    },
    mhTypeChange(item){
        this.chechItemInfo.MACHINEID=""
        this.getWFMachine(item)
    },

    //获取风场设备
    getWFMachine(id){
        var params={
            MACHINETYPEID:[id],
            PAGEINDEX:1,
            PAGESIZE:10000,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Machine/GetMachine", md5_data)
            .then((response) => {
                // console.log("获取风场设备")
                // console.log(response)
                this.mhList=response.data.DATA 

            })
    },

    sblxChange(item){
        this.sbid=""
        if(item==""){
            this.sblb=[]
        }else{
            this.getSBByLx(item)
        }
        
    },

    //获取设备
    getSBByLx(id){
        var params={
            MACHINETYPEID:[id],
            PAGEINDEX:1,
            PAGESIZE:10000,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Machine/GetMachine", md5_data)
            .then((response) => {
                // console.log("获取风场设备")
                // console.log(response)
                this.sblb=response.data.DATA 

            })
    },


    getPostList(){
        this.showLoading("请稍后")
        var params={
            PAGEINDEX:this.PageIndex,
            PAGESIZE:this.PageSize,
            CODE:this.code,
            MACHINEID:this.sbid,
            POSITIONID:this.gw,
            MACHINETYPEID:this.sblx,
            TYPE:this.$store.state.checkType,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("CheckItem/GetCheckItemList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
        });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    addPost(){
        this.chechItemInfo={
            CODE:"",
            MACHINEID:"",
            BZ:"",
            POSITIONID:"",
            POSITIONNAME:"",
            TYPE:this.$store.state.checkType,
        },
        this.doTypeStr="添加模板检查项"
        this.doType="1"
        this.postModal=true
    },
    sendAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.chechItemInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.chechItemInfo)).toUpperCase())
        this.$http.post("CheckItem/CreateNewCheckItem", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    editPost(item){
        var pm=""
        for(var i=0;i<this.gwList.length;i++){
             if(this.gwList[i].SNOWID == item.POSITIONID){
                 pm=this.gwList[i].NAME
             }
         }
        this.chechItemInfo={
            SNOWID:item.SNOWID,
            CODE:item.CODE,
            MACHINEID:item.MACHINEID,
            BZ:item.BZ,
            POSITIONID:item.POSITIONID,
            POSITIONNAME:pm,
            TYPE:this.$store.state.checkType,
        }
        this.mhType=item.MACHINERESPONSE.MACHINETYPEID
        this.getWFMachine(item.MACHINERESPONSE.MACHINETYPEID)
        this.doTypeStr="修改检查模板项"
        this.doType="2"
        this.postModal=true
    },
    sendEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.chechItemInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.chechItemInfo)).toUpperCase())
        this.$http.post("CheckItem/EditCheckItem", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    delPost(item){
        if(this.canDo){
            this.$confirm('确定删除检查模板项？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
            this.sendDel(item.SNOWID)
            }).catch(() => {
            this.$message.info("已取消")    
          });
        }

    },
    sendDel(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({SNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:id})).toUpperCase())
        this.$http.post("CheckItem/DeleteCheckItem", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    submitCheckItemForm() {
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    if(this.doType=="1"){
                        this.sendAdd()
                    }else if(this.doType=="2"){
                        this.sendEdit()
                    }
                } else {
                    return false;
                }
            });
        }
    },
    batchAdd(item){
        if(this.canDo){
            this.$confirm('确定为设备 '+item.MACHINERESPONSE.NAME+' 的其他同类型设备批量添加此模板检查项？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
                this.sendBatch(item.SNOWID)
            }).catch(() => {
            this.$message.info("已取消")    
          });
        }
    },
    sendBatch(id){
        this.canDo=false
        this.showLoading("请稍后")

        var md5_data =this.comjs.requestDataEncrypt({SNOWID:id,TYPE:this.$store.state.checkType,}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:id,TYPE:this.$store.state.checkType,})).toUpperCase())
        this.$http.post("CheckItem/BatchCreateNewCheckItemByCheckItem", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("成功增加个"+response.data.DATA+"检查项！")
                   this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },

    handleSelectionChange(e){
        this.selectSnowids=[]
        for(var i=0;i<e.length;i++){
            this.selectSnowids.push(e[i].SNOWID)
        }
    },
    delMore(){
        if(this.canDo){
            if(this.selectSnowids.length == 0){
                this.$message.error("请先选择一个模板检查项")
            }else{
                this.$confirm('确定删除选中的检查模板项？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                    this.sendDelMore()
                }).catch(() => {
            this.$message.info("已取消")    
          });
            }
        }
    },
    sendDelMore(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({CHECKITEMIDLIST:this.selectSnowids,TYPE:this.$store.state.checkType}, this.$store.state.token,this.$md5(this.comjs.sortAsc({CHECKITEMIDLIST:this.selectSnowids,TYPE:this.$store.state.checkType,})).toUpperCase())
        this.$http.post("CheckItem/DeleteCheckItemList", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    var d=response.data.DATA
                    if(d.length==0){
                        this.$message.success("已删除")
                    }else{
                        var codes=""
                         for(var i=0;i<d.length;i++){
                            if(i==0){
                                codes=d[i].CODE
                            }else{
                                codes=codes+'、'+codes
                            }
                         }   
                        this.$alert('以下检查项已绑定模板无法删除：'+codes, '删除提醒', {
                            confirmButtonText: '确定',
                            callback: action => {
                                //
                            }
                        });
                    }
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    batchMore(){
        if(this.canDo){
            if(this.selectSnowids.length == 0){
                this.$message.error("请先选择一个模板检查项")
            }else{
                this.$confirm('确定为复制选中的检查模板项？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                    this.sendBatchMore()
                }).catch(() => {
            this.$message.info("已取消")    
          });
            }
        }
    },
    sendBatchMore(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({CHECKITEMIDLIST:this.selectSnowids,TYPE:this.$store.state.checkType,}, this.$store.state.token,this.$md5(this.comjs.sortAsc({CHECKITEMIDLIST:this.selectSnowids,TYPE:this.$store.state.checkType,})).toUpperCase())
        this.$http.post("CheckItem/BatchCheckItemByCheckItemList", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("复制完成")
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    backXJGL(){
        this.$router.replace('/XJGL')
    },
    customRequest(data){
        this.showLoading("请稍后...")
        var formdata = new FormData();
        formdata.append('file', data.file);
        this.$upload.post("CheckItem/ExportData?TYPE="+this.$store.state.checkType, formdata)
        .then(resJson => {
                this.loading = false
                if (resJson.data.SUCCESS) {
                        this.hideLoading()
                        this.$message.success(resJson.data.MSG)

                        this.getPostList()
                        //this.errormsg = resJson.data.MSG.split('*')
                    } else {
                        this.hideLoading()
                        this.$message.error("上传失败")
                        //this.errormsg = resJson.data.MSG.split('*')
                    }
                })
                .catch((error) => {
                this.hideLoading()
                });
    },
    handleOk(e) {
      var thisObj = this
      this.visible = false
      thisObj.getPostList()
    },
    downloadTemplet(){
      window.open("file/模板检查项模板.xlsx")
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style scoped>

</style>