<template>
  <div class="content">
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search" style="margin:20px 20px 0 20px;">
                <el-select v-model="fc" placeholder="请选风场查询" filterable  class="iws"  clearable >
                        <el-option v-for="item in fcList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                </el-select>
                <el-date-picker v-model="up" type="date" placeholder="选择更新日期" class="iws" clearable></el-date-picker>
                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-button type="success" @click="addPost" class="fr">添加</el-button>
                
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column type="expand">
                      <template slot-scope="props">
                        <div class="rowItem">
                              风场风速:{{ props.row.WINDSPEED }}
                        </div>
                        <div class="rowItem">
                              计划检修:{{ props.row.PLANNEDMAINTENANCE }}
                        </div>
                        <div class="rowItem">
                              故障停机:{{ props.row.DOWNTIME }}
                        </div>
                        <div class="rowItem">
                              限负荷停机:{{ props.row.LOADLIMITSHUTDOWN }}
                        </div>
                        <div class="rowItem">
                              负荷:{{ props.row.LOAD }}
                        </div>
                        <div class="rowItem">
                              限负荷情况:{{ props.row.LOADLIMITING }}
                        </div>
                        <div class="rowItem">
                              运行台次:{{ props.row.RUNTIMES }}
                        </div>
                        <div class="rowItem">
                              月平台交易电量:{{ props.row.MONTHLYTRANSACTION }}
                        </div>
                        <div class="rowItem">
                              月限电量:{{ props.row.MONTHLYPOWERLIMIT }}
                        </div>
                        <div class="rowItem">
                              月限电比:{{ props.row.MONTHLYPOWERLIMITRATIO }}
                        </div>
                         <div class="rowItem">
                              月度计划完成电量指标(万):{{ props.row.MONTHLYPLAN }}
                        </div>
                        <div class="rowItem">
                              全年计划电量完成指标(万):{{ props.row.YEARPOWER }}
                        </div>
                        <div class="rowItem">
                              年限电量:{{ props.row.YEARPOWERLIMIT }}
                        </div>
                         <div class="rowItem">
                              全年计划电量完成指标(%):{{ props.row.YEARPOWERPERCENTAGE }}
                        </div>
                        <div class="rowItem">
                              风场月与地调计量表电量核对线损差额:{{ props.row.LINELOSSDIFFERENCE }}
                        </div>
                        <div class="rowItem">
                              风场月与地调线损计量差额比例（%）:{{ props.row.DIFFERENCERATIO }}
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column prop="FACTORYNAME" label="风场名称"></el-table-column>
                    <el-table-column prop="STAFFONDUTY" label="值班人员"></el-table-column>
                    <el-table-column prop="DAILYPOWER" label="日发电量"></el-table-column>
                    <el-table-column prop="MONTHPOWER" label="月发电量"></el-table-column>
                    <el-table-column prop="ANNUALCUMULATIVEPOWER" label="年发电量"></el-table-column>
                    <el-table-column prop="MONTHELECTRICITY" label="月上网电量"></el-table-column>
                    <el-table-column prop="ANNUALCUMULATIVEPOWERONGRID" label="年上网电量"></el-table-column>
                    <el-table-column prop="UPTIME" label="最后更新时间"></el-table-column>
                    <el-table-column prop="BZ" label="备注"></el-table-column>
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                        <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button>
                        <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="900px" append-to-body top="40px">
            <el-form :model="postInfo" status-icon :rules="postrules"  ref="uForm" label-width="300px" class="demo-uForm">
                <el-form-item label="风场" prop="FACTORYID">
                    <el-select v-model="postInfo.FACTORYID" placeholder="请选择风场" filterable style="width:100%" @change="changeFC">
                            <el-option v-for="item in fcList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="值班人员" prop="STAFFONDUTYID">
                    <el-select v-model="postInfo.STAFFONDUTYID" placeholder="请选择值班人员" filterable style="width:100%" @change="changeUser">
                            <el-option v-for="item in userList" :key="item.SNOWID" :label="item.TRUENAME+'('+item.LOGINNAME+')'" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="日发电量" prop="DAILYPOWER">
                  <el-input v-model="postInfo.DAILYPOWER" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="月发电量" prop="MONTHPOWER">
                  <el-input v-model="postInfo.MONTHPOWER" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="年累计发电量(万)" prop="ANNUALCUMULATIVEPOWER">
                  <el-input v-model="postInfo.ANNUALCUMULATIVEPOWER" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="月上网电量" prop="MONTHELECTRICITY">
                  <el-input v-model="postInfo.MONTHELECTRICITY" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="年累计上网电量（万）" prop="ANNUALCUMULATIVEPOWERONGRID">
                  <el-input v-model="postInfo.ANNUALCUMULATIVEPOWERONGRID" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="风场风速" prop="WINDSPEED">
                  <el-input v-model="postInfo.WINDSPEED" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="计划检修" prop="PLANNEDMAINTENANCE">
                  <el-input v-model="postInfo.PLANNEDMAINTENANCE" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="故障停机" prop="DOWNTIME">
                  <el-input v-model="postInfo.DOWNTIME" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="限负荷停机" prop="LOADLIMITSHUTDOWN">
                  <el-input v-model="postInfo.LOADLIMITSHUTDOWN" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="负荷" prop="LOAD">
                  <el-input v-model="postInfo.LOAD" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="限负荷情况" prop="LOADLIMITING">
                  <el-input v-model="postInfo.LOADLIMITING" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="运行台次" prop="RUNTIMES">
                  <el-input v-model="postInfo.RUNTIMES" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="月平台交易电量" prop="MONTHLYTRANSACTION">
                  <el-input v-model="postInfo.MONTHLYTRANSACTION" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="月限电量" prop="MONTHLYPOWERLIMIT">
                  <el-input v-model="postInfo.MONTHLYPOWERLIMIT" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="月限电比" prop="MONTHLYPOWERLIMITRATIO">
                  <el-input v-model="postInfo.MONTHLYPOWERLIMITRATIO" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="月度计划完成电量指标(万)" prop="MONTHLYPLAN">
                  <el-input v-model="postInfo.MONTHLYPLAN" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="全年计划电量完成指标(万)" prop="YEARPOWER">
                  <el-input v-model="postInfo.YEARPOWER" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="年限电量" prop="YEARPOWERLIMIT">
                  <el-input v-model="postInfo.YEARPOWERLIMIT" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="全年计划电量完成指标(%)" prop="YEARPOWERPERCENTAGE">
                  <el-input v-model="postInfo.YEARPOWERPERCENTAGE" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="风场月与地调计量表电量核对线损差额" prop="LINELOSSDIFFERENCE">
                  <el-input v-model="postInfo.LINELOSSDIFFERENCE" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="风场月与地调线损计量差额比例（%）" prop="DIFFERENCERATIO">
                  <el-input v-model="postInfo.DIFFERENCERATIO" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="备注">
                  <el-input v-model="postInfo.BZ" autocomplete="off"></el-input>
                </el-form-item>
            
                <el-form-item>
                <el-button type="primary" @click="submitPostForm()">提交</el-button>
                <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
  </div>
</template>
<style scoped> 
.rowItem{width: 50%;float:left;overflow: hidden;font-size: 15px;}
</style>
<script>
export default {
  name: 'MRFDL',
  props: {
    // snowid: {
    //   type:String,
    //   default:""
    // },
  },
  data () {
    var NUMBER=(rule, value, callback) => {
        if (value === '') {
          callback(new Error('内容不能为空'));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('内容只能是数字'));
        }else if(value<=0){
            callback(new Error('只能输入大于0的数字'));
        }else {
          callback();
        }
    };
    var FACTORYID=(rule, value, callback) => {
        //console.log(value)
        if (value === '') {
          callback(new Error('请选择风场'));
        }else {
          callback();
        }
    };
    var STAFFONDUTYID=(rule, value, callback) => {
        //console.log(value)
        if (value === '') {
          callback(new Error('请选择值班人员'));
        }else {
          callback();
        }
    };
    var LOADLIMITING=(rule, value, callback) => {
        //console.log(value)
        if (value === '') {
          callback(new Error('请输入限负荷情况'));
        }else {
          callback();
        }
    };
    

      
      return {
        canDo:true,
        
        fc:"",
        up:"",

        fcList:[],
        userList:[],
       

        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,


        doTypeStr:"",
        doType:"",
        postModal:false,
        postInfo:{},
        postrules: {
            NUMBER: [{ validator: NUMBER, trigger: "blur" }],
            FACTORYID: [{ validator: FACTORYID, trigger: "blur" }],
            STAFFONDUTYID: [{ validator: STAFFONDUTYID, trigger: "blur" }],
            LOADLIMITING: [{ validator: LOADLIMITING, trigger: "blur" }],
            DAILYPOWER: [{ validator: NUMBER, trigger: "blur" }],
            MONTHPOWER: [{ validator: NUMBER, trigger: "blur" }],
            ANNUALCUMULATIVEPOWER: [{ validator: NUMBER, trigger: "blur" }],
            MONTHELECTRICITY: [{ validator: NUMBER, trigger: "blur" }],
            ANNUALCUMULATIVEPOWERONGRID: [{ validator: NUMBER, trigger: "blur" }],
            WINDSPEED: [{ validator: NUMBER, trigger: "blur" }],
            PLANNEDMAINTENANCE: [{ validator: NUMBER, trigger: "blur" }],
            DOWNTIME: [{ validator: NUMBER, trigger: "blur" }],
            LOADLIMITSHUTDOWN: [{ validator: NUMBER, trigger: "blur" }],
            LOAD: [{ validator: NUMBER, trigger: "blur" }],
            RUNTIMES: [{ validator: NUMBER, trigger: "blur" }],
            MONTHLYTRANSACTION: [{ validator: NUMBER, trigger: "blur" }],
            MONTHLYPOWERLIMIT: [{ validator: NUMBER, trigger: "blur" }],
            MONTHLYPOWERLIMITRATIO: [{ validator: NUMBER, trigger: "blur" }],
            MONTHLYPLAN: [{ validator: NUMBER, trigger: "blur" }],
            YEARPOWER: [{ validator: NUMBER, trigger: "blur" }],
            YEARPOWERLIMIT: [{ validator: NUMBER, trigger: "blur" }],
            YEARPOWERPERCENTAGE: [{ validator: NUMBER, trigger: "blur" }],
            LINELOSSDIFFERENCE: [{ validator: NUMBER, trigger: "blur" }],
            DIFFERENCERATIO: [{ validator: NUMBER, trigger: "blur" }],
        },
      }
  },
  mounted() {
      this.loadItem=null
      this.getPostList()
      this.getFCList()
      this.getUserList()
  },
  methods:{
    changeFC(e){
      if(e==""){
        this.postInfo.FACTORYNAME=""
      }else{
        for(var i=0;i<this.fcList.length;i++){
          if(this.fcList[i].SNOWID==e){
            this.postInfo.FACTORYNAME = this.fcList[i].NAME
            break;
          }
        }
      }

    },
    changeUser(e){
      if(e==""){
        this.postInfo.STAFFONDUTY=""
      }else{
        for(var i=0;i<this.userList.length;i++){
          if(this.userList[i].SNOWID==e){
            this.postInfo.STAFFONDUTY = this.userList[i].TRUENAME
            break;
          }
        }
      }

    },
    getFCList(){
        var params={}
            var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
            this.$http.post("Factory/GetFactoryWithoutPage", md5_data)
                .then((response) => {
                    this.canDo=true
                    if (response.data.SUCCESS) {
                       this.fcList=response.data.DATA
                        
                    }
                })
    },
    getUserList(){
        var params={
          PAGEINDEX:1,
          PAGESIZE:10000,
        }
            var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
            this.$http.post("UserManage/GetUserList", md5_data)
                .then((response) => {
                    this.canDo=true
                    if (response.data.SUCCESS) {
                       this.userList=response.data.DATA
                        
                    }
                })
    },
    getPostList(){
        this.showLoading("请稍后")

        var upStr=""
        if(this.up!="" && this.up!=null){
          upStr = this.comjs.getDateMinStrByDate(this.up)
        }
        var params={
            PAGEINDEX:this.PageIndex,
            PAGESIZE:this.PageSize,
            FACTORYID:this.fc,
            UPTIME:upStr
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("DailyPower/GetDailyPower", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
        });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    addPost(){
        this.postInfo={
            FACTORYID:"",
            FACTORYNAME:"",
            STAFFONDUTY:"",
            STAFFONDUTYID:"",
            DAILYPOWER:"",
            MONTHPOWER:"",
            ANNUALCUMULATIVEPOWER:"",
            MONTHELECTRICITY:"",
            ANNUALCUMULATIVEPOWERONGRID:"",
            WINDSPEED:"",
            PLANNEDMAINTENANCE:"",
            DOWNTIME:"",
            LOADLIMITSHUTDOWN:"",
            LOAD:"",
            LOADLIMITING:"",
            RUNTIMES:"",
            MONTHLYTRANSACTION:"",
            MONTHLYPOWERLIMIT:"",
            MONTHLYPOWERLIMITRATIO:"",
            MONTHLYPLAN:"",
            YEARPOWER:"",
            YEARPOWERLIMIT:"",
            YEARPOWERPERCENTAGE:"",
            LINELOSSDIFFERENCE:"",
            DIFFERENCERATIO:"",
            BZ:"",
        }
        this.doTypeStr="添加每日发电率"
        this.doType="1"
        this.postModal=true
    },
    sendAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("DailyPower/CreateNewDailyPower", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    editPost(item){
      this.postInfo={
            SNOWID:item.SNOWID,
            FACTORYID:item.FACTORYID,
            FACTORYNAME:item.FACTORYNAME,
            STAFFONDUTY:item.STAFFONDUTY,
            STAFFONDUTYID:item.STAFFONDUTYID,
            DAILYPOWER:item.DAILYPOWER,
            MONTHPOWER:item.MONTHPOWER,
            ANNUALCUMULATIVEPOWER:item.ANNUALCUMULATIVEPOWER,
            MONTHELECTRICITY:item.MONTHELECTRICITY,
            ANNUALCUMULATIVEPOWERONGRID:item.ANNUALCUMULATIVEPOWERONGRID,
            WINDSPEED:item.WINDSPEED,
            PLANNEDMAINTENANCE:item.PLANNEDMAINTENANCE,
            DOWNTIME:item.DOWNTIME,
            LOADLIMITSHUTDOWN:item.LOADLIMITSHUTDOWN,
            LOAD:item.LOAD,
            LOADLIMITING:item.LOADLIMITING,
            RUNTIMES:item.RUNTIMES,
            MONTHLYTRANSACTION:item.MONTHLYTRANSACTION,
            MONTHLYPOWERLIMIT:item.MONTHLYPOWERLIMIT,
            MONTHLYPOWERLIMITRATIO:item.MONTHLYPOWERLIMITRATIO,
            MONTHLYPLAN:item.MONTHLYPLAN,
            YEARPOWER:item.YEARPOWER,
            YEARPOWERLIMIT:item.YEARPOWERLIMIT,
            YEARPOWERPERCENTAGE:item.YEARPOWERPERCENTAGE,
            LINELOSSDIFFERENCE:item.LINELOSSDIFFERENCE,
            DIFFERENCERATIO:item.DIFFERENCERATIO,
            BZ:item.BZ,
        }
        this.doTypeStr="修改每日发电率"
        this.doType="2"
        this.postModal=true
    },
    sendEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("DailyPower/EditDailyPower", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.postModal=false
                     this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    delPost(item){
        if(this.canDo){
            this.$confirm('确定删除？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
            this.sendDel(item.SNOWID)
            }).catch(() => {
            this.$message.info("已取消")    
          });
        }

    },
    sendDel(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({SNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:id})).toUpperCase())
        this.$http.post("DailyPower/DeleteDailyPower", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    submitPostForm() {
        if (this.canDo) {
            //console.log(this.postInfo)
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    if(this.doType=="1"){
                        this.sendAdd()
                    }else if(this.doType=="2"){
                        this.sendEdit()
                    }
                } else {
                    return false;
                }
            });
        }
    },
  }
}
</script>

