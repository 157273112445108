<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">员工通讯录</p>
        </div> 
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                <el-input  placeholder="请输入姓名" class="iw" ></el-input>
                <el-input  placeholder="请输入工号" class="iw" ></el-input>
                <el-input  placeholder="请输入岗位" class="iw" ></el-input>
                <el-input  placeholder="请输入职务" class="iw" ></el-input>
              
                <el-button type="primary">查询</el-button>
                <el-button type="success" class="fr">添加</el-button>
                <el-button type="primary" class="fr">导出Excel</el-button>
                
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    
                    <el-table-column prop="a" label="姓名"></el-table-column>
                    <el-table-column prop="b" label="工号"></el-table-column>
                    <el-table-column prop="c" label="岗位"></el-table-column>
                    <el-table-column prop="d" label="职务"></el-table-column>
                    <el-table-column prop="e" label="工作明细"></el-table-column>  
                    <el-table-column prop="f" label="完成情况"></el-table-column>  
                    <el-table-column prop="g" label="领导意见"></el-table-column>  
                    <el-table-column label="操作" width="140">
                        <template slot-scope="scope">
                            <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button>
                            <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" ></el-pagination>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  name: "",
  data() {
    return {
        canDo:true,
        

        time:"",
        postList:[
            {
                a:"张三",
                b:"001",
                c:"岗位A",
                d:"职务A",
                e:"工作明细...",
                f:"完成情况...",
                g:"领导意见...",
            },
             {
                a:"张三",
                b:"001",
                c:"岗位A",
                d:"职务A",
                e:"工作明细...",
                f:"完成情况...",
                g:"领导意见...",
            },
             {
                a:"张三",
                b:"001",
                c:"岗位A",
                d:"职务A",
                e:"工作明细...",
                f:"完成情况...",
                g:"领导意见...",
            },

        ],
        PageIndex:1,
        PageSize:10,
        Total:3,
    };
  },
  mounted() {
    this.loadItem=null
  },
  methods: {
   editPost(e){
       //
   },
   delPost(e){
        //
   },
   file(e){
       //
   },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style >
</style>