<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">考试管理</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                
                <el-input v-model="name" placeholder="请输入名称" class="iw" ></el-input>
                
                <el-select v-model="group" placeholder="请选择试卷" filterable  class="iw" >
                        <el-option v-for="item in groupList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                </el-select>
                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-button type="success" @click="addPost" class="fr">添加</el-button>
                
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column prop="NAME" label="名称"></el-table-column>
                    <el-table-column prop="MANAGERNAME" label="负责人"></el-table-column>
                    <el-table-column prop="T_QUESTIONGROUPNAME" label="试卷"></el-table-column>
                    <el-table-column prop="EXAMINATIONTIME" label="考试时间"></el-table-column>
                    <el-table-column prop="CREATETIME" label="创建日期"></el-table-column>
                    <el-table-column label="操作" width="160">
                        <template slot-scope="scope">
                            <el-button @click="showUser(scope.row)" type="text" size="small" style="color:#409EFF">考生</el-button>
                            <el-button @click="yjPost(scope.row)" type="text" size="small" style="color:#67C23A">阅卷</el-button>
                            <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button>
                            <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="500px">
            <el-form :model="postInfo" status-icon :rules="postrules"  ref="uForm" label-width="100px" class="demo-uForm">
                <el-form-item label="名称" prop="NAME">
                    <el-input v-model="postInfo.NAME" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="负责人" prop="MANAGERID">
                    <el-select v-model="postInfo.MANAGERID" placeholder="请选择负责人" filterable  style="width:100%;">
                        <el-option v-for="item in userList" :key="item.SNOWID" :label="item.TRUENAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="试卷" prop="QUESTIONGROUPID">
                    <el-select v-model="postInfo.QUESTIONGROUPID" placeholder="请选择试卷" filterable  style="width:100%;">
                        <el-option v-for="item in groupList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="时间" prop="EXAMINATIONTIME">
                    <el-date-picker v-model="postInfo.EXAMINATIONTIME" type="datetime" placeholder="选择日期时间" style="width:100%;"></el-date-picker>
                </el-form-item>
            
                <el-form-item>
                <el-button type="primary" @click="submitPostForm()">提交</el-button>
                <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
       <el-dialog title="考生管理" :visible.sync="userModal" width="1000px"   top="50px" >
            <div class="userTitle">
                <div class="treeTitle">
                    <span>人员选择</span>
                    <el-input placeholder="输入关键字进行过滤" v-model="filterText" style="width:200px;"> </el-input>
                    <el-button type="primary" @click="sendQuery" class="ml10">查询</el-button>    
                </div>
                <div class="checkedTitle">当前已选择</div>
            </div>
            <div class="userOut" :style="{height:dialogMaxHeigh+ 'px'}">
                <div class="userTree">
                    <el-tree :props="props" :load="getList" lazy  ref="tree" node-key="guid"  :filter-node-method="filterNode" draggable >
                        <span class="custom-tree-node" slot-scope="{ node, data }">
                        <span v-if="data.type=='1'"> 
                            <span class="treeName">{{ node.label }}  </span>
                            <span class="checkedAllBtn"   @click.stop="checkUserOrg(data.guid)">全部添加</span> 
                        </span>
                        <span class="lName" v-if="data.type=='2'" @click="checkUserItem(data.oguid,data.guid)">
                            <span>{{ node.label }}</span>
                            <span>({{ data.lname }})</span></span>
                        </span>
                    </el-tree>
                </div>
                <div class="userChecked">
                    <div class="userRow userRowAll">
                        <el-checkbox v-model="selectAllChecked" @change="selectAllCheckedUser">全选/全不选</el-checkbox>
                        <span class="delSelectCheckeds" @click="delSelectCheckeds">删除</span>
                    </div> 
                    
                    <el-checkbox-group v-model="selectDelUserList" @change="selectItemCheckedUser">
                        <el-checkbox   v-for="item in checkedUserList" :key="item.SNOWID" :label="item.SNOWID" class="userRow">{{item.TRUENAME}}({{item.LOGINNAME}})</el-checkbox>
                    </el-checkbox-group>
                   
                </div>
            </div>
            <div class="userBtns">
                <el-button type="primary" @click="submitUserForm()">提交</el-button>
                <el-button @click="userModal = false">取消</el-button>
            </div>
        </el-dialog>

        <el-dialog title="阅卷" :visible.sync="yjModal" width="1200px" :style="{height:coolLeftHeight+ 'px'}" top="30px">
            <div style="overflow:hidden;">
                <el-input v-model="uname" placeholder="请输入考生名称" class="iws" ></el-input>
                <el-button type="primary" @click="getKSUserList()">查询</el-button>
            </div>
            <div style="overflow:hidden;margin:30px auto;">
                    <el-table :data="yjList" stripe style="width: 100%" > 
                        <el-table-column prop="T_USERTRUENAME" label="考生姓名"></el-table-column>
                        <el-table-column prop="STATUS" label="考卷状态">
                            <template slot-scope="scope">
                                <span v-if="scope.row.STATUS==0" style="color:#909399">未答卷</span>
                                <span v-else-if="scope.row.STATUS==1" style="color:#E6A23C">已交卷</span>
                                <span v-else-if="scope.row.STATUS==2" style="color:#409EFF">已批阅</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="CREATETIME" label="创建日期"></el-table-column>
                        <el-table-column label="操作" width="160">
                            <template slot-scope="scope">
                               <el-button @click="toDT(scope.row)" type="text" size="small" style="color:#409EFF" v-if="scope.row.STATUS==1">打分</el-button>
                               <el-button @click="toDT(scope.row)" type="text" size="small" style="color:#409EFF" v-else-if="scope.row.STATUS==2">查看</el-button>
                                <span v-else>/</span>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination background layout="prev, pager, next"  :current-page="PageIndexYJ" :page-size="PageSizeYJ" :total="TotalYJ" class="pg" @current-change="PGChangeYJ"></el-pagination>
            </div>       
        </el-dialog>
    </div>
</template>
<script>
var h=document.body.clientHeight
export default {
  name: "KSGL",
  data() {
    var NAME = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入名称"));
      } else {
        callback();
      }
    };
    var QUESTIONGROUPID = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择试卷"));
      } else {
        callback();
      }
    };
    var EXAMINATIONTIME = (rule, value, callback) => {
      if (!value || value === "") {
        callback(new Error("请选择时间"));
      } else {
        callback();
      }
    };

    var MANAGERID = (rule, value, callback) => {
      if (!value || value === "") {
        callback(new Error("请选择负责人"));
      } else {
        callback();
      }
    };
    return {
        canDo:true,
        group:"",
        name:"",

        groupList:[],

        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,


        doTypeStr:"",
        doType:"",
        postModal:false,
        postInfo:{
            NAME:"",
            QUESTIONGROUPID:"",
            EXAMINATIONTIME:"",
        },
        postrules: {
            NAME: [{ validator: NAME, trigger: "blur" }],
            QUESTIONGROUPID: [{ validator: QUESTIONGROUPID, trigger: "blur" }],
            EXAMINATIONTIME: [{ validator: EXAMINATIONTIME, trigger: "blur" }],
            MANAGERID:[{ validator: MANAGERID, trigger: "blur" }],
        },

        ksItem:null,


        //人员绑定
        nowTID:"",
        filterText:"",
        oigList:{},
        checkedUserList:[],
        userModal:false,


        selectAllChecked:false,
        selectDelUserList:[],
        props: {
          label: 'name',
          children: 'zones',
          isLeaf: 'leaf'
        },


        uname:"",
        yjModal:false,
        PageIndexYJ:1,
        PageSizeYJ:10,
        TotalYJ:0,
        yjList:[],

        coolLeftHeight:0,
        dialogMaxHeigh:0,

        userList:[],
    };
  },
  mounted() {
    this.dialogMaxHeigh = h -300
    this.coolLeftHeight = h-60
    this.loadItem=null
    this.getGroupList()
    this.getUserList()
  },
  methods: {
    getUserList(){
        var params={
          PAGEINDEX:1,
          PAGESIZE:10000,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("UserManage/GetUserList", md5_data)
            .then((response) => {
                this.canDo=true
                if (response.data.SUCCESS) {
                    this.userList=response.data.DATA
                    
                }
            })
    },
    getGroupList(){
        this.showLoading("请稍后")
        var params={
            PAGEINDEX:1,
            PAGESIZE:1000,
            NAME:"",
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("QuestionGroup/GetQuestionGroupList", md5_data)
            .then((response) => {
                this.getPostList()
                if (response.data.SUCCESS) {
                    this.groupList=response.data.DATA
                } 
            })
            .catch((error) => {
              this.getPostList()
        });
    },
    getPostList(){
        var params={
            PAGEINDEX:this.PageIndex,
            PAGESIZE:this.PageSize,
            NAME:this.name,
            QuestionGroupID:this.group,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Examination/GetExaminationList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
        });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    addPost(){
        this.postInfo={
            NAME:"",
            QUESTIONGROUPID:"",
            EXAMINATIONTIME:"",
            MANAGERID:"",
        }
        this.doTypeStr="添加考试"
        this.doType="1"
        this.postModal=true
    },
    sendAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("Examination/CreateExamination", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    editPost(item){
        this.postInfo={
            SNOWID:item.SNOWID,
            NAME:item.NAME,
            QUESTIONGROUPID:item.QUESTIONGROUPID,
            EXAMINATIONTIME:new Date(item.EXAMINATIONTIME),
            MANAGERID:item.MANAGERID,
        }
        this.doTypeStr="修改考试"
        this.doType="2"
        this.postModal=true
    },
    sendEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("Examination/EditExamination", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    delPost(item){
        if(this.canDo){
            this.$confirm('确定删除考试：'+item.NAME+'？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
            this.sendDel(item.SNOWID)
            })
        }

    },
    sendDel(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({SNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:id})).toUpperCase())
        this.$http.post("Examination/DeleteExamination", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },

    submitPostForm() {
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    var date=this.comjs.getDateStrByDate(this.postInfo.EXAMINATIONTIME)
                    this.postInfo.EXAMINATIONTIME = date
                    if(this.doType=="1"){
                        this.sendAdd()
                    }else if(this.doType=="2"){
                        this.sendEdit()
                    }
                } else {
                    return false;
                }
            });
        }
    },

    //考生
    showUser(item){
        this.nowTID=item.SNOWID
        this.getUserByT()
       
    },
    getUserByT(){
        if(this.canDo){
            this.canDo=false
            this.showLoading("请稍后")
            var params = this.nowTID
            var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
            this.$http.post("Examination/GetExaminationsUsers", md5_data)
                .then((response) => {
                    this.canDo=true
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        var l=response.data.DATA
                        this.checkedUserList=[]
                        for(var i=0;i<l.length;i++){
                            this.checkedUserList.push({
                                SNOWID:l[i].SnowID,
                                TRUENAME:l[i].UserName,
                                LOGINNAME:l[i].LoginName,
                            })
                        }
                        
                        this.userModal=true
                        this.selectAllChecked=false
                        this.selectDelUserList=[]
                    } else {
                        this.errorRequest(response.data.DATA,response.data.MSG,true)
                    }
                })
                .catch((error) => {
                    this.$message.error("请求出错")
                    this.hideLoading()
                    this.canDo=true   
                });
        }
        
    },
    getList(node, resolve){
        if(node.data == undefined){
            setTimeout(()=>{
                var md5_data =this.comjs.requestDataEncrypt({}, this.$store.state.token,this.$md5(this.comjs.sortAsc({})).toUpperCase())
                this.$http.post("PositonManage/GetPositionListWithoutPage",md5_data)
                .then((response) => {
                if(response.data.SUCCESS){   
                    var l=response.data.DATA
                    var d=[]
                    for(var i=0;i<l.length;i++){
                        d.push({
                            type:"1",
                            guid:l[i].SNOWID,
                            name: l[i].NAME,
                            leaf: false,
                        })
                        this.oigList[l[i].SNOWID]=[]
                        
                    }
                    resolve(d);
                }else{
                    this.errorRequest(response.data.DATA,response.data.MSG,false)
                }
                })
            },100)
        }else{
            setTimeout(()=>{
                var params={
                    PAGEINDEX:1,
                    PAGESIZE:100,
                    POSITIONID:node.data.guid
                }
                var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
                this.$http.post("UserManage/GetUserList",md5_data)
                .then((response) => {
                if(response.data.SUCCESS){   
                    var l=response.data.DATA
                    
                    var d=[]
                    for(var i=0;i<l.length;i++){
                        d.push({
                            type:"2",
                            guid:l[i].SNOWID,
                            name: l[i].TRUENAME,
                            lname:l[i].LOGINNAME,
                            oguid:node.data.guid,
                            leaf: true,
                        }) 
                        this.oigList[node.data.guid][l[i].SNOWID]=l[i]
                    }
                    resolve(d);
                }else{
                    this.errorRequest(response.data.DATA,response.data.MSG,false)
                }
                })
            },100)
        }


    },
    filterNode(value, data) {
        if (!value) return true;
        return data.name.indexOf(value) !== -1;
    },
    sendQuery(){
      var val=this.filterText
      this.$refs.tree.filter(val)
    },
    checkUserOrg(guid){
        var params={
            PAGEINDEX:1,
            PAGESIZE:100,
            POSITIONID:guid
        }

        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("UserManage/GetUserList",md5_data)
        .then((response) => {
            if(response.data.SUCCESS){   
                var l=response.data.DATA
                for(var i=0;i<l.length;i++){
                    this.checkedUserList.push(l[i])
                }
                this.checkedUserList = this.comjs.removeDuplicateObj(this.checkedUserList,"SNOWID")
            }
        })
    },
    checkUserItem(oid,uid){
        this.checkedUserList.push(this.oigList[oid][uid])
        this.checkedUserList = this.comjs.removeDuplicateObj(this.checkedUserList,"SNOWID")
    },
    delSelectCheckeds(){
        if(this.selectDelUserList.length==[]){
            this.$message.error("请至少选中一个用户！")
        }else{
            var nl=[]
            for(var i=0;i<this.checkedUserList.length;i++){
                var has=false
                for(var j=0;j<this.selectDelUserList.length;j++){
                    if(this.checkedUserList[i].SNOWID == this.selectDelUserList[j]){
                        has=true;
                        break;
                    }
                }
                if(!has){
                   nl.push(this.checkedUserList[i])
                }
            }
            this.checkedUserList = nl
        }
    },
    selectAllCheckedUser(f){
        if(f){
            var cl=[]
            for(var i=0;i<this.checkedUserList.length;i++){
                cl.push(this.checkedUserList[i].SNOWID)
            }
            this.selectDelUserList = cl
        }else{
            this.selectDelUserList=[]
        }
    },
    selectItemCheckedUser(list){
        if(list.length == this.checkedUserList.length){
            this.selectAllChecked=true
        }else{
            this.selectAllChecked=false
        }
    },
    submitUserForm(){
        if(this.canDo){
            this.canDo=false
            this.showLoading("请稍后")
            var nl=[]
            for(var i=0;i<this.checkedUserList.length;i++){
                nl.push(this.checkedUserList[i].SNOWID)
            }
            var params={
                EXAMINATIONID:this.nowTID,
                USERIDList:nl
            }
            var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
            this.$http.post("RelationOfUserAndExamination/AddUsersToExamination", md5_data)
                .then((response) => {
                    this.canDo=true
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.$message.success("已保存")
                        this.userModal=false
                    } else {
                        this.errorRequest(response.data.DATA,response.data.MSG,true)
                    }
                })
                .catch((error) => {
                    this.$message.error("请求出错")
                    this.hideLoading()
                    this.canDo=true   
                });
        }


    },
    



    //阅卷
    yjPost(item){
        this.PageIndexYJ=1
        this.ksItem=item
        this.getSjByKs()
        this.yjModal=true  
        this.uname=""
    },
    getSjByKs(){
        this.showLoading("请稍后")
        var params={
            PAGEINDEX:this.PageIndexYJ,
            PAGESIZE:this.PageSizeYJ,
            EXAMINATIONID:this.ksItem.SNOWID,
            USERID:"",
            UserName:this.uname,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("RelationOfUserAndExamination/GetRelationOfUserAndExaminationList ", md5_data)
            .then((response) => {
                this.hideLoading()
                if (response.data.SUCCESS) {
                    //console.log(response.data)
                    this.yjList=response.data.DATA
                    this.TotalYJ=response.data.TOTALCOUNT
                } else {
                this.yjList=[]
                this.TotalYJ=0
                }
            })
            .catch((error) => {
                this.hideLoading()
                 this.yjList=[]
                this.TotalYJ=0
        });
    },
    getKSUserList(){
        this.PageIndexYJ=1
        this.getSjByKs()
    },
    PGChangeYJ(e){
        this.PageIndexYJ=e
        this.getSjByKs()
    },
    toDT(item){
        window.open("/statics/pages/KJPY.html?token="+this.$store.state.token+"&kid="+item.EXAMINATIONID+"&uid="+item.USERID)
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style scoped>
    .el-tree{background: transparent!important;;}
    .userTitle{overflow: hidden;font-size: 18px;margin-bottom: 20px;}
    .userTitle .treeTitle{width: 45%;float:left;height: 50px;line-height: 50px; overflow: hidden;text-align: left;}
    .userTitle .treeTitle span{margin:0 20px;font-weight: bold;}


    .userTitle .checkedTitle{width: 45%;float:right;height: 50px;line-height: 50px;text-align: center;font-weight: bold;}
    .userOut{overflow: auto;}
    .userOut .userTree{width: 45%;float:left;height: 90%;background-color: #F8F8F9;padding: 20px;}
    .userOut .userChecked{width: 45%;float:right;height: 90%;background-color: #F8F8F9;padding: 20px;}

    .treeName{font-size: 16px;}
    .checkedAllBtn{font-size: 14px;cursor: pointer;color: #67C23A;margin-left: 30px;}
    .treeName:hover{color: #409EFF;}
    .lName{font-size: 14px;color: #409EFF;}


    .userRow{display: block;width:100%;overflow: hidden;font-size: 14px;margin-bottom: 10px;}
    .userRow .el-checkbox{float:left;}
    .delSelectCheckeds{color:#F56C6C;float:right;font-weight: normal;cursor: pointer;}
</style>