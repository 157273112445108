<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">我的考试</p>
        </div> 
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                
                <el-input v-model="name" placeholder="请输入名称" class="iw" ></el-input>
                
                <el-button type="primary" @click="searchPost">查询</el-button>
                
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column prop="T_EXAMINATIONNAME" label="名称"></el-table-column>
                    <el-table-column prop="T_USERTRUENAME" label="考生姓名"></el-table-column>
                    <el-table-column prop="T_EXAMINATIONEXAMINATIONTIME" label="考试时间"></el-table-column>
                    <el-table-column prop="STATUS" label="状态">
                        <template slot-scope="scope">
                            <span v-if="scope.row.STATUS==0" style="color:#909399">未答卷</span>
                            <span v-else-if="scope.row.STATUS==1" style="color:#E6A23C">已交卷</span>
                            <span v-else-if="scope.row.STATUS==2" style="color:#409EFF">已批阅</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="SCORE" label="得分">
                        <template slot-scope="scope">
                            <span v-if="scope.row.STATUS==2">{{scope.row.SCORE}}</span>
                             <span v-else>/</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="CREATETIME" label="创建日期"></el-table-column>
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                            <el-button @click="toDT(scope.row)" type="text" size="small" style="color:#409EFF" v-if="scope.row.STATUS==0">开始答题</el-button>
                            <el-button @click="toDT(scope.row)" type="text" size="small" style="color:#409EFF" v-else>查看答卷</el-button>
                            
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  name: "",
  data() {
    return {
        canDo:true,
        
        name:"",
       

        postList:[
            {
                title:"电力安全培训第三期考试",
                px:"电力安全工作规程",
                yd:47,
                sd:45,
                bz:"张三、李四因出差缺席",
                jkr:"熊大",
                pjcj:"96",
                kssj:"2022-06-07 17:00:00",
                jssj:"2022-06-07 18:00:00:",
            },
            {
                title:"风场设备操作说明第一期考试",
                px:"风场设备操作说明",
                yd:80,
                sd:80,
                bz:"/",
                jkr:"熊二",
                pjcj:"86",
                kssj:"2022-06-07 17:00:00",
                jssj:"2022-06-07 18:00:00:",
            },
            
            

        ],
        PageIndex:1,
        PageSize:10,
        Total:3,
    };
  },
  mounted() {
    this.loadItem=null
    this.getPostList()
  },
  methods: {
    getPostList(){
        this.showLoading("请稍后")
        var params={
            PAGEINDEX:this.PageIndex,
            PAGESIZE:this.PageSize,
            ExaminationName:this.name,
            USERID:this.$store.state.guid,
            UserName:"",
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("RelationOfUserAndExamination/GetRelationOfUserAndExaminationList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
        });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    toDT(item){
        window.open("/statics/pages/KSDT.html?token="+this.$store.state.token+"&kid="+item.EXAMINATIONID)
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style >
</style>