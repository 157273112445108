<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">生产招投标业务</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                
                <el-input v-model="name" placeholder="请输入提交人员" class="iw" ></el-input>
                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-button type="primary" @click="createZTBModal()" class="fr">生产招投标业务申请</el-button>
                
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column prop="PROJECTCODE" label="编号"></el-table-column>
                    <el-table-column prop="SUBMITUSERNAME" label="提交人员"></el-table-column>
                    
                    <el-table-column prop="ALLUNIT" label="计量单位"></el-table-column>
                    <el-table-column prop="APPLYDEPARTMENT" label="填报单位"></el-table-column>
                    <el-table-column prop="PROJECTDESCGG" label="项目说明/规格型号"></el-table-column>
                    <el-table-column prop="MONEYTYPE" label="费用类型"></el-table-column>
                    <el-table-column prop="PROJECTNAME" label="项目名称"></el-table-column>
                    <el-table-column prop="APPLYTIME" label="填报日期"></el-table-column>
                    <el-table-column prop="NEEDDEPARTMENT" label="需用单位（全称）"></el-table-column>
                    <el-table-column prop="PROJECTTYPE" label="项目类别"></el-table-column>
                    <el-table-column prop="APPLYUSERNAME" label="申请人"></el-table-column>
                    <el-table-column prop="NEEDTIME" label="需用日期"></el-table-column>
                    <el-table-column prop="REVIEWMETHOD" label="评审方式"></el-table-column>
                    <el-table-column prop="OLDCGPLAN" label="原采购计划单"></el-table-column>
                    <el-table-column prop="PREPRICE" label="预算价格（含税）"></el-table-column>
                    <el-table-column prop="ALLCOUNT" label="数量"></el-table-column>
                    <el-table-column prop="ISBUDGET" label="是否有预算"></el-table-column>
                    <el-table-column prop="FACTORYLEADEROPINION" label="风场场长审批意见"></el-table-column>
                    <el-table-column prop="MANAGELEADEROPINION" label="分管领导审批意见"></el-table-column>
                    <el-table-column prop="MAINLEADEROPINION" label="总经理审批意见"></el-table-column>
                    <el-table-column prop="DEPARTMENTLEADEROPINION" label="物资计划审核人审批意见"></el-table-column>
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                        <el-button @click="showFiles(scope.row)" type="text" size="small" style="color:#409EFF">相关附件</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog title="流程相关附件" :visible.sync="showFileModal" width="800px" top="50px" :height="dialogMaxHeigh">
               <MFL :processid="nowProcessid"></MFL>
        </el-dialog>
    </div>
</template>
<script>
var h=document.body.clientHeight
import MFL from "../../components/ModelFileList.vue"
export default {
  name: "ZTB",
  components:{
    MFL,
  },
  data() {
    return {
        canDo:true,
        
        name:"",
       

        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,

        dialogMaxHeigh:0,

        nowProcessid:"",
        showFileModal:false,
    };
  },
  mounted() {
     this.dialogMaxHeigh = h -300
    this.loadItem=null
    this.getPostList()
  },
  methods: {
    getPostList(){
        this.showLoading("请稍后")
        var params={
            PAGEINDEX:this.PageIndex,
            PAGESIZE:this.PageSize,
            SUBMITUSERNAME:this.name,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("ZTBApply/GetList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
        });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },

    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    showFiles(item){
        this.nowProcessid=item.PROCESSID
        this.showFileModal=true
    },
    createZTBModal(type){
        this.showLoading("请稍后")
        var params={
            PAGEINDEX:1,
            PAGESIZE:1,
            NAME:"",
            ALIAS:"ZTBAPPLY",
            VISIBILITY:true,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Model/GetModelList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS && response.data.DATA.length>0) {
                var d=response.data.DATA[0]
                window.open("/statics/pages/LCQC.html?mid="+d.SNOWID+"&token="+this.$store.state.token)
            } else {
                this.$message.error("请先配置生产招投标业务申请流程")
            }
            })
            .catch((error) => {
                this.$message.error("请求失败，请稍后再试")
                this.hideLoading()
            }); 
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style >
</style>