<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">技术监督动态检查</p>
        </div> 
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                <el-input  placeholder="请输入标题" class="iw" ></el-input>
              
                <el-button type="primary">查询</el-button>
                <el-button type="success" class="fr">添加</el-button>
                
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column prop="a" label="标题"></el-table-column>
                    <el-table-column prop="k" label="时间"></el-table-column>
                    <el-table-column prop="l" label="备注"></el-table-column>  
                    <el-table-column label="操作" width="140">
                        <template slot-scope="scope">
                            <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button>
                            <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
                            <el-button @click="file(scope.row)" type="text" size="small" style="color:#409EFF">附件</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" ></el-pagination>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  name: "",
  data() {
    return {
        canDo:true,
        time:"",
        postList:[
            {
                a:"标题...",
                k:"2022-08-07",
                l:"备注...",
            },
            {
                a:"标题...",
                k:"2022-08-07",
                l:"备注...",
            },
            {
                a:"标题...",
                k:"2022-08-07",
                l:"备注...",
            },
            {
                a:"标题...",
                k:"2022-08-07",
                l:"备注...",
            },

        ],
        PageIndex:1,
        PageSize:10,
        Total:3,
    };
  },
  mounted() {
    this.loadItem=null
  },
  methods: {
   editPost(e){
       //
   },
   delPost(e){
        //
   },
   file(e){
       //
   },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style >
</style>