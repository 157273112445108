<template>
  <div class="content">
        <div class="optionTitle">模板选择</div>
        <div class="optionCon">
              <el-button type="primary" size="medium" @click="getDocList">资料库</el-button>
              <el-button type="primary" size="medium" @click="getCarList">车辆</el-button>
              <el-button type="primary" size="medium" @click="getUserList">用户</el-button>
        </div>
        <div class="optionTitle">
          数据来源
          <el-button type="text" class="optionCopyBtn" @click="copyStr">复制</el-button>
        </div>
        <div class="optionCon">
              <el-input type="textarea" placeholder="格式如下:[{key:'1',value:'张三'},{key:'2',value:'李四'}]" v-model="str" class="optionTextArea"></el-input>
              
        </div>
        <div class="optionTitle">数据预览</div>
        <div class="optionCon">
            <div style="overflow:hidden" v-if="list.length>0">
                  <div style="overflow:hidden;margin-bottom:10px;">
                       单选按钮：
                       <el-radio-group v-model="radio">
                          <el-radio v-for="ritem in list" :key="ritem.key" :label="ritem.key" >{{ritem.value}}</el-radio>
                       </el-radio-group>
                  </div>
                  <div style="overflow:hidden;margin-bottom:10px;">
                        复选框：
                        <el-checkbox-group v-model="checkAll" style="display:inline-block">
                          <el-checkbox v-for="citem in list" :label="citem.value" :key="citem.key">{{citem.value}}</el-checkbox>
                        </el-checkbox-group>
                  </div>
                  <div style="overflow:hidden;margin-bottom:10px;">
                        下拉框：
                        <el-select placeholder="请选择" v-model="sel">
                          <el-option v-for="sitem in list" :key="sitem.key" :label="sitem.value" :value="sitem.key"></el-option>
                        </el-select>
                  </div>

              </div>
            <div style="overflow:hidden" v-else>
                 <p class="optionErrorStr">数据格式错误，无法预览</p>
            </div>
        </div>
  </div>
</template>
<style>
.optionTextArea  .el-textarea__inner{height: 120px!important;}
</style>
<style scoped> 
.optionTitle{font-size: 16px;font-weight: bold;}
.optionCon{margin: 20px 0;overflow: hidden;position: relative;}

.optionTextArea{font-size: 15px;outline: none;width: 100%;}
.optionCopyBtn{float:right;}
.optionErrorStr{text-align: center;margin: 20px 0;font-size: 15px;color: #909399;}
</style>
<script>
export default {
  name: 'MRFDL',
  props: {
    // snowid: {
    //   type:String,
    //   default:""
    // },
  },
  data () {
   
      return {
        canDo:true,
        str:"",
        list:[],
        radio:"",
        sel:"",
        checkAll:[],
      }
  },
  mounted() {
      this.loadItem=null
  },
  methods:{
    getDocList(){
        this.showLoading("请稍后")
        var params={
            PAGEINDEX:1,
            PAGESIZE:1000,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Doc/GetDocList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS && response.data.DATA.length>0) {
                  var l=[]
                  for(var i=0;i<response.data.DATA.length;i++){
                      var d=response.data.DATA[i]
                      l.push({key:d.SNOWID,value:d.TITLE})
                  }
                  this.str= JSON.stringify(l)
            } 
            })
    },
    getCarList(){
      this.showLoading("请稍后")
        var params={
            PAGEINDEX:1,
            PAGESIZE:1000,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Car/GetCarList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS && response.data.DATA.length>0) {
                  var l=[]
                  for(var i=0;i<response.data.DATA.length;i++){
                      var d=response.data.DATA[i]
                      l.push({key:d.SNOWID,value:d.PLATENUMBER})
                  }
                  this.str= JSON.stringify(l)
            } 
            })
    },
    getUserList(){
      this.showLoading("请稍后")
        var params={}
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("UserManage/GetUserListWithoutPage", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS && response.data.DATA.length>0) {
                  var l=[]
                  for(var i=0;i<response.data.DATA.length;i++){
                      var d=response.data.DATA[i]
                      l.push({key:d.SNOWID,value:d.TRUENAME})
                  }
                  this.str= JSON.stringify(l)
            } 
            })
    },
    copyStr() {
        if(this.str!=''){
            var msg=this.str
            const save = function(e) {
              e.clipboardData.setData('text/plain', msg)
              e.preventDefault() // 阻止默认行为
            }
            const once = {
              once: true
            }
            document.addEventListener('copy', save, once) // 添加一个copy事件,当触发时执行一次,执行完删除
            document.execCommand('copy') // 执行copy方法
            this.$message({ message: '复制成功', type: 'success' })
        }
    }

  },
  watch: {
    str(n,o){
      this.list=[]
      this.radio=""
      this.checkAll=[]
      if(n!=""){
         try {
                var d=eval("(" + n + ")")  
                if(Array.isArray(d) && d.length>0){
                   var f=true
                   for(var i=0;i<d.length;i++){
                      if(d[i].key && d[i].key != undefined && d[i].key!='' && d[i].value && d[i].value != undefined && d[i].value!=''){
                        //
                      }else{
                        f=false
                      }
                   }
                   if(f){
                    this.list=d
                    this.radio= this.list[0].key
                    this.sel=this.list[0].key
                   }
                }
            } catch(e) {
                //
            }
      }
    }
  },
}
</script>

