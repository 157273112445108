<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">生产人员日常培训</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                
                <el-input v-model="con" placeholder="请输入培训内容" class="iw" ></el-input>
                <el-select v-model="uid" placeholder="请选择培训负责人" filterable clearable class="iw">
                        <el-option v-for="item in userList" :key="item.SNOWID" :label="item.TRUENAME+'('+item.LOGINNAME+')'" :value="item.SNOWID" ></el-option>
                </el-select>
                <el-select v-model="pid" placeholder="请选择培训类型" filterable clearable class="iw">
                        <el-option v-for="item in typeList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                </el-select>
                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-button type="success" @click="addPost">添加</el-button>
                <el-button type="warning" @click="showModelType" class="fr">培训分类</el-button>
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column prop="CONTENT" label="培训内容"></el-table-column>
                    <el-table-column prop="MANAGENAME" label="培训负责人"></el-table-column>
                    <el-table-column prop="TRAININGTYPENAME" label="培训类型"></el-table-column>
                    <el-table-column prop="STARTDATE" label="培训时间"></el-table-column>
                    <el-table-column prop="CREATETIME" label="创建日期"></el-table-column>
                    <el-table-column label="操作" width="150">
                        <template slot-scope="scope">
                            <el-button @click="showUser(scope.row)"  type="text" size="small" style="color:#409EFF">人员</el-button>
                            <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button>
                            <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
                            <el-button @click="showResult(scope.row)"  type="text" size="small" style="color:#67C23A">培训结果</el-button>
                            <el-button @click="filePost(scope.row)"  type="text" size="small" style="color:#409EFF">附件</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="500px">
            <el-form :model="postInfo" status-icon :rules="postrules"  ref="uForm" label-width="100px" class="demo-uForm">
                <el-form-item label="负责人" prop="MANAGERID">
                    <el-select v-model="postInfo.MANAGERID" placeholder="请选择培训负责人" filterable style="width:100%">
                            <el-option v-for="item in userList" :key="item.SNOWID" :label="item.TRUENAME+'('+item.LOGINNAME+')'" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="培训内容" prop="CONTENT">
                    <el-input v-model="postInfo.CONTENT" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="培训类型" prop="TRAININGTYPEID">
                    <el-select v-model="postInfo.TRAININGTYPEID" placeholder="请选择培训类型" filterable style="width:100%">
                            <el-option v-for="item in typeList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                 <el-form-item label="培训时间" prop="STARTDATE">
                        <el-date-picker v-model="postInfo.STARTDATE" type="datetime" placeholder="选择日期时间" :clearable="cdp" style="width:100%"></el-date-picker>
                </el-form-item>
                <el-form-item>
                <el-button type="primary" @click="submitPostForm()">提交</el-button>
                <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>



        <el-dialog title="培训分类管理" :visible.sync="typeModal" width="1050px" top="50px">
                <div style="width:100%;overflow:hidden;margin-bottom:20px;">
                    <el-input v-model="typename" placeholder="请输入名称" class="ics" ></el-input>
                    <el-button type="primary" @click="searchType">查询</el-button>
                    <el-button type="success" @click="addType" class="fr">添加分类</el-button>
                </div>
                <el-table :data="typeList" stripe style="width: 100%"  :height="dialogMaxHeigh"> 
                    <el-table-column prop="NAME" label="名称"></el-table-column>
                    <el-table-column prop="CREATETIME" label="创建日期"></el-table-column>
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                            <el-button @click="editType(scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button>
                            <el-button @click="delType(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="prev, pager, next"  :current-page="PageIndexType" :page-size="PageSizeType" :total="TotalType" class="pg pgItem" @current-change="PGChangeType"></el-pagination>
        </el-dialog>
        <el-dialog :title="doTypeStr" :visible.sync="typeModalD" width="500px">
            <el-form :model="typeInfo" status-icon :rules="typerules"  ref="tForm" label-width="100px" class="demo-uForm">
                <el-form-item label="名称" prop="NAME">
                <el-input v-model="typeInfo.NAME" autocomplete="off"></el-input>
                </el-form-item>
            
                <el-form-item>
                <el-button type="primary" @click="submitTypeForm()">提交</el-button>
                <el-button @click="typeModalD = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
       <el-dialog title="人员绑定" :visible.sync="userModal" width="1000px"   top="50px" >
            <div class="userTitle">
                <div class="treeTitle">
                    <span>人员选择</span>
                    <el-input placeholder="输入关键字进行过滤" v-model="filterText" style="width:200px;"> </el-input>
                    <el-button type="primary" @click="sendQuery" class="ml10">查询</el-button>    
                </div>
                <div class="checkedTitle">当前已选择</div>
            </div>
            <div class="userOut" :style="{height:dialogMaxHeigh+ 'px'}">
                <div class="userTree">
                    <el-tree :props="props" :load="getList" lazy  ref="tree" node-key="guid"  :filter-node-method="filterNode" draggable >
                        <span class="custom-tree-node" slot-scope="{ node, data }">
                        <span v-if="data.type=='1'"> 
                            <span class="treeName">{{ node.label }}  </span>
                            <span class="checkedAllBtn"   @click.stop="checkUserOrg(data.guid)">全部添加</span> 
                        </span>
                        <span class="lName" v-if="data.type=='2'" @click="checkUserItem(data.oguid,data.guid)">
                            <span>{{ node.label }}</span>
                            <span>({{ data.lname }})</span></span>
                        </span>
                    </el-tree>
                </div>
                <div class="userChecked">
                    <div class="userRow userRowAll">
                        <el-checkbox v-model="selectAllChecked" @change="selectAllCheckedUser">全选/全不选</el-checkbox>
                        <span class="delSelectCheckeds" @click="delSelectCheckeds">删除</span>
                    </div> 
                    
                    <el-checkbox-group v-model="selectDelUserList" @change="selectItemCheckedUser">
                        <el-checkbox   v-for="item in checkedUserList" :key="item.SNOWID" :label="item.SNOWID" class="userRow">{{item.TRUENAME}}({{item.LOGINNAME}})</el-checkbox>
                    </el-checkbox-group>
                   
                </div>
            </div>
            <div class="userBtns">
                <el-button type="primary" @click="submitUserForm()">提交</el-button>
                <el-button @click="userModal = false">取消</el-button>
            </div>
        </el-dialog>
        <el-dialog title="培训结果管理" :visible.sync="resultModal" width="1050px" top="50px">
                <div style="width:100%;overflow:hidden;margin-bottom:20px;">
                    <el-input v-model="rOption" placeholder="请输入考核意见" class="ics" ></el-input>

                    <el-select v-model="rUser" placeholder="请选参加人员" filterable  class="iws" >
                        <el-option v-for="item in rUserList" :key="item.SNOWID" :label="item.TRUENAME+'('+item.LOGINNAME+')'" :value="item.SNOWID" ></el-option>
                    </el-select>

                    <el-button type="primary" @click="searchResult">查询</el-button>
                    <el-button type="success" @click="addResult" class="fr">添加培训结果</el-button>
                </div>
                <el-table :data="resultList" stripe style="width: 100%"  :height="dialogMaxHeigh"> 
                    <el-table-column prop="TRAININGTYPENAME" label="培训内容"></el-table-column>
                    <el-table-column prop="USERNAME" label="被培训人"></el-table-column>
                    <el-table-column prop="EXECUTETIME" label="培训时间"></el-table-column>
                    <el-table-column prop="SCORE" label="培训得分"></el-table-column>
                    <el-table-column prop="OPINION" label="考核意见"></el-table-column>
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                        <el-button @click="editResult(scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button>
                        <el-button @click="delResult(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="prev, pager, next"  :current-page="PageIndexType" :page-size="PageSizeType" :total="TotalType" class="pg pgItem" @current-change="PGChangeType"></el-pagination>
        </el-dialog>
        <el-dialog :title="doTypeStrRD" :visible.sync="resultDoModal" width="500px">
            <el-form :model="resultInfo" status-icon :rules="resultrules"  ref="rForm" label-width="100px" class="demo-uForm">
                <el-form-item label="培训时间" prop="EXECUTETIME">
                     <el-date-picker v-model="resultInfo.EXECUTETIME" type="datetime" placeholder="选择日期时间" :clearable="cdp" style="width:100%"></el-date-picker>
                </el-form-item>
                <el-form-item label="分数" prop="SCORE">
                    <el-input v-model="resultInfo.SCORE" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="被培训人" prop="USERID">
                    <el-select v-model="resultInfo.USERID" placeholder="请选择被培训人" filterable style="width:100%" @change="changeResultUser">
                            <el-option v-for="item in rUserList" :key="item.SNOWID" :label="item.TRUENAME+'('+item.LOGINNAME+')'" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                 <el-form-item label="考核意见" prop="OPINION">
                    <el-input v-model="resultInfo.OPINION" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item>
                <el-button type="primary" @click="submitResultForm()">提交</el-button>
                <el-button @click="resultDoModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>   
        <el-dialog :title="docFileStr" :visible.sync="chooseFileModal" width="800px"  :append-to-body="true">
            <p class="fileTitle">现有附件：</p>
            <div class="fileBox">
                <div class="fileItem" v-for="item in fileList" :key="item.SNOIWD" :title="item.FILENAME">
                    <p class="name" @click="downFile(item.FILEPATH)">{{item.FILENAME}}</p>
                    <div class="fileBtn">
                        <span @click="downFile(item.PATH)" style="color:#409EFF">下载</span>
                        <span @click="delFile(item.SNOWID)" style="color:#F56C6C">删除</span>
                    </div>
                </div>
                <div v-if="fileList.length==0" class="noFile">该培训还未上传附件，请在下方上传。</div>
            </div>
            <p class="fileTitle" style="margin-bottom:20px;">上传附件：</p>
            <div style="overflow:hidden;">
                <el-upload ref="upload"  class=""  :multiple="true" :limit="5" action="" :http-request="uploadFun">
                    <el-button size="small" type="primary">点击上传</el-button>
                </el-upload>
            </div>
      </el-dialog>
    </div>
</template>
<script>
var h=document.body.clientHeight
export default {
  name: "TJGL",
  data() {
    var NAME = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入名称"));
      } else {
        callback();
      }
    };



    var CONTENT = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入培训内容"));
      } else {
        callback();
      }
    };
    var MANAGERID = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择培训负责人"));
      } else {
        callback();
      }
    };
    var STARTDATE = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择培训时间"));
      } else {
        callback();
      }
    };
    var TRAININGTYPEID = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择培训类型"));
      } else {
        callback();
      }
    };

    var EXECUTETIME = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择培训时间"));
      } else {
        callback();
      }
    };
    var SCORE=(rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入分数'));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('分数只能是数字'));
        }else if(!/^\+?(0|[1-9][0-9]*)$/.test(value)){
            callback(new Error('只能输入大于等于0的整数'));
        }else if(value>100){
             callback(new Error('分数最大为100'));
        }else {
          callback();
        }
    };
    var USERID = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择被培训人"));
      } else {
        callback();
      }
    };
    var OPINION = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入考评意见"));
      } else {
        callback();
      }
    };
    return {
        canDo:true,
        
        
        doTypeStr:"",
        doType:"",


        //培训
        con:"",
        uid:"",
        pid:"",
        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,

        userList:[],


        postModal:false,
        postInfo:{},
        postrules: {
            MANAGERID: [{ validator: MANAGERID, trigger: "blur" }],
            CONTENT: [{ validator: CONTENT, trigger: "blur" }],
            STARTDATE: [{ validator: STARTDATE, trigger: "blur" }],
            TRAININGTYPEID: [{ validator: TRAININGTYPEID, trigger: "blur" }],
        },
        cdp:false,

        //类型
        dialogMaxHeigh:0,
        typeModal:false,

        typename:"",
        typeList:[],
        PageIndexType:1,
        PageSizeType:10,
        TotalType:0,

        doTypeStrR:"1",
        typeModalD:false,
        typeInfo:{
            NAME:"",
        },
        typerules: {
            NAME: [{ validator: NAME, trigger: "blur" }],
        },

        //人员绑定

        nowTID:"",
        filterText:"",
        oigList:{},
        checkedUserList:[],
        userModal:false,


        selectAllChecked:false,
        selectDelUserList:[],
        props: {
          label: 'name',
          children: 'zones',
          isLeaf: 'leaf'
        },

        //培训结果
        tItem:null,
        resultModal:false,
        rUserList:[],
        rUser:"",
        rOption:"",

        PageIndexR:1,
        PageSizeR:10,
		TotalR:0,
        resultList:[],

        resultDoModal:false,
        doTypeStrRD:"",
        doTypeRD:"",
        resultInfo:{},
        resultrules:{
             EXECUTETIME: [{ validator: EXECUTETIME, trigger: "blur" }],
             SCORE: [{ validator: SCORE, trigger: "blur" }],
             USERID: [{ validator: USERID, trigger: "blur" }],
             OPINION: [{ validator: OPINION, trigger: "blur" }],
        },

        //附件
        docFileStr:"",
        checkDocId:"",
        fileList:[],
        chooseFileModal:false,
        fileInfo:{},
    };
  },
  mounted() {
    this.dialogMaxHeigh = h -300
    this.loadItem=null
    this.getPostList()
    this.getUserList()
    this.getPXType()
  },
  methods: {
    /**
     *培训
     */
    getUserList(){
        var params={
            PAGEINDEX:1,
            PAGESIZE:1000,
            TRUENAME:"",
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("UserManage/GetUserList", md5_data)
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.userList=response.data.DATA
                } 
            })
    },
    getPXType(){
        var params={
            PAGEINDEX:1,
            PAGESIZE:500,
            NAME:"",
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Training/GetTrainingType", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.typeList=response.data.DATA
            } 
            
        })
    },
    getPostList(){
        this.showLoading("请稍后")
        var params={
            PAGEINDEX:this.PageIndex,
            PAGESIZE:this.PageSize,
            CONTENT:this.con,
            MANAGERID:this.uid,
            TRAININGTYPEID:this.pid,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Training/GetTraining", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
        });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    addPost(){
        this.postInfo={
            CONTENT:"",
            MANAGERID:"",
            STARTDATE:"",
            TRAININGTYPEID:"",
        }
        this.doTypeStr="添加培训"
        this.doType="1"
        this.postModal=true
    },
    sendAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("Training/CreateNewTraining", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    editPost(item){
        this.postInfo={
            SNOWID:item.SNOWID,
            CONTENT:item.CONTENT,
            MANAGERID:item.MANAGERID,
            STARTDATE:new Date(item.STARTDATE),
            TRAININGTYPEID:item.TRAININGTYPEID,
        }
        this.doTypeStr="修改培训"
        this.doType="2"
        this.postModal=true
    },
    sendEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("Training/EditTraining", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.postModal=false
                   this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    delPost(item){
        if(this.canDo){
            this.$confirm('确定删除培训？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
            this.sendDel(item.SNOWID)
            })
        }

    },
    sendDel(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({SNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:id})).toUpperCase())
        this.$http.post("Training/DeleteTraining", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    submitPostForm() {
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    var date=this.comjs.getDateStrByDate(this.postInfo.STARTDATE)
                    this.postInfo.STARTDATE = date
                    if(this.doType=="1"){
                        this.sendAdd()
                    }else if(this.doType=="2"){
                        this.sendEdit()
                    }
                } else {
                    return false;
                }
            });
        }
    },


    /**
     * 分类
     */
    showModelType(){
        this.typeModal = true
        this.getTypeList()
    },
    getTypeList(){
        this.showLoading("请稍后")
        var params={
            PAGEINDEX:this.PageIndexType,
            PAGESIZE:this.PageSizeType,
            NAME:this.typename,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Training/GetTrainingType", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.typeList=response.data.DATA
                this.TotalType=response.data.TOTALCOUNT
                this.getPXType()
            } else {
                this.typeList=[]
                this.TotalType=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.typeList=[]
                this.TotalType=0
        });
    },
    searchType(){
        this.PageIndexType=1
        this.getTypeList()
    },
    PGChangeType(e){
        this.PageIndexType=e
        this.getTypeList()
    },
    addType(){
        this.typeInfo={
            NAME:"",
        }
        this.doTypeStr="添加培训分类"
        this.doType="1"
        this.typeModalD=true
    },
    sendAddType(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.typeInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.typeInfo)).toUpperCase())
        this.$http.post("Training/CreateNewTrainingType", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.typeModalD=false
                    this.getTypeList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    editType(item){
        this.typeInfo={
            SNOWID:item.SNOWID,
            NAME:item.NAME,
        }
        this.doTypeStr="修改培训分类"
        this.doType="2"
        this.typeModalD=true
    },
    sendEditType(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.typeInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.typeInfo)).toUpperCase())
        this.$http.post("Training/EditTrainingType", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.typeModalD=false
                    this.getTypeList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    delType(item){
        if(this.canDo){
            this.$confirm('确定删除类型：'+item.NAME+'？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
            this.sendDelType(item.SNOWID)
            })
        }

    },
    sendDelType(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({SNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:id})).toUpperCase())
        this.$http.post("Training/DeleteTrainingType", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.getTypeList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },

    submitTypeForm() {
        if (this.canDo) {
            this.$refs.tForm.validate((valid) => {
                if (valid) {
                    if(this.doType=="1"){
                        this.sendAddType()
                    }else if(this.doType=="2"){
                        this.sendEditType()
                    }
                } else {
                    return false;
                }
            });
        }
    },


    showUser(item){
        this.nowTID=item.SNOWID
        this.getUserByT()
       
    },
    getUserByT(){
        if(this.canDo){
            this.canDo=false
            this.showLoading("请稍后")
            var params={
                TRAININGID:this.nowTID,
            }
            var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
            this.$http.post("RelationOfUserAndTraining/GetRelationOfUserAndTrainingList", md5_data)
                .then((response) => {
                    this.canDo=true
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        var l=response.data.DATA
                        this.checkedUserList=[]
                        for(var i=0;i<l.length;i++){
                            this.checkedUserList.push({
                                SNOWID:l[i].USERID,
                                TRUENAME:l[i].TRUENAME,
                                LOGINNAME:l[i].LOGINNAME,
                            })
                        }
                        
                        this.userModal=true
                        this.selectAllChecked=false
                        this.selectDelUserList=[]
                    } else {
                        this.errorRequest(response.data.DATA,response.data.MSG,true)
                    }
                })
                .catch((error) => {
                    this.$message.error("请求出错")
                    this.hideLoading()
                    this.canDo=true   
                });
        }
        
    },
    getList(node, resolve){
        if(node.data == undefined){
            setTimeout(()=>{
                var md5_data =this.comjs.requestDataEncrypt({}, this.$store.state.token,this.$md5(this.comjs.sortAsc({})).toUpperCase())
                this.$http.post("PositonManage/GetPositionListWithoutPage",md5_data)
                .then((response) => {
                if(response.data.SUCCESS){   
                    var l=response.data.DATA
                    var d=[]
                    for(var i=0;i<l.length;i++){
                        d.push({
                            type:"1",
                            guid:l[i].SNOWID,
                            name: l[i].NAME,
                            leaf: false,
                        })
                        this.oigList[l[i].SNOWID]=[]
                        
                    }
                    resolve(d);
                }else{
                    this.errorRequest(response.data.DATA,response.data.MSG,false)
                }
                })
            },100)
        }else{
            setTimeout(()=>{
                var params={
                    PAGEINDEX:1,
                    PAGESIZE:100,
                    POSITIONID:node.data.guid
                }
                var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
                this.$http.post("UserManage/GetUserList",md5_data)
                .then((response) => {
                if(response.data.SUCCESS){   
                    var l=response.data.DATA
                    
                    var d=[]
                    for(var i=0;i<l.length;i++){
                        d.push({
                            type:"2",
                            guid:l[i].SNOWID,
                            name: l[i].TRUENAME,
                            lname:l[i].LOGINNAME,
                            oguid:node.data.guid,
                            leaf: true,
                        }) 
                        this.oigList[node.data.guid][l[i].SNOWID]=l[i]
                    }
                    resolve(d);
                }else{
                    this.errorRequest(response.data.DATA,response.data.MSG,false)
                }
                })
            },100)
        }


    },
    filterNode(value, data) {
        if (!value) return true;
        return data.name.indexOf(value) !== -1;
    },
    sendQuery(){
      var val=this.filterText
      this.$refs.tree.filter(val)
    },
    checkUserOrg(guid){
        var params={
            PAGEINDEX:1,
            PAGESIZE:100,
            POSITIONID:guid
        }

        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("UserManage/GetUserList",md5_data)
        .then((response) => {
            if(response.data.SUCCESS){   
                var l=response.data.DATA
                for(var i=0;i<l.length;i++){
                    this.checkedUserList.push(l[i])
                }
                this.checkedUserList = this.comjs.removeDuplicateObj(this.checkedUserList,"SNOWID")
            }
        })
    },
    checkUserItem(oid,uid){
        this.checkedUserList.push(this.oigList[oid][uid])
        this.checkedUserList = this.comjs.removeDuplicateObj(this.checkedUserList,"SNOWID")
    },
    delSelectCheckeds(){
        if(this.selectDelUserList.length==[]){
            this.$message.error("请至少选中一个用户！")
        }else{
            var nl=[]
            for(var i=0;i<this.checkedUserList.length;i++){
                var has=false
                for(var j=0;j<this.selectDelUserList.length;j++){
                    if(this.checkedUserList[i].SNOWID == this.selectDelUserList[j]){
                        has=true;
                        break;
                    }
                }
                if(!has){
                   nl.push(this.checkedUserList[i])
                }
            }
            this.checkedUserList = nl
        }
    },
    selectAllCheckedUser(f){
        if(f){
            var cl=[]
            for(var i=0;i<this.checkedUserList.length;i++){
                cl.push(this.checkedUserList[i].SNOWID)
            }
            this.selectDelUserList = cl
        }else{
            this.selectDelUserList=[]
        }
    },
    selectItemCheckedUser(list){
        if(list.length == this.checkedUserList.length){
            this.selectAllChecked=true
        }else{
            this.selectAllChecked=false
        }
    },
    submitUserForm(){
        if(this.canDo){
            this.canDo=false
            this.showLoading("请稍后")
            var nl=[]
            for(var i=0;i<this.checkedUserList.length;i++){
                nl.push(this.checkedUserList[i].SNOWID)
            }
            var params={
                TRAININGID:this.nowTID,
                USERIDS:nl
            }
            var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
            this.$http.post("RelationOfUserAndTraining/AddRelationsToTraining", md5_data)
                .then((response) => {
                    this.canDo=true
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.$message.success("已保存")
                        this.userModal=false
                    } else {
                        this.errorRequest(response.data.DATA,response.data.MSG,true)
                    }
                })
                .catch((error) => {
                    this.$message.error("请求出错")
                    this.hideLoading()
                    this.canDo=true   
                });
        }


    },
    //培训结果
    showResult(item){
        if(this.canDo){
            this.tItem=item
            this.getRUserList()
            this.getResult()
        }
    },
    getRUserList(){
        var params={
                TRAININGID:this.tItem.SNOWID,
            }
            var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
            this.$http.post("RelationOfUserAndTraining/GetRelationOfUserAndTrainingList", md5_data)
                .then((response) => {
                    this.canDo=true
                    if (response.data.SUCCESS) {
                       this.rUserList=response.data.DATA
                        
                    }
                })
    },
    getResult(){
        this.showLoading("请稍后")
        var params={
            TRAININGID:this.tItem.SNOWID,
            PAGEINDEX:this.PageIndexR,
            PAGESIZE:this.PageSizeR,
            OPINION:this.rOption,
            USERID:this.rUser,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Training/GetTrainingResult", md5_data)
            .then((response) => {
            this.hideLoading()
            this.resultModal=true
            if (response.data.SUCCESS) {
                this.resultList=response.data.DATA
                this.TotalR=response.data.TOTALCOUNT
            } else {
                this.resultList=[]
                this.TotalR=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.resultList=[]
                this.TotalR=0
                this.resultModal=true
        });
    },
    PGChangeR(e){
        this.PageIndexR=e
        this.getResult()
    },
    searchResult(){
        this.PageIndexR=1
        this.getResult()
    },
    changeResultUser(e){
        for(var i=0;i<this.rUserList.length;i++){
            if(this.rUserList[i].SNOWID == e){
                this.resultInfo.USERNAME = this.rUserList[i].TRUENAME
            }
        }
    },
    addResult(){
        //
        this.resultInfo={
            TRAININGID:this.tItem.SNOWID,
            EXECUTETIME:new Date(this.tItem.STARTDATE),
            SCORE:100,
            USERID:"",
            OPINION:"",
            USERNAME:"",
            TRAININGTYPEID:this.tItem.TRAININGTYPEID,
            TRAININGTYPENAME:this.tItem.CONTENT,
        }
        this.doTypeStrRD="添加培训结果"
        this.doTypeRD="1"
        this.resultDoModal=true
    },
    sendAddResult(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.resultInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.resultInfo)).toUpperCase())
        this.$http.post("Training/CreateNewTrainingResult", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.resultDoModal=false
                    this.getResult()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    editResult(item){
        this.resultInfo={
            SNOWID:item.SNOWID,
            TRAININGID:item.TRAININGID,
            EXECUTETIME:new Date(item.EXECUTETIME),
            SCORE:item.SCORE,
            USERID:item.USERID,
            OPINION:item.OPINION,
            USERNAME:item.USERNAME,
            TRAININGTYPEID:item.TRAININGTYPEID,
            TRAININGTYPENAME:item.TRAININGTYPENAME,
        }
        this.doTypeStrRD="修改培训结果"
        this.doTypeRD="2"
        this.resultDoModal=true
    },
    sendEditResult(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.resultInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.resultInfo)).toUpperCase())
        this.$http.post("Training/EditTrainingResult", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.resultDoModal=false
                    this.getResult()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    delResult(item){
        if(this.canDo){
            this.$confirm('确定删除培训结果？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
            this.sendDelResult(item.SNOWID)
            })
        }

    },
    sendDelResult(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({SNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:id})).toUpperCase())
        this.$http.post("Training/DeleteTrainingResult", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.resultDoModal=false
                    this.getResult()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },


    submitResultForm() {
        if (this.canDo) {
            this.$refs.rForm.validate((valid) => {
                if (valid) {
                    var date=this.comjs.getDateStrByDate(this.resultInfo.EXECUTETIME)
                    this.resultInfo.EXECUTETIME = date
                    if(this.doTypeRD=="1"){
                       this.sendAddResult()
                    }else if(this.doTypeRD=="2"){
                        this.sendEditResult()
                    }
                } else {
                    return false;
                }
            });
        }
    },


    filePost(item){
        this.docFileStr=item.CONTENT+"-附件列表"
        this.checkDocId=item.SNOWID
        this.getDocFiles()
    },
    uploadFun(f){
      this.showLoading("请稍后...")
      var fd = new FormData();
      fd.append("file", f.file);
      var fname=f.file.name
      this.$upload.post("Training/UploadTrainingFile?SNOWID="+this.checkDocId+"&FILENAME="+fname, fd)
        .then((response) => {
          //console.log(response)
         this.hideLoading()
          if (response.data.SUCCESS) {
                this.hideLoading()
                this.$message.success("上传成功")
                this.$refs.upload.clearFiles()
                this.getDocFiles()
          }else{
             this.hideLoading()
             this.$message.error("上传失败")
          }
        })
        .catch((error) => {
         this.hideLoading()
      });
    },
    getDocFiles(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({SNOWID:this.checkDocId}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:this.checkDocId})).toUpperCase())
        this.$http.post("Training/GetTraining", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                   this.fileList=response.data.DATA[0].FILELIST
                   this.chooseFileModal=true
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    downFile(e){
        window.open(e)
    },
    delFile(e){
        if(this.canDo){
            this.$confirm('确定删除附件？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
                this.sendDelFile(e)
            }).catch(() => {
            this.$message.info("已取消")    
          });
        }
    },
    sendDelFile(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({FILESNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({FILESNOWID:id})).toUpperCase())
        this.$http.post("Training/DeleteTrainingFile", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.getDocFiles()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },

  },
  computed: {
  },
  watch: {
  },
};
</script>
<style scoped>
    .el-dialog{overflow: hidden;}
    .pgItem{margin-bottom: 20px;}


    .taskItemBox .el-dialog{overflow: hidden;}
    .taskItemBox  .left .taskItemBoxt{font-size: 16px;height: 40px;line-height: 40px;font-weight: bold;text-align: center;color: #333;}
    .taskItemBox .left{width: 500px;background-color: #f4f4f5;float:left;position: relative;}
    .taskItemBox .left .list{position: absolute;top: 40px;left: 0;right: 0;bottom:0;}
    .taskItemBox .left .tt{font-size: 14px;height: 30px;line-height: 30px;padding: 0 12px;color: #555;margin-top: 10px;}
    .taskItemBox .left .ilist{position: absolute;top: 40px;left: 0;right: 0;bottom:30px; padding: 12px;overflow:auto;}
    .taskItemBox .left .ilist .templeItem{margin-top: 12px;font-size: 14px;overflow: hidden;color: #333333;cursor: pointer;}
    .taskItemBox .left .ilist .templeItem:hover{color: #409EFF;}
    .taskItemBox .left .listMsg{padding: 0 12px;font-size: 14px;position: absolute;left: 0;right:0;bottom: 7px;color: #F56C6C;text-align: center;}

    .taskItemBox .left .msg{font-size: 13px;color: #E6A23C;text-align: center;margin-top: 6px;}

    .taskItemBox  .left .searchBox{overflow: hidden;position: relative;}

    .taskItemBox  .left .searchBox .searchBtn{position: absolute;left: 10px;top: 0px;}

    .taskItemBox .right{width: 640px;background-color: #f4f4f5;margin-left: 20px;float:left;position: relative;}
    .taskItemBox  .right .taskItemBoxt{font-size: 16px;height: 40px;line-height: 40px;font-weight: bold;color: #333;padding: 0 24px;overflow: hidden;}
    .taskItemBox  .right .addTaskTtem{float: right;}

    .taskItemBox  .right .ilist{padding: 12px;overflow:auto;position: absolute;left: 0;right: 0;top: 40px;bottom: 0;}

    .tastItemForm{min-height: 450px;overflow: hidden;position: absolute;left: 0;right:0;top:0;bottom:0; padding-bottom: 50px;}
    .tastItemForm .halfItem{width: 50%;display: inline-block;}
    .tastItemForm .allItem{width: 100%;float:left;}
    .tastItemForm .bottomBtns{position: absolute;right: 10px;bottom:10px;margin-bottom: 0;}

    .el-tree{background: transparent!important;;}
    .userTitle{overflow: hidden;font-size: 18px;margin-bottom: 20px;}
    .userTitle .treeTitle{width: 45%;float:left;height: 50px;line-height: 50px; overflow: hidden;text-align: left;}
    .userTitle .treeTitle span{margin:0 20px;font-weight: bold;}


    .userTitle .checkedTitle{width: 45%;float:right;height: 50px;line-height: 50px;text-align: center;font-weight: bold;}
    .userOut{overflow: auto;}
    .userOut .userTree{width: 45%;float:left;height: 90%;background-color: #F8F8F9;padding: 20px;}
    .userOut .userChecked{width: 45%;float:right;height: 90%;background-color: #F8F8F9;padding: 20px;}

    .treeName{font-size: 16px;}
    .checkedAllBtn{font-size: 14px;cursor: pointer;color: #67C23A;margin-left: 30px;}
    .treeName:hover{color: #409EFF;}
    .lName{font-size: 14px;color: #409EFF;}


    .userRow{display: block;width:100%;overflow: hidden;font-size: 14px;margin-bottom: 10px;}
    .userRow .el-checkbox{float:left;}
    .delSelectCheckeds{color:#F56C6C;float:right;font-weight: normal;cursor: pointer;}

</style>
<style>
    .userRowAll{margin-bottom: 30px!important;}
    .userRowAll .el-checkbox__label{font-size: 16px!important;font-weight: bold;}
</style>
<style scoped>
.fileBox{overflow: hidden;margin: 20px 0;padding: 20px;background-color: #F8F8F9;}
.fileItem{border-radius: 3px;background-color: #FFFFFF;cursor: pointer;padding: 10px;float:left;margin-right: 20px;margin-bottom: 20px;width: 206px;}
.fileItem:nth-of-type(3n){margin-right: 0!important;}
.fileItem .name{font-size: 15px;font-weight: bold;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;color: #444;margin-bottom: 2px;}
.fileItem .keywords{font-size: 14px;font-weight: bold;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;margin-bottom: 5px;color: #909399;}
.fileItem .fileBtn{overflow: hidden;text-align: left;}
.fileItem span{margin-right: 10px;}
.noFile{text-align: center;font-size: 15px;color: #409EFF;}
.fileTitle{font-size: 16px;font-weight: bold;overflow: hidden;}
</style>