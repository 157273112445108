<template>
  <div class="views-main">
    <div class="views-main-title">
      <p class="txt">设备参数维护</p>
    </div>
    <div class="tableBox" style="top:50px;">
      <div class="views-main-search" v-if="activeName=='gq'">
              <el-input placeholder="输入关键字进行过滤" v-model="filterTextA"     style="width:200px;"> </el-input>
              <el-button type="primary" @click="sendQueryTreeA()" class="ml10">查询</el-button>
      </div>
      <div class="views-main-search" v-else>
          <el-input placeholder="输入关键字进行过滤" v-model="filterTextB"     style="width:200px;"> </el-input>
          <el-button type="primary" @click="sendQueryTreeB()" class="ml10">查询</el-button>
      </div>
      <div class="views-main-body">
          <el-tabs v-model="activeName">
            <el-tab-pane  label="高桥风场" name="gq">
              <el-tree :props="props" :load="getListA" lazy  ref="treeA" node-key="snowid" :filter-node-method="filterNode">
                      <span class="custom-tree-node" slot-scope="{ node, data }">
                        <span class="treeName">{{ node.label }}</span>
                        <span>
                          <el-button type="text" size="mini" @click.stop="() => setRun(node, data)" style="color:#19be6b">
                            设置运行状态
                          </el-button>
                          <el-button type="text" size="mini" @click.stop="() => logRun(node, data)" style="color:#ff9900">
                            运行记录
                          </el-button>
                        </span>
                        <span style="margin-left:20px;display:none;">
                          <el-button type="text" size="mini" @click.stop="() => setPower(node, data)" style="color:#19be6b">
                            设置发电量
                          </el-button>
                          <el-button type="text" size="mini" @click.stop="() => logPower(node, data)" style="color:#ff9900">
                            发电量记录
                          </el-button>
                        </span>
                      </span>
                    </el-tree>
            </el-tab-pane>
            <el-tab-pane  label="围屏风场" name="wp">
              <el-tree :props="props" :load="getListB" lazy  ref="treeB" node-key="snowid" :filter-node-method="filterNode">
                      <span class="custom-tree-node" slot-scope="{ node, data }">
                        <span class="treeName">{{ node.label }}</span>
                        <span>
                          <el-button type="text" size="mini" @click.stop="() => setRun(node, data)" style="color:#19be6b">
                            设置运行状态
                          </el-button>
                          <el-button type="text" size="mini" @click.stop="() => logRun(node, data)" style="color:#ff9900">
                            运行记录
                          </el-button>
                        </span>
                        <span style="margin-left:20px">
                          <el-button type="text" size="mini" @click.stop="() => setPower(node, data)" style="color:#19be6b">
                            设置发电量
                          </el-button>
                          <el-button type="text" size="mini" @click.stop="() => logPower(node, data)" style="color:#ff9900">
                            发电量记录
                          </el-button>
                        </span> 
                      </span>
                    </el-tree>
            </el-tab-pane>
          </el-tabs>
      </div>
    </div>
    <el-dialog :title="doListStr" :visible.sync="listModal" width="1050px" top="50px" >
            <div style="width:100%;overflow:hidden;margin-bottom:20px;">
              <el-select v-model="status" placeholder="状态"   clearable class="iww">
                      <el-option label="正常" value="1" ></el-option>
                      <el-option label="故障" value="0" ></el-option>
                      <el-option label="弃风限电" value="2" ></el-option>
              </el-select>
              <el-date-picker v-model="stime" type="date" placeholder="开始日期" class="iww"></el-date-picker>
              <el-date-picker v-model="etime" type="date" placeholder="结束日期" class="iww"></el-date-picker>
              <el-button type="primary" @click="searchPost">查询</el-button>
              <el-button type="success" @click="addPost" class="fr">添加</el-button>
            </div>
            <el-table :data="postList" stripe style="width: 100%" :height="dialogMaxHeigh">
                <el-table-column prop="MACHINENAME" label="设备名称"></el-table-column>
                <el-table-column prop="STATUS" label="状态">
                  <template slot-scope="scope">
                    <span v-if="scope.row.STATUS=='1'" style="color:#19be6b">正常</span>
                    <span v-else-if="scope.row.STATUS=='0'" style="color:#F56C6C">故障</span>
                    <span v-else-if="scope.row.STATUS=='2'" style="color:#E6A23C">弃风限电</span>
                  </template>
                </el-table-column>
                <el-table-column prop="CREATEDATE" label="运行日期">
                  <template slot-scope="scope">
                    <span>{{scope.row.CREATEDATE.split(" ")[0]}}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="OTHERHOURS" label="其他小时数"></el-table-column>
                <el-table-column prop="LOSSPOWER" label="发电量损失电量"></el-table-column>
                <el-table-column prop="LOSSPOWERHOURS" label="电量损失小时数"></el-table-column>
            </el-table>
            <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg pgItem" @current-change="PGChange"></el-pagination>
    </el-dialog>
    <el-dialog :title="doTypeStr" :visible.sync="postModal" width="600px">
        <el-form :model="postInfo" status-icon :rules="postrules"  ref="uForm" label-width="120px" class="demo-uForm">
            <el-form-item label="运行日期" prop="CREATEDATES">
                <el-date-picker v-model="postInfo.CREATEDATES" type="date" placeholder="选择运行日期" style="width:100%;"></el-date-picker>
            </el-form-item>
            <el-form-item label="运行状态" prop="STATUS">
              <el-select v-model="postInfo.STATUS" placeholder="选择运行状态"  filterable  style="width:100%;">
                      <el-option label="正常" value="1" ></el-option>
                      <el-option label="故障" value="0" ></el-option>
                      <el-option label="弃风限电" value="2" ></el-option>
              </el-select>
            </el-form-item>  
            <el-form-item label="其他小时数" prop="OTHERHOURS">
              <el-input v-model="postInfo.OTHERHOURS" autocomplete="off"></el-input>
            </el-form-item>  
            <el-form-item label="发电量损失电量" prop="LOSSPOWER">
              <el-input v-model="postInfo.LOSSPOWER" autocomplete="off"></el-input>
            </el-form-item>  
            <el-form-item label="电量损失小时数" prop="LOSSPOWERHOURS">
              <el-input v-model="postInfo.LOSSPOWERHOURS" autocomplete="off"></el-input>
            </el-form-item>  
            <el-form-item>
              <el-button type="primary" @click="submitPostForm()">提交</el-button>
              <el-button @click="postModal = false">取消</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
    

    <el-dialog :title="doListStrP" :visible.sync="listModalP" width="1050px" top="50px" >
            <div style="width:100%;overflow:hidden;margin-bottom:20px;">
              <el-date-picker v-model="stimeP" type="date" placeholder="开始日期" class="iww"></el-date-picker>
              <el-date-picker v-model="etimeP" type="date" placeholder="结束日期" class="iww"></el-date-picker>
              <el-button type="primary" @click="searchPostP">查询</el-button>
              <el-upload
                    action=""
                    name="file"
                    class="fr"
                    :multiple="false"
                    :http-request="customRequest"
                    :showUploadList="false"
                    accept=".xlsx"
                >
                <el-button type="primary">上传</el-button>
                </el-upload>
              <el-button type="success" @click="addPostP" class="fr">添加</el-button>
            </div>
            <el-table :data="postListP" stripe style="width: 100%" :height="dialogMaxHeigh">
                <el-table-column prop="MACHINENAME" label="设备名称"></el-table-column>
                <el-table-column prop="DAYPOWER" label="每日发电量"></el-table-column>
                <el-table-column prop="DAYELECTRICITY" label="日上网电量"></el-table-column>
                <el-table-column prop="DAYPOWERLIMIT" label="日限电量"></el-table-column>
                <el-table-column prop="DAY" label="日期">
                  <template slot-scope="scope">
                    <span>{{scope.row.DAY.split(" ")[0]}}</span>
                  </template>
                </el-table-column>
                <el-table-column label="操作" width="160">
                  <template slot-scope="scope">
                    <el-button type="text" size="small" @click="editPostP(scope.row)" style="color:#ff9900">编辑</el-button>
                  </template>
                </el-table-column>
                
            </el-table>
            <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndexP" :page-size="PageSizeP" :total="TotalP" class="pg pgItem" @current-change="PGChangeP"></el-pagination>
    </el-dialog>
    <el-dialog :title="doTypeStrP" :visible.sync="postModalP" width="600px">
        <el-form :model="postInfoP" status-icon :rules="postrulesP"  ref="pForm" label-width="100px" class="demo-uForm">
            <el-form-item label="日期" prop="DAYS">
                <el-date-picker v-model="postInfoP.DAYS" type="date" placeholder="选择日期" style="width:100%;"></el-date-picker>
            </el-form-item>
            <el-form-item label="每日发电量" prop="DAYPOWER">
              <el-input v-model="postInfoP.DAYPOWER" autocomplete="off"></el-input>
            </el-form-item>  
            <el-form-item label="日上网电量" prop="DAYELECTRICITY">
              <el-input v-model="postInfoP.DAYELECTRICITY" autocomplete="off"></el-input>
            </el-form-item>  
            <el-form-item label="日限电量" prop="DAYPOWERLIMIT">
              <el-input v-model="postInfoP.DAYPOWERLIMIT" autocomplete="off"></el-input>
            </el-form-item>  
            <el-form-item>
              <el-button type="primary" @click="submitPostFormP()">提交</el-button>
              <el-button @click="postModalP = false">取消</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
  </div>
</template>
<script>
import { utimes } from 'fs';

var h=document.body.clientHeight
export default {
  name: "RUN",
  data() {
    var CREATEDATES = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择运行时间"));
      } else {
        callback();
      }
    };
    var STATUS = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择运行状态"));
      } else {
        callback();
      }
    };


    var OTHERHOURS=  (rule, value, callback) => {
      if (value === '') {
          callback(new Error('请输入其他小时数'));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('其他小时数只能是数字'));
        }else if(value<0){
            callback(new Error('其他小时数不能小于0'));
        }else {
          callback();
        }
    };
    var LOSSPOWER=  (rule, value, callback) => {
      if (value === '') {
          callback(new Error('请输入发电量损失电量'));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('发电量损失电量只能是数字'));
        }else if(value<0){
            callback(new Error('发电量损失电量不能小于0'));
        }else {
          callback();
        }
    };
    var LOSSPOWERHOURS=  (rule, value, callback) => {
      if (value === '') {
          callback(new Error('请输入电量损失小时数'));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('电量损失小时数只能是数字'));
        }else if(value<0){
            callback(new Error('电量损失小时数不能小于0'));
        }else {
          callback();
        }
    };


    var DAYPOWER=  (rule, value, callback) => {
      if (value === '') {
          callback(new Error('请输入每日发电量'));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('每日发电量只能是数字'));
        }else if(value<0){
            callback(new Error('每日发电量不能小于0'));
        }else {
          callback();
        }
    };
    var DAYELECTRICITY=  (rule, value, callback) => {
      if (value === '') {
          callback(new Error('请输入日上网电量'));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('日上网电量只能是数字'));
        }else if(value<0){
            callback(new Error('日上网电量不能小于0'));
        }else {
          callback();
        }
    };
    var DAYPOWERLIMIT=  (rule, value, callback) => {
      if (value === '') {
          callback(new Error('请输入日限电量'));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('日限电量只能是数字'));
        }else if(value<0){
            callback(new Error('日限电量不能小于0'));
        }else {
          callback();
        }
    };
    var DAYS = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择日期"));
      } else {
        callback();
      }
    };
    return {
      canDo:true,

      factoryList:[],
      activeName:"gq",

      props: {
          label: 'name',
          children: 'child',
          isLeaf: 'leaf'
      },
      filterTextA:"",
      factoryA:"1555428784377827328",
      filterTextB:"",
      factoryB:"1555437816215900160",

      nowMachine:null,
      //运行
      doTypeStr:"",
      postModal:false,
      postInfo:{},
      postrules: {
        STATUS: [{ validator: STATUS, trigger: "blur" }],
        CREATEDATES: [{ validator: CREATEDATES, trigger: "blur" }],
        OTHERHOURS: [{ validator: OTHERHOURS, trigger: "blur" }],
        LOSSPOWER: [{ validator: LOSSPOWER, trigger: "blur" }],
        LOSSPOWERHOURS: [{ validator: LOSSPOWERHOURS, trigger: "blur" }],
      },

      status:"",
      stime:"",
      etime:"",

      doListStr:"",
      listModal:false,
      postList:[],
      PageIndex:1,
      PageSize:10,
      Total:0,

      dialogMaxHeigh:0,
      //发电量
      doTypeStrP:"",
      postModalP:false,
      postInfoP:{},
      postrulesP: {
        DAYPOWER: [{ validator: DAYPOWER, trigger: "blur" }],
        DAYELECTRICITY: [{ validator: DAYELECTRICITY, trigger: "blur" }],
        DAYPOWERLIMIT: [{ validator: DAYPOWERLIMIT, trigger: "blur" }],
        DAYS: [{ validator: DAYS, trigger: "blur" }],
      },

      stimeP:"",
      etimeP:"",

      doListStrP:"",
      listModalP:false,
      postListP:[],
      PageIndexP:1,
      PageSizeP:10,
      TotalP:0,



      
    };
  },
  mounted() {
    this.dialogMaxHeigh = h -300
    this.loadItem=null
    //this.getFactoryList()
  },
  methods: {

    /**
     * 风场
     */
    getFactoryList(){
        var md5_data =this.comjs.requestDataEncrypt({}, this.$store.state.token,this.$md5(this.comjs.sortAsc({})).toUpperCase())
        this.$http.post("Factory/GetFactoryWithoutPage", md5_data)
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.factoryList=response.data.DATA  
                    for(var i=0;i<this.factoryList.length;i++){
                      var d=this.factoryList[i]
                      if(d.NAME.indexOf("高桥")!=-1){
                          this.factoryA=d.SNOWID
                      }else if(d.NAME.indexOf("围屏")!=-1){
                          this.factoryB=d.SNOWID
                      }
                    }
                } 
            })
    },
    filterNode(value, data) {
        if (!value) return true;
        return data.name.indexOf(value) !== -1;
    },
    sendQueryTreeA(){
      var val=this.filterTextA
      this.$refs.treeA.filter(val)
    },
    getListA(node, resolve){
        var pid="0"
        if(node != undefined && node.data != undefined){
            pid = node.data.snowid
        }
        setTimeout(()=>{
            var param={
              PAGEINDEX:1,
              PAGESIZE:20000,
              PARENTID:pid,
              FACTORYID:this.factoryA,
            }
            var md5_data =this.comjs.requestDataEncrypt(param, this.$store.state.token,this.$md5(this.comjs.sortAsc(param)).toUpperCase())
            this.$http.post("Machine/GetMachineList",md5_data)
            .then((response) => {
              if(response.data.SUCCESS){   
                  var l=response.data.DATA
                  var nl=[]
                  for(var i=0;i<l.length;i++){
                    var f=false
                    if(l[i].CHILDCOUNT==0){
                      f=true
                    }
                     nl.push({
                      snowid:l[i].SNOWID,
                      name:l[i].NAME,
                      child:[],
                      leaf:f
                     })
                  }
                  resolve(nl);
              }else{
                this.errorRequest(response.data.DATA,response.data.MSG,false)
              }
            })
          },100)

    },
    sendQueryTreeB(){
      var val=this.filterTextB
      this.$refs.treeB.filter(val)
    },
    getListB(node, resolve){
        var pid="0"
        if(node != undefined && node.data != undefined){
            pid = node.data.snowid
        }
        setTimeout(()=>{
            var param={
              PAGEINDEX:1,
              PAGESIZE:20000,
              PARENTID:pid,
              FACTORYID:this.factoryB,
            }
            var md5_data =this.comjs.requestDataEncrypt(param, this.$store.state.token,this.$md5(this.comjs.sortAsc(param)).toUpperCase())
            this.$http.post("Machine/GetMachineList",md5_data)
            .then((response) => {
              if(response.data.SUCCESS){   
                  var l=response.data.DATA
                  var nl=[]
                  for(var i=0;i<l.length;i++){
                    var f=false
                    if(l[i].CHILDCOUNT==0){
                      f=true
                    }
                     nl.push({
                      snowid:l[i].SNOWID,
                      name:l[i].NAME,
                      child:[],
                      leaf:f
                     })
                  }
                  resolve(nl);
              }else{
                this.errorRequest(response.data.DATA,response.data.MSG,false)
              }
            })
          },100)

    },

    //运行状态

    setRun(node,data){
        this.nowMachine=node.data
        this.postInfo={
          MACHINEID:node.data.snowid,
          CREATEDATES:"",
          STATUS:"",
          OTHERHOURS:"",
          LOSSPOWER:"",
          LOSSPOWERHOURS:"",
        }
        this.doTypeStr=node.data.name+"-设置运行状态"
        this.postModal=true
    },
    submitPostForm() {
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    this.sendSet()
                } else {
                    return false;
                }
            });
        }
    },
    sendSet(){
        this.canDo=false
        this.showLoading("请稍后")
        var params={
          MACHINEID:this.postInfo.MACHINEID,
          CREATEDATE:this.comjs.getDateMinStrByDate(this.postInfo.CREATEDATES),
          STATUS:this.postInfo.STATUS,
          OTHERHOURS:this.postInfo.OTHERHOURS,
          LOSSPOWER:this.postInfo.LOSSPOWER,
          LOSSPOWERHOURS:this.postInfo.LOSSPOWERHOURS,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("MachineRunLog/CreateMachineRunLog", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.postModal=false
                    if(this.listModal){
                      this.getPostList()
                    }
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    logRun(node,data){
      this.status=""
      this.etime=""
      this.stime=""
      this.nowMachine=node.data
      this.searchPost()
      this.doListStr=node.data.name+"-运行状态"
      this.listModal=true
    },
    getPostList(){
        this.showLoading("请稍后")
        var st=""
        var et=""
        if(this.stime && this.stime!=""){
          st=this.comjs.getDateMinStrByDate(this.stime)
        }
        if(this.etime && this.etime!=""){
          et=this.comjs.getDateMinStrByDate(this.etime)
        }
        var params={
            PAGEINDEX:this.PageIndex,
            PAGESIZE:this.PageSize,
            MACHINEID:this.nowMachine.snowid,
            STATUS:this.status,
            STARTDATE:st,
            ENDDATE:et,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("MachineRunLog/GetMachineRunLogList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
        });
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    addPost(){
      this.postInfo={
          MACHINEID:this.nowMachine.snowid,
          CREATEDATES:"",
          STATUS:"",
        }
        this.doTypeStr=this.nowMachine.name+"-设置运行状态"
        this.postModal=true
    },

    //发电量


    setPower(node,data){
        var  fid=""
        if(this.activeName =="gq"){
          fid=this.factoryA
        }else{
          fid=this.factoryB
        }
        this.nowMachine=node.data
        this.postInfoP={
          MACHINESNOWID:node.data.snowid,
          FACTORYSNOWID:fid,
          DAYPOWER:"",
          DAYELECTRICITY:"",
          DAYPOWERLIMIT:"",
          DAYS:"",
        }
        this.doTypeStrP=node.data.name+"-设置每日发电量"
        this.postModalP=true
    },
    submitPostFormP() {
        if (this.canDo) {
            this.$refs.pForm.validate((valid) => {
                if (valid) {
                    this.sendSetPower()
                } else {
                    return false;
                }
            });
        }
    },
    sendSetPower(){
        this.canDo=false
        this.showLoading("请稍后")
        var params={
          FACTORYSNOWID:this.postInfoP.FACTORYSNOWID,
          MACHINESNOWID:this.postInfoP.MACHINESNOWID,
          DAY:this.comjs.getDateMinStrByDate(this.postInfoP.DAYS),
          DAYPOWER:this.postInfoP.DAYPOWER,
          DAYELECTRICITY:this.postInfoP.DAYELECTRICITY,
          DAYPOWERLIMIT:this.postInfoP.DAYPOWERLIMIT,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("DailyPower/CreateOrEditDailyPower", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.postModalP=false
                    if(this.listModalP){
                      this.getPostListP()
                    }
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    logPower(node,data){
      this.etimeP=""
      this.stimeP=""
      this.nowMachine=node.data
      this.searchPostP()
      this.doListStrP=node.data.name+"-发电量记录"
      this.listModalP=true
    },
    getPostListP(){
        this.showLoading("请稍后")
        var st=""
        var et=""
        if(this.stimeP && this.stimeP!=""){
          st=this.comjs.getDateMinStrByDate(this.stimeP)
        }
        if(this.etimeP && this.etimeP!=""){
          et=this.comjs.getDateMinStrByDate(this.etimeP)
        }
        var params={
            PageIndex:this.PageIndexP,
            PageSize:this.PageSizeP,
            MACHINEID:this.nowMachine.snowid,
            FACTORYSNOWID:"",
            STARTDATE:st,
            ENDDATE:et,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("DailyPower/GetDayPowerList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postListP=response.data.DATA
                this.TotalP=response.data.TOTALCOUNT
            } else {
                this.postListP=[]
                this.TotalP=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postListP=[]
                this.TotalP=0
        });
    },
    PGChangeP(e){
        this.PageIndexP=e
        this.getPostListP()
    },
    searchPostP(){
        this.PageIndexP=1
        this.getPostListP()
    },
    addPostP(){
      var  fid=""
        if(this.activeName =="gq"){
          fid=this.factoryA
        }else{
          fid=this.factoryB
        }
      this.postInfoP={
          FACTORYSNOWID:fid,
          MACHINESNOWID:this.nowMachine.snowid,
          DAYPOWER:"",
          DAYELECTRICITY:"",
          DAYPOWERLIMIT:"",
          DAYS:"",
        }
        this.doTypeStrP=this.nowMachine.name+"-设置每日发电量"
        this.postModalP=true
    },
    editPostP(item){
      this.postInfoP={
          MACHINESNOWID:item.MACHINESNOWID,
          DAYPOWER:item.DAYPOWER,
          DAYELECTRICITY:item.DAYELECTRICITY,
          DAYPOWERLIMIT:item.DAYPOWERLIMIT,
          DAYS:new Date(item.DAY),
          FACTORYSNOWID:item.FACTORYSNOWID,
        }
        this.doTypeStrP=this.nowMachine.name+"-设置每日发电量"
        this.postModalP=true
    },
    customRequest(data){
        this.showLoading("请稍后...")
        var formdata = new FormData();
        formdata.append('file', data.file);
        this.$upload.post("DailyPower/ExportData?MachineID="+this.nowMachine.snowid, formdata)
        .then(resJson => {
                this.loading = false
                if (resJson.data.SUCCESS) {
                        this.hideLoading()
                        this.$message.success(resJson.data.MSG)

                        this.getPostList()
                        //this.errormsg = resJson.data.MSG.split('*')
                    } else {
                        this.hideLoading()
                        this.$message.error("上传失败")
                        //this.errormsg = resJson.data.MSG.split('*')
                    }
                })
                .catch((error) => {
                this.hideLoading()
                });
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style >
  .el-tree-node{margin-top: 20px;}
  .treeName{font-size: 16px;margin-right: 5px;}

  .funTreeRow{width: 500px;}
  .funtreeName{font-size: 15px;margin-right: 30px;}
  .funItemList{float:right;}
  .managerName{font-size: 12px;margin-right: 20px;color: #409EFF;}
  .custom-tree-node{display: block;}
  .el-checkbox{margin-right: 15px!important;}
  .el-checkbox__label{padding-left: 5px!important;}
</style>
