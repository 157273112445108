<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">项目统计报表管理</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                <el-date-picker v-model="year" type="year" placeholder="选择年" class="iws"></el-date-picker>
                <el-select v-model="fid" placeholder="选择风场"  filterable class="iws" clearable>
                            <el-option v-for="item in factoryList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-button type="success" @click="addPost" class="fr">添加</el-button>
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column prop="NAME" label="名称"></el-table-column>
                    <el-table-column prop="YEAR" label="年度"></el-table-column>
                    <el-table-column prop="FACTORYSNOWID" label="风场">
                        <template slot-scope="scope">
                            <span v-if="scope.row.FACTORYSNOWID=='1555437816215900160'">大唐围屏风场</span>
                            <span v-else-if="scope.row.FACTORYSNOWID=='1555428784377827328'">大唐高桥风场</span>
                            <span v-else>/</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="COST" label="千瓦造价(万元)"></el-table-column>
                    <el-table-column prop="PROFIT" label="千瓦利润(万元)"></el-table-column>
                    <el-table-column prop="SUPERVALUE" label="人均产值(万元)"></el-table-column>
                    <el-table-column prop="PRACTITIONER" label="年平均从业人员(人)"></el-table-column>
                    <el-table-column prop="INSTALLEDCAPACITY" label="总装机量(千瓦)"></el-table-column>
                    <el-table-column prop="CREATETIME" label="创建时间"></el-table-column>
                    <el-table-column label="操作" width="120">
                        <template slot-scope="scope">
                            <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button>
                            <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
                            <el-button @click="filePost(scope.row)"  type="text" size="small" style="color:#409EFF">附件</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="1000px" >
            <el-form :model="postInfo" status-icon :rules="postrules"  ref="uForm" label-width="200px" class="demo-uForm">
                <el-form-item label="名称" prop="NAME">
                    <el-input v-model="postInfo.NAME" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="年度" prop="YEARS">
                    <el-date-picker v-model="postInfo.YEARS" type="year" placeholder="选择年" style="width:100%;"></el-date-picker>
                </el-form-item>
                <el-form-item label="风场" prop="FACTORYSNOWID">
                    <el-select v-model="postInfo.FACTORYSNOWID" placeholder="请选择风场"  filterable style="width:100%;">
                            <el-option v-for="item in factoryList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="千瓦造价(万元)" prop="COST">
                    <el-input v-model="postInfo.COST" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="千瓦利润(万元)" prop="PROFIT">
                    <el-input v-model="postInfo.PROFIT" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="人均产值(万元)" prop="SUPERVALUE">
                    <el-input v-model="postInfo.SUPERVALUE" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="年平均从业人员(人)" prop="PRACTITIONER">
                    <el-input v-model="postInfo.PRACTITIONER" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="总装机量(千瓦)" prop="INSTALLEDCAPACITY">
                    <el-input v-model="postInfo.INSTALLEDCAPACITY" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item>
                <el-button type="primary" @click="submitPostForm()">提交</el-button>
                <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog :title="docFileStr" :visible.sync="chooseFileModal" width="800px"  :append-to-body="true">
            <p class="fileTitle">现有附件：</p>
            <div class="fileBox">
                <div class="fileItem" v-for="item in fileList" :key="item.SNOIWD" :title="item.FILENAME">
                    <p class="name" @click="downFile(item.Url)">{{item.FILENAME}}</p>
                    <div class="fileBtn">
                        <span @click="downFile(item.Url)" style="color:#409EFF">预览</span>
                        <span @click="delFile(item.SNOWID)" style="color:#F56C6C">删除</span>
                    </div>
                </div>
                <div v-if="fileList.length==0" class="noFile">该统计还未上传附件，请在下方上传。</div>
            </div>
            <p class="fileTitle" style="margin-bottom:20px;">上传附件：</p>
            <div style="overflow:hidden;">
                <el-upload ref="upload"  class=""  :multiple="true" :limit="5" action="" :http-request="uploadFun" :before-upload="beforeAvatarUpload" accept="image/jpeg,image/png,application/pdf">
                    <el-button size="small" type="primary">点击上传</el-button>
                </el-upload>
            </div>
        </el-dialog>
    </div>
</template>
<script>
export default {
  name: "PostManage",
  data() {
    var NAME = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入名称"));
      } else {
        callback();
      }
    };
    var FACTORYSNOWID = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择风场"));
      } else {
        callback();
      }
    };
    var YEARS = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请选择年份"));
      } else {
        callback();
      }
    };
    var COST=(rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入千瓦造价'));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('千瓦造价只能是数字'));
        }else {
          callback();
        }
    };
    var PROFIT=(rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入千瓦利润'));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('千瓦利润只能是数字'));
        }else {
          callback();
        }
    };
    var SUPERVALUE=(rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入人均产值'));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('人均产值只能是数字'));
        }else {
          callback();
        }
    };
    var PRACTITIONER=(rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入年平均从业人员'));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('年平均从业人员只能是数字'));
        }else if(!/^\+?(0|[1-9][0-9]*)$/.test(value)){
            callback(new Error('只能输入大于等于0的整数'));
        }else {
          callback();
        }
    };
    var INSTALLEDCAPACITY=(rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入总装机量'));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('总装机量只能是数字'));
        }else {
          callback();
        }
    };
    return {
        canDo:true,
        name:"",
        factoryList:[],
        year:null,
        fid:"",

        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,


        doTypeStr:"",
        doType:"",
        postModal:false,
        postInfo:{},
        postrules: {
            NAME: [{ validator: NAME, trigger: "blur" }],
            YEARS: [{ validator: YEARS, trigger: "blur" }],
            COST: [{ validator: COST, trigger: "blur" }],
            PROFIT: [{ validator: PROFIT, trigger: "blur" }],
            SUPERVALUE: [{ validator: SUPERVALUE, trigger: "blur" }],
            PRACTITIONER: [{ validator: PRACTITIONER, trigger: "blur" }],
            INSTALLEDCAPACITY: [{ validator: INSTALLEDCAPACITY, trigger: "blur" }],
            FACTORYSNOWID: [{ validator: FACTORYSNOWID, trigger: "blur" }],
        },

        docFileStr:"",
        checkDocId:"",
        fileList:[],
        chooseFileModal:false,

    };
  },
  mounted() {
    this.loadItem=null
    this.getFactoryList()
    this.getPostList()
  },
  methods: {
    getFactoryList(){
        this.showLoading("请稍后")
        var params={}
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Factory/GetFactoryWithoutPage", md5_data)
            .then((response) => {
            this.hideLoading()
                if (response.data.SUCCESS) {
                    this.factoryList=response.data.DATA
                } 
            })
    },
    getPostList(){
        this.showLoading("请稍后")
        var year=""
        if(this.year){
            year=this.year.getFullYear()
        }
        var params={
            PAGEINDEX:this.PageIndex,
            PAGESIZE:this.PageSize,
            year:year,
            FactorySnowID:this.fid,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("ProjectCost/GetDataList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
        });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    addPost(){
        this.postInfo={
            NAME:"",
            YEARS:null,
            COST:"",
            PROFIT:"",
            SUPERVALUE:"",
            PRACTITIONER:"",
            INSTALLEDCAPACITY:"",
            FACTORYSNOWID:"",
        }
        this.doTypeStr="添加项目统计报表"
        this.doType="1"
        this.postModal=true
    },
    sendAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        var params={
            NAME:this.postInfo.NAME,
            YEAR:this.postInfo.YEARS.getFullYear(),
            COST:parseFloat(this.postInfo.COST),
            PROFIT:parseFloat(this.postInfo.PROFIT),
            SUPERVALUE:parseFloat(this.postInfo.SUPERVALUE),
            PRACTITIONER:parseInt(this.postInfo.PRACTITIONER),
            INSTALLEDCAPACITY:parseFloat(this.postInfo.INSTALLEDCAPACITY),
            FACTORYSNOWID:this.postInfo.FACTORYSNOWID,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("ProjectCost/CreateData", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    editPost(item){
        this.postInfo={
            SNOWID:item.SNOWID,
            NAME:item.NAME,
            YEARS:new Date(item.YEAR+"-01-01 00:00:00"),
            COST:item.COST,
            PROFIT:item.PROFIT,
            SUPERVALUE:item.SUPERVALUE,
            PRACTITIONER:item.PRACTITIONER,
            INSTALLEDCAPACITY:item.INSTALLEDCAPACITY,
            FACTORYSNOWID:item.FACTORYSNOWID,
        }
        this.doTypeStr="修改项目统计报表"
        this.doType="2"
        this.postModal=true
    },
    sendEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        var params={
            SNOWID:this.postInfo.SNOWID,
            NAME:this.postInfo.NAME,
            YEAR:this.postInfo.YEARS.getFullYear(),
            COST:parseFloat(this.postInfo.COST),
            PROFIT:parseFloat(this.postInfo.PROFIT),
            SUPERVALUE:parseFloat(this.postInfo.SUPERVALUE),
            PRACTITIONER:parseInt(this.postInfo.PRACTITIONER),
            INSTALLEDCAPACITY:parseFloat(this.postInfo.INSTALLEDCAPACITY),
            FACTORYSNOWID:this.postInfo.FACTORYSNOWID,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("ProjectCost/EditData", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.postModal=false
                    this.getPostList();
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    delPost(item){
        if(this.canDo){
            this.$confirm('确定删除：'+item.NAME+'？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
            this.sendDel(item.SNOWID)
            }).catch(() => {
            this.$message.info("已取消")    
          });
        }

    },
    sendDel(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({SNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:id})).toUpperCase())
        this.$http.post("ProjectCost/deleteData", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    submitPostForm() {
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    if(this.doType=="1"){
                        this.sendAdd()
                    }else if(this.doType=="2"){
                        this.sendEdit()
                    }
                } else {
                    return false;
                }
            });
        }
    },
    filePost(item){
        this.docFileStr=item.NAME+"-附件列表"
        this.checkDocId=item.SNOWID
        this.getDocFiles()
    },
    beforeAvatarUpload(file) {
        const isJPG = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf';

        if (!isJPG) {
            this.$message.error('图片只能是图片或pdf格式!');
        }else{
            // var _URL = window.URL || window.webkitURL;
            // this.imageUrl = _URL.createObjectURL(file);
            // this.postInfo.ICON=1
            // this.fileItem=file
        }
        return isJPG;
    },
    uploadFun(f){
      this.showLoading("请稍后...")
      var fd = new FormData();
      fd.append("file", f.file);
      var fname=f.file.name
      var type="3"
      if(fname.indexOf(".pdf")!=-1){
        type="2"
      }else if(fname.indexOf(".jpg")!=-1 || fname.indexOf(".png")!=-1){
        type="1"
      }
      this.$upload.post("ProjectCost/UploadProjectCostFile?CostID="+this.checkDocId+"&FileName="+fname+"&Type="+type, fd)
        .then((response) => {
         this.hideLoading()
          if (response.data.SUCCESS) {
                this.hideLoading()
                this.$message.success("上传成功")
                this.$refs.upload.clearFiles()
                this.getDocFiles()
          }else{
             this.hideLoading()
             this.$message.error("上传失败")
          }
        })
        .catch((error) => {
         this.hideLoading()
      });
    },
    getDocFiles(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({SNOWID:this.checkDocId}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:this.checkDocId})).toUpperCase())
        this.$http.post("ProjectCost/GetFileDataList", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                   this.fileList=response.data.DATA
                   this.chooseFileModal=true
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    downFile(e){
        window.open(e)
    },
    delFile(e){
        if(this.canDo){
            this.$confirm('确定删除附件？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
                this.sendDelFile(e)
            }).catch(() => {
            this.$message.info("已取消")    
          });
        }
    },
    sendDelFile(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({SNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:id})).toUpperCase())
        this.$http.post("ProjectCost/DeleteProjectCostFile", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.getDocFiles()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style scoped>
.fileBox{overflow: hidden;margin: 20px 0;padding: 20px;background-color: #F8F8F9;}
.fileItem{border-radius: 3px;background-color: #FFFFFF;cursor: pointer;padding: 10px;float:left;margin-right: 20px;margin-bottom: 20px;width: 206px;}
.fileItem:nth-of-type(3n){margin-right: 0!important;}
.fileItem .name{font-size: 15px;font-weight: bold;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;color: #444;margin-bottom: 2px;}
.fileItem .keywords{font-size: 14px;font-weight: bold;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;margin-bottom: 5px;color: #909399;}
.fileItem .fileBtn{overflow: hidden;text-align: left;}
.fileItem span{margin-right: 10px;}
.noFile{text-align: center;font-size: 15px;color: #409EFF;}
.fileTitle{font-size: 16px;font-weight: bold;overflow: hidden;}
</style>