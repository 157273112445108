<template>
  <div class="views-main">
    <div class="views-main-title">
      <p class="txt">用户管理</p>
    </div>
    
    <div class="tableBox" style="top:50px;left:16px;right:16px">
      <div class="views-main-search">
        <el-input v-model="number" placeholder="请输入编号" class="iwu" v-if="type=='2'" ></el-input>
        <el-input v-model="dlm" placeholder="请输入登录名" class="iwu" v-if="type=='2'" ></el-input>
        <el-input v-model="name" placeholder="请输入姓名" class="iwu" v-if="type=='2'" ></el-input>
        <el-input v-model="tel" placeholder="请输入手机号" class="iwu" v-if="type=='2'" type="number"></el-input>
        <!-- <el-cascader v-model="dep"  :options="orgList" :props="orgPropsA"  placeholder="所属部门" clearable filterable class="iw"></el-cascader> -->
        <el-select v-model="postId" placeholder="所属岗位"  filterable  clearable class="iwu">
                <el-option v-for="item in postList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
        </el-select>
        <el-select v-model="jobId" placeholder="职务"  filterable  clearable class="iwu">
                <el-option v-for="item in jobList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
        </el-select>
         <el-select v-model="isadmin" placeholder="管理员"    class="iwu">
                <el-option label="工作人员" value="0" ></el-option>
                <el-option label="管理员" value="1" ></el-option>
        </el-select>
        <el-button type="primary" @click="getUserList" v-if="type=='2'">查询</el-button>
        <el-button type="success" @click="addUser" v-if="type=='2'">添加</el-button>
        <el-button type="primary" @click="downloadTemplet" v-if="type=='2'" class="fr">下载模板</el-button>
        <el-button type="warning" @click="uploadTemplet" v-if="type=='2'" class="fr">导入模板</el-button>
        <el-button type="primary" @click="getDSHListA" v-if="type=='1'">查询</el-button>
      </div>
      <div class="views-main-body" v-show="type=='2'">
        <el-table :data="ysh" stripe style="width: 100%" row-key="SNOWID">
            <el-table-column type="expand">
              <template slot-scope="props">
                <span class="utItem">年龄：{{ props.row.AGE }}</span>
                <span class="utItem">工龄：{{ props.row.SERVICEAGE }}</span>
                <span class="utItem">邮箱：<span v-if="props.row.EMAIL==null">无</span><span v-else>{{props.row.EMAIL}}</span></span>
                <span class="utItem">创建时间：{{ props.row.CREATETIME }}</span>
                <span class="utItem">备注：<span v-if="props.row.BZ==null">无</span><span v-else>{{props.row.BZ}}</span></span>
                <span class="utItem">岗位职责：{{ props.row.RESPONSIBILITIES }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="SORT" label="排序号" ></el-table-column>
            <el-table-column prop="NUMBER" label="编号" ></el-table-column>
            <!-- <el-table-column prop="IMEI" label="定位器IMEI" >
                <template slot-scope="scope">
                    <span v-if="scope.row.IMEI==null">无</span>
                    <span v-else>{{scope.row.IMEI}}</span>
                </template>
            </el-table-column> -->
            <el-table-column prop="LOGINNAME" label="登录名"></el-table-column>
            <el-table-column prop="TRUENAME" label="姓名"></el-table-column>
            <el-table-column prop="MOBILEPHONE" label="电话">
                <template slot-scope="scope">
                    <span v-if="scope.row.MOBILEPHONE==null">无</span>
                    <span v-else>{{scope.row.MOBILEPHONE}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="SEX" label="性别">
                <template slot-scope="scope">
                  <span v-if="scope.row.SEX=='1'">男</span>
                  <span v-else-if="scope.row.SEX=='2'">女</span>
                  <span v-else-if="scope.row.SEX=='3'">X</span>
                  <span v-else>未知</span>
                </template>
            </el-table-column>
            <el-table-column prop="FACTORYSTR" label="风场"></el-table-column>
            <el-table-column prop="POST" label="岗位"></el-table-column>
            <el-table-column prop="POSTINFOSTR" label="职务"></el-table-column>
            
            <el-table-column prop="COMMUNICATIONID" label="通讯ID"></el-table-column>
            <el-table-column prop="SOSUSERNAME" label="紧急联系人">
                <template slot-scope="scope">
                  <span v-if="scope.row.SOSUSERNAME">{{scope.row.SOSUSERNAME}}</span>
                  <span v-else>/</span>
                </template>
            </el-table-column>
            <el-table-column prop="SOSUSERMOBILE" label="电话">
                <template slot-scope="scope">
                  <span v-if="scope.row.SOSUSERMOBILE">{{scope.row.SOSUSERMOBILE}}</span>
                  <span v-else>/</span>
                </template>
            </el-table-column>
           <el-table-column prop="ISENABLE" label="是否可用">
            <template slot-scope="scope">
              <span v-if="scope.row.ISENABLE=='1'">可用</span>
              <span v-else-if="scope.row.ISENABLE=='0'" style="color:#F56C6C">不可用</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="160">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="editUser(scope.row)" style="color:#ff9900">编辑</el-button>
              <el-button type="text" size="small" @click="signUser(scope.row)" style="color:#67C23A">签名</el-button>
              <el-button type="text" size="small" @click="resPwd(scope.row)" style="color:#409EFF">密码重置</el-button>
              <el-button type="text" size="small" @click="delUser(scope.row)" style="color:#ed4014">删除</el-button>
              <el-button type="text" size="small" @click="sortUser(scope.row)" style="color:#409EFF">排序</el-button>
              <el-button type="text" size="small" @click="setRoot(scope.row)" style="color:#409EFF">权限设置</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination background layout="total,prev,pager,next"  :current-page="yshPageIndex" :page-size="yshPageSize" :total="yshTotal" class="pg" @current-change="yshPGChange"></el-pagination>
      </div>
    </div>
    <el-dialog title="设置签名" :visible.sync="signModal" width="500px">
            <el-upload
                  class="avatar-uploader"
                  action="https://jsonplaceholder.typicode.com/posts/"
                  :show-file-list="false"
                  :http-request="uploadSign"
                  :before-upload="beforeAvatarUploadSign">
                  <img v-if="imageUrlSign" :src="imageUrlSign" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <el-button type="primary" @click="addSign()">提交</el-button>
              <el-button @click="signModal = false">取消</el-button>
        </el-dialog>
    <el-dialog :title="doUserTest" :visible.sync="changeUserModal" top="50px">
      <el-form :model="userItem" status-icon :rules="userrules" ref="userForm" label-width="100px" class="demo-userForm">
        <el-form-item label="编号" prop="NUMBER">
          <el-input  v-model="userItem.NUMBER" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="登录名" prop="LOGINNAME">
          <el-input  v-model="userItem.LOGINNAME" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="登录密码" prop="PASSWORD" v-if="doUserType=='1'">
          <el-input  v-model="userItem.PASSWORD" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="姓名" prop="TRUENAME">
          <el-input  v-model="userItem.TRUENAME" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="性别">
          <el-radio v-model="userItem.SEX" label="1">男</el-radio>
          <el-radio v-model="userItem.SEX" label="2">女</el-radio>
        </el-form-item>
        <el-form-item label="电话" prop="MOBILEPHONE">
          <el-input type="number" v-model="userItem.MOBILEPHONE" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="EMAIL">
          <el-input  v-model="userItem.EMAIL" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="年龄" prop="AGE">
          <el-input  v-model="userItem.AGE" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="工龄" prop="SERVICEAGE">
          <el-input  v-model="userItem.SERVICEAGE" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="风场" prop="FACTORYLIST">
            <el-select v-model="userItem.FACTORYLIST" placeholder="请选择风场" multiple filterable clearable style="width:100%;">
                    <el-option v-for="item in factoryList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="岗位" prop="POSITIONLIST">
            <el-select v-model="userItem.POSITIONLIST" placeholder="请选择岗位" multiple filterable clearable style="width:100%;">
                    <el-option v-for="item in postList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="岗位职责" >
            <el-input  v-model="userItem.RESPONSIBILITIES" autocomplete="off" type="textarea" class="gwzz"></el-input>
        </el-form-item>
        <el-form-item label="职务">
           <el-select v-model="userItem.POSTLIST" placeholder="请选择职务" multiple filterable clearable style="width:100%;">
                    <el-option v-for="item in jobList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="部门">
          <!-- <el-cascader v-model="userItem.ORGLIST"  :options="orgList" :props="orgPropsB" placeholder="所属部门" clearable filterable style="width:820px;"></el-cascader> -->
          <el-select v-model="userItem.ORGANIZATIONID" placeholder="请选择部门" filterable clearable style="width:100%;">
                    <el-option v-for="item in orgList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="紧急联系人" prop="SOSUSERNAME">
          <el-input  v-model="userItem.SOSUSERNAME" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="通讯ID">
          <el-input  v-model="userItem.COMMUNICATIONID" autocomplete="off"></el-input>
        </el-form-item>
        
        <el-form-item label="电话" prop="SOSUSERMOBILE">
          <el-input  v-model="userItem.SOSUSERMOBILE" autocomplete="off"></el-input>
        </el-form-item>
         <el-form-item label="是否可用">
          <el-radio v-model="userItem.ISENABLE" label="1">可用</el-radio>
          <el-radio v-model="userItem.ISENABLE" label="0">不可用</el-radio>
        </el-form-item>
        <el-form-item label="备注" prop="BZ">
          <el-input  v-model="userItem.BZ" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitUserForm()">提交</el-button>
          <el-button @click="changeUserModal=false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog title="密码重置" :visible.sync="resPwdModal" width="540px">
      <el-form :model="resItem" status-icon :rules="resrules"   ref="resForm" label-width="100px" class="demo-passForm">
        <el-form-item label="新密码" prop="pwd">
          <el-input  v-model="resItem.pwd" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="resAfter()">提交</el-button>
          <el-button @click=" resPwdModal=false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog title="选择文件" :visible.sync="chooseFileModal" width="400px"  :append-to-body="true">
            <el-upload
              ref="upload"
              class="upload-demo"
              drag
              :multiple="false"
              :limit="1"
              accept=".xls,.xlsx"
              action=""
              :http-request="uploadFun"
              >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
              <div class="el-upload__tip" slot="tip">只能上传excel文件</div>
            </el-upload>
    </el-dialog>
    <el-dialog title="导入结果明细" :visible.sync="importResultModal" width="540px"  :append-to-body="true">
          <p>导入成功{{importResult.success}}，导入出错{{importResult.error}}条。</p>
          <el-table :data="importResult.table" stripe style="width: 100%" :height="dialogMaxHeigh"> 
                <el-table-column prop="ROWINDEX" label="行号"></el-table-column>
                <el-table-column prop="INFO" label="问题描述"></el-table-column>
          </el-table>
    </el-dialog>
    <el-dialog :title="sortTitle" :visible.sync="sortModal" width="500px">
        <el-form :model="sortInfo" status-icon :rules="sortrules"  ref="sForm" label-width="100px" class="demo-uForm">
            <el-form-item label="排序号" prop="NEWSORT">
            <el-input v-model.number="sortInfo.NEWSORT" autocomplete="off"></el-input>
            </el-form-item>
        
            <el-form-item>
            <el-button type="primary" @click="submitSortForm()">提交</el-button>
            <el-button @click="sortModal = false">取消</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
    <el-dialog :title="rootTitle" :visible.sync="rootModal" width="860px" top="50px">
          <el-cascader :options="rootData" :props="props" clearable v-model="rootValue" style="width:100%;margin-bottom:20px;" placeholder="点击选择权限"> </el-cascader>
          <el-button type="warning" @click="checkAllRoot()">全选/取消全选</el-button>
          <el-button type="primary" @click="submitRootForm()">提交</el-button>
          <el-button @click="rootModal = false">取消</el-button>
    </el-dialog>
  </div>
</template>
<script>
import Sortable from 'sortablejs'
export default {
  name: "TaskIssued",
  data() {
    var ln = (rule, value, callback) => {
      if (value === '' || value == null) {
        callback(new Error('请输入登录名'));
      } else {
        callback();
      }
    };
    var pw = (rule, value, callback) => {
      if (value === '' || value == null) {
        callback(new Error('请输入登录密码'));
      } else {
        callback();
      }
    };
    var uname = (rule, value, callback) => {
      if (value === '' || value == null) {
        callback(new Error('请输入姓名'));
      } else {
        callback();
      }
    };
    var jjuname = (rule, value, callback) => {
      if (value === '' || value == null) {
        callback(new Error('请输入紧急联系人'));
      } else {
        callback();
      }
    };
    
    var utel = (rule, value, callback) => {
      if (value === '' || value == null) {
        callback(new Error('请输入手机号'));
      } else if(!/^1[3456789]\d{9}$/.test(value)){
        callback(new Error('请输入正确的手机号'));
      }else {
        callback();
      }
    };
    var jjutel = (rule, value, callback) => {
      if (value === '' || value == null) {
        callback(new Error('请输入紧急联系人手机号'));
      } else if(!/^1[3456789]\d{9}$/.test(value)){
        callback(new Error('请输入正确的手机号'));
      }else {
        callback();
      }
    };
    var uemail = (rule, value, callback) => {
      if (value === "" || value == null) {
         callback();
      } else {
        if(!/^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(value)){
          callback(new Error('请输入正确的邮箱'));
        }else {
          callback();
        }
      }
    };
    var pl = (rule, value, callback) => {
      if (value.length==0) {
        callback(new Error('请至少选择一个岗位'));
      } else {
        callback();
      }
    };

    var fl = (rule, value, callback) => {
      if (value.length==0) {
        callback(new Error('请至少选择一个风场'));
      } else {
        callback();
      }
    };
    
    var rpw = (rule, value, callback) => {
      if (value === '' || value == null) {
        callback(new Error('密码不能为空'));
      } else {
        callback();
      }
    };
    var NUMBER= (rule, value, callback) => {
      if (value === '' || value == null) {
        callback(new Error('编号不能为空'));
      } else {
        callback();
      }
    };
    var AGE=(rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入年龄'));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('年龄只能是数字'));
        }else if(!/^\+?(0|[1-9][0-9]*)$/.test(value)){
            callback(new Error('只能输入大于等于0的整数'));
        }else {
          callback();
        }
    };
    var SERVICEAGE= (rule, value, callback) => {
      if (value === '') {
          callback(new Error('请输入工龄'));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('工龄只能是数字'));
        }else if(!/^\+?(0|[1-9][0-9]*)$/.test(value)){
            callback(new Error('只能输入大于等于0的整数'));
        }else {
          callback();
        }
    };

    var NEWSORT= (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入排序号'));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('排序号只能是数字'));
        }else {
          callback();
        }
    };
    
    return {
        canDo:true,
        orgList:[], 


        factoryList:[],
        postList:[],
        jobList:[], 



        orgPropsA:{
            checkStrictly:true,
            value:"SNOWID",
            label:"NAME",
            children:"CHILD",
            //disabled:"ISENABLEBOOL"
        },

        orgPropsB:{
            multiple:true,
            checkStrictly:true,
            value:"SNOWID",
            label:"NAME",
            children:"CHILD",
            disabled:"ISENABLEBOOL"
        },

        dep:[],
        depId:"",
        jobId:"",
        postId:"",
        number:"",
        isadmin:"0",

        changeUserModal:false,
        type: "2",


        dlm:"",
        name: "",
        tel:"",

        //已审核
        yshArr:[],
        ysh:[],
        yshPageIndex:1,
        yshPageSize:10,
        yshTotal:0,

        doUserType:"",
        doUserTest:"",
        userItem:{
            LOGINNAME: "", 
            PASSWORD: "", 
            MOBILEPHONE: "", 
            EMAIL: "", 
            BZ: "", 
            ISENABLE: "1", 
            TRUENAME: "", 
            SEX: "1",
            FACTORYLIST:[],
            POSTLIST:[],
            POSITIONLIST:[],
            NUMBER:"",
            AGE:"",
            SERVICEAGE:"",
        },
        userrules: {
            TRUENAME: [
            { validator: uname, trigger: 'blur' }
            ],
            MOBILEPHONE: [
            { validator: utel, trigger: 'blur' }
            ],
            SOSUSERNAME: [
            { validator: jjuname, trigger: 'blur' }
            ],
            SOSUSERMOBILE: [
            { validator: jjutel, trigger: 'blur' }
            ],
            EMAIL: [
            { validator: uemail, trigger: 'blur' }
            ],
            LOGINNAME:[
            { validator: ln, trigger: 'blur' }
            ],
            PASSWORD:[
            { validator: pw, trigger: "blur" }
            ],
            POSITIONLIST:[
            { validator: pl, trigger: "blur" }
            ],
            FACTORYLIST:[
            { validator: fl, trigger: "blur" }
            ],
            NUMBER:[
            { validator: NUMBER, trigger: "blur" }
            ],
            AGE:[
            { validator: AGE, trigger: "blur" }
            ],
            SERVICEAGE:[
            { validator: SERVICEAGE, trigger: "blur" }
            ],   
        },


        //密码重置
        resPwdModal:false,
        resItem:{
          id:"",
          name:"",
          pwd:"123"
        },
        resrules:{
          pwd:[
            { validator: rpw, trigger: "blur" }
            ],
        },

        dialogMaxHeigh:0,

        //人员模板导入
        chooseFileModal:false,
        importResultModal:false,
        importResult:{
          success:0,
          error:0,
          table:[]
        },

        //签名
        signFile:null,
        signUserId:"",
        signModal:false,
        imageUrlSign:null,


        //排序
        sortTitle:"",
        sortInfo:{},
        sortrules: {
          NEWSORT: [{ validator: NEWSORT, trigger: "blur" }],
        },
        sortModal:false,


        rootTitle:"",
        rootModal:false,
        rootValue:[],
        props: { multiple: true },
        rootData:[
          {
            label:"系统设置",
            value:"01",
            children:[
              {label:"主创人设置",value:"0101",},
              {label:"字典项管理",value:"0102",},
              {label:"视频监控管理",value:"0103",},
              {label:"临时计划管理",value:"0104",},
              {label:"上岗通知书",value:"0105",},
              {label:"项目统计报表管理",value:"0106",},
            ]
          },
          {
            label:"用户管理",
            value:"02",
            children:[
              {label:"部门管理",value:"0206",},
              {label:"岗位管理",value:"0201",},
              {label:"职务管理",value:"0202",},
              {label:"用户列表",value:"0203",},
              {label:"人员登机记录查询",value:"0207",},
              {label:"工时统计",value:"0208",},
              {label:"定位器管理",value:"0204",},
              {label:"更换移动设备处理",value:"0205",},
            ]
          },
          {
            label:"风场与设备",
            value:"03",
            children:[
              {label:"风场管理",value:"0301",},
              {label:"风场设备类型管理",value:"0302",},
              {label:"发电计划设置",value:"0303",},
              {label:"发电设备管理",value:"0304",},
              {label:"设备参数维护",value:"0305",},
              {label:"年度费用计划",value:"0305",},
              {label:"费用支出管理",value:"0307",},
              {label:"升压站附件管理",value:"0308",},
            ]
          },
          {
            label:"资料库",
            value:"04",
            children:[
              {label:"资料库分类",value:"0401",},
              {label:"资料库管理",value:"0402",},
              {label:"资料库权限查看",value:"0403",},
            ]
          },
          {
            label:"培训管理",
            value:"05",
            children:[
              {label:"生产人员日常培训",value:"0501",},
            ]
          },
          {
            label:"考试管理",
            value:"06",
            children:[
              {label:"试卷管理",value:"0601",},
              {label:"题集管理",value:"0602",},
              {label:"考试配置",value:"0603",},
            ]
          },
          {
            label:"设备巡检",
            value:"07",
            children:[
              {label:"巡检管理",value:"0701",},
              {label:"检查结果管理",value:"0702",},
              {label:"任务查询",value:"0703",},
              {label:"任务流程查询",value:"0704",},
              {label:"模块管理",value:"0705",},
            ]
          },
          {
            label:"流程管理",
            value:"08",
            children:[
              {label:"字段类型管理",value:"0801",},
              {label:"表单管理",value:"0802",},
              {label:"流程管理",value:"0803",},
              {label:"审批意见管理",value:"0804",},
            ]
          },
          {
            label:"定检管理",
            value:"09",
            children:[
              {label:"定检任务查看",value:"0901",},
              {label:"年度定检计划",value:"0902",},
              {label:"日常定检计划",value:"0903",},
              {label:"定检交底报告",value:"0904",},
              {label:"定检模板",value:"0905",},
              {label:"工作分解",value:"0906",},
            ]
          },
          {
            label:"地调及春秋检",
            value:"10",
            children:[
              {label:"预试地调管理",value:"1001",},
              {label:"春检秋检",value:"1002",},
            ]
          },
          {
            label:"技术监督管理",
            value:"11",
            children:[
              {label:"技术监督检查",value:"1101",},
              {label:"技术监督会议",value:"1102",},
            ]
          },
          {
            label:"招投标/物资/保险",
            value:"12",
            children:[
              {label:"合同管理",value:"1201",},
              {label:"供应商管理",value:"1202",},
              {label:"货品分类",value:"1203",},
              {label:"仓库管理",value:"1204",},
              {label:"维修记录管理",value:"1205",},
              {label:"库存管理",value:"1206",},
              {label:"供货价格明细表",value:"1207",},
              {label:"生产全年预算计划",value:"1208",},
              {label:"保险理赔",value:"1209",},
              {label:"生产招投标业务",value:"1210",},
            ]
          },
          {
            label:"报表及汇总台账",
            value:"13",
            children:[
              {label:"机组设备故障情况",value:"1301",},
              {label:"生产考核",value:"1302",},
              {label:"优质方案",value:"1303",},
              {label:"工作总结及工作计划",value:"1304",},
            ]
          },
          {
            label:"日常会议后勤综合",
            value:"14",
            children:[
              {label:"印章申请审批",value:"1401",},
              {label:"生产人员费用报销管理",value:"1402",},
              {label:"车辆管理",value:"1403",},
              {label:"加油报销记录",value:"1404",},
              {label:"食堂管理",value:"1405",},
            ]
          },
        ]
    };
  },
  mounted() {
    this.loadItem=null
    this.getOrgList()
    this.getFactoryList()
    this.getJobList()
    this.getPostList()
    this.ysh=this.yshArr
    this.getYSHList()
    this.rowDrop()
  },
  methods: {
    getFactoryList(){
        var md5_data =this.comjs.requestDataEncrypt({}, this.$store.state.token,this.$md5(this.comjs.sortAsc({})).toUpperCase())
        this.$http.post("Factory/GetFactoryWithoutPage", md5_data)
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.factoryList=response.data.DATA
                } 
            })
    },
    getPostList(){
        var md5_data =this.comjs.requestDataEncrypt({}, this.$store.state.token,this.$md5(this.comjs.sortAsc({})).toUpperCase())
        this.$http.post("PositonManage/GetPositionListWithoutPage", md5_data)
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.postList=response.data.DATA  
                } 
            })
    },
    getOrgList(){
        var md5_data =this.comjs.requestDataEncrypt({OrgSnowId:""}, this.$store.state.token,this.$md5(this.comjs.sortAsc({OrgSnowId:""})).toUpperCase())
        this.$http.post("OrgManage/GetOrgList", md5_data)
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.orgList=response.data.DATA  
                } 
            })
    },
    getJobList(){
        var md5_data =this.comjs.requestDataEncrypt({}, this.$store.state.token,this.$md5(this.comjs.sortAsc({})).toUpperCase())
        this.$http.post("Post/GetPostListWithoutPage", md5_data)
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.jobList=response.data.DATA  
                } 
            })
    },
    getUserList(){
      this.yshPageIndex=1
      this.yshTotal=0
      this.getYSHList()
      
    },
    getYSHList(){
      this.showLoading("请稍后")
      var params={
        PAGEINDEX:this.yshPageIndex,
        PAGESIZE:this.yshPageSize,
        TRUENAME:this.name,
        MOBILEPHONE:this.tel,
        POSTID:this.jobId,
        LOGINNAME:this.dlm,
        POSITIONID:this.postId,
        NUMBER:this.number,
        ISADMIN:this.isadmin,
      }
      var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
      this.$http.post("UserManage/GetUserList", md5_data)
        .then((response) => {
          this.hideLoading()
          if (response.data.SUCCESS) {
              var d=response.data.DATA
              var dl=[]
              for(var i=0;i<d.length;i++){
                    var di=d[i]
                    di.POSTINFOSTR="无" //职务
                    di.POST="无"  //岗位
                    di.FACTORYSTR="无"
                    for(var k=0;k<di.POSITIONINFO.length;k++){
                            if(k==0){
                                di.POST = di.POSITIONINFO[k].NAME
                            }else{
                                di.POST = di.POST +"、"+di.POSITIONINFO[k].NAME
                            }
                    }
                    for(var j=0;j<di.POSTINFO.length;j++){
                            if(j==0){
                                di.POSTINFOSTR = di.POSTINFO[j].NAME
                            }else{
                                di.POSTINFOSTR = di.POSTINFOSTR +"、"+di.POSTINFO[j].NAME
                            }
                    }
                    for(var u=0;u<di.FACTORYLIST.length;u++){
                            if(u==0){
                                di.FACTORYSTR = di.FACTORYLIST[u].NAME
                            }else{
                                di.FACTORYSTR = di.FACTORYSTR +"、"+di.FACTORYLIST[u].NAME
                            }
                    }
                    dl.push(di)
              }
              this.ysh=dl
              this.yshTotal=response.data.TOTALCOUNT
          } else {
              this.ysh=[]
              this.yshTotal=0
              this.errorRequest(response.data.DATA,response.data.MSG,true)
          }
        })
        .catch((error) => {
            this.hideLoading()
            this.ysh=[]
            this.yshTotal=0
        });
    },
    yshPGChange(e){
      this.yshPageIndex=e
      this.getYSHList()
    },

    addUser(){
        this.doUserType="1"
        this.doUserTest="添加用户"
        this.userItem={
            COMMUNICATIONID:"",
            LOGINNAME: "", 
            PASSWORD: "", 
            MOBILEPHONE: "", 
            EMAIL: "", 
            BZ: "", 
            ISENABLE: "1", 
            TRUENAME: "", 
            SEX: "1",
            FACTORYLIST:[],
            POSTLIST:[],
            POSITIONLIST:[],
            NUMBER:"",
            AGE:"",
            SERVICEAGE:"",
            SOSUSERNAME:"",
            SOSUSERMOBILE:"",
            ORGANIZATIONID:"",
        }
        this.changeUserModal=true
    },  

    sendAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        this.userItem.AGE = parseInt(this.userItem.AGE)
        this.userItem.SERVICEAGE = parseInt(this.userItem.SERVICEAGE)
        var md5_data =this.comjs.requestDataEncrypt(this.userItem, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.userItem)).toUpperCase())
        this.$http.post("UserManage/CreateNewUser", md5_data)
            .then((response) => {
            //console.log(response)
            this.canDo=true
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.$message.success("添加成功")
                this.changeUserModal=false
                this.getYSHList()

            } else {
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true
            });
    },

    editUser(e){
        this.doUserType="2"
        this.doUserTest="编辑用户"
        var pl=[]
        for(var i=0;i<e.POSITIONINFO.length;i++){
            pl.push(e.POSITIONINFO[i].SNOWID)
        }

        var zw=[]
        for(var j=0;j<e.POSTINFO.length;j++){
            zw.push(e.POSTINFO[j].SNOWID)
        }
        var fc=[]
        for(var u=0;u<e.FACTORYLIST.length;u++){
            fc.push(e.FACTORYLIST[u].SNOWID)
        }

        var bm=""
        if(e.ORGANIZATIONINFO.length>0){
          bm= e.ORGANIZATIONINFO[0].SNOWID
        }
        this.userItem={
            SNOWID:e.SNOWID,
            LOGINNAME: e.LOGINNAME, 
            MOBILEPHONE: e.MOBILEPHONE, 
            EMAIL:e.EMAIL, 
            BZ: e.BZ, 
            ISENABLE: e.ISENABLE, 
            TRUENAME: e.TRUENAME, 
            SEX: e.SEX,
            FACTORYLIST:fc,
            POSTLIST:zw,
            POSITIONLIST:pl,
            NUMBER:e.NUMBER,
            AGE:e.AGE,
            SERVICEAGE:e.SERVICEAGE,
            RESPONSIBILITIES:e.RESPONSIBILITIES,
            SOSUSERNAME:e.SOSUSERMOBILE,
            SOSUSERMOBILE:e.SOSUSERMOBILE,
            ORGANIZATIONID:bm,
            COMMUNICATIONID:e.COMMUNICATIONID,
        }
        // console.log(e)
        // if(e.ORGANIZATIONINFO){
        //   this.setOrgList(0,e.ORGANIZATIONINFO,[])
        // }
        
        this.changeUserModal=true
    },
    setOrgList(index,oldList,newList){
        if(index >= oldList.length ){
            this.userItem.ORGLIST=newList
            this.hideLoading()
            this.changeUserModal=true
        }else{
            var oitem= oldList[index]
            if(oitem.PARENTORGANIZATIONGUID == null){
                newList.push([oitem.SNOWID])
                this.setOrgList((index+1),oldList,newList)
            }else{
                this.getParentOrgs(index,oldList,newList)
            }
        }
    },
    getParentOrgs(index,oldList,newList){
        var oitem= oldList[index]

        var md5_data =this.comjs.requestDataEncrypt({SNOWID:oitem.SNOWID}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:oitem.SNOWID})).toUpperCase())
        this.$http.post("OrgManage/GetParentOrg", md5_data)
            .then((response) => {
            if (response.data.SUCCESS) {
               var d=response.data.DATA
               var nl=[]
               for(var i=d.length ;i>0;i--){
                   nl.push(d[i-1].SNOWID)
               }
               newList.push(nl)
               this.setOrgList((index+1),oldList,newList)
            } else {
                newList.push([])
                this.setOrgList((index+1),oldList,newList)
            }
            })
            .catch((error) => {
               newList.push([])
               this.setOrgList((index+1),oldList,newList)
            });
    },
    //修改/添加
    submitUserForm() {
        if(this.canDo){
            //console.log(this.userItem)
            this.$refs.userForm.validate((valid) => {
                if (valid) {
                    this.userItem.ISENABLE = parseInt(this.userItem.ISENABLE)
                    this.userItem.SEX  = parseInt(this.userItem.SEX)
                    if(this.doUserType=="1"){
                        this.sendAdd()
                    }else if(this.doUserType=="2"){
                        this.sendEdit()
                    }
                } else {
                
                return false;
                }
            });
        }
    },
    sendEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        this.userItem.AGE = parseInt(this.userItem.AGE)
        this.userItem.SERVICEAGE = parseInt(this.userItem.SERVICEAGE)
        var md5_data =this.comjs.requestDataEncrypt(this.userItem, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.userItem)).toUpperCase())
        this.$http.post("UserManage/EditUser", md5_data)
            .then((response) => {
                //console.log(response)
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("修改成功")
                    this.changeUserModal=false
                    this.getYSHList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true
            });
    },
    delUser(e){
        if(this.canDo){
            this.$confirm('确定删除用户：'+e.TRUENAME+'？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
            this.sendDel(e.SNOWID)
            }).catch(() => {
            this.$message.info("已取消")    
          });
        }
    },
    sendDel(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({SNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:id})).toUpperCase())
        this.$http.post("UserManage/DeleteUser", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.postModal=false
                    this.getYSHList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    //密码重置
    resPwd(e){
      console.log(e)
      this.resItem={
        id:e.SNOWID,
        name:e.TRUENAME+"("+e.LOGINNAME+")",
        pwd:"123",
      }
      this.resPwdModal=true
    },
    resAfter(){
        if(this.canDo){
            this.$refs.resForm.validate((valid) => {
                if (valid) {
                    if(this.canDo){
                        this.$confirm('确定将用户：'+this.resItem.name+'的密码重置为 '+this.resItem.pwd+'？', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                        }).then(() => {
                        this.sendResPwd()
                        }).catch(() => {
            this.$message.info("已取消")    
          });
                    }
                } else {
                    return false;
                }
            });
        }
    },
    sendResPwd(){
          this.canDo=false
          this.showLoading("请稍后")
          var params={
              SNOWID:this.resItem.id,
              PASSWORD:this.resItem.pwd,
          }
          var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
          this.$http.post("UserManage/ResetPassword", md5_data)
              .then((response) => {
                  this.canDo=true
                  this.hideLoading()
                  if (response.data.SUCCESS) {
                      this.$message.success("已重置！")
                      this.resPwdModal=false
                  } else {
                      this.errorRequest(response.data.DATA,response.data.MSG,true)
                  }
              })
              .catch((error) => {
                  this.$message.error("请求出错")
                  this.hideLoading()
                  this.canDo=true   
              });
    },
    downloadTemplet(){
      window.open("file/人员导入模板.xlsx")
    },
    uploadTemplet(){
      this.chooseFileModal=true
    },
    uploadFun(f){
      //this.showLoading("请稍后...")
      var fd = new FormData();
      //console.log(f)
      fd.append("file", f.file);
      this.$upload.post("UserManage/ImportUser", fd)
        .then((response) => {
          //console.log(response)
         this.hideLoading()
          if (response.data.SUCCESS) {
             this.hideLoading()
             this.$message.success("导入完成")
             this.$refs.upload.clearFiles()
             this.chooseFileModal=false
             this.getYSHList()
             this.importResult={
                success:response.data.DATA.SUCCESSCOUNT,
                error:response.data.DATA.ERRORCOUNT,
                table:response.data.DATA.ERRORLIST
              }
              this.importResultModal=true
          }else{
             this.hideLoading()
             this.$message.error("上传失败")
          }
        })
        .catch((error) => {
         this.hideLoading()
      });
    },

    signUser(item){
      this.signFile=null
      this.signUserId=item.SNOWID
      this.imageUrlSign=item.SIGNIMAGEPATH
      this.signModal=true
    },
    beforeAvatarUploadSign(file) {
        const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isJPG) {
          this.$message.error('上传图片只能是 JPG/PNG 格式!');
        }else if (!isLt2M) {
          this.$message.error('上传图片大小不能超过 2MB!');
        }else{
            var _URL = window.URL || window.webkitURL;
            this.imageUrlSign = _URL.createObjectURL(file);
            this.signFile=file
        }
        return isJPG && isLt2M;
    },
    uploadSign(file){
        //console.log(file)
    },
    addSign(){
      if(this.signFile){
        this.showLoading("请稍后")
        var fd = new FormData();
        fd.append("file", this.signFile);
        this.$upload.post("UserManage/UploadSignImg?IMAGESOURCEGUID="+this.signUserId, fd)
            .then((response) => {
                this.hideLoading()
                //console.log("上传签名")
                //console.log(response)
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已上传")
                    this.signFile=null
                    this.signModal=false
                    this.getYSHList()
                }else{
                    this.hideLoading()
                    this.$message.error("上传失败")
                }
            })
            .catch((error) => {
            this.hideLoading()
        });
      }else{
        this.signFile=null
        this.signModal=false
      }
      
    },
    sortUser(item){
      this.sortInfo={
        USERSNOWID:item.SNOWID,
        NEWSORT:item.SORT,
      }
      this.sortTitle=item.TRUENAME+"-排序"
      this.sortModal=true
    },
    submitSortForm() {
        if (this.canDo) {
            this.$refs.sForm.validate((valid) => {
                if (valid) {
                  this.sendSort()
                } else {
                    return false;
                }
            });
        }
    },
    sendSort(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.sortInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.sortInfo)).toUpperCase())
        this.$http.post("UserManage/SortUsers", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.sortModal=false
                    this.getYSHList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    //行拖拽
    rowDrop() {
        const tbody = document.querySelector('.el-table__body-wrapper tbody')
        const _this = this
        Sortable.create(tbody, {
          onEnd({ newIndex, oldIndex }) {
            //console.log("拖动了行","当前序号: "+newIndex)
            const currRow = _this.ysh.splice(oldIndex, 1)[0]
            _this.ysh.splice(newIndex, 0, currRow)
            //console.log(_this.ysh)

            _this.sortInfo={
              USERSNOWID:_this.ysh[newIndex].SNOWID,
              NEWSORT:_this.ysh[newIndex+1].SORT -1,
            }
            _this.sendSort()
          }
        })
    },

    //权限
    checkAllRoot(){
      if(this.rootValue.length==63){
        this.rootValue=[]
      }else{
        var  vs=[]
        var list =this.rootData
        for(var i=0;i<list.length;i++){
          var pcode=list[i].value
          var pchild=list[i].children
          for(var j=0;j<pchild.length;j++){
            vs.push([pcode,pchild[j].value])
          }
        }
        this.rootValue = vs
      }
    },
    setRoot(item){
      this.rootTitle=item.TRUENAME+"权限设置"
      this.signUserId=item.SNOWID
      this.rootValue=[]
      this.getUserRoot()
    },
    getUserRoot(){
        this.canDo=false
        this.showLoading("请稍后")
        var params={
          UserSnowID:this.signUserId
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("RMenu/GetUserMenu", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.rootModal=true
                    var d=response.data.DATA
                    var  vs=[]
                    if(d!=""){
                      if(d=="*"){
                          var list =this.rootData
                          for(var i=0;i<list.length;i++){
                            var pcode=list[i].value
                            var pchild=list[i].children
                            for(var j=0;j<pchild.length;j++){
                              vs.push([pcode,pchild[j].value])
                            }
                          }
                      }else{
                        var listk =d.split(",")
                        for(var k=0;k<listk.length;k++){
                          vs.push([listk[k].substring(0,2),listk[k]])
                        }
                      }
                    }
                    
                    this.rootValue =vs
                }
            })
            .catch((error) => {
                // this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
                this.rootModal=true
             });
    },
    submitRootForm(){
      if(this.canDo){
        var str=""
        if(this.rootValue.length==63){
          str="*"
        }else{
          for(var i=0;i<this.rootValue.length;i++){
            var d=this.rootValue[i][1]
            if(i==0){
              str=d
            }else{
              str+=","+d
            }
          }
        }
        this.canDo=false
        this.showLoading("请稍后")
        var params=[
          {
            USERSNOWID:this.signUserId,
            MENUSTR:str
          }
        ]
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("RMenu/CreateOrUpdateUserMenu", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("设置成功")
                    this.rootModal=false
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
      }
    }
  },
  computed: {},
  watch: {
        dep(newVal,oldVal){
            if(newVal.length==0){
                this.depId=""
            }else{
                this.depId=newVal[newVal.length-1]
            }
        },
  },
};
</script>
<style>
  .gwzz .el-textarea__inner{height:100px!important;}
  .utItem{font-size: 14px;margin-right: 20px;}
</style>
