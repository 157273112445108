<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">年度定检计划</p>
        </div>
        <div class="tableBox" style="top:50px;">
                <div class="ksItem" @click="createDJModal('1')">
                    风机第一、二、三次定检工作（外委）
                </div>
                <div class="ksItem" @click="createDJModal('2')">
                   风机第四、五、六次定检工作（自检）
                </div>
                <!-- <div style="width:100%;float:left;height:1px;"></div>
                <div class="ksItem" @click="createDJModal('3')">
                    每年定检时对风向仪进行对中校对。（每年外委定检期内进行校对）
                </div>
                <div class="ksItem" @click="createDJModal('4')">
                   每年定检时校对叶片对角。（每年外委定检期内进行校对）
                </div>
                <div style="width:100%;float:left;height:1px;"></div>
                
                <div class="ksItem" @click="noStart()">
                    定检1、2、3次（外委）机组故障率检查出的问题明细
                </div>
                <div class="ksItem" @click="noStart()">
                   定检4、5、6次（自检）机组故障率检查出的问题明细
                </div> -->
                <div style="width:100%;float:left;height:1px;"></div>
        </div>
    </div>
</template>
<script>
export default {
  name: "KSFL",
  data() {

    return {
        canDo:true, 
    };
  },
  mounted() {
    this.loadItem=null
  },
  methods: {
      createDJModal(type){
        this.showLoading("请稍后")
        var params={
                PAGEINDEX:1,
                PAGESIZE:1,
                NAME:"",
                ALIAS:"INSPECTION",
                VISIBILITY:true,
            }
            var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
            this.$http.post("Model/GetModelList", md5_data)
                .then((response) => {
                this.hideLoading()
                if (response.data.SUCCESS && response.data.DATA.length>0) {
                    var d=response.data.DATA[0]
                    window.open("/statics/pages/LCQC.html?mid="+d.SNOWID+"&token="+this.$store.state.token+"&djtype="+type)
                } else {
                this.$message.error("请先配置定检申请流程")
                }
                })
                .catch((error) => {
                this.$message.error("请求失败，请稍后再试")
                this.hideLoading()
                
            }); 
        },
        noStart(){
            this.$message.warning("暂未开通")
        }
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style scoped>
    .el-collapse-item__header{padding: 0 20px;font-size: 16px;}
    .el-collapse .is-active{color: #409EFF;}
    .el-collapse-item__content{padding-left:20px;}

    .ksItem{width: 600px;height: 80px;border-radius: 3px;cursor: pointer;color: #FFFFFF;background-color: #409EFF;font-size: 16px;margin-left: 30px;margin-top: 30px;float:left;display: -webkit-box;display: -ms-flexbox;display: -webkit-flex;display: flex;-webkit-box-pack: center;-ms-flex-pack: center;-webkit-justify-content: center;justify-content: center;-webkit-box-align: center;-ms-flex-align: center;-webkit-align-items: center;align-items: center;padding:0 30px;}


    .ksItem:hover{background-color: #5FADFC;}

</style>