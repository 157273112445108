<template>
    <div class="views-main">
        <div class="views-main-title">
            <el-button type="primary" icon="el-icon-arrow-left" style="float:left;margin-right:20px;" size="small" @click="backAQGL">返回</el-button>
            <p class="txt">印章申请记录</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                <el-select v-model="jjcd" placeholder="紧急程度"  clearable filterable  class="iww">
                    <el-option label="一般" value="一般" ></el-option>
                    <el-option label="紧急" value="紧急" ></el-option>
                    <el-option label="特急" value="特急" ></el-option>
                </el-select>   
                
                <el-button type="primary" class="fr" @click="yzsp">印章申请</el-button>
                <el-button type="primary" @click="searchPost">查询</el-button>
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column prop="EMERGENCY" label="紧急程度"></el-table-column>
                    <el-table-column prop="CAUSE" label="申请事由"></el-table-column>
                    <el-table-column prop="OPERUSERNAME" label="经办人"></el-table-column>
                    <el-table-column prop="OPERDEPARTMENT" label="经办部门"></el-table-column>
                    <el-table-column prop="OPERLEADER" label="经办部门负责人"></el-table-column>
                    <el-table-column prop="APPLYDATE" label="申请日期"></el-table-column>
                    <el-table-column prop="USEDFILE" label="用印文件"></el-table-column>
                    <el-table-column prop="USENUM" label="用印数"></el-table-column>
                    <el-table-column prop="BZ" label="情况说明"></el-table-column>
                    <el-table-column prop="MANAGELEADEROPINION" label="主管领导审批"></el-table-column>
                    <el-table-column prop="POWERUSEROPINION" label="法定代表人/被授权人批准"></el-table-column>
                    <el-table-column prop="ZHGLBGWOPINION" label="综合管理部法律顾问/主管复核"></el-table-column>
                    <el-table-column prop="ZHGLBZROPINION" label="综合管理部主管/主任"></el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  name: "JYBXJL",
  data() {
    return {
        canDo:true,
        userList:[],
        jjcd:"",
        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,
    };
  },
  mounted() {
    this.loadItem=null
    //this.getUserList()
    this.getPostList()
  },
  methods: {
    getUserList(){
        var params={ }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("UserManage/GetUserListWithoutPage", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.userList=response.data.DATA
            } 
            })
    },
    getPostList(){
        this.showLoading("请稍后")

        var params={
            PAGEINDEX:this.PageIndex,
            PAGESIZE:this.PageSize,
            EMERGENCY:this.jjcd,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Sealapply/GetList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
        });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    backAQGL(){
        this.$router.replace('/HQAQGL')
    },
    yzsp(){
        this.showLoading("请稍后")
        var params={
            PAGEINDEX:1,
            PAGESIZE:1,
            NAME:"",
            ALIAS:"SEALAPPLY",
            VISIBILITY:true,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Model/GetModelList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS && response.data.DATA.length>0) {
                var d=response.data.DATA[0]
                window.open("/statics/pages/LCQC.html?mid="+d.SNOWID+"&token="+this.$store.state.token)
            } else {
            this.$message.error("请先配置印章申请流程")
            }
            })
            .catch((error) => {
                this.$message.error("请求失败，请稍后再试")
                this.hideLoading()
            }); 
    }
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style >
</style>