<template>
    <div class="views-main">
        <div class="views-main-title">
            <el-button type="primary" icon="el-icon-arrow-left" style="float:left;margin-right:20px;" size="small" @click="backAQGL">返回</el-button>
            <p class="txt">车辆管理</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                
                <el-select v-model="ybry" placeholder="已绑人员"  clearable filterable  class="iw">
                    <el-option v-for="item in userList" :key="item.SNOWID" :label="item.TRUENAME" :value="item.SNOWID" ></el-option>
                </el-select>   
                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-button type="success" @click="addPost" class="fr">添加</el-button>
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column prop="MANAGERNAME" label="负责人"></el-table-column>
                    <el-table-column prop="MODEL" label="车辆型号"></el-table-column>
                    <el-table-column prop="PLATENUMBER" label="车牌号"></el-table-column>
                    <el-table-column prop="FRAMENUMBER" label="车架号"></el-table-column>
                    <el-table-column prop="ENGINENUMBER" label="发动机号"></el-table-column>
                    <el-table-column prop="TYPESTR" label="车辆类型"></el-table-column>
                    <el-table-column prop="MILEAGE" label="行驶里程"></el-table-column>
                    <el-table-column prop="USERNAMES" label="已绑人员"></el-table-column>

                    <el-table-column label="操作" width="140">
                        <template slot-scope="scope">
                        <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button>
                        <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
                        <el-button @click="filePost(scope.row)"  type="text" size="small" style="color:#409EFF">附件</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="700px">
            <el-form :model="postInfo" status-icon :rules="postrules"  ref="uForm" label-width="100px" class="demo-uForm">
                <el-form-item label="车辆型号" prop="MODEL">
                    <el-input v-model="postInfo.MODEL" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="负责人" prop="MANAGERID">
                    <el-select v-model="postInfo.MANAGERID" placeholder="请选择人员" filterable clearable style="width:100%;" @change="changeUser">
                            <el-option v-for="item in userList" :key="item.SNOWID" :label="item.TRUENAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="车牌号" prop="PLATENUMBER">
                    <el-input v-model="postInfo.PLATENUMBER" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="车架号" prop="FRAMENUMBER">
                    <el-input v-model="postInfo.FRAMENUMBER" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="发动机号" prop="ENGINENUMBER">
                    <el-input v-model="postInfo.ENGINENUMBER" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="行驶里程" prop="MILEAGE">
                    <el-input v-model="postInfo.MILEAGE" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="车辆类型" prop="TYPEARR">
                     <el-cascader v-model="postInfo.TYPEARR"  :options="tree" :props="treeProps" placeholder="搜索或选择车辆类型" filterable style="width:100%"></el-cascader>
                </el-form-item>
                <el-form-item label="绑定人员">
                    <el-select v-model="postInfo.USERSNOWIDLISTITEM" placeholder="请选择人员" filterable clearable style="width:100%;">
                            <el-option v-for="item in userList" :key="item.SNOWID" :label="item.TRUENAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitPostForm()">提交</el-button>
                    <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog :title="docFileStr" :visible.sync="chooseFileModal" width="800px"  :append-to-body="true">
            <p class="fileTitle">现有附件：</p>
            <div class="fileBox">
                <div class="fileItem" v-for="item in fileList" :key="item.SNOIWD" :title="item.FILENAME">
                    <p class="name" @click="downFile(item.Url)">{{item.FILENAME}}</p>
                    <div class="fileBtn">
                        <span @click="downFile(item.Url)" style="color:#409EFF">预览</span>
                        <span @click="delFile(item.SNOWID)" style="color:#F56C6C">删除</span>
                    </div>
                </div>
                <div v-if="fileList.length==0" class="noFile">该统计还未上传附件，请在下方上传。</div>
            </div>
            <p class="fileTitle" style="margin-bottom:20px;">上传附件：</p>
            <div style="overflow:hidden;">
                <el-upload ref="upload"  class=""  :multiple="true" :limit="5" action="" :http-request="uploadFun">
                    <el-button size="small" type="primary">点击上传</el-button>
                </el-upload>
            </div>
        </el-dialog>
    </div>
</template>
<script>
export default {
  name: "CLGL",
  data() {
    var MODEL = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入车辆型号"));
      } else {
        callback();
      }
    };
    var PLATENUMBER = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入车牌号"));
      } else {
        callback();
      }
    };
    var FRAMENUMBER = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入车架号"));
      } else {
        callback();
      }
    };
    var ENGINENUMBER = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入发动机号"));
      } else {
        callback();
      }
    };
    var TYPEARR = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择车辆类型"));
      } else {
        callback();
      }
    };
    var MANAGERID=(rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择负责人"));
      } else {
        callback();
      }
    };
    var MILEAGE=(rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入行驶里程'));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('行驶里程只能是数字'));
        }else if(value<=0){
            callback(new Error('行驶里程必须大于0'));
        }else {
          callback();
        }
    };
    return {
        canDo:true,
        
        tree:[],
        treeProps:{
            value:"NAME",
            label:"NAME",
            children:"CHILD",
            //checkStrictly:true,
        },



        userList:[],


        ybry:"",
       

        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,


        doTypeStr:"",
        doType:"",
        postModal:false,
        postInfo:{},
        postrules: {
            MODEL: [{ validator: MODEL, trigger: "blur" }],
            PLATENUMBER: [{ validator: PLATENUMBER, trigger: "blur" }],
            FRAMENUMBER: [{ validator: FRAMENUMBER, trigger: "blur" }],
            ENGINENUMBER: [{ validator: ENGINENUMBER, trigger: "blur" }],
            TYPEARR: [{ validator: TYPEARR, trigger: "blur" }],
            MANAGERID: [{ validator: MANAGERID, trigger: "blur" }],
            MILEAGE: [{ validator: MILEAGE, trigger: "blur" }],
        },

        docFileStr:"",
        checkDocId:"",
        fileList:[],
        chooseFileModal:false,
    };
  },
  mounted() {
    this.loadItem=null
    this.getDicTree()
    this.getUserList()
    this.getPostList()
  },
  methods: {
    changeUser(e){
        this.postInfo.MANAGERNAME=""
        if(e!=""){
           for(var i=0;i<this.userList.length;i++){
             if(e==this.userList[i].SNOWID){
                this.postInfo.MANAGERNAME=this.userList[i].TRUENAME
             }
           } 
        }
    },
    getDicTree(){
        var md5_data =this.comjs.requestDataEncrypt({}, this.$store.state.token,this.$md5(this.comjs.sortAsc({})).toUpperCase())
        this.$http.post("Dict/GetDictTreeList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.tree=response.data.DATA
            } 
            })
    },
    getUserList(){
        var params={ }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("UserManage/GetUserListWithoutPage", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.userList=response.data.DATA
            } 
            })
    },
    getPostList(){
        this.showLoading("请稍后")
        var params={
            PAGEINDEX:this.PageIndex,
            PAGESIZE:this.PageSize,
            USERSNOWID:this.ybry,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Car/GetCarList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                var list=response.data.DATA
                var nl=[]
                for(var i=0;i<list.length;i++){
                    var d=list[i]
                    d.USERNAMES="/"
                    var ts=d.TYPE.split("/")
                    d.TYPESTR =ts[ts.length-1]
                    if(d.USERLIST && d.USERLIST.length>0){
                        for(var j=0;j<d.USERLIST.length;j++){
                            if(j==0){
                                d.USERNAMES=d.USERLIST[j].TRUENAME
                            }else{
                                d.USERNAMES=d.USERNAMES+"、"+d.USERLIST[j].TRUENAME
                            }
                        }
                    }
                    nl.push(d)
                }
                this.postList=nl
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
        });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    addPost(){
        this.postInfo={
            MODEL:"",
            PLATENUMBER:"",
            FRAMENUMBER:"",
            ENGINENUMBER:"",
            TYPEARR:[],
            TYPE:"",
            USERSNOWIDLISTITEM:"",
            MILEAGE:"",
            MANAGERID:"",
            MANAGERNAME:"",
        }
        this.doTypeStr="添加车辆"
        this.doType="1"
        this.postModal=true
    },
    sendAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("Car/CreateNewCarInfo", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    editPost(item){
        this.postInfo={
            CARSNOWID:item.SNOWID,
            MODEL:item.MODEL,
            PLATENUMBER:item.PLATENUMBER,
            FRAMENUMBER:item.FRAMENUMBER,
            ENGINENUMBER:item.ENGINENUMBER,
            TYPEARR:item.TYPE.split("/"),
            TYPE:item.TYPE,
            USERSNOWIDLISTITEM:item.USERLIST[0].SNOWID,
            MILEAGE:item.MILEAGE,
            MANAGERID:item.MANAGERID,
            MANAGERNAME:item.MANAGERNAME,
        }
        this.doTypeStr="修改车辆"
        this.doType="2"
        this.postModal=true
    },
    sendEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("Car/EditCarInfo", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.postModal=false
                    this.getPostList();
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    delPost(item){
        if(this.canDo){
            this.$confirm('确定删除？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
            this.sendDel(item.SNOWID)
            }).catch(() => {
            this.$message.info("已取消")    
          });
        }

    },
    sendDel(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({CARSNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({CARSNOWID:id})).toUpperCase())
        this.$http.post("Car/DeleteCarInfo", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    submitPostForm() {
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    var  t =this.postInfo.TYPEARR
                    var typeStr=""
                    for(var i=0;i<t.length;i++){
                        if(i==0){
                            typeStr = t[i]
                        }else{
                            typeStr =typeStr+"/"+ t[i]
                        }
                    }
                    this.postInfo.TYPE =typeStr
                    this.postInfo.USERSNOWIDLIST=[]
                    if(this.postInfo.USERSNOWIDLISTITEM !=""){
                        this.postInfo.USERSNOWIDLIST.push(this.postInfo.USERSNOWIDLISTITEM)
                    }
                    //console.log(this.postInfo)
                    if(this.doType=="1"){
                        this.sendAdd()
                    }else if(this.doType=="2"){
                        this.sendEdit()
                    }
                } else {
                    return false;
                }
            });
        }
    },
    backAQGL(){
        this.$router.replace('/HQAQGL')
    },



    filePost(item){
        this.docFileStr=item.PLATENUMBER+"-附件列表"
        this.checkDocId=item.SNOWID
        this.getDocFiles()
    },
    beforeAvatarUpload(file) {
        const isJPG = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf';

        if (!isJPG) {
            this.$message.error('图片只能是图片或pdf格式!');
        }else{
            // var _URL = window.URL || window.webkitURL;
            // this.imageUrl = _URL.createObjectURL(file);
            // this.postInfo.ICON=1
            // this.fileItem=file
        }
        return isJPG;
    },
    uploadFun(f){
      this.showLoading("请稍后...")
      var fd = new FormData();
      fd.append("file", f.file);
      var fname=f.file.name
      var type="3"
      if(fname.indexOf(".pdf")!=-1){
        type="2"
      }else if(fname.indexOf(".jpg")!=-1 || fname.indexOf(".png")!=-1){
        type="1"
      }
      this.$upload.post("Car/UploadCarFile?CarID="+this.checkDocId+"&FileName="+fname+"&Type="+type, fd)
        .then((response) => {
         this.hideLoading()
          if (response.data.SUCCESS) {
                this.hideLoading()
                this.$message.success("上传成功")
                this.$refs.upload.clearFiles()
                this.getDocFiles()
          }else{
             this.hideLoading()
             this.$message.error("上传失败")
          }
        })
        .catch((error) => {
         this.hideLoading()
      });
    },
    getDocFiles(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({SNOWID:this.checkDocId}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:this.checkDocId})).toUpperCase())
        this.$http.post("Car/GetFileDataList", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                   this.fileList=response.data.DATA
                   this.chooseFileModal=true
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    downFile(e){
        window.open(e)
    },
    delFile(e){
        if(this.canDo){
            this.$confirm('确定删除附件？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
                this.sendDelFile(e)
            }).catch(() => {
            this.$message.info("已取消")    
          });
        }
    },
    sendDelFile(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({SNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:id})).toUpperCase())
        this.$http.post("Car/DeleteCarFile", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.getDocFiles()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style scoped>
.fileBox{overflow: hidden;margin: 20px 0;padding: 20px;background-color: #F8F8F9;}
.fileItem{border-radius: 3px;background-color: #FFFFFF;cursor: pointer;padding: 10px;float:left;margin-right: 20px;margin-bottom: 20px;width: 206px;}
.fileItem:nth-of-type(3n){margin-right: 0!important;}
.fileItem .name{font-size: 15px;font-weight: bold;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;color: #444;margin-bottom: 2px;}
.fileItem .keywords{font-size: 14px;font-weight: bold;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;margin-bottom: 5px;color: #909399;}
.fileItem .fileBtn{overflow: hidden;text-align: left;}
.fileItem span{margin-right: 10px;}
.noFile{text-align: center;font-size: 15px;color: #409EFF;}
.fileTitle{font-size: 16px;font-weight: bold;overflow: hidden;}
</style>