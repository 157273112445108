<template>
    <div class="views-main">
        <div class="views-main-title">
            <el-button type="primary" icon="el-icon-arrow-left" style="float:left;margin-right:20px;" size="small" @click="backBBTZ">返回</el-button>
            <p class="txt">生产奖励</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                
                <el-select v-model="fc" placeholder="选择风场"  filterable  clearable class="iwu">
                        <el-option v-for="item in fcList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                </el-select>
                <el-date-picker v-model="khrq" type="date" placeholder="选择考核日期" class="iw"></el-date-picker>
                <el-select v-model="bkhr" placeholder="选择被考核人"  filterable  clearable class="iwu">
                        <el-option v-for="item in userList" :key="item.SNOWID" :label="item.TRUENAME+'('+item.LOGINNAME+')'" :value="item.SNOWID" ></el-option>
                </el-select>
                <el-select v-model="lqr" placeholder="选择领取人"  filterable  clearable class="iwu">
                        <el-option v-for="item in userList" :key="item.SNOWID" :label="item.TRUENAME+'('+item.LOGINNAME+')'" :value="item.SNOWID" ></el-option>
                </el-select>

                <el-select v-model="ylq" placeholder="选择是否已领取"  filterable  clearable class="iwu">
                        <el-option label="已领取" value="1" ></el-option>
                        <el-option label="未领取" value="0" ></el-option>
                </el-select>   


                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-button type="success" @click="addPost" class="fr">添加</el-button>
                
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column prop="FACTORYNAME" label="风场"></el-table-column>
                    <el-table-column prop="KPIDATE" label="考核日期">
                        <template slot-scope="scope">
                            <span>{{scope.row.KPIDATE.split(" ")[0]}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="KPIUSERNAME" label="被考核人" width="80px"></el-table-column>
                    <el-table-column prop="KPIMONEY" label="绩效考核分配" width="110px"></el-table-column>
                    <el-table-column prop="PRODUCTMONEY" label="生产考核分配" width="110px"></el-table-column>
                    <el-table-column prop="CFELECT" label="超发电量" width="80px"></el-table-column>
                    <el-table-column prop="QFELECT" label="抢发电量" width="80px"></el-table-column>
                    <el-table-column prop="MONEY" label="实发金额" width="80px"></el-table-column>
                    <el-table-column prop="USERNAME" label="领取人姓名" width="90px"></el-table-column>
                    <el-table-column prop="ISRECEIVED" label="是否已领取" width="90px">
                        <template slot-scope="scope">
                            <span v-if="scope.row.ISRECEIVED==1">是</span>
                            <span v-else-if="scope.row.SEX==2">否</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="RECEIVEDTIME" label="领取时间">
                        <template slot-scope="scope">
                            <span v-if="scope.row.ISRECEIVED==1">{{scope.row.RECEIVEDTIME}}</span>
                            <span v-else>/</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="BZ" label="备注"></el-table-column>
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                        <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button>
                        <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="800px" top="30px">
            <el-form :model="postInfo" status-icon :rules="postrules"  ref="uForm" label-width="140px" class="demo-uForm">
                <el-form-item label="风场" prop="FACTORYID" >
                    <el-select v-model="postInfo.FACTORYID" placeholder="请选择风场" filterable  style="width:100%;" @change="bkheChange">
                            <el-option v-for="item in fcList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="考核日期" prop="KPIDATE" >
                    <el-date-picker v-model="postInfo.KPIDATE" type="date" placeholder="选择考核日期" style="width:100%;"></el-date-picker>
                </el-form-item>
                <el-form-item label="被考核人" prop="KPIUSERID" >
                    <el-select v-model="postInfo.KPIUSERID" placeholder="请选择被考核人" filterable  style="width:100%;" @change="bkheChange">
                            <el-option v-for="item in userList" :key="item.SNOWID" :label="item.TRUENAME+'('+item.LOGINNAME+')'" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="绩效考核分配（元）" prop="KPIMONEY">
                    <el-input  v-model="postInfo.KPIMONEY" autocomplete="off" placeholder="请输入绩效考核分配（元）"></el-input>
                </el-form-item>
                <el-form-item label="生产考核分配（元）" prop="PRODUCTMONEY">
                    <el-input  v-model="postInfo.PRODUCTMONEY" autocomplete="off" placeholder="请输入生产考核分配（元）"></el-input>
                </el-form-item>
                <el-form-item label="超发电量" prop="CFELECT">
                    <el-input  v-model="postInfo.CFELECT" autocomplete="off" placeholder="请输入超发电量"></el-input>
                </el-form-item>
                <el-form-item label="抢发电量" prop="QFELECT">
                    <el-input  v-model="postInfo.QFELECT" autocomplete="off" placeholder="请输入抢发电量"></el-input>
                </el-form-item>
                <el-form-item label="实发金额" prop="MONEY">
                    <el-input  v-model="postInfo.MONEY" autocomplete="off" placeholder="请输入实发金额"></el-input>
                </el-form-item>
                <el-form-item label="领取人" prop="USERID" >
                    <el-select v-model="postInfo.USERID" placeholder="请选择领取人" filterable  style="width:100%;" @change="lqrChange">
                            <el-option v-for="item in userList" :key="item.SNOWID" :label="item.TRUENAME+'('+item.LOGINNAME+')'" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="是否已领取">
                    <el-radio v-model="postInfo.ISRECEIVED" label="1">是</el-radio>
                    <el-radio v-model="postInfo.ISRECEIVED" label="0">否</el-radio>
                </el-form-item>
                <el-form-item label="领取时间" prop="RECEIVEDTIME"  v-if="postInfo.ISRECEIVED=='1'">
                    <el-date-picker v-model="postInfo.RECEIVEDTIME" type="datetime" placeholder="选择领取时间" style="width:100%;"></el-date-picker>
                </el-form-item>

                 <el-form-item label="备注">
                    <el-input  v-model="postInfo.BZ" autocomplete="off" placeholder="请输入备注"></el-input>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" @click="submitPostForm()">提交</el-button>
                    <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
export default {
  name: "SCJL",
  data() {
    var KPIDATE = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择考核日期"));
      } else {
        callback();
      }
    };
    var KPIUSERID = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择被考核人"));
      } else {
        callback();
      }
    };
    var FACTORYID = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择风场"));
      } else {
        callback();
      }
    };
    var KPIMONEY= (rule, value, callback) => {
      if (value === '') {
          callback(new Error('请输入绩效考核分配'));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('绩效考核分配只能是数字'));
        }else if(value<0){
            callback(new Error('绩效考核分配不能小于0'));
        }else {
          callback();
        }
    };
    var PRODUCTMONEY= (rule, value, callback) => {
      if (value === '') {
          callback(new Error('请输入生产考核分配'));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('生产考核分配只能是数字'));
        }else if(value<0){
            callback(new Error('生产考核分配不能小于0'));
        }else {
          callback();
        }
    };
    var CFELECT= (rule, value, callback) => {
      if (value === '') {
          callback(new Error('请输入超发电量'));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('超发电量只能是数字'));
        }else if(value<0){
            callback(new Error('超发电量不能小于0'));
        }else {
          callback();
        }
    };
    var QFELECT= (rule, value, callback) => {
      if (value === '') {
          callback(new Error('请输入抢发电量'));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('抢发电量只能是数字'));
        }else if(value<0){
            callback(new Error('抢发电量不能小于0'));
        }else {
          callback();
        }
    };
    var MONEY= (rule, value, callback) => {
      if (value === '') {
          callback(new Error('请输入实发金额'));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('实发金额只能是数字'));
        }else if(value<0){
            callback(new Error('实发金额不能小于0'));
        }else {
          callback();
        }
    };
    var USERID = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择领取人"));
      } else {
        callback();
      }
    };
    var RECEIVEDTIME = (rule, value, callback) => {
      if (value === "" && this.postInfo.ISRECEIVED === "1") {
        callback(new Error("请选择领取时间"));
      } else {
        callback();
      }
    };
    

    return {
        canDo:true,
        khrq:"",
        ylq:"",
        fcList:[],
        fc:"",
        userList:[],
        bkhr:"",
        lqr:"",


        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,


        doTypeStr:"",
        doType:"",
        postModal:false,
        postInfo:{},
        postrules: {
            KPIDATE: [{ validator: KPIDATE, trigger: "blur" }],
            KPIUSERID: [{ validator: KPIUSERID, trigger: "blur" }],
            FACTORYID: [{ validator: FACTORYID, trigger: "blur" }],
            KPIMONEY: [{ validator: KPIMONEY, trigger: "blur" }],
            PRODUCTMONEY: [{ validator: PRODUCTMONEY, trigger: "blur" }],
            CFELECT: [{ validator: CFELECT, trigger: "blur" }],
            QFELECT: [{ validator: QFELECT, trigger: "blur" }],
            MONEY: [{ validator: MONEY, trigger: "blur" }],
            USERID: [{ validator: USERID, trigger: "blur" }],
            RECEIVEDTIME: [{ validator: RECEIVEDTIME, trigger: "blur" }],
        },
    };
  },
  mounted() {
    this.loadItem=null
    this.getPostList()
    this.getFactoryList()
    this.getUserList()
  },
  methods: {
    getFactoryList(){
        var md5_data =this.comjs.requestDataEncrypt({}, this.$store.state.token,this.$md5(this.comjs.sortAsc({})).toUpperCase())
        this.$http.post("Factory/GetFactoryWithoutPage", md5_data)
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.fcList=response.data.DATA  
                } 
            })
    },
    bkheChange(e){
        if(e==""){
            this.postInfo.KPIUSERNAME ==""
        }else{
            for(var i=0;i<this.userList.length;i++){
                if(this.userList[i].SNOWID == e){
                    this.postInfo.KPIUSERNAME = this.userList[i].TRUENAME
                }
            }
        }
    },
    lqrChange(e){
        if(e==""){
            this.postInfo.USERNAME ==""
        }else{
            for(var i=0;i<this.userList.length;i++){
                if(this.userList[i].SNOWID == e){
                    this.postInfo.USERNAME = this.userList[i].TRUENAME
                }
            }
        }
    },
    getUserList(){
        var params={
            PAGEINDEX:1,
            PAGESIZE:1000,
            TRUENAME:"",
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("UserManage/GetUserList", md5_data)
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.userList=response.data.DATA
                } 
            })
    },
    getPostList(){
        this.showLoading("请稍后")
        var khrqStr=""
        if(this.khrq!=""){
            khrqStr = this.comjs.getDateMinStrByDate(this.khrq)
        }
        var params={
            PAGEINDEX:this.PageIndex,
            PAGESIZE:this.PageSize,
            KPIDATE:khrqStr,
            FACTORYID:this.fc,
            KPIUSERID:this.bkhr,
            USERID:this.lqr,
            ISRECEIVED:this.ylq,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Kpi/GetKpiSumList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
        });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    addPost(){
        this.postInfo={
            KPIDATE:"",
            KPIUSERID:"",
            KPIUSERNAME:"",
            INDEXX:"",
            FACTORYID:"",
            KPIMONEY:"",
            PRODUCTMONEY:"",
            CFELECT:"",
            QFELECT:"",
            MONEY:"",
            USERID:"",
            USERNAME:"",
            ISRECEIVED:"1",
            RECEIVEDTIME:"",
            BZ:"",
        }
        this.doTypeStr="添加生产奖励"
        this.doType="1"
        this.postModal=true
    },
    sendAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("Kpi/CreateNewKpiSum", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    editPost(item){
        this.postInfo={
            SNOWID:item.SNOWID,
            KPIDATE:new Date(item.KPIDATE),
            KPIUSERID:item.KPIUSERID,
            KPIUSERNAME:item.KPIUSERNAME,
            INDEXX:item.INDEXX,
            FACTORYID:item.FACTORYID,
            KPIMONEY:item.KPIMONEY,
            PRODUCTMONEY:item.PRODUCTMONEY,
            CFELECT:item.CFELECT,
            QFELECT:item.QFELECT,
            MONEY:item.MONEY,
            USERID:item.USERID,
            USERNAME:item.USERNAME,
            ISRECEIVED:item.ISRECEIVED,
            RECEIVEDTIME:new Date(item.RECEIVEDTIME),
            BZ:item.BZ,
        }
        this.doTypeStr="修改生产奖励"
        this.doType="2"
        this.postModal=true
    },
    sendEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("Kpi/EditKpiSum", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.postModal=false
                    this.getPostList();
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    delPost(item){
        if(this.canDo){
            this.$confirm('确定删除生产奖励？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
            this.sendDel(item.SNOWID)
            }).catch(() => {
            this.$message.info("已取消")    
          });
        }

    },
    sendDel(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({SNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:id})).toUpperCase())
        this.$http.post("Kpi/DeleteKpiSum", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    submitPostForm() {
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    
                    var str1=this.comjs.getDateMinStrByDate(this.postInfo.KPIDATE)
                    this.postInfo.KPIDATE = str1

                    if(this.postInfo.ISRECEIVED=="1"){
                        var str2=this.comjs.getDateStrByDate(this.postInfo.RECEIVEDTIME)
                        this.postInfo.RECEIVEDTIME=str2
                        this.postInfo.ISRECEIVED = parseInt(this.postInfo.ISRECEIVED)
                    }
                    //console.log(this.postInfo)
                    if(this.doType=="1"){
                        this.sendAdd()
                    }else if(this.doType=="2"){
                        this.sendEdit()
                    }
                } else {
                    return false;
                }
            });
        }
    },
    backBBTZ(){
        this.$router.replace('/XTPTZH')
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style >
</style>