<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">临时计划管理</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                
                <el-date-picker v-model="stime" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" class="iww"> </el-date-picker>
                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-button type="success" @click="addPost" class="fr">添加</el-button>
                
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column prop="STARTDATE" label="计划开始日期"></el-table-column>
                    <el-table-column prop="ENDDATE" label="计划结束日期"></el-table-column>
                    <el-table-column prop="CONTEXT" label="计划内容"></el-table-column>
                    <el-table-column label="公司领导是否确认">
                      <template slot-scope="scope">
                        <span v-if="scope.row.COMPANYLEADERSNOWID">确认人: {{scope.row.COMPANYLEADERNAME ? scope.row.COMPANYLEADERNAME : '/'}}</span>
                        <span v-else style="color:#E6A23C">未确认</span>
                      </template>
                    </el-table-column>
                    <el-table-column label="风场领导是否确认">
                      <template slot-scope="scope">
                        <span v-if="scope.row.FACTORYLEADERSNOWID">确认人: {{scope.row.FACTORYLEADERNAME ? scope.row.FACTORYLEADERNAME : '/'}}</span>
                        <span v-else style="color:#E6A23C">未确认</span>
                      </template>
                    </el-table-column>
                    <el-table-column prop="STATUS" label="完成情况">
                        <template slot-scope="scope">
                            <span v-if="scope.row.STATUS==0" style="color:#E6A23C">未执行</span>
                            <span v-else-if="scope.row.STATUS==1" style="color:#409EFF">执行中</span>
                            <span v-else style="color:#67C23A">已完成</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                        <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button>
                        <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
                        <el-button @click="verify('风场',scope.row.SNOWID)" v-if="isF && !scope.row.FACTORYLEADERSNOWID" type="text" size="small" style="color:#67C23A">风场领导确认</el-button>
                        <el-button @click="verify('公司',scope.row.SNOWID)" v-if="isG && !scope.row.COMPANYLEADERNAME" type="text" size="small" style="color:#67C23A">公司领导确认</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="500px">
            <el-form :model="postInfo" status-icon :rules="postrules"  ref="uForm" label-width="100px" class="demo-uForm">
                <el-form-item label="计划开始日期" prop="STARTDATES">
                    <el-date-picker v-model="postInfo.STARTDATES" type="date" placeholder="计划开始日期"></el-date-picker>
                </el-form-item>
                <el-form-item label="计划结束日期" prop="ENDDATES">
                    <el-date-picker v-model="postInfo.ENDDATES" type="date" placeholder="计划结束日期"></el-date-picker>
                </el-form-item>
                <el-form-item label="计划内容" prop="CONTEXT">
                     <el-input type="textarea"  class="tjarea" v-model="postInfo.CONTEXT" autocomplete="off"></el-input>
                </el-form-item> 
                <el-form-item label="完成情况" prop="STATUS">
                    <el-radio v-model="postInfo.STATUS" label="0">未执行</el-radio>
                    <el-radio v-model="postInfo.STATUS" label="1">执行中</el-radio>
                    <el-radio v-model="postInfo.STATUS" label="2">已完成</el-radio>
                </el-form-item>
                <el-form-item>
                <el-button type="primary" @click="submitPostForm()">提交</el-button>
                <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<style>
.tjarea  .el-textarea__inner{height: 120px!important;}
</style>
<script>
export default {
  name: "PostManage",
  data() {
    var STARTDATES = (rule, value, callback) => {
      if (!value || value === "") {
        callback(new Error("请选择开始日期"));
      } else {
        callback();
      }
    };
    var ENDDATES = (rule, value, callback) => {
      if (!value || value === "") {
        callback(new Error("请选择结束日期"));
      } else {
        callback();
      }
    };
    var CONTEXT = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入计划内容"));
      } else {
        callback();
      }
    };
    return {
        canDo:true,
        
        stime:[],
       

        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,
        isG: false, // 是否是公司领导
        isF: false, // 是否是风场领导
        doTypeStr:"",
        doType:"",
        postModal:false,
        postInfo:{},
        postrules: {
            ENDDATES: [{ validator: ENDDATES, trigger: "blur" }],
            STARTDATES: [{ validator: STARTDATES, trigger: "blur" }],
            CONTEXT: [{ validator: CONTEXT, trigger: "blur" }],
        },
    };
  },
  mounted() {
    let list = JSON.parse(localStorage.getItem('setPostList'))
    if(list.length > 0){
      list.forEach((item)=>{
        if(item.NAME === '风场领导'){
          this.isF = true
        }
        if(item.NAME === '公司领导'){
          this.isG = true
        }
      })
    }
    this.loadItem=null
    this.getPostList()
  },
  methods: {
    getPostList(){
        this.showLoading("请稍后")
        var st=""
        var et=""
        if(this.stime.length==2){
            st = this.comjs.getDateMinStrByDate(this.stime[0])
            et = this.comjs.getDateMinStrByDate(this.stime[1])
        }
        var params={
            PAGEINDEX:this.PageIndex,
            PAGESIZE:this.PageSize,
            STARTTIME:st,
            ENDTIME:et,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Planning/GetTempPlanningList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
        });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    addPost(){
        this.postInfo={
            ENDDATES:"",
            STARTDATES:"",
            CONTEXT:"",
            STATUS:"0",
        }
        this.doTypeStr="添加年度临时计划"
        this.doType="1"
        this.postModal=true
    },
    sendAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        var d={
            ENDDATE:this.comjs.getDateMinStrByDate(this.postInfo.ENDDATES),
            STARTDATE:this.comjs.getDateMinStrByDate(this.postInfo.STARTDATES),
            CONTEXT:this.postInfo.CONTEXT,
            STATUS:this.postInfo.STATUS,
        }
        var md5_data =this.comjs.requestDataEncrypt(d, this.$store.state.token,this.$md5(this.comjs.sortAsc(d)).toUpperCase())
        this.$http.post("Planning/CreateTempPlanning", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    editPost(item){
        this.postInfo={
            SNOWID:item.SNOWID,
            ENDDATES:new Date(item.ENDDATE),
            STARTDATES:new Date(item.STARTDATE),
            CONTEXT:item.CONTEXT,
            STATUS:item.STATUS.toString(),
        }
        this.doTypeStr="修改年度临时计划"
        this.doType="2"
        this.postModal=true
    },
    sendEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        var d={
            SNOWID:this.postInfo.SNOWID,
            ENDDATE:this.comjs.getDateMinStrByDate(this.postInfo.ENDDATES),
            STARTDATE:this.comjs.getDateMinStrByDate(this.postInfo.STARTDATES),
            CONTEXT:this.postInfo.CONTEXT,
            STATUS:this.postInfo.STATUS,
        }
        var md5_data =this.comjs.requestDataEncrypt(d, this.$store.state.token,this.$md5(this.comjs.sortAsc(d)).toUpperCase())
        this.$http.post("Planning/EditTempPlanning", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.postModal=false
                    this.getPostList();
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    delPost(item){
        if(this.canDo){
            this.$confirm('确定删除临时计划？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
            this.sendDel(item.SNOWID)
            }).catch(() => {
            this.$message.info("已取消")    
          });
        }

    },
    sendDel(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({SNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:id})).toUpperCase())
        this.$http.post("Planning/DeleteYearCostPlan", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    verify(type,id){
      this.$confirm(`是否进行${type}领导确认？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.canDo=false
        this.showLoading("请稍后")
        let url = ''
        if(type === '风场'){
          url = 'Planning/FactoryAgree'
        }else if(type === '公司'){
          url = 'Planning/CompanyAgree'
        }
        var md5_data =this.comjs.requestDataEncrypt({SnowID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:id})).toUpperCase())
        this.$http.post(url, md5_data)
            .then((response) => {
              this.canDo=true
              this.hideLoading()
              if (response.data.SUCCESS) {
                this.$message.success("确认完成")
                this.postModal=false
                this.getPostList()
              } else {
                this.errorRequest(response.data.DATA,response.data.MSG,true)
              }
            })
            .catch((error) => {
              this.$message.error("请求出错")
              this.hideLoading()
              this.canDo=true
            });
      }).catch(() => {});
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    submitPostForm() {
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    if(this.doType=="1"){
                        this.sendAdd()
                    }else if(this.doType=="2"){
                        this.sendEdit()
                    }
                } else {
                    return false;
                }
            });
        }
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style >
</style>