<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">月报表及各汇总台账管理</p>
        </div>
        <div class="tableBox" style="top:50px;">
                <div class="ksItemA" v-show="dpage['1301']">
                  <p class="t">日发电量及机组设备故障情况</p>
                  <p class="n">
                      <!-- <span @click="showRFDL">日发电量</span>   -->
                      <span @click="showJZSBGZQK">机组设备故障情况</span>  
                      <span @click="showQSMTZ">专业设备全寿命周期管理</span>  
                  </p>
                </div>
                <div class="ksItemA"  v-show="dpage['1302'] || dpage['1303'] || dpage['1304']">
                  <p class="t">生产奖励与考核管理</p>
                  <p class="n">
                      <!-- <span @click="showSCJL">生产奖励</span>  
                      <span @click="showKHX">考核项</span>  
                      <span @click="showKHXM">考核项目</span>  
                      <span @click="showKHGL">考核管理</span>   -->
                      <span @click="showKHLB" v-show="dpage['1302']">生产考核</span>
                      <span @click="showYZFA" v-show="dpage['1303']">优质方案</span>
                      <span @click="showGZZJJGZJH" v-show="dpage['1304']">工作总结及工作计划</span>
                  </p>
                </div>
                <!-- <div class="ksItem" >
                  各系统登录平台账号密码查询
                </div> -->
        </div>
        <el-dialog title="日发电量" :visible.sync="rfdlModal" width="1300px" top="30px">
            <MRFDL :style="{height:dilogHeight+ 'px'}"></MRFDL>
        </el-dialog>
        <el-dialog title="机组设备故障情况" :visible.sync="jzgzsbqkModal" width="1300px" top="30px">
            <JZGZSBQK :style="{height:dilogHeight+ 'px'}"></JZGZSBQK>
        </el-dialog>
    </div>
</template>
<script>
var h=document.body.clientHeight
import MRFDL from "../../components/MRFDL"
import JZGZSBQK from "../../components/JZGZSBQK"
export default {
  name: "KSFL",
  components:{
    MRFDL,//日发电量
    JZGZSBQK,//机组故障设备情况
  },
  data() {

    return {
        dilogHeight:0,
        rfdlModal:false, //日发电量
        jzgzsbqkModal:false,//机组故障设备情况
        canDo:true, 

        dpage:{
          "1301":false,
          "1302":false,
          "1303":false,
          "1304":false,
        }
    };
  },
  mounted() {
    this.dilogHeight = h-160
    this.loadItem=null
    this.getUserRoot()
  },
  methods: {
    getUserRoot(){
        this.canDo=false
        this.showLoading("请稍后")
        var params={
          UserSnowID:  this.$store.state.guid
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("RMenu/GetUserMenu", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    var d=response.data.DATA
                    if(d!=""){
                      if(d=="*"){
                          for(var item in this.dpage){
                            this.dpage[item] =true
                          }
                      }else{
                        if(d.indexOf("1301") != -1){
                          this.dpage["1301"]=true
                        }
                        if(d.indexOf("1302") != -1){
                          this.dpage["1302"]=true
                        }
                        if(d.indexOf("1303") != -1){
                          this.dpage["1303"]=true
                        }
                        if(d.indexOf("1304") != -1){
                          this.dpage["1304"]=true
                        }
                      }
                    }
                }
            })
    },
    showRFDL(){//日发电量
      this.rfdlModal=true
    },
    showJZSBGZQK(){//机组故障设备情况
      this.jzgzsbqkModal=true
    },
    showKHX(){
      this.$router.replace('/KHX')
    },
    showKHXM(){
      this.$router.replace('/KHXM')
    },
    showKHGL(){
      this.$router.replace('/KHGL')
    },
    showSCJL(){
      this.$router.replace('/SCJL')
    },
    showKHLB(){
      this.$router.replace('/KHLB')
    },
    showYZFA(){
      this.$router.replace('/YZFA')
    },
    showGZZJJGZJH(){
      this.$router.replace('/GZZJJGZJH')
    },
    showQSMTZ(){
      this.$router.replace('/QSMZQGLTZ')
    },

  },
  computed: {
  },
  watch: {
  },
};
</script>
<style scoped>
    .el-collapse-item__header{padding: 0 20px;font-size: 16px;}
    .el-collapse .is-active{color: #409EFF;}
    .el-collapse-item__content{padding-left:20px;}

    .ksItem{width: 600px;height: 80px;border-radius: 3px;cursor: pointer;color: #FFFFFF;background-color: #409EFF;font-size: 16px;margin-left: 30px;margin-top: 30px;float:left;padding:0 30px;display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;}
   .ksItem:hover{background-color: #5FADFC;}
   .ksItemA{width: 600px;height: 80px;border-radius: 3px;cursor: pointer;color: #FFFFFF;background-color: #409EFF;font-size: 16px;margin-left: 30px;margin-top: 30px;float:left;padding:0 30px;}
   .ksItemA .t{text-align:center;margin-top:20px;}
   .ksItemA .n{text-align: center;margin-top: 5px;}
   .ksItemA .n span{color: #F56C6C;text-align: center;margin-right: 20px;}
</style>