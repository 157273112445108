<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">日常会议通知后勤综合管理</p>
        </div>
        <div class="tableBox" style="top:50px;">
              <div class="ksItemA" >
                <p class="t"> 生产业务报告申请，议案申请，印章申请审批</p>
                <p class="n" v-show="dpage['1401']">
                  <span @click="showYZSQSP">印章申请审批</span>  
                </p>
              </div>
              <div class="ksItem" @click="showFYZCGL" v-show="dpage['1402']">
                生产人员费用报销管理
              </div>
              <div class="ksItemA" >
                <p class="t">后勤安全保障和车辆维护及加油申请审批</p>
                <p class="n">
                  <span @click="showCLGL" v-show="dpage['1403']">车辆管理</span>  
                  <span @click="showJYBXJL" v-show="dpage['1404']">加油报销记录</span>  
                </p>
              </div>
              <div class="ksItem"  @click="toSTAQGL()" v-show="dpage['1405']">
                食堂管理
              </div>
              <div class="ksItemA" style="display: none">
                <p class="t">疫情防控管理</p>
                <p class="n">
                  <span @click="showFYPZ">防疫配置</span>
                </p>
              </div>
        </div>
        <el-dialog title="防疫配置" :visible.sync="fypzModal" width="800px" top="30px">
            <FYPZ :style="{height:dilogHeight+ 'px'}" style="overflow:auto"></FYPZ>
        </el-dialog>
    </div>
</template>
<script>
var h=document.body.clientHeight
import FYPZ from "../../components/FYPZ"
export default {
  name: "KSFL",
  components:{
    FYPZ,//防疫配置
  },
  data() {

    return {
        canDo:true, 
        fypzModal:false,
        dilogHeight:0,
        dpage:{
          "1401":false,
          "1402":false,
          "1403":false,
          "1404":false,
          "1405":false,
        }
    };
  },
  mounted() {
    this.dilogHeight = h-180
    this.loadItem=null
    this.getUserRoot()
  },
  methods: {
    getUserRoot(){
        this.canDo=false
        this.showLoading("请稍后")
        var params={
          UserSnowID:  this.$store.state.guid
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("RMenu/GetUserMenu", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    var d=response.data.DATA
                    if(d!=""){
                      if(d=="*"){
                          for(var item in this.dpage){
                            this.dpage[item] =true
                          }
                      }else{
                        if(d.indexOf("1401") != -1){
                          this.dpage["1401"]=true
                        }
                        if(d.indexOf("1402") != -1){
                          this.dpage["1402"]=true
                        }
                        if(d.indexOf("1403") != -1){
                          this.dpage["1403"]=true
                        }
                        if(d.indexOf("1404") != -1){
                          this.dpage["1404"]=true
                        }
                        if(d.indexOf("1405") != -1){
                          this.dpage["1405"]=true
                        }
                      }
                    }
                }
            })
    },
    toSTAQGL(){
      this.$router.replace('/SCanteen')
    },
    showCLGL(){
      this.$router.replace('/CLGL')
    },
    showJYBXJL(){
      this.$router.replace('/JYBXJL')
    },
    showFYPZ(){
      this.fypzModal=true
    },
    showFYZCGL(){
      this.$router.replace('/FYZCGL')
    },

    showYZSQSP(){
      this.$router.replace('/YZSQSP')
    }

  },
  computed: {
  },
  watch: {
  },
};
</script>
<style scoped>
    .el-collapse-item__header{padding: 0 20px;font-size: 16px;}
    .el-collapse .is-active{color: #409EFF;}
    .el-collapse-item__content{padding-left:20px;}
    .ksItem{width: 600px;height: 80px;border-radius: 3px;cursor: pointer;color: #FFFFFF;background-color: #409EFF;font-size: 16px;margin-left: 30px;margin-top: 30px;float:left;padding:0 30px;display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;}
   .ksItem:hover{background-color: #5FADFC;}
   .ksItemA{width: 600px;height: 80px;border-radius: 3px;cursor: pointer;color: #FFFFFF;background-color: #409EFF;font-size: 16px;margin-left: 30px;margin-top: 30px;float:left;padding:0 30px;}
   .ksItemA .t{text-align:center;margin-top:20px;}
   .ksItemA .n{text-align: center;margin-top: 5px;}
   .ksItemA .n span{color: #F56C6C;text-align: center;margin-right: 20px;}

</style>