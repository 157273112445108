<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">发电计划设置</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                
                <el-select v-model="fid" placeholder="风场"  filterable  clearable class="iwu fl">
                        <el-option v-for="item in factoryList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                </el-select>
                <el-date-picker v-model="jhsj"  type="monthrange" range-separator="至" start-placeholder="开始月份" end-placeholder="结束月份" class="fl">
                </el-date-picker>
                <el-button type="primary" @click="searchPost" class="fl" style="margin-left:12px;">查询</el-button>
                <el-button type="success" @click="addPost" class="fr">添加</el-button>
                
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column prop="FACTORY.NAME" label="风场名称"></el-table-column>
                    <!-- <el-table-column prop="STARTTIME" label="开始时间"></el-table-column>
                    <el-table-column prop="ENDTIME" label="结束时间"></el-table-column> -->
                    <el-table-column prop="STARTMoon" label="开始时间"></el-table-column>
                    <el-table-column prop="ENDTMoon" label="结束时间"></el-table-column>
                    <el-table-column prop="LOWPOWERCOUNT" label="保证发电量"></el-table-column>
                    <el-table-column prop="TARPOWERCOUNT" label="力争完成发电量"></el-table-column>
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                        <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button>
                        <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="600px">
            <el-form :model="postInfo" status-icon :rules="postrules"  ref="uForm" label-width="100px" class="demo-uForm">
                <el-form-item label="风场" prop="FACTORYID">
                        <el-select v-model="postInfo.FACTORYID" placeholder="请选择风场"  filterable  style="width:100%;">
                                <el-option v-for="item in factoryList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                        </el-select>
                </el-form-item>
                <el-form-item label="开始时间" prop="STARTTIME">
                       <el-date-picker v-model="postInfo.STARTTIME" type="month" placeholder="选择日期" style="width:100%;"> </el-date-picker>
                </el-form-item>
                <el-form-item label="结束时间" prop="ENDTIME">
                        <el-date-picker v-model="postInfo.ENDTIME" type="month" placeholder="选择日期" style="width:100%;"> </el-date-picker>
                </el-form-item>
                <el-form-item label="保证发电量" prop="LOWPOWERCOUNT">
                        <el-input v-model="postInfo.LOWPOWERCOUNT" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="力争完成发电量" prop="TARPOWERCOUNT">
                        <el-input v-model="postInfo.TARPOWERCOUNT" autocomplete="off"></el-input>
                </el-form-item>
            
                <el-form-item>
                <el-button type="primary" @click="submitPostForm()">提交</el-button>
                <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
export default {
  name: "FDJHSZ",
  data() {
    var FACTORYID = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择风场"));
      } else {
        callback();
      }
    };
    var STARTTIME = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入开始时间"));
      } else {
        callback();
      }
    };
    var ENDTIME = (rule, value, callback) => {
      var st=this.postInfo.STARTTIME
      var stt=0
      if(st!=""){
        if(typeof st == "string"){
            st =new Date(st)
        }
        stt = st.getTime()
      }
      var ett=0
      if(value!=""){
        if(typeof value == "string"){
            value =new Date(value)
        }
        ett=value.getTime()
      }
      if (value === "") {
        callback(new Error("请输入结束时间"));
      }else if(stt!=0 && ett!=0 && ett<=stt){
         callback(new Error("结束时间必须大于开始时间"));
      } else {
        callback();
      }
    };
    var LOWPOWERCOUNT=(rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入保证发电量'));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('保证发电量只能是数字'));
        }else if(!/^\+?(0|[1-9][0-9]*)$/.test(value)){
            callback(new Error('只能输入大于等于0的整数'));
        }else {
          callback();
        }
    };
    var TARPOWERCOUNT=(rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入力争完成发电量'));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('力争完成发电量只能是数字'));
        }else if(!/^\+?(0|[1-9][0-9]*)$/.test(value)){
            callback(new Error('只能输入大于等于0的整数'));
        }else {
          callback();
        }
    };
    return {
        canDo:true,
        
        fid:"",
        jhsj:null,
       
        factoryList:[],

        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,


        doTypeStr:"",
        doType:"",
        postModal:false,
        postInfo:{},
        postrules: {
            FACTORYID: [{ validator: FACTORYID, trigger: "blur" }],
            STARTTIME: [{ validator: STARTTIME, trigger: "blur" }],
            ENDTIME: [{ validator: ENDTIME, trigger: "blur" }],
            LOWPOWERCOUNT: [{ validator: LOWPOWERCOUNT, trigger: "blur" }],
            TARPOWERCOUNT: [{ validator: TARPOWERCOUNT, trigger: "blur" }],
        },
    };
  },
  mounted() {
    this.loadItem=null
    this.getPostList()
    this.getFactoryList()
  },
  methods: {
    getFactoryList(){
        var md5_data =this.comjs.requestDataEncrypt({}, this.$store.state.token,this.$md5(this.comjs.sortAsc({})).toUpperCase())
        this.$http.post("Factory/GetFactoryWithoutPage", md5_data)
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.factoryList=response.data.DATA  
                } 
            })
    },
    getPostList(){
        this.showLoading("请稍后")
        var st = ""
        var et = ""
        if(this.jhsj!=null && this.jhsj!="" && this.jhsj.length==2){
            st=this.comjs.getDateMinStrByDate(this.jhsj[0])
            var lastday=this.comjs.getDaysInCurrentMonth(this.jhsj[1])
            var  ett=this.comjs.getDateMinStrByDate(this.jhsj[1]).split("-")
            et=ett[0]+"-"+ett[1]+"-"+lastday
        }


        var params={
            PAGEINDEX:this.PageIndex,
            PAGESIZE:this.PageSize,
            STARTTIME:st,
            ENDTIME:et,
            FACTORYID:this.fid,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("GenelecPower/GetGenelecPowerList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                var plist=response.data.DATA
                var npl=[]
                for(var i=0;i<plist.length;i++){
                    let d=plist[i]
                    d.STARTMoon=""
                    if(d.STARTTIME && d.STARTTIME!="" && d.STARTTIME.indexOf("-")!= -1){
                        let sdd=d.STARTTIME.split("-")
                        d.STARTMoon = sdd[0]+"年"+sdd[1]+"月"
                    }
                    d.ENDTMoon=""
                    if(d.ENDTIME && d.ENDTIME!="" && d.ENDTIME.indexOf("-")!= -1){
                        let edd=d.ENDTIME.split("-")
                        d.ENDTMoon = edd[0]+"年"+edd[1]+"月"
                    }
                    npl.push(d)
                }
                this.postList=npl
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
        });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    addPost(){
        this.postInfo={
            FACTORYID:"",
            STARTTIME:"",
            ENDTIME:"",
            TARPOWERCOUNT:"",
            LOWPOWERCOUNT:"",
        }
        this.doTypeStr="添加发电计划"
        this.doType="1"
        this.postModal=true
    },
    sendAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("GenelecPower/CreateNewGenelecPower", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    editPost(item){
        this.postInfo={
            SNOWID:item.SNOWID,
            FACTORYID:item.FACTORYID,
            STARTTIME:new Date(item.STARTTIME),
            ENDTIME:new Date(item.ENDTIME),
            TARPOWERCOUNT:item.TARPOWERCOUNT,
            LOWPOWERCOUNT:item.LOWPOWERCOUNT,
        }
        this.doTypeStr="修改发电计划"
        this.doType="2"
        this.postModal=true
    },
    sendEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("GenelecPower/EditGenelecPower", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.postModal=false
                    this.getPostList();
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    delPost(item){
        if(this.canDo){
            this.$confirm('确定删除？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
            this.sendDel(item.SNOWID)
            }).catch(() => {
            this.$message.info("已取消")    
          });
        }

    },
    sendDel(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({SNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:id})).toUpperCase())
        this.$http.post("GenelecPower/DeleteGenelecPower", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    submitPostForm() {
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    if(typeof this.postInfo.STARTTIME !="string"){
                         this.postInfo.STARTTIME = this.comjs.getDateMinStrByDate(this.postInfo.STARTTIME)
                    }
                    if(typeof this.postInfo.ENDTIME !="string"){
                         this.postInfo.ENDTIME = this.comjs.getDateMinStrByDate(this.postInfo.ENDTIME)
                    }
                    this.postInfo.LOWPOWERCOUNT  = parseFloat(this.postInfo.LOWPOWERCOUNT)
                    this.postInfo.TARPOWERCOUNT  = parseFloat(this.postInfo.TARPOWERCOUNT)
                    if(this.doType=="1"){
                        this.sendAdd()
                    }else if(this.doType=="2"){
                        this.sendEdit()
                    }
                } else {
                    return false;
                }
            });
        }
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style >
</style>