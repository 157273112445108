<template>
  <div class="views-main">
    <div class="views-main-title">
      <p class="txt">部门管理</p>
    </div>
    <div class="tableBox" style="top:50px;">
      <div class="views-main-search">
        <el-input placeholder="输入关键字进行过滤" v-model="filterText" style="width:200px;"> </el-input>
        <el-button type="primary" @click="sendQuery" class="ml10">查询</el-button>
        <el-button type="success" @click="addDep" class="ml10">添加部门</el-button>
      </div>
      <div class="views-main-body">
          <el-tree :props="props" :data="blist"  ref="tree" node-key="guid"  :filter-node-method="filterNode" draggable @node-drop="handleDrop" :allow-drop="allowDrop">
            <span class="custom-tree-node" slot-scope="{ node, data }">
              <span class="treeName">{{ node.label }}</span>
              <!-- <span class="managerName">(领导：{{ data.mname }})</span> -->
              <span>
                <!-- <el-button type="text" size="mini" @click.stop="() => append(node, data)" style="color:#19be6b">
                  添加
                </el-button> -->
                <el-button type="text" size="mini" @click.stop="() => edit(node, data)" style="color:#ff9900">
                  编辑
                </el-button>
                <!-- <el-button type="text" size="mini" @click.stop="() => fun(node, data)" style="color:#409EFF">
                  权限设置
                </el-button> -->
                <el-button type="text" size="mini" @click.stop="() => remove(node, data)" style="color:#ed4014">
                  删除
                </el-button>
              </span>
            </span>
          </el-tree>
      </div>
    </div>
    <el-dialog :title="DepModalTitle" :visible.sync="DepModal" width="500px">
      <el-form :model="DepInfo" status-icon :rules="DepRules"  ref="DepForm" label-width="110px" class="demo-DepForm" >
        <el-form-item label="部门名称" prop="NAME">
          <el-input v-model="DepInfo.NAME" autocomplete="off"></el-input>
        </el-form-item>
        <!-- <el-form-item label="部门领导">
            <el-select v-model="DepInfo.MANAGERID" placeholder="请选择部门领导" filterable clearable  @change="changeUser" style="width:350px;">
                    <el-option v-for="item in userList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="是否可用">
           <el-radio v-model="DepInfo.ISENABLE" label="1">可用</el-radio>
           <el-radio v-model="DepInfo.ISENABLE" label="0">不可用</el-radio>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="DepInfo.BZ" autocomplete="off"></el-input>
        </el-form-item> -->
        <el-form-item>
          <el-button type="primary" @click="submitDepForm()">提交</el-button>
          <el-button @click="closeDepForm()">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog :title="FunModalTitle" :visible.sync="FunModal" width="600px">
          <div>
              <el-tree :data="funList" show-checkbox node-key="SNOWID"   :default-expanded-keys="expandItems" :default-checked-keys="checkPages" :props="funTreeProps" @check-change="funTreeCheckChange">
                  <span class="custom-tree-node funTreeRow" slot-scope="{ node, data }">
                        <span class="funtreeName">{{ node.label }}</span>
                        <span class="funItemList">
                            <el-checkbox  v-for="(fun) in data.FUNCLIST"   :key="fun.SNOWID" v-model="fun.ISCHECK" @change="funChechBoxChange" @click.stop.native="funChechBoxClick(fun.SNOWID)"> {{fun.FUNCNAME}} </el-checkbox>
                        </span> 
                  </span>
              </el-tree>
          </div>
          <div style="margin-top:20px;">
              <el-button type="primary" @click="submitDepFun()">保存</el-button>
              <el-button @click="closeDepForm()">取消</el-button>
          </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "DeviceManage",
  data() {
    var NAME = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入部门名称"));
      } else {
        callback();
      }
    };
    return {
      userList:[],
      blist:[],
      props: {
          label: 'label',
          children: 'children',
          isLeaf: 'isLeaf'
      },
      filterText:"",

      DepModalType:"",
      DepModalTitle:"",
      DepParent:"",
      DepModal:false,
      DepInfo:{
        SNOWID:"",
        PARENTORGANIZATIONGUID:"",
        NAME:"",
        ISENABLE:"",
        BZ:"",
        MANAGERID:"",
        MANAGERNAME:"",
      },
      DepRules: {
        NAME: [{ validator: NAME, trigger: "blur" }],
      },
      DepEditItem:null,
      canDo:true,
      auditText:"",


      funTreeProps:{
        children: 'CHILD',
        label: 'MENUNAME'
      },
      nowSetFunOrgId:"",
      nowSetFunId:"",
      expandItems:[],
      checkPages:[],
      checkFuns:[],
      FunModal:false,
      FunModalTitle:"",
      funList:[],
    };
  },
  mounted() {
    this.loadItem=null
    this.getList()
  },
  methods: {
    getUserList(id){
        var md5_data =this.comjs.requestDataEncrypt({ORGANIZATIONID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({ORGANIZATIONID:id})).toUpperCase())
        this.$http.post("UserManage/GetUserListWithoutPage",md5_data)
        .then((response) => {
          if(response.data.SUCCESS){   
            var l=response.data.DATA
            var nl=[]
            for(var i=0;i<l.length;i++){
              var d=l[i]
              d.NAME= d.TRUENAME+"("+d.LOGINNAME+")"
              nl.push(d)
            }
            this.userList=nl
          }
        })
    },
    changeUser(item){
      //console.log(item)
      if(item==""){
          this.DepInfo.MANAGERNAME="暂无"
      }else{
          var l=this.userList
          for(var i=0;i<l.length;i++){
            if(l[i].SNOWID == item){
              this.DepInfo.MANAGERNAME = l[i].TRUENAME
            }
          }
      }
    },
    filterNode(value, data) {
        if (!value) return true;
        return data.name.indexOf(value) !== -1;
    },
    getList(){
      var md5_data =this.comjs.requestDataEncrypt({}, this.$store.state.token,this.$md5(this.comjs.sortAsc({})).toUpperCase())
        this.$http.post("OrgManage/GetOrgList",md5_data)
        .then((response) => {
          if(response.data.SUCCESS){   
            var l=response.data.DATA
            var nl=[]
            for(var i=0;i<l.length;i++){
              var d=l[i]
              d.children=[]
              d.isLeaf=true
              nl.push({
                id:d.SNOWID,
                label:d.NAME,
                children:[],
                isLeaf:true,
              })
            }
            this.blist=nl
          }
        })

    },
    sendQuery(){
      var val=this.filterText
      this.$refs.tree.filter(val)
    },
    closeDepForm(){
      this.DepModal=false
    },
    submitDepForm(){
      if (this.canDo) {
          this.$refs.DepForm.validate((valid) => {
          if (valid) {
              //this.DepInfo.ISENABLE = parseInt(this.DepInfo.ISENABLE)
              if(this.DepModalType=="1"){
                this.appendDep()
              }else if(this.DepModalType=="2"){
                this.editDep()  
              }
          } else {
            return false;
          }
        });
      }
    },
    appendDep(){
      this.canDo=false
      this.showLoading("请稍后")
      var md5_data =this.comjs.requestDataEncrypt(this.DepInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.DepInfo)).toUpperCase())
      this.$http.post("OrgManage/CreateNewOrg", md5_data)
        .then((response) => {
          //console.log(response)
          this.hideLoading()
          this.canDo=true
          this.DepModal=false
          if(response.data.SUCCESS){   
            var p=null
            if(this.DepParent!="" && this.DepParent!= null){
                p=this.DepParent
            }
            var data={
                label: this.DepInfo.NAME,
                id:response.data.DATA,
                children:[],
                leaf: true,
            }
            this.$refs.tree.append(data,this.DepParent) 
            this.$message.success("添加成功")
          }else{
            this.errorRequest(response.data.DATA,response.data.MSG,true)
          }
        })
        .catch((error) => {
          this.$message.error("请求出错")
          this.hideLoading()
          this.canDo=true
      });
    },
    editDep(){
      this.canDo=false
      this.showLoading("请稍后")
      var md5_data =this.comjs.requestDataEncrypt(this.DepInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.DepInfo)).toUpperCase())
      this.$http.post("OrgManage/EditOrg", md5_data)
        .then((response) => {
          //console.log(response)
          this.hideLoading()
          this.canDo=true
          this.DepModal=false
          if(response.data.SUCCESS){   
            this.$set(this.DepEditItem,'label',this.DepInfo.NAME)
            // this.$set(this.DepEditItem,'isenable',this.DepInfo.ISENABLE.toString())
            // this.$set(this.DepEditItem,'bz',this.DepInfo.BZ)
            // this.$set(this.DepEditItem,'parent',this.DepInfo.PARENTORGANIZATIONGUID)
            // this.$set(this.DepEditItem,'mname',this.DepInfo.MANAGERNAME)
            // this.$set(this.DepEditItem,'mid',this.DepInfo.MANAGERID)
            this.$message.success("编辑成功")
          }else{
            this.errorRequest(response.data.DATA,response.data.MSG,true)
          }
        })
        .catch((error) => {
          this.$message.error("请求出错")
          this.hideLoading()
          this.canDo=true
      });
    },
    addDep(){
      this.DepInfo={
        //SNOWID:null,
        PARENTORGANIZATIONGUID:null,
        NAME:"",
        ISENABLE:"1",
        BZ:"",
        MANAGERID:"",
        MANAGERNAME:"暂无",
      },
      this.DepModalType="1"
      this.DepParent=""
      this.DepModalTitle="添加部门"
      this.DepModal=true
    },
    append(node, data){
      //console.log(data)
      this.DepInfo={
        //SNOWID:null,
        NAME:"",
        ISENABLE:"1",
        PARENTORGANIZATIONGUID:data.guid,
        BZ:"",
        MANAGERID:"",
        MANAGERNAME:"暂无",
      },
     // console.log(this.DepInfo)
      this.DepModalType="1"
      this.DepParent=data.guid
      this.DepModalTitle="添加部门"
      this.DepModal=true
    },
    edit(node, data){
       //this.getUserList(data.guid)
       this.DepInfo={
        SNOWID:data.id,
        NAME:data.label,
        PARENTORGANIZATIONGUID:null,
        ISENABLE:"1",
        BZ:"",
        MANAGERID:"",
        MANAGERNAME:"暂无",
      },
      this.DepEditItem=data
      this.DepModalType="2"
      this.DepParent=data.parent
      this.DepModalTitle="编辑部门"
      this.DepModal=true

    },
    remove(node, data){
      if(this.canDo){
        this.$confirm('确定删除'+data.label+'？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.delDep(data)
          }).catch(() => {
            this.$message.info("已取消")    
          });
      }
    },
    delDep(data){
      this.canDo=false
      this.showLoading("请稍后")
      var md5_data =this.comjs.requestDataEncrypt({SNOWID:data.id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:data.id})).toUpperCase())
      this.$http.post("OrgManage/DeleteOrg", md5_data)
        .then((response) => {
          //console.log(response)
          this.hideLoading()
          this.canDo=true
          if(response.data.SUCCESS){   
              this.$message.success("删除成功")
              this.getList()
          }else{
            this.errorRequest(response.data.DATA,response.data.MSG,true)
          }
        })
        .catch((error) => {
          this.$message.error("请求出错")
          this.hideLoading()
          this.canDo=true
      });
    },
    handleDrop(draggingNode, dropNode, dropType, ev) {
        if(dropType!="inner"){
          if(this.canDo){
            //console.log(this.blist)
            var ids=[]
            for(var i=0;i<this.blist.length;i++){
              ids.push({SnowID:this.blist[i].id,IndexX:i})
            }
            this.setIndexx(ids)
            //this.editDep()
          }
        }else{
           return false
        }
    },
    allowDrop(draggingNode,dropNode,type) {
			if(type == 'inner' && dropNode.data.nodeType != '1') {
				return false;
			}else {
				return true;
			}
		},
    setIndexx(ids){
      this.canDo=false
      this.showLoading("请稍后")
      // var params={
      //   Data:ids
      // }
      var md5_data =this.comjs.requestDataEncrypt(ids, this.$store.state.token,this.$md5(this.comjs.sortAsc(ids)).toUpperCase())
      this.$http.post("OrgManage/UpdateDateIndexX", md5_data)
        .then((response) => {
          //console.log(response)
          this.hideLoading()
          this.canDo=true
          if(response.data.SUCCESS){   
              this.$message.success("设置成功")
              this.getList()
          }else{
            this.errorRequest(response.data.DATA,response.data.MSG,true)
          }
        })
        .catch((error) => {
          this.$message.error("请求出错")
          this.hideLoading()
          this.canDo=true
      });
    },



    fun(node, data){
        this.FunModalTitle=data.name+"-权限设置",
        this.expandItems=[]
        this.checkItems=[]
        this.checkFuns=[]
        this.funList=[]
        this.nowSetFunOrgId=data.guid

        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({SNOWID:data.guid}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:data.guid})).toUpperCase())
        this.$http.post("OrgManage/GetOrgFunctionList", md5_data)
          .then((response) => {
            //console.log(response)
            this.hideLoading()
             this.canDo=true
            if(response.data.SUCCESS){   
               var d=response.data.DATA
               this.funList=d
               this.setFunTreeDefaultCheck(d)
               this.FunModal=true
            }else{
              this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
          })
          .catch((error) => {
            this.$message.error("请求出错")
            this.hideLoading()
            this.canDo=true
        });
    },
    setFunTreeDefaultCheck(list){
        for(var i=0;i<list.length;i++){
          if(list[i].ISCHECK){
            this.checkPages.push(list[i].SNOWID)
            this.expandItems.push(list[i].SNOWID)
          }
          for(var j=0;j< list[i].FUNCLIST.length;j++){
              if(list[i].FUNCLIST[j].ISCHECK){
                this.checkFuns.push(list[i].FUNCLIST[j].SNOWID)
              }
          }
          if(list[i].CHILD.length>0){
            this.setFunTreeDefaultCheck(list[i].CHILD)
          }
        }
    },
    funTreeCheckChange(data, checked, indeterminate){
      var nl=[]
      for(var i=0;i<this.checkPages.length;i++){
          if(this.checkPages[i] != data.SNOWID){
              nl.push(this.checkPages[i])
          }
      }
      if(checked){
        nl.push(data.SNOWID)
      }
      this.checkPages = nl
    },
    funChechBoxChange(checked){
      var nl=[]
      for(var i=0;i<this.checkFuns.length;i++){
          if(this.checkFuns[i] != this.nowSetFunId){
              nl.push(this.checkFuns[i])
          }
      }
      if(checked){
        nl.push(this.nowSetFunId)
      }
      this.checkFuns = nl
    },
    funChechBoxClick(id){
      this.nowSetFunId=id
    },
    submitDepFun(){
        // console.log(this.nowSetFunOrgId)
        // console.log(this.checkPages)
        // console.log(this.checkFuns)
        if(this.canDo){
          this.canDo=false
          this.showLoading("请稍后")
          var params={
              ORGSNOWID:this.nowSetFunOrgId,
              MENUSNOWIDS:this.checkPages,
              FUNCTIONSNOWIDS:this.checkFuns,
          }
          var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
          this.$http.post("OrgManage/OrgSetFunction", md5_data)
            .then((response) => {
              //console.log(response)
              this.hideLoading()
              this.canDo=true
              if(response.data.SUCCESS){   
                  this.$message.success("已保存！")
                  this.FunModal=false
              }else{
                this.errorRequest(response.data.DATA,response.data.MSG,true)
              }
            })
            .catch((error) => {
              this.$message.error("请求出错")
              this.hideLoading()
              this.canDo=true
          });
        }
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style >
  .el-tree-node{margin-top: 20px;}
  .treeName{font-size: 16px;margin-right: 5px;}

  .funTreeRow{width: 500px;}
  .funtreeName{font-size: 15px;margin-right: 30px;}
  .funItemList{float:right;}
  .managerName{font-size: 12px;margin-right: 20px;color: #409EFF;}
  .custom-tree-node{display: block;}
  .el-checkbox{margin-right: 15px!important;}
  .el-checkbox__label{padding-left: 5px!important;}
</style>
