<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">文号管理</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                
                <el-input v-model="name" placeholder="请输入名称" class="iw" ></el-input>
                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-button type="success" @click="addPost" class="fr">添加</el-button>
                
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column prop="MODELALIAS" label="流程别名"></el-table-column>
                    <el-table-column prop="KEY" label="排号级别"></el-table-column>
                    <el-table-column prop="VECTOR" label="自增号"></el-table-column>
                    <el-table-column prop="STRINGFORMAT" label="格式串">
                        <template slot-scope="scope">
                            <span>{{scope.row.STRINGFORMAT}}第{{scope.row.VECTOR}}号</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                        <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button>
                        <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="800px">
            <el-form :model="postInfo" status-icon :rules="postrules"  ref="uForm" label-width="100px" class="demo-uForm">
                <el-form-item label="流程别名" prop="MODELALIAS">
                    <el-select v-model="postInfo.MODELALIAS" placeholder="请选择流程别名"  filterable  style="width:100%;">
                            <el-option v-for="item in modelList" :key="item.ALIAS" :label="item.ALIAS" :value="item.ALIAS" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="排号级别" prop="KEY">
                    <el-input v-model="postInfo.KEY" autocomplete="off" placeholder="公司级或者部门级名称"></el-input>
                </el-form-item>
                <el-form-item label="自增号" prop="VECTOR">
                    <el-input v-model="postInfo.VECTOR" autocomplete="off" ></el-input>
                </el-form-item>
                <el-form-item label="格式串" prop="STRINGFORMAT">
                    <el-input v-model="postInfo.STRINGFORMAT" autocomplete="off" style="width:85%;" placeholder="文号名+公司(部门)名+年份，如：辽新事业部党沈阳分公司2022年"></el-input>
                    <span style="margin-left:10px;">第 {{postInfo.VECTOR}} 号</span>
                </el-form-item>
                <el-form-item>
                <el-button type="primary" @click="submitPostForm()">提交</el-button>
                <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
export default {
  name: "WFSerialNumber",
  data() {
    var MODELALIAS= (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择流程别名"));
      } else {
        callback();
      }
    };
    var KEY = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入排号级别"));
      } else {
        callback();
      }
    };
    var VECTOR=(rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入自增号'));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('自增号只能是数字'));
        }else if(!/^\+?(0|[1-9][0-9]*)$/.test(value)){
            callback(new Error('只能输入大于等于0的整数'));
        }else {
          callback();
        }
    };
    var STRINGFORMAT=(rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入格式串"));
      } else {
        callback();
      }
    };
    
    return {
        canDo:true,
        
        name:"",
       
        modelList:[],

        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,


        doTypeStr:"",
        doType:"",
        postModal:false,
        postInfo:{},
        postrules: {
            MODELALIAS: [{ validator: MODELALIAS, trigger: "blur" }],
            KEY: [{ validator: KEY, trigger: "blur" }],
            VECTOR: [{ validator: VECTOR, trigger: "blur" }],
            STRINGFORMAT: [{ validator: STRINGFORMAT, trigger: "blur" }],
        },
    };
  },
  mounted() {
    this.loadItem=null
    this.getPostList()
    this.getModelList()
  },
  methods: {
   getModelList(){
        var params={
            PAGEINDEX:1,
            PAGESIZE:500,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Model/GetModelList", md5_data)
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.modelList=response.data.DATA  
                } 
        })
    },
    getPostList(){
        this.showLoading("请稍后")
        var params={
            PAGEINDEX:this.PageIndex,
            PAGESIZE:this.PageSize,
            NAME:this.name,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("SerialNumber/GetSerialNumberList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
        });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    addPost(){
        this.postInfo={
            MODELALIAS:"",
            KEY:"",
            VECTOR:1,
            STRINGFORMAT:"",
        }
        this.doTypeStr="添加文号"
        this.doType="1"
        this.postModal=true
    },
    sendAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("SerialNumber/CreateNewSerialNumber", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    editPost(item){
        this.postInfo={
            SNOWID:item.SNOWID,
            MODELALIAS:item.MODELALIAS,
            KEY:item.KEY,
            VECTOR:item.VECTOR,
            STRINGFORMAT:item.STRINGFORMAT,
        }
        this.doTypeStr="修改文号"
        this.doType="2"
        this.postModal=true
    },
    sendEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("SerialNumber/EditSerialNumber", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    delPost(item){
        if(this.canDo){
            this.$confirm('确定删除文号：'+item.STRINGFORMAT+'？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
            this.sendDel(item.SNOWID)
            }).catch(() => {
            this.$message.info("已取消")    
          });
        }

    },
    sendDel(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({SNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:id})).toUpperCase())
        this.$http.post("SerialNumber/DeleteSerialNumber", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    submitPostForm() {
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    if(this.doType=="1"){
                        this.sendAdd()
                    }else if(this.doType=="2"){
                        this.sendEdit()
                    }
                    //console.log(this.postInfo)
                } else {
                    return false;
                }
            });
        }
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style >
</style>