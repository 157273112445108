<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">年度费用计划</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                <el-select v-model="fc" placeholder="选择风场"  filterable  clearable class="iww">
                        <el-option v-for="item in fcList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                </el-select>
                <el-date-picker v-model="year" type="year" placeholder="选择年份" class="iww" clearable></el-date-picker>
                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-button type="success" @click="addPost" class="fr">添加</el-button>
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column prop="FACTORYINFO.NAME" label="风场"></el-table-column>
                    <el-table-column prop="YEAR" label="年份"></el-table-column>
                    <el-table-column prop="REPAIRCOSTS" label="修理费用"></el-table-column>
                    <el-table-column prop="MATERIALCOSTS" label="材料费用"></el-table-column>
                    <el-table-column prop="TECHNICALCOSTS" label="技改费用"></el-table-column>
<!--                    <el-table-column prop="LABORCOSTS" label="人工成本"></el-table-column>-->
                    <el-table-column prop="INFORMATIZATIONCOSTS" label="信息化费用"></el-table-column>
<!--                    <el-table-column prop="OTHEREXPENSES" label="其他费用"></el-table-column>-->
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                        <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button>
                        <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="800px">
            <el-form :model="postInfo" status-icon :rules="postrules"  ref="uForm" label-width="150px" class="demo-uForm">
                <el-form-item label="风场"  prop="FACTORYSNOWID">
                    <el-select v-model="postInfo.FACTORYSNOWID" placeholder="请选择风场"  filterable  style="width:100%;">
                            <el-option v-for="item in fcList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="年份" prop="YEARS">
                    <el-date-picker v-model="postInfo.YEARS" type="year" placeholder="选择年" style="width:100%;"></el-date-picker>
                </el-form-item>
                <el-form-item label="修理费用" prop="REPAIRCOSTS">
                    <el-input v-model="postInfo.REPAIRCOSTS" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="材料费用" prop="MATERIALCOSTS">
                    <el-input v-model="postInfo.MATERIALCOSTS" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="技改费用" prop="TECHNICALCOSTS">
                    <el-input v-model="postInfo.TECHNICALCOSTS" autocomplete="off"></el-input>
                </el-form-item>
<!--                <el-form-item label="人工成本" prop="LABORCOSTS">-->
<!--                    <el-input v-model="postInfo.LABORCOSTS" autocomplete="off"></el-input>-->
<!--                </el-form-item>-->
                <el-form-item label="信息化费用" prop="INFORMATIZATIONCOSTS">
                    <el-input v-model="postInfo.INFORMATIZATIONCOSTS" autocomplete="off"></el-input>
                </el-form-item>
<!--                <el-form-item label="其他费用" prop="OTHEREXPENSES">-->
<!--                    <el-input v-model="postInfo.OTHEREXPENSES" autocomplete="off"></el-input>-->
<!--                </el-form-item>-->
                <el-form-item>
                <el-button type="primary" @click="submitPostForm()">提交</el-button>
                <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
export default {
  name: "YearCostPlan",
  data() {
    var YEARS = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择年份"));
      } else {
        callback();
      }
    };
    var FACTORYSNOWID = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择风场"));
      } else {
        callback();
      }
    };
    var COST= (rule, value, callback) => {
        if (value === "") {
             callback(new Error("请输入费用"));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('费用只能是数字'));
        }else if(value<0){
            callback(new Error('费用不能小于零'));
        }else {
            callback();
        }
    };
    return {
        canDo:true,
        
        year:null,

        fcList:[],
        fc:"",

        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,


        doTypeStr:"",
        doType:"",
        postModal:false,
        postInfo:{},
        postrules: {
            YEARS: [{ validator: YEARS, trigger: "blur" }],
            REPAIRCOSTS: [{ validator: COST, trigger: "blur" }],
            MATERIALCOSTS: [{ validator: COST, trigger: "blur" }],
            TECHNICALCOSTS: [{ validator: COST, trigger: "blur" }],
            LABORCOSTS: [{ validator: COST, trigger: "blur" }],
            INFORMATIZATIONCOSTS: [{ validator: COST, trigger: "blur" }],
            OTHEREXPENSES: [{ validator: COST, trigger: "blur" }],
            FACTORYSNOWID: [{ validator: FACTORYSNOWID, trigger: "blur" }],
        },
    };
  },
  mounted() {
    this.loadItem=null
    this.getFCList()
    this.getPostList()
  },
  methods: {
    getFCList(){
        var params={}
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Factory/GetFactoryWithoutPage", md5_data)
            .then((response) => {
                this.canDo=true
                if (response.data.SUCCESS) {
                    this.fcList=response.data.DATA
                }
            })
    },
    getPostList(){
        this.showLoading("请稍后")
        var year=""
        if(this.year && this.year!=""){
            year=this.year.getFullYear()
        }
        var params={
            PAGEINDEX:this.PageIndex,
            PAGESIZE:this.PageSize,
            YEAR:year.toString(),
            FACTORYSNOWID:this.fc
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("YearCost/GetYearCostPlanList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
        });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    addPost(){
        this.postInfo={
            YEARS:"",
            REPAIRCOSTS:"",
            MATERIALCOSTS:"",
            TECHNICALCOSTS:"",
            LABORCOSTS:"",
            INFORMATIZATIONCOSTS:"",
            OTHEREXPENSES:"",
            FACTORYSNOWID:"",
        }
        this.doTypeStr="添加年度费用计划"
        this.doType="1"
        this.postModal=true
    },
    sendAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        var d={
            YEAR:this.postInfo.YEARS.getFullYear().toString(),
            REPAIRCOSTS:this.postInfo.REPAIRCOSTS,
            MATERIALCOSTS:this.postInfo.MATERIALCOSTS,
            TECHNICALCOSTS:this.postInfo.TECHNICALCOSTS,
            LABORCOSTS:this.postInfo.LABORCOSTS,
            INFORMATIZATIONCOSTS:this.postInfo.INFORMATIZATIONCOSTS,
            OTHEREXPENSES:this.postInfo.OTHEREXPENSES,
            FACTORYSNOWID:this.postInfo.FACTORYSNOWID,
        }
        var md5_data =this.comjs.requestDataEncrypt(d, this.$store.state.token,this.$md5(this.comjs.sortAsc(d)).toUpperCase())
        this.$http.post("YearCost/CreateYearCostPlan", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    editPost(item){
        var year=item.YEAR+"-01-01"
        this.postInfo={
            SNOWID:item.SNOWID,
            YEARS:new Date(year),
            REPAIRCOSTS:item.REPAIRCOSTS,
            MATERIALCOSTS:item.MATERIALCOSTS,
            TECHNICALCOSTS:item.TECHNICALCOSTS,
            LABORCOSTS:item.LABORCOSTS,
            INFORMATIZATIONCOSTS:item.INFORMATIZATIONCOSTS,
            OTHEREXPENSES:item.OTHEREXPENSES,
            FACTORYSNOWID:item.FACTORYSNOWID,
        }
        this.doTypeStr="修改年度费用计划"
        this.doType="2"
        this.postModal=true
    },
    sendEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        var d={
            SNOWID:this.postInfo.SNOWID,
            YEAR:this.postInfo.YEARS.getFullYear().toString(),
            REPAIRCOSTS:this.postInfo.REPAIRCOSTS,
            MATERIALCOSTS:this.postInfo.MATERIALCOSTS,
            TECHNICALCOSTS:this.postInfo.TECHNICALCOSTS,
            LABORCOSTS:this.postInfo.LABORCOSTS,
            INFORMATIZATIONCOSTS:this.postInfo.INFORMATIZATIONCOSTS,
            OTHEREXPENSES:this.postInfo.OTHEREXPENSES,
            FACTORYSNOWID:this.postInfo.FACTORYSNOWID,
        }
        var md5_data =this.comjs.requestDataEncrypt(d, this.$store.state.token,this.$md5(this.comjs.sortAsc(d)).toUpperCase())
        this.$http.post("YearCost/EditYearCostPlan", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.postModal=false
                    this.getPostList();
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    delPost(item){
        if(this.canDo){
            this.$confirm('确定删除年度费用计划？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
            this.sendDel(item.SNOWID)
            }).catch(() => {
            this.$message.info("已取消")    
          });
        }

    },
    sendDel(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({SNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:id})).toUpperCase())
        this.$http.post("YearCost/DeleteYearCostPlan", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    submitPostForm() {
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    if(this.doType=="1"){
                        this.sendAdd()
                    }else if(this.doType=="2"){
                        this.sendEdit()
                    }
                } else {
                    return false;
                }
            });
        }
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style >
</style>