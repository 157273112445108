<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">巡检管理</p>
        </div>
        <div class="tableBox" style="top:50px;">
                <el-collapse v-model="activeNames" :accordion="acc">
                    <el-collapse-item title="大部件齿轮箱偏航油、液压油及主轴、桨叶、塔筒管理" name="1">
                            <el-button type="info" @click="toA('1')">检查模板项</el-button>
                            <el-button type="info" @click="toB('1')">检查模板</el-button>
                            <el-button type="info" @click="toC('1')">检查任务</el-button>
                            <el-button type="info" @click="toD('1')">任务分配</el-button>
                    </el-collapse-item>
                    <el-collapse-item title="技术监督管理" name="2">
                            <el-button type="success" @click="toA('2')">检查模板项</el-button>
                            <el-button type="success" @click="toB('2')">检查模板</el-button>
                            <el-button type="success" @click="toC('2')">检查任务</el-button>
                            <el-button type="success" @click="toD('2')">任务分配</el-button>  
                    </el-collapse-item>
                    <el-collapse-item title="变压器、继保室、35kV配电室、66kV室外设备、无功补偿、集电线路、环网柜检查管理。" name="3">
                            <el-button type="warning" @click="toA('3')">检查模板项</el-button>
                            <el-button type="warning" @click="toB('3')">检查模板</el-button>
                            <el-button type="warning" @click="toC('3')">检查任务</el-button>
                            <el-button type="warning" @click="toD('3')">任务分配</el-button>  
                    </el-collapse-item>
                    <el-collapse-item title="定检" name="4">
                            <el-button type="danger" @click="toA('4')">检查模板项</el-button>
                            <el-button type="danger" @click="toB('4')">检查模板</el-button>
                            <el-button type="danger" @click="toC('4')">检查任务</el-button>
                            <el-button type="danger" @click="toD('4')">任务分配</el-button>  
                    </el-collapse-item>
                </el-collapse>
        </div>
    </div>
</template>
<script>
export default {
  name: "XJGL",
  data() {

    return {
        acc:false,
        canDo:true,
        activeNames: ['1','2','3','4']

       

        
    };
  },
  mounted() {
    this.loadItem=null
  },
  methods: {
    toA(type){
        localStorage.setItem("fd_xjlx", type);
        this.$store.commit('setCheckType', type);
        this.$router.replace('/CheckTempletItem')
    },
    toB(type){
        localStorage.setItem("fd_xjlx", type);
        this.$store.commit('setCheckType', type);
        this.$router.replace('/CheckTemplet')
    },
    toC(type){
        localStorage.setItem("fd_xjlx", type);
        this.$store.commit('setCheckType', type);
        this.$router.replace('/CheckTaskManage')
    },
    toD(type){
        localStorage.setItem("fd_xjlx", type);
        this.$store.commit('setCheckType', type);
        this.$router.replace('/TaskAllot')
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style>
    .el-collapse-item__header{padding: 0 20px;font-size: 16px;}
    .el-collapse .is-active{color: #409EFF;}
    .el-collapse-item__content{padding-left:20px;}
</style>