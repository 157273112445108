<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">技术监督会议</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                
                <el-select v-model="type" placeholder="会议类型"  filterable  clearable  class="iww">
                        <el-option label="年中会议" value="1" ></el-option>
                        <el-option label="年终会议" value="2" ></el-option>
                </el-select>

                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-button type="success" @click="addPost" class="fr">添加</el-button>
                
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                     <el-table-column prop="MEETINGTYPE" label="会议类型">
                        <template slot-scope="scope">
                            <span v-if="scope.row.MEETINGTYPE=='1'">年中会议</span>
                            <span v-else-if="scope.row.MEETINGTYPE=='2'">年终会议</span>
                            <span v-else>未知</span>
                        </template>
                     </el-table-column>
                    <el-table-column prop="TITLE" label="标题"></el-table-column>
                    <el-table-column prop="REMARK" label="内容"></el-table-column>
                    <el-table-column prop="YEAR" label="年份"></el-table-column>
                    <el-table-column prop="MEETINGTIME" label="开始时间"></el-table-column>
                    <el-table-column label="操作" width="160">
                        <template slot-scope="scope">
                            <el-button @click="hyFile(scope.row)"  type="text" size="small" style="color:#409EFF">附件</el-button>
                            <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button>
                            <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
                            <el-button @click="showPost(scope.row)"  type="text" size="small" style="color:#409EFF">问题</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="500px">
            <el-form :model="postInfo" status-icon :rules="postrules"  ref="uForm" label-width="100px" class="demo-uForm">
                <el-form-item label="类型">
                    <el-radio v-model="postInfo.MEETINGTYPE" label="1">年中会议</el-radio>
                    <el-radio v-model="postInfo.MEETINGTYPE" label="2">年终会议</el-radio>
                </el-form-item>
                <el-form-item label="标题" prop="TITLE">
                    <el-input v-model="postInfo.TITLE" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="内容"  prop="REMARK">
                    <el-input  v-model="postInfo.REMARK" autocomplete="off" type="textarea" class="gwzz"></el-input>
                </el-form-item>
                <el-form-item label="年份" prop="YEAR" v-if="doType=='1'">
                    <el-date-picker v-model="postInfo.YEAR"  type="year" placeholder="选择年" style="width:100%"></el-date-picker>
                </el-form-item>


                <el-form-item>
                    <el-button type="primary" @click="submitPostForm()">提交</el-button>
                    <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog title="会议整改问题" :visible.sync="showModal" width="1200px" top="50px" >
                <el-table :data="postListA" stripe style="width: 120%" :height="dialogMaxHeigh">
                    <el-table-column prop="CREATEUSERNAME" label="提交人"></el-table-column>
                    <el-table-column prop="REMARK" label="整改进展说明"></el-table-column>
                    <el-table-column prop="STATUS" label="状态">
                        <template slot-scope="scope">
                            <span v-if="scope.row.STATUS=='0'">年中会议</span>
                            <span v-else-if="scope.row.STATUS=='1'">年终会议</span>
                            <span v-else-if="scope.row.STATUS=='2'">年终会议</span>
                            <span v-else>未知</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="MACHINESTR" label="相关设备"></el-table-column>
                    <el-table-column label="操作" width="120">
                        <template slot-scope="scope">
                            <el-button @click="fileStatusItem(scope.row)"  type="text" size="small" style="color:#409EFF">附件</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndexA" :page-size="PageSizeA" :total="TotalA" class="pg pgItem" @current-change="PGChangeA"></el-pagination>
        </el-dialog>
        <el-dialog title="附件查看" :visible.sync="chooseFileModal" width="800px"  :append-to-body="true">
            <div class="fileBox">
                <div class="fileItem" v-for="item in fl" :key="item.SNOIWD" :title="item.FILENAME">
                    <div class="fileOut">
                        <el-image style="width: 100%;" :src="item.PATH"  :preview-src-list="fileImgList" v-if="item.TYPE=='1'"></el-image>
                        <i class="el-icon-document" v-else></i>
                    </div>
                    <p class="name" @click="downFile(item.PATH)">{{item.FILENAME}}</p>
                    <div class="fileBtn">
                        <span @click="downFile(item.PATH)" v-if="item.TYPE=='2'" style="color:#409EFF">下载</span>
                    </div>
                </div>
                <div v-if="fl.length==0" class="noFile">该问题还未上传附件</div>
            </div>
        </el-dialog>
        <el-dialog title="会议附件管理" :visible.sync="chooseFileModalHY" width="800px"  :append-to-body="true">
            <p class="fileTitle">现有附件：</p>
            <div class="fileBox">
                <div class="fileItem" v-for="item in flHY" :key="item.SNOIWD" :title="item.FILENAME">
                    <div class="fileOut">
                        <el-image style="width: 100%;" :src="item.PATH"  :preview-src-list="fileImgList" v-if="item.TYPE=='1'"></el-image>
                        <i class="el-icon-document" v-else></i>
                    </div>
                    <p class="name" @click="downFile(item.PATH)">{{item.FILENAME}}</p>
                    <div class="fileBtn">
                        <span @click="downFile(item.PATH)" v-if="item.TYPE=='2'" style="color:#409EFF">下载</span>
                        <span @click="delFile(item.SNOWID)" style="color:#F56C6C">删除</span>
                    </div>
                </div>
                <div v-if="flHY.length==0" class="noFile">该会议还未上传附件</div>
            </div>
            <p class="fileTitle" style="margin-bottom:20px;">上传附件：</p>
            <div style="overflow:hidden;">
                <el-upload ref="upload"  class=""  :multiple="true" :limit="5" action="" :http-request="uploadFun">
                    <el-button size="small" type="primary">点击上传</el-button>
                </el-upload>
            </div>
        </el-dialog>
    </div>
</template>
<script>
var h=document.body.clientHeight
export default {
  name: "HY",
  data() {
    var TITLE = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入标题"));
      } else {
        callback();
      }
    };
    var REMARK = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入内容"));
      } else {
        callback();
      }
    };
    var YEAR = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择年份"));
      } else {
        callback();
      }
    };
    return {
        canDo:true,
        
        type:"",


        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,


        doTypeStr:"",
        doType:"",
        postModal:false,
        postInfo:{},
        postrules: {
            TITLE: [{ validator: TITLE, trigger: "blur" }],
            REMARK: [{ validator: REMARK, trigger: "blur" }],
            YEAR: [{ validator: YEAR, trigger: "blur" }],
        },

        dialogMaxHeigh:0,
        postListA:[],
        PageIndexA:1,
        PageSizeA:10,
        TotalA:0,
        showModal:false,

        fileList:[],
        fileImgList:[],
        chooseFileModal:false,
        fl:[],

        checkHYID:"",
        fileListHY:[],
        fileImgListHY:[],
        chooseFileModalHY:false,
        flHY:[],
    };
  },
  mounted() {
    this.dialogMaxHeigh = h -300
    this.loadItem=null
    this.getPostList()
  },
  methods: {
    getPostList(){
        this.showLoading("请稍后")
        var params={
            PAGEINDEX:this.PageIndex,
            PAGESIZE:this.PageSize,
            MEETINGTYPE:this.type,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Defect/GetMeetingList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
        });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    addPost(){
        this.postInfo={
            TITLE:"",
            REMARK:"",
            YEAR:"",
            MEETINGTYPE:"1",
        }
        this.doTypeStr="添加会议记录"
        this.doType="1"
        this.postModal=true
    },
    sendAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("Defect/CreateMeeting", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    editPost(item){
        this.postInfo={
            MEETINGSNOWID:item.SNOWID,
            TITLE:item.TITLE,
            REMARK:item.REMARK,
            MEETINGTYPE:item.MEETINGTYPE,
        }
        this.doTypeStr="修改会议记录"
        this.doType="2"
        this.postModal=true
    },
    sendEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("Defect/EditMeeting", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.postModal=false
                    this.getPostList();
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    delPost(item){
        if(this.canDo){
            this.$confirm('确定删除会议记录？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
            this.sendDel(item.SNOWID)
            }).catch(() => {
            this.$message.info("已取消")    
          });
        }

    },
    sendDel(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({MEETINGSNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({MEETINGSNOWID:id})).toUpperCase())
        this.$http.post("Defect/DeleteMeeting", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    submitPostForm() {
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    if(this.doType=="1"){
                        this.postInfo.YEAR = this.postInfo.YEAR.getFullYear().toString()
                        this.sendAdd()
                    }else if(this.doType=="2"){
                        this.sendEdit()
                    }
                } else {
                    return false;
                }
            });
        }
    },
    showPost(item){
        this.checkId=item.SNOWID
        this.showPostModal()
        this.showModal=true
    },
    showPostModal(){
        this.showLoading("请稍后")
        var params={
            PAGEINDEX:this.PageIndexA,
            PAGESIZE:this.PageSizeA,
            MEETINGSNOWID:this.checkId,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Defect/GetMeetingDefectList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                var list=response.data.DATA
                var nl=[]
                for(var i=0;i<list.length;i++){
                    var d=list[i]
                    d.MACHINESTR="/"
                    if(d.MACHINELIST.length>0){
                        for(var j=0;j<d.MACHINELIST.length;j++){
                            if(j==0){
                                d.MACHINESTR = d.MACHINELIST[j].NAME
                            }else{
                                d.MACHINESTR = d.MACHINESTR+"、"+d.MACHINELIST[j].NAME
                            }
                        }
                    }
                    nl.push(d)
                }
                
                this.postListA=nl
                this.TotalA=response.data.TOTALCOUNT
            } else {
                this.postListA=[]
                this.TotalA=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postListA=[]
                this.TotalA=0
        });
    },
    PGChangeA(e){
        this.PageIndexA=e
        this.showPostModal()
    },
    fileStatusItem(item){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({RECORDSNOWID:item.SNOWID}, this.$store.state.token,this.$md5(this.comjs.sortAsc({RECORDSNOWID:item.SNOWID})).toUpperCase())
        this.$http.post("Defect/GetFileList", md5_data)
            .then((response) => {
                 this.canDo=true   
                this.hideLoading()
                this.fl=response.data.DATA
                this.fileImgList=[]
                for(var i=0;i<this.fl.length;i++){
                    if(this.fl[i].TYPE=="1"){
                        this.fileImgList.push(this.fl[i].PATH)
                    }
                }
               this.chooseFileModal=true
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
                this.fileImgList=[]
                this.fl =[]
                this.chooseFileModal=true    
             });
    },


    //会议附件
    hyFile(item){
        this.checkHYID=item.SNOWID
        this.canDo=false
        this.getHYFiles()
        this.chooseFileModalHY=true    
    },
    getHYFiles(){
        this.flHY =[]
        this.fileImgList=[]
        this.showLoading("请稍后")
        var  params={
            SnowID: "",
            // PageIndex:1,
            // PageSize:100,
            MeetingSnowID:this.checkHYID,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Defect/GetMeetinFileList", md5_data)
            .then((response) => {
                 this.canDo=true   
                this.hideLoading()
                this.flHY=response.data.DATA
                for(var i=0;i<this.flHY.length;i++){
                    if(this.flHY[i].TYPE=="1"){
                        this.fileImgList.push(this.flHY[i].PATH)
                    }
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    delFile(e){
        //console.log(this.canDo)
        if(this.canDo){
            this.$confirm('确定删除？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
                this.sendDelFile(e)
            }).catch(() => {
            this.$message.info("已取消")    
          });
        }
    },
    sendDelFile(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({FILESNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({FILESNOWID:id})).toUpperCase())
        this.$http.post("Defect/DeleteMeetinFile", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.getHYFiles()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    uploadFun(f){
        var type =""
        if(f.file.type  === 'image/jpeg' || f.file.type === 'image/png'){
            type="1"
        }else{
            type="2"
        }
        this.showLoading("请稍后...")
        var fd = new FormData();
        fd.append("file", f.file);
        var fname=f.file.name
        this.$upload.post("Defect/UploadMeetingFile?MeetingSnowID="+this.checkHYID+"&FILENAME="+fname+"&TYPE="+type, fd)
            .then((response) => {
            //console.log(response)
            this.hideLoading()
            if (response.data.SUCCESS) {
                    this.hideLoading()
                    this.$message.success("上传成功")
                    this.$refs.upload.clearFiles()
                    this.getHYFiles()
            }else{
                this.hideLoading()
                this.$message.error("上传失败")
            }
            })
            .catch((error) => {
            this.hideLoading()
        });
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style scoped>
.fileBox{overflow: hidden;margin: 20px 0;padding: 20px;background-color: #F8F8F9;}
.fileItem{border-radius: 3px;background-color: #FFFFFF;cursor: pointer;padding: 10px;float:left;margin-right: 20px;margin-bottom: 20px;width: 206px;}
.fileItem:nth-of-type(3n){margin-right: 0!important;}
.fileItem .name{font-size: 15px;font-weight: bold;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;color: #444;margin-bottom: 2px;}
.fileItem .keywords{font-size: 14px;font-weight: bold;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;margin-bottom: 5px;color: #909399;}
.fileItem .fileBtn{overflow: hidden;text-align: left;}
.fileItem span{margin-right: 10px;}
.noFile{text-align: center;font-size: 15px;color: #409EFF;}
.fileTitle{font-size: 16px;font-weight: bold;overflow: hidden;}
.fileItem .fileOut{width:206px;height:206px;overflow:hidden;}
</style>