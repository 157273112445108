<template>
    <div class="views-main">
        <div class="views-main-title">
            <el-button type="primary" icon="el-icon-arrow-left" style="float:left;margin-right:20px;" size="small" @click="backXJGL">返回</el-button>
            <p class="txt" style="margin-top:3px;">检查任务</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                
                <el-input v-model="name" placeholder="请输入名称" class="iw" ></el-input>
                <el-button type="primary" @click="searchCheckTask">查询</el-button>
                <el-button type="success" @click="addCheckTask" class="fr">添加</el-button>
                
            </div>
            <div class="views-main-body">
                <el-table :data="checkTaskList" stripe style="width: 100%"> 
                    <el-table-column prop="NAME" label="名称"></el-table-column>
                    <!-- <el-table-column prop="POSITIONNAME" label="岗位"></el-table-column> -->
                    <el-table-column prop="BZ" label="描述"></el-table-column>
                    <el-table-column prop="CHECKSTEP" label="检查频次" width="120">
                        <template slot-scope="scope">
                        <span v-if="scope.row.CHECKSTEP=='1'">日</span>
                        <span v-else-if="scope.row.CHECKSTEP=='2'">周</span>
                        <span v-else-if="scope.row.CHECKSTEP=='3'">月</span>
                        <span v-else>未知</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="CHECKSTEPNUM" label="频次间隔"></el-table-column>
                    <el-table-column prop="CHECKTASKITEMNUM" label="任务项数量"></el-table-column>
                    <el-table-column prop="STARTTIME" label="开始时间"></el-table-column>
                    <el-table-column prop="ENDTIME" label="结束时间"></el-table-column>
                    <el-table-column label="操作" width="130">
                        <template slot-scope="scope">
                        <el-button @click="editCheckTask(scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button>
                        <el-button @click="delCheckTask(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
                        <el-button @click="showCheckTask(scope.row)"  type="text" size="small" style="color:#409EFF">任务项</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="checkTaskModal" width="700px">
            <el-form :model="checkTaskInfo" status-icon :rules="checkTaskrules"  ref="uForm" label-width="100px" class="demo-uForm">
                <el-form-item label="名称" prop="NAME">
                    <el-input v-model="checkTaskInfo.NAME" autocomplete="off"></el-input>
                </el-form-item>
                <!-- <el-form-item label="岗位" prop="POSITIONID">
                    <el-select v-model="checkTaskInfo.POSITIONID" placeholder="点击选择岗位"  filterable  style="width:100%;" @change="postChange">
                            <el-option v-for="item in postList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item> -->
                <el-form-item label="检查频次" >
                    <el-select v-model="checkTaskInfo.CHECKSTEP" placeholder="点击选检查频次"   style="width:100%;">
                            <el-option v-for="item in stepList" :key="item.value" :label="item.label" :value="item.value" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="频次间隔" prop="CHECKSTEPNUM">
                    <el-input v-model="checkTaskInfo.CHECKSTEPNUM" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="起始日期" prop="DATERANGE">
                    <el-date-picker v-model="checkTaskInfo.DATERANGE" type="datetimerange" range-separator="至" start-placeholder="开始日期"  end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']" style="width:100%;"> </el-date-picker>
                </el-form-item>
                <el-form-item label="描述" prop="BZ">
                    <el-input v-model="checkTaskInfo.BZ" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item>
                <el-button type="primary" @click="submitCheckTaskForm()">提交</el-button>
                <el-button @click="checkTaskModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog :title="taskItemModalTitle" :visible.sync="taskItemModal" width="1000px" top="50px" class="taskItemBox">
            <div class="left">
                <div class="taskItemBoxt">任务模板</div>
                <div class="list">
                    <p class="tt">模板选择：</p>
                    <el-select v-model="templetId" placeholder="点击选择模板"   style="margin:0 12px;" @change="templetChange">
                            <el-option v-for="item in templetList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                    <el-button type="primary" @click="appenToTaskListByTemplet()" style="margin:10px 12px;width:176px;">添加至任务列表</el-button>
                    <p class="tt">其他任务项：</p>
                    <div class="ilist">
                        <div class="searchBox">
                            <el-input v-model="otherCode" autocomplete="off" placeholder="编号搜索" style="width:76%;"></el-input>
                            <el-button type="text" @click="getTempletAthers()"  style="float:right;margin-top:0px;">查询</el-button>
                        </div>
                        <div  v-if="otherLoading">
                               <p class="otherItemLoadingstr">加载中,请稍后</p> 
                        </div>
                        <div v-else>
                                
                                <div v-if="templateItemss.length>0">
                                    <div class="templeItem" v-for="item in templateItemss" :key="item.SNOWID" @click="appendTempleItem(item.SNOWID)">
                                        <p class="code" :title="item.CODE">编号：{{item.CODE}}</p>
                                        <p class="sb" :title="item.MACHINERESPONSE.NAME">设备：{{item.MACHINERESPONSE.NAME}}</p>
                                        <p class="sb" :title="item.POSITIONNAME">岗位：{{item.POSITIONNAME}}</p>
                                        <p class="bz" :title="item.BZ">备注：{{item.BZ}}</p>
                                    </div>
                                </div>

                        </div>
                         <el-pagination  v-if="templateItemss.length>0"  background layout="prev,pager,next"  :current-page="unpageIndex" page-size="20" :total="unTotal" class="pg" @current-change="unPGChange"></el-pagination>
                    </div>   
                    <p class="msg">点击检查项快速添加</p>  
                </div>
            </div>
            <div class="right">
                <div class="taskItemBoxt">
                    任务项列表 
                    <el-button type="text" @click="addTaskTtem()" class="addTaskTtem">添加任务项</el-button>
                    
                </div>       
                <div class="ilist itemOutBox"  >   
                    <el-form  :model="taskItemObj" status-icon   ref="tastItemForm" label-width="60px" class="tastItemForm itemOutForm">
                        <div style="overflow:hidden;margin-top:20px;"  v-for="(task, index) in taskItemObj.taskItemList" :key="task.key">
                            <div v-if="task.TYPE!='3'" style="overflow:hidden;border-bottom:1px solid #ADADAD;">
                                <el-form-item label="编号" :prop="'taskItemList.' + index + '.CODE'" :rules="{ required: true, message: '编号不能为空', trigger: 'blur'}" class="halfItem">
                                    <el-input  v-model="task.CODE" autocomplete="off"></el-input>
                                </el-form-item>
                                <el-form-item label="类型"  class="halfItem">
                                    <el-select  v-model="task.WF" placeholder="点击选择类型"   style="widht:100%;" @change="wfChange(index)">
                                            <el-option v-for="item in wfList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="设备" :prop="'taskItemList.' + index + '.SB'" :rules="{ required: true, message: '请选择设备', trigger: 'blur'}" class="halfItem">
                                    <el-select  v-model="task.SB" placeholder="点击选择设备"   style="widht:100%;" @change="SBChange(index)">
                                            <el-option v-for="item in task.SBLIST" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                                    </el-select>
                                </el-form-item>
                                <!-- :prop="'taskItemList.' + index + '.GW'" :rules="{ required: true, message: '请选择岗位', trigger: 'blur'}" -->
                                <el-form-item label="岗位" class="halfItem">
                                    <el-select  v-model="task.GW" placeholder="点击选择岗位"   style="widht:100%;" @change="GWChange(index)">
                                            <el-option v-for="item in postList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="备注" :prop="'taskItemList.' + index + '.BZ'" :rules="{ required: true, message: '备注不能为空', trigger: 'blur'}" class="allItem">
                                    <el-input  v-model="task.BZ" autocomplete="off" style="width:84%;float:left;" type="textarea" class="jcxbz"></el-input>
                                    <el-button type="text" style="color:#F56C6C;float:right;"  @click="delTaskItem(index)">删除</el-button>
                                </el-form-item>
                            </div>
                            
                        </div>    
                        <el-form-item class="bottomBtns">
                            <el-button type="primary" @click="submitCheckTaskItemForm()">保存</el-button>
                            <el-button @click="taskItemModal = false">取消</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
export default {
  name: "CheckTaskManage",
  data() {
    var NAME = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入名称"));
      } else {
        callback();
      }
    };
    var POSITIONID = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择岗位"));
      } else {
        callback();
      }
    };
    var BZ = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入检查任务描述"));
      } else {
        callback();
      }
    };
    var CHECKSTEPNUM =(rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入检查频次间隔'));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('检查频次间隔只能是数字'));
        }else if(!/^\+?(0|[1-9][0-9]*)$/.test(value)){
            callback(new Error('只能输入大于等于0的整数'));
        }else {
          callback();
        }
    };
    var DATERANGE = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择起始日期"));
      } else {
        callback();
      }
    };
    
    
    return {
        canDo:true,
        name:"",
        checkTaskList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,
        doTypeStr:"",
        doType:"",
        checkTaskModal:false,
        checkTaskInfo:{
            NAME:"",
            // POSITIONID:"",
            // POSITIONNAME:"",
            BZ:"",
            CHECKSTEP:1,
            CHECKSTEPNUM:0,
            DATERANGE:"",
        },
        checkTaskrules: {
            NAME: [{ validator: NAME, trigger: "blur" }],
            //POSITIONID: [{ validator: POSITIONID, trigger: "blur" }],
            BZ: [{ validator: BZ, trigger: "blur" }],
            CHECKSTEPNUM: [{ validator: CHECKSTEPNUM, trigger: "blur" }],
            DATERANGE:[{ validator: DATERANGE, trigger: "blur" }],
        },

        stepList:[
            {
                value:1,
                label:"日"
            },
             {
                value:2,
                label:"周"
            },
             {
                value:3,
                label:"月"
            },
        ],
        postList:[],


        taskItemModalTitle:"",
        taskItemModal:false,
        nowCheckTask:"",
        wfList:[],
        taskItemObj:{
            taskItemList:[],
        },

        nowTaskItemList:{},


        templetId:"",
        templetList:[],
        templateItems:[],

        otherCode:"",
        templateItemss:[],
        otherLoading:false,

        unpageIndex:1,

        unTotal:0,

    };
  },
  mounted() {
    //console.log(this.$store.state.checkType)
    this.loadItem=null
    this.getCheckTaskList()
    this.getPostList()
    this.getWFList()
    this.getTempletList()
    //this.getTempletAthers()
  },
  methods: {
    //获取模板
    getTempletList(){
        var params={
            PAGEINDEX:1,
            PAGESIZE:10000,
            TYPE:this.$store.state.checkType,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("CheckTemplate/GetCheckTemplateList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.templetList=response.data.DATA
            } 
            })
    },

    //获取未绑定的模板
    getTempletAthers(){
        //templateItems
        this.unpageIndex=1
        this.getTempletAthersFun()
    },
    unPGChange(e){
        this.unpageIndex=e
        this.getTempletAthersFun()
    },
    getTempletAthersFun(){
        this.otherLoading=true
        var params={
            CODE:this.otherCode,
            TYPE:this.$store.state.checkType,
            PAGEINDEX:this.unpageIndex,
            PAGESIZE:10,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("CheckItem/GetUnBindCheckItemList", md5_data)
            .then((response) => {
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.templateItemss=response.data.DATA
                    this.unTotal=response.data.TOTALCOUNT
                } 
                this.otherLoading=false
            })
            .catch((error) => {
                this.hideLoading()
                this.otherLoading=false
            });
    },
    templetChange(item){
        this.templateItems=[]
        if(item!=""){
            this.getTempletItemById(item)
        }
    },
    getTempletItemById(id){
        this.showLoading("请稍后")
        var params={
            CHECKTEMPLATEID:id,
            TYPE:this.$store.state.checkType,
            PAGEINDEX:1,
            PAGESIZE:10000,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("CheckItem/GetCheckItemListByTempID", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.templateItems=response.data.DATA
                } 
            })
            
    },

    //获取设备类型列表
    getWFList(){
        var params={
            PAGEINDEX:1,
            PAGESIZE:10000,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("MachineType/GetMachineTypeList", md5_data)
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.wfList=response.data.DATA
                } 
            })
    },
    wfChange(index){
        var item=this.taskItemObj.taskItemList[index]
        item.SB=""
        this.getWFMachine(item.WF,index)
    },
    SBChange(index){
        var list=this.taskItemObj.taskItemList
        var nl=[]
        for(var i=0;i<list.length;i++){
            var d=list[i]
            if(i == index){
                var dl=d.SBLIST
                for(var j=0;j<dl.length;j++){
                    if(dl[j].SNOWID == d.SB){
                        d.SBNAME= dl[j].NAME
                        break;
                    }
                }
            }
            nl.push(d)
        }
        this.taskItemObj.taskItemList=nl   
    },
    GWChange(index){
        var list=this.taskItemObj.taskItemList
        var nl=[]
        for(var i=0;i<list.length;i++){
            var d=list[i]
            if(i == index){
                var dl=this.postList
                for(var j=0;j<dl.length;j++){
                    if(dl[j].SNOWID == d.GW){
                        d.GWNAME= dl[j].NAME
                        break;
                    }
                }
            }
            nl.push(d)
        }
        this.taskItemObj.taskItemList=nl   
    },
    //获取设备
    getWFMachine(id,index){
        var params={
            MACHINETYPEID:[id],
            PAGEINDEX:1,
            PAGESIZE:10000,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Machine/GetMachine", md5_data)
            .then((response) => {
                // console.log("获取设备")
                // console.log(response)
                var sb=response.data.DATA 
                var list=this.taskItemObj.taskItemList
                var nl=[]
                for(var i=0;i<list.length;i++){
                    var d=list[i]
                    if(i == index){
                        d.SBLIST=sb
                    }
                    nl.push(d)
                }
                this.taskItemObj.taskItemList=nl
            })
    },


    getPostList(){
        var params={
            PAGEINDEX:1,
            PAGESIZE:10000,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("PositonManage/GetPositionListWithoutPage", md5_data)
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.postList=response.data.DATA
                } 
            })
    },
    postChange(item){
        for(var i=0;i<this.postList.length;i++){
            if(this.postList[i].SNOWID == item){
                this.checkTaskInfo.POSITIONNAME = this.postList[i].NAME
            }
        }
    },
    getCheckTaskList(){
        this.showLoading("请稍后")
        var params={
            PAGEINDEX:this.PageIndex,
            PAGESIZE:this.PageSize,
            NAME:this.name,
            TYPE:this.$store.state.checkType,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("CheckTask/GetChecktask", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.checkTaskList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.checkTaskList=[]
                this.Total=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.checkTaskList=[]
                this.Total=0
        });
    },
    searchCheckTask(){
        this.PageIndex=1
        this.getCheckTaskList()
    },
    addCheckTask(){
        this.checkTaskInfo={
            NAME:"",
            // POSITIONID:"",
            // POSITIONNAME:"",
            BZ:"",
            CHECKSTEP:1,
            CHECKSTEPNUM:0,
            DATERANGE:"",
        }
        this.doTypeStr="添加检查任务"
        this.doType="1"
        this.checkTaskModal=true
    },
    sendAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        var date=this.checkTaskInfo.DATERANGE
        this.checkTaskInfo.STARTTIME= this.comjs.getDateStrByDate(date[0])
        this.checkTaskInfo.ENDTIME= this.comjs.getDateStrByDate(date[1])
        delete this.checkTaskInfo.DATERANGE;
        this.checkTaskInfo.CHECKSTEPNUM = parseInt( this.checkTaskInfo.CHECKSTEPNUM)
        this.checkTaskInfo.TYPE=this.$store.state.checkType
        var md5_data =this.comjs.requestDataEncrypt(this.checkTaskInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.checkTaskInfo)).toUpperCase())
        this.$http.post("CheckTask/CreateNewChecktask", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.checkTaskModal=false
                    this.getCheckTaskList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    editCheckTask(item){
        //  var pm=""
        //  for(var i=0;i<this.postList.length;i++){
        //      if(this.postList[i].SNOWID == item.POSITIONID){
        //          pm=this.postList[i].NAME
        //      }
        //  }
         var dr=[]
         dr.push(new Date(item.STARTTIME))
         dr.push(new Date(item.ENDTIME))
         this.checkTaskInfo={
            SNOWID:item.SNOWID,
            NAME:item.NAME,
            // POSITIONID:item.POSITIONID,
            // POSITIONNAME:pm,
            BZ:item.BZ,
            CHECKSTEP:item.CHECKSTEP,
            CHECKSTEPNUM:item.CHECKSTEPNUM,
            DATERANGE:dr,
            CREATETIME:item.CREATETIME,
        }
        this.doTypeStr="修改检查任务"
        this.doType="2"
        this.checkTaskModal=true
    },
    sendEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        var date=this.checkTaskInfo.DATERANGE
        this.checkTaskInfo.STARTTIME= this.comjs.getDateStrByDate(date[0])
        this.checkTaskInfo.ENDTIME= this.comjs.getDateStrByDate(date[1])
        delete this.checkTaskInfo.DATERANGE;
        this.checkTaskInfo.CHECKSTEPNUM = parseInt( this.checkTaskInfo.CHECKSTEPNUM)
        this.checkTaskInfo.TYPE=this.$store.state.checkType
        var md5_data =this.comjs.requestDataEncrypt(this.checkTaskInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.checkTaskInfo)).toUpperCase())
        this.$http.post("CheckTask/EditChecktask", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.checkTaskModal=false
                     this.getCheckTaskList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    delCheckTask(item){
        if(this.canDo){
            this.$confirm('确定删除检查任务：'+item.NAME+'？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
            this.sendDel(item.SNOWID,item.CREATETIME)
            }).catch(() => {
            this.$message.info("已取消")    
          });
        }

    },
    sendDel(id,time){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({SNOWID:id,CREATETIME:time}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:id,CREATETIME:time})).toUpperCase())
        this.$http.post("CheckTask/DeleteChecktask", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.checkTaskModal=false
                    this.getCheckTaskList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    PGChange(e){
        this.PageIndex=e
        this.getCheckTaskList()
    },
    submitCheckTaskForm() {
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    if(this.doType=="1"){
                        this.sendAdd()
                    }else if(this.doType=="2"){
                        this.sendEdit()
                    }
                } else {
                    return false;
                }
            });
        }
    },
    //显示窗口
    showCheckTask(item){
        //console.log(item)
        this.nowCheckTask=item.SNOWID
        this.taskItemModalTitle=item.NAME+'-任务项'
        this.taskItemModal=true

        this.taskItemObj={
            taskItemList:[],
        }
        this.getTaskItemByCheckTask(item.SNOWID,item.CREATETIME,1)
    },

    //获取已有的任务项
    getTaskItemByCheckTask(id,time,pageindex){
        var md5_data =this.comjs.requestDataEncrypt({CHECKTASKID:id,CREATETIME:time,PAGESIZE:20,PAGEINDEX:pageindex,}, this.$store.state.token,this.$md5(this.comjs.sortAsc({CHECKTASKID:id,CREATETIME:time,PAGESIZE:20,PAGEINDEX:pageindex,})).toUpperCase())
        this.$http.post("CheckTaskItem/GetChecktaskItem", md5_data)
            .then((response) => {
                this.canDo=true
                if (response.data.SUCCESS) {
                   var list=response.data.DATA
                   for(var i=0;i<list.length;i++){
                        if(list[i].MACHINERESPONSE){
                            this.getTaskItemSBList(list[i],"2")
                        }else{
                            this.delTaskItemByError(list[i])
                        }
                   }
                   if(list.length==20 && (pageindex*20)<response.data.TOTALCOUNT){
                        this.getTaskItemByCheckTask(id,time,(pageindex+1))
                   }
                }
            })
    },
    //遍历已有任务项设备列表
    getTaskItemSBList(item,type){
        var newItem={
            CODE:item.CODE,
            BZ:item.BZ,
            WF:item.MACHINERESPONSE.MACHINETYPEID,
            SB:item.MACHINERESPONSE.SNOWID,
            SBLIST:[],
            SBNAME:item.MACHINERESPONSE.NAME,
            TYPE:type,
            SNOWID:"",
            CREATETIME:"",
            GW:item.POSITIONID,
            GWNAME:item.POSITIONNAME,
        }
        if(type=="2"){
            newItem.SNOWID=item.SNOWID
            newItem.CREATETIME=item.CREATETIME
        }
        var params={
            MACHINETYPEID:[item.MACHINERESPONSE.MACHINETYPEID],
            PAGEINDEX:1,
            PAGESIZE:10000,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Machine/GetMachine", md5_data)
            .then((response) => {
                // console.log("获取风场设备")
                // console.log(response)
                var sb=response.data.DATA 
                newItem.SBLIST=sb
                this.addTaskItemByCheckTask(newItem)
            }).catch((error) => {
                this.addTaskItemByCheckTask(newItem)
             });
    },
    addTaskItemByCheckTask(item){
        this.taskItemObj.taskItemList.push(item)
        if(item.TYPE=="2"){
            const itemObj={
                BZ:item.BZ,
                CODE:item.CODE,
                GW:item.GW,
                GWNAME:item.GWNAME,
                SB:item.SB,
                SBNAME:item.SBNAME,
                WF:item.WF,
            }
            this.nowTaskItemList[item.SNOWID] =itemObj
        }
    },
    //MACHINERESPONSE

    //

    //点击模板
    appenToTaskListByTemplet(){
        if(this.templetId ==""){
            this.$message.error("请选择一个模板")
        }else{
            var list =this.templateItems
            var count=0
            for(var i=0;i<list.length;i++){
                var f=this.flagRepeatItem(list[i])
                if(!f){
                    this.getTaskItemSBList(list[i],"1")
                }else{
                    count++
                }
                
            }
            if(count>0){
                this.$message.error("已自动过滤"+count+"个重复项")
                this.afterAppendItem()
            }
        }
    },
    appendTempleItem(id){
        var list =this.templateItemss
        for(var i=0;i<list.length;i++){
            if(list[i].SNOWID == id){
                var f=this.flagRepeatItem(list[i])
                if(!f){
                    this.getTaskItemSBList(list[i],"1")
                }else{
                    this.$message.error("改模板项已存在！")
                }
                
                break;
            }
        }
    },
    afterAppendItem(){
       window.scrollToBottom("itemOutForm","itemOutBox")
    },
    flagRepeatItem(item){
        var f=false
        var list=this.taskItemObj.taskItemList
        for(var i=0;i<list.length;i++){
            var d=list[i]
            if(d.CODE == item.CODE && d.BZ== item.BZ && d.SB == item.MACHINEID){
                f=true
                break;
            }
        }
        return f
    },
    //手动添加新任务项
    addTaskTtem(){
        var item={
            CODE:"",
            BZ:"",
            WF:"",
            SB:"",
            SBNAME:"",
            SBLIST:[],
            TYPE:"1",
            SNOWID:"",
            CREATETIME:"",
            GW:"",
            GWNAME:"",
        }
        this.taskItemObj.taskItemList.push(item)
        this.afterAppendItem()
    },
    //删除某条任务项
    delTaskItem(index){
        //console.log(index)
        var item = this.taskItemObj.taskItemList[index]

        if(this.canDo){
            this.$confirm('确定删除检查任务项？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
                    if(item.TYPE=="1"){
                        this.delTaskItemByFrom(index)
                    }else{
                        var fl=this.taskItemObj.taskItemList
                        var nfl=[]
                        for(var i=0;i<fl.length;i++){
                            var d=fl[i]
                            if(i== index){
                                d.TYPE="3"  
                            }
                            nfl.push(d)
                        }
                        this.taskItemObj.taskItemList = nfl
                    }
            }).catch(() => {
            this.$message.info("已取消")    
          });
        }
        
    },
    delTaskItemByFrom(index){
        var fl=this.taskItemObj.taskItemList
        var nfl=[]
        for(var i=0;i<fl.length;i++){
        if(i!= index){
            nfl.push(fl[i])
        }
        }
        this.taskItemObj.taskItemList = nfl
    },

    //提交任务项列表
    submitCheckTaskItemForm(){
        if (this.canDo) {
            this.$refs.tastItemForm.validate((valid) => {
                if (valid) {
                    var list = this.taskItemObj.taskItemList
                    // console.log(list)
                    // console.log(this.nowTaskItemList)
                    if(list.length>0){
                        this.canDo=false
                        this.showLoading("请稍后")
                        for(var i=0;i<list.length;i++){
                            if(list[i].TYPE=="1"){
                                this.addTaskItemByData(list[i],i)
                            }else if(list[i].TYPE=="2"){
                                var baseData=this.nowTaskItemList[list[i].SNOWID]
                                if(baseData.BZ != list[i].BZ || baseData.CODE != list[i].CODE || baseData.GW != list[i].GW || baseData.GWNAME != list[i].GWNAME || baseData.SB != list[i].SB || baseData.SBNAME != list[i].SBNAME  || baseData.WF != list[i].WF){
                                    this.editTaskItemByData(list[i],i)
                                }else{
                                    this.afterTaskItem(i)
                                }
                                
                            }else if(list[i].TYPE=="3"){
                                this.delTaskItemByData(list[i],i)
                            }
                        }
                    }else{
                        this.taskItemModal=false
                    }
                } else {
                    return false;
                }
            });
        }
    },
    addTaskItemByData(item,index){
        var params={
            CODE: item.CODE, 
            MACHINEID: item.SB, 
            MACHINENAME: item.SBNAME, 
            BZ: item.BZ, 
            CHECKTASKID: this. nowCheckTask,
            POSITIONID:item.GW,
            POSITIONNAME:item.GWNAME,
            TYPE:this.$store.state.checkType
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("CheckTaskItem/CreateNewChecktaskItem", md5_data)
            .then((response) => {
                this.afterTaskItem(index)
            })
            .catch((error) => {
                this.afterTaskItem(index)
             });
    },
    editTaskItemByData(item,index){
        var params={
            SNOWID:item.SNOWID,
            CODE: item.CODE, 
            MACHINEID: item.SB, 
            MACHINENAME: item.SBNAME, 
            BZ: item.BZ, 
            CHECKTASKID: this. nowCheckTask,
            CREATETIME:item.CREATETIME,
            POSITIONID:item.GW,
            POSITIONNAME:item.GWNAME,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("CheckTaskItem/EditChecktaskItem", md5_data)
            .then((response) => {
                this.afterTaskItem(index)
            })
            .catch((error) => {
                this.afterTaskItem(index)
             });
    },
    delTaskItemByData(item,index){
        var md5_data =this.comjs.requestDataEncrypt({SNOWID:item.SNOWID,CREATETIME:item.CREATETIME}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:item.SNOWID,CREATETIME:item.CREATETIME})).toUpperCase())
        this.$http.post("CheckTaskItem/DeleteChecktaskItem", md5_data)
            .then((response) => {
                this.afterTaskItem(index)
            })
            .catch((error) => {
               this.afterTaskItem(index)
             });
    },
    delTaskItemByError(item){
        var md5_data =this.comjs.requestDataEncrypt({SNOWID:item.SNOWID,CREATETIME:item.CREATETIME}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:item.SNOWID,CREATETIME:item.CREATETIME})).toUpperCase())
        this.$http.post("CheckTaskItem/DeleteChecktaskItem", md5_data)
            .then((response) => {
                //删除无效数据检查项
            })
    },
    afterTaskItem(index){
        if(index==this.taskItemObj.taskItemList.length -1){
            this.hideLoading()
            this.$message.success("已保存")
            this.canDo=true   
            this.taskItemModal=false
        }
    },
    backXJGL(){
        this.$router.replace('/XJGL')
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style scoped>
.otherItemLoadingstr{text-align: center; margin-top: 100px;font-size: 14px;}


.taskItemBox .el-dialog{overflow: hidden;}
.taskItemBox  .left .taskItemBoxt{font-size: 16px;height: 40px;line-height: 40px;font-weight: bold;text-align: center;color: #333;}
.taskItemBox .left{width: 200px;height: 600px;background-color: #f4f4f5;float:left;}
.taskItemBox .left .tt{font-size: 14px;height: 30px;line-height: 30px;padding: 0 12px;color: #555;margin-top: 10px;}
.taskItemBox .left .ilist{padding: 12px;height: 325px;overflow:auto;}
.taskItemBox .left .ilist .templeItem{background-color: #409EFF;border-radius: 3px;cursor: pointer;overflow: hidden;padding: 8px 12px;margin-top: 12px;}
.taskItemBox .left .ilist .templeItem:hover{background-color: #53A8FF;}
.taskItemBox .left .ilist .templeItem p{color: #FFFFFF;}
.taskItemBox .left .ilist .templeItem .name{font-size: 15px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;}
.taskItemBox .left .ilist .templeItem .code{font-size: 14px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;margin-top: 3px;}
.taskItemBox .left .ilist .templeItem .sb{font-size: 14px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;margin-top: 3px;}
.taskItemBox .left .ilist .templeItem .bz{font-size: 14px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;margin-top: 3px;}
.taskItemBox .left .msg{font-size: 13px;color: #E6A23C;text-align: center;margin-top: 6px;}

.taskItemBox  .left .searchBox{overflow: hidden;position: relative;}

.taskItemBox  .left .searchBox .searchBtn{position: absolute;left: 10px;top: 0px;}

.taskItemBox .right{width: 740px;height: 600px;background-color: #f4f4f5;margin-left: 20px;float:left;}
.taskItemBox  .right .taskItemBoxt{font-size: 16px;height: 40px;line-height: 40px;font-weight: bold;color: #333;padding: 0 24px;overflow: hidden;}
.taskItemBox  .right .addTaskTtem{float: right;}

.taskItemBox  .right .ilist{padding: 12px;height: 505px;overflow:auto;}

.tastItemForm{min-height: 450px;overflow: hidden;position: relative;padding-bottom: 50px;}
.tastItemForm .halfItem{width: 33.33%;display: inline-block;}
.tastItemForm .allItem{width: 65%;float:left;}
.tastItemForm .bottomBtns{position: absolute;right: 0;bottom:0;margin-bottom: 0;}


</style>
<style>
.jcxbz .el-textarea__inner{height:100px!important;}
</style>