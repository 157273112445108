<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">定检任务查看</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                
                <el-select v-model="fid" placeholder="选择风场"  filterable  clearable class="iww">
                        <el-option v-for="item in factoryList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                </el-select>
                <el-select v-model="tid" placeholder="选择任务"  filterable  clearable class="iww">
                        <el-option v-for="item in taskList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                </el-select>
                <el-select v-model="djtype" placeholder="选择类型"  filterable  clearable class="iww">
                        <el-option label="风机第一、二、三次定检工作（外委）" value="1" ></el-option>
                        <el-option label="风机第四、五、六次定检工作（自检）" value="2" ></el-option>
                        <!-- <el-option label="每年定检时对风向仪进行对中校对。（每年外委定检期内进行校对）" value="3" ></el-option>
                        <el-option label="每年定检时校对叶片对角。（每年外委定检期内进行校对）" value="4" ></el-option> -->
                        <el-option label="关注高桥和围屏风机负荷情况及机组是否自动进入安全模式状态，避免造成发电量损失（日常密切监控）" value="5" ></el-option>
                        <el-option label="每季度核对测风仪及风向，避免影响发电量损失。（日常密切监控）" value="6" ></el-option>
                        <el-option label="内窥镜检查" value="7" ></el-option>
                        <el-option label="安全交底和技术交底内容" value="8" ></el-option>
                        <el-option label="外委及自检缺陷明细及消缺明细及消缺完成报告" value="9" ></el-option>
                </el-select>
                <el-button type="primary" @click="searchPost">查询</el-button>  
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    
                    <el-table-column type="expand">
                        <template slot-scope="props">
                            <div class="exItemOut">
                                <span class="exItem">上次定期工作完成时间:{{props.row.LASTWORKTIME}}</span>
                                <span class="exItem">本次定期工作完成时间:{{props.row.WORKTIME}}</span>
                                <span class="exItem">上次定期工作遗留缺陷及处理情况:{{props.row.LASTWORKBZ}}</span>
                                <span class="exItem">本次定期工作发现缺陷及处理情况:{{props.row.WORKBZ}}</span>
                                <span class="exItem">机舱外环境温度:{{props.row.OUTTEMPERATURE}}</span>
                                <span class="exItem">发电机前轴轴承温度:{{props.row.ELECFRONTTEMPERATURE}}</span>
                                <span class="exItem">机舱内部温度:{{props.row.ELECBEHINDTEMPERATURE}}</span>
                                <span class="exItem">发电机后轴轴承温度:{{props.row.WORKTIME}}</span>
                                <span class="exItem">齿轮箱高速轴轴承温度:{{props.row.CLQUICKTEMPERATURE}}</span>
                                <span class="exItem">冷却水温度:{{props.row.COLDWATERTEMPERATURE}}</span>
                                <span class="exItem">齿轮箱油温:{{props.row.CLOILTEMPERATURE}}</span>
                                <span class="exItem">液压油系统压力:{{props.row.PRESSSYSPOWER}}</span>
                                <span class="exItem">发电机定子温度:{{props.row.ELECDZTEMPERATURE}}</span>
                                <span class="exItem">液压油温:{{props.row.PRESSOILTEMPERATURE}}</span>
                                <span class="exItem">主轴温度（前）:{{props.row.MAINFRONTTEMPERATURE}}</span>
                                <span class="exItem">主轴温度（后）:{{props.row.MAINBEHINDTEMPERATURE}}</span>
                            </div>
                            <div class="exItemOut">
                                <p class="exTitle">任务列表</p>
                                <div class="exItemTask" v-for="task in props.row.CheckTaskResponse" :key="task.SNOWID ">
                                   <span class="exItem">任务名称:{{task.NAME}}</span>
                                   <span class="exItem">岗位:{{task.POSITIONNAME}}</span>
                                   <span class="exItem">任务描述:{{task.BZ}}</span>
                                   <span class="exItem" v-if="task.CHECKSTEP=='1'">检查频次:日</span>
                                   <span class="exItem" v-else-if="task.CHECKSTEP=='2'">检查频次:周</span>
                                   <span class="exItem" v-else>检查频次:月</span>
                                   <span class="exItem">检查频次间隔数:{{task.CHECKSTEPNUM}}</span>
                                   <span class="exItem">任务开始时间:{{task.STARTTIME}}</span>
                                   <span class="exItem">任务结束时间:{{task.ENDTIME}}</span>
                                   <span class="exItem">检查项数量:{{task.CHECKTASKITEMNUM}}</span>
                                </div>
                            </div>
                           
                        </template>
                    </el-table-column>  
                    <el-table-column prop="FACTORYNAME" label="风场名称"></el-table-column>
                    <el-table-column prop="MACHINEGROUPNUM" label="机组号"></el-table-column>
                    <el-table-column prop="MACHINEGROUPMANAGER" label="机组负责人"></el-table-column>
                    <el-table-column prop="WORKNUM" label="工作票号"></el-table-column>
                    <el-table-column prop="WORKMANAGER" label="工作负责人"></el-table-column>
                    <el-table-column prop="WORKUSERS" label="工作班成员"></el-table-column>
                    <el-table-column prop="MANAGERNAME" label="管理人员"></el-table-column>
                    <el-table-column prop="CHECKERNAME" label="检查人员"></el-table-column>
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                            <el-button @click="showFiles(scope.row)" type="text" size="small" style="color:#E6A23C">相关附件</el-button>
                            <el-button @click="filePost(scope.row)"  type="text" size="small" style="color:#409EFF">上传工作票</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog title="流程相关附件" :visible.sync="showFileModal" width="800px" top="50px" :height="dialogMaxHeigh">
               <MFL :processid="nowProcessid"></MFL>
        </el-dialog>
    </div>
</template>
<script>
var h=document.body.clientHeight
import MFL from "../../components/ModelFileList"
export default {
  name: "DJRWCK",
  components:{
    MFL,
  },
  data() {
    var A= (rule, value, callback) => {
      if (value ==0) {
        callback(new Error("请选择三讲一落实执行卡"));
      } else {
        callback();
      }
    };

    var B= (rule, value, callback) => {
      if (value ==0) {
        callback(new Error("请选择风机工作票"));
      } else {
        callback();
      }
    };

    var C= (rule, value, callback) => {
      if (value ==0) {
        callback(new Error("请选择危险因素控制票"));
      } else {
        callback();
      }
    };
    return {
        canDo:true,
        djtype:"",
        fid:"",
        factoryList:[],
        tid:"",
        taskList:[],

        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,

        nowProcessid:"",
        showFileModal:false,

        dialogMaxHeigh:0,

        fileId:"",
        fileModal:false,
        fileInfo:{},
        filerules: {
            A: [{ validator: A, trigger: "blur" }],
            B: [{ validator: B, trigger: "blur" }],
            C: [{ validator: C, trigger: "blur" }],
        },
        fileA:null,
        fileB:null,
        fileC:null,
    };
  },
  mounted() {
    this.dialogMaxHeigh = h -300
    this.loadItem=null
    this.getPostList()
    this.getTaskList()
    this.getFactoryList()
  },
  methods: {
    getTaskList(){
        var params={
            PAGEINDEX:1,
            PAGESIZE:1000,
            TYPE:"4",
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("CheckTask/GetChecktask", md5_data)
            .then((response) => {
            this.hideLoading()
                if (response.data.SUCCESS) {
                    this.taskList=response.data.DATA
                } 
            })
    },
    getFactoryList(){
        var params={
            PAGEINDEX:1,
            PAGESIZE:10,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Factory/GetFactory", md5_data)
            .then((response) => {
            this.hideLoading()
                if (response.data.SUCCESS) {
                    this.factoryList=response.data.DATA
                } 
            })
    },
    getPostList(){
        this.showLoading("请稍后")
        var params={
            PAGEINDEX:this.PageIndex,
            PAGESIZE:this.PageSize,
            FACTORYID:this.fid,
            TASKID:this.tid,
            TYPE:this.djtype,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Inspection/GetInspectionList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
        });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    showFiles(item){
        this.nowProcessid=item.PROCESSID
        this.showFileModal=true
    },
    filePost(item){
        this.fileId=item.SNOWID
        this.fileInfo={
            A:0,
            B:0,
            C:0,
        }
        if(this.fileA){
            this.fileA=null
            this.$refs.uploadA.clearFiles()
        }
        if(this.fileB){
            this.fileB=null
            this.$refs.uploadB.clearFiles()
        }
        if(this.fileC){
            this.fileC=null
            this.$refs.uploadC.clearFiles()
        }
        this.fileModal=true
    },
    removeFileA(){
        this.fileInfo.A=0
        this.fileA=null
    },
    beforeAvatarUploadA(file) {
        const isWord = file.type === 'application/msword' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        if (!isWord) {
          this.$message.error('只能上传word文件!');
          this.fileInfo.A=0
          this.fileA=null
        }else{
            this.fileInfo.A=1
            this.fileA=file
        }
        return isWord;
    },
    removeFileB(){
        this.fileInfo.B=0
        this.fileB=null
    },
    beforeAvatarUploadB(file) {
        const isWord = file.type === 'application/msword' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        if (!isWord) {
          this.$message.error('只能上传word文件!');
          this.fileInfo.B=0
          this.fileB=null
        }else{
            this.fileInfo.B=1
            this.fileB=file
        }
        return isWord;
    },
    removeFileC(){
        this.fileInfo.C=0
        this.fileC=null
    },
    beforeAvatarUploadC(file) {
        const isWord = file.type === 'application/msword' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        if (!isWord) {
          this.$message.error('只能上传word文件!');
          this.fileInfo.C=0
          this.fileC=null
        }else{
            this.fileInfo.C=1
            this.fileC=file
        }
        return isWord;
    },
    uploadFun(file){
        //console.log(file)
        this.$refs.fForm.validate((valid) => {
                //console.log(valid)
            });
    },
    submitFileForm() {
        if (this.canDo) {
            this.$refs.fForm.validate((valid) => {
                //console.log(valid)
                if (valid) {
                    this.uploadFunModal()
                } else {
                    return false;
                }
            });
        }
    },
    uploadFunModal(){
      this.showLoading("请稍后...")
      var fd = new FormData();
      var nameA=this.fileA.name
      var nameB=this.fileB.name
      var nameC=this.fileC.name
      fd.append("file", this.fileA);
      fd.append("file", this.fileB);
      fd.append("file", this.fileC);

      this.$upload.post("Inspection/UploadWorkFile?ID="+this.fileId+"&FILENAME1="+nameA+"&FILENAME2="+nameB+"&FILENAME3="+nameC, fd)
        .then((response) => {
          //console.log(response)
         this.hideLoading()
          if (response.data.SUCCESS) {
             this.hideLoading()
             this.$message.success("上传完成")
             this.fileModal=false
          }else{
             this.hideLoading()
             this.$message.error("上传失败")
          }
        })
        .catch((error) => {
            this.hideLoading()
        });
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style scoped>
.exItemOut{overflow: hidden;margin-bottom: 20px;}
.exItemOut .exTitle{font-size: 16px;}
.exItemOut .exItem{font-size: 14px;display: inline-block;margin-right: 20px;}
</style>