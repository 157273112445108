<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">{{title}}</p>
        </div>
        <div class="tableBox" style="top:50px;overflow:auto;">
                <div class="conOut" v-if="has">
                    <p class="zlkStr">类型：{{lx}}</p>
                    <p class="zlkStr">标签：{{bq}}</p>
                    <p class="zlkStr" v-if="qx==1">描述：<el-button type="text" @click="showDocCon">点击查看</el-button></p>
                    <p class="zlkStr" v-else>描述：{{ms}}</p>
                    <div class="zlkStr" v-if="qx==1">
                        文件：<el-button type="text" @click="showDocCon">点击查看</el-button>
                    </div>
                    <p  v-else  class="zlkStr">文件： <a v-for="item in file" :key="item.SNOWID" :href="item.FILEPATH" target="_blank" class="fileLink">{{item.FILENAME}}</a></p>
                </div>
                <div class="conOur" v-else>
                    <p class="dataEmpty">暂无资料信息</p>
                </div>
        </div>
    </div>
</template>
<script>
export default {
  name: "KSFL",
  data() {

    return {
        title:"",
        canDo:true, 
        t:"",

        
        has:true,
        nowid:"",
        lx:"",
        bq:"",
        ms:"",
        file:[],

        qx:[],
    };
  },
  mounted() {
    this.t=this.$route.query.t
    this.getZL()
    this.loadItem=null

  },
  updated(){
      var t=this.comjs.GetQueryString(t)
      //console.log(t)
  },
  methods: {
      getZL(){
        this.$cookies.set("zlkindex",this.t)
        switch(this.t){
            case "1":
                this.title="生产管理标准化制度库"
                break;
            case "2":
                this.title="生产培训资料库"
                break;
            case "3":
                this.title="生产档案管理资料库"
                break;    
            case "4":
                this.title="维斯塔斯维护手册维护资料库"
                break;  
            case "5":
                this.title="内外管理平台名称及登录密码资料库"
                break;                     
        }
        var params={
            PAGEINDEX:1,
            PAGESIZE:1,
            TITLE:this.title,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Doc/GetDocList", md5_data)
            .then((response) => {
                if (response.data.SUCCESS) {
                    var d=response.data.DATA
                    if(d.length>0){
                        var item=d[0]
                        //console.log(item)
                        this.has=true
                        var lx=""
                        for(var i=0;i<item.DOCTYPELIST.length;i++){
                            if(i==0){
                                lx=item.DOCTYPELIST[i].NAME
                            }else{
                                lx=lx+"-"+item.DOCTYPELIST[i].NAME
                            }
                        }
                        this.lx=lx
                        this.bq=item.TAG
                        this.ms=item.CONTENT
                        this.qx=item.ISNEEDPERMISSIONS
                        this.file=item.DOCFILES
                        this.nowid=item.SNOWID
                    }else{
                        this.has=false
                    }
                } 
            })
      },
    showDocCon(item){
        if (this.canDo) {
            this.canDo=false
            this.showLoading("请稍后")
            var params={
                SNOWID:this.nowid
            }
            var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
            this.$http.post("SysAccount/EditHaveCount", md5_data)
                .then((response) => {
                    this.canDo=true
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.ms=item.CONTENT
                        this.qx=0
                        this.getDocFiles()
                    } else {
                        this.errorRequest(response.data.DATA,'暂无查看权限',true)
                    }
                })
                .catch((error) => {
                    this.$message.error("请求出错")
                    this.hideLoading()
                    this.canDo=true   
                });

        }

    },
    getDocFiles(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({SNOWID:this.nowid}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:this.nowid})).toUpperCase())
        this.$http.post("Doc/GetDocFileList", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                   this.file=response.data.DATA
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
  },
  computed: {
  },
  watch: {
    $route(){
      this.t=this.$route.query.t
    },
    t(n,o){
      this.getZL(n)
    }
  },
};
</script>
<style scoped>

a{margin-right: 20px;}
.zlkStr{margin-bottom: 20px;}
.dataEmpty{font-size: 16px;margin: 50px 20px;}
.fileLink{display: block;margin-bottom: 20px;margin-left: 60px;}
</style>