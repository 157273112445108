<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">我发起的流程</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-body" style="top:0px;">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column prop="TITLE" label="标题"></el-table-column>
                    <el-table-column prop="STATUS" label="状态">
                        <template slot-scope="scope">
                            <span v-if="scope.row.STATUS=='running'" style="color:#E6A23C">审批中</span>
                            <span v-else-if="scope.row.STATUS=='completed'" style="color:#67C23A">审批完成</span>
                            <span v-else-if="scope.row.STATUS=='canceled'" style="color:#909399">已取消</span>
                            <span v-else>未知</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="CREATED" label="创建日期"></el-table-column>
                    <el-table-column prop="LASTUPDATED" label="最后一次更新时间">
                        <template slot-scope="scope">
                            <span v-if="scope.row.LASTUPDATED!='0001-01-01 00:00:00' && scope.row.LASTUPDATED!='1900-01-01 00:00:00'">{{scope.row.LASTUPDATED}}</span>
                            <span v-else>/</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="FINISHED" label="完成时间">
                         <template slot-scope="scope">
                            <span v-if="scope.row.FINISHED!='0001-01-01 00:00:00' && scope.row.FINISHED!='1900-01-01 00:00:00'">{{scope.row.FINISHED}}</span>
                            <span v-else>/</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="160">
                    <template slot-scope="scope">
                        <el-button type="text" size="small" @click="detail(scope.row)" style="color:#409EFF">表单详情</el-button>
                        <el-button type="text" size="small" @click="track(scope.row)" style="color:#E6A23C">处理情况</el-button>
                    </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  name: "WFFlowsheetLog",
  data() {
   
    return {
        canDo:true,
        
       

        postList:[],



       
    };
  },
  mounted() {
    this.loadItem=null
    this.getPostList()
  },
  methods: {
    getPostList(){
        this.showLoading("请稍后")
        var params={
            INITIATORID:this.$store.state.guid,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Process/GetMyProList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
            } else {
                this.postList=[]
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
        });
    },
    track(item){
         window.open("/statics/myflow-master/index.html?token="+this.$store.state.token+"&type=3&fid="+item.MODELID+"&pid="+item.SNOWID)
        // var params={
        //     SNOWID:item.SNOWID,
        // }
        // var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        // this.$http.post("Process/GetProTrack", md5_data)
        //     .then((response) => {
        //     this.hideLoading()
        //     if (response.data.SUCCESS) {
        //        //
        //     } else {
        //         this.errorRequest(response.data.DATA,response.data.MSG,true)
        //     }
        //     })
        //     .catch((error) => {
        // });
    },
    detail(item){
        window.open("/statics/pages/LCCK.html?pid="+item.SNOWID+"&token="+this.$store.state.token)
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style >
</style>