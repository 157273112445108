import { render, staticRenderFns } from "./CreatorManage.vue?vue&type=template&id=64dc8d76&"
import script from "./CreatorManage.vue?vue&type=script&lang=js&"
export * from "./CreatorManage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports