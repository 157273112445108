<template>
    <div class="views-main">
        <div class="views-main-title">
            <el-button type="primary" icon="el-icon-arrow-left" style="float:left;margin-right:20px;" size="small" @click="backXJGL">返回</el-button>
            <p class="txt" style="margin-top:3px;">检查模板</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                <el-input v-model="name" placeholder="请输入名称" class="iw" ></el-input>
                <el-button type="primary" @click="searchTemplet">查询</el-button>
                <el-button type="success" @click="addTemplet">添加</el-button>
                <el-button type="primary" @click="downloadTemplet" class="fr">模板下载</el-button>
                <el-upload
                    action=""
                    name="file"
                    class="fr mr20"
                    :multiple="false"
                    :http-request="customRequest"
                    :showUploadList="false"
                    accept=".xlsx"
                    >
                    <el-button type="success">上传模板</el-button>
                </el-upload>
            </div>
            <div class="views-main-body">
                <el-table :data="templetList" stripe style="width: 100%"> 
                    <el-table-column prop="NAME" label="名称"></el-table-column>
                    <el-table-column prop="CREATETIME" label="创建日期"></el-table-column>
                    <el-table-column label="操作" width="130">
                        <template slot-scope="scope">
                            <el-button @click="editTemplet(scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button>
                            <el-button @click="delTemplet(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
                            <el-button @click="showCheckItem(scope.row)"  type="text" size="small" style="color:#409EFF">检查项</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="templetModal" width="500px" append-to-body>
            <el-form :model="templetInfo" status-icon :rules="postrules"  ref="uForm" label-width="100px" class="demo-uForm">
                <el-form-item label="名称" prop="NAME">
                    <el-input v-model="templetInfo.NAME" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item>
                <el-button type="primary" @click="submitTempletForm()">提交</el-button>
                <el-button @click="templetModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>

        <el-dialog :title="checkItemListTitle" :visible.sync="checkItemModal" width="1050px" top="50px" append-to-body>
                <div style="width:100%;overflow:hidden;margin-bottom:20px;">
                    <el-button type="success" @click="addCheckItem()" class="">绑定检查项</el-button>
                    <el-button type="danger" @click="delCheckItems()" class="">批量解绑</el-button>
                    <span style="float:right;margin-right: 24px;margin-top: 4px;font-size: 15px;">共{{checkItemList.length}}条</span>
                </div>
                <el-table :data="checkItemList" stripe style="width: 100%" :height="dialogMaxHeigh" @selection-change="handleSelectionChange">
                    <el-table-column type="selection" width="60"></el-table-column>
                    <el-table-column prop="CODE" label="编号"></el-table-column>
                    <el-table-column prop="POSITIONNAME" label="岗位"></el-table-column>
                    <el-table-column prop="MACHINERESPONSE.NAME" label="设备名称"></el-table-column>
                    <el-table-column prop="BZ" label="说明"></el-table-column>
                    <el-table-column prop="CREATETIME" label="创建日期"></el-table-column>
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                            <el-button @click="delCheckItem(scope.row)"  type="text" size="small" style="color:#F56C6C">解绑</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndexItem" :page-size="PageSizeItem" :total="TotalItem" class="pg pgItem" @current-change="PGChangeItem"></el-pagination>
        </el-dialog>

        <el-dialog title="绑定检查项" :visible.sync="checkItemSetModal" width="800px" top="22px">
            <div >
                <el-input v-model="code" placeholder="请输入编号" class="ics" ></el-input>
                <el-select v-model="gw" placeholder="点击选择岗位查询"  clearable filterable  class="ics">
                        <el-option v-for="item in gwList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                </el-select>
                <el-select v-model="sblx" placeholder="选择设备类型查询"  filterable  clearable class="ics" @change="sblxChange">
                        <el-option v-for="item in mhTypeList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                </el-select>
                <el-select v-model="sbid" placeholder="点击选择设备"  filterable clearable class="ics">
                            <el-option v-for="item in sblb" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                    </el-select>

                <el-button type="primary" @click="getTempletItems" style="float:right">查询</el-button>
            </div>
            <div class="templetItemListBox" v-if="templetItemList.length>0"  :style="{width:'100%',height:dialogMaxHeigh -50+ 'px'}">
                <div v-for="item in templetItemList" :key="item.SNOWID" >
                    <div v-if="item.MACHINERESPONSE" class="templetItemList" :class="item.ON=='1'?'itemOn':''"  @click="checkTempletItem(item.SNOWID)">
                        <p class="code" :title="item.CODE">编号:{{item.CODE}}</p>
                        <p class="sb" :title="item.MACHINERESPONSE.NAME">设备:{{item.MACHINERESPONSE.NAME}}</p>
                        <p class="sb" :title="item.POSITIONNAME">岗位:{{item.POSITIONNAME}}</p>
                        <p class="bz" :title="item.BZ">描述:{{item.BZ}}</p>
                    </div>
                </div>
                
            </div>
            <div class="templetItemListBoxNUll" v-else>
                暂未查询到检查项
            </div>
            <div>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndexQuery" :page-size="PageSizeQuery" :total="TotalQuery" class="pg pgItem" @current-change="PGChangeQuery" style="display:block;width:100%;float:left;"></el-pagination>
                <el-button type="primary" @click="submitCheckItemForm()">提交</el-button>
                <el-button @click="checkItemSetModal = false">取消</el-button>
                <el-button type="primary" @click="submitCheckAllForm()" style="float:right">全部绑定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
var h=document.body.clientHeight
export default {
  name: "CheckTemplet",
  data() {
    var NAME = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入名称"));
      } else {
        callback();
      }
    };
    return {
        dialogMaxHeigh:0,

        canDo:true,
        name:"",
        templetList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,

        

        doTypeStr:"",
        doType:"",
        templetModal:false,
        templetInfo:{
            NAME:"",
        },
        postrules: {
            NAME: [{ validator: NAME, trigger: "blur" }],
        },

        //检查项
        mhTypeList:[],
        gwList:[],
        gw:"",
        code:"",
        sblx:"",
        sblb:[],
        sbid:"",
        templetItemList:[],

        PageIndexItem:1,
        PageSizeItem:8,
        TotalItem:0,

        nowTempletId:"",
        checkItemList:[],
        checkItemListTitle:"",
        checkItemModal:false,

        delIds:[],
        checkItemSetModal:false,


        PageIndexQuery:1,
        PageSizeQuery:100,
        TotalQuery:0,
    };
  },
  mounted() {
    //console.log(this.$store.state.checkType)
    this.loadItem=null
    this.getTempletList()
    this.getMachineTypeList()
    this.getGWList()

    this.dialogMaxHeigh = h -300
  },
  methods: {

    getGWList(){
        var params={
            PAGEINDEX:1,
            PAGESIZE:10000,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("PositonManage/GetPositionListWithoutPage", md5_data)
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.gwList=response.data.DATA
                } 
            })
    },

    getTempletList(){
        this.showLoading("请稍后")
        var params={
            PAGEINDEX:this.PageIndex,
            PAGESIZE:this.PageSize,
            NAME:this.name,
            TYPE:this.$store.state.checkType,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("CheckTemplate/GetCheckTemplateList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.templetList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.templetList=[]
                this.Total=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.templetList=[]
                this.Total=0
        });
    },
    searchTemplet(){
        this.PageIndex=1
        this.getTempletList()
    },
    addTemplet(){
        this.templetInfo={
            NAME:"",
            TYPE:this.$store.state.checkType,
        }
        this.doTypeStr="添加检查模板"
        this.doType="1"
        this.templetModal=true
    },
    sendAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.templetInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.templetInfo)).toUpperCase())
        this.$http.post("CheckTemplate/CreateNewCheckTemplate", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.templetModal=false
                    this.getTempletList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    editTemplet(item){
        this.templetInfo={
            SNOWID:item.SNOWID,
            NAME:item.NAME,
            TYPE:this.$store.state.checkType,
        }
        this.doTypeStr="修改检查模板"
        this.doType="2"
        this.templetModal=true
    },
    sendEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.templetInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.templetInfo)).toUpperCase())
        this.$http.post("CheckTemplate/EditCheckTemplate", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.templetModal=false
                    this.$message.success("已修改")
                    this.getTempletList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    delTemplet(item){
        if(this.canDo){
            this.$confirm('确定删除检查模板：'+item.NAME+'？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
            this.sendDel(item.SNOWID)
            }).catch(() => {
            this.$message.info("已取消")    
          });
        }

    },
    sendDel(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({SNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:id})).toUpperCase())
        this.$http.post("CheckTemplate/DeleteCheckTemplate", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.templetModal=false
                    this.getTempletList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    PGChange(e){
        this.PageIndex=e
        this.getTempletList()
    },
    submitTempletForm() {
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    if(this.doType=="1"){
                        this.sendAdd()
                    }else if(this.doType=="2"){
                        this.sendEdit()
                    }
                } else {
                    return false;
                }
            });
        }
    },

    //模板项
    //获取设备类型列表
    getMachineTypeList(){
        var params={
            PAGEINDEX:1,
            PAGESIZE:10000,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("MachineType/GetMachineTypeList", md5_data)
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.mhTypeList=response.data.DATA
                    // if(this.mhTypeList.length>0){
                    //     this.sblx=this.mhTypeList[0].SNOWID
                    //     this.getSBByLx(this.sblx)
                    // }
                } 
            })
    },
    sblxChange(item){
        this.sbid=""
        this.getSBByLx(item)
    },

    //获取设备
    getSBByLx(id){
        var params={
            MACHINETYPEID:[id],
            PAGEINDEX:1,
            PAGESIZE:10000,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Machine/GetMachine", md5_data)
            .then((response) => {
                // console.log("获取风场设备")
                // console.log(response)
                this.sblb=response.data.DATA 

            })
    },



    //打开检查项列表窗口
    showCheckItem(item){
        if (this.canDo) {
            this.nowTempletId=item.SNOWID
            this.checkItemListTitle=item.NAME+"-检查模板"
            this.checkItemList=[]
            this.canDo=false
            this.checkItemModal=true
            this.PageIndexItem=1
            this.getCheckItem()
        }
    },
    //获取检查项
    getCheckItem(id){
        this.showLoading("请稍后")
        var params={
            CHECKTEMPLATEID:this.nowTempletId,
            PAGEINDEX:this.PageIndexItem,
            PAGESIZE:this.PageSizeItem,
            TYPE:this.$store.state.checkType,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("CheckItem/GetCheckItemListByTempID", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    var list=response.data.DATA
                    this.checkItemList=list
                    this.TotalItem= response.data.TOTALCOUNT
                } else{
                    this.checkItemList=[]
                    this.TotalItem= 0
                }
            })
            .catch((error) => {
                this.checkItemList=[]
                this.TotalItem= 0
                this.hideLoading()
                this.canDo=true   
             });
    },
    PGChangeItem(e){
        this.PageIndexItem=e
        this.getCheckItem()
    },
    //打开添加绑定检查项
    addCheckItem(){
        this.templetItemList=[]
        this.checkItemSetModal=true
        this.PageIndexQuery=1
        this.getTempletItems()
    },  
    //获取检查项
    getTempletItems(){
        this.showLoading("请稍后")
        // 解决loading遮罩层在dialog之前显示导致dialog层的z-index大于loading遮罩层的bug
        this.$nextTick ( () => {
            let loadingNode = document.getElementsByClassName ('el-loading-mask')
            for (let i = 0; i < loadingNode.length; i++) {
            loadingNode [i].style.zIndex = 999999 // 设置一个较大的值
            }
        })
        var params={
            PAGEINDEX:this.PageIndexQuery,
            PAGESIZE:this.PageSizeQuery,
            CODE:this.code,
            MACHINEID:this.sbid,
            POSITIONID:this.gw,
            MACHINETYPEID:this.sblx,
            TYPE:this.$store.state.checkType,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("CheckItem/GetCheckItemList", md5_data)
            .then((response) => {
            this.hideLoading()
                if (response.data.SUCCESS) {
                    var list=response.data.DATA
                    var nl=[]
                    for(var i=0;i<list.length;i++){
                        var d=list[i]
                        var has=this.flagIsBind(d.SNOWID)
                        if(!has){
                            d.ON="0"
                            nl.push(d)
                        }
                    }
                    this.templetItemList=nl
                    this.TotalQuery= response.data.TOTALCOUNT
                }else{
                    this.TotalQuery= 0
                }
            })
    },
    PGChangeQuery(e){
        this.PageIndexQuery=e
        this.getTempletItems()
    },
    flagIsBind(id){
        var has=false
        var list =this.checkItemList
        for(var i=0;i<list.length;i++){
            if(list[i].SNOWID == id){
                has=true
                break;
            }
        }
        return has
    },
    //选择检查项
    checkTempletItem(id){
        
        var list = this.templetItemList
        // console.log(id)
        // console.log(list)
        var nl=[]
        for(var i=0;i<list.length;i++){
            var d=list[i]
            if(d.SNOWID== id){
                if(d.ON=="1"){
                    d.ON="0"
                }else{
                    d.ON="1"
                }
            }
            nl.push(d)
        }
        this.templetItemList =nl
    },

    submitCheckItemForm(){
        if (this.canDo) {
            var ids=[]
            for(var i=0;i<this.templetItemList.length;i++){
                if(this.templetItemList[i].ON=="1"){
                    ids.push(this.templetItemList[i].SNOWID)
                }
            }
            if(ids.length==0){
               this.$message.error("请至少选择一个模板项")
            }else{
                this.sendAddCheckItem(ids)
            }
        }
    },
    submitCheckAllForm(){
        if (this.canDo) {
            if(this.templetItemList.length==0){
               this.$message.error("当前没有可绑定的检查项")
            }else{
                this.$confirm('确定绑定全部'+this.templetItemList.length+'个检查项？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                    }).then(() => {
                        var ids=[]
                        for(var i=0;i<this.templetItemList.length;i++){
                            ids.push(this.templetItemList[i].SNOWID)
                        }
                        this.sendAddCheckItem(ids)
                    }).catch(() => {
            this.$message.info("已取消")    
          });
            }
        }
    },

    sendAddCheckItem(ids){
        this.canDo=false
        this.showLoading("请稍后")
        var params={
            SNOWID:this.nowTempletId,
            CHECKITEMLIST:ids,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("CheckTemplate/BindCheciItem", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已绑定")
                    this.checkItemSetModal=false
                   this.getCheckItem(this.nowTempletId)
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    delCheckItem(item){
        if(this.canDo){
            this.$confirm('确定解绑检查项？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
                 this.sendDelCheckItem(item.UNIONID)
            }).catch(() => {
            this.$message.info("已取消")    
          });
        }
    },
    sendDelCheckItem(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({SNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:id})).toUpperCase())
        this.$http.post("CheckTemplate/DelBindCheciItem", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已解绑")
                   this.getCheckItem(this.nowTempletId)
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    handleSelectionChange(e){
        this.delIds=[]
        for(var i=0;i<e.length;i++){
            this.delIds.push(e[i].SNOWID)
        }
    },
    delCheckItems(){
        if(this.canDo){
            if(this.delIds.length==0){
                this.$message.error("请个选择检查项")
            }else{
                this.$confirm('确定解绑选中的检查项？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                    this.sendDelCheckItems()
                }).catch(() => {
            this.$message.info("已取消")    
          });
            }
   
        }
    },
    sendDelCheckItems(){
        this.canDo=false
        this.showLoading("请稍后")
        var params={
            SNOWID:this.nowTempletId,
            CHECKITEMLIST:this.delIds,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("CheckTemplate/UnBindCheciItem", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已解绑")
                    this.checkItemSetModal=false
                    this.getCheckItem(this.nowTempletId)
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    backXJGL(){
        this.$router.replace('/XJGL')
    },
    downloadTemplet(){
         window.open("file/检查模板.xlsx")
    },
    customRequest(data){
        this.showLoading("请稍后...")
        var formdata = new FormData();
        formdata.append('file', data.file);
        this.$upload.post("CheckTemplate/ExportData?TYPE="+this.$store.state.checkType, formdata)
        .then(resJson => {
                this.hideLoading()
                if (resJson.data.SUCCESS) {
                        this.$message.success("上传成功")
                        this.getTempletList()
                    } else {
                        this.$message.error("上传失败")
                    }
                })
                .catch((error) => {
                    this.$message.error("上传失败")
                    this.hideLoading()
                });
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style scoped>
    .templetItemListBox{overflow: auto;margin: 20px 0;}
    .templetItemListBox .templetItemList{overflow: hidden;float:left;cursor:pointer;background-color: #ADADAD;color: #FFFFFF;border:1px solid #ADADAD;font-size: 14px;margin-right: 10px;margin-bottom: 10px;border-radius: 3px;width: 235px;padding: 5px 0;}
    .templetItemListBox .templetItemList p{white-space: nowrap;text-overflow: ellipsis;margin: 0 8px;overflow: hidden;}
    .templetItemListBox .templetItemList:hover{background-color: #409EFF!important;border:1px solid #409EFF!important;}
    .templetItemListBox  .itemOn{background-color: #409EFF!important;border:1px solid #409EFF!important;}

    .templetItemListBoxNUll{text-align: center;font-size: 14px;color: #666;margin: 50px 0;}

    .el-dialog{overflow: hidden;}
    .pgItem{margin-bottom: 20px;}
</style>