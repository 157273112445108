<template>
    <div class="views-main">
        <div class="views-main-title">
            <el-button type="primary" icon="el-icon-arrow-left" style="float:left;margin-right:20px;" size="small" @click="backHZTZ">返回</el-button>
            <p class="txt">考核记录</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                
                <el-select v-model="khr" placeholder="考核人姓名"  filterable  clearable class="iwu">
                        <el-option v-for="item in userList" :key="item.SNOWID" :label="item.TRUENAME+'('+item.LOGINNAME+')'" :value="item.SNOWID" ></el-option>
                </el-select>
                <el-select v-model="bkhr" placeholder="被考核人姓名"  filterable  clearable class="iwu">
                        <el-option v-for="item in userList" :key="item.SNOWID" :label="item.TRUENAME+'('+item.LOGINNAME+')'" :value="item.SNOWID" ></el-option>
                </el-select>
                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-button type="primary" class="fr" @click="sckhsp">生产考核申请</el-button>
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column prop="FROMUSERNAME" label="考核人姓名"></el-table-column>
                    <el-table-column prop="TOUSERNAME" label="被考核人姓名"></el-table-column>
                    <el-table-column prop="APPLYTIME" label="申请时间"></el-table-column>
                    <el-table-column prop="KPITIME" label="考核时间"></el-table-column>
                    <el-table-column prop="APPROVEDTIME" label="审批时间"></el-table-column>
                    <el-table-column prop="COMPLETEDPERCENT" label="完成率"></el-table-column>
                    <el-table-column prop="OKPERCENT" label="达标率"></el-table-column>
                    <el-table-column prop="BZ" label="评价"></el-table-column>
                    <el-table-column prop="CREATETIME" label="创建日期"></el-table-column>
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                            <el-button @click="task(scope.row)" type="text" size="small" style="color:#E6A23C">全年工作明细</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog title="全年工作明细" :visible.sync="taskModal" width="1200px" top="50px" :height="dialogMaxHeigh">
               <QNGZMX :userid="taskUserId"></QNGZMX>
        </el-dialog>
    </div>
</template>
<script>
var h=document.body.clientHeight
import QNGZMX from "../../components/QNGZMX"
export default {
  name: "HKLB",
  components:{
    QNGZMX,//全年工作明细
  },
  data() {
    return {
        canDo:true,
        userList:[],
        khr:"",
        bkhr:"",
       

        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,

        taskUserId:"",


        taskModal:false,
        dialogMaxHeigh:0,
    };
  },
  mounted() {
    this.loadItem=null
    this.getPostList()
    this.getUserList()
    this.dialogMaxHeigh = h -300
  },
  methods: {
    getUserList(){
        var params={
            PAGEINDEX:1,
            PAGESIZE:1000,
            TRUENAME:"",
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("UserManage/GetUserList", md5_data)
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.userList=response.data.DATA
                } 
            })
    },
    getPostList(){
        this.showLoading("请稍后")
        var params={
            PAGEINDEX:this.PageIndex,
            PAGESIZE:this.PageSize,
            FROMUSERID:this.khr,
            TOUSERID:this.bkhr,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("kpi/GetKpiList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
        });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    PGChange(e){
        this.PageIndex=e;
        this.getPostList()
    },
    task(item){
        this.taskUserId=item.USERID
        this.taskModal=true
    },
    backHZTZ(){
        this.$router.replace('/XTPTZH')
    },
    sckhsp(){
        this.showLoading("请稍后")
        var params={
            PAGEINDEX:1,
            PAGESIZE:1,
            NAME:"",
            ALIAS:"PRODUCTION",
            VISIBILITY:true,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Model/GetModelList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS && response.data.DATA.length>0) {
                var d=response.data.DATA[0]
                window.open("/statics/pages/LCQC.html?mid="+d.SNOWID+"&token="+this.$store.state.token)
            } else {
                this.$message.error("请先配置生产考核申请流程")
            }
            })
            .catch((error) => {
                this.$message.error("请求失败，请稍后再试")
                this.hideLoading()
            }); 
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style >
</style>