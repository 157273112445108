<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">考试分类</p>
        </div>
        <div class="tableBox" style="top:50px;">
                <div class="ksItem" @click="ksDetail('a')">
                    <p class="t">安规考试</p>
                    <p class="n" v-if="mainList.a">
                        状态：{{mainList.a.STR}}　<span v-if="mainList.a.STATUS==2">得分：{{mainList.a.SCORE}}</span>
                    </p>
                    <p class="n" v-else> 
                        您不在本次考试安排中
                    </p>
                </div>
                <div class="ksItem" @click="ksDetail('b')">
                   <p class="t">"四种人"考试</p>
                    <p class="n" v-if="mainList.b">
                        状态：{{mainList.b.STR}}　<span v-if="mainList.b.STATUS==2">得分：{{mainList.b.SCORE}}</span>
                    </p>
                    <p class="n" v-else> 
                        您不在本次考试安排中
                    </p>
                </div>
                <div class="ksItem" @click="ksDetail('c')">
                    <p class="t">生产人员日常培训考试</p>
                    <p class="n" v-if="mainList.c">
                        状态：{{mainList.c.STR}}　<span v-if="mainList.c.STATUS==2">得分：{{mainList.c.SCORE}}</span>
                    </p>
                    <p class="n" v-else> 
                        您不在本次考试安排中
                    </p>
                </div>
                <div class="ksItem" @click="ksDetail('d')">
                    <p  class="t">配件供应商维斯塔斯备品备件考试</p>
                    <p class="n" v-if="mainList.d">
                        状态：{{mainList.d.STR}}　<span v-if="mainList.d.STATUS==2">得分：{{mainList.d.SCORE}}</span>
                    </p>
                    <p class="n" v-else> 
                        您不在本次考试安排中
                    </p>
                </div>
        </div>
    </div>
</template>
<script>
export default {
  name: "KSFL",
  data() {

    return {
        postList:[],
        canDo:true,
        mainList:{},
        
       

        
    };
  },
  mounted() {
    this.loadItem=null
    this.getPostList()
  },
  methods: {
   getPostList(){
        this.showLoading("请稍后")
        var params={
            PAGEINDEX:1,
            PAGESIZE:10,
            NAME:"",
            QuestionGroupID:"",
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Examination/GetExaminationList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.getMainList()
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
            });
    },
    getMainList(){
        var params={
            PAGEINDEX:1,
            PAGESIZE:10,
            ExaminationName:"",
            USERID:this.$store.state.guid,
            UserName:"",
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("RelationOfUserAndExamination/GetRelationOfUserAndExaminationList ", md5_data)
            .then((response) => {
                if (response.data.SUCCESS) {
                    var l=response.data.DATA
                    var m={
                        a:null,
                        b:null,
                        c:null,
                        d:null,
                    }
                    for(var i=0;i<l.length;i++){
                        var d=l[i]
                        d.STR=""
                        if(d.STATUS=="0"){
                            d.STR="未答卷"
                        }else if(d.STATUS=="1"){
                            d.STR="已交卷"
                        }else if(d.STATUS=="2"){
                            d.STR="已批阅"
                        }
                        if(d.T_EXAMINATIONNAME=='安规考试' && m.a == null){
                            m.a=d
                        }else if(d.T_EXAMINATIONNAME=='“四种人”考试' && m.b == null){
                            m.b=d
                        }else if(d.T_EXAMINATIONNAME=='生产人员日常培训考试' && m.c == null){
                            m.c=d
                        }else if(d.T_EXAMINATIONNAME=='配件供应商维斯塔斯备品备件考试' && m.d == null){
                            m.d=d
                        }
                    }
                    this.mainList=m
                    //console.log(this.mainList)
                } 
            })

    },

    ksDetail(i){
        if(this.mainList[i]){
            window.open("/statics/pages/KSDT.html?token="+this.$store.state.token+"&kid="+this.mainList[i].EXAMINATIONID)
        }else{
            this.$message.error("您不在本次考试安排中！")
        }
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style scoped>
    .el-collapse-item__header{padding: 0 20px;font-size: 16px;}
    .el-collapse .is-active{color: #409EFF;}
    .el-collapse-item__content{padding-left:20px;}

    .ksItem{width: 320px;height: 100px;border-radius: 3px;cursor: pointer;color: #FFFFFF;background-color: #409EFF;font-size: 16px;margin-left: 30px;margin-top: 30px;float:left;}
    .ksItem .t{margin-top: 24px;text-align: center;font-weight: bold;}
    .ksItem .n{margin-top: 10px;text-align: center;font-size: 15px;}

    .ksItem:hover{background-color: #5FADFC;}

</style>