<template>
  <div class="content" style="width: 100%;height:100%;">
         <el-table :data="taskList" stripe style="width: 100%;height:100%;">
                    <el-table-column prop="TASKNAME" label="任务名称"></el-table-column>
                    <el-table-column prop="STATUS" label="检查状态">
                        <template slot-scope="scope">
                            <span v-if="scope.row.STATUS=='1'">已执行</span>
                            <span v-else>未执行</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="CHECKUSERNAME" label="检查人"></el-table-column>
                    <el-table-column prop="MACHINENAME" label="被检查设备"></el-table-column>
                    <el-table-column prop="RESULT" label="检查结果"></el-table-column>
                    <el-table-column prop="STARTTIME" label="开始时间"></el-table-column>
                    <el-table-column prop="ENDTIME" label="结束时间"></el-table-column>
                    <el-table-column prop="BZ" label="说明"></el-table-column>
                    <el-table-column prop="CREATETIME" label="创建日期"></el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndexT" :page-size="PageSizeT" :total="TotalT" class="pg pgItem" @current-change="PGChangeT"></el-pagination>
  </div>
</template>
<style scoped> 
.rowItem{width: 50%;float:left;overflow: hidden;font-size: 15px;}
</style>
<script>
var h=document.body.clientHeight
export default {
  name: 'MRFDL',
  props: {
    userid: {
      type:String,
      default:""
    },
  },
  data () {
    
      return {
        taskList:[],
        PageIndexT:1,
        PageSizeT:10,
        TotalT:0,

        dialogMaxHeigh:0,
      }
  },
  mounted() {
      this.dialogMaxHeigh = h -300
      //console.log(this.dialogMaxHeigh)
      this.loadItem=null
      //console.log(this.userid)
      this.getTaskList()
  },
  methods:{
    
    getTaskList(){
        this.showLoading("请稍后")
        var params={
            PAGEINDEX:this.PageIndexT,
            PAGESIZE:this.PageSizeT,
            SNOWID:this.userid,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("process/GetUserTask", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.taskList=response.data.DATA
                this.TotalT=response.data.TOTALCOUNT
            } else {
                this.taskList=[]
                this.TotalT=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.taskList=[]
                this.TotalT=0
        });
    },
    PGChangeT(e){
        this.PageIndexT=e
        this.getTaskList()
    },
    
  }
}
</script>

