<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">题集管理</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                
                <el-input v-model="name" placeholder="请输入名称" class="iw" ></el-input>
                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-button type="success" @click="addPost">添加</el-button>
                <el-button type="warning" @click="showModelType" class="fr">考题分类</el-button>
                <el-button type="primary" @click="showModelQuestion" class="fr">题库</el-button>
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column prop="NAME" label="名称"></el-table-column>
                    <el-table-column prop="CREATETIME" label="创建日期"></el-table-column>
                    <el-table-column label="操作" width="120">
                        <template slot-scope="scope">
                        <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button>
                        <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
                        <el-button @click="showPoll(scope.row)"  type="text" size="small" style="color:#409EFF">考题</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="500px">
            <el-form :model="postInfo" status-icon :rules="postrules"  ref="uForm" label-width="100px" class="demo-uForm">
                <el-form-item label="名称" prop="NAME">
                <el-input v-model="postInfo.NAME" autocomplete="off"></el-input>
                </el-form-item>
            
                <el-form-item>
                <el-button type="primary" @click="submitPostForm()">提交</el-button>
                <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>

        <el-dialog title="考题分类管理" :visible.sync="typeModal" width="1050px" top="50px">
                <div style="width:100%;overflow:hidden;margin-bottom:20px;">
                    <el-input v-model="typename" placeholder="请输入名称" class="ics" ></el-input>
                    <el-button type="primary" @click="searchType">查询</el-button>
                    <el-button type="success" @click="addType" class="fr">添加分类</el-button>
                </div>
                <el-table :data="typeList" stripe style="width: 100%"  :height="dialogMaxHeigh"> 
                    <el-table-column prop="NAME" label="名称"></el-table-column>
                    <el-table-column prop="CREATETIME" label="创建日期"></el-table-column>
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                        <el-button @click="editType(scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button>
                        <el-button @click="delType(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="prev, pager, next"  :current-page="PageIndexType" :page-size="PageSizeType" :total="TotalType" class="pg pgItem" @current-change="PGChangeType"></el-pagination>
        </el-dialog>


       <el-dialog title="题库" :visible.sync="qstModal" width="1200px" top="50px">
                <div style="width:100%;overflow:hidden;margin-bottom:20px;">
                    <el-input v-model="qstname" placeholder="请输入名称" class="ics" ></el-input>
                    <el-select v-model="qsttype" placeholder="请选择分类" filterable  class="ics" >
                            <el-option label="全部分类" value="" ></el-option>
                            <el-option v-for="item in qstTypeList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                    <el-button type="primary" @click="searchQst">查询</el-button>
                    <el-button type="success" @click="addQst" class="fr">添加考题</el-button>
                </div>
                <el-table :data="qstList" stripe style="width: 100%"  :height="dialogMaxHeigh"> 
                    <el-table-column prop="T_QUESTIONTYPENAME" label="分类"></el-table-column>
                    <el-table-column prop="CONTENT" label="题目内容" width="300"></el-table-column>
                    <el-table-column prop="RESULT" label="题目答案"></el-table-column>
                    <el-table-column prop="TYPE" label="类型">
                        <template slot-scope="scope">
                            <span v-if="scope.row.TYPE=='1'">单选</span>
                            <span v-else-if="scope.row.TYPE=='2'">多选</span>
                            <span v-else-if="scope.row.TYPE=='3'">判断</span>
                            <span v-else-if="scope.row.TYPE=='4'">简答</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="A" label="选项A"></el-table-column>
                    <el-table-column prop="B" label="选项B"></el-table-column>
                    <el-table-column prop="C" label="选项C"></el-table-column>
                    <el-table-column prop="D" label="选项D"></el-table-column>
                    <el-table-column prop="Y" label="选项Y"></el-table-column>
                    <el-table-column prop="N" label="选项N"></el-table-column>
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                        <el-button @click="editQst(scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button>
                        <el-button @click="delQst(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="prev, pager, next"  :current-page="PageIndexQst" :page-size="PageSizeQst" :total="TotalQst" class="pg pgItem" @current-change="PGChangeQst"></el-pagination>
        </el-dialog>
        <el-dialog :title="doTypeStr" :visible.sync="qstAddModal" width="800px">
            <el-form :model="qstInfo" status-icon :rules="qstrules"  ref="qForm" label-width="100px" class="demo-uForm">
                <el-form-item label="题目分类" prop="QUESTIONTYPEID">
                    <el-select v-model="qstInfo.QUESTIONTYPEID" placeholder="请选择分类" filterable  style="width:100%;">
                            <el-option v-for="item in qstTypeList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="题目内容" prop="CONTENT">
                    <el-input v-model="qstInfo.CONTENT" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="题目答案" prop="RESULT">
                    <el-input v-model="qstInfo.RESULT" autocomplete="off" placeholder="支持多答案,多答案请按循序填写(如：ACD)"></el-input>
                </el-form-item>
                <el-form-item label="题目类型">
                    <el-select v-model="qstInfo.TYPE" placeholder="请选择题目类型" filterable  style="width:100%;" >
                            <el-option  label="单选(答案必填、选项ABCD必填)" value="1" ></el-option>
                            <el-option  label="多选(答案必填、选项ABCD必填)" value="2" ></el-option>
                            <el-option  label="判断题(答案必填、选项YN必填)" value="3" ></el-option>
                            <el-option  label="简答题" value="4" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="A选项内容" prop="A">
                    <el-input v-model="qstInfo.A" autocomplete="off"></el-input>
                </el-form-item>   
                <el-form-item label="B选项内容" prop="B">
                    <el-input v-model="qstInfo.B" autocomplete="off"></el-input>
                </el-form-item>   
                <el-form-item label="C选项内容" prop="C">
                    <el-input v-model="qstInfo.C" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="D选项内容" prop="D">
                    <el-input v-model="qstInfo.D" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="Y选项内容" prop="Y">
                    <el-input v-model="qstInfo.Y" autocomplete="off" placeholder="如：正确、同意、可以等"></el-input>
                </el-form-item> 
                <el-form-item label="N选项内容" prop="N">
                    <el-input v-model="qstInfo.N" autocomplete="off" placeholder="如：错误、不同意、不可以等"></el-input>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" @click="submitQstForm()">提交</el-button>
                    <el-button @click="qstAddModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>


        <el-dialog :title="collItemModalTitle" :visible.sync="coolModal" width="1200px" top="50px" class="taskItemBox">
            <div class="left"  :style="{height:coolLeftHeight+ 'px'}">
                <div class="taskItemBoxt">题库</div>
                <div class="list">
                    <el-input v-model="qstnameP" placeholder="请输入名称" class="ics ml10" ></el-input>
                    <el-select v-model="qsttypeP" placeholder="请选择分类" filterable  class="ics" >
                            <el-option label="全部分类" value="" ></el-option>
                            <el-option v-for="item in qstTypeList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                    <el-button type="primary" @click="searchQstP">查询</el-button>

                    <div class="ilist">
                        <div class="templeItem" v-for="item in qstList" :key="item.SNOWID" @click="appendPoolItem(item.SNOWID)">
                            {{item.CONTENT}}
                        </div>
                    </div>   
                    <div class="listMsg">点击题目加入题集</div>
                </div>
            </div>
            <div class="right" :style="{height:coolLeftHeight+ 'px'}">
                <div class="taskItemBoxt">
                    现有考题列表
                </div>       
                <div class="ilist">   
                    <el-form  :model="collItemObj" status-icon   ref="coolItemForm" label-width="80px" class="tastItemForm">
                        <div style="overflow:hidden;margin-top:20px;"  v-for="(cool, index) in collItemObj.coolItemList" :key="cool.index">
                            <div style="overflow:hidden;border-bottom:1px solid #ADADAD;">
                                <el-form-item label="考题："  class="allItem">
                                   <p style="overflow:hidden;white-space: nowrap;text-overflow: ellipsis;">{{cool.CONTENT}}</p>
                                </el-form-item>
                                <el-form-item label="分数："  :prop="'coolItemList.' + index + '.SCORE'" :rules="{ required: true, message: '分数不能为空', trigger: 'blur'}" class="allItem">
                                    <el-input  v-model="cool.SCORE" autocomplete="off" class="ics"></el-input>
                                    <el-button type="text" style="color:#F56C6C;float:right;" class="mr10" @click="delPollItem(index)">删除</el-button>
                                    <el-button type="text" style="color:#409EFF;float:right;margin-left:0;" class="mr10" @click="prevPollItem(index)" v-if="index>0">上移</el-button>
                                    <el-button type="text" style="color:#E6A23C;float:right;" class="mr10" @click="topPollItem(index)" v-if="index>0">置顶</el-button>
                                </el-form-item>
                            </div>
                            
                        </div>    
                    </el-form>
                    <div class="bottomBtns">
                        <el-button @click="coolModal = false" style="margin-top:5px;float:right;">关闭</el-button>
                        <el-button type="primary" @click="submitCheckPollItemForm()" style="margin-top:5px;float:right;margin-right:12px;">保存</el-button>   
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
var h=document.body.clientHeight
export default {
  name: "TJGL",
  data() {
    var NAME = (rule, value, callback) => {
        // console.log(rule)
        // console.log(value)
        // console.log(callback)
      if (value === "") {
        callback(new Error("请输入名称"));
      } else {
        callback();
      }
    };

    var CONTENT = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入题目内容"));
      } else {
        callback();
      }
    };
    var RESULT = (rule, value, callback) => {
      if (this.qstInfo.TYPE !="4" && value === "") {
        callback(new Error("请输入题目答案"));
      } else {
        callback();
      }
    };
    var QUESTIONTYPEID = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择题目类型"));
      } else {
        callback();
      }
    };
    var A = (rule, value, callback) => {
      if (this.qstInfo.TYPE =="1" && value === "" || this.qstInfo.TYPE =="2" && value === "") {
        callback(new Error("请输入选项A"));
      } else {
        callback();
      }
    };
    var B = (rule, value, callback) => {
      if (this.qstInfo.TYPE =="1" && value === "" || this.qstInfo.TYPE =="2" && value === "") {
        callback(new Error("请输入选项B"));
      } else {
        callback();
      }
    };
    var C = (rule, value, callback) => {
      if (this.qstInfo.TYPE =="1" && value === "" || this.qstInfo.TYPE =="2" && value === "") {
        callback(new Error("请输入选项C"));
      } else {
        callback();
      }
    };
    var D = (rule, value, callback) => {
      if (this.qstInfo.TYPE =="1" && value === "" || this.qstInfo.TYPE =="2" && value === "") {
        callback(new Error("请输入选项D"));
      } else {
        callback();
      }
    };
    var Y = (rule, value, callback) => {
      if (this.qstInfo.TYPE =="3" && value === "") {
        callback(new Error("请输入选项Y"));
      } else {
        callback();
      }
    };
    var N = (rule, value, callback) => {
      if (this.qstInfo.TYPE =="3" && value === "") {
        callback(new Error("请输入选项N"));
      } else {
        callback();
      }
    };
    return {
        canDo:true,
        
        doModal:"1",

        //题集
        name:"",
        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,


        doTypeStr:"",
        doType:"",
        postModal:false,
        postInfo:{
            NAME:"",
        },
        postrules: {
            NAME: [{ validator: NAME, trigger: "blur" }],
        },

        coolLeftHeight:0,
        collItemModalTitle:"",
        coolModal:false,
        baseColl:null,
        qstnameP:"",
        qsttypeP:"",

       collItemObj:{
            coolItemList:[],
        },

        //类型
        dialogMaxHeigh:0,
        typeModal:false,

        typename:"",
        typeList:[],
        PageIndexType:1,
        PageSizeType:10,
        TotalType:0,

        //考题
        qstTypeList:[],
        qstModal:false,
        qstname:"",
        qsttype:"",
        qsttypet:"",
        qstList:[],
        PageIndexQst:1,
        PageSizeQst:10,
        TotalQst:0,

        doTypeQst:"",

        qstAddModal:false,
        qstInfo:{
            NAME:"",
        },
        qstrules: {
            CONTENT: [{ validator: CONTENT, trigger: "blur" }],
            RESULT: [{ validator: RESULT, trigger: "blur" }],
            QUESTIONTYPEID: [{ validator: QUESTIONTYPEID, trigger: "blur" }],
            A: [{ validator: A, trigger: "blur" }],
            B: [{ validator: B, trigger: "blur" }],
            C: [{ validator: C, trigger: "blur" }],
            D: [{ validator: D, trigger: "blur" }],
            Y: [{ validator: Y, trigger: "blur" }],
            N: [{ validator: N, trigger: "blur" }],
        },

    };
  },
  mounted() {
    this.dialogMaxHeigh = h -300
    this.coolLeftHeight = h-200
    this.loadItem=null
    this.getPostList()
    this.getQstTypeList()
  },
  methods: {
    /**
     * 题集
     */
    getPostList(){
        this.showLoading("请稍后")
        var params={
            PAGEINDEX:this.PageIndex,
            PAGESIZE:this.PageSize,
            NAME:this.name,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("QuestionCollection/GetCollectionList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
        });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    addPost(){
        this.postInfo={
            NAME:"",
        }
        this.doTypeStr="添加题集"
        this.doType="1"
        this.doModal="1"
        this.postModal=true
    },
    sendAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("QuestionCollection/CreateCollection", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    editPost(item){
        this.postInfo={
            SNOWID:item.SNOWID,
            NAME:item.NAME,
        }
        this.doTypeStr="修改题集"
        this.doType="2"
        this.doModal="1"
        this.postModal=true
    },
    sendEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("QuestionCollection/EditCollection", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.postModal=false
                   this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    delPost(item){
        if(this.canDo){
            this.$confirm('确定删除题集：'+item.NAME+'？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
            this.sendDel(item.SNOWID)
            })
        }

    },
    sendDel(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({SNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:id})).toUpperCase())
        this.$http.post("QuestionCollection/DeleteCollection", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    submitPostForm() {
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    if(this.doModal=="1"){
                        if(this.doType=="1"){
                            this.sendAdd()
                        }else if(this.doType=="2"){
                            this.sendEdit()
                        }
                    }else if(this.doModal=="2"){
                        if(this.doType=="1"){
                            this.sendAddType()
                        }else if(this.doType=="2"){
                            this.sendEditType()
                        }
                    }
                    
                } else {
                    return false;
                }
            });
        }
    },


    showPoll(e){
        this.collItemModalTitle=e.NAME+"-题目"
        this.baseColl=e
        this.getQstTypeList()
        this.getPoolArray()
        this.getCollList()
        this.coolModal=true
    },
    searchQstP(){
        this.getPoolArray()
    },
    getPoolArray(){
        var params={
            PAGEINDEX:1,
            PAGESIZE:500,
            CONTENT:this.qstnameP,
            QuestionTypeID:this.qsttypeP,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("QusetionPool/GetQusetionPoolList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.qstList=response.data.DATA
            } 
            
        })
    },
    getCollList(){
        var params={
            PAGEINDEX:1,
            PAGESIZE:500,
            SNOWID:this.baseColl.SNOWID,
        }
        this.collItemObj.coolItemList=[]
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("QuestionCollection/GetCollectionAndQuestion", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                var list= response.data.DATA
                for(var i=0;i<list.length;i++){
                    var d = list[i]
                    var item ={
                        QUESTIONCOLLECIONID:this.baseColl.SNOWID,
                        CONTENT:d.CONTENT,
                        QUESTIONPOOLID:d.T_QUESTIONPOOLSNOWID,
                        SCORE:d.R_QUESTIONCOLLECIONT_POOLSCORE,
                    }
                    this.collItemObj.coolItemList.push(item)
                }
                //console.log(this.collItemObj.coolItemList)
            } 
            
        })
    },

    appendPoolItem(id){
        for(var i=0;i<this.qstList.length;i++){
            if(this.qstList[i].SNOWID == id){
                var d = this.qstList[i]

                var item ={
                    QUESTIONCOLLECIONID:this.baseColl.SNOWID,
                    CONTENT:d.CONTENT,
                    QUESTIONPOOLID:id,
                    SCORE:10,
                }
                this.collItemObj.coolItemList.push(item)
                break;
            }
        }
    },

    delPollItem(index){
        if(this.canDo){
            this.$confirm('确定删除考题？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
                var list=this.collItemObj.coolItemList
                var nl=[]
                for(var i=0;i<list.length;i++){
                    if(i!=index){
                        nl.push(list[i])
                    }
                }
                this.collItemObj.coolItemList = nl
            }).catch(() => {
                this.$message.info("已取消")    
            });
        }
    },
    prevPollItem(index){
        if(index>0){
            var list=this.collItemObj.coolItemList
            var nl=[]
            var thisItem= list[index]
            var prevItem= list[index-1]
            for(var i=0;i<list.length;i++){
                if(i!=index && i!= index-1){
                    nl.push(list[i])
                }else{
                   if(i==index-1){
                        nl.push(thisItem)
                   }else{
                        nl.push(prevItem)
                   }
                }
            }
            this.collItemObj.coolItemList = nl
        }
    },
    topPollItem(index){
        if(index>0){
            var list=this.collItemObj.coolItemList
            var nl=[]
            var top=list[index]
            nl.push(top)
            for(var i=0;i<list.length;i++){
                if(i!=index){
                    nl.push(list[i])
                }
            }
            this.collItemObj.coolItemList = nl
        }
    },
    submitCheckPollItemForm(){
        if (this.canDo) {
            this.$refs.coolItemForm.validate((valid) => {
                if (valid) {
                    var list = this.collItemObj.coolItemList
                    var nl=[]
                    for(var i=0;i<list.length;i++){
                        var d=list[i]
                        d.index= i+1
                        nl.push(d)    
                    }
                    this.appendPoolToCool(nl)
                } else {
                    return false;
                }
            });
        }
    },

    appendPoolToCool(list){
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(list, this.$store.state.token,this.$md5(this.comjs.sortAsc(list)).toUpperCase())
        this.$http.post("QuestionCollection/EditQuestionsOfCollection", md5_data)
            .then((response) => {
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已保存")
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.hideLoading()
        });
    },

    /**
     * 分类
     */
    showModelType(){
        this.typeModal = true
        this.getTypeList()
    },
    getTypeList(){
        this.showLoading("请稍后")
        var params={
            PAGEINDEX:this.PageIndexType,
            PAGESIZE:this.PageSizeType,
            NAME:this.typename,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("QuestionType/GetQuestionTypeList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.typeList=response.data.DATA
                this.TotalType=response.data.TOTALCOUNT
            } else {
                this.typeList=[]
                this.TotalType=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.typeList=[]
                this.TotalType=0
        });
    },
    searchType(){
        this.PageIndexType=1
        this.getTypeList()
    },
    PGChangeType(e){
        this.PageIndexType=e
        this.getTypeList()
    },
    addType(){
        this.postInfo={
            NAME:"",
        }
        this.doTypeStr="添加考题分类"
        this.doType="1"
        this.doModal="2"
        this.postModal=true
    },
    sendAddType(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("QuestionType/CreateQuestionType", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.postModal=false
                    this.getTypeList()
                    this.getQstTypeList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    editType(item){
        this.postInfo={
            SNOWID:item.SNOWID,
            NAME:item.NAME,
        }
        this.doTypeStr="修改考题分类"
        this.doType="2"
        this.doModal="2"
        this.postModal=true
    },
    sendEditType(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("QuestionType/EditQuestionType", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.postModal=false
                    this.getTypeList()
                    this.getQstTypeList() 
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    delType(item){
        if(this.canDo){
            this.$confirm('确定删除类型：'+item.NAME+'？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
            this.sendDelType(item.SNOWID)
            })
        }

    },
    sendDelType(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({SNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:id})).toUpperCase())
        this.$http.post("QuestionType/DeleteQuestionType", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.postModal=false
                    this.getTypeList()
                    this.getQstTypeList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },

    /**
     * 题库
     */
    getQstTypeList(){
        var params={
            PAGEINDEX:1,
            PAGESIZE:500,
            NAME:"",
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("QuestionType/GetQuestionTypeList", md5_data)
            .then((response) => {
            if (response.data.SUCCESS) {
                this.qstTypeList=response.data.DATA
            } 
        })
    },
    showModelQuestion(){
        this.qstModal = true
        this.getQstList()
    },
    getQstList(){
        this.showLoading("请稍后")
        var params={
            PAGEINDEX:this.PageIndexQst,
            PAGESIZE:this.PageSizeQst,
            CONTENT:this.qstname,
            QuestionTypeID:this.qsttype,
            //TYPE:this.qsttypet,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("QusetionPool/GetQusetionPoolList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.qstList=response.data.DATA
                this.TotalQst=response.data.TOTALCOUNT
            } else {
                this.qstList=[]
                this.TotalQst=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.qstList=[]
                this.TotalQst=0
        });
    },
    searchQst(){
        this.PageIndexQst=1
        this.getQstList()
    },
    addQst(){
        this.qstInfo={
            CONTENT:"",
            RESULT:"",
            TYPE:"1",
            QUESTIONTYPEID:"",
            A:"",
            B:"",
            C:"",
            D:"",
            Y:"",
            N:"",
        }
        this.doTypeStr="添加考题"
        this.doTypeQst="1"
        this.qstAddModal=true
    },
    sendAddQst(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.qstInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("QusetionPool/CreateQusetionPool", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.qstAddModal=false
                    this.getQstList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    editQst(item){
        var qtype="1"
        if(item.Y && item.Y!=""){
            qtype="2"
        }
        this.qstInfo={
            SNOWID:item.SNOWID,
            CONTENT:item.CONTENT,
            RESULT:item.RESULT,
            TYPE:item.TYPE,
            QUESTIONTYPEID:item.QUESTIONTYPEID,
            A:item.A,
            B:item.B,
            C:item.C,
            D:item.D,
            Y:item.Y,
            N:item.N,
        }
        this.doTypeStr="修改考题"
        this.doTypeQst="2"
        this.qstAddModal=true
    },
    sendEditQst(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.qstInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("QusetionPool/EditQusetionPool", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.qstAddModal=false
                   this.getQstList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    delQst(item){
        if(this.canDo){
            this.$confirm('确定删除考题？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
            this.sendDelQst(item.SNOWID)
            })
        }

    },
    sendDelQst(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({SNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:id})).toUpperCase())
        this.$http.post("QusetionPool/DeleteQusetionPool", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.qstAddModal=false
                    this.getQstList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    PGChangeQst(e){
        this.PageIndexQst=e
        this.getQstList()
    },
    submitQstForm() {
        if (this.canDo) {
            this.$refs.qForm.validate((valid) => {
                if (valid) {
                        if(this.doTypeQst=="1"){
                            this.sendAddQst()
                        }else if(this.doTypeQst=="2"){
                            this.sendEditQst()
                        }
                } else {
                    return false;
                }
            });
        }
    },

  },
  computed: {
  },
  watch: {
  },
};
</script>
<style scoped>
    .el-dialog{overflow: hidden;}
    .pgItem{margin-bottom: 20px;}


    .taskItemBox .el-dialog{overflow: hidden;}
    .taskItemBox  .left .taskItemBoxt{font-size: 16px;height: 40px;line-height: 40px;font-weight: bold;text-align: center;color: #333;}
    .taskItemBox .left{width: 500px;background-color: #f4f4f5;float:left;position: relative;}
    .taskItemBox .left .list{position: absolute;top: 40px;left: 0;right: 0;bottom:0;}
    .taskItemBox .left .tt{font-size: 14px;height: 30px;line-height: 30px;padding: 0 12px;color: #555;margin-top: 10px;}
    .taskItemBox .left .ilist{position: absolute;top: 40px;left: 0;right: 0;bottom:30px; padding: 12px;overflow:auto;}
    .taskItemBox .left .ilist .templeItem{margin-top: 12px;font-size: 14px;overflow: hidden;color: #333333;cursor: pointer;}
    .taskItemBox .left .ilist .templeItem:hover{color: #409EFF;}
    .taskItemBox .left .listMsg{padding: 0 12px;font-size: 14px;position: absolute;left: 0;right:0;bottom: 7px;color: #F56C6C;text-align: center;}

    .taskItemBox .left .msg{font-size: 13px;color: #E6A23C;text-align: center;margin-top: 6px;}

    .taskItemBox  .left .searchBox{overflow: hidden;position: relative;}

    .taskItemBox  .left .searchBox .searchBtn{position: absolute;left: 10px;top: 0px;}

    .taskItemBox .right{width: 640px;background-color: #f4f4f5;margin-left: 20px;float:left;position: relative;}
    .taskItemBox  .right .taskItemBoxt{font-size: 16px;height: 40px;line-height: 40px;font-weight: bold;color: #333;padding: 0 24px;overflow: hidden;}
    .taskItemBox  .right .addTaskTtem{float: right;}

    .taskItemBox  .right .ilist{padding: 12px;overflow:auto;position: absolute;left: 0;right: 0;top: 40px;bottom: 0px;}

    .tastItemForm{min-height: 450px;overflow: auto;position: absolute;left: 0;right:0;top:0;bottom:0; padding-bottom: 50px;}
    .tastItemForm .halfItem{width: 50%;display: inline-block;}
    .tastItemForm .allItem{width: 100%;float:left;}
    .bottomBtns{position: absolute;left:0px; right: 0px;bottom:0px;margin-bottom: 0;background-color:#FFFFFF;height:50px;overflow:hidden;}
</style>