<template>
  <div class="views-main">
    <div class="views-main-title">
      <p class="txt">字典管理</p>
    </div>
    <div class="tableBox" style="top:50px;">
      <div class="views-main-search">
        <el-input placeholder="输入关键字进行过滤" v-model="filterText" style="width:200px;"> </el-input>
        <el-button type="primary" @click="sendQuery" class="ml10">查询</el-button>
        <el-button type="success" @click="addDep" class="ml10">添加字典</el-button>
      </div>
      <div class="views-main-body">
          <el-tree :props="props" :data="dicList" ref="tree" node-key="CODE"  :filter-node-method="filterNode" >
            <span class="custom-tree-node" slot-scope="{ node, data }">
              <span class="treeName">{{ node.label }}</span>
              <span class="managerName">({{ data.CODE }})</span>
              <span>
                <el-button type="text" size="mini" @click.stop="() => append(node, data)" style="color:#19be6b">
                  添加
                </el-button>
                <el-button type="text" size="mini" @click.stop="() => edit(node, data)" style="color:#ff9900">
                  编辑
                </el-button>
                <el-button type="text" size="mini" @click.stop="() => remove(node, data)" style="color:#ed4014">
                  删除
                </el-button>
              </span>
            </span>
          </el-tree>
      </div>
    </div>
    <el-dialog :title="DepModalTitle" :visible.sync="DepModal" width="500px">
      <el-form :model="DepInfo" status-icon :rules="DepRules"  ref="DepForm" label-width="110px" class="demo-DepForm" >
        <el-form-item label="CODE" prop="CODE">
          <el-input v-model="DepInfo.CODE" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="名称" prop="NAME">
          <el-input v-model="DepInfo.NAME" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="类型">
          <el-input v-model="DepInfo.TYPE" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitDepForm()">提交</el-button>
          <el-button @click="closeDepForm()">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "DeviceManage",
  data() {
    var CODE = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入字典CODE"));
      } else {
        callback();
      }
    };
    var NAME = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入字典名称"));
      } else {
        callback();
      }
    };
    return {
      dicList:[],
      props: {
          label: 'NAME',
          children: 'CHILD',
      },
      filterText:"",

      DepModalType:"",
      DepModalTitle:"",
      DepParent:"",
      DepModal:false,
      DepInfo:{
        CODE:"",
        NAME:"",
        PARENTCODE:null,
        TYPE:"",
      },
      DepRules: {
        NAME: [{ validator: NAME, trigger: "blur" }],
        CODE: [{ validator: CODE, trigger: "blur" }],
      },
      DepEditItem:null,
      canDo:true,
    };
  },
  mounted() {
    this.loadItem=null
    this.getList()
  },
  methods: {
    filterNode(value, data) {
        if (!value) return true;
        //console.log(value)
        return data.NAME.indexOf(value) !== -1;
    },
    getList(node, resolve){
       var md5_data =this.comjs.requestDataEncrypt({}, this.$store.state.token,this.$md5(this.comjs.sortAsc({})).toUpperCase())
            this.$http.post("Dict/GetDictTreeList",md5_data)
            .then((response) => {
              if(response.data.SUCCESS){   
                  this.dicList=response.data.DATA
              }else{
                  this.dicList=[]
                  this.errorRequest(response.data.DATA,response.data.MSG,false)
              }
            }).catch((error) => {
                  this.$message.error("请求出错")
                  this.dicList=[]
                  this.canDo=true
            });
    },
    sendQuery(){
      var val=this.filterText
      this.$refs.tree.filter(val)
    },
    closeDepForm(){
      this.DepModal=false
    },
    submitDepForm(){
      if (this.canDo) {
          this.$refs.DepForm.validate((valid) => {
          if (valid) {
              if(this.DepModalType=="1"){
                this.appendDep()
              }else if(this.DepModalType=="2"){
                this.editDep()  
              }
          } else {
            return false;
          }
        });
      }
    },
    appendDep(){
      this.canDo=false
      this.showLoading("请稍后")
      var md5_data =this.comjs.requestDataEncrypt(this.DepInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.DepInfo)).toUpperCase())
      this.$http.post("Dict/CreateNewDict", md5_data)
        .then((response) => {
          //console.log(response)
          this.hideLoading()
          this.canDo=true
          this.DepModal=false
          if(response.data.SUCCESS){   
            var p=null
            if(this.DepParent!="" && this.DepParent!= null){
                p=this.DepParent
            }
            var data={
                CODE:this.DepInfo.CODE,
                NAME:this.DepInfo.NAME,
                PARENTCODE:p,
                TYPE:this.DepInfo.TYPE,
                CHILD:[]
            }
            this.$refs.tree.append(data,this.DepParent) 
            this.$message.success("添加成功")
          }else{
            this.errorRequest(response.data.DATA,response.data.MSG,true)
          }
        })
        .catch((error) => {
          this.$message.error("请求出错")
          this.hideLoading()
          this.canDo=true
      });
    },
    editDep(){
      this.canDo=false
      this.showLoading("请稍后")
      var md5_data =this.comjs.requestDataEncrypt(this.DepInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.DepInfo)).toUpperCase())
      this.$http.post("Dict/EditDict", md5_data)
        .then((response) => {
          //console.log(response)
          this.hideLoading()
          this.canDo=true
          this.DepModal=false
          if(response.data.SUCCESS){   
            this.$set(this.DepEditItem,'NAME',this.DepInfo.NAME)
            this.$set(this.DepEditItem,'TYPE',this.DepInfo.TYPE)
            this.$set(this.DepEditItem,'CODE',this.DepInfo.CODE)
            this.$message.success("编辑成功")
          }else{
            this.errorRequest(response.data.DATA,response.data.MSG,true)
          }
        })
        .catch((error) => {
          this.$message.error("请求出错")
          this.hideLoading()
          this.canDo=true
      });
    },
    addDep(){
      this.DepInfo={
        CODE:"",
        NAME:"",
        PARENTCODE:null,
        TYPE:"",
      },
      this.DepModalType="1"
      this.DepParent=""
      this.DepModalTitle="添加字典"
      this.DepModal=true
    },
    append(node, data){
      //console.log(data)
      this.DepInfo={
        CODE:"",
        NAME:"",
        PARENTCODE:data.CODE,
        TYPE:"",
      },
      this.DepModalType="1"
      this.DepParent=data.CODE
      this.DepModalTitle="添加字典"
      this.DepModal=true
    },
    edit(node, data){
       this.DepInfo={
        SNOWID:data.SNOWID,
        CODE:data.CODE,
        NAME:data.NAME,
        PARENTCODE:data.PARENTCODE,
        TYPE:data.TYPE,
      },
      this.DepEditItem=data
      this.DepModalType="2"
      this.DepParent=data.parent
      this.DepModalTitle="编辑字典"
      this.DepModal=true

    },
    remove(node, data){
      if(this.canDo){
        this.$confirm('确定删除'+data.NAME+'及其全部的子字典？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.delDep(data)
          }).catch(() => {
            this.$message.info("已取消")    
          });
      }
    },
    delDep(data){
      this.canDo=false
      this.showLoading("请稍后")
      var md5_data =this.comjs.requestDataEncrypt({SNOWID:data.SNOWID}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:data.SNOWID})).toUpperCase())
      this.$http.post("Dict/DeleteDict", md5_data)
        .then((response) => {
          //console.log(response)
          this.hideLoading()
          this.canDo=true
          if(response.data.SUCCESS){   
             this.$refs.tree.remove(data)
            this.$message.success("删除成功")
          }else{
            this.errorRequest(response.data.DATA,response.data.MSG,true)
          }
        })
        .catch((error) => {
          this.$message.error("请求出错")
          this.hideLoading()
          this.canDo=true
      });
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style >
  .el-tree-node{margin-top: 5px;}
  .treeName{font-size: 16px;margin-right: 5px;}
  .managerName{font-size: 12px;margin-right: 20px;color: #409EFF;}
  .custom-tree-node{display: block;}
</style>
