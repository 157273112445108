<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">试卷管理</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                
                <el-input v-model="name" placeholder="请输入名称" class="iw" ></el-input>
                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-button type="success" @click="addPost" class="fr">添加</el-button>
                
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column prop="NAME" label="名称"></el-table-column>
                    <el-table-column prop="SCORE" label="总分"></el-table-column>
                    <el-table-column prop="CREATETIME" label="创建日期"></el-table-column>
                    <el-table-column label="操作" width="150">
                        <template slot-scope="scope">
                            <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button>
                            <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
                            <el-button @click="showPoll(scope.row)"  type="text" size="small" style="color:#409EFF">考题</el-button>    
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="500px">
            <el-form :model="postInfo" status-icon :rules="postrules"  ref="uForm" label-width="100px" class="demo-uForm">
                <el-form-item label="名称" prop="NAME">
                    <el-input v-model="postInfo.NAME" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="总分" prop="SCORE">
                    <el-input v-model="postInfo.SCORE" autocomplete="off"></el-input>
                </el-form-item>
            
                <el-form-item>
                <el-button type="primary" @click="submitPostForm()">提交</el-button>
                <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>

        
        <el-dialog :title="collItemModalTitle" :visible.sync="coolModal" width="1200px" top="50px" class="taskItemBox" style="overflow:hidden;">
            <div class="left"  :style="{height:coolLeftHeight+ 'px'}">
                <div class="taskItemBoxt">
                    选题
                    <el-select v-model="poolFromType" placeholder="请选择类型" filterable  class="ics fr" >
                            <el-option label="从题库中选择" value="1" ></el-option>
                            <el-option label="从题集中导入" value="2" ></el-option>
                    </el-select>
                </div>
                <div class="list" v-show="poolFromType=='1'">
                    <div style="margin:10px 12px;overflow:hidden">
                         <el-input v-model="qstnameP" placeholder="请输入名称" class="ics" ></el-input>
                        <el-select v-model="qsttypeP" placeholder="请选择分类" filterable  class="ics" >
                                <el-option label="全部分类" value="" ></el-option>
                                <el-option v-for="item in qstTypeList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                        </el-select>
                        <el-button type="primary" @click="searchQstP">查询</el-button>
                    </div>
                   

                    <div class="ilist">
                        <div class="templeItem" v-for="item in qstList" :key="item.SNOWID" @click="appendPoolItem(item.SNOWID)">
                            {{item.CONTENT}}
                        </div>
                    </div>   
                    <div class="listMsg">点击题目加入试卷</div>
                </div>
                <div class="list" v-show="poolFromType=='2'">
                    <div style="margin:10px 12px;overflow:hidden">
                        <el-input v-model="qstnameC" placeholder="请输入名称" class="ics" ></el-input>
                        <el-button type="primary" @click="searchQstC">查询</el-button>
                    </div>
                    <div class="ilist">
                        <div class="templeItem" v-for="item in coolList" :key="item.SNOWID" @click="appendCoolItem(item.SNOWID)">
                            {{item.NAME}}
                        </div>
                    </div>   
                    <div class="listMsg">点击题集，将题集中的题目全部加入试卷</div>
                </div>
            </div>
            <div class="right" :style="{height:coolLeftHeight+ 'px'}">
                <div class="taskItemBoxt">
                    现有考题列表
                </div>       
                <div class="ilist">   
                    <el-form  :model="collItemObj" status-icon   ref="coolItemForm" label-width="80px" class="tastItemForm" :style="{'min-height':coolLeftHeight-100+ 'px'}">
                        <div  v-if="collItemObj.coolItemList.length>0" style="overflow:hidden;margin-top:20px;">
                            <div   v-for="(cool, index) in collItemObj.coolItemList" :key="cool.index">
                                <div style="overflow:hidden;border-bottom:1px solid #ADADAD;margin-bottom:20px;">
                                    <el-form-item label="考题："  :prop="'coolItemList.' + index + '.SCORE'" :rules="{ required: true, message: '考题不能为空', trigger: 'blur'}" class="allItem" >
                                        <el-input  v-model="cool.CONTENT" autocomplete="off" class="icssa" :disabled="baseColl.STATUS=='1'"></el-input>
                                    </el-form-item>
                                    <el-form-item label="答案："  :prop="'coolItemList.' + index + '.RESULT'" :rules="{ required: mustR(cool.TYPE), message: '答案不能为空', trigger: 'blur'}" class="allItem" >
                                        <el-input  v-model="cool.RESULT" autocomplete="off" class="icssa" :disabled="baseColl.STATUS=='1'"></el-input>
                                    </el-form-item>
                                    <el-form-item label="题型："   class="halfItem" >
                                        <el-select v-model="cool.TYPE" placeholder="请选择题目类型" filterable  class="icss" :disabled="baseColl.STATUS=='1'">
                                               <el-option  label="单选(答案必填、选项ABCD必填)" value="1" ></el-option>
                                                <el-option  label="多选(答案必填、选项ABCD必填)" value="2" ></el-option>
                                                <el-option  label="判断题(答案必填、选项YN必填)" value="3" ></el-option>
                                                <el-option  label="简答题" value="4" ></el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="分数："  :prop="'coolItemList.' + index + '.SCORE'" :rules="{ required: true, message: '分数不能为空', trigger: 'blur'}"  class="halfItem" >
                                        <el-input  v-model="cool.SCORE" autocomplete="off" class="icss" :disabled="baseColl.STATUS=='1'"></el-input>
                                    </el-form-item>
                                    <el-form-item label="选项A："  :prop="'coolItemList.' + index + '.A'" :rules="{ required: mustA(cool.TYPE), message: '选项A不能为空', trigger: 'blur'}"  class="halfItem" >
                                        <el-input  v-model="cool.A" autocomplete="off" class="icss" :disabled="baseColl.STATUS=='1'"></el-input>
                                    </el-form-item>
                                    <el-form-item label="选项B："  :prop="'coolItemList.' + index + '.B'" :rules="{ required: mustA(cool.TYPE), message: '选项B不能为空', trigger: 'blur'}"  class="halfItem" >
                                        <el-input  v-model="cool.B" autocomplete="off" class="icss" :disabled="baseColl.STATUS=='1'"></el-input>
                                    </el-form-item>
                                    <el-form-item label="选项C："  :prop="'coolItemList.' + index + '.C'" :rules="{ required: mustA(cool.TYPE), message: '选项C不能为空', trigger: 'blur'}"  class="halfItem" >
                                        <el-input  v-model="cool.C" autocomplete="off" class="icss" :disabled="baseColl.STATUS=='1'"></el-input>
                                    </el-form-item>
                                    <el-form-item label="选项D："  :prop="'coolItemList.' + index + '.D'" :rules="{ required: mustA(cool.TYPE), message: '选项D不能为空', trigger: 'blur'}"  class="halfItem" >
                                        <el-input  v-model="cool.D" autocomplete="off" class="icss" :disabled="baseColl.STATUS=='1'"></el-input>
                                    </el-form-item>
                                    <el-form-item label="选项Y："  :prop="'coolItemList.' + index + '.Y'" :rules="{ required: mustB(cool.TYPE), message: '选项Y不能为空', trigger: 'blur'}"  class="halfItem" >
                                        <el-input  v-model="cool.Y" autocomplete="off" class="icss" :disabled="baseColl.STATUS=='1'"></el-input>
                                    </el-form-item>
                                    <el-form-item label="选项N："  :prop="'coolItemList.' + index + '.N'" :rules="{ required: mustB(cool.TYPE), message: '选项N不能为空', trigger: 'blur'}"  class="halfItem" >
                                        <el-input  v-model="cool.N" autocomplete="off" class="icss" :disabled="baseColl.STATUS=='1'"></el-input>
                                    </el-form-item>

                                    <el-form-item label="分类："  class="allItem">
                                        <el-select v-model="cool.QUESTIONTYPEID" placeholder="请选择分类" filterable  class="icsss" :disabled="baseColl.STATUS=='1'">
                                                <el-option v-for="item in qstTypeList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                                        </el-select>
                                        <div v-if="baseColl.STATUS=='0'" style="float:right;overflow:hidden;">
                                            <el-button type="text" style="color:#F56C6C;float:right;margin-right:12px" @click="delPollItem(index)">删除</el-button>
                                            <el-button type="text" style="color:#409EFF;float:right;margin-left:0;;margin-right:12px" @click="prevPollItem(index)" v-if="index>0">上移</el-button>
                                            <el-button type="text" style="color:#E6A23C;float:right;;margin-right:12px"  @click="topPollItem(index)" v-if="index>0">置顶</el-button>
                                        </div>
                                        
                                    </el-form-item>
                                </div>
                                
                            </div>  


                        </div>
    
                        <el-form-item class="bottomBtns">
                            <el-button type="primary" @click="submitCheckPollItemForm()" v-if="baseColl.STATUS=='0'">保存</el-button>
                            <el-button @click="coolModal = false">关闭</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
var h=document.body.clientHeight
export default {
  name: "SJGL",
  data() {
    var NAME = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入名称"));
      } else {
        callback();
      }
    };
    var SCORE=(rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入总分'));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('总分只能是数字'));
        }else if(!/^\+?(0|[1-9][0-9]*)$/.test(value)){
            callback(new Error('只能输入大于等于0的整数'));
        }else {
          callback();
        }
    };
    
    return {
        canDo:true,
        
        name:"",
       

        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,


        doTypeStr:"",
        doType:"",
        postModal:false,
        postInfo:{
            NAME:"",
            SCORE:0,
        },
        postrules: {
            NAME: [{ validator: NAME, trigger: "blur" }],
            SCORE: [{ validator: SCORE, trigger: "blur" }],
        },

        poolFromType:"1",

        coolLeftHeight:0,
        collItemModalTitle:"",
        coolModal:false,
        baseColl:{STATUS:"1"},
        qstnameP:"",
        qsttypeP:"",

        qstnameC:"",

        qstTypeList:[],
        qstList:[],
        coolList:[],

        baseQuest:[],
        baseDelList:[],
        collItemObj:{
            coolItemList:[],
        },
    };
  },
  mounted() {
    this.loadItem=null
    this.getPostList()
    this.coolLeftHeight = h-200
  },
  methods: {
    mustA(type){
        var f=false
        if(type=="1" || type=="2"){
            f=true
        }
        return f
    },
    mustB(type){
        var f=false
        if(type=="3"){
            f=true
        }
        return f
    },
    mustR(type){
        var f=false
        if(type!="4"){
            f=true
        }
        return f
    },
    getPostList(){
        this.showLoading("请稍后")
        var params={
            PAGEINDEX:this.PageIndex,
            PAGESIZE:this.PageSize,
            NAME:this.name,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("QuestionGroup/GetQuestionGroupList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
        });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    addPost(){
        this.postInfo={
            NAME:"",
            SCORE:100,
        }
        this.doTypeStr="添加试卷"
        this.doType="1"
        this.postModal=true
    },
    sendAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("QuestionGroup/CreateQuestionGroup", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    editPost(item){
        this.postInfo={
            SNOWID:item.SNOWID,
            NAME:item.NAME,
            SCORE:item.SCORE,
        }
        this.doTypeStr="修改试卷"
        this.doType="2"
        this.postModal=true
    },
    sendEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("QuestionGroup/EditQuestionGroup", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.postModal=false
                    var nl=[]
                    for(var i=0;i<this.postList.length;i++){
                        var d=this.postList[i]
                        if(d.SNOWID==this.postInfo.SNOWID){
                            d.NAME=this.postInfo.NAME
                        }
                        nl.push(d)
                    }
                    this.postList=nl
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    delPost(item){
        if(this.canDo){
            this.$confirm('确定删除试卷：'+item.NAME+'？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
                this.sendDel(item.SNOWID)
            })
        }

    },
    sendDel(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({SNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:id})).toUpperCase())
        this.$http.post("QuestionGroup/DeleteQuestionGroup", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    submitPostForm() {
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    if(this.doType=="1"){
                        this.sendAdd()
                    }else if(this.doType=="2"){
                        this.sendEdit()
                    }
                } else {
                    return false;
                }
            });
        }
    },

    showPoll(e){
        this.collItemModalTitle=e.NAME+"-题目"
        this.baseColl=e
        this.baseDelList=[]
        this.getQstTypeList()
        this.getPoolArray()
        this.getCollList()
        this.getGroupQuest()
        this.coolModal=true
    },
    getQstTypeList(){
        var params={
            PAGEINDEX:1,
            PAGESIZE:500,
            NAME:"",
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("QuestionType/GetQuestionTypeList", md5_data)
            .then((response) => {
            if (response.data.SUCCESS) {
                this.qstTypeList=response.data.DATA
            } 
        })
    },
    searchQstP(){
        this.getPoolArray()
    },
    getPoolArray(){
        var params={
            PAGEINDEX:1,
            PAGESIZE:500,
            CONTENT:this.qstnameP,
            QuestionTypeID:this.qsttypeP,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("QusetionPool/GetQusetionPoolList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.qstList=response.data.DATA
            } 
            
        })
    },
    searchQstC(){
        this.getCollList()
    },
    getCollList(){
        var params={
            PAGEINDEX:1,
            PAGESIZE:500,
            NAME:this.qstnameC,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("QuestionCollection/GetCollectionList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.coolList=response.data.DATA
            } 
            
        })
    },
    getGroupQuest(){
        this.collItemObj.coolItemList =[]
        var md5_data =this.comjs.requestDataEncrypt(this.baseColl.SNOWID, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.baseColl.SNOWID)).toUpperCase())
        this.$http.post("Question/GetGroupQuestionListWithResult", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.baseQuest=response.data.DATA
                this.collItemObj.coolItemList=response.data.DATA.QuestionList
            } 
            
        })
    },
    appendPoolItem(snowid){
        if(this.baseColl.STATUS=="1"){
            this.$message.error("已使用的考卷无法修改考题")
            return
        }
        for(var i=0;i<this.qstList.length;i++){
            var d=this.qstList[i]
            if(snowid == d.SNOWID){
                this.collItemObj.coolItemList.push({
                    SNOWID:"",
                    CONTENT:d.CONTENT,
                    RESULT:d.RESULT,
                    TYPE:d.TYPE,
                    QUESTIONTYPEID:d.QUESTIONTYPEID,
                    QUESTIONGROUPID:this.baseColl.SNOWID,
                    A:d.A,
                    B:d.B,
                    C:d.C,
                    D:d.D,
                    Y:d.Y,
                    N:d.N,
                    SCORE:10,
                    QUESTIONINDEX:0,
                })
                break;
            }
        }
        //console.log( this.collItemObj.coolItemList)
    },
    appendCoolItem(snowid){
        if(this.baseColl.STATUS=="1"){
            this.$message.error("已使用的考卷无法修改考题")
            return
        }
        this.showLoading("请稍后")
        var params={
            PAGEINDEX:1,
            PAGESIZE:500,
            SNOWID:snowid,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("QuestionCollection/GetCollectionAndQuestion", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                var list= response.data.DATA
                for(var i=0;i<list.length;i++){
                    var d = list[i]
                    var item ={
                        SNOWID:"",
                        CONTENT:d.CONTENT,
                        RESULT:d.RESULT,
                        TYPE:d.TYPE,
                        QUESTIONTYPEID:d.QUESTIONTYPEID,
                        QUESTIONGROUPID:this.baseColl.SNOWID,
                        A:d.A,
                        B:d.B,
                        C:d.C,
                        D:d.D,
                        Y:d.Y,
                        N:d.N,
                        SCORE:d.R_QUESTIONCOLLECIONT_POOLSCORE,
                        QUESTIONINDEX:0,
                    }
                    this.collItemObj.coolItemList.push(item)
                }
                //console.log(this.collItemObj.coolItemList)
            }else {
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
        }); 
    },
    delPollItem(index){
        if(this.canDo){
            this.$confirm('确定删除考题？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
                var list=this.collItemObj.coolItemList
                var nl=[]
                for(var i=0;i<list.length;i++){
                    if(i!=index){
                        nl.push(list[i])
                    }else{
                        if(list[i].SNOWID!=""){
                            this.baseDelList.push(list[i].SNOWID)
                        }
                    }
                }
                this.collItemObj.coolItemList = nl
            }).catch(() => {
                this.$message.info("已取消")    
            });
        }
    },
    prevPollItem(index){
        if(index>0){
            var list=this.collItemObj.coolItemList
            var nl=[]
            var thisItem= list[index]
            var prevItem= list[index-1]
            for(var i=0;i<list.length;i++){
                if(i!=index && i!= index-1){
                    nl.push(list[i])
                }else{
                   if(i==index-1){
                        nl.push(thisItem)
                   }else{
                        nl.push(prevItem)
                   }
                }
            }
            this.collItemObj.coolItemList = nl
        }
    },
    topPollItem(index){
        if(index>0){
            var list=this.collItemObj.coolItemList
            var nl=[]
            var top=list[index]
            nl.push(top)
            for(var i=0;i<list.length;i++){
                if(i!=index){
                    nl.push(list[i])
                }
            }
            this.collItemObj.coolItemList = nl
        }
    },
    submitCheckPollItemForm(){
        if (this.canDo) {
            this.$refs.coolItemForm.validate((valid) => {
                if (valid) {
                    
                    var list = this.collItemObj.coolItemList
                    var allScore=0
                    for(var j=0;j<list.length;j++){
                        allScore+=parseInt(list[j].SCORE)
                    }
                    if(parseInt(allScore) != parseInt(this.baseColl.SCORE)){
                        this.$message.error("考题总分与试卷总分不匹配！")
                        return
                    }

                    var add=[]
                    var edit=[]
                    //baseDelList
                    for(var i=0;i<list.length;i++){
                        var d=list[i]
                        d.QUESTIONINDEX= i+1

                        if(d.SNOWID==""){
                            add.push(d)
                        }else{
                            edit.push(d)
                        }    
                    }
                    
                    this.saveGroupQuestions(add,edit)
                } else {
                    return false;
                }
            });
        }
    },
    saveGroupQuestions(addList,editList){
        this.showLoading("请稍后")
        this.canDo=false
        if(editList.length>0){
            // console.log("编辑")
            // console.log(editList)
            var md5_dataEdit =this.comjs.requestDataEncrypt(editList, this.$store.state.token,this.$md5(this.comjs.sortAsc(editList)).toUpperCase())
            this.$http.post("Question/EditGroupQuestions", md5_dataEdit)
                .then((response) => {
                if (response.data.SUCCESS) {
                   //console.log(response.data)
                } 
                
            })
        }

        if(this.baseDelList.length>0){
            // console.log("删除")
            // console.log(this.baseDelList)
            var qs=[]
            for(var i=0;i<this.baseDelList.length;i++){
                qs.push({
                    SnowID:this.baseDelList[i]
                })
            }
            var params={
                GroupID:this.baseColl.SNOWID,
                Questions:qs,
            }
            var md5_dataDel =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
            this.$http.post("Question/DeleteQuestions", md5_dataDel)
                .then((response) => {
                if (response.data.SUCCESS) {
                    //console.log(response.data)
                } 
                
            })
        }

        if(addList.length>0){
            // console.log("新增")
            // console.log(addList)
            var  al=[]
            for(var j=0;j<addList.length;j++){
                var ali=addList[j]
                delete ali.SNOWID
                al.push(ali)
            }
            var md5_dataAdd =this.comjs.requestDataEncrypt(al, this.$store.state.token,this.$md5(this.comjs.sortAsc(al)).toUpperCase())
            this.$http.post("Question/CreateQuestions", md5_dataAdd)
                .then((response) => {
                if (response.data.SUCCESS) {
                    //console.log(response.data)
                } 
            })
        }

        var _this=this
        setTimeout(function(){
            _this.$message.success("已保存") 
            _this.hideLoading()
            _this.canDo=true
            _this.coolModal=false
        },3000)
    }

  },
  computed: {
  },
  watch: {

  },
};
</script>
   
<style scoped>
    .el-dialog{overflow: hidden!important;}
    .taskItemBox .left .taskItemBoxt{font-size: 16px;height: 40px;line-height: 40px;font-weight: bold;color: #333;text-indent: 10px;padding: 10px 12px;background-color: #FFFFFF;}
    .taskItemBox .left{width: 500px;background-color: #f4f4f5;float:left;position: relative;}
    .taskItemBox .left .list{position: absolute;top: 60px;left: 0;right: 0;bottom:0;}
    .taskItemBox .left .tt{font-size: 14px;height: 30px;line-height: 30px;padding: 0 12px;color: #555;margin-top: 10px;}
    .taskItemBox .left .ilist{position: absolute;top: 60px;left: 0;right: 0;bottom:30px; padding: 12px;overflow:auto;}
    .taskItemBox .left .ilist .templeItem{margin-top: 12px;font-size: 14px;overflow: hidden;color: #333333;cursor: pointer;}
    .taskItemBox .left .ilist .templeItem:hover{color: #409EFF;}
    .taskItemBox .left .listMsg{padding: 0 12px;font-size: 14px;position: absolute;left: 0;right:0;bottom: 7px;color: #F56C6C;text-align: center;}

    .taskItemBox .left .msg{font-size: 13px;color: #E6A23C;text-align: center;margin-top: 6px;}

    .taskItemBox  .left .searchBox{overflow: hidden;position: relative;}

    .taskItemBox  .left .searchBox .searchBtn{position: absolute;left: 10px;top: 0px;}

    .taskItemBox .right{width: 640px;background-color: #f4f4f5;margin-left: 20px;float:left;position: relative;}
    .taskItemBox  .right .taskItemBoxt{font-size: 16px;height: 40px;line-height: 40px;font-weight: bold;color: #333;padding: 0 24px;overflow: hidden;}
    .taskItemBox  .right .addTaskTtem{float: right;}

    .taskItemBox  .right .ilist{padding: 12px;overflow:auto;position: absolute;left: 0;right: 0;top: 40px;bottom: 0;}

    .tastItemForm{min-height: 450px;overflow: hidden;position: absolute;left: 0;right:0;top:0;padding-bottom: 50px;}
    .tastItemForm .halfItem{width: 50%;display: inline-block;}
    .tastItemForm .allItem{width: 100%;float:left;}
    .tastItemForm .bottomBtns{position: absolute;right: 10px;bottom:10px;margin-bottom: 0;}
    .icss{width: 93%;}
    .icssa{width: 96.5%;}
    .icsss{width: 50%;}
</style>