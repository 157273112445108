<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">日常定检计划</p>
        </div>
        <div class="tableBox" style="top:50px;">
                <div class="ksItem" @click="createDJModal('5')">
                    关注高桥和围屏风机负荷情况及机组是否自动进入安全模式状态，避免造成发电量损失（日常密切监控）
                </div>
                <div class="ksItem"  @click="createDJModal('6')">
                   每季度核对测风仪及风向，避免影响发电量损失。（日常密切监控）
                </div>
                <div style="width:100%;float:left;height:1px;"></div>
               <div class="ksItem" @click="createDJModal('7')">
                    内窥镜检查
                </div>
        </div>
    </div>
</template>
<script>
export default {
  name: "RCDJJH",
  data() {

    return {
        canDo:true, 
    };
  },
  mounted() {
    this.loadItem=null
  },
  methods: {
    createDJModal(type){
        this.showLoading("请稍后")
        var params={
            PAGEINDEX:1,
            PAGESIZE:1,
            NAME:"",
            ALIAS:"INSPECTION",
            VISIBILITY:true,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Model/GetModelList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS && response.data.DATA.length>0) {
                var d=response.data.DATA[0]
                window.open("/statics/pages/LCQC.html?mid="+d.SNOWID+"&token="+this.$store.state.token+"&djtype="+type)
            } else {
            this.$message.error("请先配置定检申请流程")
            }
            })
            .catch((error) => {
                this.$message.error("请求失败，请稍后再试")
                this.hideLoading()
            }); 
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style scoped>
    .el-collapse-item__header{padding: 0 20px;font-size: 16px;}
    .el-collapse .is-active{color: #409EFF;}
    .el-collapse-item__content{padding-left:20px;}

    .ksItem{width: 500px;height: 80px;border-radius: 3px;cursor: pointer;color: #FFFFFF;background-color: #409EFF;font-size: 16px;margin-left: 30px;margin-top: 30px;float:left;padding:0 30px; display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;}


    .ksItem:hover{background-color: #5FADFC;}

</style>