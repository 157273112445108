<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">定位器管理</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                <el-input v-model="imei" placeholder="请输入IMEI" class="iw" ></el-input>
                <el-input v-model="name" placeholder="请输入名称" class="iw" ></el-input>
                <el-select v-model="type" placeholder="选择类型" class="iw" filterable clearable>
                    <el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value" ></el-option>
                </el-select>
                <el-select v-model="online" placeholder="在线状态" class="iw" filterable clearable>
                    <el-option v-for="item in onlineOptions" :key="item.value" :label="item.label" :value="item.value" ></el-option>
                </el-select>
                <el-button type="primary" @click="searchLocator">查询</el-button>
                <el-button type="success" @click="addLocator" class="fr">添加</el-button>
            </div>
            <div class="views-main-body">
                <el-table :data="locatorList" stripe style="width: 100%"> 
                    <el-table-column prop="IMEI" label="IMEI"></el-table-column>
                    <el-table-column prop="DISPLAYNAME" label="名称"></el-table-column>
                    <el-table-column prop="MODELTYPEDISPLAYNAME" label="类型"></el-table-column>
                    <el-table-column prop="ISONLINE" label="状态" width="120">
                        <template slot-scope="scope">
                        <span v-if="scope.row.ISONLINE=='1'" style="color:#67C23A">在线</span>
                        <span v-else-if="scope.row.ISONLINE=='0'" style="color:#F56C6C">离线</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="USERTRUENAME" label="人员"></el-table-column>
                    <el-table-column prop="CREATETIME" label="创建日期"></el-table-column>
                    <el-table-column label="操作" width="120">
                        <template slot-scope="scope">
                            <el-button @click="editLocator(scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button>
                            <el-button @click="delLocator(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
                            <el-button @click="locatorPosition(scope.row)"  type="text" size="small" style="color:#409EFF">定位</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="locatorModal" width="500px">
            <el-form :model="locatorInfo" status-icon :rules="locatorrules"  ref="uForm" label-width="100px" class="demo-uForm">
                <el-form-item label="IMEI" prop="IMEI">
                    <el-input v-model="locatorInfo.IMEI" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="名称" prop="DISPLAYNAME">
                    <el-input v-model="locatorInfo.DISPLAYNAME" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="类型" prop="MODELTYPE">
                    <el-select v-model="locatorInfo.MODELTYPE" placeholder="请选择类型" filterable clearable style="width:360px;">
                            <el-option v-for="item in typeOptions" :key="item.id" :label="item.label" :value="item.value" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="人员" prop="USERSNOWID">
                    <el-select v-model="locatorInfo.USERSNOWID" placeholder="请选择人员" filterable clearable style="width:360px;">
                            <el-option v-for="item in userList" :key="item.SNOWID" :label="item.FULLNAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitLocatorForm()">提交</el-button>
                    <el-button @click="locatorModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog title="腾讯地图" :visible.sync="showPosition" width="1200px" top="30px">
           <Map class="map" ref="map" :width="mapWidth" :height="mapHeight" :lat="lat" :lng="lng"></Map>
        </el-dialog>
    </div>
</template>
<script>
import Map from "../../components/Map"
export default {
  name: "LocatorManage",
  components:{
    Map,
  },
  data() {
    var IMEI = (rule, value, callback) => {
      if (this.locatorInfo.MODELTYPE !='14' && value === "") {
        callback(new Error("请输入定位器IMEI"));
      } else {
        callback();
      }
    };
    var DISPLAYNAME = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入名称"));
      } else {
        callback();
      }
    };
    var MODELTYPE = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择类型"));
      } else {
        callback();
      }
    };
    var USERSNOWID = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择人员"));
      } else {
        callback();
      }
    };
    return {
        canDo:true,
        
        imei:"",
        name:"",
        type: "",
        online:"",
        typeOptions: [],
        onlineOptions:[
            {value:"",label:"在线、离线"},
            {value:"1",label:"在线"},
            {value:"0",label:"离线"},
        ],
        userList:[],

        locatorList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,


        doTypeStr:"",
        doType:"",
        locatorModal:false,
        locatorInfo:{
            IMEI:"",
            DISPLAYNAME:"",
            USERSNOWID:"",
            MODELTYPE:"",
        },
        locatorrules: {
            IMEI: [{ validator: IMEI, trigger: "blur" }],
            DISPLAYNAME: [{ validator: DISPLAYNAME, trigger: "blur" }],
            USERSNOWID: [{ validator: USERSNOWID, trigger: "blur" }],
            MODELTYPE: [{ validator: MODELTYPE, trigger: "blur" }],
        },

        showPosition:false,
        lat:0,
        lng:0,
        mapWidth:1160,
        mapHeight:0
    };
  },
  mounted() {
    this.loadItem=null
    this.getTypes()
    this.getLocatorList()
    this.getUserList()
    this.mapHeight = document.body.clientHeight - 170
  },
  methods: {
    getTypes(){
        var md5_data =this.comjs.requestDataEncrypt({}, this.$store.state.token,this.$md5(this.comjs.sortAsc({})).toUpperCase())
        this.$http.post("Card/GetCardType", md5_data)
            .then((response) => {
                //console.log(response)
                if (response.data.SUCCESS) {
                    var list =response.data.DATA
                    var nl=[]
                    for(var i=0;i<list.length;i++){
                        nl.push({
                            value:list[i].TYPE,
                            label:list[i].DISPLAYNAME,
                            id:list[i].SNOWID
                        })
                    }
                    this.typeOptions=nl
                } 
        })
    },
    getUserList(){
        var md5_data =this.comjs.requestDataEncrypt({ORGANIZATIONID:""}, this.$store.state.token,this.$md5(this.comjs.sortAsc({ORGANIZATIONID:""})).toUpperCase())
        this.$http.post("UserManage/GetUserListWithoutPage", md5_data)
            .then((response) => {
                //console.log(response)
                if (response.data.SUCCESS) {
                    var list =response.data.DATA
                    var nl=[]
                    for(var i=0;i<list.length;i++){
                        var d=list[i]
                        d.FULLNAME=d.TRUENAME+"("+d.LOGINNAME+")"
                    }
                    this.userList =response.data.DATA
                } 
            })
    },
    getLocatorList(){
        this.showLoading("请稍后")
        var params={
            IMEI:this.imei,
            DISPLAYNAME:this.name,
            ISONLINE:this.online,
            MODELTYPE:this.type,
            PAGEINDEX:this.PageIndex,
            PAGESIZE:this.PageSize,
            
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Card/GetCardList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.locatorList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.locatorList=[]
                this.Total=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.locatorList=[]
                this.Total=0
        });
    },
    searchLocator(){
        this.PageIndex=1
        this.getLocatorList()
    },
    addLocator(){
        this.locatorInfo={
            IMEI:"",
            DISPLAYNAME:"",
            USERSNOWID:"",
            MODELTYPE:this.typeOptions[0].value,
        }
        this.doTypeStr="添加定位器"
        this.doType="1"
        this.locatorModal=true
    },
    sendAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.locatorInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.locatorInfo)).toUpperCase())
        this.$http.post("Card/CreateNewCard", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.locatorModal=false
                    this.getLocatorList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
            });
    },
    editLocator(item){
        this.locatorInfo={
            SNOWID:item.SNOWID,
            IMEI:item.IMEI,
            DISPLAYNAME:item.DISPLAYNAME,
            USERSNOWID:item.USERSNOWID,
            MODELTYPE:item.MODELTYPE,
        }
        this.doTypeStr="修改定位器"
        this.doType="2"
        this.locatorModal=true
    },
    sendEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.locatorInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.locatorInfo)).toUpperCase())
        this.$http.post("Card/EditCard", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.locatorModal=false
                    this.getLocatorList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
            });
    },
    delLocator(item){
        if(this.canDo){
            this.$confirm('确定删除：'+item.MODELTYPEDISPLAYNAME+'-'+item.DISPLAYNAME+'？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.sendDel(item.SNOWID)
            }).catch(() => {
            this.$message.info("已取消")    
          });
        }
    },
    sendDel(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({SNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:id})).toUpperCase())
        this.$http.post("Card/DeleteCard", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.locatorModal=false
                    this.getLocatorList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    PGChange(e){
        this.PageIndex=e
        this.getLocatorList()
    },
    submitLocatorForm() {
        if (this.canDo) {
            //console.log(this.$refs)
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    if(this.doType=="1"){
                        this.sendAdd()
                    }else if(this.doType=="2"){
                        this.sendEdit()
                    }
                } else {
                    return false;
                }
            });
        }
    },
    locatorPosition(item){
        if(item.TXLAT && item.TXLAT != 0 && item.TXLON && item.TXLON != 0){
            this.showPosition=true
            this.lat=item.TXLAT
            this.lng=item.TXLAT
            setTimeout( () =>{
                this.$refs.map.clearMarkers()
                var marker= this.$refs.map.addMarker(this.lat,this.lng)
            },200)
        }else{
            this.$message.error("暂无位置数据")
        }
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style >
</style>