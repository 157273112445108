<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">风场设备类型管理</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                
                <el-input v-model="name" placeholder="请输入名称" class="iw" ></el-input>
                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-button type="success" @click="addPost" class="fr">添加</el-button>
                
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column  label="默认图标">
                        <template slot-scope="scope">
                        <el-image style="width: 30px; height: 30px" :src="scope.row.ICONPATH" :preview-src-list="[scope.row.ICONPATH]">
                            <div slot="error" class="image-slot">
                            <i class="el-icon-picture-outline"></i>
                            </div>
                        </el-image>
                        </template>
                    </el-table-column>
                    <el-table-column prop="NAME" label="名称"></el-table-column>
                    <el-table-column prop="CODE" label="编号"></el-table-column>
                    <el-table-column prop="ISMACHINE" label="是否是风机">
                        <template slot-scope="scope">
                            <span v-if="scope.row.ISMACHINE==1">是</span>
                            <span v-else>否</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="ISDISPLAYFORMAP" label="是否在地图显示">
                        <template slot-scope="scope">
                            <span v-if="scope.row.ISDISPLAYFORMAP=='1'">是</span>
                            <span v-else-if="scope.row.ISDISPLAYFORMAP=='2'">否</span>
                            <span v-else>/</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="TRANSPARENT" label="透明度"></el-table-column>
                    <el-table-column prop="CREATETIME" label="创建日期"></el-table-column>
                    <el-table-column label="操作" width="240">
                        <template slot-scope="scope">
                            <!-- <el-button @click="statusPost(scope.row)" type="text" size="small" style="color:#409EFF">状态管理</el-button> -->
                            <el-button @click="failPost(scope.row)" type="text" size="small" style="color:#F56C6C">故障管理</el-button>
                            <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button>
                            <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="500px">
            <el-form :model="postInfo" status-icon :rules="postrules"  ref="uForm" label-width="100px" class="demo-uForm">
                <el-form-item label="名称" prop="NAME">
                    <el-input v-model="postInfo.NAME" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="编号" prop="CODE">
                    <el-input v-model="postInfo.CODE" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="是否为风机">
                    <el-radio v-model="postInfo.ISMACHINE" label="1">是</el-radio>
                    <el-radio v-model="postInfo.ISMACHINE" label="0">否</el-radio>
                </el-form-item>
                <el-form-item label="地图上显示" prop="ISDISPLAYFORMAP">
                    <el-radio v-model="postInfo.ISDISPLAYFORMAP" label="1">是</el-radio>
                    <el-radio v-model="postInfo.ISDISPLAYFORMAP" label="2">否</el-radio>
                </el-form-item>
                <el-form-item label="透明度">
                    <el-input-number v-model="postInfo.TRANSPARENT"  :min="0.1" :max="1" label="描述文字" :step="0.1"></el-input-number>
                </el-form-item>
                <el-form-item label="默认图标" prop="ICON">
                        <el-upload
                            class="avatar-uploader"
                            action="https://jsonplaceholder.typicode.com/posts/"
                            :show-file-list="false"
                            :http-request="uploadFun"
                            :before-upload="beforeAvatarUpload">
                            <img v-if="imageUrl" :src="imageUrl" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" @click="submitPostForm()">提交</el-button>
                    <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>

        <el-dialog :title="statusListTitle" :visible.sync="statusModal" width="1050px" top="50px" >
                <div style="width:100%;overflow:hidden;margin-bottom:20px;">
                     <el-button type="success" @click="addStatus" class="fr">添加</el-button>
                </div>
                <el-table :data="statusList" stripe style="width: 120%" :height="dialogMaxHeigh">
                    <el-table-column prop="DICTNAME" label="状态名称"></el-table-column>
                    <el-table-column  label="图标">
                        <template slot-scope="scope">
                        <el-image style="width: 30px; height: 30px" :src="scope.row.ICONPATH" :preview-src-list="[scope.row.ICONPATH]">
                            <div slot="error" class="image-slot">
                            <i class="el-icon-picture-outline"></i>
                            </div>
                        </el-image>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                            <el-button @click="delStatusItem(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndexS" :page-size="PageSizeS" :total="TotalS" class="pg pgItem" @current-change="PGChangeS"></el-pagination>
        </el-dialog>
        <el-dialog :title="doTypeStr" :visible.sync="statusAddModal" width="500px">
            <el-form :model="statusInfo" status-icon :rules="statusrules"  ref="sForm" label-width="100px" class="demo-uForm">
                <el-form-item label="状态名称" prop="DICTID">
                     <el-cascader v-model="statusInfo.DICTID"  :options="tree" :props="treeProps" placeholder="搜索或选择状态类型" filterable style="width:100%"></el-cascader>
                </el-form-item>
                <el-form-item label="图标" prop="ICON">
                        <el-upload
                            class="avatar-uploader"
                            action="https://jsonplaceholder.typicode.com/posts/"
                            :show-file-list="false"
                            :http-request="uploadFunS"
                            :before-upload="beforeAvatarUploadS">
                            <img v-if="imageUrlS" :src="imageUrlS" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" @click="submitStatusForm()">提交</el-button>
                    <el-button @click="statusAddModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>


        <el-dialog :title="failTitle" :visible.sync="failModal" width="1200px" top="50px">
               <SBGZGL :machineTypeSnowid="mtypeId" :tableHeight="dialogMaxHeigh" ref="gz"></SBGZGL>
        </el-dialog>
    </div>
</template>
<script>
var h=document.body.clientHeight
import SBGZGL from "../../components/SBGZGL"
export default {
  name: "WindFieldMachineTypeManage",
  components:{
    SBGZGL,//故障信息
  },
  data() {
    var NAME = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入名称"));
      } else {
        callback();
      }
    };
    var CODE = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入编号"));
      } else {
        callback();
      }
    };

    var ICON = (rule, value, callback) => {
      if (value == 0) {
        callback(new Error("请选择图片"));
      } else {
        callback();
      }
    };

    var ISDISPLAYFORMAP = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择是否在地图上显示"));
      } else {
        callback();
      }
    };

    var DICTID = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择状态名称"));
      } else {
        callback();
      }
    };

    return {
        canDo:true,
        
        name:"",
       

        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,


        doTypeStr:"",
        doType:"",
        postModal:false,
        postInfo:{
            NAME:"",
            CODE:"",
            TRANSPARENT:1,
            ICON:0,
        },
        postrules: {
            NAME: [{ validator: NAME, trigger: "blur" }],
            CODE: [{ validator: CODE, trigger: "blur" }],
            ICON: [{ validator: ICON, trigger: "blur" }],
            ISDISPLAYFORMAP: [{ validator: ISDISPLAYFORMAP, trigger: "blur" }],
        },
        imageUrl: "",
        fileItem:null,

        //状态
        tree:[],
        treeProps:{
            value:"SNOWID",
            label:"NAME",
            children:"CHILD",
            //checkStrictly:true,
        },

        statusAddModal:false,
        statusInfo:{},
        statusrules:{
            DICTID: [{ validator: DICTID, trigger: "blur" }],
            ICON: [{ validator: ICON, trigger: "blur" }],
        },
        imageUrlS: "",
        fileItemS:null,


        dialogMaxHeigh:0,
        nowTypeId:"",
        statusList:[],
        PageIndexS:1,
        PageSizeS:10,
        TotalS:0,
        statusListTitle:"",
        statusModal:false,

        failModal:false,
        failTitle:"",
        mtypeId:"",

    };
  },
  mounted() {
    this.dialogMaxHeigh = h -300
    this.loadItem=null
    this.getPostList()
  },
  methods: {
    handleAvatarSuccess(res, file) {
        this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
        //console.log(file)
        const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isJPG) {
          this.$message.error('上传头像图片只能是 JPG/PNG  格式!');
        }else if (!isLt2M) {
          this.$message.error('上传头像图片大小不能超过 2MB!');
        }else{
            var _URL = window.URL || window.webkitURL;
            this.imageUrl = _URL.createObjectURL(file);
            this.postInfo.ICON=1
            this.fileItem=file
        }
        return isJPG && isLt2M;
    },
    uploadFun(file){
        //console.log(file)
    },
    getPostList(){
        this.showLoading("请稍后")
        var params={
            PAGEINDEX:this.PageIndex,
            PAGESIZE:this.PageSize,
            NAME:this.name,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("MachineType/GetMachineTypeList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
        });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    addPost(){
        this.postInfo={
            NAME:"",
            CODE:"",
            TRANSPARENT:1,
            ICON:0,
            ISDISPLAYFORMAP:"2",
            ISMACHINE:"0",
        }
        this.imageUrl=""
        this.fileItem=null
        this.doTypeStr="添加设备类型"
        this.doType="1"
        this.postModal=true
    },
    sendAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        let postInfo = {
            CODE:this.postInfo.CODE,
            ISDISPLAYFORMAP:parseInt(this.postInfo.ISDISPLAYFORMAP),
            ISMACHINE:parseInt(this.postInfo.ISMACHINE),
            TRANSPARENT:this.postInfo.TRANSPARENT.toString(),
            NAME:this.postInfo.NAME,
        }
        var md5_data =this.comjs.requestDataEncrypt(postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(postInfo)).toUpperCase())
        this.$http.post("MachineType/CreateNewMachineType", md5_data)
            .then((response) => {
                this.canDo=true
                if (response.data.SUCCESS) {
                    this.addImg(response.data.DATA,"1")
                } else {
                    this.hideLoading()
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    addImg(id,type){
        var fd = new FormData();
        fd.append("file", this.fileItem);
        this.$upload.post("MachineType/UploadIcon?IMAGESOURCEGUID="+id, fd)
            .then((response) => {
                this.hideLoading()
                // console.log("上传图标")
                // console.log(response)
                this.hideLoading()
                if (response.data.SUCCESS) {
                    if(type=="1"){
                        this.$message.success("已添加")
                    }else{
                        this.$message.success("已修改")
                    }
                    this.postModal=false
                    this.getPostList()
                }else{
                    this.hideLoading()
                    this.$message.error("上传失败")
                }
            })
            .catch((error) => {
            this.hideLoading()
        });
    },
    editPost(item){
        //console.log(item)
        var ms="2"
        if(item.ISDISPLAYFORMAP){
            ms = item.ISDISPLAYFORMAP.toString()
        }
        var fj="0"
        if(item.ISMACHINE){
            fj =  item.ISMACHINE.toString()
        }
        this.postInfo={
            SNOWID:item.SNOWID,
            NAME:item.NAME,
            CODE:item.CODE,
            TRANSPARENT:parseFloat(item.TRANSPARENT),
            ICON:1,
            ISDISPLAYFORMAP:ms,
            ISMACHINE:fj,
        }
        if(item.ICONPATH){
            this.imageUrl=item.ICONPATH
        }else{
            this.postInfo.ICON=0
            this.imageUrl=""
        }
        this.fileItem=null
        this.doTypeStr="修改设备类型"
        this.doType="2"
        this.postModal=true
    },
    sendEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        let postInfo = {
            SNOWID:this.postInfo.SNOWID,
            CODE:this.postInfo.CODE,
            ISDISPLAYFORMAP:parseInt(this.postInfo.ISDISPLAYFORMAP),
            ISMACHINE:parseInt(this.postInfo.ISMACHINE),
            TRANSPARENT:this.postInfo.TRANSPARENT.toString(),
            NAME:this.postInfo.NAME,
        }

        var md5_data =this.comjs.requestDataEncrypt(postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(postInfo)).toUpperCase())
        this.$http.post("MachineType/EditMachineType", md5_data)
            .then((response) => {
                this.canDo=true
                
                if (response.data.SUCCESS) {
                    if(this.fileItem){
                        this.addImg(this.postInfo.SNOWID,"2")
                    }else{
                        this.$message.success("已修改")
                        this.hideLoading()
                        this.postModal=false
                        this.getPostList()
                    }
                   
                } else {
                    this.hideLoading()
                    this.errorRequest("2",response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    delPost(item){
        if(this.canDo){
            this.$confirm('确定删除设备类型：'+item.NAME+'？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
            this.sendDel(item.SNOWID)
            }).catch(() => {
            this.$message.info("已取消")    
          });
        }

    },
    sendDel(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({SNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:id})).toUpperCase())
        this.$http.post("MachineType/DeleteMachineType", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    submitPostForm() {
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                // this.postInfo.ISDISPLAYFORMAP = parseInt(this.postInfo.ISDISPLAYFORMAP)
                // this.postInfo.ISMACHINE= parseInt(this.postInfo.ISMACHINE)
                if (valid) {
                    if(this.doType=="1"){
                        this.sendAdd()
                    }else if(this.doType=="2"){
                        this.sendEdit()
                    }
                } else {
                    return false;
                }
            });
        }
    },
    //状态
    getDicTree(){
        var md5_data =this.comjs.requestDataEncrypt({}, this.$store.state.token,this.$md5(this.comjs.sortAsc({})).toUpperCase())
        this.$http.post("Dict/GetDictTreeList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.tree=response.data.DATA
            } 
            })
    },
    beforeAvatarUploadS(file) {
        //console.log(file)
        const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isJPG) {
          this.$message.error('上传图片只能是 JPG/PNG 格式!');
        }else if (!isLt2M) {
          this.$message.error('上传图片大小不能超过 2MB!');
        }else{
            var _URL = window.URL || window.webkitURL;
            this.imageUrlS = _URL.createObjectURL(file);
            this.statusInfo.ICON=1
            this.fileItemS=file
        }
        return isJPG && isLt2M;
    },
    uploadFunS(file){
        //console.log(file)
    },
    statusPost(item){
        this.nowTypeId=item.SNOWID
        this.statusListTitle = item.NAME+"-状态管理"
        this.PageIndexS=1
        this.getDicTree()
        this.getStatusList()
        this.statusModal=true
    },
    getStatusList(){
        this.showLoading("请稍后")
        var params={
            SNOWID:this.nowTypeId,
            PAGEINDEX:this.PageIndexS,
            PAGESIZE:this.PageSizeS,
            //NAME:this.name,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("MachineType/GetMachinetypeState", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.statusList=response.data.DATA
                this.TotalS=response.data.TOTALCOUNT
            } else {
                this.statusList=[]
                this.TotalS=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.statusList=[]
                this.TotalS=0
        });
    },
    PGChangeS(e){
        this.PageIndexS=e
        this.getStatusList()
    },
    addStatus(){
       this.statusInfo={
            MACHINETYPEID:this.nowTypeId,
            DICTID:"",
            ICON:0,
        }
        this.imageUrlS=""
        this.fileItemS=null
        this.statusAddModal=true
    },
    submitStatusForm() {
        if (this.canDo) {
            this.$refs.sForm.validate((valid) => {
                if (valid) {
                    //console.log(this.statusInfo)
                    this.sendStatusAdd()
                } else {
                    return false;
                }
            });
        }
    },
    sendStatusAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        this.statusInfo.DICTID = this.statusInfo.DICTID[this.statusInfo.DICTID.length-1]
        delete this.statusInfo.ICON
        var md5_data =this.comjs.requestDataEncrypt(this.statusInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.statusInfo)).toUpperCase())
        this.$http.post("MachineType/BindState", md5_data)
            .then((response) => {
                this.hideLoading()
                this.canDo=true
                if (response.data.SUCCESS) {
                    this.addStatusImg(response.data.DATA)
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    addStatusImg(id){
        var fd = new FormData();
        fd.append("file", this.fileItemS);
        this.$upload.post("MachineType/UploadStateIcon?IMAGESOURCEGUID="+id, fd)
            .then((response) => {
                this.hideLoading()
                //console.log("上传图标")
                //console.log(response)
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.statusAddModal=false
                    this.getStatusList()
                }else{
                    this.hideLoading()
                    this.$message.error("上传失败")
                }
            })
            .catch((error) => {
            this.hideLoading()
        });
    },
    delStatusItem(item){
        if(this.canDo){
            this.$confirm('确定删除设备类型状态：'+item.DICTNAME+'？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
                this.sendDelStatus(item.SNOWID)
            }).catch(() => {
            this.$message.info("已取消")    
          });
        }
    },
    sendDelStatus(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({SNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:id})).toUpperCase())
        this.$http.post("MachineType/DeleteMachineTypeState", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.getStatusList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    failPost(item){
        this.failTitle=item.NAME+"-故障管理"
        this.mtypeId=item.SNOWID
        this.failModal=true
        setTimeout(() => {
            this.$refs.gz.searchPost()
        }, 100);
        
    }
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style >
.avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }
.avatar{ width: 178px;line-height: unset!important;;}
 .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
</style>