<template>
    <div class="views-main">
        <div class="views-main-title">
            <el-button type="primary" icon="el-icon-arrow-left" style="float:left;margin-right:20px;" size="small" @click="backAQGL">返回</el-button>
            <p class="txt">加油报销记录</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                <el-select v-model="jyr" placeholder="加油人"  clearable filterable  class="iw">
                    <el-option v-for="item in userList" :key="item.SNOWID" :label="item.TRUENAME" :value="item.SNOWID" ></el-option>
                </el-select>   
                <el-select v-model="sqr" placeholder="申请人"  clearable filterable  class="iw">
                    <el-option v-for="item in userList" :key="item.SNOWID" :label="item.TRUENAME" :value="item.SNOWID" ></el-option>
                </el-select>   
                <el-select v-model="cph" placeholder="车牌号"  clearable filterable  class="iw">
                    <el-option v-for="item in carList" :key="item.SNOWID" :label="item.PLATENUMBER" :value="item.SNOWID" ></el-option>
                </el-select> 

                <el-date-picker
                    v-model="jysj"
                    type="datetimerange"
                    :picker-options="pickerOptions"
                    range-separator="至"
                    start-placeholder="加油开始日期"
                    end-placeholder="加油结束日期"
                    align="right"
                    class="iwss">
                </el-date-picker>

                <el-date-picker
                    v-model="sqsj"
                    type="datetimerange"
                    :picker-options="pickerOptions"
                    range-separator="至"
                    start-placeholder="申请开始日期"
                    end-placeholder="申请结束日期"
                    align="right"
                    class="iwss">
                </el-date-picker>
                <el-button type="primary" class="fr" @click="jybxsp">加油报销申请</el-button>
                <el-button type="primary" @click="searchPost">查询</el-button>
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column prop="PLATENUMBER" label="车牌号"></el-table-column>
                    <el-table-column prop="ADDTIME" label="加油时间"></el-table-column>
                    <el-table-column prop="OILCOUNT" label="加油量（升）"></el-table-column>
                    <el-table-column prop="OILMONEY" label="总费用"></el-table-column>
                    <el-table-column prop="USERNAME" label="加油人"></el-table-column>
                    <el-table-column prop="MONEY" label="报销金额"></el-table-column>
                    <el-table-column prop="APPLYUSERNAME" label="报销申请人"></el-table-column>
                    <el-table-column prop="APPLYTIME" label="申请时间"></el-table-column>
                    <el-table-column prop="APPROVEDTIME" label="审批时间"></el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  name: "JYBXJL",
  data() {
    return {
        canDo:true,
        
        cph:"",
        jyr:"",
        sqr:"",

        jysj:"",
        sqsj:"",
       
        carList:[],
        userList:[],

        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,
        pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },

       
    };
  },
  mounted() {
    this.loadItem=null
    this.getCarList()
    this.getUserList()
    this.getPostList()
  },
  methods: {
    getUserList(){
        var params={ }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("UserManage/GetUserListWithoutPage", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.userList=response.data.DATA
            } 
            })
    },
    getCarList(){
        var params={ 
            PAGEINDEX:1,
            PAGESIZE:10000,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Car/GetCarList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.carList=response.data.DATA
            } 
            })
    },
    getPostList(){
        this.showLoading("请稍后")
        
        var ast=""
        var aet=""
        if(this.jysj.length>0){
            ast = this.comjs.getDateStrByDate(this.jysj[0])
            aet = this.comjs.getDateStrByDate(this.jysj[1])
        }

        var sst=""
        var set=""
        if(this.sqsj.length>0){
            sst = this.comjs.getDateStrByDate(this.sqsj[0])
            set = this.comjs.getDateStrByDate(this.sqsj[1])
        }

        var params={
            PAGEINDEX:this.PageIndex,
            PAGESIZE:this.PageSize,
            ADDTIMESTART:ast,
            ADDTIMEEND:aet,
            APPLYTIMESTART:sst,
            APPLYTIMEEND:set,
            CARID:this.cph,
            USERID:this.jyr,
            APPLYUSERID:this.sqr,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("CarOil/GetCarOilList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
        });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    backAQGL(){
        this.$router.replace('/HQAQGL')
    },
    jybxsp(){
        this.showLoading("请稍后")
        var params={
            PAGEINDEX:1,
            PAGESIZE:1,
            NAME:"",
            ALIAS:"REFUEL",
            VISIBILITY:true,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Model/GetModelList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS && response.data.DATA.length>0) {
                var d=response.data.DATA[0]
                window.open("/statics/pages/LCQC.html?mid="+d.SNOWID+"&token="+this.$store.state.token)
            } else {
            this.$message.error("请先配置加油报销申请流程")
            }
            })
            .catch((error) => {
                this.$message.error("请求失败，请稍后再试")
                this.hideLoading()
            }); 
    }
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style >
</style>