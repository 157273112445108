<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">春秋检管理</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                
                <el-input v-model="title" placeholder="请输入标题" class="iw" ></el-input>
                <el-select v-model="type" placeholder="选择类型"  filterable  clearable class="iwu">
                      <el-option label="春检及秋检" value="" ></el-option>
                      <el-option label="春检" value="1" ></el-option>
                      <el-option label="秋检" value="2" ></el-option>
                </el-select>
                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-button type="warning" @click="addQiuPost" class="fr">起草秋检流程</el-button>
                <el-button type="success" @click="addChunPost" class="fr">起草春检流程</el-button>
                
                
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                  <el-table-column type="expand">
                        <template slot-scope="props">
                            <div style="padding:10px 20px;overflow:hidden;">
                                <p style="font-size:15px;margin-bottom:10px;">
                                    风场场长审批意见：{{props.row.FACTORYLEADEROPINION}}
                                </p>
                            </div>
                            <div style="padding:10px 20px;overflow:hidden;">
                                <p style="font-size:15px;margin-bottom:10px;">
                                    部门领导审批意见：{{props.row.DEPARTMENTLEADEROPINION}}
                                </p>
                            </div>
                            <div style="padding:10px 20px;overflow:hidden;">
                                <p style="font-size:15px;margin-bottom:10px;">
                                    分管领导审批意见：{{props.row.MANAGELEADEROPINION}}
                                </p>
                            </div>
                            <div style="padding:10px 20px;overflow:hidden;">
                                <p style="font-size:15px;margin-bottom:10px;">
                                    总经理审批意见：{{props.row.MAINLEADEROPINION}}
                                </p>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="TITLE" label="标题"></el-table-column>
                    <el-table-column prop="TYPE" label="类型">
                        <template slot-scope="scope">
                            <span v-if="scope.row.TYPE=='1'">春检</span>
                            <span v-else-if="scope.row.TYPE=='2'">秋检</span>
                            <span v-else>未知</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="STARTTIME" label="开始时间"></el-table-column>
                    <el-table-column prop="ENDTIME" label="结束时间"></el-table-column>
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                        <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button>
                        <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  name: "CQJ",
  data() {
    
    return {
        canDo:true,
        
        title:"",
        type:"",
       

        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,
    };
  },
  mounted() {
    this.loadItem=null
    this.getPostList()
  },
  methods: {
    getPostList(){
        this.showLoading("请稍后")
        var params={
            PAGEINDEX:this.PageIndex,
            PAGESIZE:this.PageSize,
            TITLE:this.title,
            TYPE:this.type,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("CQCheck/GetCQCheckList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
        });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    addChunPost(){
      this.cqjsq("1")
    },
    addQiuPost(){
      this.cqjsq("2")
    },

    cqjsq(type){
      this.showLoading("请稍后")
      var params={
            PAGEINDEX:1,
            PAGESIZE:1,
            NAME:"",
            ALIAS:"CQCHECK",
            VISIBILITY:true,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Model/GetModelList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS && response.data.DATA.length>0) {
                var d=response.data.DATA[0]
                window.open("/statics/pages/LCQC.html?mid="+d.SNOWID+"&token="+this.$store.state.token+"&cqjtype="+type)
            } else {
               this.$message.error("请先配置春秋检申请流程")
            }
            })
            .catch((error) => {
              this.$message.error("请求失败，请稍后再试")
              this.hideLoading()
               
        });
    }
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style >
</style>