<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">保险理赔</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                <el-input v-model="title" placeholder="请输入标题" class="iw" ></el-input>
                <el-select v-model="fcid" placeholder="选择风场"  filterable  clearable class="iwu">
                        <el-option v-for="item in factoryList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                </el-select>
                <el-date-picker v-model="bxdate" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" class="iwss"> </el-date-picker>
                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-button type="success" @click="addPost" class="fr">添加</el-button>
                
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column prop="TITLE" label="名称"></el-table-column>
                    <el-table-column prop="INITIATORNAME" label="发起人"></el-table-column>
                    <el-table-column prop="FACTORYNAME" label="风场"></el-table-column>
                    <el-table-column prop="CAUSE" label="理赔事项"></el-table-column>
                    <el-table-column prop="COMPENSATIONTYPENAME" label="保险类型"></el-table-column>
                    <el-table-column prop="MONEY" label="申报金额"></el-table-column>
                    <el-table-column prop="EFFECTSTARTTIME" label="生效日期">
                        <template slot-scope="scope">
                            <span v-if="scope.row.EFFECTSTARTTIME">{{scope.row.EFFECTSTARTTIME.split(" ")[0]}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="EFFECTENDTIME" label="失效日期">
                        <template slot-scope="scope">
                            <span v-if="scope.row.EFFECTENDTIME">{{scope.row.EFFECTENDTIME.split(" ")[0]}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="120">
                        <template slot-scope="scope">
                            <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button>
                            <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
                            <el-button @click="imgPost(scope.row)"  type="text" size="small" style="color:#409EFF">附件</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="800px">
            <el-form :model="postInfo" status-icon :rules="postrules"  ref="uForm" label-width="100px" class="demo-uForm">
                <el-form-item label="标题" prop="TITLE">
                    <el-input v-model="postInfo.TITLE" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="风场" prop="FACTORYID">
                    <el-select v-model="postInfo.FACTORYID" placeholder="请选择风场"  filterable style="width:100%;" @change="changefactory">
                            <el-option v-for="item in factoryList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="发起人" prop="INITIATORID">
                    <el-select v-model="postInfo.INITIATORID" placeholder="请选择发起人" filterable style="width:100%;" @change="changeUser">
                            <el-option v-for="item in userList" :key="item.SNOWID" :label="item.TRUENAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="理赔事项" prop="CAUSE">
                    <el-input v-model="postInfo.CAUSE" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="保险类型" prop="COMPENSATIONTYPEID">
                    <el-select v-model="postInfo.COMPENSATIONTYPEID" placeholder="请选择保险类型" filterable style="width:100%;">
                            <el-option v-for="item in typeList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="申报金额" prop="MONEY">
                    <el-input v-model="postInfo.MONEY" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="保险生效日期" prop="EFFECTSTARTTIMES">
                    <el-date-picker v-model="postInfo.EFFECTSTARTTIMES" type="date" placeholder="选择日期" style="width:100%;"></el-date-picker>
                </el-form-item>
                <el-form-item label="保险失效日期" prop="EFFECTENDTIMES">
                    <el-date-picker v-model="postInfo.EFFECTENDTIMES" type="date" placeholder="选择日期" style="width:100%;"></el-date-picker>
                </el-form-item>
                <!-- <el-form-item label="货品列表"  prop="COMPENSATIONTHINGLIST">
                    <el-button type="success" @click="addHP()" class="fr">添加</el-button>
                </el-form-item>
                <div  v-if="postInfo.COMPENSATIONTHINGLIST.length>0" style="overflow:hidden;margin-top:20px;">
                    <div   v-for="(cool, index) in postInfo.COMPENSATIONTHINGLIST" :key="cool.index">
                        <div style="overflow:hidden;border-bottom:1px solid #ADADAD;margin-bottom:20px;">
                            <el-form-item label="货品：" class="allItem" :prop="'COMPENSATIONTHINGLIST.' + index + '.THINGID'" :rules="{ required: true, message: '请选择货品', trigger: 'blur'}">
                                <el-select v-model="cool.THINGID" placeholder="请选择货品" filterable   class="icss" :prop="'COMPENSATIONTHINGLIST.' + index + '.THINGID'" :rules="{ required: true, message: '请选择货品', trigger: 'blur'}" >
                                        <el-option v-for="item in hpList" :key="item.SNOWID" :label="item.CNNAME" :value="item.SNOWID" ></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="单价："  :prop="'COMPENSATIONTHINGLIST.' + index + '.SINGLEPRICE'" :rules="[{ required: true, message: '单价不能为空'},{ type: 'number', message: '单价必须为数字'}]"   class="allItem" >
                                <el-input  v-model.number="cool.SINGLEPRICE" autocomplete="off" class="icss" type="number"></el-input>
                            </el-form-item>
                            <el-form-item label="数量："  class="allItem"  :prop="'COMPENSATIONTHINGLIST.' + index + '.NUMCOUNT'" :rules="[{ required: true, message: '数量不能为空'},{ type: 'number', message: '数量必须为数字'}]">
                                <el-input  v-model.number="cool.NUMCOUNT" autocomplete="off" class="icss" type="number"></el-input>
                                <div style="float:right;overflow:hidden;">
                                    <el-button type="text" style="color:#F56C6C;float:right;margin-right:12px" @click="delHP(index)">删除</el-button>
                                </div>
                                
                            </el-form-item>
                        </div>
                    </div>  
                </div> -->

                <el-form-item>
                    <el-button type="primary" @click="submitPostForm()">提交</el-button>
                    <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog title="保险理赔图片" :visible.sync="chooseFileModal" width="800px"  :append-to-body="true">
            <div style="overflow:hidden">
                <div class="fileBox">
                    <div class="fileItem" v-for="item in fileList" :key="item.SNOIWD" :title="item.FILENAME">
                        <p class="name" @click="downFile(item.FILEPATH)">{{item.FILENAME}}</p>
                        <div class="fileBtn">
                            <span @click="downFile(item.FILEPATH)" style="color:#409EFF">下载</span>
                            <span @click="delFile(item.SNOWID)" style="color:#F56C6C">删除</span>
                        </div>
                    </div>
                    <div v-if="fileList.length==0" class="noFile">还未上传附件，请在下方上传。</div>
                </div>
                <div style="overflow:hidden;">
                    <el-upload ref="upload"  class=""  :multiple="true" :limit="5" action="" :http-request="uploadFun">
                        <el-button size="small" type="primary">点击上传</el-button>
                    </el-upload>
                </div>
            </div>


        </el-dialog>
    </div>
</template>
<script>
export default {
  name: "BXLP",
  data() {
    var TITLE = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入标题"));
      } else {
        callback();
      }
    };
    var FACTORYID = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择风场"));
      } else {
        callback();
      }
    };

    var CAUSE=(rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入理赔事项"));
      } else {
        callback();
      }
    };

    var COMPENSATIONTYPEID=(rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入保险类型"));
      } else {
        callback();
      }
    };

    var MONEY=(rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入申报金额'));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('申报金额只能是数字'));
        }else if(value<=0){
            callback(new Error('申报金额必须大于0'));
        }else {
          callback();
        }
    };

    var INITIATORID=(rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择发起人"));
      } else {
        callback();
      }
    };

    var EFFECTSTARTTIMES=(rule, value, callback) => {
      if (value === "" || value==null) {
        callback(new Error("请选择保险生效日期"));
      } else {
        callback();
      }
    };
    var EFFECTENDTIMES=(rule, value, callback) => {
      if (value === "" || value==null) {
        callback(new Error("请选择保险失效日期"));
      } else {
        callback();
      }
    };

    return {
        canDo:true,
        fcid:"",
        title:"",
        factoryList:[],
       
        bxdate:[],

        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,

        typeList:[],
        userList:[],

        doTypeStr:"",
        doType:"",
        postModal:false,
        postInfo:{
            TITLE:"",
            FACTORYID:"",
            COMPENSATIONTHINGLIST:[],
        },
        postrules: {
            TITLE: [{ validator: TITLE, trigger: "blur" }],
            FACTORYID: [{ validator: FACTORYID, trigger: "blur" }],
            // COMPENSATIONTHINGLIST: [{ validator: COMPENSATIONTHINGLIST, trigger: "blur" }],
            CAUSE: [{ validator: CAUSE, trigger: "blur" }],
            COMPENSATIONTYPEID: [{ validator: COMPENSATIONTYPEID, trigger: "blur" }],
            MONEY:[{ validator: MONEY, trigger: "blur" }],
            INITIATORID:[{ validator: INITIATORID, trigger: "blur" }],
            EFFECTSTARTTIMES:[{ validator: EFFECTSTARTTIMES, trigger: "blur" }],
            EFFECTENDTIMES:[{ validator: EFFECTENDTIMES, trigger: "blur" }],
        },

        nowLP:null,
        chooseFileModal:false,
        fileList:[],
        showImgs:[],
    };
  },
  mounted() {
    this.loadItem=null
    this.getFactoryList()
    this.getPostList()
    this.getUserList()
    this.getTypeList()
  },
  methods: {
    getUserList(){
        var params={ }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("UserManage/GetUserListWithoutPage", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.userList=response.data.DATA
            } 
            })
    },
    changefactory(e){
        this.postInfo.FACTORYNAME =""
        if(e!=""){
            for(var i=0;i<this.factoryList.length;i++){
                if(this.factoryList[i].SNOWID == e){
                    this.postInfo.FACTORYNAME =this.factoryList[i].NAME
                }
            }
        }
        
    },
    getTypeList(){
        var params={ }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Compensation/GetCompensationTypeList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.typeList=response.data.DATA
            } 
            })
    },
    changeUser(e){
        this.postInfo.INITIATORNAME=""
        if(e!=""){
           for(var i=0;i<this.userList.length;i++){
             if(e==this.userList[i].SNOWID){
                this.postInfo.INITIATORNAME=this.userList[i].TRUENAME
             }
           } 
        }
    },
    getFactoryList(){
        var md5_data =this.comjs.requestDataEncrypt({}, this.$store.state.token,this.$md5(this.comjs.sortAsc({})).toUpperCase())
        this.$http.post("Factory/GetFactoryWithoutPage", md5_data)
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.factoryList=response.data.DATA  
                } 
            })
    },
    getPostList(){
        this.showLoading("请稍后")
        var st=""
        var et=""
        if(this.bxdate && this.bxdate.length==2){
            st = this.comjs.getDateMinStrByDate(this.bxdate[0])
            et = this.comjs.getDateMinStrByDate(this.bxdate[1])
        }
        var params={
            PAGEINDEX:this.PageIndex,
            PAGESIZE:this.PageSize,
            FACTORYID:this.fcid,
            TITLE:this.title,
            STARTTIME:st,
            ENDTIME:et,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Compensation/GetCompensationList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
        });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    addPost(){
        this.postInfo={
            TITLE:"",
            FACTORYID:"",
            FACTORYNAME:"",
            CAUSE:"",
            COMPENSATIONTYPEID:"",
            MONEY:"",
            INITIATORNAME:"",
            INITIATORID:"",
            EFFECTSTARTTIMES:"",
            EFFECTENDTIMES:"",
        }
        this.doTypeStr="添加保险理赔"
        this.doType="1"
        this.postModal=true
    },
    sendAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        this.postInfo.EFFECTSTARTTIME = this.comjs.getDateMinStrByDate(this.postInfo.EFFECTSTARTTIMES)
        this.postInfo.EFFECTENDTIME = this.comjs.getDateMinStrByDate(this.postInfo.EFFECTENDTIMES)
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("Compensation/CreateNewCompensation", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    editPost(item){
       
        this.postInfo={
            SNOWID:item.SNOWID,
            TITLE:item.TITLE,
            FACTORYID:item.FACTORYID,
            FACTORYNAME:item.FACTORYNAME,
            CAUSE:item.CAUSE,
            COMPENSATIONTYPEID:item.COMPENSATIONTYPEID,
            MONEY:item.MONEY,
            INITIATORNAME:item.INITIATORNAME,
            INITIATORID:item.INITIATORID,
            EFFECTSTARTTIMES:new Date(item.EFFECTSTARTTIME),
            EFFECTENDTIMES:new Date(item.EFFECTENDTIME),
        }
        //console.log(this.postInfo)
        this.doTypeStr="修改保险理赔"
        this.doType="2"
        this.postModal=true
    },
    sendEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        this.postInfo.EFFECTSTARTTIME = this.comjs.getDateMinStrByDate(this.postInfo.EFFECTSTARTTIMES)
        this.postInfo.EFFECTENDTIME = this.comjs.getDateMinStrByDate(this.postInfo.EFFECTENDTIMES)
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("Compensation/EditCompensation", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.postModal=false
                    this.getPostList();
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    delPost(item){
        if(this.canDo){
            this.$confirm('确定删除：'+item.TITLE+'？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
            this.sendDel(item.SNOWID)
            }).catch(() => {
            this.$message.info("已取消")    
          });
        }

    },
    sendDel(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({SNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:id})).toUpperCase())
        this.$http.post("Compensation/DeleteCompensation", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    submitPostForm() {
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    if(this.doType=="1"){
                        this.sendAdd()
                    }else if(this.doType=="2"){
                        this.sendEdit()
                    }
                } else {
                    return false;
                }
            });
        }
    },
    downFile(e){
        window.open(e)
    },
    imgPost(item){
        this.nowLP=item
        this.getImgById()
        this.chooseFileModal=true
    },
    getImgById(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({SNOWID:this.nowLP.SNOWID}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:this.nowLP.SNOWID})).toUpperCase())
        this.$http.post("Compensation/GetCompensationFileList", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.fileList=response.data.DATA
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    delFile(e){
        if(this.canDo){
            this.$confirm('确定删除图片？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
                this.sendDelFile(e)
            }).catch(() => {
            this.$message.info("已取消")    
          });
        }
    },
    sendDelFile(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({SNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:id})).toUpperCase())
        this.$http.post("Compensation/DelCompensationFile", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.getImgById()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    uploadFun(f){
      this.showLoading("请稍后...")
      var fd = new FormData();
      fd.append("file", f.file);
      var fname=f.file.name
      this.$upload.post("Compensation/UploadCompensationFile?CompensationID="+this.nowLP.SNOWID+"&FileName="+fname, fd)
        .then((response) => {
         //console.log(response)
         this.hideLoading()
          if (response.data.SUCCESS) {
                this.getImgById()
                this.hideLoading()
                this.$message.success("上传成功")
                this.$refs.upload.clearFiles()
                
          }else{
             this.hideLoading()
             this.$message.error("上传失败")
          }
        })
        .catch((error) => {
         this.hideLoading()
      });
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style scoped>
 .allItem{width: 100%;float:left;}
    .icss{width: 90%;}

    .fileBox{overflow: hidden;padding-bottom: 20px;border-bottom: 1px solid #ADADAD;margin-bottom: 20px;}
    .fileTitle{font-size: 18px;font-weight: bold;margin-bottom: 20px;}
    .fileTitleA{font-size: 16px;margin-top: 20px;}
    .fileItem{width: 100px;float:left;margin-right: 20px;}
    .fileItem .fileImg{width: 100px;height: 100px;overflow: hidden;background-color: #F8F8F9;}
    .fileBtn span{cursor: pointer;margin-right: 20px;}
    .noFile{margin-bottom: 20px;}
</style>
<style scoped>
.fileBox{overflow: hidden;margin: 20px 0;padding: 20px;background-color: #F8F8F9;}
.fileItem{border-radius: 3px;background-color: #FFFFFF;cursor: pointer;padding: 10px;float:left;margin-right: 20px;margin-bottom: 20px;width: 206px;}
.fileItem:nth-of-type(3n){margin-right: 0!important;}
.fileItem .name{font-size: 15px;font-weight: bold;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;color: #444;margin-bottom: 2px;}
.fileItem .keywords{font-size: 14px;font-weight: bold;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;margin-bottom: 5px;color: #909399;}
.fileItem .fileBtn{overflow: hidden;text-align: left;}
.fileItem span{margin-right: 10px;}
.noFile{text-align: center;font-size: 15px;color: #409EFF;}
.fileTitle{font-size: 16px;font-weight: bold;overflow: hidden;}

</style>