<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">待办待阅</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-body" style="top:0px;">
               <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column prop="TITLE" label="标题"></el-table-column>
                    <el-table-column prop="INITIATORNAME" label="起草人"></el-table-column>
                    <el-table-column prop="CREATED" label="起草时间"></el-table-column>
                    <el-table-column prop="TRACKINGMODEL.CREATED" label="上个节点处理时间"></el-table-column>
                    <el-table-column prop="TRACKINGMODEL.INITIATORNAME" label="上个节点处理人"></el-table-column>
                    <el-table-column prop="TRACKINGMODEL" label="上个节点处理意见">
                        <template slot-scope="scope">
                            <span v-if="scope.row.TRACKINGMODEL.OPINION!='0'">{{scope.row.TRACKINGMODEL.OPINION}}</span>
                            <span v-else>/</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="160">
                        <template slot-scope="scope">
                            <!-- <el-button @click="form(scope.row)"  type="text" size="small" style="color:#409EFF">表单</el-button>
                            <el-button @click="pass(scope.row)"  type="text" size="small" style="color:#67C23A" v-if="scope.row.INITIATORID != scope.row.TRACKINGMODEL.EXECUTORID">通过</el-button>
                            <el-button @click="back(scope.row)"  type="text" size="small" style="color:#F56C6C" v-if="scope.row.INITIATORID != scope.row.TRACKINGMODEL.EXECUTORID">退回</el-button>
                            <el-button @click="edit(scope.row)"  type="text" size="small" style="color:#E6A23C" d>编辑</el-button> -->
                            <el-button @click="dohanlder(scope.row)"  type="text" size="small" style="color:#409EFF">处理</el-button>
                            <el-button @click="cancel(scope.row)"  type="text" size="small" style="color:#F56C6C" v-if="scope.row.INITIATORID == scope.row.TRACKINGMODEL.EXECUTORID">取消</el-button>
                            <el-button @click="detail(scope.row)"  type="text" size="small" style="color:#E6A23C">审批记录</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <el-dialog title="审批通过" :visible.sync="passModal" width="500px">
            <el-form :model="passExecutor" status-icon :rules="passrules"  ref="passForm" label-width="100px" class="demo-uForm">
                <el-form-item label="审批意见" prop="OPINION">
                    <el-select  v-model="passExecutor.OPINION" allow-create filterable>
                        <el-option v-for="opts in opinionList" :key="opts.OPINION" :label="opts.OPINION" :value="opts.OPINION"></el-option>
                    </el-select>
                </el-form-item>
                
                <el-form-item label="下一节点" prop="RECT">
                    <el-select  v-model="passExecutor.RECT" @change="rectChangePass">
                    <el-option v-for="opts in nextRectList" :key="opts.SNOWID" :label="opts.NAME" :value="opts.SNOWID"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="审批人" v-if="nextUserList.length>0" prop="USER">
                    <el-select  v-model="passExecutor.USER" multiple>
                        <el-option v-for="opts in nextUserList" :key="opts.USERID" :label="opts.USERNAME+'('+opts.DISPLAYNAME+')'" :value="opts.USERID" :disabled="opts.TYPE=='2'"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="抄送至" v-if="nextUserList.length>0 && canCopy">
                    <el-select  v-model="copyList" multiple>
                    <el-option v-for="opts in nextUserList" :key="opts.USERID" :label="opts.USERNAME+'('+opts.DISPLAYNAME+')'" :value="opts.USERID" :disabled="opts.TYPE=='1'"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitPassForm()">提交</el-button>
                    <el-button @click="passModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog title="审批退回" :visible.sync="backModal" width="500px">
            <el-form :model="backExecutor" status-icon :rules="backrules"  ref="backForm" label-width="100px" class="demo-uForm">
                <el-form-item label="审批意见" prop="OPINION">
                    <el-select  v-model="backExecutor.OPINION" allow-create filterable>
                        <el-option v-for="opts in opinionList" :key="opts.OPINION" :label="opts.OPINION" :value="opts.OPINION"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="上一节点" prop="RECT">
                    <el-select  v-model="backExecutor.RECT" @change="rectChangeBack">
                        <el-option v-for="opts in prevRectList" :key="opts.SNOWID" :label="opts.NAME" :value="opts.SNOWID"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="审批人" v-if="prevUserList.length>0" prop="USER">
                    <el-select  v-model="backExecutor.USER" multiple="">
                    <el-option v-for="opts in prevUserList" :key="opts.USERID" :label="opts.USERNAME+'('+opts.DISPLAYNAME+')'" :value="opts.USERID" :disabled="opts.TYPE=='2'"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="抄送至" v-if="prevUserList.length>0 && canCopy">
                    <el-select  v-model="copyList" multiple>
                    <el-option v-for="opts in prevUserList" :key="opts.USERID" :label="opts.USERNAME+'('+opts.DISPLAYNAME+')'" :value="opts.USERID" :disabled="opts.TYPE=='1'"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitBackForm()">提交</el-button>
                    <el-button @click="backModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>

        <el-dialog title="编辑表单" :visible.sync="editModal" width="1200px">
            <div class="formList" v-for="formitem in editForms" :key="formitem.alias">
                <p class="fromTitle">{{formitem.name}}</p>
                <el-form :model="formitem.form" :rules="formitem.rules" :ref="formitem.alias" label-width="100px">
                    <div v-for="field in formitem.column" :key="field.id" class="fromItemOut">
                      <el-form-item  :label="field.label" :prop="field.id" >
                          <el-input v-if="field.type=='1'" v-model="formitem.form[field.id]" class="halfFromItem"></el-input>
                          <el-date-picker v-else-if="field.type=='2'"  v-model="formitem.form[field.id]" type="date" class="halfFromItem"></el-date-picker>
                          <el-time-picker v-else-if="field.type=='3'" v-model="formitem.form[field.id]" class="halfFromItem"></el-time-picker>
                          <el-select v-else-if="field.type=='4'" v-model="formitem.form[field.id]"  :multiple="field.muti" class="halfFromItem">
                            <el-option v-for="opts in field.options" :key="opts.key" :label="opts.value" :value="opts.key"></el-option>
                          </el-select>
                          <el-radio-group v-else-if="field.type=='5'"  v-model="formitem.form[field.id]" class="halfFromItem">
                            <el-radio  v-for="opts in field.options" :key="opts.key" :label="opts.key">{{opts.value}}</el-radio>
                          </el-radio-group>
                          <el-checkbox-group v-else-if="field.type=='6'"  v-model="formitem.form[field.id]" class="halfFromItem">
                            <el-checkbox v-for="opts in field.options" :key="opts.key" :label="opts.key">{{opts.value}}</el-checkbox>
                          </el-checkbox-group>
                           <el-time-picker v-else-if="field.type=='7'" v-model="formitem.form[field.id]" class="halfFromItem" type="datetime"></el-time-picker>
                      </el-form-item>
                    </div>
                </el-form>
            </div>
            <div style="float:right;margin-bottom:20px;">
                <el-button type="primary" @click="submitEditForm()">提交</el-button>
                <el-button @click="editModal = false">取消</el-button>
           </div>
        </el-dialog>

        <el-dialog title="审批确认" :visible.sync="lastModal" width="500px">
            <el-form :model="lastInfo" status-icon :rules="lastrules"  ref="lastInfo" label-width="100px" class="demo-uForm">
                <el-form-item label="审批节点" prop="RECT">
                    <el-select  v-model="lastInfo.RECT" @change="rectChangeEdit">
                    <el-option v-for="opts in lastRectList" :key="opts.SNOWID" :label="opts.NAME" :value="opts.SNOWID"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="审批人" prop="USER">
                    <el-select  v-model="lastInfo.USER" multiple="">
                    <el-option v-for="opts in lastUserList" :key="opts.USERID" :label="opts.USERNAME+'('+opts.DISPLAYNAME+')'" :value="opts.USERID" :disabled="opts.TYPE=='2'"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="抄送至" v-if="lastUserList.length>0 && canCopy">
                    <el-select  v-model="copyList" multiple>
                    <el-option v-for="opts in lastUserList" :key="opts.USERID" :label="opts.USERNAME+'('+opts.DISPLAYNAME+')'" :value="opts.USERID" :disabled="opts.TYPE=='1'"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitLastForm()">提交</el-button>
                    <el-button @click="lastModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>

        <el-dialog :title="printName+'-表单查看'" :visible.sync="showPrint" width="800px" top="50px">
            <div class="formListBox" id="formContent">
                <div class="formListItem" v-for="item in printList" :key="item.id">
                    <div class="formRowTitle">{{item.name}}</div>
                    <div class="formRowBox" v-for="(f,index) in item.list" :key="index">
                        <label>{{f.FIELDNAME}}：</label>
                        <p>{{f.FIELDVALUE}}</p>
                    </div>
                </div>
            </div>
            <div class="formListBtn">
                <el-button type="primary" @click="printForm()">打印</el-button>
                <el-button @click="showPrint = false">关闭</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import print from 'print-js'
export default {
  name: "PostManage",
  data() {
    var OPINION = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入审批意见"));
      } else {
        callback();
      }
    };
    var RECT = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择节点"));
      } else {
        callback();
      }
    };
    var USER = (rule, value, callback) => {
      if (value .length === 0) {
        callback(new Error("请选择人员"));
      } else {
        callback();
      }
    };
    var USERA = (rule, value, callback) => {   
      var isend=false
      for(var i=0;i<this.nextRectList.length;i++){
          if(this.nextRectList[i].SNOWID == this.passExecutor.RECT){
              if(this.nextRectList[i].ALIAS =="js"){
                  isend=true
              }
              break;
          }
      }
      if (!isend && value.length ===0) {
        callback(new Error("请选择审批人"));
      } else {
        callback();
      }
    };
    var USERB = (rule, value, callback) => {   
        var isstart=false
        for(var i=0;i<this.prevRectList.length;i++){
            if(this.prevRectList[i].SNOWID == this.backExecutor.RECT){
                if(this.prevRectList[i].ALIAS =="ks"){
                    isstart=true
                }
                break;
            }
        }
        if (!isstart && value.length ===0) {
            callback(new Error("请选择审批人"));
        } else {
            callback();
        }
    };
    return {
        canDo:true,
        
        postList:[],

        nowSnowId:"",
        nowCMList:[],
        nowPrevRectId:"",

        //审批意见
        opinionList:[],
        defOpinion:"",
        //抄送至
        canCopy:false,
        copyList:[],
        //下一节点/下个人
        nextRectList:[],
        nextUserList:[],

        //前一节点/前个人
        prevRectList:[],
        prevUserList:[],


        //通过
        passModal:false,
        passInfo:{},
        passExecutor:{
            OPINION:"",
            RECT:"",
            USER:[],
        },
        passrules: {
            OPINION: [{ validator: OPINION, trigger: "blur" }],
            USER: [{ validator: USERA, trigger: "blur" }],
            RECT: [{ validator: RECT, trigger: "blur" }],
        },
        //回退
        author:{},
        backModal:false,
        backInfo:{},
        backExecutor:{
            OPINION:"",
            RECT:"",
            USER:[],
        },
        backrules: {
            OPINION: [{ validator: OPINION, trigger: "blur" }],
            USER: [{ validator: USERB, trigger: "blur" }],
            RECT: [{ validator: RECT, trigger: "blur" }],
        },

        //重新申请
        formList:[],
        modelList:[],
        editInfo:{},
        editModal:false,
        editForms:[],

        lastRectList:[],
        lastUserList:[],
      
        lastModal:false,
        lastInfo:{
          RECT:"",
          USER:[],
        },  
        lastrules: {
            RECT: [{ validator: RECT, trigger: "blur" }],
            USER:[{ validator: USER, trigger: "blur" }],
        },


        //打印
        printList:[],
        printName:"",
        showPrint:false,

    };
  },
  mounted() {
    this.loadItem=null
    this.getFormList()
    this.getOpinion()
  },
  methods: {
    //处理
    dohanlder(item){
        //console.log(this.$store.state.token)
        window.open("/statics/pages/LCSP.html?pid="+item.SNOWID+"&token="+this.$store.state.token)
    },

    //获取审批意见
    getOpinion(){
        this.showLoading("请稍后")
        var params={
            PAGEINDEX:1,
            PAGESIZE:100,
            OPINION:"",
            USERID:this.$store.state.guid,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Opinion/GetOpinionList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.opinionList=response.data.DATA
                for(var i=0;i<this.opinionList.length;i++){
                    if(this.opinionList[i].ISDEFAULT==1){
                        this.defOpinion=this.opinionList[i].OPINION
                    }
                }
            } else {
                this.postList=[]
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
        });
    },
    //获取表单
    getFormList(){
      this.showLoading("请稍后")
       var params={
            PAGEINDEX:1,
            PAGESIZE:100,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Form/GetFormList", md5_data)
            .then((response) => {
              if (response.data.SUCCESS) {
                  this.formList=response.data.DATA
                  this.getModelList()
                  this.getPostList()
              } 
            })
    },
    //获取流程图
    getModelList(){
        var params={
            PAGEINDEX:1,
            PAGESIZE:100,
            VISIBILITY:true,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Model/GetModelList", md5_data)
            .then((response) => {
              if (response.data.SUCCESS) {
                  this.modelList=response.data.DATA
              }
            }) 
    },
    getPostList(){
        this.showLoading("请稍后")
        var params={
            EXECUTORID:this.$store.state.guid,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Process/GetAgentList", md5_data)
            .then((response) => {
                this.hideLoading()
                if (response.data.SUCCESS) {
                    var d=response.data.DATA
                    var nl=[]
                    for(var i=0;i<d.length;i++){
                        if(d[i].STATUS!='completed' && d[i].STATUS!='canceled'){
                           nl.push(d[i]) 
                        }
                    }
                    this.postList=nl
                } else {
                    this.postList=[]
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
        });
    },

    //通过
    pass(item){
        //console.log(item)
        this.nextRectList=[]
        this.nextUserList=[]
        this.passExecutor={
            OPINION:this.defOpinion,
            RECT:"",
            USER:[],
        }
        this.nowPrevRectId=item.TRACKINGMODEL.ACTIVITYID
        this.nowSnowId=item.SNOWID
        this.nowCMList=item.CONTENTMEMORYMODELS
        this.passInfo={
            PARENTID:item.TRACKINGMODEL.SNOWID,  //当前节点ID
            MODELID:item.MODELID,
            TITLE:item.TITLE,
            CONTENT:item.TRACKINGMODEL.CONTENT,
            INITIATOR:item.TRACKINGMODEL.EXECUTOR, //当前审批人
            INITIATORID:item.TRACKINGMODEL.EXECUTORID,
            INITIATORNAME:item.TRACKINGMODEL.EXECUTORNAME,
            OPINION:"",  //审批意见
            ACTIVITYID:"",  //下个节点ID
            ACTIVITYNAME:"", //下个节点名称
            SENDTO:"审批", 
        }
        this.getTrackingModel(item.SNOWID,"1")
    },
    rectChangePass(item){
        if(item!=""){
            this.passExecutor.USER=[]
            for(var i=0;i<this.nextRectList.length;i++){
                if(this.nextRectList[i].SNOWID == item){
                    this.passInfo.ACTIVITYID= item
                    this.passInfo.ACTIVITYNAME= this.nextRectList[i].NAME
                    if(this.nextRectList[i].ALIAS =="js"){
                        this.nextUserList=[]
                    }else{
                        this.nextUserList = this.nextRectList[i].ACTIVITYUSERMODELS
                    }
                    break;
                }
            }
        }
    },
    submitPassForm(){
        if(this.canDo){
            this.$refs.passForm.validate((valid) => {
                if (valid) {
                    var str=""
                    var elist=[]
                    var cplist=this.getCpList(this.nextRectList)
                    this.passInfo.OPINION=this.passExecutor.OPINION
                    if(this.passExecutor.USER.length==0){
                        str="审批通过,将结束流程"
                    }else{
                        var rectname=""
                        var username=""
                        for(var i=0;i<this.nextRectList.length;i++){
                            if(this.nextRectList[i].SNOWID == this.passExecutor.RECT){
                                rectname = this.nextRectList[i].NAME
                                this.passInfo.ACTIVITYID=this.passExecutor.RECT
                                this.passInfo.ACTIVITYNAME=this.nextRectList[i].NAME
                                break;
                            }
                        }
                        for(var k=0;k<this.passExecutor.USER.length;k++){
                          var lid=this.passExecutor.USER[k]
                          var lobj=null
                          for(var j=0;j<this.nextUserList.length;j++){
                            if(this.nextUserList[j].USERID==lid){
                              lobj = this.nextUserList[j]
                              elist.push({
                                    EXECUTOR:lobj.DISPLAYNAME,
                                    EXECUTORID:lobj.USERID,
                                    EXECUTORNAME:lobj.USERNAME,
                                    DEPTID:"0",
                                    DEPTNAME:"0",
                              })
                              break;
                            }
                          }
                          if(lobj){
                            if(i==0){
                              username=lobj.USERNAME+"("+lobj.DISPLAYNAME+")"
                            }else{
                              username+="、"+lobj.USERNAME+"("+lobj.DISPLAYNAME+")"
                            }
                          }
                      }
                        str='下一节点:'+rectname+'　审批人：'+username
                    }
                    //console.log(this.passInfo)
                    this.$confirm(str, '流程走向确认', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.sendApprovalProcess(this.passInfo,elist,cplist)
                    }).catch(() => {
                        this.$message.info("已取消")    
                    });
                } else {
                   return false;
                }
            });
        }
    },
    //退回
    back(item){
        this.prevRectList=[]
        this.prevUserList=[]
        this.author={
            id:item.INITIATORID,
            name:item.INITIATORNAME,
            account:item.INITIATOR,
        }
        this.backExecutor={
            OPINION:this.defOpinion,
            RECT:"",
            USER:[],
        }
        this.nowPrevRectId=item.TRACKINGMODEL.ACTIVITYID
        this.nowSnowId=item.SNOWID
        this.nowCMList=item.CONTENTMEMORYMODELS
        this.backInfo={
            PARENTID:item.TRACKINGMODEL.SNOWID,  //当前节点ID
            MODELID:item.MODELID,
            TITLE:item.TITLE,
            CONTENT:item.TRACKINGMODEL.CONTENT,
            INITIATOR:item.TRACKINGMODEL.EXECUTOR, //当前审批人
            INITIATORID:item.TRACKINGMODEL.EXECUTORID,
            INITIATORNAME:item.TRACKINGMODEL.EXECUTORNAME,
            OPINION:"",  //审批意见
            ACTIVITYID:"",  //下个节点ID
            ACTIVITYNAME:"", //下个节点名称
            SENDTO:"退回",  //下个审批人
        }
        this.getTrackingModel(item.SNOWID,"2")
    },
    rectChangeBack(item){
        if(item!=""){
            this.backExecutor.USER=[]
            for(var i=0;i<this.prevRectList.length;i++){
                if(this.prevRectList[i].SNOWID == item){
                    this.backInfo.ACTIVITYID= item
                    this.backInfo.ACTIVITYNAME= this.prevRectList[i].NAME
                    if(this.prevRectList[i].ALIAS =="ks"){
                        this.prevUserList=[]
                    }else{
                        this.prevUserList = this.prevRectList[i].ACTIVITYUSERMODELS
                    }
                    break;
                }
            }
        }
    },
    submitBackForm(){
        if(this.canDo){
            this.$refs.backForm.validate((valid) => {
                if (valid) {
                    var str=""
                    var elist=[]
                    var cplist=this.getCpList(this.prevUserList)

                    //抄送人

                    this.backInfo.OPINION=this.backExecutor.OPINION
                    if(this.backExecutor.USER.length==0){
                        str="流程将退回至起草人"
                        elist.push({
                            EXECUTOR:this.author.account,
                            EXECUTORID:this.author.id,
                            EXECUTORNAME:this.author.name,
                            DEPTID:"0",
                            DEPTNAME:"0",
                        })
                    }else{
                        var rectname=""
                        var username=""
                        for(var i=0;i<this.prevRectList.length;i++){
                            if(this.prevRectList[i].SNOWID == this.backExecutor.RECT){
                                rectname = this.prevRectList[i].NAME
                                this.backInfo.ACTIVITYID=this.backExecutor.RECT
                                this.backInfo.ACTIVITYNAME=this.prevRectList[i].NAME
                                break;
                            }
                        }
                        //审批人
                        for(var k=0;k<this.backExecutor.USER.length;k++){
                          var lid=this.backExecutor.USER[k]
                          var lobj=null
                          for(var j=0;j<this.prevUserList.length;j++){
                            if(this.prevUserList[j].USERID==lid){
                              lobj = this.prevUserList[j]
                              elist.push({
                                    EXECUTOR:lobj.DISPLAYNAME,
                                    EXECUTORID:lobj.USERID,
                                    EXECUTORNAME:lobj.USERNAME,
                                    DEPTID:"0",
                                    DEPTNAME:"0",
                              })
                              break;
                            }
                          }
                          if(lobj){
                            if(i==0){
                              username=lobj.USERNAME+"("+lobj.DISPLAYNAME+")"
                            }else{
                              username+="、"+lobj.USERNAME+"("+lobj.DISPLAYNAME+")"
                            }
                          }
                        }

                        str='流程将退回至:'+rectname+'节点　审批人：'+username
                    }   



                    this.$confirm(str, '流程走向确认', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.sendApprovalProcess(this.backInfo,elist,cplist)
                    }).catch(() => {
                        this.$message.info("已取消")    
                    });
                } else {
                   return false;
                }
            });
        }
    },

    //发送审批
    sendApprovalProcess(data,exs,cps){
        this.canDo=false
        var params={
            SNOWID:this.nowSnowId,
            ACTIVITYID:this.nowPrevRectId,
            TRACKINGMODEL:data,
            CONTENTMEMORYMODELS:this.nowCMList,
            EXECUTORMODELS:exs,
            READMODELS:cps,
        }
        //console.log(params)
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Process/ApprovalProcess", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("处理完成")
                    this.passModal=false
                    this.backModal=false
                    this.editModal=false
                    this.lastModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });

    },

    //编辑流程（退回到起草人）
    edit(item){
        this.nowPrevRectId=item.TRACKINGMODEL.ACTIVITYID
        this.nowSnowId=item.SNOWID
        this.nowCMList=item.CONTENTMEMORYMODELS
        this.editInfo={
            PARENTID:item.TRACKINGMODEL.SNOWID,
            MODELID:item.MODELID,
            TITLE:item.TITLE,
            CONTENT:"",
            INITIATORID: this.$store.state.guid,
            INITIATOR:this.$store.state.loginName,
            INITIATORNAME:this.$store.state.name,
            OPINION:"0",  //审批意见
            ACTIVITYID:"",  //下个节点ID
            ACTIVITYNAME:"", //下个节点名称
            SENDTO:"编辑后发送",  //下个审批人
        }
        this.editForms=[]
        this.initModelsForm(item.TRACKINGMODEL.CONTENT)
        this.getTrackingModel(item.SNOWID,"3")
    },
    //通过表单ID获取表单信息
    getFormById(id){
      var item =null
      for(var i=0;i<this.formList.length;i++){
        if(this.formList[i].SNOWID == id){
          item = this.formList[i]
          break;
        }
      }
      return item
    },
    //通过字段名称获取字段编号
    getFieldTypeCodeByTypeName(name){
      var code=""
      switch(name){
        case "文本":
            code="1"
            break;
        case "日期":
            code="2"
            break;
        case "时间":
            code="3"
            break;
        case "下拉框":
            code="4"
            break;
        case "单选按钮":
            code="5"
            break;
        case "复选框":
            code="6"
            break;
        case "日期时间":
            code="7"
            break;
      }
      return code
    },
    initModelsForm(content){
        var con = eval("(" + content + ")") 
        //console.log(con) 
        for(var fitem in con){
            var fromItem = null
            for(var i=0;i<this.formList.length;i++){
                if(this.formList[i].SNOWID == fitem){
                    fromItem=this.formList[i]
                    break;
                }
            }
            //console.log(fromItem)
            if(fromItem){
                var columnList=fromItem.FORMITEMMODELS //表单对象的字段集合
                var  c=[]  //表单元素
                var  f={}  //表单属性
                var  r={}  //表单属性校验规则
                for(var k=0;k<columnList.length;k++){
                    var kd=columnList[k]
                    var ft =this.getFieldTypeCodeByTypeName(kd.FIELDTYPENAME)

                    //是否必填
                    var  must=false
                    if(kd.ISCANNULL=="0"){
                        must =true
                        r[kd.SNOWID]=[{ required: true, message: kd.FIELDNAME+'不能为空', trigger: 'blur' }]
                    }
                    //是否可多选
                    var muti =false
                    if(kd.ISCANMUTI=="1"){
                        muti =true
                    }
                    //属性数据来源（下拉框、单选按钮、复选框）
                    var options=this.getFormJsonByType(kd.DATAFROMJSON)
                    c.push({
                        id:kd.SNOWID,
                        label:kd.FIELDNAME,
                        must:must,
                        muti:muti,
                        type:ft,
                        options:options
                    })
                    if(ft =="2" || ft=="3"){
                        f[kd.SNOWID]=new Date(con[fitem].datas[kd.SNOWID])
                    }else{
                        f[kd.SNOWID]=con[fitem].datas[kd.SNOWID]
                    }
                    
                
                }
                this.editForms.push({
                    snowid:fromItem.SNOWID,
                    name:fromItem.NAME,
                    alias:fromItem.ALIAS,
                    column:c,
                    form:f,
                    rules:r
                })
            }
        }
        this.editModal=true
    },
    submitEditForm(){
        if(this.canDo){
            var formTrueCount=0
            for(var i=0;i<this.editForms.length;i++){
              var refStr=this.editForms[i].alias
              this.$refs[refStr][0].validate((valid) => {
                  if (valid) {
                      formTrueCount++
                  } else {
                    return false;
                  }
              });
            }
            if(formTrueCount == this.editForms.length){
              this.lastModal=true
            }
      }
    },
    rectChangeEdit(item){
        if(item!=""){
            this.lastInfo.USER=[]
            for(var i=0;i<this.lastRectList.length;i++){
                if(this.lastRectList[i].SNOWID == item){
                    this.lastUserList = this.lastRectList[i].ACTIVITYUSERMODELS
                    break;
                }
            }
        }
    },
    submitLastForm(){
        if(this.canDo){
            this.$refs.lastInfo.validate((valid) => {
                if (valid) {
                    var exList=[]
                    var cplist=this.getCpList(this.lastUserList)
                    for(var m=0;m<this.lastInfo.USER.length;m++){
                        var lid=this.lastInfo.USER[m]
                        var lobj=null
                        for(var n=0;n<this.lastUserList.length;n++){
                            if(this.lastUserList[n].USERID==lid){
                            lobj = this.lastUserList[n]
                            break;
                            }
                        }
                        if(lobj){
                            exList.push({
                                EXECUTOR:lobj.DISPLAYNAME,
                                EXECUTORID:lobj.USERID,
                                EXECUTORNAME:lobj.USERNAME,
                                DEPTID:"0",
                                DEPTNAME:"0",
                            })
                        }
                    }

                    for(var c=0;c<this.lastRectList.length;c++){
                        if(this.lastRectList[c].SNOWID == this.lastInfo.RECT){
                            this.editInfo.ACTIVITYID=this.lastRectList[c].SNOWID
                            this.editInfo.ACTIVITYNAME=this.lastRectList[c].NAME
                            break;
                        }
                    }
                  
                    var cms=[]
                    var con={}
                    var dataObj= this.editForms
                    for(var k=0;k<dataObj.length;k++){
                        var form=dataObj[k].form
                        var column =dataObj[k].column
                        var fid=dataObj[k].snowid
                        con[fid]={snowid:fid,name:dataObj[k].name,alias:dataObj[k].alias,datas:form}
                        for(var j=0;j<column.length;j++){
                            var v=null
                            if(column[j].type=="2"){
                            var tstrA=this.comjs.getDateStrByDate(form[column[j].id]) 
                            v= tstrA.split(" ")[0]
                            }else if(column[j].type=="3"){
                            var tstrB=this.comjs.getDateStrByDate(form[column[j].id]) 
                            v= tstrB.split(" ")[1]
                            }else{
                            if(Array.isArray(form[column[j].id])){
                                for(var l=0;l<form[column[j].id].length;l++){
                                if(l==0){
                                    v=form[column[j].id][l]
                                }else{
                                    v+=","+form[column[j].id][l]
                                }
                                }
                            }else{
                                v = form[column[j].id]
                            }
                            
                            }
                            cms.push({
                                FORMID:fid,
                                FIELDNAME:column[j].label,
                                FIELDVALUE:v,
                                PROCESSID:this.nowSnowId,
                            })
                        }
                    }

                    this.nowCMList= cms
                    con=JSON.stringify(con);
                    this.editInfo.CONTENT=con
                    this.sendApprovalProcess(this.editInfo,exList,cplist)
                }
            })
        }
    },
    //结束流程
    cancel(item){
        if(this.canDo){
            this.$confirm('确定关闭此流程？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.sendCancel(item.SNOWID)
            }).catch(() => {
                this.$message.info("已取消")    
            });
        }
    },
    sendCancel(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({SNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:id})).toUpperCase())
        this.$http.post("Process/CancelPro", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已关闭")
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },

    //获取前后节点
    getTrackingModel(id,type){
        this.canCopy=false
        this.copyList=[]
        var params={
            PROCESSID:id,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Process/GetNextActivityByPro", md5_data)
            .then((response) => {
            if (response.data.SUCCESS) {
                var d=response.data.DATA
                if(d.Now.ISCANTOREAD !=undefined &&   d.Now.ISCANTOREAD &&  d.Now.ISCANTOREAD=="1"){
                    this.canCopy=true
                }
                if(type=="1"){
                    this.nextRectList = d.Next
                    var nextDefaultRect= this.nextRectList[0]
                    this.passInfo.ACTIVITYID= nextDefaultRect.SNOWID
                    this.passInfo.ACTIVITYNAME= nextDefaultRect.NAME
                    if(nextDefaultRect.ALIAS =="js"){
                        this.nextUserList=[]
                    }else{
                        this.nextUserList = nextDefaultRect.ACTIVITYUSERMODELS
                    }
                    this.passModal=true
                }else if(type=="2"){
                    if(d.Now.ISCALLBACK){
                        this.prevRectList = d.Back
                        var prevDefaultRect= this.prevRectList[0]
                        this.backInfo.ACTIVITYID= prevDefaultRect.SNOWID
                        this.backInfo.ACTIVITYNAME= prevDefaultRect.NAME
                        if(prevDefaultRect.ALIAS =="ks"){
                            this.prevUserList=[]
                        }else{
                            this.prevUserList = prevDefaultRect.ACTIVITYUSERMODELS
                        }
                        this.backModal=true
                    }else{
                        this.$message.error("该节点无法退回")
                    }
                    
                }else if(type=="3"){
                    this.lastRectList= d.Next
                    var editDefaultRect= this.lastRectList[0]
                    this.lastUserList=editDefaultRect.ACTIVITYUSERMODELS
                    this.lastInfo={RECT:"",USER:[]}
                }
            } else {
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
        });
    },
    //审批记录
    detail(item){
         window.open("/statics/myflow-master/index.html?token="+this.$store.state.token+"&type=3&fid="+item.MODELID+"&pid="+item.SNOWID)
    },
    //抄送人
    getCpList(list){
        var c=[]
        for(var s=0;s<this.copyList.length;s++){
            var lid=this.copyList[s]
            for(var j=0;j<list.length;j++){
                if(list[j].USERID==lid){
                    c.push({
                        EXECUTOR:list[j].DISPLAYNAME,
                        EXECUTORID:list[j].USERID,
                        EXECUTORNAME:list[j].USERNAME,
                        DEPTID:"0",
                        DEPTNAME:"0",
                    })
                    break;
                }
            }
        }
        return c
    },
    form(item){
       this.printName=item.TITLE
        var list =this.getFromValueStrByKey(item.CONTENTMEMORYMODELS) 

        var formObj={}
        var formArr=[]
        for(var i=0;i<list.length;i++){
            var d=list[i]
            if(formObj[d.FORMID] == undefined){
                var name=this.getFromNameById(d.FORMID)
                formObj[d.FORMID] = {name:name,id:d.FORMID,list:[]}
            }
            formObj[d.FORMID].list.push(d)
        }
        for(var fitem in formObj){
            formArr.push(formObj[fitem])
        }
        this.printList = formArr
        this.showPrint=true
    },
    getFromValueStrByKey(list){
        var nl=[]

        for(var i=0;i<list.length;i++){
            var fieldType=this.getFieldIsSel(list[i].FORMID, list[i].FIELDNAME)
            var v=""
            if(fieldType.type =='单选按钮' || fieldType.type =='下拉框' || fieldType.type =='复选框'){
                var jsonList=fieldType.json
                for(var j=0;j<jsonList.length;j++){
                    if(jsonList[j].key == list[i].FIELDVALUE){
                        v=jsonList[j].value
                        break;
                    }
                }
            }else if(fieldType.type =='日期时间'){
                v=list[i].FIELDVALUE.replace("T"," ")
            }
            else{
                v=list[i].FIELDVALUE
            }
            nl.push({
                FIELDNAME:list[i].FIELDNAME,
                FIELDVALUE:v,
                FORMID:list[i].FORMID,
                PROCESSID:list[i].PROCESSID,
            })


        }
        return nl
    },
    getFieldIsSel(formid,name){
        var obj={type:"",json:""}
        for(var i=0;i<this.formList.length;i++){
            if(this.formList[i].SNOWID == formid){
                var  fl=this.formList[i].FORMITEMMODELS
                for(var j=0;j<fl.length;j++){
                    if(fl[j].FIELDNAME == name){
                        obj.type=fl[j].FIELDTYPENAME
                        obj.json = this.getFormJsonByType(fl[j].DATAFROMJSON)  
                        break;
                    }
                }
                break;
            }
        }
        return obj
    },
    getFromNameById(id){
        var name=""
        for(var i=0;i<this.formList.length;i++){
            if(this.formList[i].SNOWID == id){
                name = this.formList[i].NAME
            }
        }
        return name
    },
    printForm(){
        print({printable:'formContent', type:'html',showModal:true,modalMessage:"加载中...",documentTitle:this.printName})
    }
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style>
.fromConOut{overflow: hidden;}
.fromConItem{font-size: 14px;float:left;margin-right: 20px;}
.formListBox{overflow: hidden;}
.formListBox .formListItem{overflow: hidden;margin-bottom: 30px;}
.formListBox .formListItem .formRowTitle{font-size: 16px;margin-bottom: 10px;font-weight: bold;}
.formListBox .formListItem .formRowBox{font-size: 16px;overflow: hidden;margin-bottom: 10px;}
.formListBox .formListItem .formRowBox label{width:120px;display: inline-block;}
.formListBox .formListItem .formRowBox p{display: inline-block;width: 640px;}
.formListBtn{float:right;overflow: hidden;margin-bottom: 30px;}
</style>