<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">审批意见管理</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                
                <el-input v-model="opinion" placeholder="请输入审批意见" class="iw" ></el-input>
                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-button type="success" @click="addPost" class="fr">添加</el-button>
                
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column prop="OPINION" label="审批意见"></el-table-column>
                     <el-table-column prop="ISDEFAULT" label="是否默认">
                        <template slot-scope="scope">
                            <span v-if="scope.row.ISDEFAULT=='1'">是</span>
                            <span v-else>否</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                            <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button>
                            <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
                            <el-button @click="defPost(scope.row)"  type="text" size="small" style="color:#409EFF" v-if="scope.row.ISDEFAULT!='1'">设为默认</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="500px">
            <el-form :model="postInfo" status-icon :rules="postrules"  ref="uForm" label-width="100px" class="demo-uForm">
                <el-form-item label="审批意见" prop="OPINION">
                <el-input v-model="postInfo.OPINION" autocomplete="off"></el-input>
                </el-form-item>
            
                <el-form-item>
                <el-button type="primary" @click="submitPostForm()">提交</el-button>
                <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
export default {
  name: "PostManage",
  data() {
    var OPINION = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入审批意见"));
      } else {
        callback();
      }
    };
    return {
        canDo:true,
        
        opinion:"",
       

        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,


        doTypeStr:"",
        doType:"",
        postModal:false,
        postInfo:{
            OPINION:"",
        },
        postrules: {
            OPINION: [{ validator: OPINION, trigger: "blur" }],
        },
    };
  },
  mounted() {
    this.loadItem=null
    this.getPostList()
  },
  methods: {
    getPostList(){
        this.showLoading("请稍后")
        var params={
            PAGEINDEX:this.PageIndex,
            PAGESIZE:this.PageSize,
            OPINION:this.opinion,
             USERID:this.$store.state.guid,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Opinion/GetOpinionList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
        });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    addPost(){
        this.postInfo={
            USERID:this.$store.state.guid,
            OPINION:"",
        }
        this.doTypeStr="添加审批意见"
        this.doType="1"
        this.postModal=true
    },
    sendAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("Opinion/CreateNewOpinion", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    editPost(item){
        this.postInfo={
            USERID:this.$store.state.guid,
            SNOWID:item.SNOWID,
            OPINION:item.OPINION,
            ISDEFAULT:item.ISDEFAULT,
        }
        this.doTypeStr="修改审批意见"
        this.doType="2"
        this.postModal=true
    },
    sendEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("Opinion/EditOpinion", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    delPost(item){
        if(this.canDo){
            this.$confirm('确定删除？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
                this.sendDel(item.SNOWID)
            })
        }

    },
    sendDel(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({SNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:id})).toUpperCase())
        this.$http.post("Opinion/DeleteOpinion", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    defPost(item){
        if(this.canDo){
            this.$confirm('确定标记为默认？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
                this.sendDef(item.SNOWID)
            })
        }

    },
    sendDef(id){
        this.canDo=false
        var params={
            USERID:this.$store.state.guid,
            SNOWID:id,
            ISDEFAULT:"1",
        }
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Opinion/SetDelOpinion", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已标记为默认")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    submitPostForm() {
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    if(this.doType=="1"){
                        this.sendAdd()
                    }else if(this.doType=="2"){
                        this.sendEdit()
                    }
                } else {
                    return false;
                }
            });
        }
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style >
</style>