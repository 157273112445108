<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">发电设备管理</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                
                <el-input v-model="name" placeholder="请输入设备名称" class="iw" ></el-input>
                <el-input v-model="number" placeholder="请输入设备编号" class="iw" ></el-input>
                <el-select v-model="fc" placeholder="选择风场"  filterable  clearable class="iw">
                        <el-option v-for="item in fcList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                </el-select>
                <el-select v-model="type" placeholder="选择设备类型"  filterable  clearable class="iw">
                        <el-option v-for="item in mtList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                </el-select>
                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-button type="success" @click="addPost" class="fr">添加</el-button>
                <el-button type="danger" @click="delIds" class="fr ml20">批量删除</el-button>
                <!-- <el-button type="primary" @click="downloadTemplet" class="fr">下载模板</el-button> -->
                <el-button type="warning" @click="uploadTemplet"  class="fr ml20">导入模板</el-button>
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%" @selection-change="handleSelectionChange"> 
                    <el-table-column type="selection" width="60"></el-table-column>
                    <el-table-column prop="NAME" label="设备名称"></el-table-column>
                    <el-table-column prop="NUMBER" label="设备编号"></el-table-column>
                    <el-table-column prop="FACTORYNAME" label="风场名称"></el-table-column>
                    <el-table-column prop="MACHINETYPENAME" label="设备类型"></el-table-column>

                    <el-table-column prop="ACTIVEPOWER" label="有功功率">
                        <template slot-scope="scope">
                            <span v-if="scope.row.ACTIVEPOWER==null">/</span>
                            <span v-else>{{scope.row.ACTIVEPOWER}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="REACTIVEPOWER" label="无功功率">
                        <template slot-scope="scope">
                            <span v-if="scope.row.REACTIVEPOWER==null">/</span>
                            <span v-else>{{scope.row.REACTIVEPOWER}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="WINDSPEED" label="风速">
                        <template slot-scope="scope">
                            <span v-if="scope.row.WINDSPEED==null">/</span>
                            <span v-else>{{scope.row.WINDSPEED}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="WINDDIRECTION" label="风向">
                        <template slot-scope="scope">
                            <span v-if="scope.row.WINDDIRECTION==null">/</span>
                            <span v-else>{{scope.row.WINDDIRECTION}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="TEMPERATURE" label="环境温度">
                        <template slot-scope="scope">
                            <span v-if="scope.row.TEMPERATURE==null">/</span>
                            <span v-else>{{scope.row.TEMPERATURE}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="ISONLINE" label="是否在线">
                        <template slot-scope="scope">
                            <span v-if="scope.row.ISONLINE=='1'" style="color:#67C23A">在线</span>
                            <span v-else style="color:#F56C6C">离线</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="SYSTIME" label="上次通讯时间">
                        <template slot-scope="scope">
                            <span v-if="scope.row.SYSTIME==null">/</span>
                            <span v-else>{{scope.row.SYSTIME}}</span>
                        </template>
                    </el-table-column>

                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                        <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="800px">
            <el-form :model="postInfo" status-icon :rules="postrules"  ref="uForm" label-width="150px" class="demo-uForm">
                <el-form-item label="风场">
                    <el-select v-model="checkFc" placeholder="请选择风场"  filterable  style="width:100%;" @change="fcChange">
                            <el-option v-for="item in fcList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="设备类型">
                    <el-select v-model="checkLx" placeholder="请选择设备类型"  filterable  style="width:100%;" @change="lxChange">
                        <el-option v-for="item in mtList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="设备" prop="MACHINEID">
                    <el-select v-model="postInfo.MACHINEID" placeholder="请选择设备"  filterable  style="width:100%;">
                            <el-option v-for="item in sbList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <!-- <el-form-item label="有功功率" prop="ACTIVEPOWER">
                    <el-input  v-model="postInfo.ACTIVEPOWER" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="无功功率" prop="REACTIVEPOWER">
                    <el-input  v-model="postInfo.REACTIVEPOWER" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="风速" prop="WINDSPEED">
                    <el-input  v-model="postInfo.WINDSPEED" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="风向" prop="WINDDIRECTION">
                    <el-input  v-model="postInfo.WINDDIRECTION" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="温度" prop="TEMPERATURE">
                    <el-input  v-model="postInfo.TEMPERATURE" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="是否在线">
                    <el-radio v-model="postInfo.ISONLINE" label="1">是</el-radio>
                    <el-radio v-model="postInfo.ISONLINE" label="0">否</el-radio>
                </el-form-item>
                <el-form-item label="上次通讯时间" prop="SYSTIME">
                    <el-date-picker v-model="postInfo.SYSTIME" type="date" placeholder="选择日期" style="width:100%;"></el-date-picker>
                </el-form-item>
                <el-form-item label="其他小时数" prop="OTHERHOURS">
                    <el-input  v-model="postInfo.OTHERHOURS" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="运行小时数" prop="RUNHOURS">
                    <el-input  v-model="postInfo.RUNHOURS" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="故障小时数" prop="ALARMHOURS">
                    <el-input  v-model="postInfo.ALARMHOURS" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="发电量损失电量" prop="LOSSPOWER">
                    <el-input  v-model="postInfo.LOSSPOWER" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="电量损失小时数" prop="LOSSPOWERHOURS">
                    <el-input  v-model="postInfo.LOSSPOWERHOURS" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="发电量" prop="POWER">
                    <el-input  v-model="postInfo.POWER" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="状态" prop="STATUS">
                    <el-select v-model="postInfo.STATUS" placeholder="请选择状态"  filterable  style="width:100%;">
                        <el-option label="正常" value="1" ></el-option>
                        <el-option label="故障" value="0" ></el-option>
                        <el-option label="弃风限电" value="2" ></el-option>
                        <el-option label="离线" value="4" ></el-option>
                    </el-select>
                </el-form-item> -->
                <el-form-item>
                <el-button type="primary" @click="submitPostForm()">提交</el-button>
                <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog title="选择文件" :visible.sync="chooseFileModal" width="500px"  :append-to-body="true">
            <el-form :model="fileInfo" status-icon :rules="filerules"  ref="fForm" label-width="100px" class="demo-uForm">
                <el-form-item label="风场" prop="FID">
                    <el-select v-model="fileInfo.FID" placeholder="请选择风场"  filterable  style="width:100%;">
                            <el-option v-for="item in fcList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="导入文件" prop="ICON">
                    <el-upload
                        ref="upload"
                        class="upload-demo"
                        drag
                        :multiple="false"
                        :limit="1"
                        accept=".xml"
                        action=""
                        :http-request="uploadFunS"
                        :before-upload="beforeAvatarUploadS"
                        :on-remove="removeFile">
                       
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                    </el-upload>
                </el-form-item>
                    
                <el-form-item>
                    <el-button type="primary" @click="submitFileForm()">提交</el-button>
                    <el-button @click="chooseFileModal = false">取消</el-button>
                </el-form-item>
            </el-form>

    </el-dialog>
    <el-dialog title="导入结果明细" :visible.sync="importResultModal" width="540px"  :append-to-body="true">
          <p>导入成功{{importResult.success}}，导入出错{{importResult.error}}条。</p>
          <el-table :data="importResult.table" stripe style="width: 100%" :height="dialogMaxHeigh"> 
                <el-table-column prop="ROWINDEX" label="行号"></el-table-column>
                <el-table-column prop="INFO" label="问题描述"></el-table-column>
          </el-table>
    </el-dialog>
    </div>
</template>
<script>
var h=document.body.clientHeight
export default {
  name: "PostManage",
  data() {
    var MACHINEID = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择设备"));
      } else {
        callback();
      }
    };

    var ICON = (rule, value, callback) => {
      if (value === 0) {
        callback(new Error("请选择文件"));
      } else {
        callback();
      }
    };

    var FID = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择风场"));
      } else {
        callback();
      }
    };

    var SYSTIME= (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择上次通讯时间"));
      } else {
        callback();
      }
    };

    var NUM=(rule, value, callback) => {
        if (value === '') {
          callback(new Error('数据不能为空'));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('只能是数字'));
        }else if(value<0){
            callback(new Error('不能小于0'));
        }else {
          callback();
        }
    };
    return {
        canDo:true,
        
        name:"",
        number:"",

        mtList:[],
        type:"",

        fcList:[],
        fc:"",
       
        checkLx:"",
        checkFc:"",
        sbList:[],

        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,

        selectDels:[],

        doTypeStr:"",
        doType:"",
        postModal:false,
        postInfo:{},
        postrules: {
            MACHINEID: [{ validator: MACHINEID, trigger: "blur" }],
            // SYSTIME: [{ validator: SYSTIME, trigger: "blur" }],

            // ACTIVEPOWER: [{ validator: NUM, trigger: "blur" }],
            // REACTIVEPOWER: [{ validator: NUM, trigger: "blur" }],
            // WINDSPEED: [{ validator: NUM, trigger: "blur" }],
            // WINDDIRECTION: [{ validator: NUM, trigger: "blur" }],
            // TEMPERATURE: [{ validator: NUM, trigger: "blur" }],
            
            // OTHERHOURS: [{ validator: NUM, trigger: "blur" }],
            // RUNHOURS: [{ validator: NUM, trigger: "blur" }],
            // ALARMHOURS: [{ validator: NUM, trigger: "blur" }],
            // LOSSPOWER: [{ validator: NUM, trigger: "blur" }],
            // LOSSPOWERHOURS: [{ validator: NUM, trigger: "blur" }],
            // POWER: [{ validator: NUM, trigger: "blur" }],
        },

        
        fileInfo:{},
        filerules: {
            ICON: [{ validator: ICON, trigger: "blur" }],
            FID: [{ validator: FID, trigger: "blur" }],
        },
        fileItem:null,
        chooseFileModal:false,
        importResultModal:false,
        importResult:{
          success:0,
          error:0,
          table:[]
        },
        dialogMaxHeigh:0,
    };
  },
  mounted() {
    this.dialogMaxHeigh = h -300
    this.loadItem=null
    this.getFCList()
    this.getMTypeList()
    this.getPostList()
  },
  methods: {
    handleSelectionChange(e){
        this.selectDels=e
        //console.log(e)
    },
    getFCList(){
        var params={}
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Factory/GetFactoryWithoutPage", md5_data)
            .then((response) => {
                this.canDo=true
                if (response.data.SUCCESS) {
                    this.fcList=response.data.DATA
                    if(this.fcList.length>0){
                        this.checkFc = this.fcList[0].SNOWID
                    }
                }
            })
    },
    fcChange(e){
        this.checkFc=e
        this.postInfo.MACHINEID=""
        this.getSbByFc()
    },
    lxChange(e){
        this.checkLx=e
        this.postInfo.MACHINEID=""
        this.getSbByFc()
    },
    //获取设备类型列表
    getMTypeList(){
        var params={
            PAGEINDEX:1,
            PAGESIZE:3000,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("MachineType/GetMachineTypeList", md5_data)
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.mtList=response.data.DATA
                } 
            })
    },
    getSbByFc(){
        var params={
            FACTORYID:this.checkFc,
            MACHINETYPEID:[this.checkLx],
            // PAGEINDEX:1,
            // PAGESIZE:10000,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Machine/GetMachine", md5_data)
            .then((response) => {
                if (response.data.SUCCESS && response.data.DATA.length>0) {
                    this.sbList=response.data.DATA
                } 
            }) 
    },
    downloadTemplet(){
      window.open("file/发电设备模板.xml")
    },
    getPostList(){
        this.showLoading("请稍后")
        var params={
            PAGEINDEX:this.PageIndex,
            PAGESIZE:this.PageSize,
            DISPLAYNAME:this.name,
            NUMBER:this.number,
            MACHINETYPEID:this.type,
            FACTORYID:this.fc,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Machine/GetElectricPowerInfo", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
        });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    addPost(){
        this.getSbByFc()
        this.postInfo={
            MACHINEID:"",
            // ACTIVEPOWER:"",
            // REACTIVEPOWER:"",
            // WINDSPEED:"",
            // WINDDIRECTION:"",
            // TEMPERATURE:"",
            // ISONLINE:"1",
            // SYSTIME:"",
            // OTHERHOURS:"",
            // RUNHOURS:"",
            // ALARMHOURS:"",
            // LOSSPOWER:"",
            // LOSSPOWERHOURS:"",
            // POWER:"",
            // STATUS:"1"
        }
        this.checkFc=""
        this.checkLx=""
        this.sbList=[]
        this.doTypeStr="添加发电设备"
        this.doType="1"
        this.postModal=true
    },
    sendAdd(){
        this.canDo=false
        var  d={
            MACHINEID:this.postInfo.MACHINEID,
            // ACTIVEPOWER:parseFloat(this.postInfo.ACTIVEPOWER),
            // REACTIVEPOWER:parseFloat(this.postInfo.REACTIVEPOWER),
            // WINDSPEED:parseFloat(this.postInfo.WINDSPEED),
            // WINDDIRECTION:parseFloat(this.postInfo.WINDDIRECTION),
            // TEMPERATURE:parseFloat(this.postInfo.TEMPERATURE),
            // ISONLINE:parseInt(this.postInfo.STATUS),
            // SYSTIME:this.comjs.getDateMinStrByDate(this.postInfo.SYSTIME),
            // OTHERHOURS:parseFloat(this.postInfo.OTHERHOURS),
            // RUNHOURS:parseFloat(this.postInfo.RUNHOURS),
            // ALARMHOURS:parseFloat(this.postInfo.ALARMHOURS),
            // LOSSPOWER:parseFloat(this.postInfo.LOSSPOWER),
            // LOSSPOWERHOURS:parseFloat(this.postInfo.LOSSPOWERHOURS),
            // POWER:parseFloat(this.postInfo.POWER),
            // STATUS:parseInt(this.postInfo.STATUS)
        }

        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(d, this.$store.state.token,this.$md5(this.comjs.sortAsc(d)).toUpperCase())
        this.$http.post("Machine/AddElectricPowerInfo", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    delPost(item){
        if(this.canDo){
            this.$confirm('确定删除选中的发电设备？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
                this.sendDel([item.SNOWID])
            }).catch(() => {
                this.$message.info("已取消")    
          });
        }

    },
    delIds(){
        if(this.selectDels.length==0){
            this.$message.error("请至少选择一项")
        }else{
            var ids=[]
            for(var i=0;i<this.selectDels.length;i++){
                ids.push(this.selectDels[i].SNOWID)
            }
            this.sendDel(ids)
        }
    },
    sendDel(ids){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({SNOWIDLIST:ids}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWIDLIST:ids})).toUpperCase())
        this.$http.post("Machine/DeleteElectricPowerInfoByList", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    submitPostForm() {
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    //console.log(this.postInfo)
                    if(this.doType=="1"){
                        this.sendAdd()
                    }else if(this.doType=="2"){
                        //this.sendEdit()
                    }
                } else {
                    return false;
                }
            });
        }
    },
    uploadTemplet(){
      this.chooseFileModal=true
      this.fileItem=null
      this.fileInfo={
        FID:"",
        ICON:0,
      }
    },
    removeFile(){
        this.fileInfo.ICON=0
        this.fileItem=null
    },
    submitFileForm() {
        if (this.canDo) {
            this.$refs.fForm.validate((valid) => {
                if (valid) {
                    this.uploadFunModal()
                } else {
                    return false;
                }
            });
        }
    },
    beforeAvatarUploadS(file) {
        const isExcel = file.type === 'text/xml' || file.type === 'application/xml' || file.type === 'application/vnd.ms-excel';

        if (!isExcel) {
          this.$message.error('只能上传xml文件!');
        }else{
            this.fileInfo.ICON=1
            this.fileItem=file
        }
        return isExcel;
    },
    uploadFunS(file){
        //console.log(file)
    },
    uploadFunModal(){
      this.showLoading("请稍后...")
      var fd = new FormData();
      var fname=this.fileItem.name
      fd.append("file", this.fileItem);
      this.$upload.post("Machine/UploadData?factoryID="+this.fileInfo.FID+"&FileName="+fname, fd)
        .then((response) => {
          //console.log(response)
         this.hideLoading()
          if (response.data.SUCCESS) {
             this.hideLoading()
             this.$message.success("导入完成")
             this.$refs.upload.clearFiles()
             this.chooseFileModal=false
             this.getPostList()
             this.importResult={
                success:response.data.DATA.SUCCESSCOUNT,
                error:response.data.DATA.ERRORCOUNT,
                table:response.data.DATA.ERRORLIST
              }
              this.importResultModal=true
          }else{
             this.hideLoading()
             this.$message.error("上传失败")
          }
        })
        .catch((error) => {
            this.hideLoading()
        });
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style >
</style>