<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">工作分解</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                
                <el-input v-model="title" placeholder="请输入标题" class="iw" ></el-input>
                <el-input v-model="content" placeholder="请输入内容" class="iw" ></el-input>
                <el-date-picker v-model="sj" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
                <el-button type="primary" @click="searchPost" style="margin-left:20px">查询</el-button>
                <el-button type="success" @click="addPost" class="fr">添加</el-button>
                
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column prop="TITLE" label="标题"></el-table-column>
                    <el-table-column prop="CONTENT" label="内容"></el-table-column>
                    <el-table-column prop="CREATETIME" label="创建日期"></el-table-column>
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                        <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button>
                        <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <!-- <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination> -->
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="500px">
            <el-form :model="postInfo" status-icon :rules="postrules"  ref="uForm" label-width="100px" class="demo-uForm">
                <el-form-item label="标题" prop="TITLE">
                    <el-input v-model="postInfo.TITLE" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="内容" prop="CONTENT">
                    <el-input v-model="postInfo.CONTENT" autocomplete="off"  type="textarea" class="gwzz"></el-input>
                </el-form-item>
                <el-form-item>
                <el-button type="primary" @click="submitPostForm()">提交</el-button>
                <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
export default {
  name: "PostManage",
  data() {
    var TITLE = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入标题"));
      } else {
        callback();
      }
    };
    var CONTENT = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入内容"));
      } else {
        callback();
      }
    };
    return {
        canDo:true,
        
        title:"",
        content:"",
        sj:"",

        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,


        doTypeStr:"",
        doType:"",
        postModal:false,
        postInfo:{},
        postrules: {
            TITLE: [{ validator: TITLE, trigger: "blur" }],
            CONTENT: [{ validator: CONTENT, trigger: "blur" }],
        },
    };
  },
  mounted() {
    this.loadItem=null
    this.getPostList()
  },
  methods: {
    getPostList(){
        this.showLoading("请稍后")
        var st=""
        var et=""
        if(this.sj.length==2){
            st=this.comjs.getDateStrByDate(this.sj[0])
            et=this.comjs.getDateStrByDate(this.sj[1])
        }
        var params={
            // PAGEINDEX:this.PageIndex,
            // PAGESIZE:this.PageSize,
            TITLE:this.title,
            CONTENT:this.content,
            STARTTIME:st,
            ENDTIME:et,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("WorkSplit/GetWorkSplit", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
        });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    addPost(){
        this.postInfo={
            TITLE:"",
            CONTENT:"",
        }
        this.doTypeStr="添加工作分解"
        this.doType="1"
        this.postModal=true
    },
    sendAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("WorkSplit/CreateNewWorkSplit", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    editPost(item){
        this.postInfo={
            SNOWID:item.SNOWID,
            CREATETIME:item.CREATETIME,
            ISDELETE:item.ISDELETE,
            TITLE:item.TITLE,
            CONTENT:item.CONTENT,
            DELETETIME:item.DELETETIME,
        }
        this.doTypeStr="修改工作分解"
        this.doType="2"
        this.postModal=true
    },
    sendEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("WorkSplit/EditWorkSplit", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.postModal=false
                    this.getPostList();
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    delPost(item){
        if(this.canDo){
            this.$confirm('确定删除工作分解：'+item.TITLE+'？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
            this.sendDel(item.SNOWID)
            }).catch(() => {
            this.$message.info("已取消")    
          });
        }

    },
    sendDel(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({SNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:id})).toUpperCase())
        this.$http.post("WorkSplit/DeleteWorkSplit", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    submitPostForm() {
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    if(this.doType=="1"){
                        this.sendAdd()
                    }else if(this.doType=="2"){
                        this.sendEdit()
                    }
                } else {
                    return false;
                }
            });
        }
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style >
 .gwzz .el-textarea__inner{height:100px!important;}
</style>