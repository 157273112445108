<template>
  <div class="views-main">
    <div class="views-main-title">
      <p class="txt">模块管理</p>
    </div>
    <div class="tableBox" style="top:50px;">
      <div class="views-main-search">
        <el-select v-model="factoryId" placeholder="风场"  filterable  clearable class="iwu">
                <el-option v-for="item in factoryList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
        </el-select>
        <el-input type="text" placeholder="请输入模块名称" v-model="moduleName" class="iw"/>
        <el-select v-model="checkNumber" clearable placeholder="请选择定检次数">
          <el-option label="第一次定检" value="1"></el-option>
          <el-option label="第二次定检" value="2"></el-option>
          <el-option label="第三次定检" value="3"></el-option>
          <el-option label="第四次定检" value="4"></el-option>
          <el-option label="第五次定检" value="5"></el-option>
          <el-option label="第六次定检" value="6"></el-option>
        </el-select>
        <el-button style="margin-left: 20px" type="primary" @click="searchPost">查询</el-button>
        <el-button type="success" @click="addPost" class="fr">添加</el-button>
      </div>
      <div class="views-main-body">
        <el-table :data="postList" stripe style="width: 100%">
              <el-table-column prop="NAME" label="名称"></el-table-column>
              <el-table-column prop="FACTORYNAME" label="风场"></el-table-column>
              <el-table-column prop="CHECKTIME" label="第几次定检">
                <template slot-scope="scope">
                    <span v-if="scope.row.CHECKTIME ===1">一</span>
                    <span v-else-if="scope.row.CHECKTIME ===2">二</span>
                    <span v-else-if="scope.row.CHECKTIME ===3">三</span>
                    <span v-else-if="scope.row.CHECKTIME ===4">四</span>
                    <span v-else-if="scope.row.CHECKTIME ===5">五</span>
                    <span v-else-if="scope.row.CHECKTIME ===6">六</span>
                </template>  
              </el-table-column>
              <el-table-column prop="CREATETIME" label="创建日期"></el-table-column>
              <el-table-column label="操作" width="150">
                  <template slot-scope="scope">
                    <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button>
                    <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
                    <el-button @click="checkPost(scope.row)"  type="text" size="small" style="color:#409EFF">检查项</el-button>
                  </template>
              </el-table-column>
        </el-table>
        <el-pagination background layout="prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
      </div>
    </div>
    <el-dialog :title="moduleDialogStr" :visible.sync="moduleDialogFlag" width="500px">
      <el-form :model="moduleDialogInfo" status-icon :rules="moduleDialogrules" ref="uForm" label-width="120px">
        <el-form-item label="名称" prop="NAME">
          <el-input v-model="moduleDialogInfo.NAME" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="风场" prop="FACTORYSNOWID">
            <el-select v-model="moduleDialogInfo.FACTORYSNOWID" placeholder="请选择风场" filterable  style="width:100%;">
                    <el-option v-for="item in factoryList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="定检次数" prop="CHECKTIME">
          <el-select v-model="moduleDialogInfo.CHECKTIME" clearable placeholder="请选择定检次数" style="width: 100%">
            <el-option label="第一次定检" value="1"></el-option>
            <el-option label="第二次定检" value="2"></el-option>
            <el-option label="第三次定检" value="3"></el-option>
            <el-option label="第四次定检" value="4"></el-option>
            <el-option label="第五次定检" value="5"></el-option>
            <el-option label="第六次定检" value="6"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitPostForm()">提交</el-button>
          <el-button @click="moduleDialogrules = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog title="绑定检查项" :visible.sync="bindModal" width="1200px" :close-on-click-modal="false" top="50px">
        <div class="bindBoxItem">
            <div class="title">
                <div class="str" style="color:#409EFF">已绑定检查项</div>
                <el-button type="danger" @click="unBindAllCheckItems" size="mini" class="fr" style="margin-left: 20px">全部解绑</el-button>
                <el-button type="danger" @click="unBindCheckItems" size="mini" class="fr" >批量解绑</el-button> 
                
            </div>
            <div class="bindCheckItemBox">
              <div class="checkItemOut" v-if="bindList.length>0">
                  <div v-for="item in bindList"  :key="item.SNOWID" class="checkItemTag" :class="item.on?'checkItemOnA':''"  @click="clickBindCheckItem(item.SNOWID)" :title="item.BZ">{{ item.CODE }}</div>
              </div>
              <div class="checkItemOut" v-else>
                  <p class="nothaveCheckItem">此模板还未添加检查项，请在下方添加</p>
              </div>
            </div>
        </div>
        <div class="bindBoxItem">
            <div class="title" style="height:36px">
                <div class="str" style="color:#67C23A">新增绑定</div>
                <el-input v-model="searchCheckItemCode" placeholder="请输入检查项编号" class="ics fl" style="margin-left: 20px;" size="mini"></el-input>
                <el-button  type="primary" @click="searchCheckItemByCode" class="fl" size="mini" >查询检查项</el-button>
                <div style="height:30px;float:left;line-height: 30px;margin-left: 20px;">
                  <span v-if="searchList.length>0">共{{searchList.length}}条结果(单次查询最多显示100条)，其中{{hasBindCount  }}条已绑定　</span>
                  <el-checkbox v-model="showSearchCheckItemHas">显示已绑定</el-checkbox>
                </div>
                <el-button type="success" @click="bindSearchAll" class="fr" size="mini" style="margin-left: 20px">全部绑定</el-button>
                <el-button type="success" @click="bindSearchOn" class="fr" size="mini" style="margin-left: 20px">添加绑定</el-button>
                
            </div>
            <div class="bindCheckItemBox">
                <div class="checkItemOut" v-if="searchList.length>0">
                  <div v-for="item in searchList"  :key="item.SNOWID" class="checkItemTag" :class="item.has?'checkItemOnC':  item.on?'checkItemOnB':''"  @click="clickSearchCheckItem(item.SNOWID,item.has)" v-show="!item.has || item.has && showSearchCheckItemHas" :title="item.BZ">{{ item.CODE }} <span v-if="item.has">(已绑定)</span> </div>
                </div>
                <div class="checkItemOut" v-else>
                  <p class="nothaveCheckItem">输入编号查询检查项，选中检查项后进行添加</p>
                </div>
            </div>
        </div>
    </el-dialog>
  </div>
</template>
<script>
var h=document.body.clientHeight
export default {
  name: "MKGL",
  data() {
    return {
      canDo:true,
      factoryList:[],

      factoryId:'',
      checkNumber: '',
      moduleName: '',


      postList: [],
      PageIndex: 1,
      PageSize: 10,
      Total: 0,

      doType:"1",
      moduleDialogInfo: {},
      moduleDialogrules: {
        NAME: [{ required: true, message: '请输入模块名称', trigger: 'blur' },],
        FACTORYSNOWID: [{ required: true, message: '请选择所属风场', trigger: 'blur' },],
        CHECKTIME: [{ required: true, message: '请选择所属定检次数', trigger: 'blur' },],
      },
      moduleDialogStr: '',
      moduleDialogFlag: false,



      searchCheckItemCode:"",
      nowModule:null,
      bindModal:false,
      bindList:[],
      searchList:[],
      hasBindCount:0,
      showSearchCheckItemHas:false,
    };
  },
  mounted() {
    this.getFactoryList()
    this.getPostList()
  },
  methods: {
    getFactoryList(){
      var md5_data = this.comjs.requestDataEncrypt({}, this.$store.state.token, this.$md5(this.comjs.sortAsc({})).toUpperCase())
      this.$http.post("Factory/GetFactoryWithoutPage", md5_data)
          .then((response) => {
            if (response.data.SUCCESS) {
              this.factoryList = response.data.DATA
            } 
          })
    },
    getPostList(){
      this.showLoading("请稍后")
      var params={
        PAGEINDEX:this.PageIndex,
        PAGESIZE:this.PageSize,
        FactorySnowID:this.factoryId,
        Name:this.moduleName,
        CheckTimes:parseInt(this.checkNumber),
      }
      var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
      this.$http.post("CheckItemModule/GetCheckModuleList", md5_data)
          .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
              this.postList=response.data.DATA
              this.Total=response.data.TOTALCOUNT
            } else {
              this.postList=[]
              this.Total=0
              this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
          })
          .catch((error) => {
            this.hideLoading()
            this.postList=[]
            this.Total=0
          });
    },
    searchPost(){
      this.PageIndex=1
      this.getPostList()
    },
    PGChange(e){
      this.PageIndex=e
      this.getPostList()
    },
    addPost(){
        this.moduleDialogInfo={
            NAME:"",
            FACTORYSNOWID:"",
            CHECKTIME:"1",
        }
        this.moduleDialogStr="添加模板"
        this.doType="1"
        this.moduleDialogFlag=true
    },
    sendAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.moduleDialogInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.moduleDialogInfo)).toUpperCase())
        this.$http.post("CheckItemModule/CreateCheckModule", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.moduleDialogFlag=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    editPost(item){
        this.moduleDialogInfo={
            SNOWID:item.SNOWID,
            NAME:item.NAME,
            FACTORYSNOWID:item.FACTORYSNOWID,
            CHECKTIME:item.CHECKTIME.toString(),
        }
        this.moduleDialogStr="修改定检模板"
        this.doType="2"
        this.moduleDialogFlag=true
    },
    sendEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.moduleDialogInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.moduleDialogInfo)).toUpperCase())
        this.$http.post("CheckItemModule/EditCheckModule", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.moduleDialogFlag=false
                    this.getPostList();
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    submitPostForm() {
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    if(this.doType=="1"){
                        this.sendAdd()
                    }else if(this.doType=="2"){
                        this.sendEdit()
                    }
                } else {
                    return false;
                }
            });
        }
    },
    delPost(item){
        if(this.canDo){
            this.$confirm('确定删除模板'+item.NAME+'？', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
              }).then(() => {
              this.sendDel(item.SNOWID)
              }).catch(() => {
              this.$message.info("已取消")    
            });
        }

    },
    sendDel(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({SNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:id})).toUpperCase())
        this.$http.post("CheckItemModule/DeleteCheckModule", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },

    //检查项
    checkPost(item){
      this.nowModule=item
      this.getBindListById()
      this.searchCheckItemCode=""
      this.bindList=[]
      this.searchList=[]
      this.bindModal=true

    },
    //查询已绑定检查项
    getBindListById(){
      var params={
        SNOWID:this.nowModule.SNOWID
      }
      var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
      this.$http.post("CheckItemModule/GetModulesItemList", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    var list =response.data.DATA
                    var nl=[]
                    for(var i=0;i<list.length;i++){
                      var d=list[i]
                      d.on=false
                      nl.push(d)
                    }
                    this.bindList=nl
                    this.refSearchList()
                }
            })
    },

    //点击已绑定检查项
    clickBindCheckItem(id){
      var nl=[]
      for(var i=0;i<this.bindList.length;i++){
        var d=this.bindList[i]
        if(d.SNOWID == id){
          d.on = !d.on
        }
        nl.push(d)
      }
      this.bindList =nl
    },
    //解绑检查项
    unBindCheckItems(){
      var ids=[]
      var c=0
      for(var i=0;i<this.bindList.length;i++){
        var d=this.bindList[i]
        if(!d.on){
          ids.push(d.SNOWID)
        }else{
          c++
        }
      }

      if(c>0){
        this.$confirm('确定解绑已选择的检查项？', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
              }).then(() => {
                this.sendBindCheckItems(ids)
              }).catch(() => {
                this.$message.info("已取消")    
            });
      }else{
        this.$message.error("请选择要解绑的检查项")
      }
    },

    unBindAllCheckItems(){
      if(this.bindList.length>0){
        this.$confirm('确定解绑全部的检查项？', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
        }).then(() => {
          var unbindList=[]
          for(var i=0;i<this.bindList.length;i++){
            unbindList.push(this.bindList[i].SNOWID)
          }
          this.sendBindCheckItems([])
        }).catch(() => {
          this.$message.info("已取消")    
        });
      }else{
        this.$message.error("该模板还未绑定检查项")
      }
    },

    //绑定前查询
    searchCheckItemByCode(){
      if(this.searchCheckItemCode ==""){
        this.$message.error("请输入编号再查询")
        return 
      }
      this.canDo=false
        this.showLoading("请稍后")
        var params={
          CODE:this.searchCheckItemCode,
          TYPE:"4",
          PAGEINDEX:1,
          PAGESIZE:100,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("CheckItem/GetCheckItemList", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    var list =response.data.DATA
                    var nl=[]
                    for(var i=0;i<list.length;i++){
                      var d=list[i]
                      d.on=false
                      d.has=false
                      nl.push(d)
                    }
                    this.searchList=nl
                    this.refSearchList()
                   
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    //设置查询结果中含有已绑定的检查项
    refSearchList(){
      this.hasBindCount=0
      var s=this.searchList
      var b=this.bindList
      var nl=[]
      for(var i=0;i<s.length;i++){
        var d=s[i]
        var has=false
        for(var j=0;j<b.length;j++){
           if(d.SNOWID === b[j].SNOWID){
              has=true
              this.hasBindCount++
              break;
           }
        }
        d.has=has
        nl.push(d)
      }
      this.searchList=nl
    },
    //点击查询的检查项
    clickSearchCheckItem(id,has){
      if(!has){
        var nl=[]
        for(var i=0;i<this.searchList.length;i++){
          var d=this.searchList[i]
          if(d.SNOWID == id){
            d.on = !d.on
          }
          nl.push(d)
        }
        this.searchList=nl
      }
    },
    //添加查询中已选择的检查项
    bindSearchOn(){
      var ids=[]
      for(var i=0;i<this.searchList.length;i++){
        var d=this.searchList[i]
        if(d.on && !d.has){
          ids.push(d.SNOWID)
        }
      }

      if(ids.length>0){
        for(var j=0;j<this.bindList.length;j++){
          ids.push(this.bindList[j].SNOWID)
        }
        this.sendBindCheckItems(ids)
      }else{
        this.$message.error("请选择要绑定的检查项")
      }
    },
    //添加查询中的全部检查项
    bindSearchAll(){
      var ids=[]
      var c=0
      for(var i=0;i<this.searchList.length;i++){
        var d=this.searchList[i]
        if(!d.has){
          ids.push(d.SNOWID)
        }else{
          c++
        }
      }

      if(c>0){
        for(var j=0;j<this.bindList.length;j++){
          ids.push(this.bindList[j].SNOWID)
        }
        this.sendBindCheckItems(ids)
      }else{
        this.$message.error("当前查询结果暂无可绑定的检查项")
      }
    },

    //绑定检查项
    sendBindCheckItems(list){
        this.canDo=false
        this.showLoading("请稍后")
        var params={
          CheckModuleSnowID:this.nowModule.SNOWID,
          CheckItemSnowIDs:list,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("CheckItemModule/ModulesBindItemList", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("操作完成")
                    this.getBindListById()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style scoped>
.bindBoxItem{width: 100%;overflow: hidden;background-color: #F8F8F8;border-radius: 6px;margin-top: 20px;}
.bindBoxItem .title{height: 30px;overflow: hidden;margin: 8px 20px;}
.bindBoxItem .title .str{font-size: 16px;float:left;font-weight: 400;margin-top: 2px;}
.bindBoxItem .bindCheckItemBox{background-color: #FFFFFF;border-radius: 3px;margin: 10px 20px; padding: 10px 20px;max-height: 260px;overflow-x: hidden;overflow-y: auto;}
.checkItemTag{height: 30px;line-height: 30px;display: inline-block;padding: 0 12px;background-color: #C0C4CC;color: #FFFFFF;font-size: 12px;cursor: pointer;border-radius: 3px;margin-bottom: 10px;margin-right: 10px;}

.checkItemOnA{background-color: #409EFF!important;}
.checkItemOnB{background-color: #67C23A!important;}
.checkItemOnC{background-color: #F56C6C!important;cursor:not-allowed!important;}
</style>