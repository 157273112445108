<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">工时统计</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                <el-select v-model="uid" placeholder="请选择人员" class="iw" filterable clearable>
                        <el-option v-for="item in userList" :key="item.SNOWID" :label="item.FULLNAME" :value="item.SNOWID" ></el-option>
                </el-select>
                <el-select v-model="pid" placeholder="请选择岗位" class="iw" filterable clearable>
                        <el-option v-for="item in positiontList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                </el-select>
                <el-date-picker v-model="workdatetime" type="date" placeholder="选择日期" class="iw"> </el-date-picker>
                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-button type="warning" @click="exportTable">导出</el-button>
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column prop="UserName" label="姓名"></el-table-column>
                    <el-table-column prop="PositionName" label="岗位"></el-table-column>
                    <el-table-column prop="YearCount" label="年工时(小时)"></el-table-column>
                    <el-table-column prop="MonthCount" label="月工时(小时)"></el-table-column>
                    <el-table-column prop="WeekCount" label="周工时(小时)"></el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  name: "worktimeCount",
  data() {
   
    return {
        canDo:true,
        
        workdatetime:null,
        positiontList:[],
        pid:"",
        userList:[],
        uid:"",

        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,


    
    };
  },
  mounted() {
    this.loadItem=null
    this.getPositionList()
    this.getUserList()
    this.getPostList()
  },
  methods: {
    getPositionList(){
        var md5_data =this.comjs.requestDataEncrypt({}, this.$store.state.token,this.$md5(this.comjs.sortAsc({})).toUpperCase())
        this.$http.post("PositonManage/GetPositionListWithoutPage", md5_data)
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.positiontList=response.data.DATA  
                } 
            })
    },
    getUserList(){
        var md5_data =this.comjs.requestDataEncrypt({ORGANIZATIONID:""}, this.$store.state.token,this.$md5(this.comjs.sortAsc({ORGANIZATIONID:""})).toUpperCase())
        this.$http.post("UserManage/GetUserListWithoutPage", md5_data)
            .then((response) => {
                //console.log(response)
                if (response.data.SUCCESS) {
                    var list =response.data.DATA
                    var nl=[]
                    for(var i=0;i<list.length;i++){
                        var d=list[i]
                        d.FULLNAME=d.TRUENAME+"("+d.LOGINNAME+")"
                    }
                    this.userList =response.data.DATA
                } 
            })
    },
    getPostList(){
        this.showLoading("请稍后")
        var dt=''
        if(this.workdatetime){
            dt=this.comjs.getDateMinStrByDate(this.workdatetime)
        }
        var params={
            PAGEINDEX:this.PageIndex,
            PAGESIZE:this.PageSize,
            StartTime:dt,
            PosionSnowID:this.pid,
            USERSNOWID:this.uid,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("ExportExcel/GetExportYearWorkCountUserList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
        });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    exportTable(){
        if(this.canDo){
            this.showLoading("请稍后")
        var dt=''
        if(this.workdatetime){
            dt=this.comjs.getDateMinStrByDate(this.workdatetime)
        }
        var params = {
            StartTime:dt,
            PosionSnowID:this.pid,
            USERSNOWID:this.uid,
        }
        this.canDo = false
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$download.post("ExportExcel/ExportYearWorkCountUserList", md5_data,{ responseType: 'blob',timeout:300000})
            .then((resJson) => {
                this.hideLoading()
                this.canDo = true
                if (resJson) {
                    var name = "工时统计表"
                    let blob = new Blob([resJson.data], { type: 'application/octet-stream' })
                    if (window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveBlob(blob, name + ".xls")
                    } else {
                        let downloadElement = document.createElement('a')
                        let href = window.URL.createObjectURL(blob)
                        downloadElement.href = href
                        downloadElement.download = name + ".xls"
                        document.body.appendChild(downloadElement)
                        //此写法兼容火狐
                        let evt = document.createEvent('MouseEvents')
                        evt.initEvent('click', false, false)
                        downloadElement.dispatchEvent(evt)
                        document.body.removeChild(downloadElement)
                    }
                    this.$message.success('操作成功!')
                } else {
                    this.$message.error("数据导出失败")
                }
            })
            .catch((error) => {
                this.errorFlag(JSON.stringify(error))
                this.hideLoading()
                this.canDo = true
            });
        }

    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style >
</style>