<template>
  <div class="content">
        <el-form :model="postInfo" status-icon :rules="postrules"  ref="uForm" label-width="300px" class="demo-uForm">
                <el-form-item label="是否必须上传核酸报告">
                  <el-radio v-model="postInfo.ISNEEDHS" label="1">是</el-radio>
                  <el-radio v-model="postInfo.ISNEEDHS" label="0">否</el-radio>
                </el-form-item>
                <el-form-item label="是否预警核酸照片">
                  <el-radio v-model="postInfo.ISWARNINGHSNULL" label="1">是</el-radio>
                  <el-radio v-model="postInfo.ISWARNINGHSNULL" label="0">否</el-radio>
                </el-form-item>
                <el-form-item label="核酸未上传预警间隔天数" prop="HSINTERVALDAYS">
                  <el-input v-model="postInfo.HSINTERVALDAYS" autocomplete="off" placeholder="空为无限制"></el-input>
                </el-form-item>
                <el-form-item label="核酸有效期天数预警" prop="HSCHECKDAYS">
                  <el-input v-model="postInfo.HSCHECKDAYS" autocomplete="off" placeholder="空为无限制"></el-input>
                </el-form-item>

                <el-form-item label="是否必须上传健康码">
                  <el-radio v-model="postInfo.ISNEEDJKM" label="1">是</el-radio>
                  <el-radio v-model="postInfo.ISNEEDJKM" label="0">否</el-radio>
                </el-form-item>
                <el-form-item label="是否预警健康码照片">
                  <el-radio v-model="postInfo.ISWARNINGJKMNULL" label="1">是</el-radio>
                  <el-radio v-model="postInfo.ISWARNINGJKMNULL" label="0">否</el-radio>
                </el-form-item>
                <el-form-item label="健康码未上传预警间隔天数" prop="JKMINTERVALDAYS">
                  <el-input v-model="postInfo.JKMINTERVALDAYS" autocomplete="off" placeholder="空为无限制"></el-input>
                </el-form-item>
                <el-form-item label="健康码有效期天数预警" prop="JKMCHECKDAYS">
                  <el-input v-model="postInfo.JKMCHECKDAYS" autocomplete="off" placeholder="空为无限制"></el-input>
                </el-form-item>


                <el-form-item label="是否必须上传行程码">
                  <el-radio v-model="postInfo.ISNEEDXCM" label="1">是</el-radio>
                  <el-radio v-model="postInfo.ISNEEDXCM" label="0">否</el-radio>
                </el-form-item>
                <el-form-item label="是否预警行程码照片">
                  <el-radio v-model="postInfo.ISWARNINGXCMNULL" label="1">是</el-radio>
                  <el-radio v-model="postInfo.ISWARNINGXCMNULL" label="0">否</el-radio>
                </el-form-item>
                <el-form-item label="行程码未上传预警间隔天数" prop="XCMINTERVALDAYS">
                  <el-input v-model="postInfo.XCMINTERVALDAYS" autocomplete="off" placeholder="空为无限制"></el-input>
                </el-form-item>
                <el-form-item label="行程码有效期天数预警" prop="XCMCHECKDAYS">
                  <el-input v-model="postInfo.XCMCHECKDAYS" autocomplete="off" placeholder="空为无限制"></el-input>
                </el-form-item>
                <el-form-item label="行程码是否开启多地预警">
                  <el-radio v-model="postInfo.XCMMANYPLACESWARNING" label="1">是</el-radio>
                  <el-radio v-model="postInfo.XCMMANYPLACESWARNING" label="0">否</el-radio>
                </el-form-item>

                <el-form-item>
                  <el-button type="primary" @click="submitPostForm()">提交</el-button>
                  <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
  </div>
</template>
<style scoped> 
.rowItem{width: 50%;float:left;overflow: hidden;font-size: 15px;}
</style>
<script>
export default {
  name: 'MRFDL',
  props: {
    // snowid: {
    //   type:String,
    //   default:""
    // },
  },
  data () {
    var NUM=(rule, value, callback) => {
         if(value != '' && typeof value != 'number' && isNaN(value)){
             callback(new Error('天数只能是数字'));
        }else if(value != '' && value<=0){
            callback(new Error('只能输入大于0的数字'));
        }else {
          callback();
        }
    };
    
    

      
      return {
        canDo:true,
        postInfo:{},
        postrules: {
            HSINTERVALDAYS: [{ validator: NUM, trigger: "blur" }],
            JKMINTERVALDAYS: [{ validator: NUM, trigger: "blur" }],
            XCMINTERVALDAYS: [{ validator: NUM, trigger: "blur" }],
            HSCHECKDAYS: [{ validator: NUM, trigger: "blur" }],
            JKMCHECKDAYS: [{ validator: NUM, trigger: "blur" }],
            XCMCHECKDAYS: [{ validator: NUM, trigger: "blur" }],
        },
      }
  },
  mounted() {
      this.loadItem=null
      this.getPostList()
  },
  methods:{
    
    
    
    
    getPostList(){
        this.showLoading("请稍后")
        var params={}
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Quarantine/GetQuarantineConfig", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postInfo=response.data.DATA
            } 
            })
    },
    sendSet(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("Quarantine/SetQuarantineConfig", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已保存")
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },

    submitPostForm() {
        if (this.canDo) {
            //console.log(this.postInfo)
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    this.sendSet()
                } else {
                    return false;
                }
            });
        }
    },
  }
}
</script>

