<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">配件供应商维斯塔斯供货价格明细表</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                <el-input v-model="name" placeholder="请输入名称" class="iww" ></el-input>
                <el-input v-model="code" placeholder="请输入编号" class="iww" ></el-input>     
                <el-select v-model="gys" placeholder="供应商"  filterable  clearable class="iww">
                        <el-option v-for="item in gysList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                </el-select>
                <el-button type="primary" @click="searchPost">查询</el-button>        
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column prop="SUPPLIERNAME" label="供应商"></el-table-column>
                    <el-table-column prop="CNNAME" label="货品中文名称"></el-table-column>
                    <el-table-column prop="ENNAME" label="货品英文名"></el-table-column>
                    <el-table-column prop="CODE" label="物料号"></el-table-column>
                    <el-table-column prop="GGMODEL" label="规格型号"></el-table-column>
                    <el-table-column prop="UNIT" label="单位"></el-table-column>
                    <el-table-column prop="STANDARDPRICE" label="单价（元）"></el-table-column>
                    <el-table-column prop="BZ" label="备注"></el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  name: "PostManage",
  data() {
    return {
        canDo:true,
        gysList:[],
        gys:"",
        name:"",
        code:"",
       

        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,

    };
  },
  mounted() {
    this.loadItem=null
    this.getGys()
    this.getPostList()
  },
  methods: {
    getGys(){
        var params={
            PAGEINDEX:1,
            PAGESIZE:500,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Storage/GetStorageSupplierList", md5_data)
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.gysList=response.data.DATA
                } 
            })
    },
    getPostList(){
        this.showLoading("请稍后")
        var params={
            PAGEINDEX:this.PageIndex,
            PAGESIZE:this.PageSize,
            CNNAME:this.name,
            CODE:this.code,
            SUPPLIERID:this.gys,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Thing/GetThingList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
        });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },

    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style >
</style>