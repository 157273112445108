
<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">流程管理</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                <el-input v-model="name" placeholder="请输入名称" class="iw" ></el-input>
                <el-input v-model="alias" placeholder="请输入别名" class="iw" ></el-input>
                <el-select v-model="visibility" placeholder="是否可见"  clearable filterable  class="iw">
                        <el-option label="全部可见状态" value="" ></el-option>
                        <el-option label="可见" value="1" ></el-option>
                        <el-option label="不可见" value="0" ></el-option>
                </el-select>
                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-button type="success" @click="addPost" class="fr">添加</el-button>
                <el-upload ref="upload" class="wfupload-demo" drag :multiple="false" :limit="1" accept=".json" action="" :http-request="uploadFun">
                     <el-button type="primary" class="fr">导入</el-button>
                </el-upload>
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column prop="NAME" label="名称"></el-table-column>
                    <el-table-column prop="ALIAS" label="别名"></el-table-column>
                    <el-table-column prop="VISIBILITY" label="是否可见">
                        <template slot-scope="scope">
                            <span v-if="scope.row.VISIBILITY" style="color:#67C23A">可见</span>
                            <span v-else style="color:#F56C6C">不可见</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="DESCRIBE" label="描述"></el-table-column>
                    <el-table-column prop="CREATED" label="创建日期"></el-table-column>
                    <el-table-column label="操作" width="150">
                        <template slot-scope="scope">
                        <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C">查看</el-button>
                        <el-button @click="exportPost(scope.row)" type="text" size="small" style="color:#409EFF">导出</el-button>
                        <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
    </div>
</template>
<script>
import { saveAs } from 'file-saver';
export default {
  name: "PostManage",
  data() {
    return {
        canDo:true,
        
        name:"",
        alias:"",
        visibility:"1",

        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,


       
    };
  },
  mounted() {
    this.loadItem=null
   this.getPostList()
  },
  methods: {
    getPostList(){
        this.showLoading("请稍后")
        var vy=null
        if(this.visibility=="1"){
            vy = true
        }else  if(this.visibility=="0"){
            vy = false
        }

        var params={
            PAGEINDEX:this.PageIndex,
            PAGESIZE:this.PageSize,
            NAME:this.name,
            ALIAS:this.alias,
            VISIBILITY:vy,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Model/GetModelList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
        });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    addPost(){
        window.open("/statics/myflow-master/index.html?token="+this.$store.state.token+"&type=1")
    },
    editPost(item){
      window.open("/statics/myflow-master/index.html?token="+this.$store.state.token+"&type=2&fid="+item.SNOWID)
    },
    exportPost(item){
        var obj={
            NAME:item.NAME,
            DESCRIBE:item.DESCRIBE,
            ALIAS:item.ALIAS,
            ACTIVITYMODELS:item.ACTIVITYMODELS,
            ACTIVITYATTTEMPLATEMODELS:item.ACTIVITYATTTEMPLATEMODELS,
            ACTIVITYTRANSITIONMODELS:item.ACTIVITYTRANSITIONMODELS,
            ACTIVITYBACKMODELS:item.ACTIVITYBACKMODELS,

        }
        var blob = new Blob([JSON.stringify(item)], { type: "" });
        saveAs(blob, item.NAME+".json");
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    uploadFun(f){
        //var fd = new FormData();
        //console.log(f)
        var _this=this
        const reader = new FileReader();
        reader.readAsText(f.file, "UTF-8");
        reader.onload = function (event) {
           var jsonData=eval("(" + event.target.result + ")") 
           //console.log(jsonData)
           _this.createModelByJson(jsonData)
        }
    },
    createModelByJson(data){
        if(this.canDo){
            this.canDo=false
            this.showLoading("请稍后")
            var md5_data =this.comjs.requestDataEncrypt(data, this.$store.state.token,this.$md5(this.comjs.sortAsc(data)).toUpperCase())
            this.$http.post("Model/CreateNewModel", md5_data)
                .then((response) => {
                    this.canDo=true
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.$message.success("导入成功")
                        this.getPostList()
                    } else {
                        this.errorRequest(response.data.DATA,response.data.MSG,true)
                    }
                })
                .catch((error) => {
                    this.$message.error("请求出错")
                    this.hideLoading()
                    this.canDo=true   
                });
        }
    },
    delPost(item){
        if(this.canDo){
            this.$confirm('确定删除流程：'+item.NAME+'？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
            this.sendDel(item.SNOWID)
            }).catch(() => {
            this.$message.info("已取消")    
          });
        }

    },
    sendDel(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({SNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:id})).toUpperCase())
        this.$http.post("Model/DeleteModel", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style>
.wfupload-demo{overflow: hidden;float:right;}
.wfupload-demo .el-upload-dragger{width: 70px!important;height: 40px!important;border:0!important;}
.wfupload-demo .el-upload-list{display: none;}
</style>
