<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">风场物资库存</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                
                <el-input v-model="cname" placeholder="货物中文名" class="iw" ></el-input>
                <el-input v-model="ename" placeholder="货物英文名" class="iw" ></el-input>
                <el-select v-model="ckid" placeholder="请选择仓库" filterable clearable class="iw">
                        <el-option v-for="item in ckList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                </el-select>
                <el-select v-model="gysid" placeholder="请选择供应商" filterable clearable class="iw">
                        <el-option v-for="item in gysList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                </el-select> 
                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-button type="success" @click="addPost" class="fr" v-if="wzhpfl!=''">添加</el-button>
                
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column prop="CNNAME" label="货物中文名"></el-table-column>
                    <el-table-column prop="ENNAME" label="货物英文名"></el-table-column>
                    <el-table-column prop="CODE" label="物料号"></el-table-column>
                    <el-table-column prop="GGMODEL" label="规格型号"></el-table-column>
                    <el-table-column prop="UNIT" label="单位"></el-table-column>
                    <el-table-column prop="STANDARDPRICE" label="单价"></el-table-column>
                    <el-table-column prop="CURRCOUNT" label="库存"></el-table-column>

                    <el-table-column prop="STORAGENAME" label="仓库"></el-table-column>
                    <el-table-column prop="SUPPLIERNAME" label="供货商"></el-table-column>
                    <el-table-column prop="THINGTYPEID" label="货品分类">
                        <template slot-scope="scope">
                            <span >{{scope.row.STORAGETHINGTYPELIST[scope.row.STORAGETHINGTYPELIST.length-1].NAME}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="MANAGERUSER" label="管理员">
                        <template slot-scope="scope">
                            <span>{{scope.row.MANAGERUSER.TRUENAME+'('+scope.row.MANAGERUSER.LOGINNAME+')'}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="MANAGERMOBILE" label="管理员电话"></el-table-column>

                    <el-table-column prop="BZ" label="备注"></el-table-column>
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                          <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button>
                          <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
                          <el-button @click="inPost(scope.row)" type="text" size="small" style="color:#67C23A;margin-left:0px;">入库</el-button>
                          <el-button @click="outPost(scope.row)" type="text" size="small" style="color:#F56C6C">出库</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="800px" top="30px">
            <el-form :model="postInfo" status-icon :rules="postrules"  ref="uForm" label-width="100px" class="demo-uForm">
                <el-form-item label="货物中文名" prop="CNNAME">
                    <el-input v-model="postInfo.CNNAME" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="货物英文名" prop="ENNAME">
                    <el-input v-model="postInfo.ENNAME" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="物料号" prop="CODE">
                    <el-input v-model="postInfo.CODE" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="规格型号" prop="GGMODEL">
                    <el-input v-model="postInfo.GGMODEL" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="单位" prop="UNIT">
                    <el-input v-model="postInfo.UNIT" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="单价（元）" prop="STANDARDPRICE">
                    <el-input v-model="postInfo.STANDARDPRICE" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="库存" prop="CURRCOUNT">
                    <el-input v-model="postInfo.CURRCOUNT" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="仓库" prop="STORAGEID" >
                    <el-select v-model="postInfo.STORAGEID" placeholder="请选择仓库" filterable  style="width:100%;" @change="changeCK">
                            <el-option v-for="item in ckList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="供货商" prop="SUPPLIERID">
                    <el-select v-model="postInfo.SUPPLIERID" placeholder="请选择供货商" filterable  style="width:100%;">
                            <el-option v-for="item in gysList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="管理员" prop="MANAGER">
                    <el-select v-model="postInfo.MANAGER" placeholder="请选择管理员" filterable style="width:100%" @change="changeUser">
                            <el-option v-for="item in userList" :key="item.SNOWID" :label="item.TRUENAME+'('+item.LOGINNAME+')'" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="管理员电话" prop="MANAGERMOBILE">
                    <el-input v-model="postInfo.MANAGERMOBILE" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="备注" prop="BZ">
                    <el-input v-model="postInfo.BZ" autocomplete="off"></el-input>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" @click="submitPostForm()">提交</el-button>
                    <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>

        <el-dialog :title="logTitleStr" :visible.sync="logListModal" width="1200px" top="50px" >
                <div style="width:100%;overflow:hidden;margin-bottom:20px;">
                    <el-button type="success" @click="addInLog()" class="" v-if="logType=='1'">新增入库记录</el-button>
                    <el-button type="danger" @click="addOutLog()" class="" v-if="logType=='2'">新增出库记录</el-button>
                </div>
                <el-table :data="logList" stripe style="width: 100%" :height="dialogMaxHeigh">
                   <el-table-column type="expand">
                        <template slot-scope="props">
                            <span class="logItemTxt">规格型号：{{ props.row.GGMODEL }}</span>
                            <span class="logItemTxt">单位：{{ props.row.UNIT }}</span>
                            <span class="logItemTxt">仓库：{{ props.row.STORAGENAME }}</span>
                            <span class="logItemTxt">供货商：{{ props.row.SUPPLIERNAME }}</span>
                            <span class="logItemTxt">货品分类：{{ props.row.THINGTYPENAME }}</span>
                        </template>
                      </el-table-column>
                    <el-table-column prop="THINGNAME" label="货品名称"></el-table-column>
                    <el-table-column prop="SINGLEPRICE" label="单价"></el-table-column>
                    <el-table-column prop="PRICE" label="总价"></el-table-column>
                    <el-table-column prop="SUMCOUNT" label="数量"></el-table-column>
                    <el-table-column prop="ADDRESS" label="货品去向" v-if="logType=='2'"></el-table-column>

                    <el-table-column prop="MANAGERUSER" label="管理员">
                        <template slot-scope="scope">
                            <span>{{scope.row.MANAGERUSER.TRUENAME+'('+scope.row.MANAGERUSER.LOGINNAME+')'}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="MANAGERMOBILE" label="电话"></el-table-column>
                    <el-table-column prop="BZ" label="备注"></el-table-column>
                    <el-table-column prop="ISBACK" label="是否撤销">
                      <template slot-scope="scope">
                            <span v-if="scope.row.ISBACK==0">否</span>
                            <span v-else style="color:#F56C6C">是</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="BACKTIME" label="撤销时间">
                      <template slot-scope="scope">
                            <span v-if="scope.row.ISBACK==0">/</span>
                            <span v-else >{{scope.row.BACKTIME}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                            <el-button @click="delLogItem(scope.row)"  type="text" size="small" style="color:#F56C6C" v-if="scope.row.ISBACK==0">撤销</el-button>
                            <span v-else>/</span>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndexLog" :page-size="PageSizeLog" :total="TotalLog" class="pg pgItem" @current-change="PGChangeLog"></el-pagination>
        </el-dialog>

        <el-dialog :title="doTypeStrLog" :visible.sync="logModal" width="800px" top="30px">
            <el-form :model="logInfo" status-icon :rules="logrules"  ref="lForm" label-width="100px" class="demo-uForm">
                <el-form-item label="货品名称" prop="THINGNAME">
                    <el-input v-model="logInfo.THINGNAME" autocomplete="off"></el-input>
                </el-form-item>
                <!-- <el-form-item label="货品中文名称" prop="THINGENNAME">
                    <el-input v-model="logInfo.THINGENNAME" autocomplete="off"></el-input>
                </el-form-item> -->
                <el-form-item label="规格型号" prop="GGMODEL">
                    <el-input v-model="logInfo.GGMODEL" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="单位" prop="UNIT">
                    <el-input v-model="logInfo.UNIT" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="单价（元）" prop="SINGLEPRICE">
                    <el-input v-model="logInfo.SINGLEPRICE" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="入库数量" prop="SUMCOUNT" v-if="logType=='1'">
                    <el-input v-model="logInfo.SUMCOUNT" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="出库数量" prop="SUMCOUNT" v-if="logType=='2'">
                    <el-input v-model="logInfo.SUMCOUNT" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="货品去向" prop="ADDRESS" v-if="logType=='2'">
                    <el-input v-model="logInfo.ADDRESS" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="总价（元）" prop="PRICE">
                    <el-input v-model="logInfo.PRICE" autocomplete="off"></el-input>
                </el-form-item>
                
                <el-form-item label="仓库" prop="STORAGEID" >
                    <el-select v-model="logInfo.STORAGEID" placeholder="请选择仓库" filterable  style="width:100%;" @change="changeCKLog">
                            <el-option v-for="item in ckList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="供货商" prop="SUPPLIERID">
                    <el-select v-model="logInfo.SUPPLIERID" placeholder="请选择供货商" filterable  style="width:100%;" @change="changeGHSLog">
                            <el-option v-for="item in gysList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="管理员" prop="MANAGER">
                    <el-select v-model="logInfo.MANAGER" placeholder="请选择管理员" filterable style="width:100%" @change="changeUserLog">
                            <el-option v-for="item in userList" :key="item.SNOWID" :label="item.TRUENAME+'('+item.LOGINNAME+')'" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="管理员电话" prop="MANAGERMOBILE">
                    <el-input v-model="logInfo.MANAGERMOBILE" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="备注" prop="BZ">
                    <el-input v-model="logInfo.BZ" autocomplete="off"></el-input>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" @click="submitLogForm()">提交</el-button>
                    <el-button @click="logModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
var h=document.body.clientHeight
export default {
  name: "PostManage",
  data() {
    var ENNAME = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入货物英文名"));
      } else {
        callback();
      }
    };
    var CODE = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入物料号"));
      } else {
        callback();
      }
    };
    var GGMODEL = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入规格型号号"));
      } else {
        callback();
      }
    };
    var CNNAME = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入货物中文名"));
      } else {
        callback();
      }
    };
    var SUPPLIERID = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择供货商"));
      } else {
        callback();
      }
    };
    var THINGTYPEID = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择货品分类"));
      } else {
        callback();
      }
    };
    var STORAGEID = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择仓库"));
      } else {
        callback();
      }
    };
    var UNIT = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入单位"));
      } else {
        callback();
      }
    };
    var MANAGER = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择管理员"));
      } else {
        callback();
      }
    };
    var MANAGERMOBILE = (rule, value, callback) => {
      if (value === '' || value == null) {
        callback(new Error('请输入手机号'));
      } else if(!/^1[3456789]\d{9}$/.test(value)){
        callback(new Error('请输入正确的手机号'));
      }else {
        callback();
      }
    };
    var STANDARDPRICE=(rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入单价'));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('单价只能是数字'));
        }else if(value<=0){
            callback(new Error('单价必须大于0'));
        }else {
          callback();
        }
    };
    var CURRCOUNT=(rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入库存'));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('库存只能是数字'));
        }else {
          callback();
        }
    };

    var THINGNAME = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入货品名称"));
      } else {
        callback();
      }
    };
    var PRICE=(rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入总价'));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('总价只能是数字'));
        }else if(value<=0){
            callback(new Error('总价必须大于0'));
        }else {
          callback();
        }
    };
    var SUMCOUNT=(rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入数量'));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('数量只能是数字'));
        }else if(value<=0){
            callback(new Error('数量必须大于0'));
        }else {
          if(this.logInfo.SINGLEPRICE!='' && typeof this.logInfo.SINGLEPRICE == 'number'){
            this.logInfo.PRICE =  Math.round((this.logInfo.SINGLEPRICE * this.logInfo.SUMCOUNT)*100)/100
          }
          callback();
        }
    };
    var ADDRESS = (rule, value, callback) => {
      if (this.logType==="2" && value === "") {
        callback(new Error("请输入货品去向"));
      } else {
        callback();
      }
    };
    
    

    return {
        dialogMaxHeigh:0,
        canDo:true,
        
        cname:"",
        ename:"",
        ckid:"",
        gysid:"",
        wzhpfl:"",

        gysList:[],
        ckList:[],
        userList:[],

        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,


        doTypeStr:"",
        doType:"",
        postModal:false,
        postInfo:{},
        postrules: {
            ENNAME: [{ validator: ENNAME, trigger: "blur" }],
            CODE: [{ validator: CODE, trigger: "blur" }],
            GGMODEL: [{ validator: GGMODEL, trigger: "blur" }],
            CNNAME: [{ validator: CNNAME, trigger: "blur" }],
            SUPPLIERID: [{ validator: SUPPLIERID, trigger: "blur" }],
            THINGTYPEID: [{ validator: THINGTYPEID, trigger: "blur" }],
            UNIT: [{ validator: UNIT, trigger: "blur" }],
            STANDARDPRICE: [{ validator: STANDARDPRICE, trigger: "blur" }],
            CURRCOUNT: [{ validator: CURRCOUNT, trigger: "blur" }],
            MANAGER: [{ validator: MANAGER, trigger: "blur" }],
            MANAGERMOBILE: [{ validator: MANAGERMOBILE, trigger: "blur" }],
            STORAGEID: [{ validator: STORAGEID, trigger: "blur" }],
        },

        logItem:null,
        logList:[],
        PageIndexLog:1,
        PageSizeLog:10,
        TotalLog:0,
        logType:"1",
        logTitleStr:"",
        logListModal:false,

        doTypeStrLog:"",
        logModal:false,
        logInfo:{
          SUMCOUNT:"",
        },
        logrules: {
            THINGNAME: [{ validator: THINGNAME, trigger: "blur" }],
            // THINGENNAME: [{ validator: CNNAME, trigger: "blur" }],
            GGMODEL: [{ validator: GGMODEL, trigger: "blur" }],
            SUPPLIERID: [{ validator: SUPPLIERID, trigger: "blur" }],
            THINGTYPEID: [{ validator: THINGTYPEID, trigger: "blur" }],
            UNIT: [{ validator: UNIT, trigger: "blur" }],
            SINGLEPRICE: [{ validator: STANDARDPRICE, trigger: "blur" }],
            PRICE: [{ validator: PRICE, trigger: "blur" }],
            SUMCOUNT: [{ validator: SUMCOUNT, trigger: "blur" }],
            MANAGER: [{ validator: MANAGER, trigger: "blur" }],
            MANAGERMOBILE: [{ validator: MANAGERMOBILE, trigger: "blur" }],
            STORAGEID: [{ validator: STORAGEID, trigger: "blur" }],
            ADDRESS: [{ validator: ADDRESS, trigger: "blur" }],
        },
    };
  },
  mounted() {
    this.loadItem=null
    this.dialogMaxHeigh = h -300
    this.getHPFL()
    this.getGYSList()
    this.getCKList()
    this.getUserList()
  },
  methods: {

    getHPFL(){
      var md5_data =this.comjs.requestDataEncrypt({PARENTID:"0"}, this.$store.state.token,this.$md5(this.comjs.sortAsc({PARENTID:"0"})).toUpperCase())
            this.$http.post("Storage/GetStorageThingtypeList",md5_data)
            .then((response) => {
              if(response.data.SUCCESS){   
                  var list = response.data.DATA
                  for(var i=0;i<list.length;i++){
                    if(list[i].NAME=='风场物资'){
                        this.wzhpfl=list[i].SNOWID
                        break;
                    }
                  }
                  this.getPostList()
              }
            })
    },

    changeUser(e){
      if(e==""){
        this.postInfo.MANAGERMOBILE=""
      }else{
        for(var i=0;i<this.userList.length;i++){
          if(this.userList[i].SNOWID==e){
            this.postInfo.MANAGERMOBILE = this.userList[i].MOBILEPHONE
            break;
          }
        }
      }
    },
    changeUserLog(e){
      if(e==""){
        this.logInfo.MANAGERMOBILE=""
      }else{
        for(var i=0;i<this.userList.length;i++){
          if(this.userList[i].SNOWID==e){
            this.logInfo.MANAGERMOBILE = this.userList[i].MOBILEPHONE
            break;
          }
        }
      }
    },
    getUserList(){
        var params={
          PAGEINDEX:1,
          PAGESIZE:10000,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("UserManage/GetUserList", md5_data)
            .then((response) => {
                this.canDo=true
                if (response.data.SUCCESS) {
                    this.userList=response.data.DATA
                    
                }
            })
    },
    changeGHSLog(e){
      if(e==""){
        this.logInfo.SUPPLIERNAME=""
      }else{
        for(var i=0;i<this.gysList.length;i++){
          if(this.gysList[i].SNOWID==e){
            this.logInfo.SUPPLIERNAME = this.gysList[i].NAME
            break;
          }
        }
      }
    },
    
    getGYSList(){
        var params={
            PAGEINDEX:1,
            PAGESIZE:500,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Storage/GetStorageSupplierList", md5_data)
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.gysList=response.data.DATA
                
                } 
            })
    },
    changeCK(e){
      if(e==""){
        this.postInfo.STORAGENAME=""
      }else{
        for(var i=0;i<this.ckList.length;i++){
          if(this.ckList[i].SNOWID==e){
            //console.log(this.ckList[i])
            this.postInfo.STORAGENAME = this.ckList[i].NAME
            break;
          }
        }
      }
    },
    changeCKLog(e){
      if(e==""){
        this.logInfo.STORAGENAME=""
      }else{
        for(var i=0;i<this.ckList.length;i++){
          if(this.ckList[i].SNOWID==e){
            //console.log(this.ckList[i])
            this.logInfo.STORAGENAME = this.ckList[i].NAME
            break;
          }
        }
      }
    },
    getCKList(){
        var params={
            PAGEINDEX:1,
            PAGESIZE:500,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Storage/GetStorageList", md5_data)
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.ckList=response.data.DATA
                
                } 
            })
    },

    getPostList(){
        this.showLoading("请稍后")
        if(this.wzhpfl==""){
              this.hideLoading()
              this.postList=[]
              this.Total=0
        }else{
            var params={
                PAGEINDEX:this.PageIndex,
                PAGESIZE:this.PageSize,
                CNNAME:this.cname,
                ENNAME:this.ename,
                CODE:"",
                GGMODEL:"",
                SUPPLIERID:this.gysid,
                THINGTYPEID:this.wzhpfl,
                STORAGEID:this.ckid,
                MANAGER:"",
                STORAGENAME:"",
            }
            var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
            this.$http.post("Storage/GetStorageThingList", md5_data)
                .then((response) => {
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.postList=response.data.DATA
                    this.Total=response.data.TOTALCOUNT
                } else {
                    this.postList=[]
                    this.Total=0
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
                })
                .catch((error) => {
                    this.hideLoading()
                    this.postList=[]
                    this.Total=0
            });
        }

    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    addPost(){
        this.postInfo={
            ENNAME:"",
            CODE:"",
            GGMODEL:"",
            CNNAME:"",
            SUPPLIERID:"",
            THINGTYPEID:this.wzhpfl,
            UNIT:"",
            STANDARDPRICE:"",
            CURRCOUNT:"",
            BZ:"",
            MANAGER:"",
            MANAGERMOBILE:"",
            STORAGEID:"",
            STORAGENAME:"",
        }
        this.doTypeStr="添加库存"
        this.doType="1"
        this.postModal=true
    },
    sendAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("Storage/CreateNewStorageThing", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    editPost(item){
        
        this.postInfo={
            SNOWID:item.SNOWID,
            ENNAME:item.ENNAME,
            CODE:item.CODE,
            GGMODEL:item.GGMODEL,
            CNNAME:item.CNNAME,
            SUPPLIERID:item.SUPPLIERID,
            THINGTYPEID:this.wzhpfl,
            UNIT:item.UNIT,
            STANDARDPRICE:item.STANDARDPRICE,
            CURRCOUNT:item.CURRCOUNT,
            BZ:item.BZ,
            MANAGER:item.MANAGER,
            MANAGERMOBILE:item.MANAGERMOBILE,
            STORAGEID:item.STORAGEID,
            STORAGENAME:item.STORAGENAME,
        }
        this.doTypeStr="修改库存"
        this.doType="2"
        this.postModal=true
    },
    sendEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("Storage/EditStorageThing", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.postModal=false
                    this.getPostList();
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    delPost(item){
        if(this.canDo){
            this.$confirm('确定删除库存：'+item.CNNAME+'？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
            this.sendDel(item.SNOWID)
            }).catch(() => {
            this.$message.info("已取消")    
          });
        }

    },
    sendDel(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({SNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:id})).toUpperCase())
        this.$http.post("Storage/DeleteStorageThing", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    submitPostForm() {
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    if(this.doType=="1"){
                        this.sendAdd()
                    }else if(this.doType=="2"){
                        this.sendEdit()
                    }
                } else {
                    return false;
                }
            });
        }
    },


    inPost(item){
      this.logItem=item
      this.logTitleStr=item.CNNAME+"-入库记录"
      var url="Storage/GetStorageInList"
      this.logList=[]
      this.PageIndexLog=1
      this.PageSizeLog=10
      this.TotalLog=0
      this.logListModal=true
      this.logType="1"
      this.getLogList(url)
    },
    outPost(item){
      this.logItem=item
      this.logTitleStr=item.CNNAME+"-出库记录"
      var url="Storage/GetStorageOutList"
      this.logList=[]
      this.PageIndexLog=1
      this.PageSizeLog=10
      this.TotalLog=0
      this.logListModal=true
      this.logType="2"
      this.getLogList(url)
    },
    getLogList(url){
       this.showLoading("请稍后")
        // var tid=""
        // if(this.wzhpfl.length>0){
        //     tid = this.wzhpfl[this.wzhpfl.length-1]
        // }
        var params={
            PAGEINDEX:this.PageIndexLog,
            PAGESIZE:this.PageSizeLog,
            THINGID:this.logItem.SNOWID,
            // CNNAME:this.cname,
            // ENNAME:this.ename,
            // CODE:"",
            // GGMODEL:"",
            // SUPPLIERID:this.gysid,
            // THINGTYPEID:tid,
            // STORAGEID:this.ckid,
            // MANAGER:"",
            // STORAGENAME:"",
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post(url, md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.logList=response.data.DATA
                this.TotalLog=response.data.TOTALCOUNT
            } else {
                this.logList=[]
                this.TotalLog=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.logList=[]
                this.TotalLog=0
        });
        this.getPostList()
    },
    PGChangeLog(e){
        this.PageIndexLog=e
        var url=""
        if(this.logType=="1"){
          url="Storage/GetStorageInList"
        }else{
          url="Storage/GetStorageOutList"
        }
        this.getLogList(url)
    },
    addInLog(){
        this.doTypeStrLog=this.logItem.CNNAME+"-新增入库记录"
        this.logModal=true
        this.logInfo={
          THINGID:this.logItem.SNOWID,
          THINGNAME:this.logItem.CNNAME,
          THINGENNAME:this.logItem.CNNAME,
          GGMODEL:this.logItem.GGMODEL,
          SUPPLIERID:this.logItem.SUPPLIERID,
          SUPPLIERNAME:this.logItem.SUPPLIERNAME,
          THINGTYPENAME:this.logItem.THINGTYPENAME,
          THINGTYPEID:this.wzhpfl,
          UNIT:this.logItem.UNIT,
          SINGLEPRICE:this.logItem.STANDARDPRICE,
          PRICE:"",
          SUMCOUNT:"",
          BZ:this.logItem.BZ,
          MANAGER:this.logItem.MANAGER,
          MANAGERMOBILE:this.logItem.MANAGERMOBILE,
          STORAGEID:this.logItem.STORAGEID,
          STORAGENAME:this.logItem.STORAGENAME,
          OUTID:0,
        }
    },
    addOutLog(){
      this.doTypeStrLog=this.logItem.CNNAME+"-新增出库记录"
        this.logModal=true
        var tids=[]
        if(this.logItem.STORAGETHINGTYPELIST && this.logItem.STORAGETHINGTYPELIST.length>0){
            for(var i=0;i<this.logItem.STORAGETHINGTYPELIST.length;i++){
                tids.push(this.logItem.STORAGETHINGTYPELIST[i].SNOWID)
            }
        }
        this.logInfo={
          THINGID:this.logItem.SNOWID,
          THINGNAME:this.logItem.CNNAME,
          ADDRESS:"",
          GGMODEL:this.logItem.GGMODEL,
          SUPPLIERID:this.logItem.SUPPLIERID,
          SUPPLIERNAME:this.logItem.SUPPLIERNAME,
          THINGTYPENAME:this.logItem.THINGTYPENAME,
          THINGTYPEID:tids,
          UNIT:this.logItem.UNIT,
          SINGLEPRICE:this.logItem.STANDARDPRICE,
          PRICE:"",
          SUMCOUNT:"",
          BZ:this.logItem.BZ,
          MANAGER:this.logItem.MANAGER,
          MANAGERMOBILE:this.logItem.MANAGERMOBILE,
          STORAGEID:this.logItem.STORAGEID,
          STORAGENAME:this.logItem.STORAGENAME,
          INID:0,
        }
    },
    submitLogForm(){
      if (this.canDo) {
            this.$refs.lForm.validate((valid) => {
                if (valid) {
                    var ttc=this.$refs.logtt.getCheckedNodes()
                    this.logInfo.THINGTYPENAME=ttc[0].label
                    //console.log(this.logInfo)
                    if(this.logType=="1"){
                        this.sendInLog()
                    }else if(this.logType=="2"){
                        this.sendOutLog()
                    }
                } else {
                    return false;
                }
            });
        }
    },
    sendInLog(){
      this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.logInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.logInfo)).toUpperCase())
        this.$http.post("Storage/CreateNewStorageIn", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.logModal=false
                    this.getLogList('Storage/GetStorageInList')
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    sendOutLog(){
      this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.logInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.logInfo)).toUpperCase())
        this.$http.post("Storage/CreateNewStorageOut", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.logModal=false
                    this.getLogList('Storage/GetStorageOutList')
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    delLogItem(item){
      var str="入库"
      var cxUrl="Storage/CreateNewStorageInBack"
      if(this.logType=="2"){
        str="出库"
        cxUrl ="Storage/CreateNewStorageOutBack"
      }
      if(this.canDo){
            this.$confirm('确定撤销：'+str+'记录？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
              this.sendLogBack(item.SNOWID,cxUrl)
            }).catch(() => {
            this.$message.info("已取消")    
          });
        }
    },
    sendLogBack(id,url){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({SNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:id})).toUpperCase())
        this.$http.post(url, md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已撤销")
                    var gurl="Storage/GetStorageInList"
                    if(this.logType=="2"){
                      gurl="Storage/GetStorageOutList"
                    }
                    this.getLogList(gurl)
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
  },
  computed: {
  },
  watch: {
    
  },
};
</script>
<style scoped>
  .logItemTxt{font-size: 14px;margin-right: 20px;}
</style>