<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">任务查询</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                <el-date-picker v-model="timeRange" type="daterange" align="right" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions" class="fl" style="margin-right:16px;"></el-date-picker>

                <el-select v-model="status" placeholder="任务状态"   class="iwu">
                    <el-option label="未执行" value="0" ></el-option>
                    <el-option label="已执行" value="1" ></el-option>
                    <el-option label="已执行有故障" value="3" ></el-option>
                </el-select>
                <el-input v-model="taskname" placeholder="任务名称" class="iw" ></el-input>
                <el-input v-model="username" placeholder="人员姓名" class="iw" ></el-input>
                <el-input v-model="machinename" placeholder="设备名称" class="iw" ></el-input>
                <el-button type="primary" @click="searchPost">查询</el-button>
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column prop="USERS" label="人员"></el-table-column>
                    <el-table-column prop="STATUS" label="状态">
                        <template slot-scope="scope">
                            <span v-if="scope.row.STATUS=='0'" style="color:#E6A23C;">未执行</span>
                            <span v-else-if="scope.row.STATUS=='1'" style="color:#67C23A;">已执行</span>
                            <span v-else-if="scope.row.STATUS=='3'" style="color:#F56C6C;">已执行有故障</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="FACTORYNAME" label="风场"></el-table-column>
                    <el-table-column prop="MACHINENAME" label="设备"></el-table-column>
                    <el-table-column prop="MACHINETYPE" label="设备类型"></el-table-column>
                    <el-table-column prop="ITEMNO" label="检查项"></el-table-column>
                     <el-table-column prop="CHECKRESULT" label="检查结果">
                        <template slot-scope="scope">
                            <span v-if="scope.row.STATUS!='0'">{{scope.row.CHECKRESULT}}</span>
                            <span v-else>/</span>
                        </template>
                     </el-table-column>
                    <el-table-column prop="CHECKSTARTTIME" label="进入时间">
                        <template slot-scope="scope">
                            <span v-if="scope.row.STATUS!='0'">{{scope.row.CHECKSTARTTIME}}</span>
                            <span v-else>/</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="CHECKENDTIME" label="出去时间">
                        <template slot-scope="scope">
                            <span v-if="scope.row.STATUS!='0'">{{scope.row.CHECKENDTIME}}</span>
                            <span v-else>/</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="160">
                        <template slot-scope="scope">
                                <span v-if="scope.row.STATUS!='0'">
                                    <el-button @click="filePost(scope.row)" type="text" size="small" style="color:#E6A23C">图片/视频</el-button>
                                </span>
                                <span v-if="scope.row.STATUS=='3'">
                                    <el-button @click="getGZCode(scope.row)" type="text" size="small" style="color:#409EFF">故障代码</el-button>
                                </span>
                                <el-button @click="getBPBJCode(scope.row)" type="text" size="small" style="color:#409EFF">备品备件</el-button>
                            </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog title="任务图片/视频" :visible.sync="postModal" width="800px">
                <div class="titleStr">图片</div>
                <div class="fileCon">
                        <el-image v-for="item in imgList" style="width: 100px; height: 100px" :src="item" :key="item" :preview-src-list="imgList" class="imgItem"></el-image>
                </div>  
                <div class="titleStr">视频</div>
                <div class="fileCon">
                    <video v-for="item in videoList" class="videoItem" :src="item" :key="item"  controls="controls" >
                            您的浏览器不支持 video 标签。
                    </video>
                </div>
        </el-dialog>
        <el-dialog title="任务故障代码" :visible.sync="gzdmModal" width="1200px" top="50px" >
                <el-table :data="gzdmList" stripe style="width: 100%" :height="dialogMaxHeigh">
                   <el-table-column prop="CODE" label="编码/编号"></el-table-column>
                    <el-table-column prop="ID" label="ID/监控ID"></el-table-column>
                    <el-table-column prop="NAME" label="名称"></el-table-column>
                    <el-table-column prop="SYSTEM" label="子系统名称"></el-table-column>
                    <el-table-column prop="COMFIRM" label="确认"></el-table-column>
                    <el-table-column prop="TYPE" label="类别"></el-table-column>
                    <el-table-column prop="CLOSETYPE" label="关机类型"></el-table-column>
                    <el-table-column prop="CATEGORY" label="类型"></el-table-column>
                    <el-table-column prop="CONTEXT" label="文本/日志文字"></el-table-column>
                    <el-table-column prop="TIMEOUT" label="超时"></el-table-column>
                    <el-table-column prop="ALLOWTIMES" label="允许的尝试次数"></el-table-column>
                    <el-table-column prop="MAXDISCONNECTTIME" label="最长断开时间"></el-table-column>
                    <el-table-column prop="TIMEWINDOW" label="时间窗口"></el-table-column>
                    <el-table-column prop="MAXREMOVETIME" label="最长消除时间"></el-table-column>
                    <el-table-column prop="STABLEPERIOD" label="稳定期"></el-table-column>
                    <el-table-column prop="CONDITION" label="条件"></el-table-column>
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                            <el-button @click="faPost(scope.row)" type="text" size="small" style="color:#409EFF">解决方案</el-button>
                        </template>
                    </el-table-column>
                </el-table>
        </el-dialog>
        <el-dialog :title="faTitle" :visible.sync="faModal" width="800px" top="50px" >
                <el-table :data="faList" stripe style="width: 100%" :height="dialogMaxHeigh">
                    <el-table-column prop="REMARK" label="解决方案描述"></el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="faPageIndex" :page-size="faPageSize" :total="faTotal" class="pg" style="margin-bottom:20px;" @current-change="FAPGChange"></el-pagination>
        </el-dialog>
        <el-dialog title="任务备品备件" :visible.sync="bpbjModal" width="1200px" top="50px" >
                <el-table :data="bpbjList" stripe style="width: 100%" :height="dialogMaxHeigh">
                    <el-table-column prop="THINGNAME" label="货品名称"></el-table-column>
                    <el-table-column prop="GGMODEL" label="规格型号"></el-table-column>
                    <el-table-column prop="STORAGENAME" label="仓库"></el-table-column>
                    <el-table-column prop="SUPPLIERNAME" label="供货商"></el-table-column>
                    <el-table-column prop="THINGTYPENAME" label="货品分类"></el-table-column>
                    <el-table-column prop="UNIT" label="单位"></el-table-column>
                    <el-table-column prop="SINGLEPRICE" label="单价（元）"></el-table-column>
                    <el-table-column prop="PRICE" label="总价（元）"></el-table-column>
                    <el-table-column prop="SUMCOUNT" label="数量"></el-table-column>
                    <el-table-column prop="BZ" label="备注"></el-table-column>
                    <el-table-column prop="ADDRESS" label="物资存放地点"></el-table-column>
                    <el-table-column prop="MANAGER" label="管理员"></el-table-column>
                    <el-table-column prop="MANAGERMOBILE" label="管理员电话"></el-table-column>
                </el-table>
        </el-dialog>
    </div>
</template>
<script>
var h=document.body.clientHeight
export default {
  name: "PostManage",
  data() {
    var NAME = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入名称"));
      } else {
        callback();
      }
    };
    return {
        canDo:true,
        
        timeRange:"",
        pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
        status:"1",
        taskname:"",
        username:"",
        machinename:"",

        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,

        postModal:false,
        imgList:[],
        videoList:[],

        dialogMaxHeigh:0,
        gzdmList:[],
        gzdmModal:false,

        faList:[],
        faModal:false,
        faTotal:0,
        faPageIndex:1,
        faPageSize:10,

        bpbjList:[],
        bpbjModal:false,
 
    };
  },
  mounted() {
    this.loadItem=null
    this.getPostList()
    this.dialogMaxHeigh = h -300
  },
  methods: {
    getPostList(){
        var st=""
        var et=""
        if( Array.isArray(this.timeRange)){
            st=this.comjs.getDateMinStrByDate(this.timeRange[0])
            et=this.comjs.getDateMinStrByDate(this.timeRange[1])
        }
        this.showLoading("请稍后")
        var params={
            PAGEINDEX:this.PageIndex,
            PAGESIZE:this.PageSize,
            TASKNAME:this.taskname,
            STARTTIME:st,
            ENDTIME:et,
            STATUS:this.status,
            CHECKUSERNAME:this.username,
            MACHINENAME:this.machinename,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("App/GetCheckRecordList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
        });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    filePost(item){
        if(item.PHOTOLIST){
            this.imgList=[]
            this.videoList=[]
            var c=0
            for(var i=0;i<item.PHOTOLIST.length;i++){
                if(item.PHOTOLIST[i].TYPE=="1"){
                    this.imgList.push(item.PHOTOLIST[i].FILEPATH)
                    c++
                }else if(item.PHOTOLIST[i].TYPE=="2"){
                    this.videoList.push(item.PHOTOLIST[i].FILEPATH)
                    c++
                }
            }

            if(c>0){
                this.postModal=true
            }else{
                this.$message.error("暂无图片或视频")
            }

        }else{
            this.$message.error("暂无图片或视频")
        }
    },
    getGZCode(item){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({LOGSNOWID:item.LOGSNOWID}, this.$store.state.token,this.$md5(this.comjs.sortAsc({LOGSNOWID:item.LOGSNOWID})).toUpperCase())
        this.$http.post("Failure/GetLogFailureList", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                   this.gzdmList=response.data.DATA
                   this.gzdmModal=true
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    faPost(item){
        this.faTitle=item.NAME+"-解决方案管理"
        this.getFAList(item.SNOWID)
        this.faModal=true
    },
    getFAList(id){
        this.canDo=false
        this.showLoading("请稍后")
        var params={
            FAILURESNOWID:id,
            PageIndex:this.faPageIndex,
            PageSize:this.faPageSize,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Failure/GetSolutionList", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.faList=response.data.DATA
                    this.faTotal=response.data.TOTALCOUNT
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                    this.faList=[]
                    this.faTotal=0
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.faList=[]
                this.faTotal=0
                this.canDo=true   
             });
    },
    FAPGChange(e){
        this.faPageIndex=e
        this.getFAList()
    },
    getBPBJCode(item){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({CHECKLOGID:item.LOGSNOWID}, this.$store.state.token,this.$md5(this.comjs.sortAsc({CHECKLOGID:item.LOGSNOWID})).toUpperCase())
        this.$http.post("Storage/GetStorageOutList", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                   this.bpbjList=response.data.DATA
                   this.bpbjModal=true
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    }
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style scoped>
.titleStr{font-size: 18px;font-weight: bold;margin-bottom: 20px;}
.fileCon{overflow: hidden;margin-bottom: 20px;}
.imgItem{float:left;margin-right: 12px;margin-bottom: 12px;}
.vodeoItem{width: 100%;height: 340px;display: block;margin-bottom: 20px;}
</style>