<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">定检交底报告</p>
        </div>
        <div class="tableBox" style="top:50px;">
                <div class="ksItem" @click="showAQJD">
                   安全交底和技术交底内容
                </div>
                <div class="ksItem" @click="showWW">
                   外委及自检缺陷明细及消缺明细及消缺完成报告
                </div>
        </div>
       <el-dialog title="安全交底和技术交底内容" :visible.sync="aqjdModal" width="1200px" top="50px" :height="dialogMaxHeigh">
               <AQJD></AQJD>
        </el-dialog>
        <el-dialog title="外委及自检缺陷明细及消缺明细及消缺完成报告" :visible.sync="wwModel" width="1200px" top="50px" :height="dialogMaxHeigh">
               <WW></WW>
        </el-dialog>
    </div>
</template>
<script>
var h=document.body.clientHeight
import AQJD from "../../components/AQJDHJSJD"
import WW from "../../components/WWJZJQXMX"
export default {
  name: "KSFL",
  components:{
    AQJD,
    WW,
  },
  data() {

    return {
        canDo:true, 
        aqjdModal:false,
        wwModel:false,

        dialogMaxHeigh:0,
    };
  },
  mounted() {
    this.dialogMaxHeigh = h -300
    this.loadItem=null
  },
  methods: {
    showAQJD(){
      this.aqjdModal=true
    },
    showWW(){
      this.wwModel=true
    }
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style scoped>
    .el-collapse-item__header{padding: 0 20px;font-size: 16px;}
    .el-collapse .is-active{color: #409EFF;}
    .el-collapse-item__content{padding-left:20px;}

    .ksItem{width: 600px;height: 80px;border-radius: 3px;cursor: pointer;color: #FFFFFF;background-color: #409EFF;font-size: 16px;margin-left: 30px;margin-top: 30px;float:left;padding:0 30px;display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;}
            .ksItem:hover{background-color: #5FADFC;}

</style>