<template>
    <div class="views-main">
        <div class="views-main-title">
            <el-button type="primary" icon="el-icon-arrow-left" style="float:left;margin-right:20px;" size="small" @click="backAQGL">返回</el-button>
            <p class="txt">费用支出记录</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">  
                <el-input v-model="syr" placeholder="请输入使用人" class="iw" ></el-input>
                <el-input v-model="spr" placeholder="请输入审批人人" class="iw" ></el-input>
                <el-date-picker
                    v-model="sjqj"
                    type="datetimerange"
                    :picker-options="pickerOptions"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    align="right"
                    class="iwss">
                </el-date-picker>

                <el-button type="primary" class="fr" @click="fyzcsp">费用支出申请</el-button>
                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-button type="warning" @click="sumPost">按时间统计金额</el-button>
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column prop="USERNAME" label="使用人"></el-table-column>
                    <el-table-column prop="APPROVERNAME" label="审批人"></el-table-column>
                    <el-table-column prop="MONEY" label="金额"></el-table-column>
                    <el-table-column prop="USECAUSE" label="资金用途"></el-table-column>
                    <el-table-column prop="USETIME" label="使用时间"></el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  name: "JYBXJL",
  data() {
    return {
        canDo:true,
        
        syr:"",
        spr:"",

        sjqj:null,
       
        userList:[],

        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,
        pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },

       
    };
  },
  mounted() {
    this.loadItem=null
    this.getUserList()
    this.getPostList()
  },
  methods: {
    getUserList(){
        var params={ }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("UserManage/GetUserListWithoutPage", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.userList=response.data.DATA
            } 
            })
    },
    getPostList(){
        this.showLoading("请稍后")
        
        var ast=""
        var aet=""
        if(this.sjqj &&this.sjqj.length>0){
            ast = this.comjs.getDateStrByDate(this.sjqj[0])
            aet = this.comjs.getDateStrByDate(this.sjqj[1])
        }

        var params={
            PAGEINDEX:this.PageIndex,
            PAGESIZE:this.PageSize,
            STARTTIME:ast,
            ENDTIME:aet,
            USERNAME:this.syr,
            APPROVERNAME:this.sqr,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("MoneyPayOut/GetList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
        });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    backAQGL(){
        this.$router.replace('/HQAQGL')
    },
    fyzcsp(){
        this.showLoading("请稍后")
        var params={
            PAGEINDEX:1,
            PAGESIZE:1,
            NAME:"",
            ALIAS:"MONEYPAYOUT",
            VISIBILITY:true,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Model/GetModelList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS && response.data.DATA.length>0) {
                var d=response.data.DATA[0]
                window.open("/statics/pages/LCQC.html?mid="+d.SNOWID+"&token="+this.$store.state.token)
            } else {
            this.$message.error("请先配置费用支出申请流程")
            }
            })
            .catch((error) => {
                this.$message.error("请求失败，请稍后再试")
                this.hideLoading()
            }); 
    },
    sumPost(){
        var ast=""
        var aet=""
        if(this.sjqj &&this.sjqj.length>0){
            ast = this.comjs.getDateStrByDate(this.sjqj[0])
            aet = this.comjs.getDateStrByDate(this.sjqj[1])
            this.showLoading("请稍后")
        var params={
            STARTTIME:ast,
            ENDTIME:aet,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("MoneyPayOut/SumMoney", md5_data)
            .then((response) => {
            this.hideLoading()
                if (response.data.SUCCESS ) {
                    this.$alert(response.data.DATA, '金额总和', {
                            confirmButtonText: '确定',
                            callback: action => {
                                //
                            }
                        });
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求失败，请稍后再试")
                this.hideLoading()
            }); 
        }else{
            this.$message.error("请先选择日期区间")
        }
    }
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style >
</style>