<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">供应商管理</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                
                <el-input v-model="name" placeholder="请输入名称" class="iw" ></el-input>
                <el-input v-model="lxr" placeholder="请输入联系人" class="iw" ></el-input>
                <el-input v-model="dh" placeholder="请输入手机号" class="iw" ></el-input>
                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-button type="success" @click="addPost" class="fr">添加</el-button>
                
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column prop="NAME" label="名称"></el-table-column>
                    <el-table-column prop="CONTACTOR" label="联系人"></el-table-column>
                    <el-table-column prop="CONTACTMOBILE" label="手机号"></el-table-column>
                    <el-table-column prop="CREATETIME" label="创建日期"></el-table-column>
                    <el-table-column label="操作" width="200">
                        <template slot-scope="scope">
                            <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button>
                            <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
                            <el-button @click="hpPost(scope.row)"  type="text" size="small" style="color:#409EFF">货品明细</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="500px">
            <el-form :model="postInfo" status-icon :rules="postrules"  ref="uForm" label-width="100px" class="demo-uForm">
                <el-form-item label="名称" prop="NAME">
                <el-input v-model="postInfo.NAME" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="联系人" prop="CONTACTOR">
                <el-input v-model="postInfo.CONTACTOR" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="手机号" prop="CONTACTMOBILE">
                <el-input v-model="postInfo.CONTACTMOBILE" autocomplete="off"></el-input>
                </el-form-item>
            
                <el-form-item>
                <el-button type="primary" @click="submitPostForm()">提交</el-button>
                <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog title="货品明细表" :visible.sync="hpModal" width="1200px" top="50px" >
                <div style="width:100%;overflow:hidden;margin-bottom:20px;">
                    <el-input v-model="chnameS" placeholder="货物中文名" class="iw" ></el-input>
                    <el-input v-model="ennameS" placeholder="货物英文名" class="iw" ></el-input>
                    <el-cascader v-model="typeS"  :props="typeprops" placeholder="选择货品分类" clearable filterable class="iwss" ></el-cascader>
                    <el-button type="primary" @click="searchPostS">查询</el-button>
                    <el-button type="success" @click="addHp()" class="fr">新增货品</el-button>
                </div>
                <el-table :data="postListS" stripe style="width: 100%" :height="dialogMaxHeigh">
                    <el-table-column prop="CNNAME" label="货品中文名称"></el-table-column>
                    <el-table-column prop="ENNAME" label="货品英文名"></el-table-column>
                    <el-table-column prop="CODE" label="物料号"></el-table-column>
                    <el-table-column prop="GGMODEL" label="规格型号"></el-table-column>
                    <el-table-column prop="UNIT" label="单位"></el-table-column>
                    <el-table-column prop="STANDARDPRICE" label="单价（元）"></el-table-column>
                    <el-table-column prop="BZ" label="备注"></el-table-column>
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                             <el-button @click="editHp(scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button>
                            <el-button @click="delHp(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndexS" :page-size="PageSizeS" :total="TotalS" class="pg pgItem" @current-change="PGChangeS"></el-pagination>
        </el-dialog>
        <el-dialog :title="doTypeStrS" :visible.sync="postModalS" width="800px" top="30px">
            <el-form :model="postInfoS" status-icon :rules="postrulesS"  ref="sForm" label-width="100px" class="demo-uForm">
                <el-form-item label="货物中文名" prop="CNNAME">
                    <el-input v-model="postInfoS.CNNAME" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="货物英文名" prop="ENNAME">
                    <el-input v-model="postInfoS.ENNAME" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="物料号" prop="CODE">
                    <el-input v-model="postInfoS.CODE" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="规格型号" prop="GGMODEL">
                    <el-input v-model="postInfoS.GGMODEL" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="单位" prop="UNIT">
                    <el-input v-model="postInfoS.UNIT" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="单价（元）" prop="STANDARDPRICE">
                    <el-input v-model="postInfoS.STANDARDPRICE" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="货品分类" prop="THINGTYPEID">
                    <el-cascader v-model="postInfoS.THINGTYPEID"  :props="typeprops" placeholder="选择货品分类"  filterable style="width:100%;" ></el-cascader>
                </el-form-item>
                <el-form-item label="备注" prop="BZ">
                    <el-input v-model="postInfoS.BZ" autocomplete="off"></el-input>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" @click="submitPostFormS()">提交</el-button>
                    <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
var h=document.body.clientHeight
export default {
  name: "PostManage",
  data() {
    var NAME = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入名称"));
      } else {
        callback();
      }
    };
    var CONTACTOR = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入联系人"));
      } else {
        callback();
      }
    };
    var CONTACTMOBILE = (rule, value, callback) => {
      if (value === '' || value == null) {
        callback(new Error('请输入手机号'));
      } else if(!/^1[3456789]\d{9}$/.test(value)){
        callback(new Error('请输入正确的手机号'));
      }else {
        callback();
      }
    };

    var ENNAME = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入货物英文名"));
      } else {
        callback();
      }
    };
    var CODE = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入物料号"));
      } else {
        callback();
      }
    };
    var GGMODEL = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入规格型号号"));
      } else {
        callback();
      }
    };
    var CNNAME = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入货物中文名"));
      } else {
        callback();
      }
    };
    var THINGTYPEID = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择货品分类"));
      } else {
        callback();
      }
    };
    var UNIT = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入单位"));
      } else {
        callback();
      }
    };
    var STANDARDPRICE=(rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入单价'));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('单价只能是数字'));
        }else if(value<=0){
            callback(new Error('单价必须大于0'));
        }else {
          callback();
        }
    };
    return {
        canDo:true,
        
        name:"",
        lxr:"",
        dh:"",

        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,


        doTypeStr:"",
        doType:"",
        postModal:false,
        postInfo:{
            NAME:"",
        },
        postrules: {
            NAME: [{ validator: NAME, trigger: "blur" }],
            CONTACTOR: [{ validator: CONTACTOR, trigger: "blur" }],
            CONTACTMOBILE: [{ validator: CONTACTMOBILE, trigger: "blur" }],
        },

        dialogMaxHeigh:0,


        hpModal:false,
        gysItem:null,
        typeprops: {
            lazy: true,
            checkStrictly:true,
            lazyLoad :(node, resolve)=> {
                var guid="0"
                if(node && node.data!=undefined){
                guid=node.data.guid 
                }
                var nodes=[]
                var md5_data =this.comjs.requestDataEncrypt({PARENTID:guid}, this.$store.state.token,this.$md5(this.comjs.sortAsc({PARENTID:guid})).toUpperCase())
                this.$http.post("Storage/GetStorageThingtypeList",md5_data)
                .then((response) => {
                if(response.data.SUCCESS){   
                    var l=response.data.DATA
                    for(var i=0;i<l.length;i++){
                        nodes.push({
                        value:l[i].SNOWID,
                        label:l[i].NAME,
                        guid:l[i].SNOWID,
                        leaf: false
                        })
                    }
                    resolve(nodes);
                }else{
                    this.errorRequest(response.data.DATA,response.data.MSG,false)
                }
                })
            }
        },
        ennameS:"",
        chnameS:"",
        codeS:"",
        typeS:[],

        postListS:[],
        PageIndexS:1,
        PageSizeS:10,
        TotalS:0,

        doTypeStrS:"",
        doTypeS:"",
        postModalS:false,
        postInfoS:{},
        postrulesS: {
            ENNAME: [{ validator: ENNAME, trigger: "blur" }],
            CODE: [{ validator: CODE, trigger: "blur" }],
            GGMODEL: [{ validator: GGMODEL, trigger: "blur" }],
            CNNAME: [{ validator: CNNAME, trigger: "blur" }],
            THINGTYPEID: [{ validator: THINGTYPEID, trigger: "blur" }],
            UNIT: [{ validator: UNIT, trigger: "blur" }],
            STANDARDPRICE: [{ validator: STANDARDPRICE, trigger: "blur" }],
        },

    };
  },
  mounted() {
    this.loadItem=null
     this.dialogMaxHeigh = h -300
    this.getPostList()
  },
  methods: {
    getPostList(){
        this.showLoading("请稍后")
        var params={
            PAGEINDEX:this.PageIndex,
            PAGESIZE:this.PageSize,
            NAME:this.name,
            CONTACTOR:this.lxr,
            CONTACTMOBILE:this.dh

        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Storage/GetStorageSupplierList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
        });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    addPost(){
        this.postInfo={
            NAME:"",
            CONTACTOR:"",
            CONTACTMOBILE:"",
        }
        this.doTypeStr="添加供应商"
        this.doType="1"
        this.postModal=true
    },
    sendAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("Storage/CreateNewStorageSupplier", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    editPost(item){
        this.postInfo={
            SNOWID:item.SNOWID,
            NAME:item.NAME,
            CONTACTOR:item.CONTACTOR,
            CONTACTMOBILE:item.CONTACTMOBILE,
        }
        this.doTypeStr="修改供应商"
        this.doType="2"
        this.postModal=true
    },
    sendEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("Storage/EditStorageSupplier", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.postModal=false
                    this.getPostList();
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    delPost(item){
        if(this.canDo){
            this.$confirm('确定删除供应商：'+item.NAME+'？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
            this.sendDel(item.SNOWID)
            }).catch(() => {
            this.$message.info("已取消")    
          });
        }

    },
    sendDel(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({SNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:id})).toUpperCase())
        this.$http.post("Storage/DeleteStorageSupplier", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    submitPostForm() {
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    if(this.doType=="1"){
                        this.sendAdd()
                    }else if(this.doType=="2"){
                        this.sendEdit()
                    }
                } else {
                    return false;
                }
            });
        }
    },

    hpPost(item){
        this.gysItem=item
        this.ennameS=""
        this.chnameS=""
        this.codeS=""
        this.typeS=[]
        this.PageIndexS=1
        this.getSuppHPList()
        this.hpModal=true
    },
    getSuppHPList(){
        this.showLoading("请稍后")
        var t=""
        if(this.typeS.length>0){
            t=this.typeS[this.typeS.length-1]
        }
        var params={
            PAGEINDEX:this.PageIndexS,
            PAGESIZE:this.PageSizeS,
            CNNAME:this.ennameS,
            SUPPLIERID:this.gysItem.SNOWID,
            ENNAME:this.chnameS,
            CODE:this.codeS,
            THINGTYPEID:t
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Thing/GetThingList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postListS=response.data.DATA
                this.TotalS=response.data.TOTALCOUNT
            } else {
                this.postListS=[]
                this.TotalS=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postListS=[]
                this.TotalS=0
        });
    },
    searchPostS(){
        this.PageIndexS=1
        this.getSuppHPList()
    },
    PGChangeS(e){
        this.PageIndexS=e
        this.getSuppHPList()
    },
    addHp(){
        this.postInfoS={
            ENNAME:"",
            CODE:"",
            GGMODEL:"",
            CNNAME:"",
            SUPPLIERID:this.gysItem.SNOWID,
            THINGTYPEID:[],
            UNIT:"",
            STANDARDPRICE:"",
            BZ:"",
        }
        this.doTypeStrS="添加货品"
        this.doTypeS="1"
        this.postModalS=true
    },
    sendAddS(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfoS, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfoS)).toUpperCase())
        this.$http.post("Thing/CreateNewThing", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.postModalS=false
                    this.getSuppHPList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    editHp(item){
        var tids=[]
        if(item.STORAGETHINGTYPELIST && item.STORAGETHINGTYPELIST.length>0){
            for(var i=0;i<item.STORAGETHINGTYPELIST.length;i++){
                tids.push(item.STORAGETHINGTYPELIST[i].SNOWID)
            }
        }
        this.postInfoS={
            SNOWID:item.SNOWID,
            ENNAME:item.ENNAME,
            CODE:item.CODE,
            GGMODEL:item.GGMODEL,
            CNNAME:item.CNNAME,
            SUPPLIERID:item.SUPPLIERID,
            THINGTYPEID:tids,
            UNIT:item.UNIT,
            STANDARDPRICE:item.STANDARDPRICE,
            BZ:item.BZ,
        }
        this.doTypeStrS="修改货品"
        this.doTypeS="2"
        this.postModalS=true
    },
    sendEditS(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfoS, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfoS)).toUpperCase())
        this.$http.post("Thing/EditThing", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.postModalS=false
                    this.getSuppHPList();
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    delHp(item){
        if(this.canDo){
            this.$confirm('确定删除货品：'+item.CNNAME+'？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
            this.sendDelS(item.SNOWID)
            }).catch(() => {
            this.$message.info("已取消")    
          });
        }

    },
    sendDelS(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({SNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:id})).toUpperCase())
        this.$http.post("Thing/DeleteThing", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.getSuppHPList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    submitPostFormS() {
        if (this.canDo) {
            this.$refs.sForm.validate((valid) => {
                if (valid) {
                    var tid=this.postInfoS.THINGTYPEID[this.postInfoS.THINGTYPEID.length-1]
                    this.postInfoS.THINGTYPEID=tid
                    if(this.doTypeS=="1"){
                        this.sendAddS()
                    }else if(this.doTypeS=="2"){
                        this.sendEditS()
                    }
                } else {
                    return false;
                }
            });
        }
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style >
</style>