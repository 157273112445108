<template>
  <div class="views-main">
    <div class="views-main-title">
      <p class="txt">起草流程</p>
    </div>
    
    <div class="tableBox" style="top:50px;left:16px;right:16px">
            <div class="views-main-search">
                
                <el-input v-model="name" placeholder="请输入名称" class="iw" ></el-input>
                <el-input v-model="alias" placeholder="请输入别名" class="iw" ></el-input>
                <el-button type="primary" @click="searchPost">查询</el-button>
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column prop="NAME" label="名称"></el-table-column>
                    <el-table-column prop="ALIAS" label="别名"></el-table-column>
                    <el-table-column prop="VISIBILITY" label="是否可见">
                        <template slot-scope="scope">
                            <span v-if="scope.row.VISIBILITY" style="color:#67C23A">可见</span>
                            <span v-else style="color:#F56C6C">不可见</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="DESCRIBE" label="描述"></el-table-column>
                    <el-table-column prop="CREATED" label="创建日期"></el-table-column>
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                        <el-button @click="setPost(scope.row)" type="text" size="small" style="color:#E6A23C">起草</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
    </div>
    <el-dialog :title="doTypeStr" :visible.sync="postModal" width="1200px;padding:20px 0;">
            <div class="formList" v-for="formitem in doForms" :key="formitem.alias">
                <p class="fromTitle">{{formitem.name}}</p>
                <el-form :model="formitem.form" :rules="formitem.rules" :ref="formitem.alias" label-width="100px">
                    <div v-for="field in formitem.column" :key="field.id" class="fromItemOut">
                      <el-form-item  :label="field.label" :prop="field.id" >
                          <el-input v-if="field.type=='1'" v-model="formitem.form[field.id]" class="halfFromItem"></el-input>
                          <el-date-picker v-else-if="field.type=='2'"  v-model="formitem.form[field.id]" type="date" class="halfFromItem"></el-date-picker>
                          <el-time-picker v-else-if="field.type=='3'" v-model="formitem.form[field.id]" class="halfFromItem"></el-time-picker>
                          <el-select v-else-if="field.type=='4'" v-model="formitem.form[field.id]"  :multiple="field.muti" class="halfFromItem">
                            <el-option v-for="opts in field.options" :key="opts.key" :label="opts.value" :value="opts.key"></el-option>
                          </el-select>
                          <el-radio-group v-else-if="field.type=='5'"  v-model="formitem.form[field.id]" class="halfFromItem">
                            <el-radio  v-for="opts in field.options" :key="opts.key" :label="opts.key">{{opts.value}}</el-radio>
                          </el-radio-group>
                          <el-checkbox-group v-else-if="field.type=='6'"  v-model="formitem.form[field.id]" class="halfFromItem">
                            <el-checkbox v-for="opts in field.options" :key="opts.key" :label="opts.key">{{opts.value}}</el-checkbox>
                          </el-checkbox-group>
                          <el-date-picker v-else-if="field.type=='7'"  v-model="formitem.form[field.id]" type="datetime" class="halfFromItem"></el-date-picker>
                      </el-form-item>
                    </div>
                </el-form>
            </div>
            <div class="btm">
                <el-button type="primary" class="fl" @click="submitDoFrom()">提交</el-button>
                <el-button class="fl" @click="resetDoFrom()">重置</el-button>
            </div>
    </el-dialog>
    <el-dialog title="流程起草确认" :visible.sync="lastModal" width="500px">
        <el-form :model="lastInfo" status-icon :rules="lastrules"  ref="lForm" label-width="100px" class="demo-uForm">
            <el-form-item label="流程标题" prop="TITLE">
                <el-input  v-model="lastInfo.TITLE"></el-input>
            </el-form-item>
            <el-form-item label="下一审批节点" prop="RECT">
                <el-select  v-model="lastInfo.RECT" @change="rectChange">
                  <el-option v-for="opts in nextRectList" :key="opts.SNOWID" :label="opts.NAME" :value="opts.SNOWID"></el-option>
                </el-select>
            </el-form-item>
             <el-form-item label="审批人" prop="USER">
                <el-select  v-model="lastInfo.USER" multiple>
                  <el-option v-for="opts in nextUserList" :key="opts.USERID" :label="opts.USERNAME+'('+opts.DISPLAYNAME+')'" :value="opts.USERID"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="setLastDataForFlowsheet()">提交</el-button>
              <el-button @click="lastModal = false">取消</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
    <el-dialog title="春秋检类型选择" :visible.sync="cqjModal" width="500px">
        <div style="overflow:hidden;margin-bottom:40px;">
              <el-radio v-model="cqjtype" label="1">春检</el-radio>
              <el-radio v-model="cqjtype" label="2">秋检</el-radio>
        </div>
        <el-button type="primary" @click="qccqj()">提交</el-button>
        <el-button @click="cqjModal = false">取消</el-button>
    </el-dialog>
    <el-dialog title="定类型选择" :visible.sync="djModal" width="500px">
        <div style="overflow:hidden;margin-bottom:40px;">
              <el-radio v-model="djtype" label="1">风机第一、二、三次定检工作（外委）</el-radio>
              <el-radio v-model="djtype" label="2">风机第四、五、六次定检工作（自检）</el-radio>
              <el-radio v-model="djtype" label="3">每年定检时对风向仪进行对中校对。（每年外委定检期内进行校对）</el-radio>
              <el-radio v-model="djtype" label="4">每年定检时校对叶片对角。（每年外委定检期内进行校对）</el-radio>
              <el-radio v-model="djtype" label="5">关注高桥和围屏风机负荷情况及机组是否自动进入安全模式状态，避免造成发电量损失（日常密切监控）</el-radio>
              <el-radio v-model="djtype" label="6">每季度核对测风仪及风向，避免影响发电量损失。（日常密切监控）</el-radio>
              <el-radio v-model="djtype" label="7">内窥镜检查</el-radio>
        </div>
        <el-button type="primary" @click="dcqj()">提交</el-button>
        <el-button @click="djModal = false">取消</el-button>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "WFCreateFlowsheet",
  data() {
    var RECT =(rule, value, callback) => {
        if (value === '') {
          callback(new Error('请选择下个审批环节'));
        }else {
          callback();
        }
    };
    var USER =(rule, value, callback) => {
        if (value .length ===0) {
         callback(new Error('请选择审批人'));
        }else {
          callback();
        }
    };
    var TITLE =(rule, value, callback) => {
        if (value === '') {
         callback(new Error('请输入流程标题'));
        }else {
          callback();
        }
    };

    return {
        canDo:true,
        activeName:"a",
        formList:[],
        modelList:[],
        pageInit:false,

         //请假
        leaveMoedlItem:null,
        leaveForms:[],
         //调休
        breakMoedlItem:null,
        breakForms:[],
         //加班
        addworkMoedlItem:null,
        addworkForms:[],
        //出差
        outMoedlItem:null,
        outForms:[],


        //下一节点/下个人
        nextRect:{},
        nextRectList:[],
        nextUserList:[],
      
        lastModal:false,
        lastInfo:{
          RECT:"",
          USER:"",
          TITLE:"",
        },  
        lastrules: {
            RECT: [{ validator: RECT, trigger: "blur" }],
            USER:[{ validator: USER, trigger: "blur" }],
            TITLE:[{ validator: TITLE, trigger: "blur" }],
        },

        //流程
        name:"",
        alias:"",
        visibility:"1",

        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,


        doTypeStr:"",
        postModal:false,

        doMoedlItem:null,
        doForms:[],

        cqjtype:"1",
        cqjModal:false,
        cqjid:"",


        djtype:"1",
        djModal:false,
        djid:"",
    };
  },
  mounted() {
    this.loadItem=null
    this.getPostList()
    this.getFormList()
  },
  methods: {
      searchPost(){
          this.PageIndex=1
          this.getPostList()
      },
      PGChange(e){
        this.PageIndex=e
        this.getPostList()
      },
      getPostList(){
        this.showLoading("请稍后")
        var vy=null
        if(this.visibility=="1"){
            vy = true
        }else  if(this.visibility=="0"){
            vy = false
        }
        var params={
            PAGEINDEX:this.PageIndex,
            PAGESIZE:this.PageSize,
            NAME:"",
            ALIAS:"",
            VISIBILITY:true,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Model/GetModelList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
        });
    },


    //获取表单
    getFormList(){
      this.showLoading("请稍后")
       var params={
            PAGEINDEX:1,
            PAGESIZE:100,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Form/GetFormList", md5_data)
            .then((response) => {
              if (response.data.SUCCESS) {
                  this.formList=response.data.DATA
              } 
            })
    },
    qccqj(){
        window.open("/statics/pages/LCQC.html?mid="+this.cqjid+"&token="+this.$store.state.token+"&cqjtype="+this.cqjtype)
    },
    dcqj(){
        window.open("/statics/pages/LCQC.html?mid="+this.djid+"&token="+this.$store.state.token+"&djtype="+this.djtype)
    },
    setPost(d){
      this.doMoedlItem=null
      this.doForms=[]
      this.doTypeStr=d.NAME+"-流程起草"
      if(d.ACTIVITYMODELS.length ==0){
          this.$message.error("流程节点异常，无法起草")
      }else{
        if(d.ALIAS=="CQCHECK"){
          this.cqjid = d.SNOWID
          this.cqjModal=true
        }else if(d.ALIAS=="INSPECTION"){
          this.djid = d.SNOWID
          this.djModal=true
        }else{
           window.open("/statics/pages/LCQC.html?mid="+d.SNOWID+"&token="+this.$store.state.token)
        }
       
        

        // var rectFromList=[]
        // for(var g=0;g<d.ACTIVITYMODELS.length;g++){
        //   if(d.ACTIVITYMODELS[g].ALIAS == "ks"){
        //     rectFromList = d.ACTIVITYMODELS[g].FORMIDLIST
        //     break;
        //   }
        // }
        // for(var h=0;h<rectFromList.length;h++){
        //     var fromItem = this.getFormById(rectFromList[h]) //表单对象
        //     var columnList=fromItem.FORMITEMMODELS //表单对象的字段集合
        //     var  c=[]  //表单元素
        //     var  f={}  //表单属性
        //     var  r={}  //表单属性校验规则
        //     for(var k=0;k<columnList.length;k++){
        //       var kd=columnList[k]
        //       var ft =this.getFieldTypeCodeByTypeName(kd.FIELDTYPENAME)

        //       //是否必填
        //       var  must=false
        //       if(kd.ISCANNULL=="0"){
        //         must =true
        //         r[kd.SNOWID]=[{ required: true, message: kd.FIELDNAME+'不能为空', trigger: 'blur' }]
        //       }
        //       //是否可多选
        //       var muti =false
        //       if(kd.ISCANMUTI=="1"){
        //         muti =true
        //       }
        //       //属性数据来源（下拉框、单选按钮、复选框）
        //       var options=this.getFormJsonByType(kd.DATAFROMJSON)
        //       c.push({
        //         id:kd.SNOWID,
        //         label:kd.FIELDNAME,
        //         must:must,
        //         muti:muti,
        //         type:ft,
        //         options:options
        //       })
        //       if(ft=="6"){
        //         f[kd.SNOWID]=[]
        //       }else{
        //         f[kd.SNOWID]=""
        //       }
              
        //     }
        //     this.doMoedlItem = d
        //     console.log(this.doMoedlItem)
        //     this.doForms.push({
        //       snowid:fromItem.SNOWID,
        //       name:fromItem.NAME,
        //       alias:fromItem.ALIAS,
        //       column:c,
        //       form:f,
        //       rules:r
        //     })

        //   }
        //   this.postModal =true
      }
    },
    //通过字段名称获取字段编号
    getFieldTypeCodeByTypeName(name){
      var code=""
      switch(name){
        case "文本":
            code="1"
            break;
        case "日期":
            code="2"
            break;
        case "时间":
            code="3"
            break;
        case "下拉框":
            code="4"
            break;
        case "单选按钮":
            code="5"
            break;
        case "复选框":
            code="6"
            break;
        case "日期时间":
            code="7"
            break;
      }
      return code
    },
    //通过表单ID获取表单信息
    getFormById(id){
      var item =null
      for(var i=0;i<this.formList.length;i++){
        if(this.formList[i].SNOWID == id){
          item = this.formList[i]
          break;
        }
      }
      return item
    },
    //提交流程
    submitDoFrom(){
      if(this.canDo){
            var formTrueCount=0
            if(this.doMoedlItem && this.doForms.length>0){
              for(var i=0;i<this.doForms.length;i++){
                var refStr=this.doForms[i].alias
                this.$refs[refStr][0].validate((valid) => {
                    if (valid) {
                        formTrueCount++
                    } else {
                      return false;
                    }
                });
              }
              if(formTrueCount == this.doForms.length){
                this.initCreateFlowsheet()
              }
            }else{
               this.$message.error("请先配置流程图")
            }
        }
    },
    resetDoFrom(){
      for(var i=0;i<this.doForms.length;i++){
        var obj = this.doForms[i].form
        for(var item in obj){
          if(Array.isArray(obj[item])){
            obj[item]=[]
          }else{
            obj[item]=""
          }
          
        }
        this.doForms[i].form =obj
      }
    },


    /*
      起草流程
    */

    //初始化起草流程数据 （下一节点、下一审批人）
    initCreateFlowsheet(){
      this.nextRect={}
      this.nextRectList=[]
      this.nextUserList=[]
      this.lastInfo={RECT:"",USER:[],TITLE:"",}
      this.getNextRectByModelItem(this.doMoedlItem)
    },
    //通过流程图获取下个节点和节点审批人
    getNextRectByModelItem(model){
        var rects=model.ACTIVITYMODELS
        var qdId=""
        for(var i=0;rects.length;i++){
          if(rects[i].ALIAS == "ks"){
              qdId=rects[i].SNOWID
              break;
          }
        }

        var path=model.ACTIVITYTRANSITIONMODELS
        for(var j=0;j<path.length;j++){
            if(path[j].SOURCEID == qdId){
              var next= this.getRectById(rects,path[j].TARGETID)
              this.nextRectList.push(next)
            }
        }

        this.nextUserList  =this.nextRectList[0].ACTIVITYUSERMODELS

        this.nextRect = this.nextRectList[0]
        this.lastInfo={
          RECT:this.nextRect.SNOWID,
          USER:[],
          TITLE:"",
        }
        this.lastModal=true

    },
    //节点选择事件
    rectChange(id){
      for(var i=0;i<this.nextRectList.length;i++){
        if(id==this.nextRectList[i].SNOWID){
          this.nextUserList =this.nextRectList[i].ACTIVITYUSERMODELS
          this.nextRect=this.nextRectList[i]
          this.lastInfo.USER = []
          break;
        }
      }
    },
    //通过节点ID获取节点对象
    getRectById(rects,id){
      var item =null
      for(var i=0;i<rects.length;i++){
        if(rects[i].SNOWID== id){
          item = rects[i]
          break;
        }
      }
      return item
    },
    //起草流程最终确认
    setLastDataForFlowsheet(){
        if(this.canDo){
            this.$refs.lForm.validate((valid) => {
                if (valid) {
                      var usersStr=""
                      for(var i=0;i<this.lastInfo.USER.length;i++){
                          var lid=this.lastInfo.USER[i]
                          var lobj=null
                          for(var j=0;j<this.nextUserList.length;j++){
                            if(this.nextUserList[j].USERID==lid){
                              lobj = this.nextUserList[j]
                              break;
                            }
                          }
                          if(lobj){
                            if(i==0){
                              usersStr=lobj.USERNAME+"("+lobj.DISPLAYNAME+")"
                            }else{
                              usersStr+="、"+lobj.USERNAME+"("+lobj.DISPLAYNAME+")"
                            }
                          }
                      }
                      this.$confirm('下一节点:'+this.nextRect.NAME+'　审批人：'+usersStr, '流程走向确认', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                      }).then(() => {
                        this.createFlowsheet(this.doForms)
                      }).catch(() => {
                          this.$message.info("已取消")    
                        });
                } else {
                   return false;
                }
            });
        }
    },
    //起草流程（创建起草入参数据）
    createFlowsheet(dataObj){
      var modelItem=this.doMoedlItem
      var qdId=""
      var qdName=""
      for(var i=0;i<modelItem.ACTIVITYMODELS.length;i++){
        if(modelItem.ACTIVITYMODELS[i].ALIAS == "ks"){
            qdId=modelItem.ACTIVITYMODELS[i].SNOWID
            qdName = modelItem.ACTIVITYMODELS[i].NAME
            break;
        }
      }

      var cms=[]
      var con={}

      for(var k=0;k<dataObj.length;k++){
          var form=dataObj[k].form
          var column =dataObj[k].column
          var fid=dataObj[k].snowid
          con[fid]={snowid:fid,name:dataObj[k].name,alias:dataObj[k].alias,datas:form}
          for(var j=0;j<column.length;j++){
            var v=null
            if(column[j].type=="2"){
              var tstrA=this.comjs.getDateStrByDate(form[column[j].id]) 
              v= tstrA.split(" ")[0]
            }else if(column[j].type=="3"){
              var tstrB=this.comjs.getDateStrByDate(form[column[j].id]) 
              v= tstrB.split(" ")[1]
            }else if(column[j].type=="7"){
              var tstrC=this.comjs.getDateStrByDate(form[column[j].id]) 
              v= tstrC
            }else{
              if(Array.isArray(form[column[j].id])){
                for(var l=0;l<form[column[j].id].length;l++){
                  if(l==0){
                    v=form[column[j].id][l]
                  }else{
                    v+=","+form[column[j].id][l]
                  }
                }
              }else{
                v = form[column[j].id]
              }
              
            }
            cms.push({
              FORMID:fid,
              FIELDNAME:column[j].label,
              FIELDVALUE:v,
            })
          }
      }

      //console.log(con)
      con=JSON.stringify(con);

      var exList=[]
      for(var m=0;m<this.lastInfo.USER.length;m++){
          var lid=this.lastInfo.USER[m]
          var lobj=null
          for(var n=0;n<this.nextUserList.length;n++){
            if(this.nextUserList[n].USERID==lid){
              lobj = this.nextUserList[n]
              break;
            }
          }
          if(lobj){
            exList.push({
              EXECUTOR:lobj.DISPLAYNAME,
              EXECUTORID:lobj.USERID,
              EXECUTORNAME:lobj.USERNAME,
              DEPTID:"0",
              DEPTNAME:"0",
            })
          }
      }
      var modalType=this.getModalTypeByModal(modelItem)
      var d={
        TITLE:this.lastInfo.TITLE,
        MODELID:modelItem.SNOWID,
        INITIATORID: this.$store.state.guid,
        INITIATOR:this.$store.state.loginName,
        INITIATORNAME:this.$store.state.name,
        DEPTID:"0",
        DEPTNAME:"0",
        TRACKINGMODEL:{
          MODELID:modelItem.SNOWID,
          TITLE:this.lastInfo.TITLE,
          CONTENT:con, //
          ACTIVITYID:qdId,
          ACTIVITYNAME:qdName,
          INITIATOR:this.$store.state.name,
          INITIATORID:this.$store.state.guid,
          NEXTACTIVITYID:this.nextRect.SNOWID,
          NEXTACTIVITYNAME:this.nextRect.NAME,
          OPINION:"0",
          SENDTO:"起草",
        },
        CONTENTMEMORYMODELS:cms,
        EXECUTORMODELS:exList,
        TYPE:modalType,
      }
      //console.log(d)
      this.sendCreate(d)
    },
    //调起草接口
    sendCreate(d){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(d, this.$store.state.token,this.$md5(this.comjs.sortAsc(d)).toUpperCase())
        this.$http.post("Process/CreateNewProcess", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("起草完成")
                    this.postModal=false
                    this.lastModal=false
                    this.afterSend()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    //起草完成
    afterSend(){
        this.getPostList()
        this.getFormList()
    }

  },
  computed: {},
  watch: {
  },
};
</script>
<style scoped>
  .btm{overflow: hidden;}
  .fromItemOut{overflow: hidden;width: 50%;float:left;}
  .halfFromItem{width: 90%!important;}
  .fullFromItem{width: 100%;}
  .fromTitle{font-size: 16px;font-weight: bold;margin-bottom: 20px;}
  .formList{overflow: hidden;margin-bottom: 20px;}
</style>
