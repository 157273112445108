<template>
  <div class="views-main">
    <div class="views-main-title">
      <p class="txt">升压站附件管理</p>
    </div>
    <div class="tableBox" style="top:50px;">
      <div class="views-main-search">
        <el-input v-model="name" placeholder="升压站名称" class="iw" ></el-input>
        <el-button type="primary" @click="searchPost">查询</el-button>
      </div>
      <div class="views-main-body">
        <el-table :data="postList" stripe style="width: 100%">
          <el-table-column prop="NAME" label="升压站名称"></el-table-column>
          <el-table-column prop="CREATETIME" label="创建时间"></el-table-column>
          <el-table-column label="操作" width="160">
            <template v-slot="scope">
              <el-button @click="openAttachment(scope.row)" type="text">查看附件</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination background layout="total, prev, pager, next" :current-page="PageIndex"
                       :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
      </div>
    </div>
    <el-dialog :title="htFileStr" :visible.sync="chooseFileModal" :before-close="close" width="800px"  :append-to-body="true">
      <p class="fileTitle">现有附件：</p>
      <div class="fileBox">
        <div class="fileItem" v-for="item in fileList" :key="item.SNOIWD" :title="item.FILENAME">
          <p class="name" @click="downFile(item.URL)">{{item.FILENAME}}</p>
          <div class="fileBtn">
            <span @click="downFile(item.URL)" style="color:#409EFF">下载</span>
            <span @click="delFile(item.SNOWID)" style="color:#F56C6C">删除</span>
          </div>
        </div>
        <div v-if="fileList.length==0" class="noFile">该升压站还未上传附件，请在下方上传。</div>
      </div>
      <p class="fileTitle" style="margin-bottom:20px;">上传附件：</p>
      <div style="overflow:hidden;">
        <el-upload ref="upload"  class=""  :multiple="true" :limit="5" action="" :http-request="uploadFun" :show-file-list="false">
          <el-button size="small" type="primary">点击上传</el-button>
        </el-upload>
      </div>
    </el-dialog>
  </div>
</template>

<script>
let h = document.body.clientHeight
export default {
  name: "SYZFJ",
  data() {
    return {
      canDo:true,
      typeId: '', // 升压站类型id
      name: '',
      postList: [],
      PageSize: 10,
      PageIndex: 1,
      Total: 0,
      currentObj: '',
      chooseFileModal:false,
      fileList:[],
      htFileStr:"",
      dialogMaxHeigh: 0
    }
  },
  created() {
  },
  mounted() {
    this.loadItem = null
    this.getTypeId()
    this.dialogMaxHeigh = h - 300
  },
  methods: {
    getTypeId() {
      this.showLoading("请稍后")
      var params = {
        NAME: '升压站'
      }
      var md5_data = this.comjs.requestDataEncrypt(params, this.$store.state.token, this.$md5(this.comjs.sortAsc(params)).toUpperCase())
      this.$http.post("MachineType/GetMachineTypeList", md5_data)
          .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
              this.typeId = response.data?.DATA[0]?.SNOWID
              this.getPostList()
            } else {
              this.errorRequest(response.data.DATA, response.data.MSG, true)
            }
          })
          .catch((error) => {
            this.hideLoading()
          });
    },
    getPostList() {
      this.showLoading("请稍后")
      var params = {
        PAGEINDEX: this.PageIndex,
        PAGESIZE: this.PageSize,
        NAME: this.name,
        MACHINETYPEID: [this.typeId],
      }
      var md5_data = this.comjs.requestDataEncrypt(params, this.$store.state.token, this.$md5(this.comjs.sortAsc(params)).toUpperCase())
      this.$http.post("Machine/GetMachine", md5_data)
          .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
              let data = JSON.parse(JSON.stringify(response.data.DATA))
              this.postList = data
              this.Total = response.data.TOTALCOUNT
            } else {
              this.postList = []
              this.Total = 0
              this.errorRequest(response.data.DATA, response.data.MSG, true)
            }
          })
          .catch((error) => {
            this.hideLoading()
            this.postList = []
            this.Total = 0
          });
    },
    searchPost(){
      this.PageIndex=1
      this.getPostList()
    },
    PGChange(e) {
      this.PageIndex = e
      this.getPostList()
    },
    // 获取附件数据
    getAttachmentData(){
      this.canDo=false
      this.showLoading("请稍后")
      var params = {
        FACTORYID: this.currentObj.FACTORYID
      }
      var md5_data = this.comjs.requestDataEncrypt(params, this.$store.state.token, this.$md5(this.comjs.sortAsc(params)).toUpperCase())
      this.$http.post("BoosterDataFile/GetBoosterDataFile", md5_data)
          .then((response) => {
            this.hideLoading()
            this.canDo=true
            if (response.data.SUCCESS) {
              let data = JSON.parse(JSON.stringify(response.data.DATA))
              this.fileList = data
            } else {
              this.errorRequest(response.data.DATA, response.data.MSG, true)
            }
          })
          .catch((error) => {
            this.hideLoading()
            this.canDo=true
          });
    },
    // 打开附件
    openAttachment(data){
      this.chooseFileModal = true
      this.currentObj = data
      this.htFileStr = data.NAME+"-附件列表"
      this.getAttachmentData()
    },
    close(){
      this.chooseFileModal = false
      this.currentObj = {}
      this.fileList = []
      this.htFileStr = ''
    },
    uploadFun(f){
      this.showLoading("请稍后...")
      let fd = new FormData();
      fd.append("file", f.file);
      let fname=f.file.name
      this.$upload.post("BoosterDataFile/UploadBoosterDataFile?FACTORYID="+this.currentObj.FACTORYID+"&FILENAME="+fname, fd)
          .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
              this.hideLoading()
              this.$message.success("上传成功")
              this.$refs.upload.clearFiles()
              this.getAttachmentData()
            }else{
              this.hideLoading()
              this.$message.error("上传失败")
            }
          })
          .catch((error) => {
            this.hideLoading()
          });
    },
    delFile(e){
      if(this.canDo){
        this.$confirm('确定删除附件？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.sendDelFile(e)
        }).catch(() => {
          this.$message.info("已取消")
        });
      }
    },
    downFile(e){
      window.open(e)
    },
    sendDelFile(id){
      this.canDo=false
      this.showLoading("请稍后")
      var md5_data =this.comjs.requestDataEncrypt({SNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:id})).toUpperCase())
      this.$http.post("BoosterDataFile/DeleteBoosterDataFile", md5_data)
          .then((response) => {
            this.canDo=true
            this.hideLoading()
            if (response.data.SUCCESS) {
              this.$message.success("已删除")
              this.getAttachmentData()
            } else {
              this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
          })
          .catch((error) => {
            this.$message.error("请求出错")
            this.hideLoading()
            this.canDo=true
          });
    },
  }
}
</script>

<style scoped>
.fileBox{overflow: hidden;margin: 20px 0;padding: 20px;background-color: #F8F8F9;}
.fileItem{border-radius: 3px;background-color: #FFFFFF;cursor: pointer;padding: 10px;float:left;margin-right: 20px;margin-bottom: 20px;width: 206px;}
.fileItem:nth-of-type(3n){margin-right: 0!important;}
.fileItem .name{font-size: 15px;font-weight: bold;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;color: #444;margin-bottom: 2px;}
.fileItem .keywords{font-size: 14px;font-weight: bold;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;margin-bottom: 5px;color: #909399;}
.fileItem .fileBtn{overflow: hidden;text-align: left;}
.fileItem span{margin-right: 10px;}
.noFile{text-align: center;font-size: 15px;color: #409EFF;}
.fileTitle{font-size: 16px;font-weight: bold;overflow: hidden;}
</style>