<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">上岗告知书</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <el-input type="textarea" placeholder="请输入上岗告知书内容"  v-model="textarea"></el-input >
           <el-button type="primary" @click="submitText()" style="float:right;margin-top:20px;">提交</el-button>
        </div>
    </div>
</template>
<script>
export default {
  name: "NoticeOfWork",
  data() {
    
    return {
        canDo:true,
        textarea:"",
        id:"",
    };
  },
  mounted() {
    this.loadItem=null
    this.getText()
  },
  methods: {
    getText(){
        this.showLoading("请稍后")
        var params={
            TYPE:"1",
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Login/GetNotification", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.textarea=response.data.DATA.CONTENT
                this.id=response.data.DATA.SNOWID
            } else {
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
        });
    },
    submitText(){
        if(this.canDo){
            if(this.textarea!= ""){
                this.canDo=false
                this.showLoading("请稍后")
                var params={
                    //SNOWID:this.id,
                    CONTENT:this.textarea,
                    TYPE:"1",
                }
                var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
                this.$http.post("Login/EditNotification", md5_data)
                    .then((response) => {
                    this.hideLoading()
                    this.canDo=true
                    if (response.data.SUCCESS) {
                            this.$message.success("已保存！")
                    } else {
                        this.errorRequest(response.data.DATA,response.data.MSG,true)
                    }
                    })
                    .catch((error) => {
                        this.$message.error("请求出错！")
                        this.hideLoading()
                        this.canDo=true   
                    });
            }else{
                this.$message.error("上岗告知书内容不能为空！")
            }
        }
       
    }
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style >
.el-textarea__inner{height: 500px;}
</style>