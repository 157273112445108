<template>
  <div class="content" style="width: 100%;height:100%;">
         <el-button type="primary" @click="createDJModal()" class="fr">起草流程</el-button>
         <el-table :data="dataList" stripe style="width: 100%;height:100%;">
                    <el-table-column prop="SUBMITUSERNAME" label="提交人员"></el-table-column>
                    <el-table-column prop="SUBMITQUESTION" label="提交问题"></el-table-column>
                    <el-table-column prop="RELATIONMACHINE" label="关联设备"></el-table-column>
                    <el-table-column prop="COMPLETECONTENT" label="完成情况"></el-table-column>
                    <el-table-column prop="FACTORYLEADEROPINION" label="风场场长审批意见"></el-table-column>
                    <el-table-column prop="DEPARTMENTLEADEROPINION" label="部门领导审批意见"></el-table-column>
                    <el-table-column prop="MANAGELEADEROPINION" label="分管领导审批意见"></el-table-column>
                    <el-table-column prop="MAINLEADEROPINION" label="总经理审批意见"></el-table-column>
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                            <el-button @click="showFiles(scope.row)" type="text" size="small" style="color:#409EFF">相关附件</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndexT" :page-size="PageSizeT" :total="TotalT" class="pg pgItem" @current-change="PGChangeT"></el-pagination>
        <el-dialog title="流程相关附件" :visible.sync="showFileModal" width="800px" top="50px" :height="dialogMaxHeigh">
               <MFL :processid="nowProcessid"></MFL>
        </el-dialog>
  </div>
</template>
<style scoped> 
.rowItem{width: 50%;float:left;overflow: hidden;font-size: 15px;}
</style>
<script>
var h=document.body.clientHeight
import MFL from "../components/ModelFileList.vue"
export default {
  name: 'MRFDL',
    components:{
    MFL,
  },
  props: {
  },
  data () {
    
      return {
        dataList:[],
        PageIndexT:1,
        PageSizeT:10,
        TotalT:0,

        dialogMaxHeigh:0,
        nowProcessid:"",
        showFileModal:false,
      }
  },
  mounted() {
      this.dialogMaxHeigh = h -300
      this.loadItem=null
      this.getList()
  },
  methods:{
    
    getList(){
        this.showLoading("请稍后")
        var params={
            PAGEINDEX:this.PageIndexT,
            PAGESIZE:this.PageSizeT,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("ShowBottom/GetShowBottomList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.dataList=response.data.DATA
                this.TotalT=response.data.TOTALCOUNT
            } else {
                this.dataList=[]
                this.TotalT=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.dataList=[]
                this.TotalT=0
        });
    },
    PGChangeT(e){
        this.PageIndexT=e
        this.getTaskList()
    },
    showFiles(item){
        this.nowProcessid=item.PROCESSID
        this.showFileModal=true
    },
    createDJModal(){
        this.showLoading("请稍后")
        var params={
            PAGEINDEX:1,
            PAGESIZE:1,
            NAME:"",
            ALIAS:"SHOWBOTTOM",
            VISIBILITY:true,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Model/GetModelList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS && response.data.DATA.length>0) {
                var d=response.data.DATA[0]
                window.open("/statics/pages/LCQC.html?mid="+d.SNOWID+"&token="+this.$store.state.token)
            } else {
            this.$message.error("请先配置安全交底和技术交底申请流程")
            }
            })
            .catch((error) => {
                this.$message.error("请求失败，请稍后再试")
                this.hideLoading()
            }); 
    },
  }
}
</script>

