<template>
  <div class="content">
    <div class="tableBox" style="top: 50px">
      <div class="views-main-search" style="margin:20px 20px 0 20px;">
        <el-input v-model="fname" placeholder="请输入故障名称" class="iws" ></el-input>
        <el-select v-model="fid" placeholder="请选风场查询" filterable  class="iws"  clearable @change="changeFCS">
                <el-option v-for="item in fcList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
        </el-select>
        <el-select v-model="mid" placeholder="请选风机查询" filterable  class="iws"  clearable >
                <el-option v-for="item in fjsList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
        </el-select>
        <el-date-picker v-model="fdate" type="datetime" placeholder="选择更新日期" class="iws" clearable></el-date-picker>
        <el-button type="primary" @click="searchPost">查询</el-button>
        <el-button type="success" @click="addPost" class="fr">添加</el-button>
      </div>
      <div class="views-main-body">
        <el-table :data="postList" stripe style="width: 100%">
          <el-table-column prop="FACTORYNAME" label="风场"></el-table-column>
          <el-table-column prop="FANNUMBER" label="风机"></el-table-column>
          <el-table-column prop="FAULTNAME" label="故障名称"></el-table-column>
          <el-table-column prop="FAULTDATE" label="故障时间"></el-table-column>
          <el-table-column label="操作" width="100">
            <template slot-scope="scope">
              <el-button
                @click="editPost(scope.row)"
                type="text"
                size="small"
                style="color: #e6a23c"
                >编辑</el-button
              >
              <el-button
                @click="delPost(scope.row)"
                type="text"
                size="small"
                style="color: #f56c6c"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="PageIndex"
          :page-size="PageSize"
          :total="Total"
          class="pg"
          @current-change="PGChange"
        ></el-pagination>
      </div>
    </div>
    <el-dialog :title="doTypeStr" :visible.sync="postModal" width="500px" append-to-body top="40px">
      <el-form
        :model="postInfo"
        status-icon
        :rules="postrules"
        ref="uForm"
        label-width="100px"
        class="demo-uForm"
      >
        <el-form-item label="风场" prop="FACTORYID">
            <el-select v-model="postInfo.FACTORYID" placeholder="请选择风场" filterable style="width:100%" @change="changeFC">
                    <el-option v-for="item in fcList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="风机" prop="FANID">
            <el-select v-model="postInfo.FANID" placeholder="请选择风机" filterable style="width:100%" @change="changeFJ">
                    <el-option v-for="item in fjList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="故障名称" prop="FAULTNAME">
          <el-input v-model="postInfo.FAULTNAME" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="故障日期" prop="FAULTDATE">
             <el-date-picker v-model="postInfo.FAULTDATE" type="datetime" placeholder="选择故障日期"  clearable style="width:100%"></el-date-picker>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="submitPostForm()">提交</el-button>
          <el-button @click="postModal = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<style scoped>
</style>
<script>
export default {
  name: "MRFDL",
  props: {},
  data() {
    var FACTORYID=(rule, value, callback) => {
        if (value === '') {
          callback(new Error('请选择风场'));
        }else {
          callback();
        }
    };
    var FANID=(rule, value, callback) => {
        if (value === '') {
          callback(new Error('请选择风机'));
        }else {
          callback();
        }
    };
    var FAULTNAME=(rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入故障名称'));
        }else {
          callback();
        }
    };
    var FAULTDATE=(rule, value, callback) => {
        if (value === '') {
          callback(new Error('请选择故障日期'));
        }else {
          callback();
        }
    };
    return {
      canDo: true,

     
      fjsList:[],
      fid: "",
      mid:"",
      fname:"",
      fdate:"",

      fcList:[],
      fjList:[],

      postList: [],
      PageIndex: 1,
      PageSize: 10,
      Total: 0,

      doTypeStr: "",
      doType: "",
      postModal: false,
      postInfo: {
        NAME: "",
      },
      postrules: {
        FANID: [{ validator: FANID, trigger: "blur" }],
        FACTORYID: [{ validator: FACTORYID, trigger: "blur" }],
        FAULTNAME: [{ validator: FAULTNAME, trigger: "blur" }],
        FAULTDATE: [{ validator: FAULTDATE, trigger: "blur" }],
      },
    };
  },
  mounted() {
    this.loadItem = null;
    this.getPostList()
    this.getFCList()
  },

  methods: {
    getFCList(){
        var params={}
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Factory/GetFactoryWithoutPage", md5_data)
            .then((response) => {
                this.canDo=true
                if (response.data.SUCCESS) {
                    this.fcList=response.data.DATA
                    
                }
            })
    },
    /*
      *查询 
    */

    //风场切换
    changeFCS(e){
      if(e!=""){
        for(var i=0;i<this.fcList.length;i++){
          if(this.fcList[i].SNOWID==e){
            this.getFJSList(e)
            break;
          }
        }
      }else{
        this.fjsList=[]
        this.mid=""
      }
    },
    //获取风机
    getFJSList(fid){
        var params={
          FACTORYID:fid,
          MACHINETYPEID: ["1552521440132206592","1587974615005794304","1567304123211190272"],
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Machine/GetMachine", md5_data)
            .then((response) => {
                this.canDo=true
                if (response.data.SUCCESS) {
                    this.fjsList=response.data.DATA
                    this.mid=""
                }
            })
    },

    /*
      *表单 
    */

    //风场切换
    changeFC(e){
      if(e==""){
        this.postInfo.FACTORYNAME=""
        this.fjList=[]
        this.postInfo.FANID=""
        this.postInfo.FANNUMBER=""
      }else{
        for(var i=0;i<this.fcList.length;i++){
          if(this.fcList[i].SNOWID==e){
            this.postInfo.FACTORYNAME = this.fcList[i].NAME
            this.getFJList(e,true)
            break;
          }
        }
      }
      
    },
    //风机切换
    changeFJ(e){
      if(e==""){
        this.postInfo.FANNUMBER
      }else{
        for(var i=0;i<this.fjList.length;i++){
          if(this.fjList[i].SNOWID==e){
            this.postInfo.FANNUMBER = this.fjList[i].NAME
            break;
          }
        }
      }

    },
    //获取风机
    getFJList(fid,t){
        var params={
          FACTORYID:fid,
          MACHINETYPEID: ["1552521440132206592","1587974615005794304","1567304123211190272"],
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Machine/GetMachine", md5_data)
            .then((response) => {
                this.canDo=true
                if (response.data.SUCCESS) {
                    this.fjList=response.data.DATA
                    if(t){
                      this.postInfo.FANID=""
                      this.postInfo.FANNUMBER=""
                    }
                }
            })
    },

    getPostList() {
      this.showLoading("请稍后");
      var params = {
        PAGEINDEX: this.PageIndex,
        PAGESIZE: this.PageSize,
        FACTORYID: this.fid,
        FANID:this.mid,
        FAULTNAME:this.fname,
        FAULTDATE:this.fdate
      };
      var md5_data = this.comjs.requestDataEncrypt(
        params,
        this.$store.state.token,
        this.$md5(this.comjs.sortAsc(params)).toUpperCase()
      );
      this.$http
        .post("Fault/GetFault", md5_data)
        .then((response) => {
          this.hideLoading();
          if (response.data.SUCCESS) {
            this.postList = response.data.DATA;
            this.Total = response.data.TOTALCOUNT;
          } else {
            this.postList = [];
            this.Total = 0;
            this.errorRequest(response.data.DATA, response.data.MSG, true);
          }
        })
        .catch((error) => {
          this.hideLoading();
          this.postList = [];
          this.Total = 0;
        });
    },
    searchPost() {
      this.PageIndex = 1;
      this.getPostList();
    },
    addPost() {
      this.postInfo = {
        FANID: "",
        FANNUMBER:"",
        FACTORYID:"",
        FACTORYNAME:"",
        FAULTNAME:"",
        FAULTDATE:"",
      };
      this.doTypeStr = "添加故障情况";
      this.doType = "1";
      this.postModal = true;
    },
    sendAdd() {
      this.canDo = false;
      this.showLoading("请稍后");
      var md5_data = this.comjs.requestDataEncrypt(
        this.postInfo,
        this.$store.state.token,
        this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase()
      );
      this.$http
        .post("Fault/CreateNewFault", md5_data)
        .then((response) => {
          this.canDo = true;
          this.hideLoading();
          if (response.data.SUCCESS) {
            this.$message.success("已添加");
            this.postModal = false;
            this.getPostList();
          } else {
            this.errorRequest(response.data.DATA, response.data.MSG, true);
          }
        })
        .catch((error) => {
          this.$message.error("请求出错");
          this.hideLoading();
          this.canDo = true;
        });
    },
    editPost(item) {
      this.getFJList(item.FACTORYID,false)
      this.postInfo = {
        SNOWID: item.SNOWID,
        FANID:item.FANID,
        FANNUMBER:item.FANNUMBER,
        FACTORYID:item.FACTORYID,
        FACTORYNAME:item.FACTORYNAME,
        FAULTNAME:item.FAULTNAME,
        FAULTDATE:new Date(item.FAULTDATE),
      };
      this.doTypeStr = "修改故障情况";
      this.doType = "2";
      this.postModal = true;
    },
    sendEdit() {
      this.canDo = false;
      this.showLoading("请稍后");
      var md5_data = this.comjs.requestDataEncrypt(
        this.postInfo,
        this.$store.state.token,
        this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase()
      );
      this.$http
        .post("Fault/EditFault", md5_data)
        .then((response) => {
          this.canDo = true;
          this.hideLoading();
          if (response.data.SUCCESS) {
            this.$message.success("已修改");
            this.postModal = false;
            this.getPostList();
          } else {
            this.errorRequest(response.data.DATA, response.data.MSG, true);
          }
        })
        .catch((error) => {
          this.$message.error("请求出错");
          this.hideLoading();
          this.canDo = true;
        });
    },
    delPost(item) {
      if (this.canDo) {
        this.$confirm("确定删除？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.sendDel(item.SNOWID);
          })
          .catch(() => {
            this.$message.info("已取消");
          });
      }
    },
    sendDel(id) {
      this.canDo = false;
      this.showLoading("请稍后");
      var md5_data = this.comjs.requestDataEncrypt(
        { SNOWID: id },
        this.$store.state.token,
        this.$md5(this.comjs.sortAsc({ SNOWID: id })).toUpperCase()
      );
      this.$http
        .post("Fault/DeleteFault", md5_data)
        .then((response) => {
          this.canDo = true;
          this.hideLoading();
          if (response.data.SUCCESS) {
            this.$message.success("已删除");
            this.postModal = false;
            this.getPostList();
          } else {
            this.errorRequest(response.data.DATA, response.data.MSG, true);
          }
        })
        .catch((error) => {
          this.$message.error("请求出错");
          this.hideLoading();
          this.canDo = true;
        });
    },
    PGChange(e) {
      this.PageIndex = e;
      this.getPostList();
    },
    submitPostForm() {
      if (this.canDo) {
        this.$refs.uForm.validate((valid) => {
          if (valid) {
            var dstr=this.comjs.getDateStrByDate(this.postInfo.FAULTDATE)
            this.postInfo.FAULTDATE =dstr
            if (this.doType == "1") {
              this.sendAdd();
            } else if (this.doType == "2") {
              this.sendEdit();
            }
          } else {
            return false;
          }
        });
      }
    },
  },
};
</script>

