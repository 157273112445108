<template>
    <div class="views-main">
        <div class="views-main-title">
            <el-button type="primary" icon="el-icon-arrow-left" style="float:left;margin-right:20px;" size="small" @click="backBBTZ">返回</el-button>
            <p class="txt">考核管理</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                <el-select v-model="khx" placeholder="选择考核项"  filterable  clearable class="iw">
                    <el-option v-for="item in khxList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                </el-select>
                <el-select v-model="khxm" placeholder="选择考核项目"  filterable  clearable class="iw">
                        <el-option v-for="item in khxmList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                </el-select>
                <el-date-picker v-model="rq" type="date" placeholder="选择日期" class="iw"></el-date-picker>
                <el-input v-model="sy" placeholder="请输入事由" class="iw"  style="display:none"></el-input>
                <el-input v-model="jg" placeholder="请输入结果" class="iw" style="display:none"></el-input>

                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-button type="success" @click="addPost" class="fr">添加</el-button>
                
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column prop="ITEMNAME" label="考核项"></el-table-column>
                    <el-table-column prop="PROJECTNAME" label="考核项目"></el-table-column>
                    <el-table-column prop="KPIDATE" label="考核日期"></el-table-column>
                    <el-table-column prop="KPICAUSE" label="事由"></el-table-column>
                    <el-table-column prop="KPIRESULT" label="结果"></el-table-column>
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                        <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button>
                        <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="500px">
            <el-form :model="postInfo" status-icon :rules="postrules"  ref="uForm" label-width="100px" class="demo-uForm">
                <el-form-item label="考核项" prop="ITEMID">
                    <el-select v-model="postInfo.ITEMID" placeholder="选择考核项"  filterable style="width:100%;" @change="changeKHX">
                        <el-option v-for="item in khxList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="考核项目" prop="PROJECTID">
                    <el-select v-model="postInfo.PROJECTID" placeholder="选择考核项目"  filterable style="width:100%;" @change="changeKHXM">
                        <el-option v-for="item in khxmList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="考核日期" prop="KPIDATE" >
                    <el-date-picker v-model="postInfo.KPIDATE" type="date" placeholder="选择日期" style="width:100%;"></el-date-picker>
                </el-form-item>
                <el-form-item label="事由" prop="KPICAUSE">
                    <el-input  v-model="postInfo.KPICAUSE" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="结果" prop="KPIRESULT">
                    <el-input  v-model="postInfo.KPIRESULT" autocomplete="off"></el-input>
                </el-form-item>
            
                <el-form-item>
                <el-button type="primary" @click="submitPostForm()">提交</el-button>
                <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
export default {
  name: "PostManage",
  data() {
    var ITEMID = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择考核项"));
      } else {
        callback();
      }
    };
    var PROJECTID = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择考核项目"));
      } else {
        callback();
      }
    };
    var KPIDATE = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择考核日期"));
      } else {
        callback();
      }
    };
    var KPICAUSE = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入事由"));
      } else {
        callback();
      }
    };
    var KPIRESULT = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入结果"));
      } else {
        callback();
      }
    };
    return {
        canDo:true,
        
        khxList:[],
        khx:"",
        khxmList:[],
        khxm:"",
        sy:"",
        jg:"",
        rq:"",

        
        name:"",
       

        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,


        doTypeStr:"",
        doType:"",
        postModal:false,
        postInfo:{},
        postrules: {
            ITEMID: [{ validator: ITEMID, trigger: "blur" }],
            PROJECTID: [{ validator: PROJECTID, trigger: "blur" }],
            KPIDATE: [{ validator: KPIDATE, trigger: "blur" }],
            KPICAUSE: [{ validator: KPICAUSE, trigger: "blur" }],
            KPIRESULT: [{ validator: KPIRESULT, trigger: "blur" }],
        },
    };
  },
  mounted() {
    this.loadItem=null
    this.getPostList()
    this.getKHXList()
    this.getKHXMList()
  },
  methods: {
    getKHXList(){
        var params={
            PAGEINDEX:1,
            PAGESIZE:500,
        }
        var md5_data =this.comjs.requestDataEncrypt({params}, this.$store.state.token,this.$md5(this.comjs.sortAsc({params})).toUpperCase())
        this.$http.post("Kpi/GetKpiItemList", md5_data)
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.khxList=response.data.DATA  
                } 
            })
    },
    changeKHX(e){
        if(e==""){
            this.postInfo.ITEMNAME=""
        }else{
            for(var i=0;i<this.khxList.length;i++){
                if(this.khxList[i].SNOWID == e){
                    this.postInfo.ITEMNAME=this.khxList[i].NAME
                    break;
                }
            }
        }
    },
    getKHXMList(){
        var params={
            PAGEINDEX:1,
            PAGESIZE:500,
        }
        var md5_data =this.comjs.requestDataEncrypt({params}, this.$store.state.token,this.$md5(this.comjs.sortAsc({params})).toUpperCase())
        this.$http.post("Kpi/GetKpiProjectList", md5_data)
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.khxmList=response.data.DATA  
                }
            })
    },
    changeKHXM(e){
        if(e==""){
            this.postInfo.PROJECTNAME=""
        }else{
            for(var i=0;i<this.khxmList.length;i++){
                if(this.khxmList[i].SNOWID == e){
                    this.postInfo.PROJECTNAME=this.khxmList[i].NAME
                    break;
                }
            }
        }
    },
    getPostList(){
        this.showLoading("请稍后")
        var rqstr=""
        if(this.rq){
            rqstr=this.comjs.getDateMinStrByDate(this.rq)
        }
        var params={
            PAGEINDEX:this.PageIndex,
            PAGESIZE:this.PageSize,
            ITEMID:this.khx,
            PROJECTID:this.khxm,
            KPIDATE:rqstr,
            KPICAUSE:this.sy,
            KPIRESULT:this.jg,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Kpi/GetKpiList", md5_data)
            .then((response) => {
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.postList=response.data.DATA
                    this.Total=response.data.TOTALCOUNT
                } else {
                    this.postList=[]
                    this.Total=0
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
        });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    addPost(){
        this.postInfo={
            ITEMID:"",
            ITEMNAME:"",
            PROJECTID:"",
            PROJECTNAME:"",
            KPIDATE:"",
            KPICAUSE:"",
            KPIRESULT:"",
            INDEXX:"",
        }
        this.doTypeStr="添加考核"
        this.doType="1"
        this.postModal=true
    },
    sendAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("Kpi/CreateNewKpi", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
            });
    },
    editPost(item){
        this.postInfo={
            SNOWID:item.SNOWID,
            ITEMID:item.ITEMID,
            ITEMNAME:item.ITEMNAME,
            PROJECTID:item.PROJECTID,
            PROJECTNAME:item.PROJECTNAME,
            KPIDATE:new Date(item.KPIDATE),
            KPICAUSE:item.KPICAUSE,
            KPIRESULT:item.KPIRESULT,
            INDEXX:item.INDEXX,
        }
        this.doTypeStr="修改考核"
        this.doType="2"
        this.postModal=true
    },
    sendEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("Kpi/EditKpi", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.postModal=false
                    this.getPostList();
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    delPost(item){
        if(this.canDo){
            this.$confirm('确定删除考核？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
            this.sendDel(item.SNOWID)
            }).catch(() => {
            this.$message.info("已取消")    
          });
        }
    },
    sendDel(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({SNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:id})).toUpperCase())
        this.$http.post("Kpi/DeleteKpi", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    submitPostForm() {
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    var dateStr=this.comjs.getDateMinStrByDate(this.postInfo.KPIDATE)
                    this.postInfo.KPIDATE = dateStr
                    if(this.doType=="1"){
                        this.sendAdd()
                    }else if(this.doType=="2"){
                        this.sendEdit()
                    }
                } else {
                    return false;
                }
            });
        }
    },

    backBBTZ(){
        this.$router.replace('/XTPTZH')
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style >
</style>