<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">检查管理</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                
               <el-select v-model="type" placeholder="检查类型"  clearable filterable  class="iw">
                    <el-option label="动态检查" value="1" ></el-option>
                    <el-option label="自查" value="2" ></el-option>
                </el-select>   
                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-button type="success" @click="addPost" class="fr">添加</el-button>
                
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column prop="TYPE" label="类型">
                        <template slot-scope="scope">
                            <span v-if="scope.row.TYPE=='1'">动态检查</span>
                            <span v-else>自查</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="STATUS" label="状态">
                        <template slot-scope="scope">
                            <span v-if="scope.row.STATUS=='0'" style="color:#E6A23C;">未完成</span>
                            <span v-else-if="scope.row.STATUS=='1'" style="color:#67C23A;">已完成</span>
                            <span v-else-if="scope.row.STATUS=='2'" style="color:#409EFF;">有提交记录</span>
                            <span v-else>/</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="CREATEUSERNAME" label="创建人"></el-table-column>
                    <el-table-column prop="REMARK" label="问题描述"></el-table-column>
                    <el-table-column prop="MACHINESTR" label="关联设备"></el-table-column>
                    <el-table-column label="操作" width="200">
                        <template slot-scope="scope">
                            
                            <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C" v-if="scope.row.STATUS!='2'">编辑</el-button>
                            <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
                            <el-button @click="zgPost(scope.row)" type="text" size="small" style="color:#409EFF">整改情况</el-button>
                            <el-button @click="xgztFun(scope.row)" type="text" size="small" style="color:#67C23A" v-if="scope.row.STATUS!='1'">标记为已完成</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="800px">
            <el-form :model="postInfo" status-icon :rules="postrules"  ref="uForm" label-width="100px" class="demo-uForm">
                <el-form-item label="检查类型">
                    <el-select v-model="postInfo.TYPE" placeholder="请选择类型"  filterable  style="width:100%;">
                            <el-option label="动态检查" value="1" ></el-option>
                            <el-option label="自查" value="2" ></el-option>
                    </el-select>
                </el-form-item>
                <!-- <el-form-item label="检查状态">
                    <el-select v-model="postInfo.STATUS" placeholder="请选择状态"  filterable  style="width:100%;">
                            <el-option label="未完成 " value="0" ></el-option>
                            <el-option label="已完成" value="1" ></el-option>
                            <el-option label="有提交记录" value="2" ></el-option>
                    </el-select>
                </el-form-item> -->
                <el-form-item label="问题描述" prop="REMARK">
                    <el-input v-model="postInfo.REMARK" autocomplete="off" type="textarea" class="ttaa"></el-input>
                </el-form-item>
                <el-form-item label="关联风场设备" >
                    <!-- <el-select v-model="fc" placeholder="请选择风场"  filterable  style="width:49%;margin-right:2%" @change="fcqh">
                            <el-option v-for="item in fcList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                    </el-select> -->
                    <el-select v-model="postInfo.MACHINESNOWIDLIST" placeholder="请选择设备" multiple filterable clearable style="width:100%;">
                            <el-option v-for="item in sbList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                <el-button type="primary" @click="submitPostForm()">提交</el-button>
                <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>


        <el-dialog title="问题整改进展说明" :visible.sync="zgModal" width="1050px" top="50px" >
                <div style="width:100%;overflow:hidden;margin-bottom:20px;">
                     <el-button type="success" @click="addzg" class="fr">添加</el-button>
                </div>
                <el-table :data="zgList" stripe style="width: 120%" :height="dialogMaxHeigh">
                    <el-table-column prop="CREATEUSERNAME" label="提交人"></el-table-column>
                    <el-table-column prop="REMARK" label="整改进展说明"></el-table-column>
                    <el-table-column prop="CREATETIME" label="提交时间"></el-table-column>
                    <el-table-column label="操作" width="120">
                        <template slot-scope="scope">
                            <el-button @click="fileStatusItem(scope.row)"  type="text" size="small" style="color:#409EFF">附件</el-button>
                            <el-button @click="editStatusItem(scope.row)"  type="text" size="small" style="color:#E6A23C">编辑</el-button>
                            <el-button @click="delStatusItem(scope.row)"   type="text" size="small" style="color:#F56C6C">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndexZ" :page-size="PageSizeZ" :total="TotalZ" class="pg pgItem" @current-change="PGChangeZ"></el-pagination>
        </el-dialog>
        <el-dialog :title="zgPostTitle" :visible.sync="zgPostModal" width="800px">
            <el-form :model="zgInfo" status-icon :rules="zgrules"  ref="zForm" label-width="100px" class="demo-uForm">
                <el-form-item label="整改进展说明" prop="REMARK">
                    <el-input v-model="zgInfo.REMARK" autocomplete="off" type="textarea" class="ttaa"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitZGPostForm()">提交</el-button>
                    <el-button @click="zgPostModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>

        <el-dialog title="附件管理" :visible.sync="chooseFileModal" width="800px"  :append-to-body="true">
            <p class="fileTitle">现有附件：</p>
            <div class="fileBox">
                <div class="fileItem" v-for="item in fl" :key="item.SNOIWD" :title="item.FILENAME">
                    <div class="fileOut">
                        <el-image style="width: 100%;" :src="item.PATH"  :preview-src-list="fileImgList" v-if="item.TYPE=='1'"></el-image>
                        <i class="el-icon-document" v-else></i>
                    </div>
                    <p class="name" @click="downFile(item.PATH)">{{item.FILENAME}}</p>
                    <div class="fileBtn">
                        <span @click="downFile(item.PATH)" v-if="item.TYPE=='2'" style="color:#409EFF">下载</span>
                        <span @click="delFile(item.SNOWID)" style="color:#F56C6C">删除</span>
                    </div>
                </div>
                <div v-if="fl.length==0" class="noFile">该问题还未上传附件</div>
            </div>
            <p class="fileTitle" style="margin-bottom:20px;">上传附件：</p>
            <div style="overflow:hidden;">
                <el-upload ref="upload"  class=""  :multiple="true" :limit="5" action="" :http-request="uploadFun">
                    <el-button size="small" type="primary">点击上传</el-button>
                </el-upload>
            </div>
        </el-dialog>
    </div>
</template>
<style>
    .ttaa{overflow: hidden;}
    .ttaa .el-textarea__inner{height:100px;}
</style>
<script>
var h=document.body.clientHeight
export default {
  name: "PostManage",
  data() {
    var REMARK = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入问题描述"));
      } else {
        callback();
      }
    };

    var REMARKZG = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入整改进展说明"));
      } else {
        callback();
      }
    };

    
    return {
        canDo:true,
        
        type:"",
       
        fc:"",
        fcList:[],
        sbList:[],

        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,


        doTypeStr:"",
        doType:"",
        postModal:false,
        postInfo:{},
        postrules: {
            REMARK: [{ validator: REMARK, trigger: "blur" }],
        },





        //整改
        jcId:"",
        dialogMaxHeigh:0,
        zgListTitle:"",
        zgModal:false,
        zgList:[],
        PageIndexZ:1,
        PageSizeZ:10,
        TotalZ:0,

        zgType:"",
        zgPostTitle:"",
        zgInfo:{},
        zgrules: {
            REMARK: [{ validator: REMARKZG, trigger: "blur" }],
        },
        zgPostModal:false,

        checkZGId:"",
        fileList:[],
        fileImgList:[],
        chooseFileModal:false,

        fl:[],



    };
  },
  mounted() {
    this.dialogMaxHeigh = h -300
    this.loadItem=null
    //this.getFcList()
    this.getPostList()
  },
  methods: {
    fcqh(e){
        this.postInfo.MACHINESNOWIDLIST=[]
        this.getSbByFc(e)
    },
    getFcList(){
        var md5_data =this.comjs.requestDataEncrypt({}, this.$store.state.token,this.$md5(this.comjs.sortAsc({})).toUpperCase())
        this.$http.post("Factory/GetFactoryWithoutPage", md5_data)
            .then((response) => {
                if (response.data.SUCCESS && response.data.DATA.length>0) {
                    this.fcList=response.data.DATA
                    this.fc=this.fcList[0].SNOWID
                } 
            }) 
    },
    getSbByFc(id){
        var params={
            // FACTORYID:id,
            // PAGEINDEX:1,
            // PAGESIZE:10000,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Machine/GetMachine", md5_data)
            .then((response) => {
                if (response.data.SUCCESS && response.data.DATA.length>0) {
                    this.sbList=response.data.DATA
                } 
            }) 
    },
    getPostList(){
        this.showLoading("请稍后")
        var params={
            PAGEINDEX:this.PageIndex,
            PAGESIZE:this.PageSize,
            type:this.type,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Defect/GetDefectList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                var list= response.data.DATA
                var nl=[]
                for(var i=0;i<list.length;i++){
                    var d=list[i]
                    d.MACHINESTR=""
                    if(d.MACHINELIST && d.MACHINELIST.length>0){
                        
                        for(var j=0;j<d.MACHINELIST.length;j++){
                            if(j==0){
                                d.MACHINESTR=d.MACHINELIST[j].NAME
                            }else{
                                d.MACHINESTR= d.MACHINESTR+"、"+d.MACHINELIST[j].NAME
                            }
                        }
                    }else{
                        d.MACHINESTR="/"
                    }
                    nl.push(d)
                }
                this.postList=nl
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
        });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    addPost(){
        this.postInfo={
            TYPE:"1",
            REMARK:"",
            //STATUS:"0",
            USERSNOWID:this.$store.state.guid,
            MACHINESNOWIDLIST:[],
        }
        this.getSbByFc("")
        this.doTypeStr="添加检查"
        this.doType="1"
        this.postModal=true
    },
    sendAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("Defect/CreateNewDefect", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    editPost(item){
        this.getSbByFc("")
        var ml=[]
        for(var i=0;i<item.MACHINELIST.length;i++){
            ml.push(item.MACHINELIST[i].SNOWID)
        }
        this.postInfo={
            DEFECTSNOWID:item.SNOWID,
            TYPE:item.TYPE,
            REMARK:item.REMARK,
            //STATUS:item.STATUS,
            USERSNOWID:item.CREATEUSERSNOWID,
            MACHINESNOWIDLIST:ml,
        }
        this.doTypeStr="修改检查"
        this.doType="2"
        this.postModal=true
    },
    sendEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("Defect/EditDefectInfo", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.postModal=false
                    this.getPostList();
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    delPost(item){
        if(this.canDo){
            this.$confirm('确定删除检查？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
            this.sendDel(item.SNOWID)
            }).catch(() => {
            this.$message.info("已取消")    
          });
        }

    },
    sendDel(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({DEFECTSNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({DEFECTSNOWID:id})).toUpperCase())
        this.$http.post("Defect/DeleteDefect", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    submitPostForm() {
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    if(this.doType=="1"){
                        this.sendAdd()
                    }else if(this.doType=="2"){
                        this.sendEdit()
                    }
                } else {
                    return false;
                }
            });
        }
    },

    //整改
    zgPost(item){
        this.jcId=item.SNOWID
        this.PageIndexZ=1
        this.getZgList()
        this.zgModal=true
        //this.zgListTitle=""
    },
    getZgList(){
        this.showLoading("请稍后")
        var params={
            PAGEINDEX:this.PageIndexZ,
            PAGESIZE:this.PageSizeZ,
            DEFECTSNOWID:this.jcId,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Defect/GetImprovementRecord", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.zgList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.zgList=[]
                this.TotalZ=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.zgList=[]
                this.TotalZ=0
        });
    },
    PGChangeZ(e){
        this.PAGEINDEXZ=e
        this.getZgList()
    },
    addzg(){
        this.zgType="1"
        this.zgPostTitle="添加问题整改进展说明"
        this.zgInfo={
            USERSNOWID:this.$store.state.guid,
            DEFECTSNOWID:this.jcId,
            REMARK:"",
        }
        this.zgPostModal=true
    },
    
    sendZGAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.zgInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.zgInfo)).toUpperCase())
        this.$http.post("Defect/SubmitImprovement", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.zgPostModal=false
                    this.getZgList();
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    editStatusItem(item){
        this.zgType="2"
        this.zgPostTitle="编辑问题整改进展说明"
        this.zgInfo={
            RECORDSNOWID:item.SNOWID,
            REMARK:item.REMARK,
        }
        this.zgPostModal=true
    },
    sendZGEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.zgInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.zgInfo)).toUpperCase())
        this.$http.post("Defect/EditImprovementRecord", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.zgPostModal=false
                    this.getZgList();
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    
    delStatusItem(item){
        if(this.canDo){
            this.$confirm('确定删除整改进展说明？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
                this.sendZGDel(item.SNOWID)
            }).catch(() => {
            this.$message.info("已取消")    
          });
        }

    },
    sendZGDel(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({RECORDSNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({RECORDSNOWID:id})).toUpperCase())
        this.$http.post("Defect/DeleteImprovementRecord", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    submitZGPostForm() {
        if (this.canDo) {
            this.$refs.zForm.validate((valid) => {
                if (valid) {
                    if(this.zgType=="1"){
                        this.sendZGAdd()
                    }else if(this.zgType=="2"){
                        this.sendZGEdit()
                    }
                } else {
                    return false;
                }
            });
        }
    },
    fileStatusItem(item){
        this.checkZGId=item.SNOWID
        this.getFileById()
    },

    getFileById(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({RECORDSNOWID:this.checkZGId}, this.$store.state.token,this.$md5(this.comjs.sortAsc({RECORDSNOWID:this.checkZGId})).toUpperCase())
        this.$http.post("Defect/GetFileList", md5_data)
            .then((response) => {
                 this.canDo=true   
                this.hideLoading()
                this.fl=response.data.DATA
                this.fileImgList=[]
                for(var i=0;i<this.fl.length;i++){
                    if(this.fl[i].TYPE=="1"){
                        this.fileImgList.push(this.fl[i].PATH)
                    }
                }
               this.chooseFileModal=true
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
                this.fileImgList=[]
                this.fl =[]
                this.chooseFileModal=true    
             });
    },

    uploadFun(f){
        var type =""
        if(f.file.type  === 'image/jpeg' || f.file.type === 'image/png'){
            type="1"
        }else{
            type="2"
        }
        this.showLoading("请稍后...")
        var fd = new FormData();
        fd.append("file", f.file);
        var fname=f.file.name
        this.$upload.post("Defect/UploadImprovement?RECORDSNOWID="+this.checkZGId+"&FILENAME="+fname+"&TYPE="+type, fd)
            .then((response) => {
            //console.log(response)
            this.hideLoading()
            if (response.data.SUCCESS) {
                    this.hideLoading()
                    this.$message.success("上传成功")
                    this.$refs.upload.clearFiles()
                    this.getFileById()
            }else{
                this.hideLoading()
                this.$message.error("上传失败")
            }
            })
            .catch((error) => {
            this.hideLoading()
        });
    },
    downFile(e){
        window.open(e)
    },
    delFile(e){
        //console.log(this.canDo)
        if(this.canDo){
            this.$confirm('确定删除？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
                this.sendDelFile(e)
            }).catch(() => {
            this.$message.info("已取消")    
          });
        }
    },
    sendDelFile(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({FILESNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({FILESNOWID:id})).toUpperCase())
        this.$http.post("Defect/DeleteImprovement", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.getFileById()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    xgztFun(item){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({DEFECTSNOWID:item.SNOWID}, this.$store.state.token,this.$md5(this.comjs.sortAsc({DEFECTSNOWID:item.SNOWID})).toUpperCase())
        this.$http.post("Defect/ManagerSetDefectComplate", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已标记")
                    this.getPostList();
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    }
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style scoped>
.fileBox{overflow: hidden;margin: 20px 0;padding: 20px;background-color: #F8F8F9;}
.fileItem{border-radius: 3px;background-color: #FFFFFF;cursor: pointer;padding: 10px;float:left;margin-right: 20px;margin-bottom: 20px;width: 206px;}
.fileItem:nth-of-type(3n){margin-right: 0!important;}
.fileItem .name{font-size: 15px;font-weight: bold;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;color: #444;margin-bottom: 2px;}
.fileItem .keywords{font-size: 14px;font-weight: bold;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;margin-bottom: 5px;color: #909399;}
.fileItem .fileBtn{overflow: hidden;text-align: left;}
.fileItem span{margin-right: 10px;}
.noFile{text-align: center;font-size: 15px;color: #409EFF;}
.fileTitle{font-size: 16px;font-weight: bold;overflow: hidden;}
.fileItem .fileOut{width:206px;height:206px;overflow:hidden;}
</style>
