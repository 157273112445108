import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'mint-ui/lib/style.css'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios';
import qs from 'qs';
import VueCookies from 'vue-cookies'
import md5 from 'js-md5';
import comjs from '../public/statics/js/common.js'

import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'
Vue.use(mavonEditor)
Vue.config.productionTip = false

// console.log("-------")
// console.log(router)
// const um={
//   path: '/UserManage',
//   name: 'UserManage',
//   component: () => import('./views/UserManage.vue')
// }
// router.addRoute('Home', um)

//addRoutes
//console.log(router.getRoutes())


//上传 ajax
const upload = axios.create({
  baseURL: comjs.baseUrl,
  timeout: 50000000,
  headers: {
    'Content-Type': 'multipart/form-data' ,
  },
})

Vue.prototype.$upload = upload;


//普通ajax
const $axios = axios.create({
  baseURL: comjs.baseUrl,
  timeout: 5000000,
});
Vue.prototype.$http = $axios;

//下载
const download = axios.create({
  baseURL: comjs.baseUrl,
  timeout: 50000000,
  responseType: 'blob',
})
download.interceptors.request.use(
  function (config) {
    var a = window.localStorage.getItem("ny_token")
    if (a) {
      config.headers['Authorization'] = "Bearer "+a
    }
    // if (xa) {
    //   config.headers['X-Authorization'] = "Bearer "+xa
    // }
    return config
  },
  function (error) {
    return Promise.reject(error)
  },
)
Vue.prototype.$download = download;


//组件
Vue.prototype.$qs = qs;
Vue.prototype.$md5 = md5;
Vue.prototype.comjs = comjs;
Vue.use(ElementUI);
Vue.use(VueCookies);
VueCookies.config('30d')

//注销
var pcLogout= ()=>{
  store.commit('setToken', "");
  localStorage.removeItem('setPostList')
  VueCookies.remove("setToken")
  if(router.currentRoute.fullPath!="/"){
    router.replace("/")
  }
}
Vue.prototype.logout = pcLogout

//接口异常处理
var errorRequest=(code,msg,show)=>{
    if(code){
      code=code.toString()
      var  c=code.substring(0, 1);
      if(c=="1"){
        ElementUI.Message.error(msg+",即将回到登录界面")
        setTimeout(function(){
          pcLogout()
        },2000)
      }else{
        if(show){
          ElementUI.Message.error(msg)
        }
      }
    }else{
      if(msg!=""){
        ElementUI.Message.error(msg)
      }
    }
}
Vue.prototype.errorRequest = errorRequest

//等待框
var loadItem=null
var showLoading=function(txt){
  loadItem = this.$loading({
    lock: true,
    text: txt,
    spinner: 'el-icon-loading',
    background: 'rgba(0, 0, 0, 0.7)'
  });
}
var hideLoading=function(){
  loadItem.close()
}


var getModalTypeByModal=function(modal){
  var type=""
  if(modal.ALIAS=="SHOWDOC"){   //资料库权限流程
    type="1"
  }else if(modal.ALIAS=="REFUEL"){  //加油审批
    type="2"
  }else if(modal.ALIAS=="INSPECTION"){ //定检管理
    type="3"
  }else if(modal.ALIAS=="PRODUCTION"){ //生产考核
    type="4"
  }else if(modal.ALIAS=="HIGHQUALITY"){ //优质方案
    type="5"
  }else if(modal.ALIAS=="STOPELECPOWER"){ //停电作业申请
      type="6"
  }else if(modal.ALIAS=="CQCHECK"){ //春秋检
      type="7"
  }else if(modal.ALIAS=="SHOWBOTTOM"){ //安全交底和技术交底内容
      type="8"
  }else if(modal.ALIAS=="CLEARDEFECT"){ //外委及自检缺陷明细及消缺明细及消缺完成报告
      type="9"
  }else if(modal.ALIAS=="ZTBAPPLY"){ //生产招投标业务申请预审批
      type="10"
  }else if(modal.ALIAS=="SEALAPPLY"){ //印章申请
      type="11"
  }else if(modal.ALIAS=="MONEYPAYOUT"){ //费用支出管理
      type="12"
  }else if (modal.ALIAS == "logPpproval") { //费用支出管理
    type = "13"
  }else if (modal.ALIAS == "WEEKWORK") { //定检周工作
    type = "14"
  }    
  return type
}
Vue.prototype.getModalTypeByModal = getModalTypeByModal

var getFormJsonByType=function(type){
  var url=""
  var data=null
  var key=""
  var value=""

  var list=[]
  
  if(type=="用户"){
    url=comjs.baseUrl+"UserManage/GetUserListWithoutPage"
    data=comjs.requestDataEncrypt({}, store.state.token,md5(comjs.sortAsc({})).toUpperCase())
    key="SNOWID"
    value="TRUENAME"
    list =  window.getFormJsonData(url,data,key,value)
  }else if(type=="车辆"){
    url=comjs.baseUrl+"Car/GetCarList"
    var p1={
      PAGEINDEX:1,
      PAGESIZE:10000,
    }
    data=comjs.requestDataEncrypt(p1, store.state.token,md5(comjs.sortAsc(p1)).toUpperCase())
    key="SNOWID"
    value="PLATENUMBER"
    list =  window.getFormJsonData(url,data,key,value)
  }else if(type=="资料库"){
    url=comjs.baseUrl+"Doc/GetDocList"
    var p2={
      PAGEINDEX:1,
      PAGESIZE:10000,
      ISNEEDPERMISSIONS:1,
    }
    data=comjs.requestDataEncrypt(p2, store.state.token,md5(comjs.sortAsc(p2)).toUpperCase())
    key="SNOWID"
    value="TITLE"
    list =  window.getFormJsonData(url,data,key,value)
  }else if(type=="定检任务"){
    url=comjs.baseUrl+"Process/GetUnBindCheckTaskList"
    var p3={}
    data=comjs.requestDataEncrypt(p3, store.state.token,md5(comjs.sortAsc(p3)).toUpperCase())
    key="SNOWID"
    value="NAME"
    list =  window.getFormJsonData(url,data,key,value)
  }

  return list
}
Vue.prototype.getFormJsonByType = getFormJsonByType



Vue.prototype.loadItem = loadItem
Vue.prototype.showLoading = showLoading
Vue.prototype.hideLoading = hideLoading

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
