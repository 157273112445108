<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">主创人设置</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <el-form :model="postInfo" status-icon :rules="postrules"  ref="uForm" label-width="100px" class="demo-uForm">
                <el-form-item label="主创人姓名" prop="MAINCREATOR">
                    <el-input v-model="postInfo.MAINCREATOR" autocomplete="off" style="width:600px"></el-input>
                </el-form-item>
            
                <el-form-item>
                <el-button type="primary" @click="submitPostForm()">提交</el-button>
                <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </div>

    </div>
</template>
<script>
export default {
  name: "zcrxsz",
  data() {
    var MAINCREATOR = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入主创人姓名"));
      } else {
        callback();
      }
    };
    return {
        canDo:true,

        postInfo:{
            SNOWID:"1",
            MAINCREATOR:"",
        },
        postrules: {
            MAINCREATOR: [{ validator: MAINCREATOR, trigger: "blur" }],
        },
    };
  },
  mounted() {
    this.loadItem=null
    this.getPostList()
  },
  methods: {
    getPostList(){
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({}, this.$store.state.token,this.$md5(this.comjs.sortAsc({})).toUpperCase())
        this.$http.post("HomePageStatistics/GetSysConfig", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postInfo.MAINCREATOR=response.data.DATA.MAINCREATOR
            } else {
                this.postInfo.MAINCREATOR=""
            }
            })
            .catch((error) => {
                this.postInfo.MAINCREATOR=""
        });
    },

    sendEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("HomePageStatistics/UpdateSysConfig", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.postModal=false
                    this.getPostList();
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    submitPostForm() {
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    this.sendEdit()
                } else {
                    return false;
                }
            });
        }
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style >
</style>