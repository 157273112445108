<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">费用支出管理</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                
                <el-select v-model="fc" placeholder="选择风场"  filterable  clearable class="iw">
                        <el-option v-for="item in fcList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                </el-select>
                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-button type="success" @click="addPost" class="fr">添加</el-button>
                
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column prop="FACTORYNAME" label="风场名称"></el-table-column>
                    <el-table-column prop="MONEY" label="支出资金"></el-table-column>
                    <el-table-column prop="USETIME" label="使用时间"></el-table-column>
                    <el-table-column prop="USEPERSONNAME" label="使用人"></el-table-column>
                    <el-table-column prop="AUDITPERSONNAME" label="审批人"></el-table-column>
                    <el-table-column prop="REMARK" label="备注"></el-table-column>
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                        <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button>
                        <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="500px">
            <el-form :model="postInfo" status-icon :rules="postrules"  ref="uForm" label-width="100px" class="demo-uForm">
                <el-form-item label="风场" prop="FACTORYSNOWID">
                    <el-select v-model="postInfo.FACTORYSNOWID" placeholder="请选择风场"  filterable  style="width:100%;">
                            <el-option v-for="item in fcList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="使用时间" prop="USETIMES">
                    <el-date-picker v-model="postInfo.USETIMES" type="date" placeholder="选择使用时间" style="width:100%"></el-date-picker>
                </el-form-item>
                <el-form-item label="支出资金" prop="MONEY">
                    <el-input v-model="postInfo.MONEY" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="使用人" prop="USEPERSONSNOWID">
                    <el-select v-model="postInfo.USEPERSONSNOWID" placeholder="请选择使用人" filterable style="width:100%">
                            <el-option v-for="item in userList" :key="item.SNOWID" :label="item.TRUENAME+'('+item.LOGINNAME+')'" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="审批人" prop="AUDITPERSONSNOWID">
                    <el-select v-model="postInfo.AUDITPERSONSNOWID" placeholder="请选择审批人" filterable style="width:100%">
                            <el-option v-for="item in userList" :key="item.SNOWID" :label="item.TRUENAME+'('+item.LOGINNAME+')'" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="备注" prop="REMARK">
                    <el-input v-model="postInfo.REMARK" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitPostForm()">提交</el-button>
                    <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
export default {
  name: "FYZC",
  data() {
    var REMARK = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入备注"));
      } else {
        callback();
      }
    };
    var USETIMES=(rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择使用时间"));
      } else {
        callback();
      }
    };
    var MONEY=(rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入支出资金'));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('支出资金只能是数字'));
        }else if(value<=0){
            callback(new Error('支出资金必须大于0'));
        }else {
          callback();
        }
    };
    var USEPERSONSNOWID=(rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择使用人"));
      } else {
        callback();
      }
    };
    var AUDITPERSONSNOWID=(rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择审批人"));
      } else {
        callback();
      }
    };
    var FACTORYSNOWID=(rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择风场"));
      } else {
        callback();
      }
    };
    return {
        canDo:true,
        
        fc:"",
        fcList:[],
        userList:[],
        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,


        doTypeStr:"",
        doType:"",
        postModal:false,
        postInfo:{},
        postrules: {
            USETIMES: [{ validator: USETIMES, trigger: "blur" }],
            MONEY: [{ validator: MONEY, trigger: "blur" }],
            USEPERSONSNOWID: [{ validator: USEPERSONSNOWID, trigger: "blur" }],
            AUDITPERSONSNOWID: [{ validator: AUDITPERSONSNOWID, trigger: "blur" }],
            REMARK: [{ validator: REMARK, trigger: "blur" }],
            FACTORYSNOWID: [{ validator: FACTORYSNOWID, trigger: "blur" }],
        },
    };
  },
  mounted() {
    this.loadItem=null
    this.getPostList()
    this.getUserList()
    this.getFCList()
  },
  methods: {
    getFCList(){
        var params={}
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Factory/GetFactoryWithoutPage", md5_data)
            .then((response) => {
                this.canDo=true
                if (response.data.SUCCESS) {
                    this.fcList=response.data.DATA
                }
            })
    },
    getUserList(){
        var params={
          PAGEINDEX:1,
          PAGESIZE:10000,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("UserManage/GetUserList", md5_data)
            .then((response) => {
                this.canDo=true
                if (response.data.SUCCESS) {
                    this.userList=response.data.DATA
                    
                }
            })
    },
    getPostList(){
        this.showLoading("请稍后")
        var params={
            PAGEINDEX:this.PageIndex,
            PAGESIZE:this.PageSize,
            FACTORYSNOWID:this.fc,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("YearCost/GetExpensesList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
        });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    addPost(){
        this.postInfo={
            FACTORYSNOWID:"",
            USETIMES:"",
            MONEY:"",
            USEPERSONSNOWID:"",
            AUDITPERSONSNOWID:"",
            REMARK:"",
        }
        this.doTypeStr="添加费用支出"
        this.doType="1"
        this.postModal=true
    },
    sendAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        var params={
            USETIME:this.comjs.getDateMinStrByDate(this.postInfo.USETIMES),
            MONEY:this.postInfo.MONEY,
            USEPERSONSNOWID:this.postInfo.USEPERSONSNOWID,
            AUDITPERSONSNOWID:this.postInfo.AUDITPERSONSNOWID,
            REMARK:this.postInfo.REMARK,
            FACTORYSNOWID:this.postInfo.FACTORYSNOWID,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("YearCost/CreateExpenses", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    editPost(item){
        this.postInfo={
            SNOWID:item.SNOWID,
            FACTORYSNOWID:item.FACTORYSNOWID,
            USETIMES:new Date(item.USETIME),
            MONEY:item.MONEY,
            USEPERSONSNOWID:item.USEPERSONSNOWID,
            AUDITPERSONSNOWID:item.AUDITPERSONSNOWID,
            REMARK:item.REMARK,
        }
        this.doTypeStr="修改费用支出"
        this.doType="2"
        this.postModal=true
    },
    sendEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        var params={
            SNOWID:this.postInfo.SNOWID,
            USETIME:this.comjs.getDateMinStrByDate(this.postInfo.USETIMES),
            MONEY:this.postInfo.MONEY,
            USEPERSONSNOWID:this.postInfo.USEPERSONSNOWID,
            AUDITPERSONSNOWID:this.postInfo.AUDITPERSONSNOWID,
            REMARK:this.postInfo.REMARK,
            FACTORYSNOWID:this.postInfo.FACTORYSNOWID,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("YearCost/EditExpensesList", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.postModal=false
                    this.getPostList();
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    delPost(item){
        if(this.canDo){
            this.$confirm('确定删除费用支出？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
            this.sendDel(item.SNOWID)
            }).catch(() => {
            this.$message.info("已取消")    
          });
        }

    },
    sendDel(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({SNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:id})).toUpperCase())
        this.$http.post("YearCost/DeleteExpenses", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    submitPostForm() {
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    if(this.doType=="1"){
                        this.sendAdd()
                    }else if(this.doType=="2"){
                        this.sendEdit()
                    }
                } else {
                    return false;
                }
            });
        }
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style >
</style>