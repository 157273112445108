<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">安规考试</p>
        </div>
        <div class="tableBox" style="top:50px;" v-if="postList.length>0">
                <div class="ksItem" v-for="item in postList" :key="item.SNOWID" @click="ksDetail(item)">
                    <p class="t">{{item.NAME}}</p>
                    <p class="n" v-if="item.ks">
                        <span v-if="item.ks.STATUS=='0'" style="color:#E6A23C">
                            状态：未答卷　<span>点击答卷</span>
                        </span>
                        <span v-else-if="item.ks.STATUS=='1'" style="color:#67C23A">
                            状态：已交卷　<span>点击查看</span>
                        </span>
                        <span v-else-if="item.ks.STATUS=='2'" style="color:#67C23A">
                            状态：已批阅　<span>得分：{{item.ks.SCORE}}</span> <span>点击查看</span>
                        </span>
                    </p>
                    <p class="n" v-else style="color:#909399"> 
                        您不在本次考试安排中
                    </p>
                </div>
        </div>
        <div class="tableBox" style="top:50px;" v-else>
            <p class="ksNull">暂无 安规考试 安排</p>
        </div>
    </div>
</template>
<script>
export default {
  name: "KSFL",
  data() {

    return {

        mainList:[],

        postList:[],
        canDo:true,

        username:"",
       

        
    };
  },
  mounted() {
    this.username=this.$store.state.name,
    this.loadItem=null
    this.getMainList()
    
  },
  methods: {
   getPostList(){
        this.showLoading("请稍后")
        var params={
            PAGEINDEX:1,
            PAGESIZE:100,
            NAME:"安规考试",
            QuestionGroupID:"",
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Examination/GetExaminationList", md5_data)
            .then((response) => {
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.postList=response.data.DATA
                    this.setPostData()
                }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
            });
    },
    getMainList(){
        var params={
            PAGEINDEX:1,
            PAGESIZE:500,
            ExaminationName:"",
            USERID:this.$store.state.guid,
            UserName:"",
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("RelationOfUserAndExamination/GetRelationOfUserAndExaminationList ", md5_data)
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.mainList=response.data.DATA
                    this.getPostList()
                } 
            })

    },
    setPostData(){
        var nl=[]
        for(var i=0;i< this.postList.length;i++){
            var p=this.postList[i]
            p.ks=null
            for(var j=0;j<this.mainList.length;j++){
                var m=this.mainList[j]
                if(m.EXAMINATIONID == p.SNOWID){
                    p.ks=m
                    break;
                }
            }
            nl.push(p)
        }
        this.postList=nl
    },
    ksDetail(item){
        if(item.ks){
             window.open("/statics/pages/KSDT.html?token="+this.$store.state.token+"&kid="+item.SNOWID)
        }
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style scoped>

    .ksItem{width: 320px;height: 100px;border-radius: 3px;cursor: pointer;background-color: #FFFFFF;font-size: 16px;margin-left: 30px;margin-top: 30px;float:left;}
    .ksItem .t{margin-top: 24px;text-align: center;font-weight: bold;color: #409EFF;}
    .ksItem .n{margin-top: 10px;text-align: center;font-size: 15px;color: #333333;}

    .ksNull{text-align: center;font-size: 18px;color: #666;margin-top: 300px;}
</style>