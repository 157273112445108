<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">资料库权限查看</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                
                
                <el-select v-model="userId" placeholder="用户"  filterable  clearable class="iws">
                        <el-option v-for="item in userList" :key="item.SNOWID" :label="item.TRUENAME+'('+item.LOGINNAME+')'" :value="item.SNOWID" ></el-option>
                </el-select>

                <el-select v-model="docId" placeholder="资料库"  filterable  clearable class="iws">
                        <el-option v-for="item in docList" :key="item.SNOWID" :label="item.TITLE" :value="item.SNOWID" ></el-option>
                </el-select>


                <el-button type="primary" @click="searchPost">查询</el-button>       
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                     <el-table-column prop="USERNAME" label="用户名称"></el-table-column>
                    <el-table-column prop="SYSNAME" label="资料库名称"></el-table-column>
                    <el-table-column prop="CANCOUNT" label="最大查看次数"></el-table-column>
                    <el-table-column prop="HAVECOUNT" label="已查看次数"></el-table-column>
                    <el-table-column prop="ENDTIME" label="截止日期"></el-table-column>
                    <el-table-column prop="CREATETIME" label="创建日期"></el-table-column>
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                        <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button>
                        <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="500px">
            <el-form :model="postInfo" status-icon :rules="postrules"  ref="uForm" label-width="120px" class="demo-uForm">
                <el-form-item label="最大查看次数" prop="CANCOUNT">
                    <el-input v-model="postInfo.CANCOUNT" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="已查看次数" prop="HAVECOUNT">
                    <el-input v-model="postInfo.HAVECOUNT" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="截止日期" prop="ENDTIME">
                    <el-date-picker v-model="postInfo.ENDTIME" type="datetime" placeholder="选择日期时间"></el-date-picker>
                </el-form-item>
            
                <el-form-item>
                <el-button type="primary" @click="submitPostForm()">提交</el-button>
                <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
export default {
  name: "PostManage",
  data() {
    var ENDTIME = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择截止日期"));
      } else {
        callback();
      }
    };
    var CANCOUNT= (rule, value, callback) => {
      if (value === '') {
          callback(new Error('请输入最大查看次数'));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('最大查看次数只能是数字'));
        }else if(!/^\+?(0|[1-9][0-9]*)$/.test(value)){
            callback(new Error('只能输入大于等于0的整数'));
        }else {
          callback();
        }
    };
    var HAVECOUNT= (rule, value, callback) => {
      if (value === '') {
          callback(new Error('请输入已查看次数'));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('已查看次数只能是数字'));
        }else if(!/^\+?(0|[1-9][0-9]*)$/.test(value)){
            callback(new Error('只能输入大于等于0的整数'));
        }else {
          callback();
        }
    };
    return {
        canDo:true,
        
        userId:"",
        userList:[],
        docId:"",
        docList:[],



        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,


        doTypeStr:"",
        doType:"",
        postModal:false,
        postInfo:{},
        postrules: {
            ENDTIME: [{ validator: ENDTIME, trigger: "blur" }],
            CANCOUNT: [{ validator: CANCOUNT, trigger: "blur" }],
            HAVECOUNT: [{ validator: HAVECOUNT, trigger: "blur" }],
        },
    };
  },
  mounted() {
    this.loadItem=null
    this.getUserList()
    this.getDocList()
    this.getPostList()
  },
  methods: {
    getUserList(){
        var params={
            PAGEINDEX:1,
            PAGESIZE:1000,
            ISADMIN:'0',
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("UserManage/GetUserList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.userList=response.data.DATA
            } 
            })
    },
    getDocList(){
        var params={
            PAGEINDEX:1,
            PAGESIZE:1000,
            ISNEEDPERMISSIONS:1,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Doc/GetDocList", md5_data)
            .then((response) => {
            this.hideLoading()
                if (response.data.SUCCESS) {
                    var d=response.data.DATA
                    var nl=[]
                    for(var i=0;i<d.length;i++){
                        if(d[i].ISNEEDPERMISSIONS==1){
                            nl.push(d[i])
                        }
                    }
                    this.docList=nl
                } 
            })
    },
    getPostList(){
        this.showLoading("请稍后")
        var params={
            PAGEINDEX:this.PageIndex,
            PAGESIZE:this.PageSize,
            USERID:this.userId,
            SYSID:this.docId
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("SysAccount/CheckPermission", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
        });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    editPost(item){
        this.postInfo={
            SNOWID:item.SNOWID,
            CANCOUNT:item.CANCOUNT,
            HAVECOUNT:item.HAVECOUNT,
            ENDTIME:new Date(item.ENDTIME)
        }
        this.doTypeStr=item.USERNAME+"-"+item.SYSNAME+"资料库查看权限修改"
        this.doType="2"
        this.postModal=true
    },
    sendEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("SysAccount/EditSysAccountPower", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.postModal=false
                    this.getPostList();
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    delPost(item){
        if(this.canDo){
            this.$confirm('确定删除：'+item.USERNAME+'的'+item.SYSNAME+'查看权限？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
                this.sendDel(item.SNOWID)
            }).catch(() => {
                this.$message.info("已取消")    
          });
        }

    },
    sendDel(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({SNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:id})).toUpperCase())
        this.$http.post("SysAccount/DeleteSysAccountPower", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    submitPostForm() {
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    //console.log(this.postInfo)
                    this.postInfo.CANCOUNT = parseInt(this.postInfo.CANCOUNT)
                    this.postInfo.HAVECOUNT = parseInt(this.postInfo.HAVECOUNT)
                    this.postInfo.ENDTIME = this.comjs.getDateStrByDate(this.postInfo.ENDTIME)
                    this.sendEdit()
                } else {
                    return false;
                }
            });
        }
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style >
</style>