<template>
    <div id="mapCon" :style="{width:width+ 'px',height:height+ 'px'}"></div>
</template>
<script>
import * as turf from '@turf/turf'
export default {
  name: "MAP",
  props: {
    width: {
      type:Number,
      default:600
    },
    height:{
       type:Number,
      default:450
    },
    lat:{
       type:Number,
        default:32.151818
    },
    lng:{
       type:Number,
        default:118.711152
    },
    zoom:{
       type:Number,
        default:14
    },
  },
  data() {
    return {
      map:null,
      markers:[],
    };
  },
  mounted() {
    this.loadItem=null
    this.initMap()
  },
  methods: {
    initMap(){
        var center = new window.qq.maps.LatLng(this.lat, this.lng);
        this.map = new window.qq.maps.Map(document.getElementById("mapCon"), {
            center: center,
            zoom: this.zoom,
            mapTypeControlOptions: {
                mapTypeIds: [
                    window.qq.maps.MapTypeId.ROADMAP,
                    window.qq.maps.MapTypeId.SATELLITE,
                    window.qq.maps.MapTypeId.HYBRID
                ],
                //设置控件位置相对上方中间位置对齐
                position: window.qq.maps.ControlPosition.TOP_LEFT
            },
            panControlOptions: { position: window.qq.maps.ControlPosition.TOP_RIGHT },
            zoomControlOptions: { position: window.qq.maps.ControlPosition.RIGHT_TOP, }
        });
    },
    getMap(){
      return this.map
    },
    addMarker(lat,lng){
        var center = new window.qq.maps.LatLng(lat, lng);
        var marker= new window.qq.maps.Marker({
            position: center,
            map: this.map,
        })
        this.markers.push(marker)
        return marker
    },
    clearMarkers(){
        for(var i=0;i<this.markers.length;i++){
            this.markers[i].setMap(null)
        }
        this.markers=[]
    },

    //腾讯坐标转WKT 字符串
    getWKTByTXPolygon(patn){
      var str="("
      var f=this.flagIsRingPolygon(patn)
      var elems=patn.elems
      if(f){
          for(var k=0;k<elems.length;k++){
            var kelems=elems[k].elems
            if(k==0){
              str +="("
            }else{
              str +=",("
            }

            for(var j=0;j<kelems.length;j++){
                if(j==0){
                    str +=kelems[j].lng+" "+kelems[j].lat
                }else{
                    str +=","+kelems[j].lng+" "+kelems[j].lat
                }
            }
            str +=","+kelems[0].lng+" "+kelems[0].lat
            str +=")"
          }
          str +=")"
      }else{
          for(var i=0;i<elems.length;i++){
              if(i==0){
                  str +="("+elems[i].lng+" "+elems[i].lat
              }else{
                  str +=","+elems[i].lng+" "+elems[i].lat
              }
          }
          str +=","+elems[0].lng+" "+elems[0].lat
          str+="))"
      }
      return str
    },

    //WKT字符串转腾讯坐标
    getTXPathByWKTStr(str){
      var zb={isMulti:false,arr:[]}
      if(str.indexOf("MULTIPOLYGON")!= -1){  //多个多边形
          zb.isMulti=true
          str = str.replace("MULTIPOLYGON","")
          str = str.replace("(((","")
          str = str.replace(")))","")
          var m_strs=str.split(")),((")
          var marr=[]
          for(var i=0;i<m_strs.length;i++){
              var mstrArr= this.getTXItemPathByStr(m_strs[i])
              marr.push(mstrArr)
          }
          zb.arr = marr
      }else if(str.indexOf("POLYGON")!= -1){  //单个多边形
          str = str.replace("POLYGON","")
          str = str.replace("((","")
          str = str.replace("))","")
          zb.arr=this.getTXItemPathByStr(str)
      }
      return zb
    },
    getTXItemPathByStr(str){
       var arr=[]
       if(str.indexOf("),(")!= -1){   //环多边形
            var h_strs=str.split("),(")
            for(var i=0;i<h_strs.length;i++){
                var ph_strs=h_strs[i].split(",")  
                var arrItem=[]
                for(var k=0;k<ph_strs.length-1;k++){
                    var hp=ph_strs[k].split(" ")
                    arrItem.push(new window.qq.maps.LatLng(parseFloat(hp[1]),parseFloat(hp[0])))
                }
                arr.push(arrItem)
            }
          }else{    //普通多边形
            var p_strs=str.split(",")
            for(var j=0;j<p_strs.length-1;j++){
                var pp=p_strs[j].split(" ")
                arr.push(new window.qq.maps.LatLng(parseFloat(pp[1]),parseFloat(pp[0])))
            }
      }
      return arr
    },

    /*
    * 坐标判断
    * turf.js
    */

    //判断腾讯多边形坐标是否为环多边形
    flagIsRingPolygon(path) {
        var flag = true
        if (path.elems[0].length == undefined) {
            flag=false
        }
        return flag
    },

    //腾讯多边形转Turf 多边形
    getTurfPolygonByTx(elems) {
        var coordinates = []
        for (var i = 0; i < elems.length; i++) {
            coordinates.push([elems[i].lng, elems[i].lat])
        }
        coordinates.push([elems[0].lng, elems[0].lat])
        return turf.polygon([coordinates])
    },
    //腾讯点坐标转Turf点坐标
    getTurfPointByTx(point) {
        return turf.point([point.lng, point.lat])
    },
    //判断两个多边形是否包含
    isInclude(p1, p2) {
        return turf.booleanContains(p1, p2);
    },
    //判断两个多边形是否重叠 （在内部和外部 false，有交集true)
    isOverlay(p1, p2) {
        return turf.booleanOverlap(p1, p2)
    },
    //判断点是否在多边形内
    isPointInPolygon(pt, poly){
      return turf.booleanPointInPolygon(pt, poly);
    },

    //获取多边形中心点
    getPolygonCenter(polygon) {
        return  turf.centerOfMass(polygon);
    },

    //turf坐标 转腾讯坐标
    turfCoordToTxPoint(coordinates) {
        return new window.qq.maps.LatLng(coordinates[1], coordinates[0])
    },

    //获取turf坐标集合中心点(返回腾讯坐标)
    getTurfCoorListCenter(list){
        var center=null
        if(list.length == 1){
          var p=list[0].geometry.coordinates
          center=this.turfCoordToTxPoint(p)
        }else if(list.length == 2){
          var p0 =turf.point(list[0].geometry.coordinates);
          var p1 =turf.point(list[1].geometry.coordinates);
          var midpoint = turf.midpoint(p0, p1)
          center = this.turfCoordToTxPoint(midpoint.geometry.coordinates)
        }else if(list.length > 2){
          var pl=[]
          for(var i=0;i<list.length;i++){
            var pItem=list[i].geometry.coordinates
            pl.push(pItem)
          }
          pl.push(list[0].geometry.coordinates)
          var polygon = turf.polygon([pl])
          var trufcenter = this.getPolygonCenter(polygon)
          center = this.turfCoordToTxPoint(trufcenter.geometry.coordinates)
        }
        return center
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style >
</style>