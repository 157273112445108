<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">我的待阅</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                <el-select v-model="status" placeholder="点击选择状态"  clearable filterable  class="iw">
                        <el-option label="待阅" value="reading" ></el-option>
                        <el-option label="已阅" value="readed" ></el-option>
                </el-select>
                <el-button type="primary" @click="searchPost">查询</el-button>
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                     <el-table-column type="expand">
                        <template slot-scope="props">
                            <div class="moreRow">
                                <div class="formLiBox" v-for="item in props.row.cons" :key="item.alias">
                                    <div class="moreRowTit">{{item.name}}</div>
                                    <div class="fromConOut">
                                       <div class="fromConItem" v-for="datas in item.datas" :key="datas.key">
                                            {{datas.key}}：{{datas.value}}
                                       </div>
                                    </div>
                                </div>
                                    
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="TITLE" label="标题"></el-table-column>
                    <el-table-column prop="INITIATORNAME" label="起草人"></el-table-column>
                    <el-table-column prop="CREATED" label="起草时间"></el-table-column>
                    <el-table-column prop="TRACKINGMODEL.CREATED" label="上个节点处理时间"></el-table-column>
                    <el-table-column prop="TRACKINGMODEL.INITIATORNAME" label="上个节点处理人"></el-table-column>
                    <el-table-column prop="TRACKINGMODEL" label="上个节点处理意见">
                        <template slot-scope="scope">
                            <span v-if="scope.row.TRACKINGMODEL.OPINION!='0'">{{scope.row.TRACKINGMODEL.OPINION}}</span>
                            <span v-else>/</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                        <el-button @click="detail(scope.row)" type="text" size="small" style="color:#E6A23C">审批记录</el-button>
                        <el-button @click="toRead(scope.row)"  type="text" size="small" style="color:#409EFF" v-if="scope.row.STATUS=='reading'">改为已阅</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  name: "PostManage",
  data() {
    
    return {
        canDo:true,
        
        status:"reading",
       

        postList:[],

    };
  },
  mounted() {
    this.loadItem=null
    this.getPostList()
  },
  methods: {
    getPostList(){
        this.showLoading("请稍后")
        var params={
            EXECUTORID:this.$store.state.guid,
            STATUS:this.status,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Process/GetAgentReadList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                var d=response.data.DATA
                    for(var j=0;j<d.length;j++){
                        var content=eval("(" + d[j].TRACKINGMODEL.CONTENT + ")")   
                
                        d[j].cons=[]
                        for(var item in content){
                            var datas=[]
                            for(var i=0;i<d[j].CONTENTMEMORYMODELS.length;i++){
                                if(d[j].CONTENTMEMORYMODELS[i].FORMID == item){
                                    datas.push({key:d[j].CONTENTMEMORYMODELS[i].FIELDNAME,value:d[j].CONTENTMEMORYMODELS[i].FIELDVALUE})
                                }
                            }
                            d[j].cons.push({
                                    alias:content[item].alias,
                                    name:content[item].name,
                                    datas:datas
                                })
                        }
                    }
                    
                    //console.log(d)
                    this.postList=d
            } else {
                this.postList=[]
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
        });
    },
    searchPost(){
        this.getPostList()
    },
    detail(item){
        window.open("/statics/myflow-master/index.html?token="+this.$store.state.token+"&type=3&fid="+item.MODELID+"&pid="+item.SNOWID)
    },
    toRead(item){
        if(this.canDo){
            this.canDo=false
            this.showLoading("请稍后")
            var params={
                SNOWID:item.SNOWID,
            }
            var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
            this.$http.post("Process/AgentReaded", md5_data)
                .then((response) => {
                    this.canDo=true
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.$message.success("已阅")
                        this.getPostList()
                    } else {
                        this.errorRequest(response.data.DATA,response.data.MSG,true)
                    }
                })
                .catch((error) => {
                    this.$message.error("请求出错")
                    this.hideLoading()
                    this.canDo=true   
                });
        }
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style >
</style>