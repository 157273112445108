<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">视频监控管理</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                
                <el-input v-model="name" placeholder="请输入名称" class="iw" ></el-input>
                <el-select v-model="type" placeholder="选择类型"  filterable  clearable class="iwu">
                        <el-option label="风机监控" value="1" ></el-option>
                        <el-option label="线路监控" value="2" ></el-option>
                        <el-option label="升场站监控" value="3" ></el-option>
                        <el-option label="其他监控" value="4" ></el-option>
                        <el-option label="可见光" value="5" ></el-option>
                        <el-option label="热成像" value="6" ></el-option>
                        <el-option label="全景" value="7" ></el-option>
                        <el-option label="森林防火" value="8" ></el-option>
                </el-select>
                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-button type="success" @click="addPost" class="fr">添加</el-button>
                
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column prop="DISPLAYNAME" label="名称"></el-table-column>
                     <el-table-column prop="TYPE" label="类型">
                        <template slot-scope="scope">
                            <span v-if="scope.row.TYPE=='1'">风机监控</span>
                            <span v-else-if="scope.row.TYPE=='2'">线路监控</span>
                            <span v-else-if="scope.row.TYPE=='3'">升场站监控</span>
                            <span v-else-if="scope.row.TYPE=='4'">其他监控</span>
                            <span v-else-if="scope.row.TYPE=='5'">可见光</span>
                            <span v-else-if="scope.row.TYPE=='6'">热成像</span>
                            <span v-else-if="scope.row.TYPE=='7'">全景</span>
                            <span v-else-if="scope.row.TYPE=='8'">森林防火</span>
                        </template>
                     </el-table-column>
                    <el-table-column prop="VIDEONO" label="视频监控序号"></el-table-column>
                    <el-table-column prop="MACHINENAME" label="设备名称">
                        <template slot-scope="scope">
                            <p v-if="scope.row.MACHINENAME && scope.row.MACHINENAME!=''">
                                {{scope.row.MACHINENAME}}
                            </p>
                            <p v-else>/</p>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="200">
                        <template slot-scope="scope">
                            <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button>
                            <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
                            <el-button @click="showPost(scope.row)"  type="text" size="small" style="color:#409EFF">查看播放地址</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="500px">
            <el-form :model="postInfo" status-icon :rules="postrules"  ref="uForm" label-width="100px" class="demo-uForm">
                <el-form-item label="名称" prop="DISPLAYNAME">
                    <el-input v-model="postInfo.DISPLAYNAME" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="类型">
                    <el-select v-model="postInfo.TYPE" placeholder="请选择类型"  filterable style="width:100%;">
                            <el-option label="风机监控" value="1" ></el-option>
                            <el-option label="线路监控" value="2" ></el-option>
                            <el-option label="升场站监控" value="3" ></el-option>
                            <el-option label="其他监控" value="4" ></el-option>
                            <el-option label="可见光" value="5" ></el-option>
                            <el-option label="热成像" value="6" ></el-option>
                            <el-option label="全景" value="7" ></el-option>
                            <el-option label="森林防火" value="8" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="视频监控序号" prop="VIDEONO">
                    <el-input v-model="postInfo.VIDEONO" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="风场" v-show="postInfo.TYPE!='8'">
                    <el-select v-model="fid" placeholder="请选择风场"  filterable style="width:100%;" @change="factoryChange" :disabled="doType=='2'">
                            <el-option v-for="item in factoryList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="设备" prop="MACHINEID" v-show="postInfo.TYPE!='8'">
                    <el-select v-model="postInfo.MACHINEID" placeholder="请选择风场设备"  filterable style="width:100%;" :disabled="doType=='2'">
                            <el-option v-for="item in machineList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitPostForm()">提交</el-button>
                    <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
export default {
  name: "SPJKGL",
  data() {
    var DISPLAYNAME = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入名称"));
      } else {
        callback();
      }
    };
    var VIDEONO = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入视频监控序号"));
      } else {
        callback();
      }
    };
    var MACHINEID = (rule, value, callback) => {
      if (value === "" && this.postInfo.TYPE!="8") {
        callback(new Error("请选择风场设备"));
      } else {
        callback();
      }
    };
    return {
        canDo:true,
        
        name:"",
        type:"",

        factoryList:[],
        fid:"",
        machineList:[],

        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,


        doTypeStr:"",
        doType:"",
        postModal:false,
        postInfo:{},
        postrules: {
            DISPLAYNAME: [{ validator: DISPLAYNAME, trigger: "blur" }],
            VIDEONO: [{ validator: VIDEONO, trigger: "blur" }],
            MACHINEID: [{ validator: MACHINEID, trigger: "blur" }],
        },
    };
  },
  mounted() {
    this.loadItem=null
    this.getFactoryList()
    this.getPostList()
  },
  methods: {
    factoryChange(e){
        this.postInfo.MACHINEID=""
        this.getMachinelist()
    },
    getFactoryList(){
        this.showLoading("请稍后")
        var params={}
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Factory/GetFactoryWithoutPage", md5_data)
            .then((response) => {
            this.hideLoading()
                if (response.data.SUCCESS) {
                    this.factoryList=response.data.DATA
                } 
            })
    },
    getMachinelist(){
        var params={
            FACTORYID:this.fid,
            PAGEINDEX:1,
            PAGESIZE:20000,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Machine/GetMachine", md5_data)
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.machineList=response.data.DATA  
                } 
            })
    },
    getPostList(){
        this.showLoading("请稍后")
        var params={
            PAGEINDEX:this.PageIndex,
            PAGESIZE:this.PageSize,
            DISPLAYNAME:this.name,
            TYPE:this.type,
        }
        //var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Video/GetVideoSurveillanceList", params)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
        });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    addPost(){
        this.postInfo={
            DISPLAYNAME:"",
            VIDEONO:"",
            MACHINEID:"",
            TYPE:"1",
        }
        this.fid=this.factoryList[0].SNOWID
        this.getMachinelist()
        this.doTypeStr="添加视频监控"
        this.doType="1"
        this.postModal=true
    },
    sendAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        //var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("Video/AddVideoSurveillance", this.postInfo)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    editPost(item){
        this.fid=item.FACTORYID
        this.getMachinelist()
        this.postInfo={
            SNOWID:item.SNOWID,
            DISPLAYNAME:item.DISPLAYNAME,
            VIDEONO:item.VIDEONO,
            MACHINEID:item.MACHINEID,
            TYPE:item.TYPE.toString(),
        }
        
        this.doTypeStr="修改视频监控"
        this.doType="2"
        this.postModal=true
    },
    sendEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        //var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("Video/EditVideoSurveillance", this.postInfo)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.postModal=false
                    this.getPostList();
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    delPost(item){
        if(this.canDo){
            this.$confirm('确定删除监控：'+item.DISPLAYNAME+'？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
            this.sendDel(item.SNOWID)
            }).catch(() => {
            this.$message.info("已取消")    
          });
        }

    },
    sendDel(id){
        this.canDo=false
        this.showLoading("请稍后")
        //var md5_data =this.comjs.requestDataEncrypt({SNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:id})).toUpperCase())
        this.$http.post("Video/DeleteVideoSurveillance", {SNOWID:id})
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    submitPostForm() {
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    if(this.doType=="1"){
                        this.sendAdd()
                    }else if(this.doType=="2"){
                        this.sendEdit()
                    }
                } else {
                    return false;
                }
            });
        }
    },
    showPost(item){
        this.canDo=false
        this.showLoading("请稍后")
        //var md5_data =this.comjs.requestDataEncrypt({SNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:id})).toUpperCase())
        this.$http.post("Video/GetVideoPushUrl", {SNOWID:item.SNOWID})
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    alert(response.data.DATA)
                    // this.$alert('<p class="spdz">'+response.data.DATA+'</p>', item.DISPLAYNAME+"-视频地址", {
                    //         confirmButtonText: '确定',
                    //         callback: action => {
                    //             //
                    //         }
                    //     });
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    }
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style>
.spdz{overflow: hidden;}
</style>