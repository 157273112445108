<template>
  <div class="views-main">
    <div class="views-main-title">
      <p class="txt">风场管理</p>
    </div>
    <div class="tableBox" style="top:50px;">
      <div class="views-main-search">

        <el-input v-model="name" placeholder="请输入名称" class="iw"></el-input>
        <el-button type="primary" @click="searchWF">查询</el-button>
        <el-button type="success" @click="addWF" class="fr">添加</el-button>

      </div>
      <div class="views-main-body">
        <el-table :data="wfList" stripe style="width: 100%;">
          <el-table-column prop="NAME" label="名称"></el-table-column>
          <el-table-column prop="CREATETIME" label="创建日期"></el-table-column>
          <el-table-column label="操作" width="300">
            <template slot-scope="scope">
              <el-button @click="editWF(scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button>
              <el-button @click="delWF(scope.row)" type="text" size="small" style="color:#F56C6C">删除</el-button>
              <el-button @click="setPower(scope.row)" type="text" size="small" style="color:#19be6b">设置月发电量
              </el-button>
              <el-button @click="logPower(scope.row)" type="text" size="small" style="color:#ff9900">发电量记录
              </el-button>
              <el-button @click="openRealTimeData(scope.row)" type="text" size="small" style="color:#409eff">实时数据
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination background layout="total, prev, pager, next" :current-page="PageIndex" :page-size="PageSize"
                       :total="Total" class="pg" @current-change="PGChange"></el-pagination>
      </div>
    </div>
    <el-dialog :title="setMachineStr" :visible.sync="machineModal" width="500px">
      <el-form :model="machine" status-icon :rules="machinerules" ref="machineForm" label-width="100px"
               class="demo-uForm">
        <el-form-item label="名称" prop="NAME">
          <el-input v-model="machine.NAME" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="编号" prop="NUMBER">
          <el-input v-model="machine.NUMBER" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="代码" prop="CODE">
          <el-input v-model="machine.CODE" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="类型" prop="MACHINETYPEID">
          <el-select v-model="machine.MACHINETYPEID" placeholder="选择设备类型" style="width:360px;">
            <el-option v-for="item in machineTypes" :key="item.SNOWID" :label="item.NAME"
                       :value="item.SNOWID"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="高度（米）" prop="HIGHT">
          <el-input v-model="machine.HIGHT" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="通讯ID">
          <el-input v-model="machine.COMMUNICATIONID" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitMachineForm()">提交</el-button>
          <el-button @click="machineModal = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog :title="doTypeStr" :visible.sync="wfModal" width="1200px" top="30px" :close-on-press-escape="false">
      <el-form :model="wfInfo" status-icon :rules="postrules" ref="uForm" label-width="100px" class="demo-uForm">
        <el-form-item label="名称" prop="NAME">
          <el-input v-model="wfInfo.NAME" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="区域/设备" prop="POLYGON">
          <Map class="map" ref="map" :width="mapWidth" :height="mapHeight" style="float:left"></Map>
          <div class="nowMachineBox">
            <p class="mlTitle">设备列表</p>
            <div class="machineListBox">
              <div class="machineListOut" v-if="machineList.length>0">
                <el-tree :data="machineList" :props="treeProps" ref="tree" node-key="SNOWID">
                                        <span class="custom-tree-node" slot-scope="{ node, data }">
                                        <span class="treeName">{{ node.label }}　{{ data.NUMBER }}</span>
                                            <el-dropdown style="float:right;">
                                                <span class="el-dropdown-link" style="color:#409EFF">
                                                    操作<i class="el-icon-arrow-down el-icon--right"></i>
                                                </span>
                                                <el-dropdown-menu slot="dropdown">
                                                    <el-dropdown-item
                                                        @click.native="() => append(node, data)">添加</el-dropdown-item>
                                                    <el-dropdown-item
                                                        @click.native="() => edit(node, data)">编辑</el-dropdown-item>
                                                    <el-dropdown-item
                                                        @click.native="() => remove(node, data)">删除</el-dropdown-item>
                                                    <el-dropdown-item @click.native="() => moveToMachine(data.SNOWID)"
                                                                      v-if="nowWFMachineMarker[data.SNOWID]">定位</el-dropdown-item>
                                                    <el-dropdown-item @click.native="() => moveToPosition(data)" v-else>定位</el-dropdown-item>
                                                    <span v-if="nowWFMachineMarker[data.SNOWID]">
                                                        <el-dropdown-item @click.native="() => addMachineZB(data)"
                                                                          v-if="!data.ZB || data.ZB==''">添加四角方位</el-dropdown-item>
                                                        <el-dropdown-item @click.native="() => removeMachineZB(data)"
                                                                          v-else>删除四角方位</el-dropdown-item>
                                                    </span>
                                                    <el-dropdown-item @click.native="() => copy(node, data)"
                                                                      v-if="data.PARENTID=='0' || data.PARENTID=='' || data.PARENTID==null">复制</el-dropdown-item>
                                                </el-dropdown-menu>
                                            </el-dropdown>
                                          <!-- <span style="display:block;width:100%;text-align: right;">
                                              <el-button type="text" size="mini" @click.stop="() => append(node, data)" style="color:#19be6b">添加</el-button>
                                              <el-button type="text" size="mini" @click.stop="() => edit(node, data)" style="color:#ff9900">编辑</el-button>
                                              <el-button type="text" size="mini" @click.stop="() => remove(node, data)" style="color:#ed4014">删除</el-button>
                                              <el-button v-if="nowWFMachineMarker[data.SNOWID]" type="text" size="mini" @click.stop="() => moveToMachine(data.SNOWID)" style="color:#409EFF">定位</el-button>
                                              <span v-if="nowWFMachineMarker[data.SNOWID]" style="margin-left:10px;">
                                                  <el-button v-if="!data.ZB || data.ZB==''" type="text" size="mini" @click.stop="() => addMachineZB(data)" style="color:#409EFF">添加四角方位</el-button>
                                                  <el-button v-else type="text" size="mini" @click.stop="() => removeMachineZB(data)" style="color:#ed4014">删除四角方位</el-button>
                                              </span>
                                              <el-button  v-if="data.PARENTID=='0' || data.PARENTID=='' || data.PARENTID==null" type="text" size="mini" @click.stop="() => copy(node, data)" style="color:#409EFF;margin-left:10px;">复制</el-button>
                                          </span> -->
                                        </span>
                </el-tree>
              </div>
              <div class="machineListNull" v-else>
                <p style="margin-top:160px;">暂无设备</p>
                <p>请点击下方按钮添加</p>
              </div>
            </div>
            <div class="addMachineBox">
              <el-button type="primary" @click="addWFMachine()" class="addBtn">添加设备</el-button>
            </div>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button type="warning" @click="stopDraw()" class="fl mr20" v-if="drawFence">停止绘制</el-button>
          <el-button type="success" @click="startDraw()" class="fl mr20" v-else>绘制区域</el-button>
          <el-button type="danger" @click="delDraw()" class="fl mr20">删除区域</el-button>
          <!-- <el-button type="danger" @click="clearDraw()" class="fl mr20">清空绘制</el-button> -->


          <el-button @click="cancelWfForm()" class="fr ml20">取消</el-button>
          <el-button type="primary" @click="submitWFForm()" class="fr ml20">提交</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog title="设备复制" :visible.sync="copyModal" width="500px">
      <el-form :model="copyInfo" status-icon :rules="copyrules" ref="copyForm" label-width="100px" class="demo-uForm">
        <el-form-item label="起始编号" prop="NUMBER">
          <el-input v-model.number="copyInfo.NUMBER" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="复制数量" prop="COPYNUM">
          <el-input v-model.number="copyInfo.COPYNUM" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitCopyForm()">提交</el-button>
          <el-button @click="copyModal = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog :title="doListStrP" :visible.sync="listModalP" width="1050px" top="50px">
      <div style="width:100%;overflow:hidden;margin-bottom:20px;">
        <!-- <el-date-picker v-model="stimeP" type="month" placeholder="开始日期" class="iww"></el-date-picker>
        <el-date-picker v-model="etimeP" type="month" placeholder="结束日期" class="iww"></el-date-picker> -->
        <el-date-picker v-model="stimeP" type="monthrange" range-separator="至" start-placeholder="开始月份" end-placeholder="结束月份" style="margin-right:20px;"> </el-date-picker>
        <el-button type="primary" @click="searchPostP">查询</el-button>
        <el-button type="success" @click="addPostP" class="fr">添加</el-button>
      </div>
      <el-table :data="postListP" stripe style="width: 100%" :height="dialogMaxHeigh">
        <el-table-column prop="MACHINENAME" label="设备名称"></el-table-column>
        <el-table-column prop="DAYPOWER" label="每月发电量"></el-table-column>
        <el-table-column prop="DAYELECTRICITY" label="月上网电量"></el-table-column>
        <el-table-column prop="DAYPOWERLIMIT" label="月限电量"></el-table-column>
        <el-table-column prop="DAYMOON" label="月份"></el-table-column>
      </el-table>
      <el-pagination background layout="total, prev, pager, next" :current-page="PageIndexP" :page-size="PageSizeP"
                     :total="TotalP" class="pg pgItem" @current-change="PGChangeP"></el-pagination>
    </el-dialog>
    <el-dialog :title="doTypeStrP" :visible.sync="postModalP" width="600px">
      <el-form :model="postInfoP" status-icon :rules="postrulesP" ref="pForm" label-width="100px" class="demo-uForm">
        <el-form-item label="日期" prop="DAYS">
          <el-date-picker v-model="postInfoP.DAYS" type="month" placeholder="选择日期" style="width:100%;"></el-date-picker>
        </el-form-item>
        <el-form-item label="每月发电量" prop="DAYPOWER">
          <el-input v-model="postInfoP.DAYPOWER" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="月上网电量" prop="DAYELECTRICITY">
          <el-input v-model="postInfoP.DAYELECTRICITY" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="月限电量" prop="DAYPOWERLIMIT">
          <el-input v-model="postInfoP.DAYPOWERLIMIT" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitPostFormP()">提交</el-button>
          <el-button @click="postModalP = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog class="realTimeDialog" :title="realTimeDataStr" :visible.sync="realTimeDataP" :before-close="closeRealTimeData" width="1050px" top="50px">
      <div style="width:100%;overflow:hidden;margin-bottom:20px;">
        <el-date-picker v-model="fillingTime" type="date" placeholder="填报日期" class="iww"></el-date-picker>
        <el-select v-model="timing" clearable placeholder="请选择填报时间点" style="margin-right: 20px">
          <el-option label="8点" :value="8"></el-option>
          <el-option label="15点" :value="15"></el-option>
          <el-option label="20点" :value="20"></el-option>
          <el-option label="24点" :value="24"></el-option>
        </el-select>
        <el-button @click="getRealTimeData" type="primary">查询</el-button>
        <el-button @click="openRealTimeDataModify('新增')" type="success" class="fr">添加</el-button>
      </div>
      <el-table ref="realTimeTable" :data="realTimeData" @select="select" stripe style="width: 100%;height: 589px;overflow-y: auto">
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-form label-position="left" inline class="demo-table-expand">
              <el-form-item label="限负荷情况">
                <span>{{ props.row.LIMITLOAD }}</span>
              </el-form-item>
              <el-form-item label="年限电量">
                <span>{{ props.row.YEARLIMITPOWER }}</span>
              </el-form-item>
              <el-form-item label="年利用小时数">
                <span>{{ props.row.YEARUSEHOUR }}</span>
              </el-form-item>
              <el-form-item label="月平台交易电量">
                <span>{{ props.row.MONTHTRADEPOWER }}</span>
              </el-form-item>
              <el-form-item label="全年电量计划确保值">
                <span>{{ props.row.YEARLOWPOWER }}</span>
              </el-form-item>
              <el-form-item label="月上网电量">
                <span>{{ props.row.MONTHUPPOWER }}</span>
              </el-form-item>
              <el-form-item label="运行台数">
                <span>{{ props.row.MACHINECOUNT }}</span>
              </el-form-item>
              <el-form-item label="全年电量计划力争值">
                <span>{{ props.row.YEARHIGHPOWER }}</span>
              </el-form-item>
              <el-form-item label="故障停机">
                <span>{{ props.row.ERRMACHINECOUNT }}</span>
              </el-form-item>
              <el-form-item label="全年电量计划力争值完成比例">
                <span>{{ props.row.YEARHIGHPOWERPERCENT }}</span>
              </el-form-item>
              <el-form-item label="容量">
                <span>{{ props.row.CAPACITY }}</span>
              </el-form-item>
              <el-form-item label="风场月与地调计量表电量核对线损差额">
                <span>{{ props.row.MONTHLOSEPOWER }}</span>
              </el-form-item>
              <el-form-item label="计划检修">
                <span>{{ props.row.REPAIRMACHINECOUNT }}</span>
              </el-form-item>
              <el-form-item label="备注">
                <span>{{ props.row.BZ }}</span>
              </el-form-item>
              <el-form-item label="风速">
                <span>{{ props.row.WINDYSPEED }}</span>
              </el-form-item>
              <el-form-item label="风场月与地调线损计量差额比例">
                <span>{{ props.row.MONTHLOSEPOWERPERCENT }}</span>
              </el-form-item>
              <el-form-item label="限负荷停机">
                <span>{{ props.row.LIMITLOADMACHINECOUNT }}</span>
              </el-form-item>
              <el-form-item label="值班人员">
                <span>{{ props.row.DUTYUSER }}</span>
              </el-form-item>
              <el-form-item label="负荷">
                <span>{{ props.row.LOAD }}</span>
              </el-form-item>
              <el-form-item label="月限电量">
                <span>{{ props.row.MONTHLIMITPOWER }}</span>
              </el-form-item>
              <el-form-item label="在岗人员">
                <span>{{ props.row.POSITIONUSER }}</span>
              </el-form-item>
              <el-form-item label="月限电比">
                <span>{{ props.row.MONTHLIMITPOWERPERCENT }}</span>
              </el-form-item>
              <el-form-item label="年累计上网电量">
                <span>{{ props.row.YEARUPPOWER }}</span>
              </el-form-item>
            </el-form>
          </template>
        </el-table-column>
        <el-table-column
            type="selection"
            width="60">
        </el-table-column>
        <el-table-column prop="DAYPOWER" label="日发电量"></el-table-column>
        <el-table-column prop="MONTHPOWER" label="月发电量"></el-table-column>
        <el-table-column prop="YEARPOWER" label="年累计发电量"></el-table-column>
        <el-table-column prop="UPHOUR" label="填报时间点">
          <template v-slot="scope">
            <div>{{scope.row.UPHOUR + '点'}}</div>
          </template>
        </el-table-column>
        <el-table-column label="填报时间">
          <template slot-scope="scope">
            <span>{{ scope.row.UPDATE.split(" ")[0] }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template v-slot="scope">
            <el-button @click="openRealTimeDataModify('修改',scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button>
            <el-button @click="realTimeDataRemove(scope.row)" type="text" size="small" style="color:#F56C6C">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <el-dialog :title="realTimeDataModifyStr" :visible.sync="realTimeDataModifyP" :before-close="closeRealTimeDataModify" width="700px" top="20px">
      <el-form :model="realTimeDataInfo" status-icon :rules="realTimeDataRules" ref="realTimeDataForm"
               label-width="220px">
        <div v-show="realTimeDataActive === 1">
          <el-form-item label="填报时间点" prop="UPHOUR" ref="UPHOUR">
            <el-select v-model="realTimeDataInfo.UPHOUR" clearable placeholder="请选择填报时间点"
                       style="margin-right: 20px;width: 100%">
              <el-option label="8点" :value="8"></el-option>
              <el-option label="15点" :value="15"></el-option>
              <el-option label="20点" :value="20"></el-option>
              <el-option label="24点" :value="24"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="填报日期" prop="UPDATE" ref="UPDATE">
            <el-date-picker v-model="realTimeDataInfo.UPDATE" type="date" ormat="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd"
                            placeholder="选择填报日期" style="width:100%;"></el-date-picker>
          </el-form-item>
          <el-form-item label="容量" prop="CAPACITY" ref="CAPACITY">
            <el-input type="number" min="0" v-model="realTimeDataInfo.CAPACITY" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="风速(米秒)" prop="WINDYSPEED" ref="WINDYSPEED">
            <el-input type="number" min="0" v-model="realTimeDataInfo.WINDYSPEED" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="负荷(万千瓦)" prop="LOAD" ref="LOAD">
            <el-input type="number" min="0" v-model="realTimeDataInfo.LOAD" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="日发电量(万千瓦时)" prop="DAYPOWER" ref="DAYPOWER">
            <el-input type="number" min="0" v-model="realTimeDataInfo.DAYPOWER" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="限负荷情况(万千瓦)" prop="LIMITLOAD" ref="LIMITLOAD">
            <el-input type="number" min="0" v-model="realTimeDataInfo.LIMITLOAD" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="月发电量(万千瓦时)" prop="MONTHPOWER" ref="MONTHPOWER">
            <el-input type="number" min="0" v-model="realTimeDataInfo.MONTHPOWER" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="月上网电量(万千瓦时)" prop="MONTHUPPOWER" ref="MONTHUPPOWER">
            <el-input type="number" min="0" v-model="realTimeDataInfo.MONTHUPPOWER" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="月计划电量(万千瓦时)" prop="MONTHPLANPOWER" ref="MONTHPLANPOWER">
            <el-input type="number" min="0" v-model="realTimeDataInfo.MONTHPLANPOWER" autocomplete="off"></el-input>
          </el-form-item>
        </div>
        <div v-show="realTimeDataActive === 2">
          <el-form-item label="风场月与地调计量表电量核对线损差额(万千瓦时)" prop="MONTHLOSEPOWER" ref="MONTHLOSEPOWER">
            <el-input type="number" min="0" v-model="realTimeDataInfo.MONTHLOSEPOWER" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="风场月与地调线损计量差额比例" prop="MONTHLOSEPOWERPERCENT" ref="MONTHLOSEPOWERPERCENT">
            <el-input type="number" min="0" v-model="realTimeDataInfo.MONTHLOSEPOWERPERCENT" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="月限电量(万千瓦时)" prop="MONTHLIMITPOWER" ref="MONTHLIMITPOWER">
            <el-input type="number" min="0" v-model="realTimeDataInfo.MONTHLIMITPOWER" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="月限电比" prop="MONTHLIMITPOWERPERCENT" ref="MONTHLIMITPOWERPERCENT">
            <el-input type="number" min="0" v-model="realTimeDataInfo.MONTHLIMITPOWERPERCENT" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="年限电量(万千瓦时)" prop="YEARLIMITPOWER" ref="YEARLIMITPOWER">
            <el-input type="number" min="0" v-model="realTimeDataInfo.YEARLIMITPOWER" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="月平台交易电量(万千瓦时)" prop="MONTHTRADEPOWER" ref="MONTHTRADEPOWER">
            <el-input type="number" min="0" v-model="realTimeDataInfo.MONTHTRADEPOWER" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="运行台数" prop="MACHINECOUNT" ref="MACHINECOUNT">
            <el-input type="number" min="0" :step="1" v-model="realTimeDataInfo.MACHINECOUNT" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="故障停机" prop="ERRMACHINECOUNT" ref="ERRMACHINECOUNT">
            <el-input type="number" min="0" :step="1" v-model="realTimeDataInfo.ERRMACHINECOUNT" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="计划检修" prop="REPAIRMACHINECOUNT" ref="REPAIRMACHINECOUNT">
            <el-input type="number" min="0" v-model="realTimeDataInfo.REPAIRMACHINECOUNT" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="限负荷停机" prop="LIMITLOADMACHINECOUNT" ref="LIMITLOADMACHINECOUNT">
            <el-input type="number" min="0" v-model="realTimeDataInfo.LIMITLOADMACHINECOUNT" autocomplete="off"></el-input>
          </el-form-item>
        </div>
        <div v-show="realTimeDataActive === 3">
          <el-form-item label="年累计发电量(万千瓦时)" prop="YEARPOWER" ref="YEARPOWER">
            <el-input type="number" min="0" v-model="realTimeDataInfo.YEARPOWER" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="年累计上网电量（万千瓦时）" prop="YEARUPPOWER" ref="YEARUPPOWER">
            <el-input type="number" min="0" v-model="realTimeDataInfo.YEARUPPOWER" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="年利用小时数(小时)" prop="YEARUSEHOUR" ref="YEARUSEHOUR">
            <el-input type="number" min="0" v-model="realTimeDataInfo.YEARUSEHOUR" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="全年电量计划确保值(万千瓦时)" prop="YEARLOWPOWER" ref="YEARLOWPOWER">
            <el-input type="number" min="0" v-model="realTimeDataInfo.YEARLOWPOWER" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="全年电量计划力争值(万千瓦时)" prop="YEARHIGHPOWER" ref="YEARHIGHPOWER">
            <el-input type="number" min="0" v-model="realTimeDataInfo.YEARHIGHPOWER" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="全年电量计划力争值完成比例" prop="YEARHIGHPOWERPERCENT" ref="YEARHIGHPOWERPERCENT">
            <el-input type="number" min="0" v-model="realTimeDataInfo.YEARHIGHPOWERPERCENT" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="备注" prop="BZ" ref="BZ">
            <el-input v-model="realTimeDataInfo.BZ" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="值班人员" prop="DUTYUSER" ref="DUTYUSER">
            <el-input v-model="realTimeDataInfo.DUTYUSER" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="在岗人员" prop="POSITIONUSER" ref="POSITIONUSER">
            <el-input v-model="realTimeDataInfo.POSITIONUSER" autocomplete="off"></el-input>
          </el-form-item>
        </div>
      </el-form>
      <el-steps :active="realTimeDataActive" finish-status="success">
        <el-step title="步骤 1"></el-step>
        <el-step title="步骤 2"></el-step>
        <el-step title="步骤 3"></el-step>
      </el-steps>
      <div style="text-align: right">
        <el-button @click="realTimeDataActive--" v-show="realTimeDataActive === 2 || realTimeDataActive === 3"
                   type="primary">上一步
        </el-button>
        <el-button @click="nextStep" v-show="realTimeDataActive === 1 || realTimeDataActive === 2"
                   type="primary">下一步
        </el-button>
        <el-button @click="submitRealForm" v-show="realTimeDataActive === 3" type="primary">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
var pattern = /^[1-9][0-9]*$/
var h = document.body.clientHeight
import Map from "../../components/Map"

export default {
  name: "WindFieldManage",
  components: {
    Map,
  },
  data() {
    var NAME = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入名称"));
      } else {
        callback();
      }
    };
    var POLYGON = (rule, value, callback) => {
      if (value <= 0) {
        this.$message.error("请绘制风场区域")
        callback(new Error("请绘制风场区域"));
      } else {
        callback();
      }
    };

    var NUMBER = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入编号"));
      } else {
        callback();
      }
    };

    var CODE = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入代码"));
      } else {
        callback();
      }
    };

    var MACHINETYPEID = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择类型"));
      } else {
        callback();
      }
    };


    var CNUMBER = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入起始编号'));
      } else if (!pattern.test(value)) {
        callback(new Error('起始编号只能是正整数'));
      } else {
        callback();
      }
    };
    var COPYNUM = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入复制数量'));
      } else if (!pattern.test(value)) {
        callback(new Error('复制数量只能是正整数'));
      } else {
        callback();
      }
    };

    var DAYPOWER = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入每月发电量'));
      } else if (typeof value != 'number' && isNaN(value)) {
        callback(new Error('每月发电量只能是数字'));
      } else if (value < 0) {
        callback(new Error('每月发电量不能小于0'));
      } else {
        callback();
      }
    };
    var DAYELECTRICITY = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入月上网电量'));
      } else if (typeof value != 'number' && isNaN(value)) {
        callback(new Error('月上网电量只能是数字'));
      } else if (value < 0) {
        callback(new Error('月上网电量不能小于0'));
      } else {
        callback();
      }
    };
    var DAYPOWERLIMIT = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入月限电量'));
      } else if (typeof value != 'number' && isNaN(value)) {
        callback(new Error('月限电量只能是数字'));
      } else if (value < 0) {
        callback(new Error('月限电量不能小于0'));
      } else {
        callback();
      }
    };
    var DAYS = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择日期"));
      } else {
        callback();
      }
    };

    var HIGHT= (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入高度'));
      } else if (typeof value != 'number' && isNaN(value)) {
        callback(new Error('高度只能是数字'));
      } else if (value < 0) {
        callback(new Error('高度不能小于0'));
      } else {
        callback();
      }
    };
    return {
      canDo: true,

      name: "",


      wfList: [],
      PageIndex: 1,
      PageSize: 10,
      Total: 0,


      doTypeStr: "",
      doType: "",
      wfModal: false,
      wfInfo: {
        NAME: "",
        POLYGON: 0,  //已绘制区域数量
      },
      postrules: {
        NAME: [{validator: NAME, trigger: "blur"}],
        POLYGON: [{validator: POLYGON, trigger: "blur"}],
      },

      //绘制
      map: null,
      mapWidth: 650,
      mapHeight: 600,

      WFCenter: null,

      drawingManagerObj: null, //绘制对象
      drawFence: false, //绘制中
      polygons: [],//已绘制多边形集合
      drawBtns: {}, //绘制的多边形编辑按钮
      nowEditDrawPolygon: null,//当前正在编辑的已绘制多边形
      wktStr: "",
      nowFactoryCenter: null,

      zbId: "",//风场坐标的ID

      checkPolygon: null,


      //风场设备
      machineModal: false,
      filterText: "",

      machineList: [],
      machineTypes: [],
      machine: {
        NAME: "",
        NUMBER: "",
        MACHINETYPEID: "",
        CODE: "",
        PARENTID: "",
        HEIGHT:"",
        COMMUNICATIONID:"",
      },

      machinerules: {
        NAME: [{validator: NAME, trigger: "blur"}],
        NUMBER: [{validator: NUMBER, trigger: "blur"}],
        CODE: [{validator: CODE, trigger: "blur"}],
        MACHINETYPEID: [{validator: MACHINETYPEID, trigger: "blur"}],
        HIGHT:[{validator: HIGHT, trigger: "blur"}],
      },

      nowWFMachineMarker: {},
      nowWFMachinePolygon: {},

      coolLeftHeight: 0,

      MachineEditItem: null,
      setMachineStr: "添加设备",
      treeProps: {
        label: 'NAME',
        children: 'MACHINERESPONSELIST'
      },

      nowMachineItem: null,
      drawingMachineObj: null, //绘制对象
      drawMachine: false, //绘制中
      machinePolygon: null,//已绘制多边形集合


      //复制
      copyFactoryID: "",
      copyInfo: {},
      copyModal: false,
      copyrules: {
        COPYNUM: [{validator: COPYNUM, trigger: "blur"}],
        NUMBER: [{validator: CNUMBER, trigger: "blur"}],
      },

      //发电量
      doTypeStrP: "",
      postModalP: false,
      postInfoP: {},
      postrulesP: {
        DAYPOWER: [{validator: DAYPOWER, trigger: "blur"}],
        DAYELECTRICITY: [{validator: DAYELECTRICITY, trigger: "blur"}],
        DAYPOWERLIMIT: [{validator: DAYPOWERLIMIT, trigger: "blur"}],
        DAYS: [{validator: DAYS, trigger: "blur"}],
      },

      stimeP: "",
      etimeP: "",

      doListStrP: "",
      listModalP: false,
      postListP: [],
      PageIndexP: 1,
      PageSizeP: 10,
      TotalP: 0,

      dialogMaxHeigh: 0,

      // 实时数据
      realTimeDataP: false, // 实时数据弹框控制
      realTimeDataStr: '', // 实时数据弹框标题
      currentRealTimeObj: '',
      realTimeData: [], // 实时数据
      fillingTime: '', // 填报时间
      timing: '', // 填报时间点
      realTimeDataModifyP: false, // 新增实时数据弹框控制
      realTimeDataModifyStr: '',
      realTimeDataInfo: {}, // 提交参数
      currentSelection: {}, // 载入的数据
      realTimeDataActive: 1, // 当前步骤
      realTimeDataRules: {
        UPDATE: [{required: true, message: '请选择填写时间', trigger: 'blur'}],
        LIMITLOAD: [{required: true, message: '请输入限负荷情况', trigger: 'blur'}],
        YEARLIMITPOWER: [{required: true, message: '请输入年限电量', trigger: 'blur'}],
        YEARUSEHOUR: [{required: true, message: '请输入年利用小时数', trigger: 'blur'}],
        UPHOUR: [{required: true, message: '请选择填报时间点', trigger: 'blur'}],
        MONTHPOWER: [{required: true, message: '请输入月发电量', trigger: 'blur'}],
        MONTHTRADEPOWER: [{required: true, message: '请输入月平台交易电量', trigger: 'blur'}],
        YEARLOWPOWER: [{required: true, message: '请输入全年电量计划确保值', trigger: 'blur'}],
        MONTHUPPOWER: [{required: true, message: '请输入月上网电量', trigger: 'blur'}],
        MACHINECOUNT: [{required: true, message: '请输入运行台数', trigger: 'blur'}],
        YEARHIGHPOWER: [{required: true, message: '请输入全年电量计划力争值', trigger: 'blur'}],
        MONTHPLANPOWER: [{required: true, message: '请输入月计划电量', trigger: 'blur'}],
        ERRMACHINECOUNT: [{required: true, message: '请输入故障停机', trigger: 'blur'}],
        YEARHIGHPOWERPERCENT: [{required: true, message: '请输入全年电量计划力争值完成比例', trigger: 'blur'}],
        CAPACITY: [{required: true, message: '请输入容量', trigger: 'blur'}],
        MONTHLOSEPOWER: [{required: true, message: '请输入风场月与地调计量表电量核对线损差额', trigger: 'blur'}],
        REPAIRMACHINECOUNT: [{required: true, message: '请输入计划检修', trigger: 'blur'}],
        BZ: [{required: true, message: '请输入备注', trigger: 'blur'}],
        WINDYSPEED: [{required: true, message: '请输入风速', trigger: 'blur'}],
        MONTHLOSEPOWERPERCENT: [{required: true, message: '请输入风场月与地调线损计量差额比例', trigger: 'blur'}],
        LIMITLOADMACHINECOUNT: [{required: true, message: '请输入限负荷停机', trigger: 'blur'}],
        DUTYUSER: [{required: true, message: '请输入置办人', trigger: 'blur'}],
        LOAD: [{required: true, message: '请输入负荷', trigger: 'blur'}],
        MONTHLIMITPOWER: [{required: true, message: '请输入月限电量', trigger: 'blur'}],
        YEARPOWER: [{required: true, message: '请输入年累计发电量', trigger: 'blur'}],
        POSITIONUSER: [{required: true, message: '请输入在岗人员', trigger: 'blur'}],
        DAYPOWER: [{required: true, message: '请输入日发电量', trigger: 'blur'}],
        MONTHLIMITPOWERPERCENT: [{required: true, message: '请输入月限电比', trigger: 'blur'}],
        YEARUPPOWER: [{required: true, message: '请输入年累计上网电量', trigger: 'blur'}],
      },
      realTimeStepArr1: [
          'UPHOUR','UPDATE','CAPACITY','WINDYSPEED','LOAD',
          'DAYPOWER','LIMITLOAD','MONTHPOWER','MONTHUPPOWER','MONTHPLANPOWER'
      ], // 步骤1验证数组
      realTimeStepArr2: [
        'MONTHLOSEPOWER','MONTHLOSEPOWERPERCENT','MONTHLIMITPOWER','MONTHLIMITPOWERPERCENT','YEARLIMITPOWER',
        'MONTHTRADEPOWER','MACHINECOUNT','ERRMACHINECOUNT','REPAIRMACHINECOUNT','LIMITLOADMACHINECOUNT'
      ], // 步骤2验证数组
    };
  },
  mounted() {
    this.loadItem = null
    this.getWFList()
    var _this = this
    this.$nextTick(function () {
      document.addEventListener('keyup', function (e) {
        if (e.keyCode == 27) {
          if (_this.drawFence) {
            _this.drawFence = false
            _this.drawingManagerObj.setDrawingMode(null)
          }
          if (_this.drawMachine) {
            _this.drawMachine = false
            _this.drawingMachineObj.setDrawingMode(null)
          }
        }
      })
    })
    this.getMachineType()
    this.coolLeftHeight = h - 60
    this.dialogMaxHeigh = h - 300
  },
  methods: {
    //获取风场设备类型列表
    getMachineType() {
      var params = {
        PAGEINDEX: 1,
        PAGESIZE: 1000,
      }
      var md5_data = this.comjs.requestDataEncrypt(params, this.$store.state.token, this.$md5(this.comjs.sortAsc(params)).toUpperCase())
      this.$http.post("MachineType/GetMachineTypeList", md5_data)
          .then((response) => {
            if (response.data.SUCCESS) {
              this.machineTypes = response.data.DATA
            }
          })
    },
    //获取风场列表
    getWFList() {
      this.showLoading("请稍后")
      var params = {
        PAGEINDEX: this.PageIndex,
        PAGESIZE: this.PageSize,
        NAME: this.name,
      }
      var md5_data = this.comjs.requestDataEncrypt(params, this.$store.state.token, this.$md5(this.comjs.sortAsc(params)).toUpperCase())
      this.$http.post("Factory/GetFactory", md5_data)
          .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
              this.wfList = response.data.DATA
              this.Total = response.data.TOTALCOUNT
            } else {
              this.wfList = []
              this.Total = 0
              this.errorRequest(response.data.DATA, response.data.MSG, true)
            }
          })
          .catch((error) => {
            this.hideLoading()
            this.wfList = []
            this.Total = 0
          });
    },
    //搜索风场
    searchWF() {
      this.PageIndex = 1
      this.getWFList()
    },
    //点击添加风场
    addWF() {
      this.wfInfo = {
        NAME: "",
        POLYGON: 0,
      }
      this.doTypeStr = "添加风场"
      this.doType = "1"
      this.wfModal = true

      setTimeout(() => {
        if (!this.map) {
          this.map = this.$refs.map.getMap()
          var _this = this
          window.qq.maps.event.addListener(this.map, 'click', function (e) {
            _this.checkOffPolygon()
          })
        }
        this.clearDraw()
        this.clearMapMachine()
      }, 100)
    },
    //执行添加风场
    sendAdd() {
      this.canDo = false
      this.showLoading("请稍后")
      var params = {
        NAME: this.wfInfo.NAME,
        LON: this.nowFactoryCenter.lng,
        LAT: this.nowFactoryCenter.lat,
      }
      var md5_data = this.comjs.requestDataEncrypt(params, this.$store.state.token, this.$md5(this.comjs.sortAsc(params)).toUpperCase())
      this.$http.post("Factory/CreateNewFactory", md5_data)
          .then((response) => {
            this.canDo = true
            this.hideLoading()
            if (response.data.SUCCESS) {
              this.addWFZB(response.data.DATA)
              this.$message.success("已添加")
              this.wfModal = false
              this.getWFList()
            } else {
              this.errorRequest(response.data.DATA, response.data.MSG, true)
            }
          })
          .catch((error) => {
            this.$message.error("请求出错")
            this.hideLoading()
            this.canDo = true
          });
    },
    //风场坐标 查、增、改
    getWFZB(id) {
      var params = {
        FACTORYID: id,
        PAGEINDEX: 1,
        PAGESIZE: 1,
      }
      var md5_data = this.comjs.requestDataEncrypt(params, this.$store.state.token, this.$md5(this.comjs.sortAsc(params)).toUpperCase())
      this.$http.post("Factory/GetFactoryZB", md5_data)
          .then((response) => {
            // console.log("获取风场坐标")
            // console.log(response)
            if (response.data.SUCCESS) {
              var zb = response.data.DATA.ZB
              this.zbId = response.data.DATA.SNOWID
              var pathItem = this.$refs.map.getTXPathByWKTStr(zb)
              //console.log(pathItem)
              var pathArr = pathItem.arr
              if (pathItem.isMulti) {
                for (var i = 0; i < pathArr.length; i++) {
                  var polygonI = new window.qq.maps.Polygon({
                    map: this.map,
                    path: pathArr[i],
                    editable: true,
                    fillColor: new window.qq.maps.Color(45, 140, 240, 0.3),
                    strokeColor: new window.qq.maps.Color(45, 140, 240, 1),
                    strokeDashStyle: "solid",
                    visible: true,
                    zIndex: 66,
                  });
                  this.appendNewOutPolygon(polygonI)
                }
              } else {
                var polygon = new window.qq.maps.Polygon({
                  map: this.map,
                  path: pathArr,
                  editable: true,
                  fillColor: new window.qq.maps.Color(45, 140, 240, 0.3),
                  strokeColor: new window.qq.maps.Color(45, 140, 240, 1),
                  strokeDashStyle: "solid",
                  visible: true,
                  zIndex: 66,
                });
                this.appendNewOutPolygon(polygon)
              }
            }
          })
    },
    addWFZB(id) {
      var params = {
        FACTORYID: id,
        ZB: this.wktStr,
      }
      var md5_data = this.comjs.requestDataEncrypt(params, this.$store.state.token, this.$md5(this.comjs.sortAsc(params)).toUpperCase())
      this.$http.post("Factory/CreateNewFactoryZB", md5_data)
          .then((response) => {
            // console.log("添加风场坐标")
            // console.log(response)
          })
    },
    editWFZB(id) {
      var params = {
        FACTORYID: id,
        SNOWID: this.zbId,
        ZB: this.wktStr,
      }
      var md5_data = this.comjs.requestDataEncrypt(params, this.$store.state.token, this.$md5(this.comjs.sortAsc(params)).toUpperCase())
      this.$http.post("Factory/CreateNewFactoryZB", md5_data)
          .then((response) => {
            // console.log("编辑风场坐标")
            // console.log(response)
          })
    },
    //点击编辑风场
    editWF(item) {
      this.wfInfo = {
        SNOWID: item.SNOWID,
        NAME: item.NAME,
        POLYGON: 0.
      }
      this.doTypeStr = "编辑风场"
      this.doType = "2"
      this.wfModal = true
      setTimeout(() => {
        if (!this.map) {
          this.map = this.$refs.map.getMap()
          var _this = this
          window.qq.maps.event.addListener(this.map, 'click', function (e) {
            _this.checkOffPolygon()
          })
        }
        this.WFCenter = new window.qq.maps.LatLng(item.LAT, item.LON);
        this.map.setCenter(this.WFCenter)
        this.clearDraw()
        this.clearMapMachine()
        this.getWFZB(item.SNOWID)
        this.getWFMachine(item.SNOWID)
        //console.log(this.WFCenter)
      }, 100)

    },
    //执行编辑风场请求
    sendEdit() {
      this.canDo = false
      this.showLoading("请稍后")
      var params = {
        SNOWID: this.wfInfo.SNOWID,
        NAME: this.wfInfo.NAME,
        LON: this.nowFactoryCenter.lng,
        LAT: this.nowFactoryCenter.lat,
      }
      var md5_data = this.comjs.requestDataEncrypt(params, this.$store.state.token, this.$md5(this.comjs.sortAsc(params)).toUpperCase())
      this.$http.post("Factory/EditFactory", md5_data)
          .then((response) => {
            this.canDo = true
            this.hideLoading()
            if (response.data.SUCCESS) {
              this.addWFZB(params.SNOWID)
              var nl = []
              for (var i = 0; i < this.wfList.length; i++) {
                var d = this.wfList[i]
                if (d.SNOWID == params.SNOWID) {
                  d.NAME = params.NAME
                  d.LON = params.LON
                  d.LAT = params.LAT
                }
                nl.push(d)
              }
              this.wfList = nl
              this.$message.success("编辑成功")
              this.wfModal = false
            } else {
              this.errorRequest(response.data.DATA, response.data.MSG, true)
            }
          })
          .catch((error) => {
            this.$message.error("请求出错")
            this.hideLoading()
            this.canDo = true
          });
    },
    //点击删除风场
    delWF(item) {
      if (this.canDo) {
        this.$confirm('确定删除风场：' + item.NAME + '？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.sendDel(item.SNOWID)
        }).catch(() => {
          this.$message.info("已取消")
        });
      }

    },
    //执行删除风场请求
    sendDel(id) {
      this.canDo = false
      this.showLoading("请稍后")
      var md5_data = this.comjs.requestDataEncrypt({SNOWID: id}, this.$store.state.token, this.$md5(this.comjs.sortAsc({SNOWID: id})).toUpperCase())
      this.$http.post("Factory/DeleteFactory", md5_data)
          .then((response) => {
            this.canDo = true
            this.hideLoading()
            if (response.data.SUCCESS) {
              this.$message.success("已删除")
              this.wfModal = false
              this.getWFList()
            } else {
              this.errorRequest(response.data.DATA, response.data.MSG, true)
            }
          })
          .catch((error) => {
            this.$message.error("请求出错")
            this.hideLoading()
            this.canDo = true
          });
    },
    //页码切换
    PGChange(e) {
      this.PageIndex = e
      this.getWFList()
    },
    //确定提交风场
    submitWFForm() {
      if (this.canDo) {
        if (this.drawFence) {
          this.drawFence = false
          this.drawingManagerObj.setDrawingMode(null)
        }
        this.$refs.uForm.validate((valid) => {
          if (valid) {
            var canSend = true
            var _this = this
            var mapDom = _this.$refs.map
            var pgStr = ""
            var isMutil = false
            var centers = []
            if (_this.polygons.length == 1) {
              pgStr = "POLYGON"
            } else if (_this.polygons.length > 1) {
              pgStr = "MULTIPOLYGON"
              isMutil = true
            }

            for (var i = 0; i < _this.polygons.length; i++) {
              var ep = _this.flagDrawPolygonCanSubmit(_this.polygons[i].polygon)
              //console.log(ep)
              if (!ep) {
                canSend = false
                break;
              } else {
                var pgpath = _this.polygons[i].polygon.path
                //console.log(pgpath)
                var d = mapDom.flagIsRingPolygon(pgpath)
                var p = null
                if (d) {
                  p = mapDom.getTurfPolygonByTx(pgpath.elems[0].elems)
                } else {
                  p = mapDom.getTurfPolygonByTx(pgpath.elems)
                }

                var turfCenter = mapDom.getPolygonCenter(p)
                centers.push(turfCenter)
                _this.nowFactoryCenter = mapDom.getTurfCoorListCenter(centers)
                var str = mapDom.getWKTByTXPolygon(pgpath)
                if (isMutil) {
                  if (i == 0) {
                    pgStr += "("
                  } else {
                    pgStr += ","
                  }
                  pgStr += str
                } else {
                  pgStr += str
                }
              }
            }

            if (isMutil) {
              pgStr += ")"
            }
            //console.log(pgStr)
            _this.wktStr = pgStr
            if (canSend && _this.polygons.length > 0) {
              if (this.doType == "1") {
                this.sendAdd()
              } else if (this.doType == "2") {
                this.sendEdit()
              }

            } else {
              this.$message.error("包含违规相交的多边形")
            }

          } else {
            return false;
          }
        });
      }
    },

    //风场区域绘制初始化
    createDraw() {
      this.drawingManagerObj = new window.qq.maps.drawing.DrawingManager({
        drawingMode: window.qq.maps.drawing.OverlayType.POLYGON,
        map: this.map,
        polygonOptions: {
          //clickable: true,
          cursor: "pointer",
          editable: true,
          fillColor: new window.qq.maps.Color(45, 140, 240, 0.3),
          strokeColor: new window.qq.maps.Color(45, 140, 240, 1),
          map: this.map,
          strokeDashStyle: "solid",
          visible: true,
          zIndex: 66,
          clickable: false,
        },
        selectMode: true,
      });
      var _this = this
      window.qq.maps.event.addListener(this.drawingManagerObj, 'polygoncomplete', function (e) {
        //console.log('绘制完成')
        //console.log(e)
        if (_this.wfInfo.POLYGON == 0) {
          _this.appendNewOutPolygon(e)
        } else {
          _this.flagPolygonCompliance(e)
        }
      })
    },
    //开始绘制
    startDraw() {
      if (!this.drawFence) {
        if (!this.drawingManagerObj) {
          this.createDraw()
        } else {
          this.drawingManagerObj.setDrawingMode(window.qq.maps.drawing.OverlayType.POLYGON)
        }
        this.drawFence = true
      }
    },
    //停止绘制
    stopDraw() {
      this.drawFence = false
      this.drawingManagerObj.setDrawingMode(null)
    },
    //取消选中
    checkOffPolygon() {
      this.checkPolygon = null
      for (var i = 0; i < this.polygons.length; i++) {
        this.polygons[i].polygon.setOptions({
          fillColor: new window.qq.maps.Color(45, 140, 240, 0.3),
          strokeColor: new window.qq.maps.Color(45, 140, 240, 1),
        })
      }
    },
    //删除绘制
    delDraw() {
      if (this.checkPolygon) {
        this.$confirm('确定删除当前选中的区域？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.removeDrawPolygon(this.checkPolygon)
        }).catch(() => {
          this.$message.info("已取消")
        });

      } else {
        this.$message.error("请先选择一个区域")
      }
    },
    //清空绘制
    clearDraw() {
      for (var i = 0; i < this.polygons.length; i++) {
        this.polygons[i].polygon.setMap(null)
        var id = this.polygons[i].polygon.id
        this.drawBtns[id].edit.setMap(null)
        this.drawBtns[id].del.setMap(null)
        this.drawBtns[id].ok.setMap(null)
      }
      this.polygons = []
      this.drawBtns = {}
    },
    //添加新的外环多边形
    appendNewOutPolygon(polygon) {
      var _this = this
      var id = "draw_" + _this.wfInfo.POLYGON
      _this.drawBtns[id] = {edit: null, del: null, ok: null}
      polygon.id = id

      window.qq.maps.event.addListener(polygon, 'click', function (e) {
        _this.checkOffPolygon()
        polygon.setOptions({
          fillColor: new window.qq.maps.Color(255, 153, 0, 0.3),
          strokeColor: new window.qq.maps.Color(255, 153, 0, 1),
        })
        _this.checkPolygon = polygon
      })


      _this.polygons.push({id: id, polygon: polygon})

      var mapDom = _this.$refs.map

      //创建按钮
      //console.log(polygon.path)
      var hf = mapDom.flagIsRingPolygon(polygon.path)
      var p = null
      if (hf) {
        p = mapDom.getTurfPolygonByTx(polygon.path.elems[0].elems)
      } else {
        p = mapDom.getTurfPolygonByTx(polygon.path.elems)
      }
      var turfCenter = mapDom.getPolygonCenter(p)

      var txCenter = mapDom.turfCoordToTxPoint(turfCenter.geometry.coordinates)

      var editlabel = new window.qq.maps.Label({
        clickable: true,
        content: '编辑',
        map: _this.map,
        offset: new window.qq.maps.Size(0, 0),
        position: txCenter,
        style: {
          "color": "#FFF",
          "border": "0",
          "cursor": "pointer",
          "padding": "0px 5px",
          "border- radius": "3px",
          "background": "#FF9900",
        },
        visible: false,
        zIndex: 88
      });
      editlabel.id = id

      window.qq.maps.event.addListener(editlabel, 'click', function (e) {
        var pid = e.target.id
        for (var i = 0; i < _this.polygons.length; i++) {
          var pitem = _this.polygons[i].polygon
          if (pitem.id == pid) {
            _this.nowEditDrawPolygon = pitem
            _this.nowEditDrawPolygon.setOptions({
              editable: true,
            })
            _this.nowEditDrawPolygon.setOptions({
              fillColor: new window.qq.maps.Color(255, 153, 0, 0.3),
              strokeColor: new window.qq.maps.Color(255, 153, 0, 1),
            })
            _this.drawBtns[pid].edit.setVisible(false)
            _this.drawBtns[pid].ok.setVisible(true)
          }
        }

      })
      _this.drawBtns[id].edit = editlabel

      var oklabel = new window.qq.maps.Label({
        clickable: true,
        content: '确定',
        map: _this.map,
        offset: new window.qq.maps.Size(0, 0),
        position: txCenter,
        style: {
          "color": "#FFF",
          "fontSize": "12px",
          "border": "0",
          "cursor": "pointer",
          "padding": "0px 5px",
          "border- radius": "3px",
          "background": "#19BE6B",
        },
        visible: false,
        zIndex: 88
      });
      oklabel.id = id

      window.qq.maps.event.addListener(oklabel, 'click', function (e) {
        var pid = e.target.id
        _this.nowEditDrawPolygon = null
        for (var i = 0; i < _this.polygons.length; i++) {
          var pitem = _this.polygons[i].polygon
          if (pitem.id == pid) {
            pitem.setOptions({
              editable: false,
            })
            pitem.setOptions({
              fillColor: new window.qq.maps.Color(45, 140, 240, 0.3),
              strokeColor: new window.qq.maps.Color(45, 140, 240, 1),
            })
            _this.drawBtns[pid].edit.setVisible(true)
            _this.drawBtns[pid].ok.setVisible(false)
            break;
          }
        }
      })
      _this.drawBtns[id].ok = oklabel

      var dellabel = new window.qq.maps.Label({
        clickable: true,
        content: '删除',
        map: _this.map,
        offset: new window.qq.maps.Size(0, 0),
        position: txCenter,
        style: {
          "height": "28px",
          "line-height": "28px",
          "color": "#FFF",
          "fontSize": "12px",
          "border": "0",
          "cursor": "pointer",
          "padding": "0px 5px",
          "border-radius": "3px",
          "background": "#ED3F14",
        },
        visible: false,
        zIndex: 88
      });
      dellabel.id = id

      window.qq.maps.event.addListener(dellabel, 'click', function (e) {
        var pid = e.target.id
        for (var i = 0; i < _this.polygons.length; i++) {
          var pitem = _this.polygons[i].polygon
          if (pitem.id == pid) {
            _this.nowEditDrawPolygon = pitem
            _this.$confirm('确定删除？', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              var id = _this.nowEditDrawPolygon.id
              var ndp = []
              for (var i = 0; i < _this.polygons.length; i++) {
                if (_this.polygons[i].id == id) {
                  _this.removeDrawPolygon(_this.polygons[i].polygon)
                } else {
                  ndp.push(_this.polygons[i])
                }
              }
              _this.polygons = ndp
              _this.nowEditDrawPolygon = null
              _this.$message.success("已删除")
            }).catch(() => {
              this.$message.info("已取消")
            });
          }
        }
      })
      _this.drawBtns[id].del = dellabel

      _this.wfInfo.POLYGON++
      //console.log(this.polygons)
      //console.log(this.wfInfo)
    },
    //绘制时判断多边形是否合规 
    // polygon = 当前多变形
    //parentPolygon= 外层多边形
    flagPolygonCompliance(polygon) {
      var _this = this
      var mapDom = _this.$refs.map

      //有交集数量
      var ofCount = 0
      //包含数量
      var infCount = 0
      //包含的多边形对象
      var parentPolygon = null

      for (var i = 0; i < _this.polygons.length; i++) {
        var p = _this.polygons[i].polygon
        var hf = mapDom.flagIsRingPolygon(p.path)
        if (hf) { //环多边形
          //console.log("环多边形")
          //外环坐标
          var fhp = p.path.elems[0].elems
          var of = mapDom.isOverlay(mapDom.getTurfPolygonByTx(fhp), mapDom.getTurfPolygonByTx(polygon.path.elems))
          //判断与外环有无交集
          if (of) {  //有交集
            //console.log("外环有交集")
            ofCount++
          } else { //无交集 在内部或外部
            //console.log("外环无交集")
            var inf = mapDom.isInclude(mapDom.getTurfPolygonByTx(fhp), mapDom.getTurfPolygonByTx(polygon.path.elems))
            if (inf) { //在内部
              //console.log("在内部")
              var pes = p.path.elems
              //循环内部多边形
              var ec = 0
              for (var j = 1; j < pes.length; j++) {
                var off = mapDom.isOverlay(mapDom.getTurfPolygonByTx(pes[j].elems), mapDom.getTurfPolygonByTx(polygon.path.elems))
                if (off) {
                  //console.log("与内部环有交集")
                  ofCount++
                } else {
                  //console.log("与内部环无交集")
                  var inff = mapDom.isInclude(mapDom.getTurfPolygonByTx(pes[j].elems), mapDom.getTurfPolygonByTx(polygon.path.elems))
                  if (inff) {
                    ec++
                  }
                }
              }
              if (ec == 0) {
                //console.log("没在内部以内")
                //console.log(p)
                infCount++
                parentPolygon = p
              } else {
                //console.log("在内部以内")
                ofCount++
              }

            }
          }
        } else { //普通多边形
          //console.log("普通多边形")
          var ofr = mapDom.isOverlay(mapDom.getTurfPolygonByTx(p.path.elems), mapDom.getTurfPolygonByTx(polygon.path.elems))
          if (ofr) {   //有交集
            //console.log("有交集")
            ofCount++
          } else { //无交集 在内部或外部
            //console.log("无交集")
            var infr = mapDom.isInclude(mapDom.getTurfPolygonByTx(p.path.elems), mapDom.getTurfPolygonByTx(polygon.path.elems))
            if (infr) {   //在内部
              //console.log("在内部")
              //console.log(p)
              infCount++
              parentPolygon = p
            }
          }
        }
      }
      if (ofCount > 0) { //有交集
        this.$confirm('多边形只能绘制在其他多边形内部或外部！', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          polygon.setMap(null)
        }).catch(() => {
          polygon.setMap(null)
        })

      } else {
        if (infCount > 0) { //是包含 setPath

          var nppp = []
          var isH = mapDom.flagIsRingPolygon(parentPolygon.getPath())
          if (isH) {
            var pppath = parentPolygon.getPath().elems
            for (var g = 0; g < pppath.length; g++) {
              var nspp = []
              for (var h = 0; h < pppath[g].length; h++) {
                nspp.push(new window.qq.maps.LatLng(pppath[g].elems[h].lat, pppath[g].elems[h].lng))
              }
              nppp.push(nspp)
            }
          } else {
            var pppathr = parentPolygon.getPath().elems
            var nsppr = []
            for (var k = 0; k < pppathr.length; k++) {
              nsppr.push(new window.qq.maps.LatLng(pppathr[k].lat, pppathr[k].lng))
            }
            nppp.push(nsppr)
          }

          var appp = []
          for (var l = 0; l < polygon.path.elems.length; l++) {
            appp.push(new window.qq.maps.LatLng(polygon.path.elems[l].lat, polygon.path.elems[l].lng))
          }
          nppp.push(appp)
          //console.log(nppp)
          parentPolygon.setPath(nppp)
          var id = parentPolygon.id
          var ndps = []
          for (var z = 0; z < _this.polygons.length; z++) {
            var o = _this.polygons[z]
            if (o.id == id) {
              o.polygon = parentPolygon
            }
            ndps.push(o)
          }
          _this.polygons = ndps
          polygon.setMap(null)
          // console.log(this.polygons)
          // console.log(this.wfInfo)
        } else { //无包含 新增
          //console.log("无包含 新增")
          _this.appendNewOutPolygon(polygon)
        }
      }


    },
    //提交前判断形状是否合规
    flagDrawPolygonCanSubmit(polygon) {
      var _this = this
      var mapDom = _this.$refs.map

      var phf = mapDom.flagIsRingPolygon(polygon.path)
      var pout = null
      if (phf) {
        pout = polygon.path.elems[0].elems
      } else {
        pout = polygon.path.elems
      }

      var f = true

      //先判断外环
      for (var i = 0; i < _this.polygons.length; i++) {
        if (_this.polygons[i].id != polygon.id) {
          var p = _this.polygons[i].polygon
          var hf = mapDom.flagIsRingPolygon(p.path)
          var dpout = null

          if (hf) {
            dpout = p.path.elems[0].elems
          } else {
            dpout = p.path.elems
          }
          var of = mapDom.isOverlay(mapDom.getTurfPolygonByTx(dpout), mapDom.getTurfPolygonByTx(pout))
          if (of) {
            f = false
            break;
          }
        }
      }
      //console.log("先判断外环")
      //console.log(f)
      if (f) {
        //再判断内环
        //console.log("再判断内环")
        if (phf) {
          var inps = polygon.path.elems
          for (var k = 1; k < inps.length; k++) {
            var nowp = inps[k].elems
            var ecc = 0

            var oofr = mapDom.isInclude(mapDom.getTurfPolygonByTx(inps[0].elems), mapDom.getTurfPolygonByTx(nowp))
            if (oofr) {
              for (var j = 1; j < inps.length; j++) {
                if (j != k) {
                  var checkp = inps[j].elems
                  var ofr = mapDom.isOverlay(mapDom.getTurfPolygonByTx(checkp), mapDom.getTurfPolygonByTx(nowp))
                  if (ofr) {
                    ecc++
                    break;
                  }
                }
              }
            } else {
              ecc++
            }

            if (ecc > 0) {
              f = false
              break;
            }
          }
        }
      }


      return f
    },

    //删除绘制的多边形
    removeDrawPolygon(polygon) {
      var id = polygon.id
      polygon.setMap(null)
      this.drawBtns[id].edit.setMap(null)
      this.drawBtns[id].del.setMap(null)
      this.drawBtns[id].ok.setMap(null)
      var ndps = []
      for (var z = 0; z < this.polygons.length; z++) {
        var o = this.polygons[z]
        if (o.id != id) {
          ndps.push(o)
        }
      }
      this.polygons = ndps
      this.wfInfo.POLYGON--

      //console.log(this.polygons)
      //console.log(this.wfInfo)
    },


    //移动到设备所在地图位置
    moveToMachine(id) {
      if (this.nowWFMachineMarker[id] && this.nowWFMachineMarker[id] != undefined) {
        var path = this.nowWFMachineMarker[id].getPosition()
        this.map.panTo(path)
      }
    },
    //移动到地图位置
    moveToPosition(data) {
      var center = new window.qq.maps.LatLng(data.LAT, data.LON);
      this.map.panTo(center)
    },
    //清空地图上的设备标记和设备四角面
    clearMapMachine() {
      for (var item in this.nowWFMachineMarker) {
        this.nowWFMachineMarker[item].setMap(null)
      }
      this.nowWFMachineMarker = {}
      for (var itemp in this.nowWFMachinePolygon) {
        this.nowWFMachinePolygon[itemp].setMap(null)
      }
      this.nowWFMachinePolygon = {}
    },
    //移除某个设备
    removeMapMachine(id) {
      if (this.nowWFMachineMarker[id] && this.nowWFMachineMarker[id] != undefined) {
        this.nowWFMachineMarker[id].setMap(null)
      }
      delete this.nowWFMachineMarker[id]
    },
    //获取风场设备
    getWFMachine(id) {
      var params = {
        FACTORYID: id,
      }
      this.nowWFMachineMarker = {}
      var md5_data = this.comjs.requestDataEncrypt(params, this.$store.state.token, this.$md5(this.comjs.sortAsc(params)).toUpperCase())
      this.$http.post("Machine/GetMachineTree", md5_data)
          .then((response) => {
            // console.log("获取风场设备")
            // console.log(response)
            var list = response.data.DATA
            this.machineList = list
            this.setMachineShowInMap(list)
          })
    },
    setMachineShowInMap(list) {
      for (var i = 0; i < list.length; i++) {
        if (list[i].MACHINETYPERESPONSE.ISDISPLAYFORMAP && list[i].MACHINETYPERESPONSE.ISDISPLAYFORMAP == 1) {
          this.appendMachineToMap(list[i])
        }

        if (list[i].MACHINERESPONSELIST && list[i].MACHINERESPONSELIST.length > 0) {
          this.setMachineShowInMap(list[i].MACHINERESPONSELIST)
        }
      }
    },
    //添加设备到地图上
    appendMachineToMap(machineItem) {
      //console.log(this.machine)
      var ml = this.machineTypes
      var iconPath = "statics/bigScreen/images/icon/fj5.gif"
      var option = 0
      var center = null

      center = new window.qq.maps.LatLng(machineItem.LAT, machineItem.LON);


      var icon = new window.qq.maps.MarkerImage(iconPath, new window.qq.maps.Size(20, 20), null, null, new window.qq.maps.Size(20, 20))
      var marker = new window.qq.maps.Marker({
        draggable: true,
        position: center,
        map: this.map,
        icon: icon,
        zIndex: 100,
        title: machineItem.NAME,
      });
      marker.id = machineItem.SNOWID
      var _this = this
      window.qq.maps.event.addListener(marker, 'dragend', function (e) {
        _this.sendUpdateMachinePosition({SNOWID: marker.id, LAT: e.latLng.lat, LON: e.latLng.lng})
      })
      this.nowWFMachineMarker[machineItem.SNOWID] = marker
      setTimeout(function () {
        var imgs = document.getElementsByTagName("img")
        for (var j = 0; j < imgs.length; j++) {
          if (imgs[j].__src__ == iconPath) {
            imgs[j].style.opacity = option
          }
        }
      }, 100)


      if (machineItem.ZB && machineItem.ZB != "") {
        var pathItem = this.$refs.map.getTXPathByWKTStr(machineItem.ZB)
        var pathArr = pathItem.arr
        var polygon = new window.qq.maps.Polygon({
          map: this.map,
          path: pathArr,
          editable: false,
          fillColor: new window.qq.maps.Color(245, 108, 108, 0.3),
          strokeColor: new window.qq.maps.Color(245, 108, 108, 1),
          strokeDashStyle: "solid",
          visible: true,
          zIndex: 88,
        });
        this.nowWFMachinePolygon[machineItem.SNOWID] = polygon
      }
    },

    //关闭风场编辑
    cancelWfForm() {
      this.wfModal = false
      if (this.map && this.drawFence) {
        this.drawFence = false
        this.drawingManagerObj.setDrawingMode(null)
      }
    },
    //复制设备
    copy(n, d) {
      //console.log(d)
      this.copyInfo = {
        SNOWID: d.SNOWID,
        COPYNUM: "",
        NUMBER: "",
      }
      this.copyFactoryID = d.FACTORYID
      this.copyModal = true
    },
    submitCopyForm() {
      if (this.canDo) {
        this.$refs.copyForm.validate((valid) => {
          if (valid) {
            //console.log(this.copyInfo)
            this.sendCopy()
          } else {
            return false;
          }
        });
      }
    },
    sendCopy() {
      var md5_data = this.comjs.requestDataEncrypt(this.copyInfo, this.$store.state.token, this.$md5(this.comjs.sortAsc(this.copyInfo)).toUpperCase())
      this.$http.post("Machine/MachineCopy", md5_data)
          .then((response) => {
            // console.log("复制风场设备")
            // console.log(response)
            if (response.data.SUCCESS) {
              var d = response.data.DATA
              if (d == "") {
                this.$message.success("复制完成")
              } else {
                this.$alert(d, '复制失败提醒', {
                  confirmButtonText: '确定',
                  callback: action => {
                    //
                  }
                });
              }
              this.copyModal = false
              this.clearMapMachine()
              this.getWFMachine(this.copyFactoryID)
            } else {
              this.$message.error(response.data.MSG)
            }
          })
    },
    //添加设备
    addWFMachine() {
      if (this.doType == "1") {
        this.$message.error("请先创建风场")
      } else {
        this.setMachineStr = "添加设备"
        this.machine = {
          FACTORYID: this.wfInfo.SNOWID,
          NAME: "",
          NUMBER: "",
          CODE: "",
          MACHINETYPEID: "",
          PARENTID: "0",
          LAT: this.WFCenter.lat,
          LON: this.WFCenter.lng,
          HIGHT:"",
          COMMUNICATIONID:"",
        }
        this.machineModal = true
      }

    },
    append(node, data) {
      this.setMachineStr = "添加设备"
      //console.log(data)
      this.machine = {
        FACTORYID: this.wfInfo.SNOWID,
        NAME: "",
        NUMBER: "",
        CODE: "",
        MACHINETYPEID: "",
        PARENTID: data.SNOWID,
        LAT: data.LAT,
        LON: data.LON,
        HIGHT:"",
        COMMUNICATIONID:"",
      }
      this.machineModal = true
    },
    edit(node, data) {
      this.setMachineStr = "编辑设备"
      this.machine = {
        SNOWID: data.SNOWID,
        FACTORYID: data.FACTORYID,
        NAME: data.NAME,
        CODE: data.CODE,
        NUMBER: data.NUMBER,
        MACHINETYPEID: data.MACHINETYPEID,
        PARENTID: data.PARENTID,
        HIGHT:data.HIGHT,
        COMMUNICATIONID:data.COMMUNICATIONID,
        // LAT:data.LAT,
        // LON:data.LON,
      },
      this.MachineEditItem = data
      this.machineModal = true
    },
    //确定添加/编辑设备
    submitMachineForm() {
      if (this.canDo) {
        this.$refs.machineForm.validate((valid) => {
          if (valid) {
            if (this.setMachineStr == "添加设备") {
              this.sendAppendMachineRequest()
            } else {
              this.sendUpdateMachineRequest()
            }
          } else {
            return false;
          }
        });
      }
    },
    //执行调用添加设备
    sendAppendMachineRequest() {
      var md5_data = this.comjs.requestDataEncrypt(this.machine, this.$store.state.token, this.$md5(this.comjs.sortAsc(this.machine)).toUpperCase())
      this.$http.post("Machine/CreateNewMachine", md5_data)
          .then((response) => {
            // console.log("添加风场设备")
            // console.log(response)
            if (response.data.SUCCESS) {
              var d = response.data.DATA
              d.MACHINERESPONSELIST = []
              var pid = null
              if (this.machine.PARENTID != "0") {
                pid = this.machine.PARENTID
              }
              this.$message.success("添加成功")

              if (this.machineList.length > 0) {
                this.$refs.tree.append(d, pid)
                for (var i = 0; i < this.machineTypes.length; i++) {
                  if (this.machineTypes[i].SNOWID == this.machine.MACHINETYPEID && this.machineTypes[i].ISDISPLAYFORMAP && this.machineTypes[i].ISDISPLAYFORMAP == "1") {
                    this.appendMachineToMap(d)
                    break;
                  }
                }
              } else {
                this.getWFMachine(this.machine.FACTORYID)
              }

              this.machineModal = false

            }
          })
    },
    //执行调用编辑设备
    sendUpdateMachineRequest() {
      var md5_data = this.comjs.requestDataEncrypt(this.machine, this.$store.state.token, this.$md5(this.comjs.sortAsc(this.machine)).toUpperCase())
      this.$http.post("Machine/EditMachine", md5_data)
          .then((response) => {
            // console.log("编辑风场设备")
            // console.log(response)
            if (response.data.SUCCESS) {
              this.$set(this.MachineEditItem, 'NAME', this.machine.NAME)
              this.$set(this.MachineEditItem, 'CODE', this.machine.CODE)
              this.$set(this.MachineEditItem, 'NUMBER', this.machine.NUMBER)
              this.$set(this.MachineEditItem, 'MACHINETYPEID', this.machine.MACHINETYPEID)
              this.$set(this.MachineEditItem, 'COMMUNICATIONID', this.machine.COMMUNICATIONID)
              this.$message.success("编辑成功")
              this.machineModal = false
            }
          })
    },
    //修改设备坐标
    sendUpdateMachinePosition(params) {
      var md5_data = this.comjs.requestDataEncrypt(params, this.$store.state.token, this.$md5(this.comjs.sortAsc(params)).toUpperCase())
      this.$http.post("Machine/EditMachineCoordinates", md5_data)
          .then((response) => {
            // console.log("编辑风场设备坐标")
            // console.log(response)
            if (response.data.SUCCESS) {
              //
            }
          })
    },
    //删除设备

    remove(node, data) {
      if (this.canDo) {
        this.$confirm('确定删除设备：' + data.NAME + '及其全部子设备？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.sendDelMachineRequest(data)
        }).catch(() => {
          this.$message.info("已取消")
        });
      }
    },
    //执行调用删除设备
    sendDelMachineRequest(data) {
      var params = {
        SNOWID: data.SNOWID,
      }
      var md5_data = this.comjs.requestDataEncrypt(params, this.$store.state.token, this.$md5(this.comjs.sortAsc(params)).toUpperCase())
      this.$http.post("Machine/DeleteMachineChildren", md5_data)
          .then((response) => {
            //console.log("删除风场设备")
            this.$message.success("删除成功")
            this.$refs.tree.remove(data)
            //console.log(this.machineList)
            this.removeMapMachine(data.SNOWID)
          })
    },

    addMachineZB(data) {
      if (this.canDo) {
        this.$confirm('点击确定开始绘制设备 ' + data.NAME + ' 的四角坐标。单击画点，双击确定，ESC退出。', '四角坐标添加说明', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.nowMachineItem = data
          var path = this.nowWFMachineMarker[data.SNOWID].getPosition()
          this.map.panTo(path)
          this.map.zoomTo(22)
          this.startDrawMachine()
        })
      }
    },
    //设备四角坐标区域绘制初始化
    createMachineDraw() {
      this.drawingMachineObj = new window.qq.maps.drawing.DrawingManager({
        drawingMode: window.qq.maps.drawing.OverlayType.POLYGON,
        map: this.map,
        polygonOptions: {
          //clickable: true,
          cursor: "pointer",
          editable: true,
          fillColor: new window.qq.maps.Color(245, 108, 108, 0.3),
          strokeColor: new window.qq.maps.Color(245, 108, 108, 1),
          map: this.map,
          strokeDashStyle: "solid",
          visible: true,
          zIndex: 88,
          clickable: false,
        },
        selectMode: false,
      });
      var _this = this
      window.qq.maps.event.addListener(this.drawingMachineObj, 'polygoncomplete', function (e) {
        // console.log('绘制完成')
        // console.log(e)
        var mapDom = _this.$refs.map
        if (_this.drawMachine) {
          _this.drawMachine = false
          _this.drawingMachineObj.setDrawingMode(null)
        }
        var f = _this.flagMachineZBIsOk(e)
        if (f) {
          _this.nowWFMachinePolygon[_this.nowMachineItem.SNOWID] = e
          var zbs = "POLYGON"
          zbs = zbs + mapDom.getWKTByTXPolygon(e.path)
          _this.setMachineZB(zbs)
        } else {
          e.setMap(null)
          _this.$confirm('设备必须在四角坐标内且四角坐标只能有四个角', '坐标错误', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
        }
      })
    },
    //开始绘制设备四角坐标
    startDrawMachine() {
      if (!this.drawMachine) {
        if (!this.drawingMachineObj) {
          this.createMachineDraw()
        } else {
          this.drawingMachineObj.setDrawingMode(window.qq.maps.drawing.OverlayType.POLYGON)
        }
        this.drawMachine = true
      }
    },
    flagMachineZBIsOk(e) {
      var _this = this
      var mapDom = _this.$refs.map
      var can = true
      var pp = e.path.elems
      if (pp.length != "4") {
        can = false
      } else {
        if (this.nowWFMachineMarker[this.nowMachineItem.SNOWID] && this.nowWFMachineMarker[this.nowMachineItem.SNOWID] != undefined) {
          var p = this.nowWFMachineMarker[this.nowMachineItem.SNOWID].getPosition()
          can = mapDom.isPointInPolygon(mapDom.getTurfPointByTx(p), mapDom.getTurfPolygonByTx(pp))
        }
      }
      return can
    },
    setMachineZB(zb) {
      var params = {
        SNOWID: this.nowMachineItem.SNOWID,
        ZB: zb
      }
      var md5_data = this.comjs.requestDataEncrypt(params, this.$store.state.token, this.$md5(this.comjs.sortAsc(params)).toUpperCase())
      this.$http.post("Machine/EditMachinePointZB", md5_data)
          .then((response) => {
            // console.log("编辑风场设备四角坐标")
            // console.log(response)
            if (response.data.SUCCESS) {
              this.$set(this.nowMachineItem, 'ZB', zb)
              if (zb == "") {
                this.nowWFMachinePolygon[this.nowMachineItem.SNOWID].setMap(null)
                delete this.nowWFMachinePolygon[this.nowMachineItem.SNOWID]
              }
            }
          })
    },
    //删除四角四角坐标
    removeMachineZB(data) {
      this.$confirm('确定删除该设备的四角坐标？', '操作提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.nowMachineItem = data
        this.setMachineZB("")
      })
    },


    //发电量


    setPower(data) {
      this.nowFactory = data
      this.postInfoP = {
        MACHINESNOWID: "",
        FACTORYSNOWID: data.SNOWID,
        DAYPOWER: "",
        DAYELECTRICITY: "",
        DAYPOWERLIMIT: "",
        DAYS: "",
      }
      this.doTypeStrP = data.NAME + "-设置月发电量"
      this.postModalP = true
    },
    submitPostFormP() {
      if (this.canDo) {
        this.$refs.pForm.validate((valid) => {
          if (valid) {
            this.sendSetPower()
          } else {
            return false;
          }
        });
      }
    },
    sendSetPower() {
      this.canDo = false
      this.showLoading("请稍后")
      //console.log(this.postInfoP)
      var params = {
        FACTORYSNOWID: this.postInfoP.FACTORYSNOWID,
        MACHINESNOWID: this.postInfoP.MACHINESNOWID,
        DAY: this.comjs.getDateMinStrByDate(this.postInfoP.DAYS),
        DAYPOWER: this.postInfoP.DAYPOWER,
        DAYELECTRICITY: this.postInfoP.DAYELECTRICITY,
        DAYPOWERLIMIT: this.postInfoP.DAYPOWERLIMIT,
      }
      var md5_data = this.comjs.requestDataEncrypt(params, this.$store.state.token, this.$md5(this.comjs.sortAsc(params)).toUpperCase())
      this.$http.post("DailyPower/CreateOrEditDailyPower", md5_data)
          .then((response) => {
            this.canDo = true
            this.hideLoading()
            if (response.data.SUCCESS) {
              this.$message.success("已添加")
              this.postModalP = false
              if (this.listModalP) {
                this.getPostListP()
              }
            } else {
              this.errorRequest(response.data.DATA, response.data.MSG, true)
            }
          })
          .catch((error) => {
            this.$message.error("请求出错")
            this.hideLoading()
            this.canDo = true
          });
    },
    logPower(data) {
      this.etimeP = ""
      this.stimeP = ""
      this.nowFactory = data
      this.searchPostP()
      this.doListStrP = data.NAME + "-发电量记录"
      this.listModalP = true
    },
    getPostListP() {
      this.showLoading("请稍后")
      var st = ""
      var et = ""
      if(this.stimeP!=null && this.stimeP!="" && this.stimeP.length==2){
          st=this.comjs.getDateMinStrByDate(this.stimeP[0])
          var lastday=this.comjs.getDaysInCurrentMonth(this.stimeP[1])
          var  ett=this.comjs.getDateMinStrByDate(this.stimeP[1]).split("-")
          et=ett[0]+"-"+ett[1]+"-"+lastday
      }
      var params = {
        PageIndex: this.PageIndexP,
        PageSize: this.PageSizeP,
        MACHINEID: "",
        FACTORYID: this.nowFactory.SNOWID,
        STARTDATE: st,
        ENDDATE: et,
      }
      var md5_data = this.comjs.requestDataEncrypt(params, this.$store.state.token, this.$md5(this.comjs.sortAsc(params)).toUpperCase())
      this.$http.post("DailyPower/GetDayPowerList", md5_data)
          .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
              var list=response.data.DATA
              var  nl=[]
              for(var i=0;i<list.length;i++){
                let d=list[i]
                d.DAYMOON=""
                if(d.DAY && d.DAY!="" && d.DAY.indexOf("-")!= -1){
                  var solitDate=d.DAY.split("-")
                  d.DAYMOON = solitDate[0]+"年"+solitDate[1]+"月"
                }
                nl.push(d)
              }
              this.postListP = nl
              this.TotalP = response.data.TOTALCOUNT
            } else {
              this.postListP = []
              this.TotalP = 0
              this.errorRequest(response.data.DATA, response.data.MSG, true)
            }
          })
          .catch((error) => {
            this.hideLoading()
            this.postListP = []
            this.TotalP = 0
          });
    },
    PGChangeP(e) {
      this.PageIndexP = e
      this.getPostListP()
    },
    searchPostP() {
      this.PageIndexP = 1
      this.getPostListP()
    },
    addPostP() {
      this.postInfoP = {
        FACTORYSNOWID: this.nowFactory.SNOWID,
        MACHINESNOWID: "",
        DAYPOWER: "",
        DAYELECTRICITY: "",
        DAYPOWERLIMIT: "",
        DAYS: "",
      }
      this.doTypeStrP = this.nowFactory.NAME + "-设置每日发电量"
      this.postModalP = true
    },
    // 打开风场实时数据弹框
    openRealTimeData(data) {
      this.realTimeDataP = true
      this.currentRealTimeObj = data
      this.realTimeDataStr = data.NAME
      this.getRealTimeData()
    },
    closeRealTimeData(){
      this.realTimeDataP = false
      this.fillingTime = ''
      this.timing = ''
      this.currentRealTimeObj = {}
      this.realTimeDataStr = ''
      this.currentSelection = {}
    },
    realTimeDataRemove(data){
      this.$confirm('确定删除该实时数据吗？', '操作提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.showLoading("请稍后")
        let params = {
          SNOWID: data.SNOWID,
        }
        let md5_data = this.comjs.requestDataEncrypt(params, this.$store.state.token, this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("FactoryData/DeleteFactoryData", md5_data)
            .then((response) => {
              this.hideLoading()
              if (response.data.SUCCESS) {
                this.getRealTimeData()
              } else {
                this.errorRequest(response.data.DATA, response.data.MSG, true)
              }
            })
            .catch((error) => {
              this.hideLoading()
            });
      })
    },
    getRealTimeData() {
      this.showLoading("请稍后")
      // 解决loading遮罩层在dialog之前显示导致dialog层的z-index大于loading遮罩层的bug
      this.$nextTick(() => {
        let loadingNode = document.getElementsByClassName('el-loading-mask')
        for (let i = 0; i < loadingNode.length; i++) {
          loadingNode [i].style.zIndex = 999999 // 设置一个较大的值
        }
      })
      let time = ''
      if (this.fillingTime && this.fillingTime != '') {
        time = this.comjs.getDateMinStrByDate(this.fillingTime)
      }
      let params = {
        FACTORYID: this.currentRealTimeObj.SNOWID,
        UPDATE: time,
        UPHOUR: this.timing
      }
      let md5_data = this.comjs.requestDataEncrypt(params, this.$store.state.token, this.$md5(this.comjs.sortAsc(params)).toUpperCase())
      this.$http.post("FactoryData/GetFactoryData", md5_data)
          .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
              this.realTimeData = [...response.data.DATA]
            } else {
              this.realTimeData = []
              this.errorRequest(response.data.DATA, response.data.MSG, true)
            }
          })
          .catch((error) => {
            this.hideLoading()
            this.realTimeData = []
          });
    },
    nextStep(){
      let ok=1
      let arr;
      if(this.realTimeDataActive === 1){
        arr = this.realTimeStepArr1
      }else if(this.realTimeDataActive === 2){
        arr = this.realTimeStepArr2
      }
      for(let i in arr){
        this.$refs.realTimeDataForm.validateField(arr[i],err=>{if(err) ok=0 }) //校验当前字段，如果没有通过验证，则ok=0
        if(ok===0)break   //如果ok为0，则说明有字段没有通过验证，终止循环
      }
      if(ok===1){this.realTimeDataActive++} //循环结束，如果当前步骤的字段都通过验证，则进入下一步
    },
    openRealTimeDataModify(type,obj) {
      if(type === '新增'){
        this.realTimeDataModifyStr = '新增实时数据'
        this.realTimeDataInfo = JSON.parse(JSON.stringify(this.currentSelection))
      }else if(type === '修改'){
        this.realTimeDataModifyStr = '修改实时数据'
        this.realTimeDataInfo = JSON.parse(JSON.stringify(obj))
      }
      this.realTimeDataModifyP = true
    },
    closeRealTimeDataModify(){
      this.$refs.realTimeDataForm.resetFields()
      this.realTimeDataModifyStr = ''
      this.realTimeDataActive = 1
      this.realTimeDataInfo = {}
      this.realTimeDataModifyP = false
    },
    submitRealForm(){
      this.$refs.realTimeDataForm.validate((valid,object)=>{
        if (valid) {
          let url
          let params = {
            ...this.realTimeDataInfo
          }
          let text
          if(this.realTimeDataModifyStr === '新增实时数据'){
            url = 'FactoryData/CreateNewFactoryData'
            text = '新增成功'
            params.FACTORYID = this.currentRealTimeObj.SNOWID
            delete params.CREATETIME
            delete params.DELETETIME
            delete params.ISDELETE
            delete params.ISDELETE
            delete params.SNOWID
          }else if(this.realTimeDataModifyStr === '修改实时数据'){
            url = 'FactoryData/EditFactoryData'
            text = '修改成功'
          }
          let md5_data = this.comjs.requestDataEncrypt(params, this.$store.state.token, this.$md5(this.comjs.sortAsc(params)).toUpperCase())
          this.$http.post(url, md5_data)
              .then((response) => {
                this.hideLoading()
                if (response.data.SUCCESS) {
                  this.closeRealTimeDataModify()
                  this.getRealTimeData()
                  this.$message({
                    message: text,
                    type: 'success'
                  });
                } else {
                  this.errorRequest(response.data.DATA, response.data.MSG, true)
                }
              })
              .catch((error) => {
                this.hideLoading()
              });
        }
      })
    },
    // 表格多选框只能单选
    select(selection, row) {
      this.timeData = row
      if (selection.length > 1) {
        let del_row = selection.shift()
        this.$refs.realTimeTable.toggleRowSelection(del_row, false) // 用于多选表格，切换某一行的选中状态，如果使用了第二个参数，则是设置这一行选中与否（selected 为 true 则选中）
      }
      if(selection.length > 0){
        this.currentSelection = {...selection[0]}
      }else{
        this.currentSelection = {}
      }
    },

  },
  computed: {},
  watch: {},
};
</script>
<style scoped>
.nowMachineBox {
  width: 400px;
  height: 600px;
  position: relative;
  float: left;
  overflow: hidden;
  margin-left: 10px;
  background-color: #f4f4f5;
}

.nowMachineBox .mlTitle {
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  font-weight: bold;
  padding: 0 12px;
}

.nowMachineBox .machineListBox {
  height: 520px;
  overflow-y: scroll;
}

.nowMachineBox .machineListNull p {
  color: #adadad;
  text-align: center;
}

.nowMachineBox .addMachineBox {
  height: 40px;
}

.nowMachineBox .addMachineBox .addBtn {
  width: 100%;
  border-radius: 0;
}

.machineListOut {
  overflow: hidden;
  padding: 20px 0px;
}

.machineListOut .machineItem {
  overflow: hidden;
  height: 40px;
  cursor: pointer;
  border-bottom: 1px solid #ADADAD;
}

.machineListOut .machineItem .machineItemImg {
  width: 30px;
  height: 30px;
  float: left;
  display: block;
  margin: 5px;
}

.machineListOut .machineItem .machineItemTxt {
  width: 150px;
  height: 40px;
  color: #666;
  line-height: 40px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 15px;
  float: left;
  margin-left: 10px;
}

.machineListOut .machineItem .removeIcon {
  float: left;
  color: #FF0036;
}

.el-tree {
  background: transparent;
}

.custom-tree-node {
  width: 100%;
}

.demo-table-expand {
  font-size: 0;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
.demo-table-expand  ::v-deep.el-form-item label{
  color: #99a9bf;
}
.el-table::before{
  height: 0px;
}
::v-deep .realTimeDialog .el-table__header-wrapper .el-checkbox {
  display: none;
}
</style>