<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">维修记录管理</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                <el-cascader v-model="result"  :options="tree" :props="treeProps" placeholder="搜索或选择维修结果" filterable class="iww"></el-cascader>

                <el-select v-model="fc" placeholder="选择风场"  filterable  clearable class="iw" @change="getSbByFcS">
                        <el-option v-for="item in fcList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                </el-select>
                <el-select v-model="type" placeholder="选择设备类型"  filterable  clearable class="iw" @change="getSbByFcS">
                        <el-option v-for="item in mtList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                </el-select>
                <el-select v-model="sb" placeholder="选择设备"  filterable  clearable class="iw">
                        <el-option v-for="item in sbList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                </el-select>


                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-button type="success" @click="addPost" class="fr">添加</el-button> 
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column type="expand">
                        <template slot-scope="props" >
                            <div class="hclist" v-for="item in props.row.THINGS" :key="item.SNOWID">
                                名称：{{item.THING.CNNAME}}　数量：{{item.USECOUNT}}
                            </div>
                            <div v-if="props.row.THINGS.length==0">未使用耗材</div>

                        </template>
                    </el-table-column>
                    <el-table-column prop="FACTORY.NAME" label="风场名称"></el-table-column>
                    <el-table-column prop="MACHINE.NAME" label="设备名称"></el-table-column>
                    <el-table-column prop="FIXTIME" label="维修时间"></el-table-column>
                    <el-table-column prop="RESULTENTITY.NAME" label="维修结果"></el-table-column>
                    <el-table-column prop="MONEY" label="维修费用"></el-table-column>
                    <el-table-column prop="USERSSTR" label="维修人员"></el-table-column>
                    <el-table-column prop="BZ" label="备注"></el-table-column>
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                            <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button>
                            <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
                            <el-button @click="filePost(scope.row)"  type="text" size="small" style="color:#409EFF;margin-left:0;">上传工作票</el-button>
                            <!-- <el-button @click="downPost(scope.row)"  type="text" size="small" style="color:#409EFF;margin-left:0;">下载工作票</el-button> -->
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="800px">
            <el-form :model="postInfo" status-icon :rules="postrules"  ref="uForm" label-width="100px" class="demo-uForm">
                <el-form-item label="风场">
                    <el-select v-model="postInfo.FACTORYID" placeholder="请选择风场"  filterable  style="width:100%;" @change="fcChange">
                            <el-option v-for="item in fcList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="设备类型">
                    <el-select v-model="checkLx" placeholder="请选择设备类型"  filterable  style="width:100%;" @change="lxChange">
                        <el-option v-for="item in mtList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="设备" prop="MACHINEID">
                    <el-select v-model="postInfo.MACHINEID" placeholder="请选择设备"  filterable  style="width:100%;">
                            <el-option v-for="item in sbList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="维修时间" prop="FIXTIME">
                    <el-date-picker  v-model="postInfo.FIXTIME"  type="datetime" placeholder="选择维修时间" style="width:100%;"> </el-date-picker>
                </el-form-item>
                <el-form-item label="维修结果" prop="RESULT">
                     <el-cascader v-model="postInfo.RESULT"  :options="tree" :props="treeProps" placeholder="搜索或选择维修结果" filterable style="width:100%"></el-cascader>
                </el-form-item>
                
                <el-form-item label="维修费用" prop="MONEY">
                    <el-input v-model="postInfo.MONEY" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="维修人员" prop="USERS">
                    <el-select v-model="postInfo.USERSL" placeholder="请选择人员" multiple filterable clearable style="width:100%;">
                            <el-option v-for="item in userList" :key="item.SNOWID" :label="item.TRUENAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="备注" prop="BZ">
                    <el-input v-model="postInfo.BZ" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="维修耗材">
                    <el-button type="success" @click="addHP()" class="fr">添加</el-button>
                </el-form-item>
                <div  v-if="postInfo.THINGS.length>0" style="overflow:hidden;margin-top:20px;">
                    <div   v-for="(cool, index) in postInfo.THINGS" :key="cool.index">
                        <div style="overflow:hidden;border-bottom:1px solid #ADADAD;margin-bottom:20px;">
                            <el-form-item label="货品：" class="allItem" :prop="'THINGS.' + index + '.THINGID'" :rules="{ required: true, message: '请选择货品', trigger: 'blur'}">
                                <el-select v-model="cool.STOREID" placeholder="请选择仓库"    style="width:180px;float:left;margin-right:12px;" @change="getThingsByStore(index)">
                                        <el-option label="公司仓库" value="1630854024087605248" ></el-option>
                                        <el-option label="高桥二级仓库" value="1745264458265137152" ></el-option>
                                        <el-option label="围屏二级仓库" value="1636549548467949568" ></el-option>
                                </el-select>
                                <el-select v-model="cool.GYSID" placeholder="请选供应商"    style="width:180px;float:left;margin-right:12px;" @change="getThingsByStore(index)">
                                    <el-option v-for="item in supplierList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                                </el-select>
                                <el-select v-model="cool.THINGID" placeholder="请选择货品" filterable  :prop="'THINGS.' + index + '.THINGID'" :rules="{ required: true, message: '请选择货品', trigger: 'blur'}" style="width:180px;float:left;margin-right:12px;">
                                        <el-option v-for="item in cool.THINGLIST" :key="item.SNOWID" :label="item.CNNAME" :value="item.SNOWID" ></el-option>
                                </el-select>
                                <div style="float:right;overflow:hidden;">
                                    <el-button type="text" style="color:#F56C6C;float:right;margin-right:12px" @click="delHP(index)">删除</el-button>
                                </div>
                            </el-form-item>
                            <el-form-item label="数量："  class="allItem"  :prop="'THINGS.' + index + '.USECOUNT'" :rules="[{ required: true, message: '数量不能为空'},{ type: 'number', message: '数量必须为数字'}]">
                                <el-input  v-model.number="cool.USECOUNT" autocomplete="off" class="icss" type="number"></el-input>
                            </el-form-item>
                        </div>
                    </div>  
                </div>

                <el-form-item>
                    <el-button type="primary" @click="submitPostForm()">提交</el-button>
                    <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog title="上传工作票" :visible.sync="fileModal" width="800px"  :append-to-body="true" top="50px">
            <el-form :model="fileInfo" status-icon :rules="filerules"  ref="fForm" label-width="150px" class="demo-uForm">
                <el-form-item label="三讲一落实执行卡" prop="A">
                    <el-upload
                        ref="uploadA"
                        class="upload-demo"
                        drag
                        :multiple="false"
                        :limit="1" 
                        accept=".docx,.doc"
                        action=""
                        :http-request="uploadFun"
                        :before-upload="beforeAvatarUploadA"
                        :on-remove="removeFileA">
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                    </el-upload>
                </el-form-item>
                <el-form-item label="风机工作票" prop="B">
                    <el-upload
                        ref="uploadB"
                        class="upload-demo"
                        drag
                        :multiple="false"
                        :limit="1"
                        accept=".docx,.doc"
                        action=""
                        :http-request="uploadFun"
                        :before-upload="beforeAvatarUploadB"
                        :on-remove="removeFileB">
                       
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                    </el-upload>
                </el-form-item>
                <el-form-item label="危险因素控制票" prop="C">
                    <el-upload
                        ref="uploadC"
                        class="upload-demo"
                        drag
                        :multiple="false"
                        :limit="1"
                        accept=".docx,.doc"
                        action=""
                        :http-request="uploadFun"
                        :before-upload="beforeAvatarUploadC"
                        :on-remove="removeFileC">
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                    </el-upload>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitFileForm()">提交</el-button>
                    <el-button @click="fileModal = false">取消</el-button>
                </el-form-item>
            </el-form>

        </el-dialog>
    </div>
</template>
<script>
export default {
  name: "WX",
  data() {
    var FIXTIME = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择维修时间名称"));
      } else {
        callback();
      }
    };
    var RESULT = (rule, value, callback) => {
      if (value === "" || value.length== 0 ) {
        callback(new Error("请选择维修结果"));
      } else {
        callback();
      }
    };
    
    var MONEY=(rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入维修费用'));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('维修费用只能是数字'));
        }else if(value<=0){
            callback(new Error('维修费用必须大于0'));
        }else {
          callback();
        }
    };

    var THINGS= (rule, value, callback) => {
        //console.log(value)
      if (value.length ==0) {
        callback(new Error("请选择维修耗材"));
      } else {
        callback();
      }
    };

    var USERSL= (rule, value, callback) => {
      if (value.length ==0) {
        callback(new Error("请选择维修人员"));
      } else {
        callback();
      }
    };

    var A= (rule, value, callback) => {
      if (value ==0) {
        callback(new Error("请选择三讲一落实执行卡"));
      } else {
        callback();
      }
    };

    var B= (rule, value, callback) => {
      if (value ==0) {
        callback(new Error("请选择风机工作票"));
      } else {
        callback();
      }
    };

    var C= (rule, value, callback) => {
      if (value ==0) {
        callback(new Error("请选择危险因素控制票"));
      } else {
        callback();
      }
    };

    var FACTORYID=(rule, value, callback) => {
      if (value === "" || value.length== 0 ) {
        callback(new Error("请选择风场"));
      } else {
        callback();
      }
    };

    var MACHINEID=(rule, value, callback) => {
      if (value === "" || value.length== 0 ) {
        callback(new Error("请选择设备"));
      } else {
        callback();
      }
    };
    
    return {
        canDo:true,
        
        result:"",

        

        tree:[],
        treeProps:{
            value:"SNOWID",
            label:"NAME",
            children:"CHILD",
            //checkStrictly:true,
        },

        userList:[],
        kcList:[],

        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,

        doTypeStr:"",
        doType:"",
        postModal:false,
        postInfo:{
            THINGS:[]
        },
        postrules: {
            FACTORYID:[{ validator: FACTORYID, trigger: "blur" }],
            MACHINEID:[{ validator: MACHINEID, trigger: "blur" }],
            FIXTIME: [{ validator: FIXTIME, trigger: "blur" }],
            RESULT: [{ validator: RESULT, trigger: "blur" }],
            MONEY: [{ validator: MONEY, trigger: "blur" }],
            THINGS: [{ validator: THINGS, trigger: "blur" }],
            USERSL: [{ validator: USERSL, trigger: "blur" }],
        },


        fileId:"",
        fileModal:false,
        fileInfo:{},
        filerules: {
            A: [{ validator: A, trigger: "blur" }],
            B: [{ validator: B, trigger: "blur" }],
            C: [{ validator: C, trigger: "blur" }],
        },
        fileA:null,
        fileB:null,
        fileC:null,


        mtList:[],
        type:"",

        fcList:[],
        fc:"",
       
        checkLx:"",
        sbList:[],
        sb:"",

        supplierList:[],
    };
  },
  mounted() {
    this.loadItem=null
    this.getDicTree()
    this.getUserList()
    this.getPostList()
    this.getFCList()
    this.getMTypeList()
    this.getSupplierList()
  },
  methods: {
    getSupplierList(){
        var params={}
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Storage/GetStorageSupplierList", md5_data)
            .then((response) => {
                this.canDo=true
                if (response.data.SUCCESS) {
                    this.supplierList=response.data.DATA
                }
            })
    },
    getFCList(){
        var params={}
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Factory/GetFactoryWithoutPage", md5_data)
            .then((response) => {
                this.canDo=true
                if (response.data.SUCCESS) {
                    this.fcList=response.data.DATA
                }
            })
    },
    fcChange(e){
        this.postInfo.MACHINEID=""
        this.getSbByFc()
    },
    lxChange(e){
        this.checkLx=e
        this.postInfo.MACHINEID=""
        this.getSbByFc()
    },
    //获取设备类型列表
    getMTypeList(){
        var params={
            PAGEINDEX:1,
            PAGESIZE:3000,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("MachineType/GetMachineTypeList", md5_data)
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.mtList=response.data.DATA
                } 
            })
    },
    getSbByFc(){
        var params={
            FACTORYID:this.postInfo.FACTORYID,
            MACHINETYPEID:[this.checkLx],
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Machine/GetMachine", md5_data)
            .then((response) => {
                if (response.data.SUCCESS && response.data.DATA.length>0) {
                    this.sbList=response.data.DATA
                } 
            }) 
    },
    getSbByFcS(){
        this.sb=""
        var params={
            FACTORYID:this.fc,
            MACHINETYPEID:[this.type],
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Machine/GetMachine", md5_data)
            .then((response) => {
                if (response.data.SUCCESS && response.data.DATA.length>0) {
                    this.sbList=response.data.DATA
                } 
            }) 
    },
    addHP(){
        var sid=""
        if(this.postInfo.FACTORYID ==""){
            sid= "1630854024087605248"
        }else if(this.postInfo.FACTORYID =="1555437816215900160"){
            sid="1636549548467949568"
        }else if(this.postInfo.FACTORYID =="1555428784377827328"){
            sid="1745264458265137152"
        }
        var gysid=""
        if(this.supplierList.length>0){
            gysid=this.supplierList[0].SNOWID
        }
        this.postInfo.THINGS.push({
            GYSID:gysid,
            THINGID:"",
            USECOUNT:"",
            THINGLIST:[],
            STOREID:sid,
        })
        this.getKCList(this.postInfo.THINGS.length-1,sid,gysid)
    },
    delHP(index){
        if(this.canDo){
            this.$confirm('确定删除此耗材？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
                var list=this.postInfo.THINGS
                var nl=[]
                for(var i=0;i<list.length;i++){
                    if(i!=index){
                        nl.push(list[i])
                    }
                }
                this.postInfo.THINGS = nl
            }).catch(() => {
                this.$message.info("已取消")    
            });
        }
    },
    getDicTree(){
        var md5_data =this.comjs.requestDataEncrypt({}, this.$store.state.token,this.$md5(this.comjs.sortAsc({})).toUpperCase())
        this.$http.post("Dict/GetDictTreeList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.tree=response.data.DATA
            } 
            })
    },
    getUserList(){
        var params={ }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("UserManage/GetUserListWithoutPage", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.userList=response.data.DATA
            } 
            })
    },
    getThingsByStore(index){
        this.postInfo.THINGS[index].THINGID=""
        this.postInfo.THINGS[index].THINGLIST=[]
        this.getKCList(index,this.postInfo.THINGS[index].STOREID,this.postInfo.THINGS[index].GYSID)
    },
    getKCList(index,sid,gid){
        var params={
            PAGEINDEX:1,
            ISSELECTALL:true,
            PAGESIZE:2000,
            STORAGEID:sid,
            SUPPLIERID:gid,
         }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Storage/GetStorageThingList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postInfo.THINGS[index].THINGLIST=response.data.DATA
            } 
            })
    },

    getPostList(){
        this.showLoading("请稍后")
        var params={
            PAGEINDEX:this.PageIndex,
            PAGESIZE:this.PageSize,
            RESULT:this.result,
            MACHINEID:this.sb,
            FACTORYID:this.fc,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Fix/GetFixList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                var list=response.data.DATA
                var nl=[]
                for(var i=0;i<list.length;i++){
                    var d=list[i]
                    d.USERSSTR="无"
                    if(d.USERS.length>0){
                        for(var j=0;j<d.USERS.length;j++){
                            if(j==0){
                                d.USERSSTR = d.USERS[j].USER.TRUENAME
                            }else{
                                d.USERSSTR =d.USERSSTR+"、"+d.USERS[j].USER.TRUENAME
                            }
                        }
                    }
                    nl.push(d)
                }
                
                this.postList=nl
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
        });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    addPost(){
        this.postInfo={
            FIXTIME:"",
            RESULT:"",
            BZ:"",
            MONEY:"",
            THINGS:[],
            USERSL:[],
            FACTORYID:"",
            MACHINEID:"",
        }
        this.checkLx=""
        this.sbList=[]
        this.doTypeStr="添加维修记录"
        this.doType="1"
        this.postModal=true
    },
    sendAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("Fix/CreateNewFix", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    editPost(item){
        var rs=[]
        for(var i=0;i<item.DICTLIST.length;i++){
            rs.push(item.DICTLIST[i].SNOWID)
        }
        var ts=[]
        if(item.THINGS){
            for(var j=0;j<item.THINGS.length;j++){
                ts.push({
                    THINGID:item.THINGS[j].THING.SNOWID,
                    USECOUNT:item.THINGS[j].USECOUNT,
                })
            }
        }

        var us=[]
        for(var k=0;k<item.USERS.length;k++){
            us.push(item.USERS[k].USER.SNOWID)
        }


        this.postInfo={
            SNOWID:item.SNOWID,
            FIXTIME:new Date(item.FIXTIME),
            RESULT:rs,
            BZ:item.BZ,
            MONEY:item.MONEY,
            THINGS:ts,
            USERSL:us,
            MACHINEID:item.MACHINEID,
            FACTORYID:item.FACTORY.SNOWID,
        }
        this.sbList=[]
        this.checkLx=item.MACHINE.MACHINETYPEID
        this.getSbByFc()
        this.doTypeStr="修改维修记录"
        this.doType="2"
        this.postModal=true
    },
    sendEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("Fix/EditFix", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.postModal=false
                    this.getPostList();
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    delPost(item){
        if(this.canDo){
            this.$confirm('确定删除维修记录？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
            this.sendDel(item.SNOWID)
            }).catch(() => {
            this.$message.info("已取消")    
          });
        }

    },
    sendDel(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({SNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:id})).toUpperCase())
        this.$http.post("Fix/DeleteFix", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    submitPostForm() {
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    this.postInfo.FIXTIME = this.comjs.getDateStrByDate(this.postInfo.FIXTIME)
                    this.postInfo.MONEY= parseFloat(this.postInfo.MONEY)
                    var ul=this.postInfo.USERSL
                    var nul=[]
                    for(var i=0;i<ul.length;i++){
                        var  u=ul[i]
                        nul.push({USERID:u})
                    }
                    this.postInfo.USERS = nul
                    this.postInfo.RESULT = this.postInfo.RESULT[this.postInfo.RESULT.length-1]
                    if(this.doType=="1"){
                        this.sendAdd()
                    }else if(this.doType=="2"){
                        this.sendEdit()
                    }
                } else {
                    return false;
                }
            });
        }
    },
    filePost(item){
        this.fileId=item.SNOWID
        this.fileInfo={
            A:0,
            B:0,
            C:0,
        }
        if(this.fileA){
            this.fileA=null
            this.$refs.uploadA.clearFiles()
        }
        if(this.fileB){
            this.fileB=null
            this.$refs.uploadB.clearFiles()
        }
        if(this.fileC){
            this.fileC=null
            this.$refs.uploadC.clearFiles()
        }
        this.fileModal=true
    },
    removeFileA(){
        this.fileInfo.A=0
        this.fileA=null
    },
    beforeAvatarUploadA(file) {
        const isWord = file.type === 'application/msword' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        if (!isWord) {
          this.$message.error('只能上传word文件!');
          this.fileInfo.A=0
          this.fileA=null
        }else{
            this.fileInfo.A=1
            this.fileA=file
        }
        return isWord;
    },
    removeFileB(){
        this.fileInfo.B=0
        this.fileB=null
    },
    beforeAvatarUploadB(file) {
        const isWord = file.type === 'application/msword' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        if (!isWord) {
          this.$message.error('只能上传word文件!');
          this.fileInfo.B=0
          this.fileB=null
        }else{
            this.fileInfo.B=1
            this.fileB=file
        }
        return isWord;
    },
    removeFileC(){
        this.fileInfo.C=0
        this.fileC=null
    },
    beforeAvatarUploadC(file) {
        const isWord = file.type === 'application/msword' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        if (!isWord) {
          this.$message.error('只能上传word文件!');
          this.fileInfo.C=0
          this.fileC=null
        }else{
            this.fileInfo.C=1
            this.fileC=file
        }
        return isWord;
    },
    uploadFun(file){
        //console.log(file)
        this.$refs.fForm.validate((valid) => {
                //console.log(valid)
            });
    },
    submitFileForm() {
        if (this.canDo) {
            this.$refs.fForm.validate((valid) => {
                //console.log(valid)
                if (valid) {
                    this.uploadFunModal()
                } else {
                    return false;
                }
            });
        }
    },
    uploadFunModal(){
      this.showLoading("请稍后...")
      var fd = new FormData();
      var nameA=this.fileA.name
      var nameB=this.fileB.name
      var nameC=this.fileC.name
      fd.append("file", this.fileA);
      fd.append("file", this.fileB);
      fd.append("file", this.fileC);

      this.$upload.post("Fix/UploadWorkFile?ID="+this.fileId+"&FILENAME1="+nameA+"&FILENAME2="+nameB+"&FILENAME3="+nameC, fd)
        .then((response) => {
          //console.log(response)
         this.hideLoading()
          if (response.data.SUCCESS) {
             this.hideLoading()
             this.$message.success("上传完成")
             this.fileModal=false
          }else{
             this.hideLoading()
             this.$message.error("上传失败")
          }
        })
        .catch((error) => {
            this.hideLoading()
        });
    },

    downPost(item){
        if(this.canDo){
            if(item.URL && item.URL!=""){
                window.open(item.URL)
            }else{
                this.$message.error("暂无工作票")
            }
            
        }
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style scoped>
.fileBox{overflow: hidden;margin: 20px 0;padding: 20px;background-color: #F8F8F9;}
.fileItem{border-radius: 3px;background-color: #FFFFFF;cursor: pointer;padding: 10px;float:left;margin-right: 20px;margin-bottom: 20px;width: 206px;}
.fileItem:nth-of-type(3n){margin-right: 0!important;}
.fileItem .name{font-size: 15px;font-weight: bold;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;color: #444;margin-bottom: 2px;}
.fileItem .keywords{font-size: 14px;font-weight: bold;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;margin-bottom: 5px;color: #909399;}
.fileItem .fileBtn{overflow: hidden;text-align: left;}
.fileItem span{margin-right: 10px;}
.noFile{text-align: center;font-size: 15px;color: #409EFF;}
.fileTitle{font-size: 16px;font-weight: bold;overflow: hidden;}

</style>