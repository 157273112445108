<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">合同管理</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                
                <el-input v-model="code" placeholder="请输入编号" class="iw" ></el-input>
                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-button type="success" @click="addPost" class="fr">添加</el-button>
                
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column prop="CODE" label="编号"></el-table-column>
                    <el-table-column prop="BZ" label="备注"></el-table-column>
                    <el-table-column prop="TOTALPRICE" label="合同总价"></el-table-column>
                    <el-table-column prop="DATE" label="合同日期"></el-table-column>
                    <el-table-column label="操作" width="150">
                        <template slot-scope="scope">
                            <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button>
                            <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
                            <el-button @click="filePost(scope.row)" type="text" size="small" style="color:#409EFF">附件</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="800px">
            <el-form :model="postInfo" status-icon :rules="postrules"  ref="uForm" label-width="100px" class="demo-uForm">
                <el-form-item label="编号" prop="CODE">
                    <el-input v-model="postInfo.CODE" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="备注">
                    <el-input v-model="postInfo.BZ" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="合同总价" prop="TOTALPRICE">
                    <el-input v-model="postInfo.TOTALPRICE" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="合同日期" prop="DATES">
                    <el-date-picker v-model="postInfo.DATES" type="date"  placeholder="选择日期" style="width:100%;"> </el-date-picker>
                </el-form-item>
            
                <el-form-item>
                    <el-button type="primary" @click="submitPostForm()">提交</el-button>
                    <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog :title="htFileStr" :visible.sync="chooseFileModal" width="800px"  :append-to-body="true">
            <p class="fileTitle">现有附件：</p>
            <div class="fileBox">
                <div class="fileItem" v-for="item in fileList" :key="item.SNOIWD" :title="item.FILENAME">
                    <p class="name" @click="downFile(item.PATH)">{{item.FILENAME}}</p>
                    <div class="fileBtn">
                        <span @click="downFile(item.PATH)" style="color:#409EFF">下载</span>
                        <span @click="delFile(item.SNOWID)" style="color:#F56C6C">删除</span>
                    </div>
                </div>
                <div v-if="fileList.length==0" class="noFile">该合同还未上传附件，请在下方上传。</div>
            </div>
            <p class="fileTitle" style="margin-bottom:20px;">上传附件：</p>
            <div style="overflow:hidden;">
                <el-upload ref="upload"  class=""  :multiple="true" :limit="5" action="" :http-request="uploadFun">
                    <el-button size="small" type="primary">点击上传</el-button>
                </el-upload>
            </div>
      </el-dialog>
    </div>
</template>
<script>
export default {
  name: "ContractManage",
  data() {
    var CODE = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入合同编号"));
      } else {
        callback();
      }
    };
    var TOTALPRICE=(rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入合同总价'));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('合同总价只能是数字'));
        }else if(value<=0){
            callback(new Error('合同总价必须大于0'));
        }else {
          callback();
        }
    };
    var DATES= (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择合同日期"));
      } else {
        callback();
      }
    };
    return {
        canDo:true,
        
        code:"",
       

        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,


        doTypeStr:"",
        doType:"",
        postModal:false,
        postInfo:{},
        postrules: {
            CODE: [{ validator: CODE, trigger: "blur" }],
            TOTALPRICE: [{ validator: TOTALPRICE, trigger: "blur" }],
            DATES: [{ validator: DATES, trigger: "blur" }],
        },


        htFileStr:"",
        checkHtId:"",
        fileList:[],
        chooseFileModal:false,
    };
  },
  mounted() {
    this.loadItem=null
    this.getPostList()
  },
  methods: {
    getPostList(){
        this.showLoading("请稍后")
        var params={
            PageIndex:this.PageIndex,
            PageSize:this.PageSize,
            ISPAGE:true,
            CODE:this.code,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Storage/GetContractList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
        });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    addPost(){
        this.postInfo={
            CODE:"",
            TOTALPRICE:"",
            DATES:"",
            BZ:"",
        }
        this.doTypeStr="添加合同"
        this.doType="1"
        this.postModal=true
    },
    sendAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        var params={
            CODE : this.postInfo.CODE,
            TOTALPRICE : this.postInfo.TOTALPRICE,
            DATE:this.comjs.getDateMinStrByDate(this.postInfo.DATES),
            BZ:this.postInfo.BZ,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Storage/CreateContract", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    editPost(item){
        this.postInfo={
            SNOWID:item.SNOWID,
            CODE:item.CODE,
            TOTALPRICE:item.TOTALPRICE,
            DATES:new Date(item.DATE),
            BZ:item.BZ,
        }
        this.doTypeStr="修改合同"
        this.doType="2"
        this.postModal=true
    },
    sendEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        var params={
            SNOWID:this.postInfo.SNOWID,
            CODE : this.postInfo.CODE,
            TOTALPRICE : this.postInfo.TOTALPRICE,
            DATE:this.comjs.getDateMinStrByDate(this.postInfo.DATES),
            BZ:this.postInfo.BZ,
        }
        var md5_data =this.comjs.requestDataEncrypt(params , this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Storage/EditContract", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.postModal=false
                    this.getPostList();
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    delPost(item){
        if(this.canDo){
            this.$confirm('确定删除合同：'+item.CODE+'？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
            this.sendDel(item.SNOWID)
            }).catch(() => {
            this.$message.info("已取消")    
          });
        }

    },
    sendDel(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({SNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:id})).toUpperCase())
        this.$http.post("Storage/DeleteContract", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    submitPostForm() {
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    if(this.doType=="1"){
                        this.sendAdd()
                    }else if(this.doType=="2"){
                        this.sendEdit()
                    }
                } else {
                    return false;
                }
            });
        }
    },
    filePost(item){
        this.htFileStr=item.CODE+"-附件列表"
        this.checkHtId=item.SNOWID
        this.getHtFiles()

        
    },
    uploadFun(f){
      this.showLoading("请稍后...")
      var fd = new FormData();
      fd.append("file", f.file);
      var fname=f.file.name
      this.$upload.post("Storage/UploadContractFile?CONTRACTSNOWID="+this.checkHtId+"&FILENAME="+fname, fd)
        .then((response) => {
          //console.log(response)
         this.hideLoading()
          if (response.data.SUCCESS) {
                this.hideLoading()
                this.$message.success("上传成功")
                this.$refs.upload.clearFiles()
                this.getHtFiles()
          }else{
             this.hideLoading()
             this.$message.error("上传失败")
          }
        })
        .catch((error) => {
         this.hideLoading()
      });
    },
    getHtFiles(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({SNOWID:this.checkHtId,ISPAGE:false}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:this.checkHtId,ISPAGE:false})).toUpperCase())
        this.$http.post("Storage/GetContractList", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                   this.fileList=response.data.DATA[0].FILELIST
                   this.chooseFileModal=true
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    downFile(e){
        window.open(e)
    },
    delFile(e){
        if(this.canDo){
            this.$confirm('确定删除附件？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
                this.sendDelFile(e)
            }).catch(() => {
            this.$message.info("已取消")    
          });
        }
    },
    sendDelFile(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({SNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:id})).toUpperCase())
        this.$http.post("Storage/DeleteContractFile", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.getHtFiles()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style scoped>
.fileBox{overflow: hidden;margin: 20px 0;padding: 20px;background-color: #F8F8F9;}
.fileItem{border-radius: 3px;background-color: #FFFFFF;cursor: pointer;padding: 10px;float:left;margin-right: 20px;margin-bottom: 20px;width: 206px;}
.fileItem:nth-of-type(3n){margin-right: 0!important;}
.fileItem .name{font-size: 15px;font-weight: bold;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;color: #444;margin-bottom: 2px;}
.fileItem .keywords{font-size: 14px;font-weight: bold;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;margin-bottom: 5px;color: #909399;}
.fileItem .fileBtn{overflow: hidden;text-align: left;}
.fileItem span{margin-right: 10px;}
.noFile{text-align: center;font-size: 15px;color: #409EFF;}
.fileTitle{font-size: 16px;font-weight: bold;overflow: hidden;}

</style>