<template>
    <div class="views-main">
        <div class="views-main-title">
            <el-button type="primary" icon="el-icon-arrow-left" style="float:left;margin-right:20px;" size="small" @click="backBBTZ">返回</el-button>
            <p class="txt">工作总结及工作计划</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                
                <el-input v-model="bz" placeholder="请输入说明" class="iw" ></el-input>
                <el-select v-model="type" placeholder="所属岗位"  filterable  clearable class="iwu">
                        <el-option  label="工作计划" value="1" ></el-option>
                        <el-option  label="工作总结" value="2" ></el-option>
                </el-select>
                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-button type="success" @click="addPost" class="fr">添加</el-button>
                
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column type="expand">
                        <template slot-scope="props">
                            <p class="tableText" v-for="item in props.row.PLANSUMMARYITEMLIST" :key="item.SNOWID">{{item.SORTINDEX+1}}：{{item.CONTENT}}</p>
                        </template>
                    </el-table-column>
                    <el-table-column prop="USERNAME" label="创建人"></el-table-column>
                    <el-table-column prop="TYPE" label="类型">
                        <template slot-scope="scope">
                            <span v-if="scope.row.TYPE==1">工作计划</span>
                            <span v-else>工作总结</span>
                        </template>
                    </el-table-column>

                    <el-table-column prop="PLANTIME" label="计划时间"></el-table-column>
                    <el-table-column prop="BZ" label="说明"></el-table-column>
                    <el-table-column prop="CREATETIME" label="创建时间"></el-table-column>
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                        <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button>
                        <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="800px">
            <el-form :model="postInfo" status-icon :rules="postrules"  ref="uForm" label-width="120px" class="demo-uForm">
                <el-form-item label="类型">
                    <el-radio v-model="postInfo.TYPE" label="1">工作计划</el-radio>
                    <el-radio v-model="postInfo.TYPE" label="2">工作总结</el-radio>
                </el-form-item>
                <el-form-item label="计划时间" prop="PLANTIME">
                    <el-date-picker v-model="postInfo.PLANTIME" type="datetime" placeholder="选择计划时间"></el-date-picker>
                </el-form-item>
                <el-form-item label="说明" prop="BZ">
                    <el-input v-model="postInfo.BZ" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="计划/总结内容" prop="PLANSUMMARYITEMLIST">
                     <el-button type="text" @click="appendPoolItem()" class="addFieldTtem">添加内容</el-button>
                     <div style="overflow:hidden;margin-top:60px;"  v-for="(pl, index) in postInfo.PLANSUMMARYITEMLIST" :key="pl.index" class="">
                            <div style="overflow:hidden;border-bottom:1px solid #ADADAD;">
                                <el-form-item label=""  :prop="'PLANSUMMARYITEMLIST.' + index + '.CONTENT'" :rules="{ required: true, message: '内容不能为空', trigger: 'blur'}"  class="allItems">
                                   <el-input  v-model="pl.CONTENT" autocomplete="off" placeholder="请输入计划/总结内容"></el-input>
                                </el-form-item>
                                <el-form-item label=""   class="allItems">
                                    <el-button type="text" style="color:#F56C6C;float:right;" class="mr10" @click="delPollItem(index)">删除</el-button>
                                    <el-button type="text" style="color:#409EFF;float:right;margin-left:0;" class="mr10" @click="prevPollItem(index)" v-if="index>0">上移</el-button>
                                    <el-button type="text" style="color:#E6A23C;float:right;" class="mr10" @click="topPollItem(index)" v-if="index>0">置顶</el-button>
                                </el-form-item>

                            </div>
                            
                        </div>        
                </el-form-item>   
                <el-form-item>
                    <el-button type="primary" @click="submitPostForm()">提交</el-button>
                    <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
export default {
  name: "PostManage",
  data() {
    var BZ = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入说明"));
      } else {
        callback();
      }
    };
    var PLANTIME = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择计划时间"));
      } else {
        callback();
      }
    };
    var PLANSUMMARYITEMLIST = (rule, value, callback) => {
      if (value.length<=0) {
        callback(new Error("请添加计划/总结内容"));
      } else {
        callback();
      }
    };
    return {
        canDo:true,
        
        bz:"",
        type:"",
       

        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,


        doTypeStr:"",
        doType:"",
        postModal:false,
        postInfo:{},
        postrules: {
            BZ: [{ validator: BZ, trigger: "blur" }],
            PLANTIME: [{ validator: PLANTIME, trigger: "blur" }],
            PLANSUMMARYITEMLIST: [{ validator: PLANSUMMARYITEMLIST, trigger: "blur" }],
        },
    };
  },
  mounted() {
    this.loadItem=null
    this.getPostList()
  },
  methods: {
    getPostList(){
        this.showLoading("请稍后")
        var params={
            PAGEINDEX:this.PageIndex,
            PAGESIZE:this.PageSize,
            BZ:this.bz,
            TYPE:this.type,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Plansummary/GetPlansummaryList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
        });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    addPost(){
        this.postInfo={
            USERID:this.$store.state.guid,
            USERNAME:this.$store.state.name,
            TYPE:"1",
            PLANTIME:"",
            BZ:"",
            PLANSUMMARYITEMLIST:[],
        }
        this.doTypeStr="添加工作总结/工作计划"
        this.doType="1"
        this.postModal=true
    },
    sendAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("Plansummary/CreateNewPlansummary", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    editPost(item){
        this.postInfo={
            SNOWID:item.SNOWID,
            USERID:item.USERID,
            USERNAME:item.USERNAME,
            TYPE:item.TYPE.toString(),
            BZ:item.BZ,
            PLANTIME:new Date(item.PLANTIME),
            PLANSUMMARYITEMLIST:item.PLANSUMMARYITEMLIST,
        }
        this.doTypeStr="修改工作总结/工作计划"
        this.doType="2"
        this.postModal=true
    },
    sendEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("Plansummary/EditPlansummary", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.postModal=false
                    this.getPostList();
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    delPost(item){
        if(this.canDo){
            this.$confirm('确定删除？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
            this.sendDel(item.SNOWID)
            }).catch(() => {
            this.$message.info("已取消")    
          });
        }

    },
    sendDel(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({SNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:id})).toUpperCase())
        this.$http.post("Plansummary/DeletePlansummary", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    submitPostForm() {
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    var l=this.postInfo.PLANSUMMARYITEMLIST
                    var nl=[]
                    for(var i=0;i<l.length;i++){
                        nl.push({
                            SORTINDEX:i,
                            CONTENT:l[i].CONTENT
                        })
                    }
                    this.postInfo.PLANSUMMARYITEMLIST = nl
                    var  pt=this.comjs.getDateStrByDate(this.postInfo.PLANTIME)
                    this.postInfo.PLANTIME = pt
                    if(this.doType=="1"){
                        this.sendAdd()
                    }else if(this.doType=="2"){
                        this.sendEdit()
                    }
                } else {
                    return false;
                }
            });
        }
    },
    backBBTZ(){
        this.$router.replace('/XTPTZH')
    },
    
    appendPoolItem(){
        this.postInfo.PLANSUMMARYITEMLIST.push({CONTENT:""})
    },

    delPollItem(index){
        if(this.canDo){
            this.$confirm('确定删除内容？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
                var list=this.postInfo.PLANSUMMARYITEMLIST
                var nl=[]
                for(var i=0;i<list.length;i++){
                    if(i!=index){
                        nl.push(list[i])
                    }
                }
                this.postInfo.PLANSUMMARYITEMLIST = nl
            }).catch(() => {
                this.$message.info("已取消")    
            });
        }
    },
    prevPollItem(index){
        if(index>0){
            var list=this.postInfo.PLANSUMMARYITEMLIST
            var nl=[]
            var thisItem= list[index]
            var prevItem= list[index-1]
            for(var i=0;i<list.length;i++){
                if(i!=index && i!= index-1){
                    nl.push(list[i])
                }else{
                   if(i==index-1){
                        nl.push(thisItem)
                   }else{
                        nl.push(prevItem)
                   }
                }
            }
            this.postInfo.PLANSUMMARYITEMLIST = nl
        }
    },
    topPollItem(index){
        if(index>0){
            var list=this.postInfo.PLANSUMMARYITEMLIST
            var nl=[]
            var top=list[index]
            nl.push(top)
            for(var i=0;i<list.length;i++){
                if(i!=index){
                    nl.push(list[i])
                }
            }
            this.postInfo.PLANSUMMARYITEMLIST = nl
        }
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style scope>
    .allItems .el-form-item__label{width:0px!important;}
    .allItems{width: 100%;float:left;}
    .tableTitle{font-size: 16px;}
    .tableText{font-size: 14px;}
</style>