<template>
  <div class="views-main">
    <div class="views-main-title">
      <p class="txt">资料分类</p>
    </div>
    <div class="tableBox" style="top:50px;">
      <div class="views-main-search">
        <el-input placeholder="输入关键字进行过滤" v-model="filterText" style="width:200px;"> </el-input>
        <el-button type="primary" @click="sendQuery" class="ml10">查询</el-button>
        <el-button type="success" @click="addDep" class="ml10">添加分类</el-button>
        <el-button type="warning" @click="showModelType" class="fr">模块分类管理</el-button>
      </div>
      <div class="views-main-body">
          <el-tree :props="props" :load="getList" lazy  ref="tree" node-key="guid"  :filter-node-method="filterNode" draggable @node-drop="handleDrop">
            <span class="custom-tree-node" slot-scope="{ node, data }">
              <span class="treeName">{{ node.label }}</span>
              <span class="managerName">({{ data.tname }})</span>
              <span>
                <el-button type="text" size="mini" @click.stop="() => append(node, data)" style="color:#19be6b">
                  添加
                </el-button>
                <el-button type="text" size="mini" @click.stop="() => edit(node, data)" style="color:#ff9900">
                  编辑
                </el-button>
                <el-button type="text" size="mini" @click.stop="() => remove(node, data)" style="color:#ed4014">
                  删除
                </el-button>
              </span>
            </span>
          </el-tree>
      </div>
    </div>
    <el-dialog :title="DepModalTitle" :visible.sync="DepModal" width="500px">
      <el-form :model="DepInfo" status-icon :rules="DepRules"  ref="DepForm" label-width="110px" class="demo-DepForm" >
        <el-form-item label="资料分类名称" prop="NAME">
          <el-input v-model="DepInfo.NAME" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="模块分类">
            <el-select v-model="DepInfo.BLOCKTYPEID" placeholder="请选择模块分类" filterable  style="width:350px;" @change="selBlock">
                    <el-option v-for="item in flList" :key="item.SNOWID" :label="item.BLOCKNAME" :value="item.SNOWID" ></el-option>
            </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitDepForm()">提交</el-button>
          <el-button @click="closeDepForm()">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog title="模块分类管理" :visible.sync="typeModal" width="1050px" top="50px">
            <div style="width:100%;overflow:hidden;margin-bottom:20px;">
                <el-input v-model="typename" placeholder="请输入名称" class="ics" ></el-input>
                <el-button type="primary" @click="searchType">查询</el-button>
                <el-button type="success" @click="addType" class="fr">添加分类</el-button>
            </div>
            <el-table :data="typeList" stripe style="width: 100%"  :height="dialogMaxHeigh"> 
                <el-table-column prop="BLOCKNAME" label="名称"></el-table-column>
                <el-table-column prop="CREATETIME" label="创建日期"></el-table-column>
                <el-table-column label="操作" width="100">
                    <template slot-scope="scope">
                    <el-button @click="editType(scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button>
                    <el-button @click="delType(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination background layout="prev, pager, next"  :current-page="PageIndexType" :page-size="PageSizeType" :total="TotalType" class="pg pgItem" @current-change="PGChangeType"></el-pagination>
    </el-dialog>
    <el-dialog :title="doTypeStrT" :visible.sync="postModalT" width="500px">
        <el-form :model="postInfoT" status-icon :rules="postrulesT"  ref="uFormT" label-width="100px" class="demo-uForm">
            <el-form-item label="名称" prop="BLOCKNAME">
            <el-input v-model="postInfoT.BLOCKNAME" autocomplete="off"></el-input>
            </el-form-item>
        
            <el-form-item>
            <el-button type="primary" @click="submitPostFormT()">提交</el-button>
            <el-button @click="postModalT = false">取消</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
  </div>
</template>
<script>
var h=document.body.clientHeight
export default {
  name: "ZLFL",
  data() {
    var NAME = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入部门名称"));
      } else {
        callback();
      }
    };
    return {
      //资料库模块分类
      dialogMaxHeigh:0,
      typeModal:false,

      typename:"",
      typeList:[],
      PageIndexType:1,
      PageSizeType:10,
      TotalType:0,

      doTypeStrT:"",
        doType:"",
        postModalT:false,
        postInfoT:{
            BLOCKNAME:"",
        },
        postrulesT: {
            BLOCKNAME: [{ validator: NAME, trigger: "blur" }],
        },

      flList:[],
      //资料库模块


      props: {
          label: 'name',
          children: 'zones',
          isLeaf: 'leaf'
      },
      filterText:"",

      DepModalType:"",
      DepModalTitle:"",

      checkTName:"",
      DepParentID:"",
      DepParentNAME:"",   

      DepModal:false,
      DepInfo:{
        SNOWID:"",
        PARENTORGANIZATIONGUID:"",
        NAME:"",
        ISENABLE:"",
        BZ:"",
        MANAGERID:"",
        MANAGERNAME:"",
      },
      DepRules: {
        NAME: [{ validator: NAME, trigger: "blur" }],
      },
      DepEditItem:null,
      canDo:true,
      auditText:"",
    };
  },
  mounted() {
    this.dialogMaxHeigh = h -300
    this.loadItem=null
    this.getFLArray()
  },
  methods: {

    /**
     * 模块分类
     */
    getFLArray(){
        var params={
            PAGEINDEX:1,
            PAGESIZE:500,
            BLOCKNAME:"",
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Doc/GetDocBlocktypeList", md5_data)
            .then((response) => {
            if (response.data.SUCCESS) {
                this.flList=response.data.DATA
            } 
            
        })
    },
    showModelType(){
        this.typeModal = true
        this.getTypeList()
    },
    getTypeList(){
        this.showLoading("请稍后")
        var params={
            PAGEINDEX:this.PageIndexType,
            PAGESIZE:this.PageSizeType,
            BLOCKNAME:this.typename,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Doc/GetDocBlocktypeList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.typeList=response.data.DATA
                this.TotalType=response.data.TOTALCOUNT
            } else {
                this.typeList=[]
                this.TotalType=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.typeList=[]
                this.TotalType=0
        });
    },
    searchType(){
        this.PageIndexType=1
        this.getTypeList()
    },
    PGChangeType(e){
        this.PageIndexType=e
        this.getTypeList()
    },
    addType(){
        this.postInfoT={
            BLOCKNAME:"",
        }
        this.doTypeStrT="添加模块分类"
        this.doType="1"
        this.postModalT=true
    },
    sendAddType(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfoT, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfoT)).toUpperCase())
        this.$http.post("Doc/CreateNewDocBlocktype", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.postModalT=false
                    this.getTypeList()
                    this.getFLArray()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    editType(item){
        this.postInfoT={
            SNOWID:item.SNOWID,
            BLOCKNAME:item.BLOCKNAME,
        }
        this.doTypeStrT="修改模块分类"
        this.doType="2"
        this.postModalT=true
    },
    sendEditType(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfoT, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfoT)).toUpperCase())
        this.$http.post("Doc/EditDocBlocktype", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.postModalT=false
                    this.getTypeList()
                    this.getFLArray()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    delType(item){
        if(this.canDo){
            this.$confirm('确定删除模块分类：'+item.BLOCKNAME+'？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
            this.sendDelType(item.SNOWID)
            })
        }

    },
    sendDelType(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({SNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:id})).toUpperCase())
        this.$http.post("Doc/DeleteDocBlocktype", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.postModalT=false
                    this.getTypeList()
                    this.getFLArray()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    submitPostFormT() {
        if (this.canDo) {
            this.$refs.uFormT.validate((valid) => {
                if (valid) {
                  if(this.doType=="1"){
                      this.sendAddType()
                  }else if(this.doType=="2"){
                      this.sendEditType()
                  }
                } else {
                    return false;
                }
            });
        }
    },



    /*模块 */
    selBlock(e){
      if(e==""){
        this.checkTName=""
      }else{
        for(var i=0;i<this.flList.length;i++){
          if(this.flList[i].SNOWID == e){
            this.checkTName =this.flList[i].BLOCKNAME
            break;
          }
        }
      }
    },
    filterNode(value, data) {
        if (!value) return true;
        return data.name.indexOf(value) !== -1;
    },
    getList(node, resolve){
        var guid="0"
        var hasChild=true
        if(node.data != undefined){
            guid = node.data.guid
        }
        if(hasChild){
          setTimeout(()=>{
            var md5_data =this.comjs.requestDataEncrypt({PARENTID:guid}, this.$store.state.token,this.$md5(this.comjs.sortAsc({PARENTID:guid})).toUpperCase())
            this.$http.post("Doc/GetDoctypeList",md5_data)
            .then((response) => {
              if(response.data.SUCCESS){   
                  var l=response.data.DATA
                  var d=[]
                  for(var i=0;i<l.length;i++){
                    var pname="0"
                    if(l[i].PARENTNAME){
                      pname=l[i].PARENTNAME
                    }
                    d.push({
                        guid:l[i].SNOWID,
                        name: l[i].NAME,
                        pid:l[i].PARENTID,
                        pname:pname,
                        tid:l[i].BLOCKTYPEID,
                        tname:l[i].BLOCKTYPENAME,
                        leaf: false,
                    })
                    
                  }
                  resolve(d);
              }else{
                this.errorRequest(response.data.DATA,response.data.MSG,false)
              }
            })
          },100)
            
        }

    },
    sendQuery(){
      var val=this.filterText
      this.$refs.tree.filter(val)
    },
    closeDepForm(){
      this.DepModal=false
    },
    submitDepForm(){
      if (this.canDo) {
          this.$refs.DepForm.validate((valid) => {
          if (valid) {
              if(this.DepModalType=="1"){
                this.appendDep()
              }else if(this.DepModalType=="2"){
                this.editDep()  
              }
          } else {
            return false;
          }
        });
      }
    },
    appendDep(){
      this.canDo=false
      this.showLoading("请稍后")
      var md5_data =this.comjs.requestDataEncrypt(this.DepInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.DepInfo)).toUpperCase())
      this.$http.post("Doc/CreateNewDoctype", md5_data)
        .then((response) => {
          //console.log(response)
          this.hideLoading()
          this.canDo=true
          this.DepModal=false
          if(response.data.SUCCESS){   

            var data={
                name: this.DepInfo.NAME,
                guid:response.data.DATA,
                pid:this.DepParentID,
                pname:this.DepParentNAME,
                tid:this.DepInfo.BLOCKTYPEID,
                tname:this.checkTName,
                leaf: false,
            }
            var  pid=null
            if(this.DepParentID!="0"){
              pid=this.DepParentID
            }
            this.$refs.tree.append(data,pid) 
            this.$message.success("添加成功")
          }else{
            this.errorRequest(response.data.DATA,response.data.MSG,true)
          }
        })
        .catch((error) => {
          this.$message.error("请求出错")
          this.hideLoading()
          this.canDo=true
      });
    },
    editDep(){
      this.canDo=false
      this.showLoading("请稍后")
      var md5_data =this.comjs.requestDataEncrypt(this.DepInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.DepInfo)).toUpperCase())
      this.$http.post("Doc/EditDoctype", md5_data)
        .then((response) => {
          //console.log(response)
          this.hideLoading()
          this.canDo=true
          this.DepModal=false
          if(response.data.SUCCESS){   
            this.$set(this.DepEditItem,'name',this.DepInfo.NAME)
            this.$set(this.DepEditItem,'tid',this.DepInfo.BLOCKTYPEID)
            this.$set(this.DepEditItem,'tname',this.checkTName)
            this.$set(this.DepEditItem,'pid',this.DepParentID)
            this.$set(this.DepEditItem,'pname',this.DepParentNAME)
            this.$message.success("编辑成功")
          }else{
            this.errorRequest(response.data.DATA,response.data.MSG,true)
          }
        })
        .catch((error) => {
          this.$message.error("请求出错")
          this.hideLoading()
          this.canDo=true
      });
    },
    addDep(){
      this.DepInfo={
        NAME:"",
        PARENTID:"0",
        BLOCKTYPEID:"",
      },
      this.DepModalType="1"
      this.DepParentID="0"
      this.DepParentNAME="0"
      this.DepModalTitle="添加资料分类"
      this.DepModal=true
    },
    append(node, data){
      this.DepInfo={
        NAME:"",
        PARENTID:data.guid,
        BLOCKTYPEID:""
      },
      this.DepModalType="1"
      this.DepParentID=data.guid
      this.DepParentNAME=data.name
      this.DepModalTitle="添加资料分类"
      this.DepModal=true
    },
    edit(node, data){
       this.DepInfo={
        SNOWID:data.guid,
        NAME:data.name,
        PARENTID:data.pid,
        BLOCKTYPEID:data.tid,
      },
      this.selBlock(data.tid)
      this.DepEditItem=data
      this.DepModalType="2"
      this.DepParentID=data.pid
      this.DepParentNAME=data.pname
      this.DepModalTitle="编辑资料分类"
      this.DepModal=true

    },
    remove(node, data){
      if(this.canDo){
        this.$confirm('确定删除'+data.name+'及其全部的子分类？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.delDep(data)
          }).catch(() => {
            this.$message.info("已取消")    
          });
      }
    },
    delDep(data){
      this.canDo=false
      this.showLoading("请稍后")
      var md5_data =this.comjs.requestDataEncrypt({SNOWID:data.guid}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:data.guid})).toUpperCase())
      this.$http.post("Doc/DeleteDoctype", md5_data)
        .then((response) => {
          //console.log(response)
          this.hideLoading()
          this.canDo=true
          if(response.data.SUCCESS){   
              this.$refs.tree.remove(data)
              this.$message.success("删除成功")
          }else{
            this.errorRequest(response.data.DATA,response.data.MSG,true)
          }
        })
        .catch((error) => {
          this.$message.error("请求出错")
          this.hideLoading()
          this.canDo=true
      });
    },
    handleDrop(draggingNode, dropNode, dropType, ev) {
        this.DepEditItem=draggingNode.data
        this.DepInfo={
            SNOWID:draggingNode.data.guid,
            NAME:draggingNode.data.name,
            PARENTID:null,
            BLOCKTYPEID:draggingNode.data.tid,
        }
        this.checkTName = draggingNode.data.tname

        if(dropType=="inner"){
            this.DepInfo.PARENTID = dropNode.data.guid
            this.DepParentID = dropNode.data.guid
            this.DepParentNAME=dropNode.data.name
        }else{
            this.DepInfo.PARENTID = "0"
            this.DepParentID ="0"
            this.DepParentNAME="0"
        }
        if(this.canDo){
            this.editDep()
        }
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style >
  .el-tree-node{margin-top: 20px;}
  .treeName{font-size: 16px;margin-right: 5px;}

  .funTreeRow{width: 500px;}
  .funtreeName{font-size: 15px;margin-right: 30px;}
  .funItemList{float:right;}
  .managerName{font-size: 12px;margin-right: 20px;color: #409EFF;}
  .custom-tree-node{display: block;}
  .el-checkbox{margin-right: 15px!important;}
  .el-checkbox__label{padding-left: 5px!important;}
</style>
