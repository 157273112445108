<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">历次检查问题记录</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
               <el-input  placeholder="请输入标题" class="iw" ></el-input>
                <el-button type="primary">查询</el-button>
                <el-button type="success" class="fr">添加</el-button>
                
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column prop="a" label="标题"></el-table-column>
                    <el-table-column prop="b" label="范围"></el-table-column>
                    <el-table-column prop="c" label="检查内容"></el-table-column>
                    <el-table-column prop="d" label="检查时间"></el-table-column>
                    <el-table-column prop="e" label="检查单位"></el-table-column>
                    <el-table-column prop="f" label="检查人"></el-table-column>
                    <el-table-column prop="g" label="问题描述"></el-table-column>
                    <el-table-column prop="h" label="问题原因"></el-table-column>
                    <el-table-column prop="i" label="解决方案"></el-table-column>
                    <el-table-column prop="j" label="解决单位"></el-table-column>
                    <el-table-column prop="k" label="解决人"></el-table-column>
                    <el-table-column prop="l" label="解决时间"></el-table-column>
                    <el-table-column prop="m" label="领导意见"></el-table-column>
                    <el-table-column prop="n" label="备注"></el-table-column>
                    <el-table-column label="操作" width="160">
                        <template slot-scope="scope">
                            <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button>
                            <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
                            <el-button @click="file(scope.row)" type="text" size="small" style="color:#409EFF">附件</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" ></el-pagination>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  name: "",
  data() {
    return {
        canDo:true,
        
        name:"",
        postList:[
            {
                a:"标题...",
                b:"系统内",
                c:"检查内容...",
                d:"2022-07-07",
                e:"检查单位...",
                f:"检查人...",
                g:"问题描述...",
                h:"问题原因...",
                i:"解决方案...",
                j:"解决单位...",
                k:"解决人...",
                l:"2022-07-07",
                m:"领导意见...",
                n:"备注...",
            },
        ],
        PageIndex:1,
        PageSize:10,
        Total:3,
    };
  },
  mounted() {
    this.loadItem=null
  },
  methods: {
   editPost(e){
       //
   },
   delPost(e){
        //
   },
   file(e){
       //
   },
   log(e){
       //
   }
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style >
</style>