<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">定检模板</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                
                <el-select v-model="fc" placeholder="风场"  filterable  clearable class="iwu">
                        <el-option v-for="item in factoryList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                </el-select>
                <el-input v-model="xh" placeholder="请输入项号" class="iwu"  ></el-input>
                <el-input v-model="xm" placeholder="请输入项名" class="iwu"  ></el-input>
                <el-input v-model="djh" placeholder="请输入定检号" class="iwu"  ></el-input>
                <el-input v-model="djm" placeholder="请输入定检名" class="iwu"  ></el-input>
                <el-input v-model="mkh" placeholder="请输入模块号" class="iwu"  ></el-input>
                <el-input v-model="mkm" placeholder="请输入模块名" class="iwu"  ></el-input>
                <el-date-picker v-model="sj" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>


                <el-button type="primary" @click="searchPost" style="margin-left:20px">查询</el-button>
                <el-button type="success" @click="addPost" class="fr">添加</el-button>
                
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column prop="FACTORYNAME" label="风场"></el-table-column>
                    <el-table-column prop="BLOCKNUM" label="模块号"></el-table-column>
                    <el-table-column prop="BLOCKNAME" label="模块名"></el-table-column>
                    <el-table-column prop="ITEMNUM" label="项号"></el-table-column>
                    <el-table-column prop="ITEMNAME" label="项名"></el-table-column>
                    <el-table-column prop="INSPECTIONNUM" label="定检号"></el-table-column>
                    <el-table-column prop="INSPECTIONNAME" label="定检名"></el-table-column>
                    
                    <el-table-column prop="CONTENT" label="内容"></el-table-column>
                    <el-table-column prop="CREATETIME" label="创建日期"></el-table-column>
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                        <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button>
                        <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="500px">
            <el-form :model="postInfo" status-icon :rules="postrules"  ref="uForm" label-width="100px" class="demo-uForm">
                <el-form-item label="风场" prop="FACTORYID">
                    <el-select v-model="postInfo.FACTORYID" placeholder="请选择风场" filterable  style="width:100%;" @change="factoryChange">
                            <el-option v-for="item in factoryList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="模块号" prop="BLOCKNUM">
                    <el-input v-model="postInfo.BLOCKNUM" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="模块名" prop="BLOCKNAME">
                    <el-input v-model="postInfo.BLOCKNAME" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="项号" prop="ITEMNUM">
                    <el-input v-model="postInfo.ITEMNUM" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="项名" prop="ITEMNAME">
                    <el-input v-model="postInfo.ITEMNAME" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="定检号" prop="INSPECTIONNUM">
                    <el-input v-model="postInfo.INSPECTIONNUM" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="定检名" prop="INSPECTIONNAME">
                    <el-input v-model="postInfo.INSPECTIONNAME" autocomplete="off"></el-input>
                </el-form-item>
                
                <el-form-item label="内容" prop="CONTENT">
                    <el-input v-model="postInfo.CONTENT" autocomplete="off"  type="textarea" class="gwzz"></el-input>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" @click="submitPostForm()">提交</el-button>
                    <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
export default {
  name: "InspectionTemplate",
  data() {
    var FACTORYID = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择风场"));
      } else {
        callback();
      }
    };
    var ITEMNUM=(rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入项号'));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('项号只能是数字'));
        }else if(!/^\+?(0|[1-9][0-9]*)$/.test(value)){
            callback(new Error('只能输入大于等于0的整数'));
        }else {
          callback();
        }
    };
    var INSPECTIONNUM=(rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入定检号'));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('定检号只能是数字'));
        }else if(!/^\+?(0|[1-9][0-9]*)$/.test(value)){
            callback(new Error('只能输入大于等于0的整数'));
        }else {
          callback();
        }
    };
    var BLOCKNUM=(rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入模块号'));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('模块号只能是数字'));
        }else if(!/^\+?(0|[1-9][0-9]*)$/.test(value)){
            callback(new Error('只能输入大于等于0的整数'));
        }else {
          callback();
        }
    };


    var ITEMNAME= (rule, value, callback) => {
      if (value === '' || value == null) {
        callback(new Error('项名不能为空'));
      } else {
        callback();
      }
    };
    var INSPECTIONNAME= (rule, value, callback) => {
      if (value === '' || value == null) {
        callback(new Error('定检名不能为空'));
      } else {
        callback();
      }
    };
    var BLOCKNAME= (rule, value, callback) => {
      if (value === '' || value == null) {
        callback(new Error('模块名不能为空'));
      } else {
        callback();
      }
    };

    var CONTENT= (rule, value, callback) => {
      if (value === '' || value == null) {
        callback(new Error('内容名不能为空'));
      } else {
        callback();
      }
    };
    return {
        canDo:true,
        factoryList:[],

        fc:"",
        xm:"",
        xh:"",
        djm:"",
        djh:"",
        mkm:"",
        mkh:"",
        sj:"",


        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,


        doTypeStr:"",
        doType:"",
        postModal:false,
        postInfo:{},
        postrules: {
            FACTORYID: [{ validator: FACTORYID, trigger: "blur" }],
            ITEMNUM: [{ validator: ITEMNUM, trigger: "blur" }],
            INSPECTIONNUM: [{ validator: INSPECTIONNUM, trigger: "blur" }],
            BLOCKNUM: [{ validator: BLOCKNUM, trigger: "blur" }],
            ITEMNAME: [{ validator: ITEMNAME, trigger: "blur" }],
            INSPECTIONNAME: [{ validator: INSPECTIONNAME, trigger: "blur" }],
            BLOCKNAME: [{ validator: BLOCKNAME, trigger: "blur" }],
            CONTENT: [{ validator: CONTENT, trigger: "blur" }],
        },
    };
  },
  mounted() {
    this.loadItem=null
    this.getFactoryList()
    this.getPostList()
  },
  methods: {
    factoryChange(e){
        if(e!=""){
            for(var i=0;i<this.factoryList.length;i++){
                if(this.factoryList[i].SNOWID == e){
                    this.postInfo.FACTORYNAME = this.factoryList[i].NAME
                    break;
                }
            }
        }else{
            this.postInfo.FACTORYNAME=""
        }
    },
    getFactoryList(){
      var md5_data = this.comjs.requestDataEncrypt({}, this.$store.state.token, this.$md5(this.comjs.sortAsc({})).toUpperCase())
      this.$http.post("Factory/GetFactoryWithoutPage", md5_data)
          .then((response) => {
            if (response.data.SUCCESS) {
              this.factoryList = response.data.DATA
            } 
          })
    },
    getPostList(){
        this.showLoading("请稍后")
        var st=""
        var et=""
        if(this.sj.length==2){
            st=this.comjs.getDateStrByDate(this.sj[0])
            et=this.comjs.getDateStrByDate(this.sj[1])
        }
        var params={
            PAGEINDEX:this.PageIndex,
            PAGESIZE:this.PageSize,
            BLOCKNAME:this.mkm,
            BLOCKNUM:this.mkh,
            INSPECTIONNAME:this.djm,
            INSPECTIONNUM:this.djh,
            ITEMNAME:this.xm,
            ITEMNUM:this.xh,
            FACTORYID:this.fc,
            STARTTIME:st,
            ENDTIME:et,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("InspectionTemplate/GetInspectionTemplate", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
        });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    addPost(){
        this.postInfo={
            BLOCKNUM:"",
            ITEMNUM:"",
            CONTENT:"",
            INSPECTIONNAME:"",
            INSPECTIONNUM:"",
            BLOCKNAME:"",
            ITEMNAME:"",
            FACTORYID:"",
            FACTORYNAME:"",
        }
        this.doTypeStr="添加定检模板"
        this.doType="1"
        this.postModal=true
    },
    sendAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("InspectionTemplate/CreateNewInspectionTemplate", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    editPost(item){
        this.postInfo={
            SNOWID:item.SNOWID,
            BLOCKNUM:item.BLOCKNUM,
            ITEMNUM:item.ITEMNUM,
            CONTENT:item.CONTENT,
            INSPECTIONNAME:item.INSPECTIONNAME,
            INSPECTIONNUM:item.INSPECTIONNUM,
            BLOCKNAME:item.BLOCKNAME,
            ITEMNAME:item.ITEMNAME,
            FACTORYID:item.FACTORYID,
            FACTORYNAME:item.FACTORYNAME,
            ISDELETE:item.ISDELETE,
            CREATETIME:item.CREATETIME,
            DELETETIME:item.DELETETIME,
        }
        this.doTypeStr="修改定检模板"
        this.doType="2"
        this.postModal=true
    },
    sendEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("InspectionTemplate/EditInspectionTemplate", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.postModal=false
                    this.getPostList();
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    delPost(item){
        if(this.canDo){
            this.$confirm('确定删除定检模板？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
            this.sendDel(item.SNOWID)
            }).catch(() => {
            this.$message.info("已取消")    
          });
        }

    },
    sendDel(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({SNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:id})).toUpperCase())
        this.$http.post("InspectionTemplate/DeleteInspectionTemplate", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    submitPostForm() {
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    if(this.doType=="1"){
                        this.sendAdd()
                    }else if(this.doType=="2"){
                        this.sendEdit()
                    }
                } else {
                    return false;
                }
            });
        }
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style >
 .gwzz .el-textarea__inner{height:100px!important;}
</style>