<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">预试地调管理</p>
        </div>
        <div class="tableBox" style="top:50px;">
                <div class="ksItem" @click="tdjh">
                    葫地调下一年预试停电计划
                </div>
                <div class="ksItem" @click="tdsq">
                   报地调预试停电申请
                </div>
                <div style="width:100%;float:left;height:1px;"></div>
                <div class="ksItem" >
                    年度预试
                </div>
                <div class="ksItem" >
                  每年预试时校对风场和国网对端双方计量表。（停电或日常）
                </div>
                <div style="width:100%;float:left;height:1px;"></div>
        </div>
    </div>
</template>
<script>
export default {
  name: "KSFL",
  data() {

    return {
        canDo:true, 
    };
  },
  mounted() {
    this.loadItem=null
  },
  methods: {
    //停电计划
    tdjh(){
      this.$router.replace('/TDJH')
    },
    //地调停电申请
    tdsq(){
      this.showLoading("请稍后")
      var params={
            PAGEINDEX:1,
            PAGESIZE:1,
            NAME:"",
            ALIAS:"STOPELECPOWER",
            VISIBILITY:true,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Model/GetModelList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS && response.data.DATA.length>0) {
                var d=response.data.DATA[0]
                window.open("/statics/pages/LCQC.html?mid="+d.SNOWID+"&token="+this.$store.state.token)
            } else {
               this.$message.error("请先配置停电作业申请流程")
            }
            })
            .catch((error) => {
              this.$message.error("请求失败，请稍后再试")
              this.hideLoading()
               
        });
    }
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style scoped>
    .el-collapse-item__header{padding: 0 20px;font-size: 16px;}
    .el-collapse .is-active{color: #409EFF;}
    .el-collapse-item__content{padding-left:20px;}

    .ksItem{width: 600px;height: 80px;border-radius: 3px;cursor: pointer;color: #FFFFFF;background-color: #409EFF;font-size: 16px;margin-left: 30px;margin-top: 30px;float:left;display: -webkit-box;display: -ms-flexbox;display: -webkit-flex;display: flex;-webkit-box-pack: center;-ms-flex-pack: center;-webkit-justify-content: center;justify-content: center;-webkit-box-align: center;-ms-flex-align: center;-webkit-align-items: center;align-items: center;padding:0 30px;}


    .ksItem:hover{background-color: #5FADFC;}

</style>