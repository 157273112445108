<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">设备故障管理</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                
                <el-input v-model="code" placeholder="请输入编码" class="iw" ></el-input>
                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-button type="primary" @click="downloadTemplet" class="fr">下载模板</el-button>
                <el-button type="warning" @click="uploadTemplet"  class="fr">导入模板</el-button>
                <el-button type="success" @click="addPost" class="fr">添加</el-button>
                
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column prop="CODE" label="编码/编号"></el-table-column>
                    <el-table-column prop="ID" label="ID/监控ID"></el-table-column>
                    <el-table-column prop="NAME" label="名称"></el-table-column>
                    <el-table-column prop="SYSTEM" label="子系统名称"></el-table-column>
                    <el-table-column prop="COMFIRM" label="确认"></el-table-column>
                    <el-table-column prop="TYPE" label="类别"></el-table-column>
                    <el-table-column prop="CLOSETYPE" label="关机类型"></el-table-column>
                    <el-table-column prop="CATEGORY" label="类型"></el-table-column>
                    <el-table-column prop="CONTEXT" label="文本/日志文字"></el-table-column>
                    <el-table-column prop="TIMEOUT" label="超时"></el-table-column>
                    <el-table-column prop="ALLOWTIMES" label="允许的尝试次数"></el-table-column>
                    <el-table-column prop="MAXDISCONNECTTIME" label="最长断开时间"></el-table-column>
                    <el-table-column prop="TIMEWINDOW" label="时间窗口"></el-table-column>
                    <el-table-column prop="MAXREMOVETIME" label="最长消除时间"></el-table-column>
                    <el-table-column prop="STABLEPERIOD" label="稳定期"></el-table-column>
                    <el-table-column prop="CONDITION" label="条件"></el-table-column>
                    <el-table-column label="操作" width="160">
                        <template slot-scope="scope">
                            <el-button @click="faPost(scope.row)" type="text" size="small" style="color:#409EFF">解决方案</el-button>
                            <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button>
                            <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="800px" top="30px">
            <el-form :model="postInfo" status-icon :rules="postrules"  ref="uForm" label-width="120px" class="demo-uForm">
                <el-form-item label="编码/编号" prop="CODE">
                     <el-input v-model="postInfo.CODE" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="名称" prop="NAME">
                     <el-input v-model="postInfo.NAME" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="ID/监控ID" prop="ID">
                     <el-input v-model="postInfo.ID" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="子系统名称" prop="SYSTEM">
                     <el-input v-model="postInfo.SYSTEM" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="确认" prop="COMFIRM">
                     <el-input v-model="postInfo.COMFIRM" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="类别" prop="TYPE">
                     <el-input v-model="postInfo.TYPE" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="关机类型" prop="CLOSETYPE">
                     <el-input v-model="postInfo.CLOSETYPE" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="类型" prop="CATEGORY">
                     <el-input v-model="postInfo.CATEGORY" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="文本/日志文字" prop="CONTEXT">
                     <el-input v-model="postInfo.CONTEXT" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="超时" prop="TIMEOUT">
                     <el-input v-model="postInfo.TIMEOUT" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="允许的尝试次数" prop="ALLOWTIMES">
                     <el-input v-model="postInfo.ALLOWTIMES" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="最长断开时间" prop="MAXDISCONNECTTIME">
                     <el-input v-model="postInfo.MAXDISCONNECTTIME" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="时间窗口" prop="TIMEWINDOW">
                     <el-input v-model="postInfo.TIMEWINDOW" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="最长消除时间" prop="MAXREMOVETIME">
                     <el-input v-model="postInfo.MAXREMOVETIME" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="稳定期" prop="STABLEPERIOD">
                     <el-input v-model="postInfo.STABLEPERIOD" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="条件" prop="CONDITION">
                     <el-input type="textarea"  class="tjarea" v-model="postInfo.CONDITION" autocomplete="off"></el-input>
                </el-form-item>            
                <el-form-item>
                <el-button type="primary" @click="submitPostForm()">提交</el-button>
                <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog :title="faTitle" :visible.sync="faModal" width="800px" top="50px" >
                <div style="width:100%;overflow:hidden;margin-bottom:20px;">
                    <el-button type="success" @click="addFAItem()" class="">添加解决方案</el-button>
                </div>
                <el-table :data="faList" stripe style="width: 100%" :height="dialogMaxHeigh">
                    <el-table-column prop="REMARK" label="解决方案描述"></el-table-column>
                    <el-table-column label="操作" width="120">
                        <template slot-scope="scope">
                            <el-button @click="editFAPost(scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button>
                            <el-button @click="delFAPost(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
                            <el-button @click="fileFAPost(scope.row)"  type="text" size="small" style="color:#409EFF">附件</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="faPageIndex" :page-size="faPageSize" :total="faTotal" class="pg" style="margin-bottom:20px;" @current-change="FAPGChange"></el-pagination>
        </el-dialog>
        <el-dialog :title="fadoTypeStr" :visible.sync="fapostModal" width="800px" top="100px">
            <el-form :model="fapostInfo" status-icon :rules="fapostrules"  ref="fForm" label-width="120px" class="demo-uForm">
                <el-form-item label="描述" prop="REMARK">
                     <el-input v-model="fapostInfo.REMARK" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item>
                <el-button type="primary" @click="submitFAPostForm()">提交</el-button>
                <el-button @click="fapostModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog :title="faFileTitle" :visible.sync="faFileModal" width="800px"  :append-to-body="true">
            <p class="fileTitle">现有附件：</p>
            <div class="fileBox">
                <div class="fileItem" v-for="item in faFileList" :key="item.SNOIWD" :title="item.FILENAME">
                    <p class="name" @click="downFile(item.FILEPATH)">{{item.FILENAME}}</p>
                    <div class="fileBtn">
                        <span @click="downFile(item.PATH)" style="color:#409EFF" v-if="item.TYPE=='1'">查看</span>
                        <span @click="downFile(item.PATH)" style="color:#409EFF" v-else>下载</span>
                        <span @click="delFile(item.SNOWID)" style="color:#F56C6C">删除</span>
                    </div>
                </div>
                <div v-if="faFileList.length==0" class="noFile">该方案还未上传附件，请在下方上传。</div>
            </div>
            <p class="fileTitle" style="margin-bottom:20px;">上传附件：</p>
            <div style="overflow:hidden;">
                <el-upload ref="upload"  class=""  :multiple="true" :limit="5" action="" :http-request="uploadFun">
                    <el-button size="small" type="primary">点击上传</el-button>
                </el-upload>
            </div>
      </el-dialog>
      <el-dialog title="选择文件" :visible.sync="chooseFileModal" width="400px"  :append-to-body="true">
            <el-upload
              ref="upload"
              class="upload-demo"
              drag
              :multiple="false"
              :limit="1"
              accept=".xls,.xlsx"
              action=""
              :http-request="uploadFunModal"
              >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
              <div class="el-upload__tip" slot="tip">只能上传excel文件</div>
            </el-upload>
    </el-dialog>
    <el-dialog title="导入结果明细" :visible.sync="importResultModal" width="540px"  :append-to-body="true">
          <p>导入成功{{importResult.success}}，导入出错{{importResult.error}}条。</p>
          <el-table :data="importResult.table" stripe style="width: 100%" :height="dialogMaxHeigh"> 
                <el-table-column prop="ROWINDEX" label="行号"></el-table-column>
                <el-table-column prop="INFO" label="问题描述"></el-table-column>
          </el-table>
    </el-dialog>
    </div>
</template>
<script>
var h=document.body.clientHeight
export default {
  name: "SBGZGL",
  data() {
    var CODE = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入编码/编号"));
      } else {
        callback();
      }
    };
    var ID = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入ID/监控ID"));
      } else {
        callback();
      }
    };
    var NAME = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入名称"));
      } else {
        callback();
      }
    };
    var SYSTEM = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入子系统名称"));
      } else {
        callback();
      }
    };
    var COMFIRM = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入确认"));
      } else {
        callback();
      }
    };
    var TYPE = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入类别"));
      } else {
        callback();
      }
    };
    var CLOSETYPE = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入关机类型"));
      } else {
        callback();
      }
    };
    var CATEGORY = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入类型"));
      } else {
        callback();
      }
    };
    var CONTEXT = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入文本/日志文字"));
      } else {
        callback();
      }
    };
    var TIMEOUT = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入超时"));
      } else {
        callback();
      }
    };
    var ALLOWTIMES = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入允许的尝试次数"));
      } else {
        callback();
      }
    };
    var MAXDISCONNECTTIME = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入最长断开时间"));
      } else {
        callback();
      }
    };
    var TIMEWINDOW = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入时间窗口"));
      } else {
        callback();
      }
    };
    var MAXREMOVETIME = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入最长消除时间"));
      } else {
        callback();
      }
    };
    var STABLEPERIOD = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入稳定期"));
      } else {
        callback();
      }
    };
    var CONDITION = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入条件"));
      } else {
        callback();
      }
    };
    var REMARK = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入解决方案"));
      } else {
        callback();
      }
    };
    return {
        canDo:true,
        
        code:"",
       

        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,


        doTypeStr:"",
        doType:"",
        postModal:false,
        postInfo:{},
        postrules: {
            CODE: [{ validator: CODE, trigger: "blur" }],
            ID: [{ validator: ID, trigger: "blur" }],
            NAME: [{ validator: NAME, trigger: "blur" }],
            SYSTEM: [{ validator: SYSTEM, trigger: "blur" }],
            COMFIRM: [{ validator: COMFIRM, trigger: "blur" }],
            TYPE: [{ validator: TYPE, trigger: "blur" }],
            CLOSETYPE: [{ validator: CLOSETYPE, trigger: "blur" }],
            CATEGORY: [{ validator: CATEGORY, trigger: "blur" }],
            CONTEXT: [{ validator: CONTEXT, trigger: "blur" }],
            TIMEOUT: [{ validator: TIMEOUT, trigger: "blur" }],
            ALLOWTIMES: [{ validator: ALLOWTIMES, trigger: "blur" }],
            MAXDISCONNECTTIME: [{ validator: MAXDISCONNECTTIME, trigger: "blur" }],
            TIMEWINDOW: [{ validator: TIMEWINDOW, trigger: "blur" }],
            MAXREMOVETIME: [{ validator: MAXREMOVETIME, trigger: "blur" }],
            STABLEPERIOD: [{ validator: STABLEPERIOD, trigger: "blur" }],
            CONDITION: [{ validator: CONDITION, trigger: "blur" }],
        },

        dialogMaxHeigh:0,
        faList:[],
        faModal:false,
        faTitle:"",
        nowSnowid:"",


        faTotal:0,
        faPageIndex:1,
        faPageSize:10,
        fadoTypeStr:"",
        fadoType:"",
        fapostModal:false,
        fapostInfo:{},
        fapostrules: {
            REMARK: [{ validator: REMARK, trigger: "blur" }],
        },

        nowFASnowid:"",
        faFileList:[],
        faFileModal:false,
        faFileTitle:"",

        chooseFileModal:false,
        importResultModal:false,
        importResult:{
          success:0,
          error:0,
          table:[]
        },
    };
  },
  mounted() {
    this.loadItem=null
    this.getPostList()
    this.dialogMaxHeigh = h -300
  },
  methods: {
    getPostList(){
        this.showLoading("请稍后")
        var params={
            PAGEINDEX:this.PageIndex,
            PAGESIZE:this.PageSize,
            FAILURESNOWID:"",
            CODE:this.code,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Failure/GetFailureList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
        });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    addPost(){
        this.postInfo={
            CODE:"",
            ID:"",
            NAME:"",
            SYSTEM:"",
            COMFIRM:"",
            TYPE:"",
            CLOSETYPE:"",
            CATEGORY:"",
            CONTEXT:"",
            TIMEOUT:"",
            ALLOWTIMES:"",
            MAXDISCONNECTTIME:"",
            TIMEWINDOW:"",
            MAXREMOVETIME:"",
            STABLEPERIOD:"",
            CONDITION:"",
            USERSNOWID:this.$store.state.guid,
        }
        this.doTypeStr="添加故障"
        this.doType="1"
        this.postModal=true
    },
    sendAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("Failure/CreateFailure", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    editPost(item){
        this.postInfo={
            FAILURESNOWID:item.SNOWID,
            CODE:item.CODE,
            ID:item.ID,
            NAME:item.NAME,
            SYSTEM:item.SYSTEM,
            COMFIRM:item.COMFIRM,
            TYPE:item.TYPE,
            CLOSETYPE:item.CLOSETYPE,
            CATEGORY:item.CATEGORY,
            CONTEXT:item.CONTEXT,
            TIMEOUT:item.TIMEOUT,
            ALLOWTIMES:item.ALLOWTIMES,
            MAXDISCONNECTTIME:item.MAXDISCONNECTTIME,
            TIMEWINDOW:item.TIMEWINDOW,
            MAXREMOVETIME:item.MAXREMOVETIME,
            STABLEPERIOD:item.STABLEPERIOD,
            CONDITION:item.CONDITION,
            USERSNOWID:this.$store.state.guid,
        }
        this.doTypeStr="修改故障"
        this.doType="2"
        this.postModal=true
    },
    sendEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("Failure/EditFailure", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.postModal=false
                    this.getPostList();
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    delPost(item){
        if(this.canDo){
            this.$confirm('确定删除故障：'+item.NAME+'？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
            this.sendDel(item.SNOWID)
            }).catch(() => {
            this.$message.info("已取消")    
          });
        }

    },
    sendDel(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({FAILURESNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({FAILURESNOWID:id})).toUpperCase())
        this.$http.post("Failure/DeleteFailure", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    submitPostForm() {
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    if(this.doType=="1"){
                        this.sendAdd()
                    }else if(this.doType=="2"){
                        this.sendEdit()
                    }
                } else {
                    return false;
                }
            });
        }
    },

    faPost(item){
        this.faTitle=item.NAME+"-解决方案管理"
        this.nowSnowid=item.SNOWID
        this.getFAList()
        this.faModal=true
    },
    getFAList(){
        this.canDo=false
        this.showLoading("请稍后")
        var params={
            FAILURESNOWID:this.nowSnowid,
            PageIndex:this.faPageIndex,
            PageSize:this.faPageSize,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Failure/GetSolutionList", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.faList=response.data.DATA
                    this.faTotal=response.data.TOTALCOUNT
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                    this.faList=[]
                    this.faTotal=0
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.faList=[]
                this.faTotal=0
                this.canDo=true   
             });
    },
    addFAItem(){
        this.fadoTypeStr="添加解决方案"
        this.fadoType="1"
        this.fapostInfo={
            FAILURESNOWID:this.nowSnowid,
            REMARK:"",
            USERSNOWID:this.$store.state.guid,
        }
        this.fapostModal=true
    },
    sendFAAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.fapostInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.fapostInfo)).toUpperCase())
        this.$http.post("Failure/CreateSolution", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.fapostModal=false
                    this.getFAList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    editFAPost(item){
        this.fapostInfo={
            SOLUTIONSNOWID:item.SNOWID,
            REMARK:item.REMARK,
            USERSNOWID:this.$store.state.guid,
        }
        this.fadoTypeStr="修改解决方案"
        this.fadoType="2"
        this.fapostModal=true
    },
    sendFAEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.fapostInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.fapostInfo)).toUpperCase())
        this.$http.post("Failure/EditSolution", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.fapostModal=false
                    this.getFAList();
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    delFAPost(item){
        if(this.canDo){
            this.$confirm('确定删除方案？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
            this.sendFADel(item.SNOWID)
            }).catch(() => {
            this.$message.info("已取消")    
          });
        }

    },
    sendFADel(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({SOLUTIONSNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SOLUTIONSNOWID:id})).toUpperCase())
        this.$http.post("Failure/DeleteSolution", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.getFAList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    submitFAPostForm() {
        if (this.canDo) {
            this.$refs.fForm.validate((valid) => {
                if (valid) {
                    if(this.fadoType=="1"){
                        this.sendFAAdd()
                    }else if(this.fadoType=="2"){
                        this.sendFAEdit()
                    }
                } else {
                    return false;
                }
            });
        }
    },
    FAPGChange(e){
        this.faPageIndex=e
        this.getFAList()
    },
    fileFAPost(item){
        this.nowFASnowid=item.SNOWID
        this.faFileTitle=item.REMARK+"-附件列表"
        this.getFAFiles()
        this.faFileModal=true
    },
    getFAFiles(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({SOLUTIONSNOWID:this.nowFASnowid}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SOLUTIONSNOWID:this.nowFASnowid})).toUpperCase())
        this.$http.post("Failure/GetSolutionFileList", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                   this.faFileList=response.data.DATA
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    uploadFun(f){
      this.showLoading("请稍后...")
      var fd = new FormData();
      fd.append("file", f.file);
      var fname=f.file.name
      var type="2"
      if(f.file.type === 'image/jpeg' || f.file.type === 'image/png'){
        type="1"
      }
      this.$upload.post("Failure/UploadSolutionFile?SOLUTIONSNOWID="+this.nowFASnowid+"&FILENAME="+fname+"&TYPE="+type, fd)
        .then((response) => {
          //console.log(response)
         this.hideLoading()
          if (response.data.SUCCESS) {
                this.hideLoading()
                this.$message.success("上传成功")
                this.$refs.upload.clearFiles()
                this.getFAFiles()
          }else{
             this.hideLoading()
             this.$message.error("上传失败")
          }
        })
        .catch((error) => {
         this.hideLoading()
      });
      
    },
    downFile(e){
        window.open(e)
    },
    delFile(e){
        if(this.canDo){
            this.$confirm('确定删除附件？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
                this.sendDelFile(e)
            }).catch(() => {
            this.$message.info("已取消")    
          });
        }
    },
    sendDelFile(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({FILESNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({FILESNOWID:id})).toUpperCase())
        this.$http.post("Failure/DeleteSolutionFile", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.getFAFiles()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },

    downloadTemplet(){
      window.open("file/故障代码导入模板.xlsx")
    },
    uploadTemplet(){
      this.chooseFileModal=true
    },
    uploadFunModal(f){
      //this.showLoading("请稍后...")
      var fd = new FormData();
      //console.log(f)
      fd.append("file", f.file);
      this.$upload.post("Failure/ImportFailure", fd)
        .then((response) => {
          //console.log(response)
         this.hideLoading()
          if (response.data.SUCCESS) {
             this.hideLoading()
             this.$message.success("导入完成")
             this.$refs.upload.clearFiles()
             this.chooseFileModal=false
             this.getPostList()
             this.importResult={
                success:response.data.DATA.SUCCESSCOUNT,
                error:response.data.DATA.ERRORCOUNT,
                table:response.data.DATA.ERRORLIST
              }
              this.importResultModal=true
          }else{
             this.hideLoading()
             this.$message.error("上传失败")
          }
        })
        .catch((error) => {
         this.hideLoading()
      });
    },

  },
  computed: {
  },
  watch: {
  },
};
</script>
<style>
.tjarea  .el-textarea__inner{height: 120px!important;}
</style>
<style scoped>

.fileBox{overflow: hidden;margin: 20px 0;padding: 20px;background-color: #F8F8F9;}
.fileItem{border-radius: 3px;background-color: #FFFFFF;cursor: pointer;padding: 10px;float:left;margin-right: 20px;margin-bottom: 20px;width: 206px;}
.fileItem:nth-of-type(3n){margin-right: 0!important;}
.fileItem .name{font-size: 15px;font-weight: bold;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;color: #444;margin-bottom: 2px;}
.fileItem .keywords{font-size: 14px;font-weight: bold;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;margin-bottom: 5px;color: #909399;}
.fileItem .fileBtn{overflow: hidden;text-align: left;}
.fileItem span{margin-right: 10px;}
.noFile{text-align: center;font-size: 15px;color: #409EFF;}
.fileTitle{font-size: 16px;font-weight: bold;overflow: hidden;}

</style>