var  comjs = {
  baseUrl:"https://powerplant.17fbfb.com/WebApi/",
  sortAsc :function(o) {
    var n = [];
    for (var k in o) n.push(k);
    n.sort();
    for (var i = 0, str = ''; i < n.length; i++) {
      var v = o[n[i]];
      if (typeof v == 'string') {
        if (n[i].indexOf("TIME") != -1) {
          v=v.replace("T", "")
              }
        v = v.replace(/\s+/g, "")
      }
        
      if (v) {
        if ({}.toString.call(v) == '[object Object]') {
          //v = this.sortAsc(v);
          //v = JSON.stringify(v).replace(/:/g,'=');
          v = JSON.stringify(v)
        }
        else if ({}.toString.call(v) == '[object Array]') {
          //v = JSON.stringify(v).replace(/:/g,'=');
          v = JSON.stringify(v)
        }
      }
      str += n[i] + v;
    }
    ////console.log(str)
    return str;
  },
  requestDataEncrypt:function (data, token,sign) {
    var newData = {
      TIMESTAMP: new Date().getTime(),
      TOKEN: token,
      DATA: data,
      DATASIGN: sign,
    }
    return newData
  },
  GetQueryString:function(name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    var r = window.location.search.substr(1).match(reg);
    if (r != null) return unescape(r[2]);
    return null;
  },
  PH : document.body.clientHeight,
  PW : document.body.clientWidth,
  getDateStrByDate(date){
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var hour = date.getHours();
    var minutes = date.getMinutes() ;
    var seconds = date.getSeconds();
    var strDate = date.getDate();
    
    if (month >= 1 && month <= 9) {
        month = "0" + month;
    }
    if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
    }

    if (hour >= 0 && hour <= 9) {
      hour = "0" + hour;
    }
    if (minutes >= 0 && minutes <= 9) {
      minutes = "0" + minutes;
    }
    if (seconds >= 0 && seconds <= 9) {
      seconds = "0" + seconds;
    }
    return year + "-" + month + "-" + strDate+" "+hour+":" +minutes+":" +seconds;
  },
  getDateMinStrByDate(date){
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var strDate = date.getDate();
    
    if (month >= 1 && month <= 9) {
        month = "0" + month;
    }
    if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
    }
    return year + "-" + month + "-" + strDate;
  },
  compareDate(date1,date2){
    var result = true;
    if(date1.getFullYear() < date2.getFullYear()){
        result = false;
    }else{
      if(date1.getMonth() < date2.getMonth()){
          result = false;
      }else{
        if(date1.getDate() < date2.getDate()){
          result = false;
        }
      } 
    }
    return result;
  },
  getDaysInCurrentMonth(currentDate) {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const lastDay = new Date(year, month, 0).getDate();
    return lastDay;
  },
  removeDuplicateObj (arr,key){
    var nl=[]
    for(var i=0;i<arr.length;i++){
      var d=arr[i]
      var has=false
      for(var j=0;j<nl.length;j++){
        if(nl[j][key] == d[key]){
          has=true;
          break;
        }
      }
      if(!has){
        nl.push(d)
      }
    }
    return nl;
  },
}
export default comjs