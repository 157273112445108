<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">用户更换设备处理</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                
                <el-select v-model="status" placeholder="点击选择状态"  clearable filterable  class="iw">
                        <el-option label="全部状态" value="" ></el-option>
                        <el-option label="待审核" value="1" ></el-option>
                        <el-option label="已通过" value="2" ></el-option>
                        <el-option label="已拒绝" value="3" ></el-option>
                </el-select>
                <el-input v-model="username" placeholder="请输入姓名" class="iw" ></el-input>
                <el-input v-model="loginname" placeholder="请输入登录名" class="iw" ></el-input>
                <el-button type="primary" @click="searchPost">查询</el-button>
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column prop="TITLE" label="标题"></el-table-column>
                    <el-table-column prop="USERNAME" label="姓名"></el-table-column>
                    <el-table-column prop="LOGINNAME" label="登录名"></el-table-column>
                    <el-table-column prop="STATUS" label="状态">
                        <template slot-scope="scope">
                            <span v-if="scope.row.STATUS=='1'" style="color:#409EFF">待审核</span>
                            <span v-else-if="scope.row.STATUS=='2'" style="color:#67C23A">已通过</span>
                            <span v-else-if="scope.row.STATUS=='3'" style="color:#F56C6C">已拒绝</span>
                            <span v-else>/</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="OPINION" label="审核意见"></el-table-column>
                    
                    <el-table-column prop="CREATETIME" label="申请时间"></el-table-column>
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                        <el-button @click="pass(scope.row)" type="text" size="small" style="color:#E6A23C" v-if="scope.row.STATUS == '1'">审核</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total,prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog title="设备更换处理" :visible.sync="postModal" width="500px">
            <el-form :model="postInfo" status-icon :rules="postrules"  ref="uForm" label-width="100px" class="demo-uForm">
                <el-form-item label="是否通过：">
                    <el-radio v-model="postInfo.STATUS" label="2">通过</el-radio>
                    <el-radio v-model="postInfo.STATUS" label="3">不通过</el-radio>
                </el-form-item>
                <el-form-item label="审核意见：" prop="OPINION">
                    <el-input v-model="postInfo.OPINION" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item>
                <el-button type="primary" @click="submitPostForm()">提交</el-button>
                <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
export default {
  name: "UserManage",
  data() {
    var OPINION = (rule, value, callback) => {
      if (this.postInfo.STATUS=="3" && value === "") {
        callback(new Error("请输入不通过原因"));
      } else {
        callback();
      }
    };
    return {
        canDo:true,
        
        username:"",
        loginname:"",
        status:"1",

        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,

        postModal:false,
        postInfo:{
            STATUS:"2",
            OPINION:"",
        },
        postrules: {
            OPINION: [{ validator: OPINION, trigger: "blur" }],
        },
    };
  },
  mounted() {
    this.loadItem=null
    this.getPostList()
  },
  methods: {
    getPostList(){
        this.showLoading("请稍后")
        var params={
            PAGEINDEX:this.PageIndex,
            PAGESIZE:this.PageSize,
            STATUS:this.status,
            USERNAME:this.username,
            LOGINNAME:this.loginname
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("DeviceWf/GetWaitToAuditList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
        });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },

    pass(item){
        this.postInfo={
            SNOWID:item.SNOWID,
            CREATORGUID:item.CREATORGUID,
            STATUS:"2",
            OPINION:"",
        }
        this.postModal=true
    },
    sendPass(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("DeviceWf/Audit", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    PGChange(e){
        this.PageIndex=e
        this.getUserList()
    },
    submitPostForm() {
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    this.sendPass()
                } else {
                    return false;
                }
            });
        }
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style >
</style>