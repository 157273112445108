<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">党建、工会工作及生产考核管理</p>
        </div>
        <div class="tableBox" style="top:50px;">
                <div class="ksItem" >
                   风场完成党支部工作范围
                </div>
                <div class="ksItem" >
                   葫芦岛区域风场年终总结
                </div>
                <div class="ksItem" >
                   风场全体员工述职报告，含全年度每个岗位工作完成明细。
                </div>
                <div class="ksItem" >
                   生产各项工作提交优质方案 
                </div>
                <div class="ksItem" >
                   风场值班电话和风场生产人员电号通讯录。 
                </div>
        </div>
    </div>
</template>
<script>
export default {
  name: "KSFL",
  data() {

    return {
        canDo:true, 
    };
  },
  mounted() {
    this.loadItem=null
  },
  methods: {

  },
  computed: {
  },
  watch: {
  },
};
</script>
<style scoped>
    .el-collapse-item__header{padding: 0 20px;font-size: 16px;}
    .el-collapse .is-active{color: #409EFF;}
    .el-collapse-item__content{padding-left:20px;}

    .ksItem{width: 600px;height: 80px;border-radius: 3px;cursor: pointer;color: #FFFFFF;background-color: #409EFF;font-size: 16px;margin-left: 30px;margin-top: 30px;float:left;padding:0 30px;display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;}
            .ksItem:hover{background-color: #5FADFC;}

</style>