<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">库存管理</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                
                <el-input v-model="cname" placeholder="货物中文名" class="iw" ></el-input>
                <el-input v-model="ename" placeholder="货物英文名" class="iw" ></el-input>
                <el-input v-model="ecode" placeholder="物料号" class="iw" ></el-input>
                <el-select v-model="ckid" placeholder="请选择仓库" filterable clearable class="iw">
                        <el-option v-for="item in ckList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                </el-select>
                <el-select v-model="gysid" placeholder="请选择供应商" filterable clearable class="iw">
                        <el-option v-for="item in gysList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                </el-select>
                <el-cascader v-model="wzhpfl"  :props="typeprops" placeholder="选择货品分类" clearable filterable class="iwss" ></el-cascader>
                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-button type="success" @click="addPost" class="fr">添加</el-button>
                
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column prop="CNNAME" label="货物中文名"></el-table-column>
                    <el-table-column prop="ENNAME" label="货物英文名"></el-table-column>
                    <el-table-column prop="CODE" label="物料号"></el-table-column>
                    <el-table-column prop="GGMODEL" label="规格型号"></el-table-column>
                    <el-table-column prop="UNIT" label="单位"></el-table-column>
                    <el-table-column prop="STANDARDPRICE" label="单价"></el-table-column>
                    <el-table-column prop="CURRCOUNT" label="库存"></el-table-column>

                    <el-table-column prop="STORAGENAME" label="仓库"></el-table-column>
                    <el-table-column prop="SUPPLIERNAME" label="供货商"></el-table-column>
                    <el-table-column prop="THINGTYPEID" label="货品分类">
                        <template slot-scope="scope">
                            <span v-if="scope.row.STORAGETHINGTYPELIST && scope.row.STORAGETHINGTYPELIST.length>0">{{scope.row.STORAGETHINGTYPELIST[scope.row.STORAGETHINGTYPELIST.length-1].NAME}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="MANAGERUSER" label="管理员">
                        <template slot-scope="scope" >
                            <span v-if="scope.row.MANAGERUSER">{{scope.row.MANAGERUSER.TRUENAME+'('+scope.row.MANAGERUSER.LOGINNAME+')'}}</span>
                            <span v-else>/</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="MANAGERMOBILE" label="管理员电话"></el-table-column>

                    <el-table-column prop="BZ" label="备注"></el-table-column>
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                          <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button>
                          <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
                          <el-button @click="inPost(scope.row)" type="text" size="small" style="color:#67C23A;margin-left:0" v-if="!scope.row.FACTORYSNOWID">公司入库</el-button>
                          <el-button @click="inPostFC(scope.row)" type="text" size="small" style="color:#67C23A;margin-left:0" v-else>风场入库</el-button>
                          <el-button @click="OutPost(scope.row)" type="text" size="small" style="color:#F56C6C" v-if="!scope.row.FACTORYSNOWID">公司出库</el-button>
                          <el-button @click="OutPostFC(scope.row)" type="text" size="small" style="color:#F56C6C" v-else>风场出库</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="800px" top="30px">
            <el-form :model="postInfo" status-icon :rules="postrules"  ref="uForm" label-width="100px" class="demo-uForm">
                <el-form-item label="货物中文名" prop="CNNAME">
                    <el-input v-model="postInfo.CNNAME" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="货物英文名" prop="ENNAME">
                    <el-input v-model="postInfo.ENNAME" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="物料号" prop="CODE">
                    <el-input v-model="postInfo.CODE" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="规格型号" prop="GGMODEL">
                    <el-input v-model="postInfo.GGMODEL" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="单位" prop="UNIT">
                    <el-input v-model="postInfo.UNIT" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="单价（元）" prop="STANDARDPRICE">
                    <el-input v-model="postInfo.STANDARDPRICE" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="库存" prop="CURRCOUNT">
                    <el-input v-model="postInfo.CURRCOUNT" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="仓库" prop="STORAGEID" >
                    <el-select v-model="postInfo.STORAGEID" placeholder="请选择仓库" filterable  style="width:100%;" @change="changeCK">
                            <el-option v-for="item in ckList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="货品分类" prop="THINGTYPEID">
                    <el-cascader v-model="postInfo.THINGTYPEID"  :props="typeprops" placeholder="选择货品分类"  filterable style="width:100%;" ></el-cascader>
                </el-form-item>
                <el-form-item label="供货商" prop="SUPPLIERID">
                    <el-select v-model="postInfo.SUPPLIERID" placeholder="请选择供货商" filterable  style="width:100%;">
                            <el-option v-for="item in gysList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="负责人" prop="MANAGER">
                    <el-select v-model="postInfo.MANAGER" placeholder="请选择管理员" filterable style="width:100%" @change="changeUser">
                            <el-option v-for="item in userList" :key="item.SNOWID" :label="item.TRUENAME+'('+item.LOGINNAME+')'" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="管理员电话" prop="MANAGERMOBILE">
                    <el-input v-model="postInfo.MANAGERMOBILE" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="备注" prop="BZ">
                    <el-input v-model="postInfo.BZ" autocomplete="off"></el-input>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" @click="submitPostForm()">提交</el-button>
                    <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>


        <!-- 公司入库 -->
        <el-dialog :title="logTitleStr" :visible.sync="logInListModal" width="1400px" top="50px" >
                <div style="width:100%;overflow:hidden;margin-bottom:20px;">
                    <el-date-picker v-model="logInDate"  type="daterange" range-separator="至" start-placeholder="开始日期"  end-placeholder="结束日期"> </el-date-picker>
                    <el-button type="primary" @click="searchInPost()">查询</el-button>
                    <el-button type="success" @click="addInLog()" class="">新增入库记录</el-button>
                    
                </div>
                <el-table :data="logInList" stripe style="width: 100%" :height="dialogMaxHeigh">
                    <el-table-column prop="THINGNAME" label="物资名称"></el-table-column>
                    <el-table-column prop="SUPPLIERNAME" label="供货商"></el-table-column>
                    <el-table-column prop="CREATETIME" label="入库日期"></el-table-column>
                    <el-table-column prop="UNIT" label="单位"></el-table-column>
                    <el-table-column prop="SINGLEPRICE" label="单价"></el-table-column>
                    <el-table-column prop="PRICE" label="入库总价"></el-table-column>
                    <el-table-column prop="SUMCOUNT" label="入库数量"></el-table-column>
                    <el-table-column prop="MANAGER" label="负责人"></el-table-column>
                    
                    <el-table-column prop="INSTOCKCOUNT" label="现余库存"></el-table-column>
                    <el-table-column prop="INSTOCKPRICE" label="库存总价"></el-table-column>
                    <el-table-column prop="STORAGEPOINT" label="物资存放点"></el-table-column>
                    <el-table-column prop="CONTRACTPRICE" label="合同总价"></el-table-column>
                    <el-table-column prop="BZ" label="备件状态"></el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndexLogIn" :page-size="PageSizeLogIn" :total="TotalLogIn" class="pg pgItem" @current-change="PGChangeLogIn"></el-pagination>
        </el-dialog>
        <el-dialog :title="doTypeStrLog" :visible.sync="logInModal" width="800px" top="30px" append-to-body>
            <el-form :model="logInInfo" status-icon :rules="logInrules"  ref="logInForm" label-width="100px" class="demo-uForm">
                <el-form-item label="入库数量" prop="SUMCOUNT">
                    <el-input v-model="logInInfo.SUMCOUNT" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="备注" prop="BZ">
                    <el-input v-model="logInInfo.BZ" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="合同">
                    <el-select v-model="logInInfo.CONTRACTSNOWID" placeholder="请选择合同" filterable  clearable style="width:100%;">
                            <el-option v-for="item in htList" :key="item.SNOWID" :label="item.CODE" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="物资存放点" prop="STORAGEPOINT">
                    <el-input v-model="logInInfo.STORAGEPOINT" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitLogInForm()">提交</el-button>
                    <el-button @click="logInModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <!-- 公司出库 -->
        <el-dialog :title="logTitleStr" :visible.sync="logOutListModal" width="1400px" top="50px" >
                <div style="width:100%;overflow:hidden;margin-bottom:20px;">
                    <el-date-picker v-model="logOutDate"  type="daterange" range-separator="至" start-placeholder="开始日期"  end-placeholder="结束日期"> </el-date-picker>
                    <el-button type="primary" @click="searchOutPost()">查询</el-button>
                    <el-button type="success" @click="addOutLog()" class="">新增出库记录</el-button>
                </div>
                <el-table :data="logOutList" stripe style="width: 100%" :height="dialogMaxHeigh">
                    <el-table-column prop="THINGNAME" label="物资名称"></el-table-column>
                    <el-table-column prop="THINGCODE" label="物料号"></el-table-column>
                    <el-table-column prop="SUPPLIERNAME" label="供货商"></el-table-column>
                    <el-table-column prop="OUTDATE" label="出库日期"></el-table-column>
                    <el-table-column prop="UNIT" label="单位"></el-table-column>
                    <el-table-column prop="UNITPRICE" label="单价"></el-table-column>
                    <el-table-column prop="OUTCOUNT" label="公司物资备件出库数量"></el-table-column>
                    <el-table-column prop="TOTALPRICE" label="总价金额"></el-table-column>
                    <el-table-column prop="INSTOCKCOUNT" label="公司物资备件库存剩余数量"></el-table-column>
                    <el-table-column prop="OUTPERSON" label="公司出库人"></el-table-column>
                    <el-table-column prop="FACTORYINCOUNT" label="风场物资备件入库数量"></el-table-column>
                    <el-table-column prop="FACTORYINPRICE" label="风场物资备件入库金额"></el-table-column>
                    <el-table-column prop="FACTORYINSTOCKCOUNT" label="风场物资备件剩余数量"></el-table-column>
                    <el-table-column prop="FACTORYINSTOCKPRICE" label="风场物资备件剩余库存金额"></el-table-column>

                    <el-table-column prop="MACHINENUMBER" label="使用备件风机号"></el-table-column>
                    <el-table-column prop="FACTORYSTORAGEPOINT" label="物资存放点"></el-table-column>
                    <el-table-column prop="FACTORYDELIVERYPERSON" label="风场提货人"></el-table-column>
                    <el-table-column prop="BZ" label="备件状态"></el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndexLogOut" :page-size="PageSizeLogOut" :total="TotalLogOut" class="pg pgItem" @current-change="PGChangeLogOut"></el-pagination>
        </el-dialog>
        <el-dialog :title="doTypeStrLog" :visible.sync="logOutModal" width="800px" top="30px" append-to-body>
            <el-form :model="logOutInfo" status-icon :rules="logOutrules"  ref="logOutForm" label-width="150px" class="demo-uForm">
                <el-form-item label="出库数量" prop="OUTCOUNT">
                    <el-input v-model="logOutInfo.OUTCOUNT" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="出库人" prop="OUTUSERSNOWID">
                  <el-select v-model="logOutInfo.OUTUSERSNOWID" placeholder="请选择出库人" filterable  style="width:100%;">
                            <el-option v-for="item in userList" :key="item.SNOWID" :label="item.TRUENAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="入库风场">
                  <el-select placeholder="请选择入库风场" v-model="rkfc" filterable  style="width:100%;" @change="getCkByFc">
                            <el-option v-for="item in fcList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="入库仓库" prop="STORAGESNOWID">
                  <el-select v-model="logOutInfo.STORAGESNOWID" placeholder="请选择入库仓库" filterable  style="width:100%;">
                            <el-option v-for="item in fcckList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="风机设备" prop="MACHINESNOWID">
                  <el-select v-model="logOutInfo.MACHINESNOWID" placeholder="请选择风机设备" filterable  style="width:100%;">
                            <el-option v-for="item in sbList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="入库存放点" prop="STORAGEPOINT">
                    <el-input v-model="logOutInfo.STORAGEPOINT" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="入库提取人" prop="DELIVERYPERSON">
                  <el-select v-model="logOutInfo.DELIVERYPERSON" placeholder="请选择入库提取人" filterable  style="width:100%;">
                            <el-option v-for="item in userList" :key="item.SNOWID" :label="item.TRUENAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="入库物料管理员" prop="MANAGERSNOWID">
                    <el-select v-model="logOutInfo.MANAGERSNOWID" placeholder="请选择入库物料管理员" filterable  style="width:100%;" @change="changeUserLogOut">
                            <el-option v-for="item in userList" :key="item.SNOWID" :label="item.TRUENAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="入库物料管理员电话" prop="MANAGERMOBILE">
                    <el-input v-model="logOutInfo.MANAGERMOBILE" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="出库备注" prop="REMARK">
                    <el-input v-model="logOutInfo.REMARK" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitLogOutForm()">提交</el-button>
                    <el-button @click="logOutModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <!-- 风场入库 -->
        <el-dialog :title="logTitleStr" :visible.sync="logInListModalFC" width="1400px" top="50px" >
                <div style="width:100%;overflow:hidden;margin-bottom:20px;">
                  <el-date-picker v-model="logInDateFC"  type="daterange" range-separator="至" start-placeholder="开始日期"  end-placeholder="结束日期"> </el-date-picker>
                    <el-button type="primary" @click="searchInPostFC()">查询</el-button>
                </div>
                <el-table :data="logInListFC" stripe style="width: 100%" :height="dialogMaxHeigh">
                    <el-table-column prop="THINGNAME" label="物资名称"></el-table-column>
                    <el-table-column prop="SUPPLIERNAME" label="供货商"></el-table-column>
                    <el-table-column prop="CREATETIME" label="入库日期"></el-table-column>
                    <el-table-column prop="UNIT" label="单位"></el-table-column>
                    <el-table-column prop="SINGLEPRICE" label="单价"></el-table-column>
                    <el-table-column prop="PRICE" label="入库总价"></el-table-column>
                    <el-table-column prop="SUMCOUNT" label="入库数量"></el-table-column>
                    <el-table-column prop="MANAGER" label="负责人"></el-table-column>
                    
                    <el-table-column prop="INSTOCKCOUNT" label="现余库存"></el-table-column>
                    <el-table-column prop="INSTOCKPRICE" label="库存总价"></el-table-column>
                    <el-table-column prop="STORAGEPOINT" label="物资存放点"></el-table-column>
                    <!-- <el-table-column prop="CONTRACTPRICE" label="合同总价"></el-table-column> -->
                    <el-table-column prop="BZ" label="备注"></el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndexLogInFC" :page-size="PageSizeLogInFC" :total="TotalLogInFC" class="pg pgItem" @current-change="PGChangeLogInFC"></el-pagination>
        </el-dialog>

        <!-- 风场出库 -->
        <el-dialog :title="logTitleStr" :visible.sync="logOutListModalFC" width="1400px" top="50px" >
                <div style="width:100%;overflow:hidden;margin-bottom:20px;">
                  <el-date-picker v-model="logOutDateFC"  type="daterange" range-separator="至" start-placeholder="开始日期"  end-placeholder="结束日期"> </el-date-picker>
                    <el-button type="primary" @click="searchOutPostFC()">查询</el-button>
                    <el-button type="success" @click="addOutLogFC()" class="">新增出库记录</el-button>
                </div>
                <el-table :data="logOutListFC" stripe style="width: 100%" :height="dialogMaxHeigh">
                    <el-table-column prop="THINGNAME" label="物资名称"></el-table-column>
                    <el-table-column prop="THINGCODE" label="物料号"></el-table-column>
                    <el-table-column prop="SUPPLIERNAME" label="供货商"></el-table-column>
                    <el-table-column prop="OUTDATE" label="出库日期"></el-table-column>
                    <el-table-column prop="UNIT" label="单位"></el-table-column>
                    <el-table-column prop="UNITPRICE" label="单价"></el-table-column>
                    <el-table-column prop="OUTCOUNT" label="公司物资备件出库数量"></el-table-column>
                    <el-table-column prop="TOTALPRICE" label="总价金额"></el-table-column>
                    <el-table-column prop="INSTOCKCOUNT" label="公司物资备件库存剩余数量"></el-table-column>
                    <el-table-column prop="OUTPERSON" label="公司出库人"></el-table-column>
                    <el-table-column prop="MACHINENUMBER" label="使用备件风机号"></el-table-column>
                    <el-table-column prop="FACTORYDELIVERYPERSON" label="风场提货人"></el-table-column>
                    <el-table-column prop="BZ" label="备件状态"></el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndexLogOutFC" :page-size="PageSizeLogOutFC" :total="TotalLogOutFC" class="pg pgItem" @current-change="PGChangeLogOutFC"></el-pagination>
        </el-dialog>
        <el-dialog :title="doTypeStrLog" :visible.sync="logOutModalFC" width="800px" top="30px" append-to-body>
            <el-form :model="logOutInfoFC" status-icon :rules="logOutrulesFC"  ref="logOutFormFC" label-width="150px" class="demo-uForm">
                <el-form-item label="出库数量" prop="OUTCOUNT">
                    <el-input v-model="logOutInfoFC.OUTCOUNT" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="出库人" prop="OUTUSERSNOWID">
                  <el-select v-model="logOutInfoFC.OUTUSERSNOWID" placeholder="请选择出库人" filterable  style="width:100%;">
                            <el-option v-for="item in userList" :key="item.SNOWID" :label="item.TRUENAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="入库记录" prop="INSTORAGESNOWID">
                  <el-select v-model="logOutInfoFC.INSTORAGESNOWID" placeholder="请选择入库记录" filterable  style="width:100%;">
                            <el-option v-for="item in rkjlList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="提货人" prop="DELIVERYPERSON">
                  <el-select v-model="logOutInfoFC.DELIVERYPERSON" placeholder="请选择入库提取人" filterable  style="width:100%;">
                            <el-option v-for="item in userList" :key="item.SNOWID" :label="item.TRUENAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="出库备注" prop="REMARK">
                    <el-input v-model="logOutInfoFC.REMARK" autocomplete="off"></el-input>
                </el-form-item>
                

                <el-form-item>
                    <el-button type="primary" @click="submitLogOutFormFC()">提交</el-button>
                    <el-button @click="logOutModalFC = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
var h=document.body.clientHeight
export default {
  name: "PostManage",
  data() {
    var ENNAME = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入货物英文名"));
      } else {
        callback();
      }
    };
    var CODE = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入物料号"));
      } else {
        callback();
      }
    };
    var GGMODEL = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入规格型号号"));
      } else {
        callback();
      }
    };
    var CNNAME = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入货物中文名"));
      } else {
        callback();
      }
    };
    var SUPPLIERID = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择供货商"));
      } else {
        callback();
      }
    };
    var THINGTYPEID = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择货品分类"));
      } else {
        callback();
      }
    };
    var STORAGEID = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择仓库"));
      } else {
        callback();
      }
    };
    var UNIT = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入单位"));
      } else {
        callback();
      }
    };
    var MANAGER = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择管理员"));
      } else {
        callback();
      }
    };
    var MANAGERMOBILE = (rule, value, callback) => {
      if (value === '' || value == null) {
        callback(new Error('请输入手机号'));
      } else if(!/^1[3456789]\d{9}$/.test(value)){
        callback(new Error('请输入正确的手机号'));
      }else {
        callback();
      }
    };
    var STANDARDPRICE=(rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入单价'));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('单价只能是数字'));
        }else if(value<=0){
            callback(new Error('单价必须大于0'));
        }else {
          callback();
        }
    };
    var CURRCOUNT=(rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入库存'));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('库存只能是数字'));
        }else {
          callback();
        }
    };



    //公司入库
    var SUMCOUNT=(rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入数量'));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('数量只能是数字'));
        }else if(!/^\+?(0|[1-9][0-9]*)$/.test(value)){
            callback(new Error('只能输入大于等于0的整数'));
        }else {
          callback();
        }
    };

    var STORAGEPOINT = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入物资存放点"));
      } else {
        callback();
      }
    };
  
    //公司出库
    var OUTCOUNT=(rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入出库数量'));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('出库数量只能是数字'));
        }else if(!/^\+?(0|[1-9][0-9]*)$/.test(value)){
            callback(new Error('只能输入大于等于0的整数'));
        }else {
          callback();
        }
    };
    var STORAGESNOWID= (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择入库仓库"));
      } else {
        callback();
      }
    };
    var MACHINESNOWID=(rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择风机设备"));
      } else {
        callback();
      }
    };
    var DELIVERYPERSON=(rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择入库提取人（提货人）"));
      } else {
        callback();
      }
    };
    var  REMARK=(rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选输入出库备注"));
      } else {
        callback();
      }
    };
    var MANAGERSNOWID=(rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择入库物料管理员"));
      } else {
        callback();
      }
    };
    var OUTUSERSNOWID=(rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择出库人"));
      } else {
        callback();
      }
    };

    var INSTORAGESNOWID=(rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择一条入库记录"));
      } else {
        callback();
      }
    };

    return {
        dialogMaxHeigh:0,
        typeprops: {
            lazy: true,
            checkStrictly:true,
            lazyLoad :(node, resolve)=> {
                var guid="0"
                if(node && node.data!=undefined){
                guid=node.data.guid 
                }
                var nodes=[]
                var md5_data =this.comjs.requestDataEncrypt({PARENTID:guid}, this.$store.state.token,this.$md5(this.comjs.sortAsc({PARENTID:guid})).toUpperCase())
                this.$http.post("Storage/GetStorageThingtypeList",md5_data)
                .then((response) => {
                if(response.data.SUCCESS){   
                    var l=response.data.DATA
                    for(var i=0;i<l.length;i++){
                        nodes.push({
                        value:l[i].SNOWID,
                        label:l[i].NAME,
                        guid:l[i].SNOWID,
                        leaf: false
                        })
                    }
                    resolve(nodes);
                }else{
                    this.errorRequest(response.data.DATA,response.data.MSG,false)
                }
                })
            }
        },
        canDo:true,
        
        cname:"",
        ename:"",
        ecode:"",
        ckid:"",
        gysid:"",
        wzhpfl:"",
        fcList:[],
        gysList:[],
        ckList:[],
        fcckList:[],
        sbList:[],
        htList:[],
        userList:[],

        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,


        doTypeStr:"",
        doType:"",
        postModal:false,
        postInfo:{},
        postrules: {
            ENNAME: [{ validator: ENNAME, trigger: "blur" }],
            CODE: [{ validator: CODE, trigger: "blur" }],
            GGMODEL: [{ validator: GGMODEL, trigger: "blur" }],
            CNNAME: [{ validator: CNNAME, trigger: "blur" }],
            SUPPLIERID: [{ validator: SUPPLIERID, trigger: "blur" }],
            THINGTYPEID: [{ validator: THINGTYPEID, trigger: "blur" }],
            UNIT: [{ validator: UNIT, trigger: "blur" }],
            STANDARDPRICE: [{ validator: STANDARDPRICE, trigger: "blur" }],
            CURRCOUNT: [{ validator: CURRCOUNT, trigger: "blur" }],
            MANAGER: [{ validator: MANAGER, trigger: "blur" }],
            MANAGERMOBILE: [{ validator: MANAGERMOBILE, trigger: "blur" }],
            STORAGEID: [{ validator: STORAGEID, trigger: "blur" }],
        },


        rkfc:"",

        logItem:null,
        logTitleStr:"",
        doTypeStrLog:"",
        //公司入库查询
        logInDate:[],
        logInList:[],
        PageIndexLogIn:1,
        PageSizeLogIn:10,
        TotalLogIn:0,
        logInListModal:false,
        //公司人库
        logInModal:false,
        logInInfo:{},
        logInrules: {
            SUMCOUNT: [{ validator: SUMCOUNT, trigger: "blur" }],
            STORAGEPOINT: [{ validator: STORAGEPOINT, trigger: "blur" }],
            
        },

        //公司出库查询
        logOutList:[],
        PageIndexLogOut:1,
        PageSizeLogOut:10,
        TotalLogOut:0,
        logOutListModal:false,
        logOutDate:[],
        //公司出库
        logOutModal:false,
        logOutInfo:{},
        logOutrules: {
            OUTCOUNT: [{ validator: OUTCOUNT, trigger: "blur" }],
            STORAGESNOWID: [{ validator: STORAGESNOWID, trigger: "blur" }],
            MACHINESNOWID: [{ validator: MACHINESNOWID, trigger: "blur" }],
            STORAGEPOINT: [{ validator: STORAGEPOINT, trigger: "blur" }],
            DELIVERYPERSON: [{ validator: DELIVERYPERSON, trigger: "blur" }],
            REMARK: [{ validator: REMARK, trigger: "blur" }],
            MANAGERSNOWID: [{ validator: MANAGERSNOWID, trigger: "blur" }],
            MANAGERMOBILE: [{ validator: MANAGERMOBILE, trigger: "blur" }],
            OUTUSERSNOWID: [{ validator: OUTUSERSNOWID, trigger: "blur" }],
        },



        //风场入库查询
        logInListFC:[],
        PageIndexLogInFC:1,
        PageSizeLogInFC:10,
        TotalLogInFC:0,
        logInListModalFC:false,
        logInDateFC:[],
        //风场出库查询
        logOutListFC:[],
        PageIndexLogOutFC:1,
        PageSizeLogOutFC:10,
        TotalLogOutFC:0,
        logOutListModalFC:false,
        logOutDateFC:[],
        //风场出库
        logOutModalFC:false,
        logOutInfoFC:{},
        logOutrulesFC: {
            OUTCOUNT: [{ validator: OUTCOUNT, trigger: "blur" }],
            OUTUSERSNOWID: [{ validator: OUTUSERSNOWID, trigger: "blur" }],
            REMARK: [{ validator: REMARK, trigger: "blur" }],
            DELIVERYPERSON: [{ validator: DELIVERYPERSON, trigger: "blur" }],
            INSTORAGESNOWID: [{ validator: INSTORAGESNOWID, trigger: "blur" }],
        },
        rkjlList:[],


    };
  },
  mounted() {
    this.loadItem=null
    this.dialogMaxHeigh = h -300
    this.getPostList()
    this.getGYSList()
    this.getCKList()
    this.getUserList()
    this.getSbList()
    this.getHtList()
    this.getFcList()
  },
  methods: {
    getFcList(){
        var params={}
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Factory/GetFactoryWithoutPage", md5_data)
            .then((response) => {
                if (response.data.SUCCESS && response.data.DATA.length>0) {
                    this.fcList=response.data.DATA
                }
            })
    },
    getCkByFc(e){
      this.fcckList=[]
      if(e!=""){
        this.getfcck(e)
      }
    },
    getfcck(id){
        var params={
          FACTORYSNOWID:id
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Storage/GetStorageList", md5_data)
            .then((response) => {
                if (response.data.SUCCESS && response.data.DATA.length>0) {
                    this.fcckList=response.data.DATA
                } 
            }) 
    },
    getSbList(){
        var params={}
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Machine/GetMachine", md5_data)
            .then((response) => {
                if (response.data.SUCCESS && response.data.DATA.length>0) {
                    this.sbList=response.data.DATA
                } 
            }) 
    },
    getHtList(){
      var params={
        ISPAGE:false
      }
      var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
      this.$http.post("Storage/GetContractList", md5_data)
          .then((response) => {
              if (response.data.SUCCESS && response.data.DATA.length>0) {
                  this.htList=response.data.DATA
              } 
          }) 
    },
    changeUser(e){
      if(e==""){
        this.postInfo.MANAGERMOBILE=""
      }else{
        for(var i=0;i<this.userList.length;i++){
          if(this.userList[i].SNOWID==e){
            this.postInfo.MANAGERMOBILE = this.userList[i].MOBILEPHONE
            break;
          }
        }
      }
    },
    changeUserLogOut(e){
      if(e==""){
        this.logOutInfo.MANAGERMOBILE=""
      }else{
        for(var i=0;i<this.userList.length;i++){
          if(this.userList[i].SNOWID==e){
            this.logOutInfo.MANAGERMOBILE = this.userList[i].MOBILEPHONE
            break;
          }
        }
      }
    },
    getUserList(){
        var params={
          PAGEINDEX:1,
          PAGESIZE:10000,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("UserManage/GetUserList", md5_data)
            .then((response) => {
                this.canDo=true
                if (response.data.SUCCESS) {
                    this.userList=response.data.DATA
                    
                }
            })
    },
    changeGHSLog(e){
      if(e==""){
        this.logInInfo.SUPPLIERNAME=""
      }else{
        for(var i=0;i<this.gysList.length;i++){
          if(this.gysList[i].SNOWID==e){
            this.logInInfo.SUPPLIERNAME = this.gysList[i].NAME
            break;
          }
        }
      }
    },
    
    getGYSList(){
        var params={
            PAGEINDEX:1,
            PAGESIZE:500,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Storage/GetStorageSupplierList", md5_data)
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.gysList=response.data.DATA
                
                } 
            })
    },
    changeCK(e){
      if(e==""){
        this.postInfo.STORAGENAME=""
      }else{
        for(var i=0;i<this.ckList.length;i++){
          if(this.ckList[i].SNOWID==e){
            this.postInfo.STORAGENAME = this.ckList[i].NAME
            break;
          }
        }
      }
    },
    changeCKLog(e){
      if(this.logInInfo.INSTORAGESNOWID && this.logInInfo.INSTORAGESNOWID!=undefined){
          this.logInInfo.INSTORAGESNOWID =""
        }
      if(e==""){
        this.logInInfo.STORAGENAME=""
      }else{
        for(var i=0;i<this.ckList.length;i++){
          if(this.ckList[i].SNOWID==e){
            this.logInInfo.STORAGENAME = this.ckList[i].NAME
            break;
          }
        }
      }
    },
    getCKList(){
        var params={
            PAGEINDEX:1,
            PAGESIZE:500,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Storage/GetStorageList", md5_data)
            .then((response) => {
                if (response.data.SUCCESS) {
                    var list =response.data.DATA
                    list.push({SNOWID:'1636549548467949568',NAME:'围屏二级仓库',FACTORYSNOWID:"1555437816215900160"})
                    list.push({SNOWID:'1745264458265137152',NAME:'高桥二级仓库',FACTORYSNOWID:"1555428784377827328"})
                    this.ckList=list
                } 
            })
    },

    getPostList(){
        this.showLoading("请稍后")
        var tid=""
        if(this.wzhpfl.length>0){
            tid = this.wzhpfl[this.wzhpfl.length-1]
        }
        var params={
            PAGEINDEX:this.PageIndex,
            PAGESIZE:this.PageSize,
            CNNAME:this.cname,
            ENNAME:this.ename,
            CODE:this.ecode,
            GGMODEL:"",
            SUPPLIERID:this.gysid,
            THINGTYPEID:tid,
            STORAGEID:this.ckid,
            MANAGER:"",
            STORAGENAME:"",
            FACTORYSNOWID:[],
            HASINRECORD:null,
            HASOUTRECORD:null,
            ISSELECTALL:true,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Storage/GetStorageThingList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
        });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    addPost(){
        this.postInfo={
            ENNAME:"",
            CODE:"",
            GGMODEL:"",
            CNNAME:"",
            SUPPLIERID:"",
            THINGTYPEID:"",
            UNIT:"",
            STANDARDPRICE:"",
            CURRCOUNT:"",
            BZ:"",
            MANAGER:"",
            MANAGERMOBILE:"",
            STORAGEID:"",
            STORAGENAME:"",
        }
        this.doTypeStr="添加库存"
        this.doType="1"
        this.postModal=true
    },
    sendAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("Storage/CreateNewStorageThing", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    editPost(item){
        var tids=[]
        if(item.STORAGETHINGTYPELIST && item.STORAGETHINGTYPELIST.length>0){
            for(var i=0;i<item.STORAGETHINGTYPELIST.length;i++){
                tids.push(item.STORAGETHINGTYPELIST[i].SNOWID)
            }
        }
        this.postInfo={
            SNOWID:item.SNOWID,
            ENNAME:item.ENNAME,
            CODE:item.CODE,
            GGMODEL:item.GGMODEL,
            CNNAME:item.CNNAME,
            SUPPLIERID:item.SUPPLIERID,
            THINGTYPEID:tids,
            UNIT:item.UNIT,
            STANDARDPRICE:item.STANDARDPRICE,
            CURRCOUNT:item.CURRCOUNT,
            BZ:item.BZ,
            MANAGER:item.MANAGER,
            MANAGERMOBILE:item.MANAGERMOBILE,
            STORAGEID:item.STORAGEID,
            STORAGENAME:item.STORAGENAME,
        }
        this.doTypeStr="修改库存"
        this.doType="2"
        this.postModal=true
    },
    sendEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("Storage/EditStorageThing", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.postModal=false
                    this.getPostList();
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    delPost(item){
        if(this.canDo){
            this.$confirm('确定删除库存：'+item.CNNAME+'？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
            this.sendDel(item.SNOWID)
            }).catch(() => {
            this.$message.info("已取消")    
          });
        }

    },
    sendDel(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({SNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:id})).toUpperCase())
        this.$http.post("Storage/DeleteStorageThing", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    submitPostForm() {
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    var tid=this.postInfo.THINGTYPEID[this.postInfo.THINGTYPEID.length-1]
                    this.postInfo.THINGTYPEID=tid
                    if(this.doType=="1"){
                        this.sendAdd()
                    }else if(this.doType=="2"){
                        this.sendEdit()
                    }
                } else {
                    return false;
                }
            });
        }
    },


    //公司入库 
    inPost(item){
      this.logItem=item
      this.logTitleStr=item.CNNAME+"-公司入库记录"
      this.logInList=[]
      this.PageIndexLogIn=1
      this.PageSizeLogIn=10
      this.TotalLogIn=0
      this.logInListModal=true
      this.logInDate=[]
      this.getInLogList()
    },
    searchInPost(){
      this.PageIndexLogIn=1
      this.getInLogList()
    },
    getInLogList(){
       this.showLoading("请稍后")
       var st=""
       var et=""
       if(this.logInDate.length==2){
        st = this.comjs.getDateMinStrByDate(this.logInDate[0])
        et = this.comjs.getDateMinStrByDate(this.logInDate[1])
       }
        var params={
            PAGEINDEX:this.PageIndexLogIn,
            PAGESIZE:this.PageSizeLogIn,
            THINGSNOWID:this.logItem.SNOWID,
            BEGINDATE:st,
            ENDDATE:et,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post('Storage/GetStorageInList', md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.logInList=response.data.DATA
                this.TotalLogIn=response.data.TOTALCOUNT
            } else {
                this.logInList=[]
                this.TotalLogIn=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.logInList=[]
                this.TotalLogIn=0
        });
    },
    PGChangeLogIn(e){
        this.PageIndexLogIn=e
        this.getInLogList()
    },
    addInLog(){
        this.doTypeStrLog=this.logItem.CNNAME+"-新增入库记录"
        this.logInModal=true
        this.logInInfo={
          THINGID:this.logItem.SNOWID,
          SUMCOUNT:"",
          BZ:"",
          CONTRACTSNOWID:"",
          STORAGEPOINT:"",
        }
        
    },
    submitLogInForm(){
      if (this.canDo) {
            this.$refs.logInForm.validate((valid) => {
                if (valid) {
                    this.sendInLog()
                } else {
                    return false;
                }
            });
        }
    },
    sendInLog(){
      this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.logInInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.logInInfo)).toUpperCase())
        this.$http.post("Storage/CreateNewStorageIn", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.logInModal=false
                    this.getInLogList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },

    //公司出库
    OutPost(item){
      this.logItem=item
      this.logTitleStr=item.CNNAME+"-公司出库记录"
      this.logOutList=[]
      this.PageIndexLogOut=1
      this.PageSizeLogOut=10
      this.TotalLogOut=0
      this.logOutListModal=true
      this.logOutDate=[]
      this.getOutLogList()
    },
    searchOutPost(){
      this.PageIndexLogOut=1
      this.getOutLogList()
    },
    getOutLogList(){
       this.showLoading("请稍后")
       var st=""
       var et=""
       if(this.logOutDate.length==2){
        st = this.comjs.getDateMinStrByDate(this.logOutDate[0])
        et = this.comjs.getDateMinStrByDate(this.logOutDate[1])
       }
        var params={
            PAGEINDEX:this.PageIndexLogOut,
            PAGESIZE:this.PageSizeLogOut,
            THINGSNOWID:this.logItem.SNOWID,
            BEGINDATE:st,
            ENDDATE:et,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post('Storage/GetStorageOutList', md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.logOutList=response.data.DATA
                this.TotalLogOut=response.data.TOTALCOUNT
            } else {
                this.logOutList=[]
                this.TotalLogOut=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.logOutList=[]
                this.TotalLogOut=0
        });
    },
    PGChangeLogOut(e){
        this.PageIndexLogOut=e
        this.getOutLogList()
    },
    addOutLog(){
        this.doTypeStrLog=this.logItem.CNNAME+"-新增公司出库记录"
        this.logOutModal=true
        this.rkfc=""
        this.logOutInfo={
          THINGSNOWID:this.logItem.SNOWID,
          OUTCOUNT:"",
          STORAGESNOWID:"",
          MACHINESNOWID:"",
          STORAGEPOINT:"",
          DELIVERYPERSON:"",
          REMARK:"",
          MANAGERSNOWID:"",
          MANAGERMOBILE:"",
          OUTUSERSNOWID:"",
        }
        
    },
    submitLogOutForm(){
      if (this.canDo) {
            this.$refs.logOutForm.validate((valid) => {
                if (valid) {
                    this.sendOutLog()
                } else {
                    return false;
                }
            });
        }
    },
    sendOutLog(){
      this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.logOutInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.logInInfo)).toUpperCase())
        this.$http.post("Storage/CreateNewStorageOut", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.logOutModal=false
                    this.getOutLogList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },



    //风场入库查询
    inPostFC(item){
      this.logItem=item
      this.logTitleStr=item.CNNAME+"-风场入库记录"
      this.logInListFC=[]
      this.PageIndexLogInFC=1
      this.PageSizeLogInFC=10
      this.TotalLogInFC=0
      this.logInDateFC=[]
      this.logInListModalFC=true
      this.getInLogListFC()
    },
    searchInPostFC(){
      this.PageIndexLogInFC=1
      this.getInLogListFC()
    },
    getInLogListFC(){
       this.showLoading("请稍后")
       var st=""
       var et=""
       if(this.logInDateFC.length==2){
        st = this.comjs.getDateMinStrByDate(this.logInDateFC[0])
        et = this.comjs.getDateMinStrByDate(this.logInDateFC[1])
       }
        var params={
            PAGEINDEX:this.PageIndexLogInFC,
            PAGESIZE:this.PageSizeLogInFC,
            THINGSNOWID:this.logItem.SNOWID,
            BEGINDATE:st,
            ENDDATE:et,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post('Storage/GetStorageInList', md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.logInListFC=response.data.DATA
                this.TotalLogInFC=response.data.TOTALCOUNT
            } else {
                this.logInListFC=[]
                this.TotalLogInFC=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.logInListFC=[]
                this.TotalLogInFC=0
        });
    },
    PGChangeLogInFC(e){
        this.PageIndexLogInFC=e
        this.getInLogListFC()
    },


    //风场出库查询
    OutPostFC(item){
      this.logItem=item
      this.logTitleStr=item.CNNAME+"-风场出库记录"
      this.logOutListFC=[]
      this.PageIndexLogOutFC=1
      this.PageSizeLogOutFC=10
      this.TotalLogOutFC=0
      this.logOutDateFC=[]
      this.logOutListModalFC=true
      this.getOutLogListFC()
    },
    searchOutPostFC(){
      this.PageIndexLogOutFC=1
      this.getOutLogListFC()
    },
    getOutLogListFC(){
       this.showLoading("请稍后")
       var st=""
       var et=""
       if(this.logOutDateFC.length==2){
        st = this.comjs.getDateMinStrByDate(this.logOutDateFC[0])
        et = this.comjs.getDateMinStrByDate(this.logOutDateFC[1])
       }
        var params={
            PAGEINDEX:this.PageIndexLogOutFC,
            PAGESIZE:this.PageSizeLogOutFC,
            THINGSNOWID:this.logItem.SNOWID,
            BEGINDATE:st,
            ENDDATE:et,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post('Storage/GetFactoryStorageOutList', md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.logOutListFC=response.data.DATA
                this.TotalLogOutFC=response.data.TOTALCOUNT
            } else {
                this.logOutListFC=[]
                this.TotalLogOutFC=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.logOutListFC=[]
                this.TotalLogOutFC=0
        });
    },
    PGChangeLogOutFC(e){
        this.PageIndexLogOutFC=e
        this.getOutLogListFC()
    },
    
    addOutLogFC(){
        this.getRKJL()
        this.doTypeStrLog=this.logItem.CNNAME+"-新增风场出库记录"
        this.logOutModalFC=true
        this.logOutInfoFC={
          INSTORAGESNOWID:"",
          OUTCOUNT:"",
          OUTUSERSNOWID:"",
          REMARK:"",
          DELIVERYPERSON:"",
        }
    },
    getRKJL(){
      var params={
            PAGEINDEX:1,
            PAGESIZE:10000,
            THINGSNOWID:this.logItem.SNOWID,
            BEGINDATE:"",
            ENDDATE:"",
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post('Storage/GetStorageInList', md5_data)
            .then((response) => {
            this.hideLoading()
              if (response.data.SUCCESS) {
                  
                  var nl=[]
                  var list=response.data.DATA
                  for(var i=0;i<list.length;i++){
                    var d=list[i]
                    if(d.INSTORAGERESTCOUNT>0){
                      d.NAME='备注：'+d.BZ+" 入库数量："+d.SUMCOUNT+" 入库单剩余数量："+d.INSTORAGERESTCOUNT
                      nl.push(d)
                    }

                  }
                  this.rkjlList=nl
              }
            })
    },
    submitLogOutFormFC(){
      if (this.canDo) {
            this.$refs.logOutFormFC.validate((valid) => {
                if (valid) {
                    this.sendOutLogFC()
                } else {
                    return false;
                }
            });
        }
    },
    sendOutLogFC(){
      this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.logOutInfoFC, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.logInInfoFC)).toUpperCase())
        this.$http.post("Storage/CreateFactoryStorageOut", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.logOutModalFC=false
                    this.getOutLogListFC()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
  },
  computed: {
  },
  watch: {
    
  },
};
</script>
<style scoped>
  .logItemTxt{font-size: 14px;margin-right: 20px;}
</style>