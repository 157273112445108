<template>
    <div class="views-main">
        <div class="views-main-title">
            <el-button type="primary" icon="el-icon-arrow-left" style="float:left;margin-right:20px;" size="small" @click="backBBTZ">返回</el-button>
            <p class="txt">专业设备全寿命周期管理</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                <el-input v-model="mechineName" placeholder="请输入名称" class="iw" ></el-input>
                <el-select v-model="mechineType" placeholder="设备类型"  filterable  clearable class="iw">
                        <el-option  label="风机专业设备" value="1" ></el-option>
                        <el-option  label="电气专业设备" value="2" ></el-option>
                </el-select>
                <el-select v-model="mechineBookType" placeholder="台账分类"  filterable  clearable class="iw">
                    <el-option v-for="item in bookTypeList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                </el-select>
                <el-select v-model="factoryId" placeholder="风场"  filterable  clearable class="iw">
                    <el-option v-for="item in factoryList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                </el-select>
                <el-button type="primary" @click="searchMechine">查询</el-button>
                <el-button type="warning" @click="exportTable" class="fr">导出</el-button>
                <el-button type="success" @click="addMechine" class="fr">添加</el-button>
                <el-button type="success" @click="bookTypeManage" class="fr">台账分类管理</el-button>
            </div>
            <div class="views-main-body">
                <el-collapse v-model="nowCheckmechineId" @change="handleChangeMechine" :accordion="true">
                    <el-collapse-item  v-for="mitem in mechineList" :key="mitem.SNOWID" :name="mitem.SNOWID">
                        <template slot="title">
                            <div class="mechineInfoRow">设备名称：{{mitem.NAME}}　　类型：<span v-if="mitem.LIFETYPE==1">风机专业设备</span><span v-else>电气专业设备</span>　　分类：{{mitem.DeviceType}}</div>
                            <div class="mechineBtnRow">
                                <el-button type="text" style="color:#E6A23C" @click="editMechine(mitem)">编辑</el-button>
                                <el-button type="text" style="color:#F56C6C" @ckick="delMechine(mitem)">删除</el-button>
                            </div>
                        </template>
                        <div class="unitListBox">
                            <div class="unitRow">
                                <div class="unitListTitle">部件列表：</div>
                                <div v-if="unitList.length==0" class="unitNull">暂无部件，请点击右侧按钮添加</div>
                                <div v-else class="unitRowItem" :class="nowCheckUnitId == uitem.SNOWID?'unitRowItemOn':''" v-for="uitem in unitList" :key="uitem.SNOWID" @click="selectUnitItem(uitem)">{{uitem.NAME}}</div>
                                <el-button type="danger" size="mini" @click="delUnit()" style="float:right;" v-if="nowCheckUnitId!=''">删除部件</el-button>
                                <el-button type="warning" size="mini" @click="editUnit()" style="float:right;margin-right:12px;" v-if="nowCheckUnitId!=''">编辑部件</el-button>
                                <el-button type="success" size="mini" @click="addUnit()" style="float:right;">添加部件</el-button>
                            </div>
                            <div class="partListBox" v-if="nowCheckUnitId!=''" style="background-color:#FFFFFF;padding:12px 12px 0 12px;">
                                <div style="width:100%;overflow:hidden;margin-bottom:20px;">
                                    <div class="partTitle">零件列表</div>
                                    <el-input v-model="partName" placeholder="请输入名称" class="iww fl" size="mini"></el-input>
                                    <el-button type="primary" @click="searchPart()" class="fl" size="mini">查询</el-button>
                                    <!-- <el-button type="success" @click="addPart()" class="fl" size="mini">添加</el-button> -->
                                    <el-button type="success" @click="addPartInRow()" class="fl" size="mini">添加</el-button>
                                    <el-button type="success" @click="refPartRow()" class="fl" size="mini">刷新</el-button>
                                </div>
                                <el-table :data="partList" stripe style="width: 100%" size="small">
                                    <!-- <el-table-column prop="ComponentName" label="所属部件"></el-table-column> -->
                                    <el-table-column label="零件名称" width="140">
                                        <template slot-scope="scope">
                                            <el-input v-model="scope.row.NAME" @change="partInputChange(scope.row,'NAME')" size="mini"></el-input>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="部件品牌" width="240">
                                        <template slot-scope="scope">
                                            <el-input v-model="scope.row.BRAND" @change="partInputChange(scope.row,'BRAND')" size="mini"></el-input>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="部件型号" width="160">
                                        <template slot-scope="scope">
                                            <el-input v-model="scope.row.TYPE" @change="partInputChange(scope.row,'TYPE')" size="mini"></el-input>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="部件数量" width="70">
                                        <template slot-scope="scope">
                                            <el-input v-model="scope.row.COUNT" @change="partInputChange(scope.row,'COUNT')" size="mini"></el-input>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="部件供应商" width="200">
                                        <template slot-scope="scope">
                                            <el-input v-model="scope.row.SUPPLIER" @change="partInputChange(scope.row,'SUPPLIER')" size="mini"></el-input>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="预期寿命指标名称" width="120">
                                        <template slot-scope="scope">
                                            <el-input v-model="scope.row.EXCEPTLIFENAME" @change="partInputChange(scope.row,'EXCEPTLIFENAME')" size="mini"></el-input>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="预期寿命指标数值" width="120">
                                        <template slot-scope="scope">
                                            <el-input v-model="scope.row.EXCEPTLIFECOUNT" @change="partInputChange(scope.row,'EXCEPTLIFECOUNT')" size="mini"></el-input>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="预期寿命指标单位" width="120">
                                        <template slot-scope="scope">
                                            <el-input v-model="scope.row.EXCEPTUNIT" @change="partInputChange(scope.row,'EXCEPTUNIT')" size="mini"></el-input>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="投运(更换)日期" width="150">
                                        <template slot-scope="scope">
                                            <el-date-picker v-model="scope.row.OPERATIONDATE" type="date" placeholder="选择日期" style="width:100%" @change="partInputChange(scope.row,'OPERATIONDATE')" size="mini"></el-date-picker>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="当前日期" width="150">
                                        <template slot-scope="scope">
                                            <p v-if="scope.row.CURRENTDATE && scope.row.CURRENTDATE!=''">
                                                {{scope.row.CURRENTDATE}}
                                            </p>
                                            <p v-else>/</p>
                                            <!-- <el-date-picker v-model="scope.row.CURRENTDATE" type="date" placeholder="无需填写" style="width:100%" @change="partInputChange(scope.row,'CURRENTDATE')" size="mini" readonly></el-date-picker> -->
                                        </template>
                                    </el-table-column>
                                    <el-table-column  label="当前寿命指标数值" width="120">
                                        <template slot-scope="scope">
                                            <p v-if="scope.row.CURRENTLIFECOUNT && scope.row.CURRENTLIFECOUNT!=''">
                                                {{scope.row.CURRENTLIFECOUNT}}
                                            </p>
                                            <p v-else>/</p>
                                            <!-- <el-input v-model="scope.row.CURRENTLIFECOUNT" @change="partInputChange(scope.row,'CURRENTLIFECOUNT')" size="mini" placeholder="无需填写" readonly></el-input> -->
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="当前寿命指标单位" width="120">
                                        <template slot-scope="scope">
                                            <el-input v-model="scope.row.CURRENTUNIT" @change="partInputChange(scope.row,'CURRENTUNIT')" size="mini"></el-input>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="当前寿命进程（百分比）" width="160">
                                        <template slot-scope="scope">
                                            <el-input v-model="scope.row.CURRENTLIFPROCESS" @change="partInputChange(scope.row,'CURRENTLIFPROCESS')" size="mini"></el-input>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="历史预期寿命数值" width="120">
                                        <template slot-scope="scope">
                                            <el-input v-model="scope.row.HISTORYEXCEPTLIFECOUNT" @change="partInputChange(scope.row,'HISTORYEXCEPTLIFECOUNT')" size="mini"></el-input>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="历史实际寿命数值" width="120">
                                        <template slot-scope="scope">
                                            <p v-if="scope.row.HISTORYREALLIFECOUNT && scope.row.HISTORYREALLIFECOUNT!=''">
                                                {{scope.row.HISTORYREALLIFECOUNT}}
                                            </p>
                                            <p v-else>/</p>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="历史寿命指标单位" width="120">
                                        <template slot-scope="scope">
                                            <el-input v-model="scope.row.HISTORYUNIT" @change="partInputChange(scope.row,'HISTORYUNIT')" size="mini"></el-input>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="历史实际寿命进程（百分比）" width="180">
                                        <template slot-scope="scope">
                                            <!-- <el-input v-model="scope.row.HISTORYLIFEPROCESS" @change="partInputChange(scope.row,'HISTORYLIFEPROCESS')" size="mini"></el-input> -->
                                            <p v-if="scope.row.REALLIFEPROCESS && scope.row.REALLIFEPROCESS!=''">
                                                {{scope.row.REALLIFEPROCESS}}
                                            </p>
                                            <p v-else>/</p>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="操作" width="150" fixed="right">
                                        <template slot-scope="scope" >
                                            <!-- <el-button @click="editPart(scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button> -->
                                            <el-button @click="addPartRow(scope.row)"  type="text" size="small" style="color:#67C23A"  v-if="scope.row.noSend">提交</el-button>
                                            <el-button @click="cancelPartRow(scope.row)"  type="text" size="small" style="color:#F56C6C"  v-if="scope.row.noSend">取消</el-button>
                                            <el-button @click="delPart(scope.row)"  type="text" size="small" style="color:#F56C6C" v-if="!scope.row.isAdd">删除</el-button>
                                            <el-button @click="copyPart(scope.row)"  type="text" size="small" style="color:#67C23A" v-if="!scope.row.isAdd">复制</el-button>
                                            <el-button @click="workPart(scope.row)"  type="text" size="small" style="color:#409EFF;" v-if="!scope.row.isAdd">维修记录</el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                                <el-pagination background layout="total, prev, pager, next"  :current-page="partPageIndex" :page-size="partPageSize" :total="partTotal" class="pg" style="margin-bottom:20px;" @current-change="partChange"></el-pagination>
                            </div>
                        </div>
                    </el-collapse-item>
                </el-collapse>
            </div>
        </div>
        <!--台账分类-->
        <el-dialog title="台账分类列表" :visible.sync="bookTypeListModal" width="800px" top="30px" append-to-body :close-on-click-modal="false">
            <div style="width:100%;overflow:hidden;margin-bottom:20px;">
                <el-input v-model="bookTypeName" placeholder="请输入名称" class="iww fl" ></el-input>
                <el-button type="primary" @click="getBookTypeList()" class="fl">查询</el-button>
                <el-button type="success" @click="addBookType()" class="fl">添加</el-button>
            </div>
            <el-table :data="bookTypeList" stripe style="width: 100%" :height="dialogMaxHeigh">
                <el-table-column prop="NAME" label="分类名称"></el-table-column>
                <el-table-column prop="FactoryName" label="风场名称"></el-table-column>
                <el-table-column label="操作" width="180">
                    <template slot-scope="scope">
                        <el-button @click="editBookType(scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button>
                        <el-button @click="delBookType(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>
        <el-dialog :title="bookTypeDoTypeStr" :visible.sync="bookTypeModal" width="800px" append-to-body :close-on-click-modal="false">
            <el-form :model="bookTypeInfo" status-icon :rules="bookTyperules"  ref="tForm" label-width="120px" class="demo-uForm">
                <el-form-item label="分类名称" prop="Name">
                    <el-input v-model="bookTypeInfo.Name" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="风场" prop="FACTORYSNOWID">
                    <el-select v-model="bookTypeInfo.FACTORYSNOWID" placeholder="请选择风场" filterable  style="width:100%;">
                        <el-option v-for="item in factoryList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitBookTypeForm()">提交</el-button>
                    <el-button @click="bookTypeModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <!--设备-->
        <el-dialog :title="mechineDoTypeStr" :visible.sync="mechineModal" width="800px" append-to-body :close-on-click-modal="false">
            <el-form :model="mechineInfo" status-icon :rules="mechinerules"  ref="mForm" label-width="120px" class="demo-uForm">
                <el-form-item label="名称" prop="Name">
                    <el-input v-model="mechineInfo.Name" autocomplete="off"></el-input>
                </el-form-item> 
                <el-form-item label="类型">
                    <el-radio v-model="mechineInfo.LifeType" label="1">风机专业设备</el-radio>
                    <el-radio v-model="mechineInfo.LifeType" label="2">电气专业设备</el-radio>
                </el-form-item>
                <el-form-item label="分类" prop="DeviceTypeSnowID">
                    <el-select v-model="mechineInfo.DeviceTypeSnowID" placeholder="台账分类"  filterable  style="width:100%">
                        <el-option v-for="item in bookTypeList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitMechineForm()">提交</el-button>
                    <el-button @click="mechineModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <!--部件-->
        <el-dialog :title="unitDoTypeStr" :visible.sync="unitModal" width="800px" append-to-body :close-on-click-modal="false">
            <el-form :model="unitInfo" status-icon :rules="unitrules"  ref="bForm" label-width="120px" class="demo-uForm">
                <el-form-item label="名称" prop="Name">
                    <el-input v-model="unitInfo.Name" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitUnitForm()">提交</el-button>
                    <el-button @click="unitModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <!--零件-->
        <el-dialog :title="partDoTypeStr" :visible.sync="partModal" width="800px" append-to-body :close-on-click-modal="false" top="30px">
            <el-form :model="partInfo" status-icon :rules="partrules"  ref="pForm" label-width="200px" class="demo-uForm">
                <el-form-item label="名称" prop="NAME">
                    <el-input v-model="partInfo.NAME" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="部件品牌">
                    <el-input v-model="partInfo.BRAND" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="部件型号">
                    <el-input v-model="partInfo.TYPE" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="部件数量" prop="NUM">
                    <el-input v-model="partInfo.COUNT" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="部件供应商">
                    <el-input v-model="partInfo.SUPPLIER" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="预期寿命指标名称">
                    <el-input v-model="partInfo.EXCEPTLIFENAME" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="预期寿命指标数值" prop="NUM">
                    <el-input v-model="partInfo.EXCEPTLIFECOUNT" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="预期寿命指标单位" prop="NUM">
                    <el-input v-model="partInfo.EXCEPTUNIT" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="投运(更换)日期">
                    <el-date-picker v-model="partInfo.OPERATIONDATES" type="date" placeholder="选择日期" style="width:100%"></el-date-picker>
                </el-form-item>
                <!-- <el-form-item label="当前日期">
                    <el-date-picker v-model="partInfo.CURRENTDATES" type="date" placeholder="后台计算无需填写" style="width:100%" readonly></el-date-picker>
                </el-form-item>
                <el-form-item label="当前寿命指标数值">
                    <el-input v-model="partInfo.CURRENTLIFECOUNT" autocomplete="off" placeholder="后台计算无需填写" readonly></el-input>
                </el-form-item> -->
                <el-form-item label="当前寿命指标单位">
                    <el-input v-model="partInfo.CURRENTUNIT" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="当前寿命进程（百分比）" prop="NUM">
                    <el-input v-model="partInfo.CURRENTLIFPROCESS" autocomplete="off"></el-input>
                </el-form-item>

                <el-form-item label="历史预期寿命数值" prop="NUM">
                    <el-input v-model="partInfo.HISTORYEXCEPTLIFECOUNT" autocomplete="off"></el-input>
                </el-form-item>
                <!-- <el-form-item label="历史实际寿命数值" prop="NUM">
                    <el-input v-model="partInfo.HISTORYLIFECOUNT" autocomplete="off"></el-input>
                </el-form-item> -->
                <el-form-item label="历史寿命指标单位">
                    <el-input v-model="partInfo.HISTORYUNIT" autocomplete="off"></el-input>
                </el-form-item>
                <!-- <el-form-item label="历史实际寿命进程（百分比）" prop="NUM">
                    <el-input v-model="partInfo.HISTORYLIFEPROCESS" autocomplete="off"></el-input>
                </el-form-item> -->

                <el-form-item>
                    <el-button type="primary" @click="submitPartForm()">提交</el-button>
                    <el-button @click="partModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <!--维修记录-->
        <el-dialog :title="workListModalName" :visible.sync="workListModal" width="800px" top="100px" append-to-body :close-on-click-modal="false">
            <div class="workAddBox">
                <el-select v-model="workUser" placeholder="选择人员"  filterable  clearable class="iws fl">
                    <el-option v-for="item in userList" :key="item.SNOWID" :label="item.TRUENAME+'('+item.LOGINNAME+')'" :value="item.SNOWID" ></el-option>
                </el-select>
                <el-button type="primary" @click="searchWork()" class="fl">查询</el-button>
                <el-button type="success" @click="addWork()" class="fr">添加维修记录</el-button>
            </div>
            <div class="workItem" v-for="item in workList" :key="item.SNOWID">
                {{item.USERNAME}}({{item.CREATETIME}})
                <div class="close" @click="delWork(item.SNOWID)">×</div>
            </div>
            <div class="workListNull" v-if="workList.length==0">暂无维修记录</div>
        </el-dialog>
        <!--导出台账-->
        <el-dialog title="导出台账" :visible.sync="exportModal" width="600px"  append-to-body :close-on-click-modal="false">
            <el-form :model="exportInfo" status-icon :rules="exportrules"  ref="exportForm" label-width="120px" class="demo-uForm">
                <el-form-item label="风场" prop="factorySnowID">
                    <el-select v-model="exportInfo.factorySnowID" placeholder="请选择风场" filterable  style="width:100%;">
                        <el-option v-for="item in factoryList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitExportForm()">提交</el-button>
                    <el-button @click="exportModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
var h=document.body.clientHeight
export default {
  name: "PostManage",
  data() {
    var Name = (rule, value, callback) => {
        if (value === "") {
        callback(new Error("请输入名称"));
      } else {
        callback();
      }
    };
    var NAME = (rule, value, callback) => {
        if (value === "") {
        callback(new Error("请输入名称"));
      } else {
        callback();
      }
    };
    var FACTORYSNOWID=(rule, value, callback) => {
        if (value === "") {
        callback(new Error("请选择风场"));
      } else {
        callback();
      }
    };
    var NUM=(rule, value, callback) => {
         if(value != '' && typeof value != 'number' && isNaN(value)){
             callback(new Error('内容只能是数字'));
        }else {
          callback();
        }
    };
    var DeviceTypeSnowID=(rule, value, callback) => {
        if (value === "") {
        callback(new Error("请选择台账分类"));
      } else {
        callback();
      }
    };

    var factorySnowID=(rule, value, callback) => {
        if (value === "") {
        callback(new Error("请选择风场"));
      } else {
        callback();
      }
    };
    return {
        canDo:true,
        
        dialogMaxHeigh:0,
        //风场
        factoryId:"",
        factoryList:[],

        //台账类型管理
        bookTypeListModal:false,
        bookTypeList:[],
        bookTypeName:"",
        bookTypeDoTypeStr:"",
        bookTypeDoType:"",
        bookTypeModal:false,
        bookTypeInfo:{},
        bookTyperules: {
            Name: [{ validator: Name, trigger: "blur" }],
            FACTORYSNOWID:[{ validator: FACTORYSNOWID, trigger: "blur" }],
        },

        //设备管理
        mechineListModal:false,
        mechineList:[],
        mechineName:"",
		mechineType:"",
        mechineBookType:"",
        mechinePageIndex:1,
        mechinePageSize:10,
        mechineTotal:0,
        mechineDoTypeStr:"",
        mechineDoType:"",
        mechineModal:false,
        mechineInfo:{},
        mechinerules: {
            Name: [{ validator: Name, trigger: "blur" }],
            DeviceTypeSnowID: [{ validator: DeviceTypeSnowID, trigger: "blur" }],
        },
        //部件管理
        nowCheckmechineId:"",
        nowCheckmechine:null,
        unitListModalName:"",
        unitListModal:false,
        unitList:[],
        unitName:"",
        unitPageIndex:1,
        unitPageSize:10,
        unitTotal:0,
        unitDoTypeStr:"",
        unitDoType:"",
        unitModal:false,
        unitInfo:{},
        unitrules: {
            Name: [{ validator: Name, trigger: "blur" }],
        },

        //零件管理
        nowCheckUnitId:"",
        nowCheckUnit:null,
        partListModalName:"",
        unitId:"",
        partName:"",
        partListModal:false,
        partList:[],
        partPageIndex:1,
        partPageSize:10,
        partTotal:0,
        partDoTypeStr:"",
        partDoType:"",
        partModal:false,
        partInfo:{},
        partrules: {
            NAME: [{ validator: NAME, trigger: "blur" }],
            NUM: [{ validator: NUM, trigger: "blur" }],
        },

        //维修记录管理
        userList:[],
        nowCheckPart:null,
        workListModal:false,
        workListModalName:"",
        workList:[],
        workUser:"",

        exportModal:false,
        exportInfo:{
            factorySnowID:"",
        },
        exportrules: {
            factorySnowID: [{ validator: factorySnowID, trigger: "blur" }],
        },
    
    };
  },
  mounted() {
    this.dialogMaxHeigh = h -270
    this.loadItem=null
    this.getFactoryList()
    this.getBookTypeList()
    this.mechineManage()
    this.getUserList()
  },
  methods: {
    //风场
    getFactoryList(){
        this.showLoading("请稍后")
        var params={
            Name:this.bookTypeName,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Factory/GetFactoryWithoutPage", md5_data)
            .then((response) => {
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.factoryList=response.data.DATA
                    this.exportInfo.factorySnowID=response.data.DATA[0].SNOWID
                }
            })
    },

    backBBTZ(){
        this.$router.replace('/XTPTZH')
    },
    /**
     * 台账分类管理
     */
    getBookTypeList(){
        this.showLoading("请稍后")
        var params={
            Name:this.bookTypeName,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("LifeManager/GetLifeDeviceTypeList", md5_data)
            .then((response) => {
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.bookTypeList=response.data.DATA
                } else {
                    this.bookTypeList=[]
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.hideLoading()
                this.bookTypeList=[]
            });
    },
    bookTypeManage(){
        this.getBookTypeList()
        this.bookTypeListModal=true
    },
    addBookType(){
        this.bookTypeInfo={
            Name:"",
            FACTORYSNOWID:"",
        }
        this.bookTypeModal=true
        this.bookTypeDoType="1"
        this.bookTypeDoTypeStr="添加台账分类"
    },
    sendAddBookType(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.bookTypeInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.bookTypeInfo)).toUpperCase())
        this.$http.post("LifeManager/CreateLifeDeviceType", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.bookTypeModal=false
                    this.getBookTypeList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
            });
    },
    editBookType(item){
        this.bookTypeInfo={
            SnowID:item.SNOWID,
            Name:item.NAME,
            FACTORYSNOWID:item.FACTORYSNOWID,
        }
        this.bookTypeDoTypeStr="修改台账分类"
        this.bookTypeDoType="2"
        this.bookTypeModal=true
    },
    sendEditBookType(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.bookTypeInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.bookTypeInfo)).toUpperCase())
        this.$http.post("LifeManager/UpdateLifeDeviceType", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.bookTypeModal=false
                    this.getBookTypeList();
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    submitBookTypeForm() {
        if (this.canDo) {
            this.$refs.tForm.validate((valid) => {
                if (valid) {
                    if(this.bookTypeDoType=="1"){
                        this.sendAddBookType()
                    }else if(this.bookTypeDoType=="2"){
                        this.sendEditBookType()
                    }
                } else {
                    return false;
                }
            });
        }
    },
    delBookType(item){
        if(this.canDo){
            this.$confirm('确定删除？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
            this.sendDelBookType(item.SNOWID)
            }).catch(() => {
            this.$message.info("已取消")    
          });
        }

    },
    sendDelBookType(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({SNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:id})).toUpperCase())
        this.$http.post("LifeManager/DeleteLifeDeviceType", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.getBookTypeList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },

    /**
     * 设备管理
    */
    handleChangeMechine(e){
        if(e!=""){
            var mlist=this.mechineList
            for(var i=0;i<mlist.length;i++){
                if(mlist[i].SNOWID ==e){
                    this.nowCheckmechine= mlist[i]
                    this.nowCheckUnitId=""
                    this.getUnitList()
                    break;
                }
            }
        }
    },
    mechineManage(){
        this.mechineListModal=true
        this.getMechineList()
    },
    getMechineList(){
        this.showLoading("请稍后")
        var params={
            PAGEINDEX:this.mechinePageIndex,
            PAGESIZE:this.mechinePageSize,
            Name:this.mechineName,
            Type:this.mechineType,
            DeviceTypeSnowID:this.mechineBookType,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("LifeManager/GetLifeDeviceList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                var list=response.data.DATA
                var nl=[]
                for(var i=0;i<list.length;i++){
                    let d=list[i]
                    d.unitList=[]
                    nl.push(d)
                }
                this.mechineList=nl
                this.mechineTotal=response.data.TOTALCOUNT
            } else {
                this.mechineList=[]
                this.mechineTotal=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.mechineList=[]
                this.mechineTotal=0
        });
    },
    searchMechine(){
        this.mechinePageIndex=1
        this.getMechineList()
    },
    mechineChange(e){
        this.mechinePageIndex=e
        this.getMechineList()
    },
    addMechine(){
        this.mechineInfo={
            Name:"",
            LifeType:"1",
            DeviceTypeSnowID:"",
        }
        this.mechineModal=true
        this.mechineDoType="1"
        this.mechineDoTypeStr="添加设备"
    },
    sendAddMechine(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.mechineInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.mechineInfo)).toUpperCase())
        this.$http.post("LifeManager/CreateLifeDevice", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.mechineModal=false
                    this.getMechineList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    editMechine(item){
        this.mechineInfo={
            SnowID:item.SNOWID,
            Name:item.NAME,
            LifeType:item.LIFETYPE.toString(),
            DeviceTypeSnowID:item.DEVICETYPESNOWID,
        }
        this.mechineDoTypeStr="修改设备"
        this.mechineDoType="2"
        this.mechineModal=true
    },
    sendEditMechine(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.mechineInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.mechineInfo)).toUpperCase())
        this.$http.post("LifeManager/UpdateLifeDevice", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.mechineModal=false
                    var mlist=this.mechineList
                    var nml=[]
                    for(var i=0;i<mlist.length;i++){
                        let d=mlist[i]
                        if(d.SNOWID == this.mechineInfo.SnowID){
                            d.NAME =  this.mechineInfo.Name
                            d.LIFETYPE = parseInt(this.mechineInfo.LifeType)
                            d.DEVICETYPESNOWID = this.mechineInfo.DeviceTypeSnowID
                            d.DeviceType=this.getDeviceTypeNameById(this.mechineInfo.DeviceTypeSnowID)
                        }
                        nml.push(d)
                    }
                    this.mechineList = nml
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    getDeviceTypeNameById(id){
        var str=""
        for(var i=0;i<this.bookTypeList.length;i++){
            if(this.bookTypeList[i].SNOWID == id){
                str=this.bookTypeList[i].NAME
                break;
            }
        }
        return str
    },
    delMechine(item){
        if(this.canDo){
            this.$confirm('确定删除？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
            this.sendDelMechine(item.SNOWID)
            }).catch(() => {
            this.$message.info("已取消")    
          });
        }

    },
    sendDelMechine(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({SNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:id})).toUpperCase())
        this.$http.post("LifeManager/DeleteLifeDevice", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    var mlist=this.mechineList
                    var nml=[]
                    for(var i=0;i<mlist.length;i++){
                        var d=mlist[i]
                        if(d.SNOWID != id){
                            nml.push(d)
                        }
                    }
                    this.mechineList=nml
                    if(this.nowCheckmechineId==id){
                        this.nowCheckmechineId=""
                    }
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    submitMechineForm() {
        if (this.canDo) {
            this.$refs.mForm.validate((valid) => {
                if (valid) {
                    if(this.mechineDoType=="1"){
                        this.sendAddMechine()
                    }else if(this.mechineDoType=="2"){
                        this.sendEditMechine()
                    }
                } else {
                    return false;
                }
            });
        }
    },

    /**
     * 部件管理
     */

    unitManage(item){
        this.nowCheckmechine=item
        this.unitListModalName=item.NAME+"-部件管理"
        this.getUnitList()
        this.unitListModal=true
    },
    getUnitList(){
        this.showLoading("请稍后")
        var params={
            // PAGEINDEX:this.unitPageIndex,
            // PAGESIZE:this.unitPageSize,
            PAGEINDEX:1,
            PAGESIZE:100,
            Name:this.unitName,
            DeviceSnowID: this.nowCheckmechine.SNOWID,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("LifeManager/GetLifeComponentList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.unitList=response.data.DATA
                this.unitTotal=response.data.TOTALCOUNT
            } else {
                this.unitList=[]
                this.unitTotal=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.unitList=[]
                this.unitTotal=0
        });
    },
    searchUnit(){
        this.unitPageIndex=1
        this.getUnitList()
    },
    unitChange(e){
        this.unitPageIndex=e
        this.getUnitList()
    },
    addUnit(){
        this.unitInfo={
            Name:"",
            DEVICESNOWID: this.nowCheckmechine.SNOWID,
        }
        this.unitModal=true
        this.unitDoType="1"
        this.unitDoTypeStr=this.nowCheckmechine.NAME+"-添加部件"
    },
    sendAddUnit(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.unitInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.unitInfo)).toUpperCase())
        this.$http.post("LifeManager/CreateLifeComponent", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.unitModal=false
                    this.getUnitList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    editUnit(){
        this.unitInfo={
            SnowID:this.nowCheckUnit.SNOWID,
            Name:this.nowCheckUnit.NAME,
            DeviceSnowid:this.nowCheckUnit.DEVICESNOWID,
        }
        this.unitDoTypeStr="修改设备"
        this.unitDoType="2"
        this.unitModal=true
    },
    sendEditUnit(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.unitInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.unitInfo)).toUpperCase())
        this.$http.post("LifeManager/UpdateLifeComponent", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.unitModal=false
                    this.getUnitList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    delUnit(item){
        if(this.canDo){
            this.$confirm('确定删除？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
            this.sendDelUnit(this.nowCheckUnit.SNOWID)
            }).catch(() => {
            this.$message.info("已取消")    
          });
        }
    },
    sendDelUnit(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({SNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:id})).toUpperCase())
        this.$http.post("LifeManager/DeleteLifeComponent", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS){
                    this.$message.success("已删除")
                    this.getUnitList()
                    if(this.nowCheckUnitId == id){
                        this.nowCheckUnitId=""
                        this.nowCheckUnit=null
                        this.partList=[]
                        this.basePartList=[]
                    }
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true
            });
    },
    submitUnitForm() {
        if (this.canDo) {
            this.$refs.bForm.validate((valid) => {
                if (valid) {
                    if(this.unitDoType=="1"){
                        this.sendAddUnit()
                    }else if(this.unitDoType=="2"){
                        this.sendEditUnit()
                    }
                } else {
                    return false;
                }
            });
        }
    },

    /**
     * 零件管理
     */
    selectUnitItem(item){
        if(item.SNOWID != this.nowCheckUnitId){
            this.nowCheckUnit =item
            this.nowCheckUnitId=item.SNOWID
            this.searchPart()
        }
    },
    partManage(item){
        this.nowCheckUnit=item
        this.partListModalName=item.NAME+"-零件管理"
        this.getPartList()
        this.partListModal=true
    },
    getPartList(){
        this.showLoading("请稍后")
        var params={
            PAGEINDEX:this.partPageIndex,
            PAGESIZE:this.partPageSize,
            Name:this.partName,
            ComponentSnowID:this.nowCheckUnit.SNOWID,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("LifeManager/GetLifePartList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                var nlist=[]
                for(var i=0;i<response.data.DATA.length;i++){
                    let d=response.data.DATA[i]
                    d.isAdd=false
                    d.noSend=false
                    nlist.push(d)
                }
                this.partList=nlist
                this.partTotal=response.data.TOTALCOUNT
            } else {
                this.partList=[]
                this.partTotal=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.partList=[]
                this.partTotal=0
        });
    },
    searchPart(){
        this.partPageIndex=1
        this.getPartList()
    },
    partChange(e){
        this.partPageIndex=e
        this.getPartList()
    },
    addPart(){
        this.partInfo={
            COMPONENTSNOWID:this.nowCheckUnit.SNOWID,
            NAME:"",
            BRAND:"",
            TYPE:"",
            COUNT:"",
            SUPPLIER:"",
            EXCEPTLIFENAME:"",
            EXCEPTLIFECOUNT:"",
            EXCEPTUNIT:"",
            OPERATIONDATES:"",
            OPERATIONDATE:"",
            CURRENTDATES:"",
            CURRENTDATE:"",
            CURRENTLIFECOUNT:"",
            CURRENTUNIT:"",
            CURRENTLIFPROCESS:"",
            HISTORYEXCEPTLIFECOUNT:"",
            //HISTORYLIFECOUNT:"",
            HISTORYUNIT:"",
            //HISTORYLIFEPROCESS:"",
        }
        this.partModal=true
        this.partDoType="1"
        this.partDoTypeStr="添加零件"
    },
    copyPart(item){
        this.partInfo={
            NAME:item.NAME,
            COMPONENTSNOWID:item.COMPONENTSNOWID,
            BRAND:item.BRAND,
            TYPE:item.TYPE,
            COUNT:item.COUNT,
            SUPPLIER:item.SUPPLIER,
            EXCEPTLIFENAME:item.EXCEPTLIFENAME,
            EXCEPTLIFECOUNT:item.EXCEPTLIFECOUNT,
            EXCEPTUNIT:item.EXCEPTUNIT,
            OPERATIONDATES:new Date(item.OPERATIONDATE),
            OPERATIONDATE:item.OPERATIONDATE,
            CURRENTDATES:new Date(item.CURRENTDATE),
            CURRENTDATE:item.CURRENTDATE,
            CURRENTLIFECOUNT:item.CURRENTLIFECOUNT,
            CURRENTUNIT:item.CURRENTUNIT,
            CURRENTLIFPROCESS:item.CURRENTLIFPROCESS,
            HISTORYEXCEPTLIFECOUNT:item.HISTORYEXCEPTLIFECOUNT,
            //HISTORYLIFECOUNT:item.HISTORYLIFECOUNT,
            HISTORYUNIT:item.HISTORYUNIT,
            //HISTORYLIFEPROCESS:item.HISTORYLIFEPROCESS,
        }
        this.partDoTypeStr="复制零件"
        this.partDoType="1"
        this.partModal=true
    },
    sendAddPart(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.partInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.partInfo)).toUpperCase())
        this.$http.post("LifeManager/CreateLifePart", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.partModal=false
                    this.getPartList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    editPart(item){
        this.partInfo={
            SnowID:item.SNOWID,
            NAME:item.NAME,
            COMPONENTSNOWID:item.COMPONENTSNOWID,
            BRAND:item.BRAND,
            TYPE:item.TYPE,
            COUNT:item.COUNT,
            SUPPLIER:item.SUPPLIER,
            EXCEPTLIFENAME:item.EXCEPTLIFENAME,
            EXCEPTLIFECOUNT:item.EXCEPTLIFECOUNT,
            EXCEPTUNIT:item.EXCEPTUNIT,
            OPERATIONDATES:new Date(item.OPERATIONDATE),
            OPERATIONDATE:item.OPERATIONDATE,
            CURRENTDATES:new Date(item.CURRENTDATE),
            CURRENTDATE:item.CURRENTDATE,
            CURRENTLIFECOUNT:item.CURRENTLIFECOUNT,
            CURRENTUNIT:item.CURRENTUNIT,
            CURRENTLIFPROCESS:item.CURRENTLIFPROCESS,
            HISTORYEXCEPTLIFECOUNT:item.HISTORYEXCEPTLIFECOUNT,
            //HISTORYLIFECOUNT:item.HISTORYLIFECOUNT,
            HISTORYUNIT:item.HISTORYUNIT,
            //HISTORYLIFEPROCESS:item.HISTORYLIFEPROCESS,

        }
        this.partDoTypeStr="修改零件"
        this.partDoType="2"
        this.partModal=true
    },
    sendEditPart(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.partInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.partInfo)).toUpperCase())
        this.$http.post("LifeManager/UpdateLifePart", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.partModal=false
                    this.getPartList();
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    delPart(item){
        if(this.canDo){
            this.$confirm('确定删除？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                this.sendDelPart(item.SNOWID)
                }).catch(() => {
                this.$message.info("已取消")    
            });
        }
    },
    sendDelPart(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({SNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:id})).toUpperCase())
        this.$http.post("LifeManager/DeleteLifePart", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.getPartList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    submitPartForm() {
        if (this.canDo) {
            this.$refs.pForm.validate((valid) => {
                if (valid) {
                    if(this.partInfo.OPERATIONDATES && this.partInfo.OPERATIONDATES!=""){
                        this.partInfo.OPERATIONDATE=this.comjs.getDateMinStrByDate(this.partInfo.OPERATIONDATES) 
                    }
                    if(this.partInfo.CURRENTDATES && this.partInfo.CURRENTDATES!=""){
                        this.partInfo.CURRENTDATE=this.comjs.getDateMinStrByDate(this.partInfo.CURRENTDATES) 
                    }
                    if(this.partDoType=="1"){
                        this.sendAddPart()
                    }else if(this.partDoType=="2"){
                        this.sendEditPart()
                    }
                } else {
                    return false;
                }
            });
        }
    },
    refPartRow(){
        if(this.canDo){
            var noSend=false
            var pl=this.partList
            for(var i=0;i<pl.length;i++){
                if(pl[i].noSend){
                    noSend=true
                }
            }
            if(noSend){
                this.$confirm('列表中有待提交数据，确认刷新吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                    }).then(() => {
                        this.getPartList()
                    }).catch(() => {
                        this.$message.info("已取消")    
                    });
            }else{
                this.getPartList()
            }
        }
    },
    partInputChange(item,key){
        if(!item.isAdd){
            var  value = item[key]
            var canEdit=true
            if(key!="CURRENTDATE" && value == null ||  value ==""){
                this.$message.error("内容不能为空")
                canEdit=false
            }else if(key=='COUNT' || key=='EXCEPTLIFECOUNT' || key=='CURRENTLIFPROCESS' || key=='HISTORYEXCEPTLIFECOUNT' ){
                if(typeof value != 'number' && isNaN(value)){
                    this.$message.error("内容必须为数字")
                    canEdit=false
                }
            }

            if(canEdit){
                this.partInfo={
                    SnowID:item.SNOWID,
                    NAME:item.NAME,
                    COMPONENTSNOWID:item.COMPONENTSNOWID,
                    BRAND:item.BRAND,
                    TYPE:item.TYPE,
                    COUNT:item.COUNT,
                    SUPPLIER:item.SUPPLIER,
                    EXCEPTLIFENAME:item.EXCEPTLIFENAME,
                    EXCEPTLIFECOUNT:item.EXCEPTLIFECOUNT,
                    EXCEPTUNIT:item.EXCEPTUNIT,
                    CURRENTLIFECOUNT:item.CURRENTLIFECOUNT,
                    CURRENTUNIT:item.CURRENTUNIT,
                    CURRENTLIFPROCESS:item.CURRENTLIFPROCESS,
                    HISTORYEXCEPTLIFECOUNT:item.HISTORYEXCEPTLIFECOUNT,
                    //HISTORYLIFECOUNT:item.HISTORYLIFECOUNT,
                    HISTORYUNIT:item.HISTORYUNIT,
                    //HISTORYLIFEPROCESS:item.HISTORYLIFEPROCESS,
                }
                if(typeof item.CURRENTDATE === "object"){
                    this.partInfo.CURRENTDATE=this.comjs.getDateMinStrByDate(new Date(item.CURRENTDATE))
                }else{
                    this.partInfo.CURRENTDATE=item.CURRENTDATE
                }
                if(typeof item.OPERATIONDATE === "object") {
                    this.partInfo.OPERATIONDATE=this.comjs.getDateMinStrByDate(new Date(item.OPERATIONDATE))
                }else{
                    this.partInfo.OPERATIONDATE=item.OPERATIONDATE
                }
                this.sendEditPart()
            }else{
                this.getPartList()
            }
        }
    },
    addPartInRow(){
        var sid=new Date().getTime()
        var item ={
            SNOWID:sid.toString(),
            COMPONENTSNOWID:this.nowCheckUnit.SNOWID,
            NAME:"",
            BRAND:"",
            TYPE:"",
            COUNT:"",
            SUPPLIER:"",
            EXCEPTLIFENAME:"",
            EXCEPTLIFECOUNT:"",
            EXCEPTUNIT:"",
            OPERATIONDATE:"",
            CURRENTDATE:"",
            CURRENTLIFECOUNT:"",
            CURRENTUNIT:"",
            CURRENTLIFPROCESS:"",
            HISTORYEXCEPTLIFECOUNT:"",
            //HISTORYLIFECOUNT:"",
            HISTORYUNIT:"",
            //HISTORYLIFEPROCESS:"",
            isAdd:true,
            noSend:true,
        }
        if(this.nowCheckmechine.LIFETYPE && this.nowCheckmechine.LIFETYPE == 2){
            item.EXCEPTLIFENAME="运行时限"
            item.EXCEPTUNIT="小时"
            item.CURRENTUNIT="小时"
            item.HISTORYUNIT="小时"
        }
        this.partList.unshift(item)
    },
    cancelPartRow(item){
        var mpl=[]
        var pl=this.partList
        for(var i=0;i<pl.length;i++){
            if(pl[i].SNOWID!=item.SNOWID){
                mpl.push(pl[i])
            }
        }
        this.partList = mpl
    },
    addPartRow(item){
        if(this.canDo){
            var canSubmit=true
            var msg=""
            if(item.NAME == "" || item.NAME == null){
                msg="零件名称不能为空"
                canSubmit=false
            }else if(item.BRAND == "" || item.BRAND == null){
                msg="部件品牌不能为空"
                canSubmit=false
            }else if(item.TYPE == "" || item.TYPE == null){
                msg="部件型号不能为空"
                canSubmit=false
            }else if(item.COUNT == "" || item.COUNT == null){
                msg="部件数量不能为空"
                canSubmit=false
            }else if(item.COUNT != "" && typeof item.COUNT != 'number' && isNaN(item.COUNT)){
                msg="部件数量必须为数字"
                canSubmit=false
            }else if(item.SUPPLIER == "" || item.SUPPLIER == null){
                msg="部件供应商不能为空"
                canSubmit=false
            }else if(item.EXCEPTLIFENAME == "" || item.EXCEPTLIFENAME == null){
                msg="预期寿命指标名称不能为空"
                canSubmit=false
            }else if(item.EXCEPTLIFECOUNT == "" || item.EXCEPTLIFECOUNT == null){
                msg="预期寿命指标数值不能为空"
                canSubmit=false
            }else if(item.EXCEPTLIFECOUNT != "" && typeof item.EXCEPTLIFECOUNT != 'number' && isNaN(item.EXCEPTLIFECOUNT)){
                msg="预期寿命指标数值必须为数字"
                canSubmit=false
            }else if(item.EXCEPTUNIT == "" || item.EXCEPTUNIT == null){
                msg="预期寿命指标单位不能为空"
                canSubmit=false
            }else if(item.OPERATIONDATE == "" || item.OPERATIONDATE == null){
                msg="投运(更换)日期不能为空"
                canSubmit=false
            }else if(item.CURRENTUNIT == "" || item.CURRENTUNIT == null){
                msg="当前寿命指标单位不能为空"
                canSubmit=false
            }else if(item.CURRENTLIFPROCESS == "" || item.CURRENTLIFPROCESS == null){
                msg="当前寿命进程（百分比）不能为空"
                canSubmit=false
            }else if(item.CURRENTLIFPROCESS != "" && typeof item.CURRENTLIFPROCESS != 'number' && isNaN(item.CURRENTLIFPROCESS)){
                msg="当前寿命进程（百分比）必须为数字"
                canSubmit=false
            }else if(item.HISTORYEXCEPTLIFECOUNT == "" || item.HISTORYEXCEPTLIFECOUNT == null){
                msg="历史预期寿命数值不能为空"
                canSubmit=false
            }else if(item.HISTORYEXCEPTLIFECOUNT != "" && typeof item.HISTORYEXCEPTLIFECOUNT != 'number' && isNaN(item.HISTORYEXCEPTLIFECOUNT)){
                msg="历史预期寿命数值必须为数字"
                canSubmit=false
            }else if(item.HISTORYUNIT == "" || item.HISTORYUNIT == null){
                msg="历史寿命指标单位不能为空"
                canSubmit=false
            }
            // else if(item.HISTORYLIFECOUNT == "" || item.HISTORYLIFECOUNT == null){
            //     msg="历史实际寿命数值不能为空"
            //     canSubmit=false
            // }else if(item.HISTORYLIFECOUNT != "" && typeof item.HISTORYLIFECOUNT != 'number' && isNaN(item.HISTORYLIFECOUNT)){
            //     msg="历史实际寿命数值必须为数字"
            //     canSubmit=false
            // }
            // else if(item.CURRENTLIFECOUNT != "" && typeof item.CURRENTLIFECOUNT != 'number' && isNaN(item.CURRENTLIFECOUNT)){
            //     msg="当前寿命指标数值必须为数字"
            //     canSubmit=false
            // }
            // else if(item.HISTORYLIFEPROCESS != "" && typeof item.HISTORYLIFEPROCESS != 'number' && isNaN(item.HISTORYLIFEPROCESS)){
            //     msg="历史实际寿命进程（百分比）必须为数字"
            //     canSubmit=false
            // }
            // else if(item.HISTORYLIFEPROCESS == "" || item.HISTORYLIFEPROCESS == null){
            //     msg="历史实际寿命进程（百分比）不能为空"
            //     canSubmit=false
            // }
            // else if(item.CURRENTDATE == "" || item.CURRENTDATE == null){
            //     msg="当前日期不能为空"
            //     canSubmit=false
            // }else if(item.CURRENTLIFECOUNT == "" || item.CURRENTLIFECOUNT == null){
            //     msg="当前寿命指标数值不能为空"
            //     canSubmit=false
            // }
            if(!canSubmit){
                this.$message.error(msg)
            }else{
                var params={
                    NAME:item.NAME,
                    COMPONENTSNOWID:item.COMPONENTSNOWID,
                    BRAND:item.BRAND,
                    TYPE:item.TYPE,
                    COUNT:item.COUNT,
                    SUPPLIER:item.SUPPLIER,
                    EXCEPTLIFENAME:item.EXCEPTLIFENAME,
                    EXCEPTLIFECOUNT:item.EXCEPTLIFECOUNT,
                    EXCEPTUNIT:item.EXCEPTUNIT,
                    OPERATIONDATE:new Date(item.OPERATIONDATE),
                    CURRENTDATE:new Date(item.CURRENTDATE),
                    CURRENTLIFECOUNT:item.CURRENTLIFECOUNT,
                    CURRENTUNIT:item.CURRENTUNIT,
                    CURRENTLIFPROCESS:item.CURRENTLIFPROCESS,
                    HISTORYEXCEPTLIFECOUNT:item.HISTORYEXCEPTLIFECOUNT,
                    //HISTORYLIFECOUNT:item.HISTORYLIFECOUNT,
                    HISTORYUNIT:item.HISTORYUNIT,
                    //HISTORYLIFEPROCESS:item.HISTORYLIFEPROCESS,
                }
                this.canDo=false
                this.showLoading("请稍后")
                var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
                this.$http.post("LifeManager/CreateLifePart", md5_data)
                    .then((response) => {
                        this.canDo=true
                        this.hideLoading()
                        if (response.data.SUCCESS) {
                            this.$message.success("已添加")
                            var pl=this.partList
                            var npl=[]
                            for(var i=0;i<pl.length;i++){
                                var d=pl[i]
                                if(d.SNOWID == item.SNOWID){
                                    d.noSend=false
                                }
                                npl.push(d)
                            }
                            this.partList=npl
                        } else {
                            this.errorRequest(response.data.DATA,response.data.MSG,true)
                        }
                    })
                    .catch((error) => {
                        this.$message.error("请求出错")
                        this.hideLoading()
                        this.canDo=true   
                    });
            }
        }
    },
    /**
     * 维修记录
     */
    getUserList(){
        var params={
            PAGEINDEX:1,
            PAGESIZE:1000,
            TRUENAME:"",
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("UserManage/GetUserList", md5_data)
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.userList=response.data.DATA
                }
            })
    },
    workPart(item){
        this.nowCheckPart=item
        this.workListModalName=item.NAME+"-维修记录"
        this.getWorkList()
        this.workList=true
        this.workListModal=true
        // console.log("设备")
        // console.log(this.nowCheckmechine)
        // console.log("部件")
        // console.log(this.nowCheckUnit)
        // console.log("零件")
        // console.log(this.nowCheckPart)
    },
    getWorkList(){
        this.showLoading("请稍后")
        var params={
            // PartSnowID:this.nowCheckPart.SNOWID,
            // UserSnowID:this.workUser,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("LifeManager/GetLifePartWorkList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.workList=response.data.DATA
            } else {
                this.workList=[]
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.workList=[]
        });
    },
    searchWork(){
        this.getWorkList()
    },
    addWork(){
        if(this.workUser==""){
            this.$message.error("请先选择一个用户")
        }else {
            var l=this.userList
            var uname=""
            for(var i=0;i<this.userList.length;i++){
                if(this.userList[i].SNOWID==this.workUser){
                    uname=this.userList[i].TRUENAME
                    break;
                }
            }
            if(this.canDo){
                this.$confirm('确定添加一条'+uname+'的维修记录？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                    this.sendAddWork(uname)
                }).catch(() => {
                this.$message.info("已取消")    
            });
            }
        }
    },
    sendAddWork(name){
        this.canDo=false
        this.showLoading("请稍后")
        var params={
            USERSNOWID:this.workUser,
            USERNAME:name,
            DeviceTypeSnowID:this.nowCheckmechine.DEVICETYPESNOWID,
            DEVICESNOWID:this.nowCheckmechine.SNOWID,
            DEVICENAME:this.nowCheckmechine.NAME,
            COMPONENTSNOWID:this.nowCheckUnit.SNOWID,
            COMPONENTNAME:this.nowCheckUnit.NAME,
            PARTSNOWID:this.nowCheckPart.SNOWID,
            PARTNAME:this.nowCheckPart.NAME,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("LifeManager/CreateLifePartWork", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("添加成")
                    this.getWorkList();
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    delWork(id){
        if(this.canDo){
            this.$confirm('确定删除？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
                this.sendDelWork(id) 
            }).catch(() => {
            this.$message.info("已取消")    
          });
        }

    },
    sendDelWork(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({SnowID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SnowIDS:id})).toUpperCase())
        this.$http.post("LifeManager/DeleteLifePartWork", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if(response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.getWorkList()
                }else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true
            });
    },

    exportTable(){
        this.exportModal=true
    },
    submitExportForm(){
        if (this.canDo) {
            this.$refs.exportForm.validate((valid) => {
                if (valid) {
                    this.sendExport()
                } else {
                    return false;
                }
            });
        }
    },
    sendExport(){
       this.showLoading("请稍后")
        var params = {
            factorySnowID:this.exportInfo.factorySnowID,
        }
        this.canDo = false
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$download.post("ExportExcel/ExportElectricalLedger", md5_data,{ responseType: 'blob',timeout:300000})
            .then((resJson) => {
                this.hideLoading()
                this.canDo = true
                if (resJson) {
                    var fname=""
                    for(var i=0;i<this.factoryList.length;i++){
                        if(this.exportInfo.factorySnowID==this.factoryList[i].SNOWID){
                            fname=this.factoryList[i].NAME
                        }
                    }
                    var name = fname+"全寿命周期管理台账"
                    let blob = new Blob([resJson.data], { type: 'application/octet-stream' })
                    if (window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveBlob(blob, name + ".xls")
                    } else {
                        let downloadElement = document.createElement('a')
                        let href = window.URL.createObjectURL(blob) 
                        downloadElement.href = href
                        downloadElement.download = name + ".xls"
                        document.body.appendChild(downloadElement)
                        //此写法兼容火狐
                        let evt = document.createEvent('MouseEvents')
                        evt.initEvent('click', false, false)
                        downloadElement.dispatchEvent(evt)
                        document.body.removeChild(downloadElement)
                    }
                    this.$message.success('操作成功!')
                } else {
                    this.$message.error("数据导出失败")
                }
            })
            .catch((error) => {
                this.errorFlag(JSON.stringify(error))
                this.hideLoading()
                this.canDo = true
            });
        },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style scope>
    .allItems .el-form-item__label{width:0px!important;}
    .allItems{width: 100%;float:left;}
    .tableTitle{font-size: 16px;}
    .tableText{font-size: 14px;}
    .workAddBox{height:60px;overflow:hidden;}
    .workItem{height:30px;line-height:30px; float:left;padding:0 40px 0 12px;background-color:#409EFF;font-size:14px;border-radius: 3px;margin-bottom: 12px;margin-right: 12px;color: #FFFFFF;position: relative;}
    .workItem .close{color: #F56C6C;font-size: 20px;cursor: pointer;position: absolute;right: 3px;top: -6px;}
    .workListNull{height: 80px;line-height: 80px;text-align: center;color: #666666;font-size: 15px;}






    .mechineInfoRow{font-size: 15px;overflow: hidden;float:left;}
	.mechineBtnRow{overflow: hidden;float:left;margin-left:50px;}
    .unitHandleBtn{float:left;margin-right: 12px;margin-top: 3px;cursor: pointer;font-size: 12px;}
    .unitNull{float:left;margin-top: 3px;font-size:12px;color:#ADADAD}
    .unitListTitle{float:left;margin-top: 3px;cursor: pointer;font-size: 12px;color:#F56C6C;font-weight: bold;}

    .partTitle{float:left;margin-top: 3px;cursor: pointer;font-size: 12px;color:#F56C6C;font-weight: bold;margin-right:10px}

	.unitListBox{overflow:hidden;padding:5px 12px;background-color:#F8F8F8;}
    .unitListBox .unitRow{overflow:hidden;margin-top:4px;}
    .unitListBox .unitRowItem{height:26px;background-color:#FFFFFF;cursor: pointer;font-size: 12px;line-height: 26px;padding: 0 16px;border-radius: 3px;color: #444;float:left;margin-right: 12px;margin-bottom: 5px;border:1px solid #444}
    .unitListBox .unitRowItemOn{background-color: #409EFF!important;color: #FFFFFF!important;border:1px solid #409EFF!important;}
    .unitListBox .unitRowItem:hover{background-color: #409EFF!important;color: #FFFFFF!important;border:1px solid #409EFF!important;}
    .partListBox{overflow:hidden;margin-top:10px}
</style>