<template>
    <div class="views-main">
        <div class="views-main-title">
            <el-button type="primary" icon="el-icon-arrow-left" style="float:left;margin-right:20px;" size="small" @click="backXJGL()">返回</el-button>
            <p class="txt" style="margin-top:3px;">任务分配</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <el-calendar style="width:45%;float:left;min-width:700px;" v-model="nowCheck">
                <template
                    slot="dateCell"
                    slot-scope="{date, data}">
                    <div class="dateItem">
                        <p class="d">{{ data.day.split('-').slice(1).join('-') }} </p>
                        <div v-if="showCalendar">
                            <p class="r dfp" v-if="date.getMonth() == nowCheck.getMonth()">待分配：{{monthTask[data.day].DAIFENPEI}} </p>
                            <p class="r yfp" v-if="date.getMonth() == nowCheck.getMonth()">已分配：{{monthTask[data.day].YIFENPEI}} </p>
                        </div>
                    </div>
                   
                </template>
            </el-calendar>
            <div class="rightBox" :style="{width:tableWidth+'px'}">
                <el-table :data="postList" style="width: 100%" height="662" ref="expandTable" @expand-change="exchange">
                    <el-table-column type="expand">
                        <template slot-scope="props">

                            <div class="tableOut">
                                <div class="tableSearch" v-if="loadExpand">
                                        <el-select v-model="fcid" placeholder="选择风场"  filterable  clearable class="iwsm" @change="fcChange">
                                                <el-option v-for="item in fcList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                                        </el-select> 
                                        <el-cascader v-model="sbid"  :options="sbTree" :props="treeProps" placeholder="搜索或选择设备" filterable class="iwsm" @change="sbChange"></el-cascader>

                                        <el-select v-model="yfp" placeholder="选择是否已分配"  class="iwsm" @change="yfpChange">
                                                <el-option label="已分配/未分配" value="0" ></el-option>
                                                <el-option label="已分配" value="1" ></el-option>
                                                <el-option label="未分配" value="2" ></el-option>
                                        </el-select> 
                                        <el-button type="primary" @click="searchTaskBySb(props.row)">查询</el-button>
                                        <el-button type="primary" @click="setUserMore(props.row)">批量分配</el-button>
                                </div>
                                <el-table :data="props.row.TASKITEMLIST" style="width: 100%" border  @selection-change="taskSelectMore">
                                    <el-table-column type="selection" width="55"></el-table-column>     
                                    <el-table-column label="岗位" prop="POSITIONNAME" width="80" sortable></el-table-column>
                                    <el-table-column label="风场" prop="FACTORY" sortable></el-table-column>
                                    <el-table-column label="设备" prop="MACHINENAME" sortable></el-table-column>
                                    <el-table-column label="检查项编号" prop="CODE" sortable></el-table-column>
                                    <el-table-column label="检查项描述" prop="BZ"></el-table-column>
                                    <el-table-column label="执行人" prop="CHECKUSERNAME" width="80">
                                        <template slot-scope="scope">
                                            <span v-if="scope.row.USERLISTSSTR==null">未分配</span>
                                            <span v-else>{{scope.row.USERLISTSSTR}}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="操作" width="60" v-if="canSet">
                                        <template slot-scope="scope">
                                            <el-button type="text" size="small" v-if="scope.row.USERLISTSSTR==null" @click="setUser(props.row,scope.row)" style="color:#409EFF">分配</el-button>
                                            <el-button type="text" size="small" v-else @click="resetUser(props.row,scope.row)" style="color:#ff9900">修改</el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                            
                        </template>
                    </el-table-column>
                    <el-table-column label="名称"  prop="NAME"></el-table-column>
                    <el-table-column label="岗位" prop="POSITIONNAME"></el-table-column>
                    <el-table-column label="描述" prop="BZ"></el-table-column>
                    <el-table-column label="当期开始时间" prop="TASKSTARTTIME"></el-table-column>
                    <el-table-column label="当期结束时间" prop="TASKENDTIME"></el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="500px">
            <el-form :model="postInfo" status-icon :rules="postrules"  ref="uForm" label-width="100px" class="demo-uForm">
                <!-- multiple :disabled="userGWDisabled"-->
                <el-form-item label="岗位">
                    <el-select v-model="userGW" placeholder="请选择岗位"  filterable  style="width:100%;"   :clearable="!userGWDisabled" @change="changeUserGW">
                            <el-option v-for="item in userGWList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="执行人" prop="USERID" > 
                        <el-select v-model="postInfo.USERID" placeholder="请选择执行人" filterable  multiple style="width:100%;" @change="changeUser">
                                <el-option v-for="item in userList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                        </el-select>
                </el-form-item>
                <el-form-item>
                <el-button type="primary" @click="submitPostForm()">提交</el-button>
                <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
export default {
  name: "TaskAllot",
  data() {
    var USERID = (rule, value, callback) => {
      if (value.length==0) {
        callback(new Error("请选择执行人"));
      } else {
        callback();
      }
    };
    return {
        showCalendar:false,
        tableWidth:0,
        canDo:true,
        
        nowCheck:null,
        canSet:true,

        name:"",

        monthTask:{},
        userList:[],

        postList:[],
        postObject:{},

        PageIndex:1,
        PageSize:10,
        Total:0,

        tableRow:null,

        userGWDisabled:false,
        userGWList:[],
        userGW:"",
        doTypeStr:"",
        doType:"",
        postModal:false,
        postInfo:{
           USERNAME:[],
           USERID:[],
           TASKITEMLIST:[],
        },
        postrules: {
            USERID: [{ validator: USERID, trigger: "blur" }],
        },

        
        loadExpand:false,
        fcList:[],
        fcid:"",
        sbTree:[],
        sbid:[],
        yfp:"",
        treeProps:{
            value:"SNOWID",
            label:"NAME",
            children:"MACHINERESPONSELIST",
            checkStrictly:true,
        },


        nowExplanId:"",
        nowExplanData:{},

        selectTasks:[],
    };
  },
  mounted() {
    //console.log(this.$store.state.checkType)
    this.getTableWidth()
    window.onresize = () => {
        return (() => {
           this.getTableWidth()
        })();
      };

    this.loadItem=null
    //this.getPostList()
    this.getSBlist()
    this.nowCheck=new Date()
    this.getMonthTasks()
    this.getPostList()
    this.getFCList()
  },
  methods: {
    exchange(a,b){
       // console.log(this.nowExplanData)
        this.nowExplanId=a.SNOWID
        this.fcid = this.nowExplanData[this.nowExplanId].fcid
        this.sbid = this.nowExplanData[this.nowExplanId].sbid
        this.yfp = this.nowExplanData[this.nowExplanId].yfp
        this.getSBlist()
        for(var i=0;i<b.length;i++){
            if(b[i].SNOWID != a.SNOWID){
                 this.$refs.expandTable.toggleRowExpansion(b[i], false);
            }
        }
    },
    searchTaskBySb(row){
        this.tableRow =row
        // console.log(tid)
        //console.log(this.tobj)
        var tid = row.SNOWID
        var sid=""
        var yfp=this.yfp
        if(this.sbid.length>0){
            let l =this.sbid
            sid = l[l.length-1]
        }
        this.showLoading("请稍后")
        var params={
            TASKSNOWID:tid,
            MACHINEID:sid,
            DATE:this.nowCheck,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Schedule/GetScheuleItems", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                //
                var list =response.data.DATA
                var tl=[]
                for(var i=0;i<list.length;i++){
                    var item =list[i]
                    item.USERLISTSSTR=null
                    if(item.USERLIST.length>0){
                        for(var k=0;k<item.USERLIST.length;k++){
                            if(k==0){
                                item.USERLISTSSTR = item.USERLIST[k].CHECKUSERNAME
                            }else{
                                item.USERLISTSSTR = item.USERLISTSSTR+"、"+item.USERLIST[k].CHECKUSERNAME
                            }
                        }
                    }
                    if(yfp=="0"){
                        tl.push(item)
                    }else if(yfp=="1"){
                        if(item.USERLIST.length>0){
                            tl.push(item)
                        }
                    }
                    else{
                        if(item.USERLIST.length==0){
                            tl.push(item)
                        }
                    }
                }
                //console.log(tl)
                var pl=this.postList
                var nl=[]
                for(var j=0;j<pl.length;j++){
                    var pitem =pl[j]
                    if(pitem.SNOWID== tid){
                        pitem.TASKITEMLIST = tl
                    }
                    nl.push(pitem)
                }
                this.postList = nl
                setTimeout(() => {
                        this.$refs.expandTable.toggleRowExpansion(this.tableRow, true);
                }, 50);    
            } else {
                var pul=this.postList
                var nlu=[]
                for(var u=0;u<pul.length;u++){
                    var pitemu =pul[u]
                    if(pitemu.SNOWID== tid){
                        pitemu.TASKITEMLIST = []
                    }
                    nlu.push(pitemu)
                }
                this.postList = nlu
                setTimeout(() => {
                        this.$refs.expandTable.toggleRowExpansion(this.tableRow, true);
                }, 50);   
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                var plo=this.postList
                var nlo=[]
                for(var o=0;o<plo.length;o++){
                    var pitemo =plo[o]
                    if(pitemo.SNOWID== tid){
                        pitemo.TASKITEMLIST = []
                    }
                    nlo.push(pitemo)
                }
                this.postList = nlo
                setTimeout(() => {
                        this.$refs.expandTable.toggleRowExpansion(this.tableRow, true);
                }, 50);       
                this.hideLoading()
               
        });
    },
    fcChange(e){
        if(e==""){
            this.sbid=""
            this.sbTree=[]
        }else{
            this.getSBlist()
        }
        this.nowExplanData[this.nowExplanId].fcid=e
    },
    sbChange(e){
        this.nowExplanData[this.nowExplanId].sbid=e
    },
    yfpChange(e){
        this.nowExplanData[this.nowExplanId].yfp=e
    },
    getFCList(){
        var md5_data =this.comjs.requestDataEncrypt({}, this.$store.state.token,this.$md5(this.comjs.sortAsc({})).toUpperCase())
        this.$http.post("Factory/GetFactoryWithoutPage", md5_data)
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.fcList=response.data.DATA  
                } 
            })
    },
    getSBlist(){
        var params={
            FACTORYID:this.fcid
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Machine/GetMachineTree", md5_data)
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.sbTree=response.data.DATA  
                } 
            })
    },
    getPostList(){
        var md5_data =this.comjs.requestDataEncrypt({}, this.$store.state.token,this.$md5(this.comjs.sortAsc({})).toUpperCase())
        this.$http.post("PositonManage/GetPositionListWithoutPage", md5_data)
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.userGWList=response.data.DATA  
                } 
            })
    },
    getTableWidth(){
        var pw=document.querySelector(".tableBox").clientWidth
        var cw=document.querySelector(".el-calendar").clientWidth
        this.tableWidth =  pw -  cw -10
    },
    changeUserGW(id){
        //var uid=this.postInfo.USERID
        this.postInfo.USERID=[]
        this.getUserByGW(id,[])
    },
    getUserByGW(id,uid){
        if(id && id!=""){
            this.userGW=id
        }else{
            // this.userGWDisabled=false
            // this.userGW=this.userGWList[0].SNOWID
            // id= this.userGWList[0].SNOWID
            id=""
        }
        var md5_data =this.comjs.requestDataEncrypt({POSITIONID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({POSITIONID:id})).toUpperCase())
        this.$http.post("UserManage/GetUserListWithoutPage",md5_data)
        .then((response) => {
          if(response.data.SUCCESS){   
            var l=response.data.DATA
            var nl=[]
            for(var i=0;i<l.length;i++){
              var d=l[i]
              d.NAME= d.TRUENAME+"("+d.LOGINNAME+")"
              nl.push(d)
              if(d.SNOWID == uid){
                  this.postInfo.CHECKUSERNAME = d.TRUENAME
              }
            }
            this.userList=nl
          }
        })
    },
    changeUser(item){
      if(item.length==0){
          this.postInfo.USERNAME=[]
      }else{
          var l=this.userList
          var names=[]
          for(var j=0;j<item.length;j++){
                for(var i=0;i<l.length;i++){
                    if(l[i].SNOWID == item[j]){
                        names.push(l[i].TRUENAME)
                        break;
                    }
                }
          }
          //console.log(names)
          this.postInfo.USERNAME=names
      }
    },
    getMonthTasks(){
        this.showCalendar=false
        var y= this.nowCheck.getFullYear()
        var m= this.nowCheck.getMonth() + 1
        var params={
            YEAR:y.toString(),
            MONTH:m.toString(),
            TYPE:this.$store.state.checkType
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Schedule/GetScheduleTask", md5_data)
            .then((response) => {
                if (response.data.SUCCESS) {
                    //console.log(response.data.DATA)
                    this.monthTask=response.data.DATA
                    this.showCalendar=true
                }
            })
    },
    getTaskByDate(date){
        var str=this.comjs.getDateStrByDate(date).split(" ")[0]
        //console.log(str)
        this.loadExpand=false
        this.showLoading("请稍后")
        var params={
            PAGEINDEX:this.PageIndex,
            PAGESIZE:this.PageSize,
            DATE:str,
            TYPE:this.$store.state.checkType
        }
        this.nowExplanData={}
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Schedule/GetOneDayScheule", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                var nl=[]
                var list=response.data.DATA
                for(var i=0;i<list.length;i++){
                    
                    var tl=[]
                    var d=list[i]
                    this.nowExplanData[d.SNOWID]={fcid:"",sbid:[],yfp:""}
                    for(var j=0;j<list[i].TASKITEMLIST.length;j++){
                        var item =list[i].TASKITEMLIST[j]
                        item.USERLISTSSTR=null
                        if(item.USERLIST.length>0){
                            for(var k=0;k<item.USERLIST.length;k++){
                                if(k==0){
                                    item.USERLISTSSTR = item.USERLIST[k].CHECKUSERNAME
                                }else{
                                    item.USERLISTSSTR = item.USERLISTSSTR+"、"+item.USERLIST[k].CHECKUSERNAME
                                }
                            }
                        }
                        tl.push(item)
                    }
                    d.TASKITEMLIST = tl
                    nl.push(d)
                }
                //console.log(nl)
                this.postList=nl
                this.postObject={}
                this.loadExpand=true
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.postObject={}
                this.Total=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.postObject={}
                this.Total=0
        });
    },
    PGChange(e){
        this.PageIndex=e
        this.getTaskByDate(this.nowCheck)
    },
    setUser(task,check){

        this.tableRow=task
        if(check.POSITIONID == null || check.POSITIONID==""){
            this.userGWDisabled=false
        }else{
            this.userGW=check.POSITIONID
            this.userGWDisabled=true
        }
        this.getUserByGW(check.POSITIONID,null)
        this.doTypeStr="分配执行人"
        this.doType="1"
        this.postModal=true
        this.postInfo={
            TASKITEMLIST:[
                {
                    TASKSTARTTIME:task.TASKSTARTTIME,
                    TASKENDTIME:task.TASKENDTIME,
                    CHECKTASKID:task.SNOWID,
                    CHECKITEMID:check.SNOWID,
                }
            ],
            USERID:[],
            USERNAME:[],
        }
    },
    resetUser(task,check){
        this.tableRow=task
        this.doTypeStr="修改执行人"
        this.doType="2"
        this.postModal=true
        var ids=[]
        var names=[]
        for(var i=0;i<check.USERLIST.length;i++){
            ids.push(check.USERLIST[i].CHECKUSERID)
            names.push(check.USERLIST[i].CHECKUSERNAME)
        }
        this.postInfo={
            TASKITEMLIST:[
                {
                    TASKSTARTTIME:task.TASKSTARTTIME,
                    TASKENDTIME:task.TASKENDTIME,
                    CHECKTASKID:task.SNOWID,
                    CHECKITEMID:check.SNOWID,
                }
            ],
            USERID:ids,
            USERNAME:names,
        }
        if(check.POSITIONID == null || check.POSITIONID==""){
            this.userGWDisabled=false
        }else{
            this.userGW=check.POSITIONID
            this.userGWDisabled=true
        }
        this.getUserByGW(check.POSITIONID,ids)
    },
    submitPostForm() {
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    if(this.doType=="1"){
                        this.sendAdd()
                    }else if(this.doType=="2"){
                        this.sendEdit()
                    }
                } else {
                    return false;
                }
            });
        }
    },
    sendAdd(){
        //console.log(this.postInfo)

        this.canDo=false
        this.showLoading("请稍后")

        var params={TASKITEMLIST:[]}
        

        for(var j=0;j<this.postInfo.TASKITEMLIST.length;j++){
            var item =this.postInfo.TASKITEMLIST[j]
            item.USERLIST=this.postInfo.USERID
            params.TASKITEMLIST.push(item)
        }



        var ul=[]
        var ustr=""
        for(var i=0;i<this.postInfo.USERID.length;i++){
            var obj={CHECKUSERID:this.postInfo.USERID[i],CHECKUSERNAME:this.postInfo.USERNAME[i]}
            ul.push(obj)
            if(i==0){
                ustr = this.postInfo.USERNAME[i]
            }else{
                ustr =ustr +"、"+this.postInfo.USERNAME[i]
            }
        }


        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Schedule/SetSchedule", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已分配")
                    this.postModal=false
                    this.getMonthTasks()

                    var nl=[]
                    for(var k=0;k<this.postInfo.TASKITEMLIST.length;k++){
                        var tid=this.postInfo.TASKITEMLIST[k].CHECKTASKID
                        var cid=this.postInfo.TASKITEMLIST[k].CHECKITEMID
                        for(var i=0;i<this.postList.length;i++){
                            var d=this.postList[i]
                            if(d.SNOWID == tid){
                                var nnl=[]
                                for(var j=0;j<d.TASKITEMLIST.length;j++){
                                    var dd=d.TASKITEMLIST[j]
                                    if(dd.SNOWID == cid){
                                        dd.USERLIST=ul
                                        dd.USERLISTSSTR =ustr
                                    }
                                    nnl.push(dd)
                                }
                                d.TASKITEMLIST = nnl
                            }
                            nl.push(d)
                        }
                    }
 
                    this.postList = nl
                    setTimeout(() => {
                        this.$refs.expandTable.toggleRowExpansion(this.tableRow, true);
                    }, 50);
                    
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    sendEdit(){
        //console.log(this.postInfo)
        this.canDo=false
        this.showLoading("请稍后")

        var params={TASKITEMLIST:[]}
        var item =this.postInfo.TASKITEMLIST[0]
        item.USERLIST=this.postInfo.USERID
        params.TASKITEMLIST.push(item)

        var ul=[]
        var ustr=""
        for(var i=0;i<this.postInfo.USERID.length;i++){
            var obj={CHECKUSERID:this.postInfo.USERID[i],CHECKUSERNAME:this.postInfo.USERNAME[i]}
            ul.push(obj)
            if(i==0){
                ustr = this.postInfo.USERNAME[i]
            }else{
                ustr =ustr +"、"+this.postInfo.USERNAME[i]
            }
        }

        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Schedule/EditSchedule", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.postModal=false
                    this.getMonthTasks()

                    var nl=[]
                    var tid=this.postInfo.TASKITEMLIST[0].CHECKTASKID
                    var cid=this.postInfo.TASKITEMLIST[0].CHECKITEMID
                    //var row= null
                    for(var i=0;i<this.postList.length;i++){
                        var d=this.postList[i]
                        if(d.SNOWID == tid){
                            var nnl=[]
                            for(var j=0;j<d.TASKITEMLIST.length;j++){
                                var dd=d.TASKITEMLIST[j]
                                if(dd.SNOWID == cid){
                                    dd.USERLIST=ul
                                    dd.USERLISTSSTR =ustr
                                }
                                nnl.push(dd)
                            }
                            d.TASKITEMLIST = nnl
                        }
                        nl.push(d)
                    }
                    this.postList = nl
                    setTimeout(() => {
                        this.$refs.expandTable.toggleRowExpansion(this.tableRow, true);
                    }, 50);
                    
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    backXJGL(){
        this.$router.replace('/XJGL')
    },
    taskSelectMore(e){
        //console.log(e)
        this.selectTasks=e
    },
    setUserMore (task){
        this.tableRow=task
        if(this.selectTasks.length>0){
            this.userGWDisabled=false
            var  tis=[]
            for(var i=0;i<this.selectTasks.length;i++){
                var r=this.selectTasks[i]
                tis.push({
                        TASKSTARTTIME:task.TASKSTARTTIME,
                        TASKENDTIME:task.TASKENDTIME,
                        CHECKTASKID:task.SNOWID,
                        CHECKITEMID:r.SNOWID,
                })
            }
            this.getUserByGW("",null)
            this.doTypeStr="分配执行人"
            this.doType="1"
            this.postModal=true
            this.postInfo={
                TASKITEMLIST:tis,
                USERID:[],
                USERNAME:[],
            }
        }else{
            this.$message.error("请至少选择一个任务项")
        }
    }
  },
  computed: {
  },
  watch: {
      nowCheck(newVal,oldVal){
        this.canSet=this.comjs.compareDate(newVal,new Date())
        if(oldVal){
            if(newVal.getFullYear() != oldVal.getFullYear() || newVal.getMonth() != oldVal.getMonth()){
              this.getMonthTasks()
            }
        }
        this.getTaskByDate(newVal)
      }
  },
};
</script>
<style scoped>
.dateItem{width: 100%;height: 100%;}
.dateItem .d{font-size: 15px;margin-bottom: 5px;}
.dateItem .r{font-size: 13px;margin-bottom: 5px;}
.dateItem .dfp{color:#E6A23C;}
.dateItem .yfp{color:#67C23A;}
.dateItem .ywc{color:#67C23A;}

.is-selected .d{color: #409EFF;font-weight: bold;}
.el-backtop, .el-calendar-table td.is-today {
    color: #000000;
}

.rightBox{overflow: hidden;float:right;}
.tableOut{margin: 0px 12px 0px 12px;padding: 12px;background-color: #F8F8F8;}
.el-table tbody tr:hover>td { 
    background-color:#FFFFFF!important
}
.tableSearch{margin-bottom: 20px;}
/* 
.is-today{background-color: #F2F8FE!important;}
.is-selected{background-color: unset;} */
</style>