<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">人员登机记录查询</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                <el-radio v-model="type" label="1" border style="margin-right:10px">汇总表</el-radio>
                <el-radio v-model="type" label="2" border>明细表</el-radio>

                <el-select v-model="userId" placeholder="请选择用户" filterable clearable class="iw" style="margin-left:100px;" v-show="type=='1'">
                    <el-option v-for="item in userList" :key="item.SnowID" :label="item.TrueName" :value="item.SnowID" ></el-option>
                </el-select>
                <el-date-picker v-model="allTime" type="month" placeholder="选择月" class="iw" :clearable="false" v-show="type=='1'"> </el-date-picker>
                <el-date-picker v-model="detailTime"  type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" class="iww" :clearable="false" :picker-options="pickerOption" v-show="type=='2'"></el-date-picker>
                <el-button type="primary" @click="searchTable">查询</el-button>
                <el-button type="warning" @click="exportTable">导出</el-button>
            </div>
            <div class="views-main-body" v-show="type=='1'">
                <el-table :data="allList" stripe style="width: 100%" > 
                    <el-table-column prop="UserName" label="姓名"></el-table-column>
                    <el-table-column prop="UpDays" label="当月登机天数"></el-table-column>
                    <el-table-column prop="UpCount" label="登机记录"></el-table-column>
                    <el-table-column prop="UpTimeCount" label="登机时长（小时）"></el-table-column>
                    <el-table-column prop="DealCount" label="消缺记录"></el-table-column>
                    <el-table-column prop="PartUseCount" label="配件使用记录"></el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="allPageIndex" :page-size="allPageSize" :total="allTotal" class="pg" @current-change="allPGChange"></el-pagination>
            </div>
            <div class="views-main-body" v-show="type=='2'">
                <el-table :data="detailList" stripe style="width: 100%;height:526px;" > 
                    <!-- <el-table-column prop="UserName" label="姓名"></el-table-column> -->
                    <el-table-column prop="MachineryName" label="风机名称"></el-table-column>
                    <el-table-column prop="UpTime" label="登机时间"></el-table-column>
                    <el-table-column prop="OutTime" label="离机时间"></el-table-column>
                    <el-table-column prop="TimeCount" label="登机时长（小时）"></el-table-column>
                    <el-table-column prop="DealCount" label="消缺记录"></el-table-column>
                    <el-table-column prop="PartUseCount" label="配件使用记录"></el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="detailPageIndex" :page-size="detailPageSize" :total="detailTotal" class="pg" @current-change="detailPGChange"></el-pagination>
                <el-radio-group v-model="selUserId" size="small" style="margin-top:20px;float:left;" >
                    <el-radio-button   v-for="item in userList" :key="item.SnowID" :label="item.SnowID">{{item.TrueName}}</el-radio-button>
                </el-radio-group>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  name: "PostManage",
  data() {
    return {
        canDo:true,
        
        type:"1",
        
        userId:"",
        userList:[],

        allTime:new Date(),
        allList:[],
        allPageIndex:1,
        allPageSize:10,
        allTotal:0,


        selUserId:"",
        detailTime:[],
        pickerOption:{
            disabledDate(time) {
                return time.getTime() > Date.now() ; //-8.64e7 毫秒数代表一天
            }
        },
        detailList:[],
        detailPageIndex:1,
        detailPageSize:10,
        detailTotal:0,

    };
  },
  mounted() {
    this.loadItem=null
    var today = new Date();
    var oneDay = 24 * 60 * 60 * 1000; 
    var yesterday = today.getTime() - oneDay; 
    this.detailTime = [new Date(yesterday),new Date(yesterday)]; 
    this.getHasWorkUsers()
    this.getAllList()
  },
  methods: {
    getHasWorkUsers(){
        var params={
            PAGEINDEX:1,
            PAGESIZE:1000,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("ExportExcel/GetWorkDetailUserList", md5_data)
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.userList=response.data.DATA
                    if(this.userList.length>0){
                        this.selUserId=this.userList[0].SnowID
                    }
                }
            })
    },
    getAllList(){
        var st=""
        var et=""
        if(this.allTime){
            var sd=this.comjs.getDateMinStrByDate(this.allTime).split("-")
            st=sd[0]+"-"+sd[1]+"-01"
            var ed= this.comjs.getDaysInCurrentMonth(this.allTime)
            if(ed<10){
                ed="0"+ed
            }
            et=sd[0]+"-"+sd[1]+"-"+ed
        }else{
            this.$message.error("请选择月份")
            return false
        }
        this.showLoading("请稍后")
        var params={
            PAGEINDEX:this.allPageIndex,
            PAGESIZE:this.allPageSize,
            USERSNOWID:this.userId,
            StartTime:st,
            EndTime:et,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("ExportExcel/GetExportWorkCountList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.allList=response.data.DATA
                this.allTotal=response.data.TOTALCOUNT
            } else {
                this.allList=[]
                this.allTotal=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.allList=[]
                this.allTotal=0
        });
    },
    allPGChange(e){
        this.allPageIndex=e
        this.getAllList()
    },


    getDetailList(){
        var st=""
        var et=""
        if(this.detailTime.length>0){
            st=this.comjs.getDateMinStrByDate(this.detailTime[0])+" 00:00:00"
            et=this.comjs.getDateMinStrByDate(this.detailTime[1])+" 23:59:59"
        }else{
            this.$message.error("请选择日期")
            return false
        }
        this.showLoading("请稍后")
        var params={
            PAGEINDEX:this.detailPageIndex,
            PAGESIZE:this.detailPageSize,
            USERSNOWID:this.selUserId,
            StartTime:st,
            EndTime:et,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("ExportExcel/GetUserWorkDetail", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.detailList=response.data.DATA
                this.detailTotal=response.data.TOTALCOUNT
            } else {
                this.detailList=[]
                this.detailTotal=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.detailList=[]
                this.detailTotal=0
        });
    },
    detailPGChange(e){
        this.detailPageIndex=e
        this.getDetailList()
    },

    searchTable(){
        if(this.type=="1"){
            this.allPageIndex=1
            this.getAllList()
        }else{
            this.detailPageIndex=1
            this.getDetailList()
        }
    },
   

    exportTable(){
        if(this.type=="1"){
            this.exportAllTable()
        }else{
            this.exportDetailTable()
        }   
    },
    exportAllTable(){
        var st=""
        var et=""
        if(this.allTime){
            var sd=this.comjs.getDateMinStrByDate(this.allTime).split("-")
            st=sd[0]+"-"+sd[1]+"-01"
            var ed= this.comjs.getDaysInCurrentMonth(this.allTime)
            if(ed<10){
                ed="0"+ed
            }
            et=sd[0]+"-"+sd[1]+"-"+ed
        }else{
            this.$message.error("请选择月份")
            return false
        }
        this.showLoading("请稍后")
        var params = {
            USERSNOWID:this.userId,
            StartTime:st,
            EndTime:et,
        }
        this.canDo = false
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$download.post("ExportExcel/ExportWorkCountList", md5_data,{ responseType: 'blob',timeout:300000})
        .then((resJson) => {
            this.hideLoading()
            this.canDo = true
            if (resJson) {
                var sdd=this.comjs.getDateMinStrByDate(this.allTime).split("-")
                var name = sdd[0]+"年"+sdd[1]+"月登机汇总表"
                let blob = new Blob([resJson.data], { type: 'application/octet-stream' })
                if (window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveBlob(blob, name + ".xls")
                } else {
                    let downloadElement = document.createElement('a')
                    let href = window.URL.createObjectURL(blob) //创建下载的链接
                    downloadElement.href = href
                    downloadElement.download = name + ".xls"
                    document.body.appendChild(downloadElement)
                    //此写法兼容火狐
                    let evt = document.createEvent('MouseEvents')
                    evt.initEvent('click', false, false)
                    downloadElement.dispatchEvent(evt)
                    document.body.removeChild(downloadElement)
                }
                this.$message.success('操作成功!')
            } else {
                this.$message.error("数据导出失败")
            }
        })
        .catch((error) => {
            this.errorFlag(JSON.stringify(error))
            this.hideLoading()
            this.canDo = true
        });
    },
    exportDetailTable(){
        var st=""
        var et=""
        if(this.detailTime.length>0){
            st=this.comjs.getDateMinStrByDate(this.detailTime[0])+" 00:00:00"
            et=this.comjs.getDateMinStrByDate(this.detailTime[1])+" 23:59:59"
        }else{
            this.$message.error("请选择日期")
            return false
        }
        this.showLoading("请稍后")
        var params = {
            //USERSNOWID:this.selUserId,
            StartTime:st,
            EndTime:et,
        }
        this.canDo = false
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$download.post("ExportExcel/ExportWorkDetailList", md5_data,{ responseType: 'blob',timeout:300000})
        .then((resJson) => {
            this.hideLoading()
            this.canDo = true
            if (resJson) {
                var name = this.comjs.getDateMinStrByDate(this.detailTime[0])+"至"+this.comjs.getDateMinStrByDate(this.detailTime[1])+"登机明细表"
                let blob = new Blob([resJson.data], { type: 'application/octet-stream' })
                if (window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveBlob(blob, name + ".xls")
                } else {
                    let downloadElement = document.createElement('a')
                    let href = window.URL.createObjectURL(blob) //创建下载的链接
                    downloadElement.href = href
                    downloadElement.download = name + ".xls"
                    document.body.appendChild(downloadElement)
                    //此写法兼容火狐
                    let evt = document.createEvent('MouseEvents')
                    evt.initEvent('click', false, false)
                    downloadElement.dispatchEvent(evt)
                    document.body.removeChild(downloadElement)
                }
                this.$message.success('操作成功!')
            } else {
                this.$message.error("数据导出失败")
            }
        })
        .catch((error) => {
            this.errorFlag(JSON.stringify(error))
            this.hideLoading()
            this.canDo = true
        });
    },
  },
  computed: {
  },
  watch: {
    type:function(nVal,oVal){
        if(nVal=="2" && this.detailTime.length>0){
            this.detailPageIndex=1
            this.getDetailList()
        }
    },
    selUserId:function(nVal,oVal){
        if(this.type=="2" && this.detailTime.length>0){
            this.detailPageIndex=1
            this.getDetailList()
        }
    },
  },
};
</script>
<style >
</style>