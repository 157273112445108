<template>
    <div class="content" style="width: 100%;height:100%;">
            <el-button type="success" @click="addPost" class="fr">添加</el-button>
            <el-table :data="postList" stripe style="width: 100%" :height="tableHeight"> 
                <el-table-column prop="MACHINETYPENAME" label="设备类型"></el-table-column>
                <el-table-column prop="FAULTTYPECONTEXT" label="故障类型"></el-table-column>
                <el-table-column label="操作" width="160">
                    <template slot-scope="scope">
                        <el-button @click="faPost(scope.row)" type="text" size="small" style="color:#409EFF">解决方案</el-button>
                        <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button>
                        <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange" style="margin-bottom:20px;"></el-pagination>
            <el-dialog :title="doTypeStr" :visible.sync="postModal" width="800px" top="30px" append-to-body>
                <el-form :model="postInfo" status-icon :rules="postrules"  ref="uForm" label-width="120px" class="demo-uForm">
                    <el-form-item label="故障类型" prop="FAULTTYPECONTEXT">
                        <el-input type="textarea"  class="tjarea" v-model="postInfo.FAULTTYPECONTEXT" autocomplete="off"></el-input>
                    </el-form-item>            
                    <el-form-item>
                    <el-button type="primary" @click="submitPostForm()">提交</el-button>
                    <el-button @click="postModal = false">取消</el-button>
                    </el-form-item>
                </el-form>
            </el-dialog>
            <el-dialog :title="faTitle" :visible.sync="faModal" width="800px" top="50px" append-to-body >
                    <div style="width:100%;overflow:hidden;margin-bottom:20px;">
                        <el-button type="success" @click="addFAItem()" class="">添加解决方案</el-button>
                    </div>
                    <el-table :data="faList" stripe style="width: 100%" :height="tableHeight">
                        <el-table-column prop="REMARK" label="解决方案描述"></el-table-column>
                        <el-table-column prop="CREATEUSERNAME" label="解决方案提交人"></el-table-column>
                        <el-table-column label="操作" width="120">
                            <template slot-scope="scope">
                                <el-button @click="editFAPost(scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button>
                                <el-button @click="delFAPost(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
                                <el-button @click="fileFAPost(scope.row)"  type="text" size="small" style="color:#409EFF">附件</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination background layout="total, prev, pager, next"  :current-page="faPageIndex" :page-size="faPageSize" :total="faTotal" class="pg" style="margin-bottom:20px;" @current-change="FAPGChange"></el-pagination>
            </el-dialog>
            <el-dialog :title="fadoTypeStr" :visible.sync="fapostModal" width="800px" top="100px" append-to-body>
                <el-form :model="fapostInfo" status-icon :rules="fapostrules"  ref="fForm" label-width="120px" class="demo-uForm">
                    <el-form-item label="描述" prop="REMARK">
                        <el-input v-model="fapostInfo.REMARK" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item>
                    <el-button type="primary" @click="submitFAPostForm()">提交</el-button>
                    <el-button @click="fapostModal = false">取消</el-button>
                    </el-form-item>
                </el-form>
            </el-dialog>
            <el-dialog :title="faFileTitle" :visible.sync="faFileModal" width="800px"  append-to-body>
                <p class="fileTitle">现有附件：</p>
                <div class="fileBox">
                    <div class="fileItem" v-for="item in faFileList" :key="item.SNOIWD" :title="item.FILENAME">
                        <p class="name" @click="downFile(item.FILEPATH)">{{item.FILENAME}}</p>
                        <div class="fileBtn">
                            <span @click="downFile(item.PATH)" style="color:#409EFF" v-if="item.TYPE=='1'">查看</span>
                            <span @click="downFile(item.PATH)" style="color:#409EFF" v-else>下载</span>
                            <span @click="delFile(item.SNOWID)" style="color:#F56C6C">删除</span>
                        </div>
                    </div>
                    <div v-if="faFileList.length==0" class="noFile">该方案还未上传附件，请在下方上传。</div>
                </div>
                <p class="fileTitle" style="margin-bottom:20px;">上传附件：</p>
                <div style="overflow:hidden;">
                    <el-upload ref="upload"  class=""  :multiple="true" :limit="5" action="" :http-request="uploadFun">
                        <el-button size="small" type="primary">点击上传</el-button>
                    </el-upload>
                </div>
            </el-dialog>
            <el-dialog title="选择文件" :visible.sync="chooseFileModal" width="400px"  append-to-body>
                    <el-upload
                    ref="upload"
                    class="upload-demo"
                    drag
                    :multiple="false"
                    :limit="1"
                    accept=".xls,.xlsx"
                    action=""
                    :http-request="uploadFunModal"
                    >
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                    <div class="el-upload__tip" slot="tip">只能上传excel文件</div>
                    </el-upload>
            </el-dialog>
            <el-dialog title="导入结果明细" :visible.sync="importResultModal" width="540px"  append-to-body>
                    <p>导入成功{{importResult.success}}，导入出错{{importResult.error}}条。</p>
                    <el-table :data="importResult.table" stripe style="width: 100%" :height="tableHeight"> 
                        <el-table-column prop="ROWINDEX" label="行号"></el-table-column>
                        <el-table-column prop="INFO" label="问题描述"></el-table-column>
                    </el-table>
            </el-dialog>
    </div>
</template>
<script>
var h=document.body.clientHeight
export default {
  name: "SBGZGL",
  props: {
    machineTypeSnowid: {
      type:String,
      default:""
    },
    tableHeight: {
      type:Number,
      default:h -300
    },
  },
  data() {
   
    var FAULTTYPECONTEXT = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入故障类型"));
      } else {
        callback();
      }
    };
    var REMARK = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入解决方案"));
      } else {
        callback();
      }
    };
    return {
        canDo:true,
        
        code:"",
       

        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,


        doTypeStr:"",
        doType:"",
        postModal:false,
        postInfo:{},
        postrules: {
          FAULTTYPECONTEXT: [{ validator: FAULTTYPECONTEXT, trigger: "blur" }],
        },

        dialogMaxHeigh:0,
        faList:[],
        faModal:false,
        faTitle:"",
        nowSnowid:"",


        faTotal:0,
        faPageIndex:1,
        faPageSize:10,
        fadoTypeStr:"",
        fadoType:"",
        fapostModal:false,
        fapostInfo:{},
        fapostrules: {
            REMARK: [{ validator: REMARK, trigger: "blur" }],
        },

        nowFASnowid:"",
        faFileList:[],
        faFileModal:false,
        faFileTitle:"",

        chooseFileModal:false,
        importResultModal:false,
        importResult:{
          success:0,
          error:0,
          table:[]
        },
    };
  },
  mounted() {
    this.loadItem=null
    //this.getPostList()
    this.dialogMaxHeigh = h -300
  },
  methods: {
    getPostList(){
        this.showLoading("请稍后")
        var params={
            PAGEINDEX:this.PageIndex,
            PAGESIZE:this.PageSize,
            MACHINETYPESNOWID:this.machineTypeSnowid,
            FAILURESNOWID:"",
            CODE:this.code,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("FailureNew/GetFailureList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
        });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    addPost(){
        this.postInfo={
          MACHINETYPESNOWID:this.machineTypeSnowid,
          FAULTTYPECONTEXT:"",
        }
        this.doTypeStr="添加故障"
        this.doType="1"
        this.postModal=true
    },
    sendAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("FailureNew/CreateFailure", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    editPost(item){
        this.postInfo={
            SNOWID:item.SNOWID,
            MACHINETYPESNOWID:this.machineTypeSnowid,
            FAULTTYPECONTEXT:item.FAULTTYPECONTEXT,
        }
        this.doTypeStr="修改故障"
        this.doType="2"
        this.postModal=true
    },
    sendEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("FailureNew/EditFailure", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.postModal=false
                    this.getPostList();
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    delPost(item){
        if(this.canDo){
            this.$confirm('确定删除故障：'+item.FAULTTYPECONTEXT+'？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
            this.sendDel(item.SNOWID)
            }).catch(() => {
            this.$message.info("已取消")    
          });
        }

    },
    sendDel(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({SNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:id})).toUpperCase())
        this.$http.post("FailureNew/DeleteFailure", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    submitPostForm() {
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    if(this.doType=="1"){
                        this.sendAdd()
                    }else if(this.doType=="2"){
                        this.sendEdit()
                    }
                } else {
                    return false;
                }
            });
        }
    },

    faPost(item){
        this.faTitle=item.MACHINETYPENAME+"-解决方案管理"
        this.nowSnowid=item.SNOWID
        this.getFAList()
        this.faModal=true
    },
    getFAList(){
        this.canDo=false
        this.showLoading("请稍后")
        var params={
            FAILURESNOWID:this.nowSnowid,
            PageIndex:this.faPageIndex,
            PageSize:this.faPageSize,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("FailureNew/GetSolutionList", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.faList=response.data.DATA
                    this.faTotal=response.data.TOTALCOUNT
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                    this.faList=[]
                    this.faTotal=0
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.faList=[]
                this.faTotal=0
                this.canDo=true   
             });
    },
    addFAItem(){
        this.fadoTypeStr="添加解决方案"
        this.fadoType="1"
        this.fapostInfo={
            FAILURESNOWID:this.nowSnowid,
            REMARK:"",
            USERSNOWID:this.$store.state.guid,
        }
        this.fapostModal=true
    },
    sendFAAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.fapostInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.fapostInfo)).toUpperCase())
        this.$http.post("FailureNew/CreateSolution", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.fapostModal=false
                    this.getFAList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    editFAPost(item){
        this.fapostInfo={
            SOLUTIONSNOWID:item.SNOWID,
            REMARK:item.REMARK,
            USERSNOWID:this.$store.state.guid,
        }
        this.fadoTypeStr="修改解决方案"
        this.fadoType="2"
        this.fapostModal=true
    },
    sendFAEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.fapostInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.fapostInfo)).toUpperCase())
        this.$http.post("FailureNew/EditSolution", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.fapostModal=false
                    this.getFAList();
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    delFAPost(item){
        if(this.canDo){
            this.$confirm('确定删除方案？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
            this.sendFADel(item.SNOWID)
            }).catch(() => {
            this.$message.info("已取消")    
          });
        }

    },
    sendFADel(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({SOLUTIONSNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SOLUTIONSNOWID:id})).toUpperCase())
        this.$http.post("FailureNew/DeleteSolution", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.getFAList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    submitFAPostForm() {
        if (this.canDo) {
            this.$refs.fForm.validate((valid) => {
                if (valid) {
                    if(this.fadoType=="1"){
                        this.sendFAAdd()
                    }else if(this.fadoType=="2"){
                        this.sendFAEdit()
                    }
                } else {
                    return false;
                }
            });
        }
    },
    FAPGChange(e){
        this.faPageIndex=e
        this.getFAList()
    },
    fileFAPost(item){
        this.nowFASnowid=item.SNOWID
        this.faFileTitle=item.REMARK+"-附件列表"
        this.getFAFiles()
        this.faFileModal=true
    },
    getFAFiles(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({SOLUTIONSNOWID:this.nowFASnowid}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SOLUTIONSNOWID:this.nowFASnowid})).toUpperCase())
        this.$http.post("FailureNew/GetSolutionFileList", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                   this.faFileList=response.data.DATA
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    uploadFun(f){
      this.showLoading("请稍后...")
      var fd = new FormData();
      fd.append("file", f.file);
      var fname=f.file.name
      var type="2"
      if(f.file.type === 'image/jpeg' || f.file.type === 'image/png'){
        type="1"
      }
      this.$upload.post("FailureNew/UploadSolutionFile?SOLUTIONSNOWID="+this.nowFASnowid+"&FILENAME="+fname+"&TYPE="+type, fd)
        .then((response) => {
          //console.log(response)
         this.hideLoading()
          if (response.data.SUCCESS) {
                this.hideLoading()
                this.$message.success("上传成功")
                this.$refs.upload.clearFiles()
                this.getFAFiles()
          }else{
             this.hideLoading()
             this.$message.error("上传失败")
          }
        })
        .catch((error) => {
         this.hideLoading()
      });
      
    },
    downFile(e){
        window.open(e)
    },
    delFile(e){
        if(this.canDo){
            this.$confirm('确定删除附件？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
                this.sendDelFile(e)
            }).catch(() => {
            this.$message.info("已取消")    
          });
        }
    },
    sendDelFile(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({FILESNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({FILESNOWID:id})).toUpperCase())
        this.$http.post("FailureNew/DeleteSolutionFile", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.getFAFiles()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },

    downloadTemplet(){
      window.open("file/故障代码导入模板.xlsx")
    },
    uploadTemplet(){
      this.chooseFileModal=true
    },
    uploadFunModal(f){
      //this.showLoading("请稍后...")
      var fd = new FormData();
      //console.log(f)
      fd.append("file", f.file);
      this.$upload.post("FailureNew/ImportFailure", fd)
        .then((response) => {
          //console.log(response)
         this.hideLoading()
          if (response.data.SUCCESS) {
             this.hideLoading()
             this.$message.success("导入完成")
             this.$refs.upload.clearFiles()
             this.chooseFileModal=false
             this.getPostList()
             this.importResult={
                success:response.data.DATA.SUCCESSCOUNT,
                error:response.data.DATA.ERRORCOUNT,
                table:response.data.DATA.ERRORLIST
              }
              this.importResultModal=true
          }else{
             this.hideLoading()
             this.$message.error("上传失败")
          }
        })
        .catch((error) => {
         this.hideLoading()
      });
    },

  },
  computed: {
  },
  watch: {
  },
};
</script>
<style>
.tjarea  .el-textarea__inner{height: 120px!important;}
</style>
<style scoped>

.fileBox{overflow: hidden;margin: 20px 0;padding: 20px;background-color: #F8F8F9;}
.fileItem{border-radius: 3px;background-color: #FFFFFF;cursor: pointer;padding: 10px;float:left;margin-right: 20px;margin-bottom: 20px;width: 206px;}
.fileItem:nth-of-type(3n){margin-right: 0!important;}
.fileItem .name{font-size: 15px;font-weight: bold;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;color: #444;margin-bottom: 2px;}
.fileItem .keywords{font-size: 14px;font-weight: bold;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;margin-bottom: 5px;color: #909399;}
.fileItem .fileBtn{overflow: hidden;text-align: left;}
.fileItem span{margin-right: 10px;}
.noFile{text-align: center;font-size: 15px;color: #409EFF;}
.fileTitle{font-size: 16px;font-weight: bold;overflow: hidden;}

</style>