<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">职务管理</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                <el-input v-model="name" placeholder="请输入名称" class="iw" ></el-input>
                <el-button type="primary" @click="searchJob">查询</el-button>
                <el-button type="success" @click="addJob" class="fr">添加</el-button>
            </div>
            <div class="views-main-body">
                <el-table :data="jobList" stripe style="width: 100%"> 
                    <el-table-column prop="NAME" label="名称"></el-table-column>
                    <el-table-column prop="CREATETIME" label="创建日期"></el-table-column>
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                            <el-button @click="editJob(scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button>
                            <el-button @click="delJob(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="jobModal" width="500px">
            <el-form :model="jobInfo" status-icon :rules="jobrules"  ref="uForm" label-width="100px" class="demo-uForm">
                <el-form-item label="名称" prop="NAME">
                <el-input v-model="jobInfo.NAME" autocomplete="off"></el-input>
                </el-form-item>
            
                <el-form-item>
                <el-button type="primary" @click="submitJobForm()">提交</el-button>
                <el-button @click="jobModal = false">取消</el-button>
                </el-form-item>
            </el-form>
            </el-dialog>
    </div>
</template>
<script>
export default {
  name: "JobManage",
  data() {
    var NAME = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入名称"));
      } else {
        callback();
      }
    };
    return {
        canDo:true,
        
        name:"",
       

        jobList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,


        doTypeStr:"",
        doType:"",
        jobModal:false,
        jobInfo:{
            NAME:"",
        },
        jobrules: {
            NAME: [{ validator: NAME, trigger: "blur" }],
        },
    };
  },
  mounted() {
    this.loadItem=null
    this.getJobList()
  },
  methods: {
    getJobList(){
        this.showLoading("请稍后")
        var params={
            PAGEINDEX:this.PageIndex,
            PAGESIZE:this.PageSize,
            NAME:this.name,
            SNOWID:"",
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Post/GetPostList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.jobList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.jobList=[]
                this.Total=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.jobList=[]
                this.Total=0
        });
    },
    searchJob(){
        this.PageIndex=1
        this.getJobList()
    },
    addJob(){
        this.jobInfo={
            NAME:"",
        }
        this.doTypeStr="添加职务"
        this.doType="1"
        this.jobModal=true
    },
    sendAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.jobInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.jobInfo)).toUpperCase())
        this.$http.post("Post/CreateNewPost", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.jobModal=false
                    this.getJobList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    editJob(item){
        this.jobInfo={
            SNOWID:item.SNOWID,
            NAME:item.NAME,
        }
        this.doTypeStr="修改职务"
        this.doType="2"
        this.jobModal=true
    },
    sendEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.jobInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.jobInfo)).toUpperCase())
        this.$http.post("Post/EditPost", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.jobModal=false
                    var nl=[]
                    for(var i=0;i<this.jobList.length;i++){
                        var d=this.jobList[i]
                        if(d.SNOWID==this.jobInfo.SNOWID){
                            d.NAME=this.jobInfo.NAME
                        }
                        nl.push(d)
                    }
                    this.jobList=nl
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    delJob(item){
        if(this.canDo){
            this.$confirm('确定删除职务：'+item.NAME+'？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
            this.sendDel(item.SNOWID)
            }).catch(() => {
            this.$message.info("已取消")    
          });
        }

    },
    sendDel(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({SNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:id})).toUpperCase())
        this.$http.post("Post/DeletePost", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.jobModal=false
                    this.getJobList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    PGChange(e){
        this.PageIndex=e
        this.getJobList()
    },
    submitJobForm() {
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    if(this.doType=="1"){
                        this.sendAdd()
                    }else if(this.doType=="2"){
                        this.sendEdit()
                    }
                } else {
                    return false;
                }
            });
        }
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style >
</style>