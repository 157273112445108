<template>
  <div class="content" style="width: 100%;height:100%;">
          <div class="ft">图片</div>
          <div class="wjOut" v-if="imgs.length>0">
             <div class="imgItem" v-for="item in imgs" :key="item.SNOWID">
                  <el-image style="width: 100%;" :src="item.FILEPATH"  :preview-src-list="imgs"></el-image>
             </div>
          </div>
          <div  class="wjOut" v-else>
            <p class="nofile">暂无内容</p>
          </div>
          <div class="ft">文件</div>
          <div class="wjOut" v-if="files.length>0">
             <div class="fileItem" v-for="item in files" :key="item.SNOWID" @click="down(item.FILEPATH)">
                  {{item.FILENAME}}
             </div>
          </div>
          <div  class="wjOut" v-else>
            <p class="nofile">暂无内容</p>
          </div>

  </div>
</template>
<style scoped> 
.ft{font-size: 18px;font-weight: bold;}
.content{overflow: auto;}
.wjOut{margin: 20px 0;overflow: hidden;}
.imgItem{width: 180px;height: 180px;overflow: hidden;float:left;margin-right: 20px;cursor: pointer;}
.fileItem{background-color: #F8F8F9;border-radius: 3px;padding: 6px 20px;cursor: pointer;font-size: 16px;float: left;margin-right: 20px;}
.nofile{margin: 20px 0 ;text-align: center;font-size: 16px;color: #666;}
</style>
<script>
var h=document.body.clientHeight
export default {
  name: 'LCFJ',
  props: {
    processid: {
      type:String,
      default:""
    },
  },
  data () {
    
      return {

        imgs:[],
        files:[],

        dialogMaxHeigh:0,
      }
  },
  mounted() {
      this.dialogMaxHeigh = h -300
      this.loadItem=null
      if(this.processid!=""){
        this.getTaskList()
      } 
  },
  methods:{
    
    getTaskList(){
        this.showLoading("请稍后")
        var params={
            PROCESSID:this.processid,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Process/GetProAttachList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                var list=response.data.DATA
                for(var i=0;i<list.lenght;i++){
                    if(list[i].TYPE=="1"){
                      this.imgs.push(list[i])
                    }else{
                      this.files.push(list[i])
                    }
                }
            } else {
                this.imgs=[]
                this.files=[]
              
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.imgs=[]
                this.files=[]
        });
    },
    down(path){
       window.open(path)
    }
  }
}
</script>

