<template>
  <div class="views-main">
    <div class="views-main-title">
      <p class="txt">任务流程查询</p>
    </div>
    <div class="tableBox" style="top:50px;">
      <div class="views-main-search">
        <el-date-picker v-model="timeRange" type="daterange" align="right" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions" class="fl" style="margin-right:16px;"></el-date-picker>
        <el-select v-model="status" placeholder="请选择状态" clearable class="iw">
          <el-option label="工作前" value="1"></el-option>
          <el-option label="工作中" value="2"></el-option>
          <el-option label="审核中" value="3"></el-option>
          <el-option label="已结束" value="4"></el-option>
        </el-select>
        <el-input v-model="username" placeholder="用户名称" class="iw" ></el-input>
        <el-button type="primary" @click="searchPost">查询</el-button>
      </div>
      <div class="views-main-body">
        <el-table :data="postList" stripe style="width: 100%">
          <el-table-column label="用户名称" width="100" fixed>
            <template v-slot="scope">
              <div>{{scope.row.userName ? scope.row.userName : '/'}}</div>
            </template>
          </el-table-column>
          <el-table-column label="计划登机人员" width="200" fixed>
            <template v-slot="scope">
              <div>{{scope.row.plannedBoardingOfficer ? scope.row.plannedBoardingOfficer : '/'}}</div>
            </template>
          </el-table-column>
          <el-table-column label="计划工作任务" width="160">
            <template v-slot="scope">
              <div>{{scope.row.plannedWorkTask ? scope.row.plannedWorkTask : '/'}}</div>
            </template>
          </el-table-column>
          <el-table-column label="状态" width="120">
            <template v-slot="scope">
              <div :style="getColorState(scope.row.state)">{{getStatusText(scope.row.state)}}</div>
            </template>
          </el-table-column>
          <el-table-column label="风场名称" width="160">
            <template v-slot="scope">
              <div>{{scope.row.windFieldName ? scope.row.windFieldName : '/'}}</div>
            </template>
          </el-table-column>
          <el-table-column label="风机号" width="160">
            <template v-slot="scope">
              <div>{{scope.row.windFieldCoder ? scope.row.windFieldCoder : '/'}}</div>
            </template>
          </el-table-column>
          <el-table-column label="技术安全交底" width="160">
            <template v-slot="scope">
              <div>{{scope.row.technicalSafety ? scope.row.technicalSafety : '/'}}</div>
            </template>
          </el-table-column>
          <el-table-column label="风机故障检查缺陷情况" width="200">
            <template v-slot="scope">
              <div>{{scope.row.fanFailureInfo ? scope.row.fanFailureInfo : '/'}}</div>
            </template>
          </el-table-column>
          <el-table-column label="故障缺陷图片" width="160">
            <template v-slot="scope">
              <el-image
                  style="width: 50px; height: 50px"
                  :src="scope.row.faultDefectImg"
                  :preview-src-list="[scope.row.faultDefectImg]">
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
            </template>
          </el-table-column>
          <el-table-column label="风机故障消缺情况" width="160">
            <template v-slot="scope">
              <span v-if="scope.row.fanEliminationInfo=='0'" style="color:#E6A23C;">未解决</span>
              <span v-else-if="scope.row.fanEliminationInfo=='1'" style="color:#67C23A;">已解决</span>
              <span v-else>/</span>
            </template>
          </el-table-column>
          <el-table-column prop="completeEliminationImg" label="完成消缺图片" width="160">
            <template v-slot="scope">
              <el-image
                  style="width: 50px; height: 50px"
                  :src="scope.row.completeEliminationImg"
                  :preview-src-list="[scope.row.completeEliminationImg]">
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
            </template>
          </el-table-column>
<!--          <el-table-column prop="ERRCOMPLETEDTIME" label="风机故障消缺时间" width="160">-->
<!--            <template v-slot="scope">-->
<!--              <div>{{scope.row.ERRCOMPLETEDTIME ? scope.row.ERRCOMPLETEDTIME : '/'}}</div>-->
<!--            </template>-->
<!--          </el-table-column>-->
          <el-table-column label="未完成消缺图片" width="160">
            <template v-slot="scope">
              <el-image
                  style="width: 50px; height: 50px"
                  :src="scope.row.incompleteEliminationImg"
                  :preview-src-list="[scope.row.incompleteEliminationImg]">
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
            </template>
          </el-table-column>
<!--          <el-table-column prop="PLANCOMPLETEDBZ" label="计划工作完成情况" width="160">-->
<!--            <template v-slot="scope">-->
<!--              <div>{{scope.row.PLANCOMPLETEDBZ ? scope.row.PLANCOMPLETEDBZ : '/'}}</div>-->
<!--            </template>-->
<!--          </el-table-column>-->
          <el-table-column label="风机配件损坏及更换情况" width="200">
            <template v-slot="scope">
              <div>{{scope.row.damagedReplacement ? scope.row.damagedReplacement : '/'}}</div>
            </template>
          </el-table-column>
          <el-table-column label="实际登机人员" width="160">
            <template v-slot="scope">
              <div>{{scope.row.actualBoardingOfficer ? scope.row.actualBoardingOfficer : '/'}}</div>
            </template>
          </el-table-column>
<!--          <el-table-column prop="TRUEOPERANDBZ" label="实际操作人员参与工作内容" width="200">-->
<!--            <template v-slot="scope">-->
<!--              <div>{{scope.row.TRUEOPERANDBZ ? scope.row.TRUEOPERANDBZ : '/'}}</div>-->
<!--            </template>-->
<!--          </el-table-column>-->
          <el-table-column label="参与定检人员图片" width="160">
            <template v-slot="scope">
              <el-image
                  style="width: 50px; height: 50px"
                  :src="scope.row.participationCheckImg"
                  :preview-src-list="[scope.row.participationCheckImg]">
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
            </template>
          </el-table-column>
          <el-table-column label="计划工作日期" width="160" fixed="right">
            <template v-slot="scope">
              <div>{{scope.row.scheduledWorkingDate ? scope.row.scheduledWorkingDate : '/'}}</div>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="160" fixed="right">
            <template v-slot="scope">
              <el-button :disabled="scope.row.PROCESSID ? false : true" @click="openApprovalProcess(scope.row.PROCESSID)" type="text">查看审批流程</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination background layout="prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
var h=document.body.clientHeight
export default {
  name: "RWLCCX",
  data() {
    return {
      username: '',
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      timeRange: '',
      status: '',
      postList: [],
      PageIndex: 1,
      PageSize: 10,
      Total: 0,
      listItemsLength: 0,
    };
  },
  mounted() {
    this.getPostListNew()
  },
  methods: {
    getPostListNew(){
      var st=""
      var et=""
      if( Array.isArray(this.timeRange)){
        st=this.comjs.getDateMinStrByDate(this.timeRange[0])
        et=this.comjs.getDateMinStrByDate(this.timeRange[1])
      }
      this.showLoading("请稍后")
      var params={
        PAGEINDEX:this.PageIndex,
        PAGESIZE:this.PageSize,
        TASKNAME:this.taskname,
        STARTTIME:st,
        ENDTIME:et,
        STATUS: this.status,
        USERNAME: this.username,
      }
      var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
      this.$http.post("DailyInspectionTask/GetDailyInspectionTask", md5_data)
          .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
              let list = response.data.DATA
              //console.log(list)
              var newData = []
              // 遍历,重新组装数据
              for(var i=0;i< list.length;i++){
                  //第一层
                  if(list[i].PLANTIME){
                    let item=list[i]
                    let obj = {
                        userName: item.USERNAME, // 用户名称
                        plannedBoardingOfficer: item.PLANUSERS, //计划登机人员
                        plannedWorkTask: item.PLANWORKTASK, // 计划工作任务
                        windFieldName: item.FACTORYNAME, // 风场名称
                        technicalSafety: item.SAFEBZ, // 技术安全交底
                        state: item.STATUS, // 状态
                        windFieldCoder: item.MACHINECODE, // 风机号
                        scheduledWorkingDate: item.PLANTIME.split(' ')[0], // 计划工作日期
                        damagedReplacement: item.MACHINEERRCHANGEBZ // 风机配件损坏及更换情况
                    }
                     //有没有第二层
                    if(item.LISTTIMES.length > 0){ 
                      //第二层
                      for(var j=0;j<item.LISTTIMES.length;j++){
                        let ele=item.LISTTIMES[j]
                        let objSecondFloor = obj
                        objSecondFloor.actualBoardingOfficer=ele.USERNAME // 实际登机人员
                        objSecondFloor.actualBoardingDate=ele.UPTIME // 实际登机日期
                        objSecondFloor.participationCheckImg=ele.USERIMAGE // 参与定检人员图片
                        objSecondFloor.fanFailureInfo=''
                        objSecondFloor.fanEliminationInfo=''
                        objSecondFloor.faultDefectImg=''
                        objSecondFloor.completeEliminationImg=''
                        objSecondFloor.incompleteEliminationImg=''
                        //有没有第三层
                        if(ele.LISTITEMS.length > 0){
                          for(var k=0;k<ele.LISTITEMS.length;k++){
                            let n = ele.LISTITEMS[k]
                            let objThreeLayer = objSecondFloor
                            objThreeLayer.fanFailureInfo= n.ERRBZ // 风机故障检查缺陷情况
                            objThreeLayer.fanEliminationInfo= n.ERRSTATUS // 风机故障消缺情况
                            objThreeLayer.faultDefectImg=n.ERRIMAGE // 故障缺陷图片
                            objThreeLayer.completeEliminationImg= n.ERRIMAGECOMPLETED // 完成消缺图片
                            objThreeLayer.incompleteEliminationImg= n.ERRIMAGENOCOMPLETED // 未完成消缺图片
                            newData.push(objThreeLayer)
                          }
                        }else{
                          newData.push(objSecondFloor)
                        }
                      }
                    }else{
                      obj.actualBoardingOfficer= ''// 实际登机人员
                      obj.actualBoardingDate= '' // 实际登机日期
                      obj.participationCheckImg= '' // 参与定检人员图片
                      obj.fanFailureInfo= '' // 风机故障检查缺陷情况
                      obj.fanEliminationInfo= '' // 风机故障消缺情况
                      obj.faultDefectImg= '' // 故障缺陷图片
                      obj.completeEliminationImg= '' // 完成消缺图片
                      obj.incompleteEliminationImg= '' // 未完成消缺图片
                      newData.push(obj)
                    }
                  }
                }
                this.postList=newData
                this.Total=response.data.TOTALCOUNT
              } else {
                this.postList=[]
                this.Total=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
              }
          })
          .catch((error) => {
            this.hideLoading()
            this.postList=[]
            this.Total=0
          });
    },
    searchPost(){
      this.PageIndex=1
      this.getPostListNew()
    },
    PGChange(e){
      this.PageIndex=e
      this.getPostListNew()
    },
    getStatusText(type){
      let text = '/';
      switch (type) {
        case 1:
          text = '工作前'
          break;
        case 2:
          text = '工作中'
          break;
        case 3:
          text = '审核中'
          break;
        case 4:
          text = '已结束'
          break;
      }
      return text
    },
    getColorState(type){
      let color = '';
      if(type == 1 || type == 2 || type == 3){
        color = 'color: #409EFF'
      }else if(type == 4){
        color = 'color: #67C23A'
      }
      return color
    },
    openApprovalProcess(id){
      window.open("/statics/pages/LCCK.html?pid="+id+"&token="+this.$store.state.token)
    }
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style scoped>
.titleStr{font-size: 18px;font-weight: bold;margin-bottom: 20px;}
.fileCon{overflow: hidden;margin-bottom: 20px;}
.imgItem{float:left;margin-right: 12px;margin-bottom: 12px;}
.vodeoItem{width: 100%;height: 340px;display: block;margin-bottom: 20px;}
</style>