<template>
    <div class="views-main">
        <div class="sqsBox">
            <div class="sqs_title">停电作业呈请书</div>
            <div class="sqs_yt">
                <div class="left">
                    <input type="text" placeholder="请输入单位名称" class="ipt" value="国网葫芦岛供电公司">
                </div>
                <div class="right">
                    <p class="t">日提</p>
                    <input type="date" placeholder="请选择日期" class="ipt" value="2022-06-08">
                </div>
            </div>
            <div class="sqs_main">
                <div class="item" style="width:120px;height:50px;">
                    呈请单位
                </div>
                <div class="item" style="width:180px;height:50px;">
                     <input type="text" placeholder="请输入呈请单位" class="ipt" value="大唐葫芦岛茶家风电场">
                </div>
                <div class="item" style="width:120px;height:50px;">
                    本单位领导
                </div>
                <div class="item" style="width:120px;height:50px;">
                     <input type="text" placeholder="请输入本单位领导" class="ipt" value="廉立峰">
                </div>
                 <div class="item" style="width:100px;height:50px;">
                    计划员
                </div>
                <div class="item" style="width:134px;height:50px;">
                     <input type="text" placeholder="请输入计划员" class="ipt" value="李济安">
                </div>


                 <div class="item" style="width:120px;height:200px;">
                    停检设备名称
                </div>
                <div class="item" style="width:180px;height:200px;">
                    <textarea class="tar" placeholder="请输入呈请单位" style="height:160px">大唐葫芦岛茶家风电场66kV升压站</textarea>
                </div>
                <div class="item" style="width:120px;height:200px;">
                    影响停电地区及厂矿名称
                </div>
                <div class="item" style="width:120px;height:200px;">
                     <input type="text" placeholder="请输入影响停电地区" class="ipt" value="无">
                </div>
                <div class="item" style="width:100px;height:200px;">
                    影响设备情况及要求
                </div>
                <div class="item" style="width:134px;height:200px;">
                     <textarea class="tar" placeholder="请输入影响设备情况及要求" style="height:160px">茶家变围唐线由运行转检修，断开围唐线4605开关、断开围唐线4605开关甲刀闸、断开围唐线4605开关乙刀闸</textarea>
                </div>

                <div class="item" style="width:120px;height:90px;">
                    请求停电日期时间
                </div>
                <div class="item pv" style="width:738px;height:90px;">
                    <div class="pa" style="width:300px;left:30px;top:10px;">
                        <span>自　</span>
                        <input type="date" placeholder="请选择日期" class="ipt" value="2022-06-08" style="display:inline;width:100px;">
                        <input type="time" placeholder="请选择时间" class="ipt" value="12:00:00" style="display:inline;width:100px;">
                    </div>
                    <div class="pa" style="width:300px;left:30px;bottom:10px;">
                        <span>至　</span>
                        <input type="date" placeholder="请选择日期" class="ipt" value="2022-06-08" style="display:inline;width:100px;">
                        <input type="time" placeholder="请选择时间" class="ipt" value="12:00:00" style="display:inline;width:100px;">
                    </div>
                    <div class="pa" style="right:30px;bottom:35px;">
                        记　15时　00分
                       
                    </div>
                </div>    

                <div class="item" style="width:16px;height:300px;">
                    检修详细内容
                </div>
                <div class="item" style="width:842px;height:300px;">
                     <textarea class="tar" placeholder="请输入检修详细内容" style="height:180px">茶家风电场66kV一次设备（包括1号主变及主变高压侧E5501开关、E55011隔离刀闸、E55012隔离刀闸、PT、CT、避雷器等）、35kV一次设备以及相关保护设备的消缺及电气预防性试验。</textarea>
                </div>

                <div class="item" style="width:120px;height:50px;">
                    工作负责人
                </div>
                <div class="item" style="width:738px;height:50px;">
                    <input type="text" placeholder="请输入工作负责人" class="ipt" value="王宏飞">
                </div>

                <div class="item" style="width:16px;height:181px;">
                    批准执行日期
                </div>
                <div class="item" style="width:83px;height:90px;">
                    停电时间
                </div>
                <div class="item pv" style="width:610px;height:90px;">
                    <div class="pa" style="width:300px;left:30px;top:10px;">
                        <span>自　</span>
                        <input type="date" placeholder="请选择日期" class="ipt" value="2022-06-08" style="display:inline;width:100px;">
                        <input type="time" placeholder="请选择时间" class="ipt" value="12:00:00" style="display:inline;width:100px;">
                    </div>
                    <div class="pa" style="width:300px;left:30px;bottom:10px;">
                        <span>至　</span>
                        <input type="date" placeholder="请选择日期" class="ipt" value="2022-06-08" style="display:inline;width:100px;">
                        <input type="time" placeholder="请选择时间" class="ipt" value="12:00:00" style="display:inline;width:100px;">
                    </div>
                    <div class="pa" style="right:30px;bottom:35px;">
                        记　15时　00分
                       
                    </div>
                </div> 
                <div class="item" style="width:16px;height:90px;">
                   
                </div>   
                <div class="item" style="width:70px;height:90px;">
                   
                </div>   
                <div class="item" style="width:83px;height:90px;">
                    作业时间
                </div>
                <div class="item pv" style="width:610px;height:90px;">
                    <div class="pa" style="width:300px;left:30px;top:10px;">
                        <span>自　</span>
                        <input type="date" placeholder="请选择日期" class="ipt" value="2022-06-08" style="display:inline;width:100px;">
                        <input type="time" placeholder="请选择时间" class="ipt" value="12:00:00" style="display:inline;width:100px;">
                    </div>
                    <div class="pa" style="width:300px;left:30px;bottom:10px;">
                        <span>至　</span>
                        <input type="date" placeholder="请选择日期" class="ipt" value="2022-06-08" style="display:inline;width:100px;">
                        <input type="time" placeholder="请选择时间" class="ipt" value="12:00:00" style="display:inline;width:100px;">
                    </div>
                    <div class="pa" style="right:30px;bottom:35px;">
                        记　15时　00分
                       
                    </div>
                </div> 
                <div class="item" style="width:16px;height:90px;">
                   
                </div>   
                <div class="item" style="width:70px;height:90px;">
                   
                </div>

                <div class="item" style="width:16px;height:170px;">
                    批示
                </div>   
                <div class="item" style="width:714px;height:170px;">
                    <textarea class="tar" placeholder="请输入批示" style="height:140px"></textarea>
                </div>
                <div class="item" style="width:16px;height:84px;">
                   受理人
                </div>   
                <div class="item" style="width:70px;height:84px;">
                   <input type="text" placeholder="受理人" class="ipt" value="">
                </div>
                <div class="item" style="width:16px;height:85px;">
                   批准人
                </div>   
                <div class="item" style="width:70px;height:85px;">
                    <input type="text" placeholder="批准人" class="ipt" value="">
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  name: "",
  data() {
    return {
    };
  },
  mounted() {
    this.loadItem=null
  },
  methods: {
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style >
.views-main{overflow:auto;}
</style>