<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">全年预算计划</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                
                <el-input v-model="title" placeholder="请输入名称" class="iw fl" ></el-input>
                <el-date-picker
                        v-model="jhsj"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始时间"
                        end-placeholder="结束时间"
                        class="fl">
                </el-date-picker>
                <el-button type="primary" @click="searchPost" style="margin-left:20px;">查询</el-button>
                <el-button type="success" @click="addPost" class="fr">添加</el-button>
                
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column prop="USERNAME" label="负责人"></el-table-column>
                    <el-table-column prop="TITLE" label="标题"></el-table-column>
                    <el-table-column prop="STARTDATE" label="开始时间"></el-table-column>
                    <el-table-column prop="ENDDATE" label="结束时间"></el-table-column>
                    <el-table-column prop="CONTENT" label="预算内容"></el-table-column>
                    <el-table-column prop="CAPTION" label="预算说明"></el-table-column>
                    <el-table-column label="公司领导是否确认">
                      <template slot-scope="scope">
                        <span v-if="scope.row.COMPANYLEADERSNOWID">确认人: {{scope.row.COMPANYLEADERNAME ? scope.row.COMPANYLEADERNAME : '/'}}</span>
                        <span v-else style="color:#E6A23C">未确认</span>
                      </template>
                    </el-table-column>
                    <el-table-column label="风场领导是否确认">
                      <template slot-scope="scope">
                        <span v-if="scope.row.FACTORYLEADERSNOWID">确认人: {{scope.row.FACTORYLEADERNAME ? scope.row.FACTORYLEADERNAME : '/'}}</span>
                        <span v-else style="color:#E6A23C">未确认</span>
                      </template>
                    </el-table-column>
                    <el-table-column prop="REMARK" label="备注"></el-table-column>
                    <el-table-column label="操作" width="160">
                        <template slot-scope="scope">
                        <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button>
                        <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
                        <el-button @click="filePost(scope.row)"  type="text" size="small" style="color:#409EFF">附件</el-button>
                          <el-button @click="verify('风场',scope.row.SNOWID)" v-if="isF && !scope.row.FACTORYLEADERSNOWID" type="text" size="small" style="color:#67C23A">风场领导确认</el-button>
                          <el-button @click="verify('公司',scope.row.SNOWID)" v-if="isG && !scope.row.COMPANYLEADERNAME" type="text" size="small" style="color:#67C23A">公司领导确认</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="500px">
            <el-form :model="postInfo" status-icon :rules="postrules"  ref="uForm" label-width="100px" class="demo-uForm">
                <el-form-item label="标题" prop="TITLE">
                    <el-input v-model="postInfo.TITLE" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="负责人">
                    <el-select v-model="postInfo.USERSNOWID" placeholder="请选择人员"  filterable  style="width:100%;" clearable>
                            <el-option v-for="item in userList" :key="item.SNOWID" :label="item.TRUENAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="开始时间" prop="STARTDATE">
                       <el-date-picker v-model="postInfo.STARTDATE" type="date" placeholder="选择日期" style="width:100%;" @change="prevStChange"> </el-date-picker>
                </el-form-item>
                <el-form-item label="结束时间" prop="ENDDATE">
                        <el-date-picker v-model="postInfo.ENDDATE" type="date" placeholder="选择日期" style="width:100%;" @change="prevEtChange"> </el-date-picker>
                </el-form-item>
                <el-form-item label="预算内容" prop="CONTENT">
                    <el-input v-model="postInfo.CONTENT" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="预算说明" prop="CAPTION">
                    <el-input v-model="postInfo.CAPTION" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="备注" prop="REMARK">
                    <el-input v-model="postInfo.REMARK" autocomplete="off"></el-input>
                </el-form-item>
            
                <el-form-item>
                <el-button type="primary" @click="submitPostForm()">提交</el-button>
                <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog :title="docFileStr" :visible.sync="chooseFileModal" width="800px"  :append-to-body="true">
            <p class="fileTitle">现有附件：</p>
            <div class="fileBox">
                <div class="fileItem" v-for="item in fileList" :key="item.SNOIWD" :title="item.FILENAME">
                    <p class="name" @click="downFile(item.FILEPATH)">{{item.FILENAME}}</p>
                    <div class="fileBtn">
                        <span @click="downFile(item.FILEPATH)" style="color:#409EFF">下载</span>
                        <span @click="delFile(item.SNOWID)" style="color:#F56C6C">删除</span>
                    </div>
                </div>
                <div v-if="fileList.length==0" class="noFile">该计划还未上传附件，请在下方上传。</div>
            </div>
            <p class="fileTitle" style="margin-bottom:20px;">上传附件：</p>
            <div style="overflow:hidden;">
                <el-upload ref="upload"  class=""  :multiple="true" :limit="5" action="" :http-request="uploadFun">
                    <el-button size="small" type="primary">点击上传</el-button>
                </el-upload>
            </div>
      </el-dialog>
    </div>
</template>
<script>
export default {
  name: "QNYSJH",
  data() {
    var TITLE = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入标题"));
      } else {
        callback();
      }
    };
    var CONTENT = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入预算内容"));
      } else {
        callback();
      }
    };
    var CAPTION = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入预算说明"));
      } else {
        callback();
      }
    };
    var USERSNOWID = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择负责人"));
      } else {
        callback();
      }
    };
    var REMARK = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入备注"));
      } else {
        callback();
      }
    };
    var STARTDATE = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入开始时间"));
      } else {
        callback();
      }
    };
    var ENDDATE = (rule, value, callback) => {
      var st=this.postInfo.STARTDATE
      var stt=0
      if(st!=""){
        if(typeof st == "string"){
            st =new Date(st)
        }
        stt = st.getTime()
      }
      var ett=0
      if(value!=""){
        if(typeof value == "string"){
            value =new Date(value)
        }
        ett=value.getTime()
      }
      if (value === "") {
        callback(new Error("请输入结束时间"));
      }else if(stt!=0 && ett!=0 && ett<=stt){
         callback(new Error("结束时间必须大于开始时间"));
      } else {
        callback();
      }
    };
    return {
        canDo:true,
        
        title:"",
        jhsj:"",

        userList:[],

        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,

        doTypeStr:"",
        doType:"",
        postModal:false,
        postInfo:{},
        isG: false, // 是否是公司领导
        isF: false, // 是否是风场领导
        postrules: {
            TITLE: [{ validator: TITLE, trigger: "blur" }],
            STARTDATE: [{ validator: STARTDATE, trigger: "blur" }],
            ENDDATE: [{ validator: ENDDATE, trigger: "blur" }],
            CONTENT: [{ validator: CONTENT, trigger: "blur" }],
            CAPTION: [{ validator: CAPTION, trigger: "blur" }],
            USERSNOWID: [{ validator: USERSNOWID, trigger: "blur" }],
            REMARK: [{ validator: REMARK, trigger: "blur" }],
        },

        docFileStr:"",
        checkJHId:"",
        fileList:[],
        chooseFileModal:false,

        prevSt:"",
        prevEt:"",
    };
  },
  mounted() {
    let list = JSON.parse(localStorage.getItem('setPostList'))
    if(list.length > 0){
      list.forEach((item)=>{
        if(item.NAME === '风场领导'){
          this.isF = true
        }
        if(item.NAME === '公司领导'){
          this.isG = true
        }
      })
    }
    this.loadItem=null
    this.getPostList()
    this.getUserList()
  },
  methods: {
    prevStChange(e){
      this.prevSt=e
    },
    prevEtChange(e){
      this.prevEt=e
    },
    getUserList(){
        var params={ }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("UserManage/GetUserListWithoutPage", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.userList=response.data.DATA
            } 
            })
    },
    getPostList(){
        this.showLoading("请稍后")
        var st=""
        var et=""
        if(this.jhsj && this.jhsj!=""){
            st=this.comjs.getDateMinStrByDate(this.jhsj[0])
            et=this.comjs.getDateMinStrByDate(this.jhsj[1])
        }
        var params={
            PAGEINDEX:this.PageIndex,
            PAGESIZE:this.PageSize,
            TITLE:this.name,
            STARTDATE:st,
            ENDDATE:et,
        }
        var md5_data =this.comjs.requestDataEncrypt(params, this.$store.state.token,this.$md5(this.comjs.sortAsc(params)).toUpperCase())
        this.$http.post("Budget/GetBudgetList", md5_data)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.errorRequest(response.data.DATA,response.data.MSG,true)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
        });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    addPost(){
        this.postInfo={
            TITLE:"",
            STARTDATE:this.prevSt,
            ENDDATE:this.prevEt,
            CONTENT:"",
            CAPTION:"",
            USERSNOWID:"",
            REMARK:"",
        }
        this.doTypeStr="添加计划"
        this.doType="1"
        this.postModal=true
    },
    sendAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("Budget/CreateBudget", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    editPost(item){
        this.postInfo={
            SNOWID:item.SNOWID,
            TITLE:item.TITLE,
            STARTDATE:item.STARTDATE,
            ENDDATE:item.ENDDATE,
            CONTENT:item.CONTENT,
            CAPTION:item.CAPTION,
            USERSNOWID:item.USERSNOWID,
            REMARK:item.REMARK,
        }
        this.doTypeStr="修改计划"
        this.doType="2"
        this.postModal=true
    },
    sendEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt(this.postInfo, this.$store.state.token,this.$md5(this.comjs.sortAsc(this.postInfo)).toUpperCase())
        this.$http.post("Budget/UpdateBudget", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.postModal=false
                    this.getPostList();
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    delPost(item){
        if(this.canDo){
            this.$confirm('确定删除计划：'+item.TITLE+'？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
            this.sendDel(item.SNOWID)
            }).catch(() => {
            this.$message.info("已取消")    
          });
        }

    },
    verify(type,id){
      this.$confirm(`是否进行${type}领导确认？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.canDo=false
        this.showLoading("请稍后")
        let url = ''
        if(type === '风场'){
          url = 'Budget/FactoryAgree'
        }else if(type === '公司'){
          url = 'Budget/CompanyAgree'
        }
        var md5_data =this.comjs.requestDataEncrypt({SnowID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:id})).toUpperCase())
        this.$http.post(url, md5_data)
            .then((response) => {
              this.canDo=true
              this.hideLoading()
              if (response.data.SUCCESS) {
                this.$message.success("确认完成")
                this.postModal=false
                this.getPostList()
              } else {
                this.errorRequest(response.data.DATA,response.data.MSG,true)
              }
            })
            .catch((error) => {
              this.$message.error("请求出错")
              this.hideLoading()
              this.canDo=true
            });
      }).catch(() => {});
    },
    sendDel(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({SNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({SNOWID:id})).toUpperCase())
        this.$http.post("Budget/DeleteBudget", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    submitPostForm() {
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    if(typeof this.postInfo.STARTDATE !="string"){
                         this.postInfo.STARTDATE = this.comjs.getDateMinStrByDate(this.postInfo.STARTDATE) 
                    }
                    if(typeof this.postInfo.ENDDATE !="string"){
                         this.postInfo.ENDDATE = this.comjs.getDateMinStrByDate(this.postInfo.ENDDATE)
                    }
                    if(this.doType=="1"){
                        this.sendAdd()
                    }else if(this.doType=="2"){
                        this.sendEdit()
                    }
                } else {
                    return false;
                }
            });
        }
    },
    filePost(item){
       this.docFileStr=item.TITLE+"-附件列表"
        this.checkJHId=item.SNOWID
        this.getJHFiles()
    },
    uploadFun(f){
      this.showLoading("请稍后...")
      var fd = new FormData();
      fd.append("file", f.file);
      var fname=f.file.name
      var type="2"
      if(f.file.type=='image/png' || f.file.type=='image/jpeg' || f.file.type=='image/gif'){
        type="1"
      }
      this.$upload.post("Budget/UploadBudgetFile?BUDGETSNOWID="+this.checkJHId+"&FILENAME="+fname+"&TYPE="+type, fd)
        .then((response) => {
          //console.log(response)
         this.hideLoading()
          if (response.data.SUCCESS) {
                this.hideLoading()
                this.$message.success("上传成功")
                this.$refs.upload.clearFiles()
                this.getJHFiles()
          }else{
             this.hideLoading()
             this.$message.error("上传失败")
          }
        })
        .catch((error) => {
         this.hideLoading()
      });
    },
    getJHFiles(){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({BUDGETSNOWID:this.checkJHId}, this.$store.state.token,this.$md5(this.comjs.sortAsc({BUDGETSNOWID:this.checkJHId})).toUpperCase())
        this.$http.post("Budget/GetBudgetFileList", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                   this.fileList=response.data.DATA
                   this.chooseFileModal=true
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
    downFile(e){
        window.open(e)
    },
    delFile(e){
        //console.log(this.canDo)
        if(this.canDo){
            this.$confirm('确定删除附件？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
                this.sendDelFile(e)
            }).catch(() => {
            this.$message.info("已取消")    
          });
        }
    },
    sendDelFile(id){
        this.canDo=false
        this.showLoading("请稍后")
        var md5_data =this.comjs.requestDataEncrypt({FILESNOWID:id}, this.$store.state.token,this.$md5(this.comjs.sortAsc({FILESNOWID:id})).toUpperCase())
        this.$http.post("Budget/DeleteBudgetFile", md5_data)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.getJHFiles()
                } else {
                    this.errorRequest(response.data.DATA,response.data.MSG,true)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
             });
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style scoped>
.fileBox{overflow: hidden;margin: 20px 0;padding: 20px;background-color: #F8F8F9;}
.fileItem{border-radius: 3px;background-color: #FFFFFF;cursor: pointer;padding: 10px;float:left;margin-right: 20px;margin-bottom: 20px;width: 206px;}
.fileItem:nth-of-type(3n){margin-right: 0!important;}
.fileItem .name{font-size: 15px;font-weight: bold;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;color: #444;margin-bottom: 2px;}
.fileItem .keywords{font-size: 14px;font-weight: bold;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;margin-bottom: 5px;color: #909399;}
.fileItem .fileBtn{overflow: hidden;text-align: left;}
.fileItem span{margin-right: 10px;}
.noFile{text-align: center;font-size: 15px;color: #409EFF;}
.fileTitle{font-size: 16px;font-weight: bold;overflow: hidden;}
</style>